import React, { Component } from "react";
import { Card, CardBody,  CardHeader, CardFooter, CustomInput, Input, Form, FormGroup } from 'reactstrap';
import FeatureListEditor from '../survey/FeatureListEditor';
import Annotation from 'react-image-annotation';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable'
import Tooltip from 'rc-tooltip';
import Slider from 'rc-slider';
import Toggle from 'react-toggle'
import ReactTooltip from 'react-tooltip'
import  'react-toggle/style.css'


/// styling for react-select
const customStyles = (isValid) => ({
  control: (styles: any) => ({
    ...styles,
    padding: 5,
    fontSize: "smaller",
    ...(!isValid && { borderColor: 'red' }),
  }),
});


    /// styling for react-select
    const customStyles2 = (isValid) => ({
      option: (provided, state) => ({
        ...provided,
        fontSize: '0.9em',
        borderBottom: '1px gray',
        backgroundColor: state.isSelected ? '#2f353a' : 'white',
        color: state.isSelected ? '#20a8d8' : '#2f353a',
        padding: 10,
        overflow: 'visible',
      }),
        control: (styles: any, state:any) => ({
          ...styles,
          color:"#2f353a",
          backgroundColor:"#f0f3f5",
          padding: 5,
          fontSize: "smaller",
          overflow: "visible",
          ...(!isValid && { borderColor: 'red' }),
        }),
      });

/// styling for range and slider
const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);
const Handle = Slider.Handle;


const handle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <Tooltip
      prefixCls="rc-slider-tooltip"
      overlay={value}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps} />
    </Tooltip>
  );
};

function makeId(length) {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export default class RenderDesign extends Component {
  constructor(props) {
    super(props);
    let initialannotations = []
    let initialselections = {}
    let initializefalse = ['checkbox', 'radio']
    initialannotations = this.props.options[0]['annotations']
    if (typeof this.props.savedpages != "undefined") {
      if(this.props.savedpages===true){
        initialannotations = this.props.savedresponses.annotations
        initialselections = this.props.savedresponses.selectvalue
      } 
    } else {
          for (let i of this.props.options){
                let radiocheck = {}        
                initialselections[i['id']] = null
                if(this.props.questionprops.selectiontype==='toggle'){initialselections[i['id']] = false}
                if(this.props.questionprops.selectiontype==='slider'){initialselections[i['id']] = i['sliderdefault']}
                if(this.props.questionprops.selectiontype==='range'){initialselections[i['id']] = i['rangedefault']}
                if(initializefalse.includes(this.props.questionprops.selectiontype))
                    {
                    for (let s of i['selectoptions']){
                        radiocheck[s['value']]=false
                    }
                    initialselections[i['id']]=radiocheck
                    }
            }
        }

    this.state = {
      options: this.props.options,
      annotations: initialannotations,
      annotation: {},
      selectvalue: initialselections,
      allocation:null,
    }
  }

  async componentDidMount(){ 
  }

  /// used to show the default annotations from render back on this page right away
  componentDidUpdate(prevProps){
    if(this.props.options[0].annotations.length > this.state.annotations.length){
      let listofannotations = []
      let copyofannotations = [...this.state.annotations]

      for(let y in this.state.annotations){
        listofannotations.push(this.state.annotations[y].data.id)
      }
      
      for(let x in this.props.options[0].annotations){
        if(!listofannotations.includes(this.props.options[0].annotations[x].data.id)){
          copyofannotations.push(this.props.options[0].annotations[x])
        }
      }
      this.setState({annotations:copyofannotations})
    }
  }

  componentWillUnmount(){
    this.props.stateChangePassThru(this.props.questionId, this.state)
}


  onChange = (annotation) => {
    this.setState({ annotation })
  }

  onSubmit = (annotation) => {
    const { geometry, data } = annotation
    this.setState({
      annotation: {},
      annotations: this.state.annotations.concat({
        geometry,
        data: {
          ...data,
          id: makeId(5)
        }
      })
    })
  }

  changeSelection(i, e){
    this.setState(prevState => ({selectvalue: {...prevState.selectvalue, [i]: e}}))
}

  /// callback to handle the select or multiselect change, only event used at this point for anything
  handleSelectChange = (selectvalue:any, action: any) => {
      this.changeSelection(this.props.options[0].id, selectvalue)
  };

  handleToggleSelect(e){
    this.changeSelection(this.props.options[0].id, e)
  }

  handleFieldChange = (event) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    this.changeSelection(this.props.options[0].id, value)
  }

  handleToggleChange(){
    this.changeSelection(this.props.options[0].id, !this.state.selectvalue[this.props.options[0].id])
  }

  handleRadioCheckChange = (event) => {
  const target = event.target;
  let type = target.type
  let value = target.value;
  console.log('toggle it', this.props.options[0].id, value, type)
  this.toggleRadioCheck(this.props.options[0].id, value, type)
  }

  toggleRadioCheck(i, e, t){

    if(t==='radio'){
        let newvals = this.state.selectvalue[i]
        for (let p in newvals)
            {
            newvals[p]=false
            }
        newvals[e]=true

        this.setState(prevState => ({
            selectvalue: {
            ...prevState.selectvalue,          
            [i]: newvals
            }
            }))
    } else {

        this.setState(prevState => ({
            selectvalue: {
            ...prevState.selectvalue,          
            [i]: {                     
            ...prevState.selectvalue[i], 
            [e]: !this.state.selectvalue[i][e]         
            }
            }
            }))
        }
  }

      /// used to generate the radio and checkbox items
      getSelectItems(item, type, id){
        if(type==="checkbox"){
          return(
          <CustomInput type="checkbox" id={id} value={item['value']} label={item['label']} name={id}></CustomInput>
    
          )}
          if(type==="radio"){
            return(
            <CustomInput type="radio" id={id} value={item['value']} label={item['label']} name={id}></CustomInput>
          )}
  
  
      }

      updatePageTitle = (event) => {
        const target = event.target;
        if(target != null){
          let value = target.value;
          const name = target.name;
          const type = target.type
          let item = {}
          item['name']=name
          item['value']=value
          item['type']=type
          this.props.updatePageTitle(item)
        }
      }

    updateCardHeaders = (event) => {
    const target = event.target;
    if(target != null){
        let value = target.value;
        const name = target.name;
        const type = target.type
        let item = {}
        item['name']=name
        item['value']=value
        item['type']=type
        console.log('updating headings', item)
        this.props.updateCardHeaders(item)
    }
    }

    /// triggers a change in border color when card is selected
    getActiveCardStyle(activeId){
      if (activeId===this.props.currentcard)
        {return '#20a8d8'}
      else {return '#c8ced3'}
  }

  activeItem(action, id){
    let item={}
    item['id']=this.props.options[0].id
    item['action']="Design"
    item['modalsize']="md"
    this.props.activeQuestion(item)
}


  renderSelectType(){

    if (this.props.questionprops.selectiontype === "select"){
         return(
            <Select
            defaultValue={this.state.selectvalue[this.props.options[0].id]}
            onChange={this.handleSelectChange}
            options={this.props.options[0].selectoptions}
            styles={customStyles2(true)}  /// false means there is an error and will error style
          />
        )
        }
    if (this.props.questionprops.selectiontype === "selectcreatable"){
        return(
            <CreatableSelect
            defaultValue={this.state.selectvalue[this.props.options[0].id]}
            onChange={this.handleSelectChange}
            options={this.props.options[0].selectoptions}
            styles={customStyles2(true)}  
            />
        )
        }
    if (this.props.questionprops.selectiontype === "multiselect"){
        return(
            <Select
            defaultValue={this.state.selectvalue[this.props.options[0].id]}
            isMulti
            onChange={this.handleSelectChange}
            options={this.props.options[0].selectoptions}
            styles={customStyles2(true)}  
            />
        )
        }   
    if (this.props.questionprops.selectiontype === "multiselectcreatable"){
        return(
            <CreatableSelect
            isMulti
            defaultValue={this.state.selectvalue[this.props.options[0].id]}
            onChange={this.handleSelectChange}
            options={this.props.options[0].selectoptions}
            styles={customStyles2(true)}  
            />
        )
        }

    if (this.props.questionprops.selectiontype === "toggle"){
      return(
          <Toggle
          id={this.props.options[0].id}
          value={this.props.options[0].togglevalue}
          checked= {this.state.selectvalue[this.props.options[0].id]}
          icons={{unchecked: null}}
          className = {this.props.options[0].togglestyle}
          onChange = {() => this.handleToggleChange()}
      />
      )
    }

    if (this.props.questionprops.selectiontype === "textfield"){
        return(
            <td style={{verticalAlign:"middle"}}>
          <Input
          key={this.props.options[0].id}
          name={this.props.options[0].id}
          type={"text"} 
          value={this.props.selectvalue[this.props.options[0].id]}
          onChange={this.handleFieldChange}/>
        </td>
        )
    }
    if (this.props.questionprops.selectiontype === "textarea"){
      return(
        <td style={{verticalAlign:"middle"}}>
           <Input
           key={this.props.options[0].id}
          name={this.props.options[0].id}
          type={"textarea"} 
          value={this.props.selectvalue[this.props.options[0].id]}
          onChange={this.handleFieldChange}/>
      </td>
      )
    }


    if (this.props.questionprops.selectiontype === "checkbox"){
        return(
        <td style={{verticalAlign:"middle"}}>
      <Form>
        <FormGroup check inline name={this.props.options[0].id} onChange={this.handleRadioCheckChange}>
            {this.props.options[0].selectoptions.map((item)=>this.getSelectItems(item, "checkbox", this.props.options[0].id))}
        </FormGroup>
      </Form>
        </td>
        )
    }
    if (this.props.questionprops.selectiontype === "radio"){
    return(
        <td style={{verticalAlign:"middle"}}>
      <Form>
        <FormGroup check inline name={this.props.options[0].id} onChange={this.handleRadioCheckChange}>
            {this.props.options[0].selectoptions.map((item)=>this.getSelectItems(item, "radio", this.props.options[0].id))}
        </FormGroup>
      </Form>
        </td>
    )
    }
  
    if (this.props.questionprops.selectiontype === "slider"){
      return(
        <div style={{ marginTop:"15px", marginLeft:"15px", marginRight:"15px", marginBottom:"30px"}}>
        <Slider 
        marks={this.props.options[0].slidermarks}
        min={this.props.options[0].slidermin}
        max={this.props.options[0].slidermax}
        step={this.props.options[0].sliderstep}
        defaultValue={this.state.selectvalue[this.props.options[0].id]}
        onAfterChange={this.handleSelectChange}
        dotStyle={{borderColor: '#c8ced3'}}
        activeDotStyle={{borderColor: '#c8ced3'}}
        />
        </div>
      )
    }
    if (this.props.questionprops.selectiontype === "range"){
      return(
        <div style={{ marginTop:"15px", marginLeft:"15px", marginRight:"15px", marginBottom:"30px" }}>
          <Range 
          marks={this.props.options[0].slidermarks}
          min={this.props.options[0].slidermin}
          max={this.props.options[0].slidermax}
          step={this.props.options[0].sliderstep} 
          defaultValue={this.props.options[0].rangedefault}
          onAfterChange={this.handleSelectChange}
          />
        </div>
      )
    }


  }


  render () {
    return (

        <div style={{width:"100%", height:"100%"}}>
        <ReactTooltip />
          
        <div className = "survey-question-headings" style={{color:this.props.surveyprops.style.questionheadtextcolor}}>
                  <div style={{display:"flex", width:"100"}}>
  
                  {this.props.pageprops.showquestiontitle &&
                      <Input 
                          name={"questiontitle"}
                          value={this.props.pageprops.questiontitle}
                          placeholder={"Question Title..."}
                          onChange={this.updatePageTitle}
                          style={{width:"97%", fontSize:"1.7em", fontWeight:"500", borderColor:"#8f9ba640", backgroundColor:"transparent", color:this.props.surveyprops.style.questionheadtextcolor}}    
                          />
                  }
  
                      {this.props.pageprops.showquestiontip &&
                      <a className="icon-info" style={{fontSize:"1.2em", marginLeft:"auto", marginRight:"10px", color:"#5c6873"}} data-place="left" data-tip={this.props.pageprops.questiontip}/>
                      }
                      </div>
                  
                  {this.props.pageprops.showquestionsubtitle &&
                      <Input 
                          name={"questionsubtitle"}
                          value={this.props.pageprops.questionsubtitle}
                          placeholder={"Question Subtitle..."}
                          type={"textarea"}
                          rows={"1"}
                          onChange={this.updatePageTitle}
                          style={{width:"97%", fontSize:"1.2em", fontWeight:"200",  borderColor:"#8f9ba640", backgroundColor:"transparent", color:this.props.surveyprops.style.questionheadtextcolor}}  
                          />
                  }
          </div>

      <div style={{display:"flex", alignItems:"stretch", flexWrap: "wrap"}}>

      <Card 
      onClick={()=>this.props.activeCard(this.props.options[0].id)}
      key={this.props.page.questions[0].questionId}
      style={{cursor:'pointer', 
          backgroundColor:this.props.surveyprops.style.formbodycolor,
          color:this.props.surveyprops.style.formcardtextcolor,
          width:"75%",
          borderColor: this.getActiveCardStyle(this.props.options[0].id),
        }}>
          
          {this.props.options[0].showheader &&
            <CardHeader className="survey-form-card-header" style={{backgroundColor:this.props.surveyprops.style.formheadcolor, color:this.props.surveyprops.style.formheadtextcolor}}>
               <div style={{display:"flex"}} >
                <Input 
                    name={"optionheader"}
                    value={this.props.options[0].optionheader}
                    placeholder={"Card header..."}
                    type={"text"}
                    onChange={this.updateCardHeaders}
                    style={{backgroundColor:"transparent", borderColor:"#8f9ba640", color:this.props.surveyprops.style.formheadtextcolor}}  
                    />
                <div>
                    <span><i className="cui-options" style={{fontSize:"1em", cursor:"pointer", color:"#8f9ba6", marginLeft:"auto", marginRight:"10px"}} onClick={()=>this.activeItem('Design', this.props.options[0].id)}  ></i></span>
                </div>
                </div>
            </CardHeader>
          }
      
        
        <CardBody style={{backgroundColor: this.props.questionprops.noselectcardcolor}}>


        <div>
        <Annotation
                src={this.props.options[0].imageSrc}
                alt='pricekit ux design image'
                annotations={this.state.annotations}
                allowTouch={true}
                value={this.state.annotation}
                onChange={this.onChange}
                onSubmit={this.onSubmit}
                disableOverlay={this.props.options[0].disableannotation}
                disableEditor={this.props.options[0].disableannotation}
                disableSelector={this.props.options[0].disableannotation}
                />
        </div>
        </CardBody>


      </Card>
      {this.props.options[0].showfeatures &&  
      <Card 
      onClick={()=>this.props.activeCard(this.props.options[0].id)}
      key={this.props.page.questions[0].questionId+"Features"}
      style={{cursor:'pointer', 
          backgroundColor:this.props.surveyprops.style.formbodycolor,
          color:this.props.surveyprops.style.formcardtextcolor,
          width:"24%",
          marginLeft:"5px",
          borderColor: this.getActiveCardStyle(this.props.options[0].id),
        }}
      >
        <CardHeader className="survey-form-card-header" style={{backgroundColor:this.props.surveyprops.style.formheadcolor, color:this.props.surveyprops.style.formheadtextcolor}}>
        <div style={{display:"flex"}} >
        <Input 
                name={"helpmessage"}
                value={this.props.options[0].featureheader}
                placeholder={"Card title..."}
                type={"text"}
                onChange={this.updateCardHeaders}
                style={{backgroundColor:"transparent", fontSize:"0.8em", marginBottom:"5px", borderColor:"#8f9ba640", borderColor:"#8f9ba640", color:this.props.surveyprops.style.formfoottextcolor}}  
                />
            <div>
                <span><i className="cui-options" style={{fontSize:"1em", cursor:"pointer", color:"#8f9ba6", marginLeft:"auto", marginRight:"10px"}} onClick={()=>this.activeItem('Design', this.props.options[0].id)}  ></i></span>
            </div>
        </div>
        </CardHeader>
        <CardBody>
              <FeatureListEditor
              key={this.props.options[0].id}
              surveyprops={this.props.surveyprops}
              features={this.props.options[0].features}
              randomize={this.props.options[0].randomize}
              showprice={this.props.options[0].showprice}
              price={this.props.options[0].price}
              pricemetric={this.props.options[0].pricemetric}
          />
      </CardBody>
      {this.props.options[0].showfooter &&
        <CardFooter className="survey-form-card-footer" style={{backgroundColor:this.props.surveyprops.style.formfootcolor, color: this.props.surveyprops.style.formfoottextcolor, paddingBottom:"0px"}}>
            <Input 
                name={"helpmessage"}
                value={this.props.options[0].helpmessage}
                type={"text"}
                placeholder={"Instructions..."}
                onChange={this.updateCardHeaders}
                style={{backgroundColor:"transparent",  marginBottom:"5px", borderColor:"#8f9ba640", borderColor:"#8f9ba640", color:this.props.surveyprops.style.formfoottextcolor}}  
                />

                {this.renderSelectType(this.props.page.questions[0].cards[0].selectiontype)}

        </CardFooter>
        }
      </Card>
      }
      </div>
      </div>
    )
  }
}
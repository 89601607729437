import React, { Component } from "react";
import { API } from "aws-amplify";
import Loader from "react-loader-spinner";
import DataTable from 'react-data-table-component';
import FeatureExpanderMenu from './FeatureExpanderMenu'

/// also search, filter and download csv - see examples https://jbetancur.github.io/react-data-table-component/?path=/story/filtering--example-1
/// need to only show those with same ORG ID

/// gets all survey responses
const conditionalRowStyles = [
  {
    when: row => row.status === "LIVE",
    style: {
      fontWeight: "500",
      },
    },
    {
      when: row => row.selectedrow === true,
      style: {
        fontWeight: "500",
        backgroundColor:"#2f353a",
        color: "#20a8d8",
        },
      },
];

const columns = [
  {
    name: 'Feature ID',
    selector: 'featureId',
    sortable: true,
    width:'100px'
  },
  {
    name: 'Header',
    selector: 'header',
    sortable: true,
    width:'250px'
  },

  {
    name: 'Detail',
    selector: 'detail',
    sortable: false,
    width:'250px'
  },
  {
    name: 'Feature Type',
    selector: 'featuretype',
    sortable: true,
    width:'150px'
  },
  {
    name: 'Active',
    selector: 'isActive',
    sortable: true,
    width:'100px'
  },
  {
    name: 'Last Update',
    selector: 'localsavetime',
    sortable: true,
    width:'150px'
  },
];



export default class DataTableFeature extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        isLoading: true,
        data: []
      };
    }

    async componentDidMount() {
        try{
            let querystring = {'queryStringParameters':{'orgId':this.props.orgId}}
            let responses = await API.get("features", "/features", querystring)
            this.setState({data: responses, isLoading: false},()=>this.props.updateCard(responses.length))
        } catch (e) {
            alert('There was problem getting feature list', e);
        }
        }

    componentDidUpdate(prevProps, prevState){
          if(this.props.modifiedfeature !==prevProps.modifiedfeature){
            this.setState({isLoading:true},()=>this.updateDataTable())
          }
          if (this.props.updatetable !==prevProps.updatetable){
            if(this.props.updatetable===true){
              this.setState({isLoading:true},()=>this.updateDataTable())
            }
          }
          }


      async updateDataTable(){
          try{
            let querystring = {'queryStringParameters':{'orgId':this.props.orgId, 'updateitem':this.props.modifiedfeature}}
            let updatefeats = await API.get("features", "/features", querystring)
            updatefeats = JSON.parse(JSON.stringify(updatefeats))
            console.log('updated new data')
            this.setState({data: updatefeats, isLoading: false}, ()=>this.props.updateTable(false))
        } catch (e) {
            alert('There was problem getting feature list', e);
        }
        }

        updateState = state => {
          if(state.featureId !== this.state.selectedfeature){

            let datacopy = [...this.state.data]
            
            for(let i in datacopy){
              if(datacopy[i]['featureId']===state.featureId){
                datacopy[i]['selectedrow']=true
              }
              if(datacopy[i]['featureId']===this.state.selectedfeature){
                datacopy[i]['selectedrow']=false
              }
            }
          this.setState({ selectedfeature: state.featureId, data:datacopy }, ()=>this.props.getRow(true, state));
          }
        }



        updateStateToggle = (togglestate, row) => {
        }

    render() {
        return (
          <div>

            {this.state.isLoading && <Loader type="Puff" color="#20a8d8" height={100} width={100} timeout={3000}/>}


            <DataTable style={{overflowX:"hidden"}}
            keyField={"featureId"}
            title="Feature List"
            theme="pricekit"
            columns={columns}
            data={this.state.data}
            defaultSortField="localsavetime"
            defaultSortAsc={false}
            selectableRows={false}
            selectableRowsNoSelectAll={true}
            selectableRowsHighlight={true}
            expandableRows={true}
            expandableRowsComponent={<FeatureExpanderMenu />}
            onRowExpandToggled={this.updateStateToggle}
            onRowClicked={this.updateState}
            expandOnRowClicked={true}
            pagination={false}
            highlightOnHover={false}
            striped={false}
            pointerOnHover={true}
            dense={false}
            noTableHead={false}
            persistTableHead={true}
            progressPending={false}
            noHeader={true}
            subHeader={false}
            subHeaderAlign={"right"}
            fixedHeader={true}
            fixedHeaderScrollHeight="400px"
            conditionalRowStyles={conditionalRowStyles}

      />
      </div>
      );
    }
}
import React, { Component } from "react";
import { Row} from 'reactstrap';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; 
import ContentCardEditor from '../survey/ContentCardEditor'

/// SurveyContent - can have any number of cards
/// cards are created by calling ContentCard
/// each card can have multiple content elements inside it (e.g., image, text)
/// Used for Instructions and Interstitial content between other types of pages



/// gets viewport width
const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

export default class SurveyContent extends Component {
  constructor(props) {
    super(props);
    let mwidth = this.props.options.length
    mwidth = 1/mwidth
    mwidth = mwidth * 100
    mwidth = mwidth - 3
    mwidth = mwidth.toFixed()
    mwidth = mwidth + "%"
    if(vw < 600){
      mwidth = "100%"
    }
    this.toggleImage = this.toggleImage.bind(this)
    this.state = {
        showImage: false,
        isvalid: true,
        mwidth: mwidth,
      };
    }

   
  async componentDidMount() {
    this.props.handlePageValid(this.state.isvalid)
  }

  componentWillUnmount(){
      this.props.stateChangePassThru(this.props.questionId, this.state)
  }

    ///toggle image not working, see what is getting passed to i
    toggleImage(i){
        for(let a in this.props.options){
        if(a['id']===i){
        this.setState({
        showImage: true,
        imageSrc: a['imageSrc'],
        imageTitle: a['imageTitle'],
        imageCaption: a['imageCaption']})
        }
        }
    }

    /// in render the map calls the component to assemble a card for each content option
    getContentCard(option){
        return(
        <ContentCardEditor
            key={option['id']}
            option={option}
            surveyprops={this.props.surveyprops}
            maxcardwidth={this.state.mwidth}
            onImageToggle={this.toggleImage}
            toggleSidebar={this.props.toggleSidebar} 
            />
        )
    }



   render(){

    return(

      <div>
      {this.state.showImage && this.props.questionprops.showLightbox && (
        <Lightbox
          mainSrc={this.state.imageSrc}
          enableZoom={true}
          imageTitle={this.state.imageTitle}
          imageCaption={this.state.imageCaption}
          onCloseRequest={() => this.setState({ showImage: false })}
          />
      )}
      
      <div className = "survey-row-of-cards">

        {this.props.options.map((option, key=option.id)=>this.getContentCard(option))}
            
       </div>

      </div>
    );
  }
}
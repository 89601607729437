import React, { Component } from "react";
import { Table, Input, Card, CardBody} from 'reactstrap';
import ReactTooltip from 'react-tooltip'
import RenderListItems from '../survey/RenderListItems'
import './RenderList.scss';

/// SurveyList - is used as a list or grid to handle multiple questions (columns) with multiple subjects (rows)
/// only limit is one Slider or one Range, otherwise can have multiple of any types


const defaultcolumn = {
    id: null,
    header: "",
    type: "New",
    value: null,
    initial: null, 
    slidermin: 0,
    slidermax: 100,
    sliderstep: 25,
    slidermarks: { 0: "A", 25: "B", 50: "C", 75: "D", 100: "E" },
    sliderdefault: null,
    rangedefault:[0,100],
    allocationamount:100,
    selectoptions:[],
    style: "custom-success", 
    validationtype: "none",
    toggletype:null,
}

/// used to optionally shuffle rows
function shuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }
    return array;
    }

function makeId(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
    }

    export default class RenderList extends Component {
        constructor(props) {
          super(props);
          let initialselections = {}
          let initializefalse = ['checkbox', 'radio']
          let val = {}
          let initialtotal = 0
          let initialvalid = 0
          let initialremaining = 0
          let initialize = {}
          let newlistrow = this.props.list.listrow
          let validcolumns = {}
          let pageisvalid = false
          let hasallocation = false
          let initialallocation = 0
          if (typeof this.props.savedpages != "undefined") {
              if(this.props.savedpages===true){
                  initialselections = this.props.savedresponses['selectvalue']
                  newlistrow = this.props.savedresponses['listrow']
                  initialremaining = this.props.savedresponses['remaining']
                  initialtotal = this.props.savedresponses['total']
                  validcolumns = this.props.savedresponses['validcolumns']
              }
              } else {
                    for (let r in this.props.list.listrow){
                        let rowid = this.props.list.listrow[r]['id']
                        for(let c in this.props.list.listcolumn)
                        {
                            let colid = this.props.list.listcolumn[c]['id']
                            if(this.props.list.listcolumn[c]['type']==='allocation'){
                                initialtotal = 0
                                initialvalid = parseInt(this.props.list.listcolumn[c]['allocationamount'])
                                initialremaining = parseInt(this.props.list.listcolumn[c]['allocationamount'])
                                initialallocation = parseInt(this.props.list.listcolumn[c]['allocationamount'])
                                hasallocation = true
                                val[colid]=0
                            
                            }
                            val[colid] = this.props.list.listcolumn[c]['initial']
                            if(this.props.list.listcolumn[c]['validationtype']==="none"){
                                validcolumns[colid]=true
                            } else {validcolumns[colid] = false}
                           
                            if (initializefalse.includes(this.props.list.listcolumn[c]['type']))
                            {
                            let startvals = {}
                            for (let s of this.props.list.listcolumn[c]['selectoptions']){
                                startvals[s['value']]=false
                                }
                                    val[colid]=startvals
                                }
                            initialize[rowid]=val
                        }
                    }
                    initialselections = initialize
                    if (this.props.list.listprops.randomize){
                        newlistrow = shuffle(newlistrow)}
                  }

          this.changeSelection = this.changeSelection.bind(this)
          this.changeAllocation = this.changeAllocation.bind(this)
          this.toggleRadioCheck = this.toggleRadioCheck.bind(this)
          this.changeDiscreteToggle = this.changeDiscreteToggle.bind(this)
          this.state = {
              selectvalue: initialselections,
              listrow: newlistrow,
              remaining: initialremaining,
              total: initialtotal,
              validallocationamount:initialvalid,
              isvalid: pageisvalid,
              validcolumns: validcolumns,
              allocation: initialallocation,
              hasallocation: hasallocation
            };
          }
        
    async componentDidMount() {
    }

    /// checks if new columns and will initialize the data
    /// needed for check/radio otherwise will error when tries to toggle value
    componentDidUpdate(prevProps, prevState){
        }

    /// used to save state on going forward/back a page - so if you come back here the order (if random row) and selections you made are preserved
    componentWillUnmount(){
        this.props.stateChangePassThru(this.props.questionId, this.state)
    }





    /// NOTE *** does NOTE reset page validation or column validation if you go from valid and then make invalid change
    /// this is checking of all the columns are valid
    pageValidation(){
        let checkitems = Object.values(this.state.validcolumns)
        let validcheck = checkitems.includes(false)
        this.setState({isvalid: !validcheck}, () => {this.props.handlePageValid(this.state.isvalid)})
    }

    /// this is checking if the individual column which was just updated is valid
    columnValidation(c){
        let validcheck = true
        let validtype = "none"
        let questtype = null
        let deepchecking = ['checkbox', 'radio']
        let colvals = []
        for(let j in this.props.list.listcolumn){
            if(this.props.list.listcolumn[j]['id']===c){
                validtype = this.props.list.listcolumn[j]['validationtype']
                questtype = this.props.list.listcolumn[j]['type']
            }
        }

        for(let i in this.state.selectvalue){
            if (deepchecking.includes(questtype)){
                let deepanswers = Object.values(this.state.selectvalue[i][c])
                let deepanswercheck = deepanswers.includes(true)
                colvals.push(deepanswercheck)
            } else {
            colvals.push(this.state.selectvalue[i][c])
            }
        }

        if(validtype==="all"){
            if (colvals.includes(false)){
                validcheck= false
            }
            if (colvals.includes(null)){
                validcheck = false
            }
        }

        if(validtype==="one")
            {
            validcheck = false
            if (colvals.includes(true)){
                validcheck= true
            } else {
            validcheck = colvals.some(function (el) {return el !== null})
            }
            }

        this.setState(prevState => ({validcolumns: {...prevState.validcolumns, [c]: validcheck}}), () => {this.pageValidation()} )
        
    }

    /// updating selection for r=row, c=column, v=value
    changeSelection(r, c, v){

        this.setState(prevState => ({
                selectvalue: {
                ...prevState.selectvalue,          
                [r]: {                     
                ...prevState.selectvalue[r], 
                [c]: v         
                }
                }
                }),() => {this.columnValidation(c)})
        }

    /// updating selection for r=row, c=column, v=value, t=type (row - cant have more than one toggle in row, likewise for column, and can also enforce both for max-diff type)
    changeDiscreteToggle(r, c, v, t){



        if(t==="column" && v===true){
            for (let i of Object.keys(this.state.selectvalue)){

            this.setState(prevState => ({
                selectvalue: {
                ...prevState.selectvalue,          
                [i]: {                     
                ...prevState.selectvalue[i], 
                [c]: false      
                }
                }
                }))
            }
            this.setState(prevState => ({
                selectvalue: {
                ...prevState.selectvalue,          
                [r]: {                     
                ...prevState.selectvalue[r], 
                [c]: true     
                }
                }
                }),() => {this.columnValidation(c)})
        }

        if(t==="row" && v===true){
                for(let j of Object.keys(this.state.selectvalue[r])){
                    for(let k in this.props.list.listcolumn){
                        if(this.props.list.listcolumn[k]['id']===j){
                            if (this.props.list.listcolumn[k]['type']==="discretetoggle"){
                                this.setState(prevState => ({
                                    selectvalue: {
                                    ...prevState.selectvalue,          
                                    [r]: {                     
                                    ...prevState.selectvalue[r], 
                                    [this.props.list.listcolumn[k]['id']]: false     
                                    }
                                    }
                                    }),() => {this.columnValidation(c)})
                                } 
                            }
                        }
                    }
                this.setState(prevState => ({
                    selectvalue: {
                    ...prevState.selectvalue,          
                    [r]: {                     
                    ...prevState.selectvalue[r], 
                    [c]: true      
                    }
                    }
                    }),() => {this.columnValidation(c)})
                }

            if(t==="both" && v===true){
                for (let i of Object.keys(this.state.selectvalue)){

                    this.setState(prevState => ({
                        selectvalue: {
                        ...prevState.selectvalue,          
                        [i]: {                     
                        ...prevState.selectvalue[i], 
                        [c]: false      
                        }
                        }
                        }),() => {this.columnValidation(c)})
                    }
                for(let j of Object.keys(this.state.selectvalue[r])){
                    for(let k in this.props.list.listcolumn){
                        if(this.props.list.listcolumn[k]['id']===j){
                            if (this.props.list.listcolumn[k]['type']==="discretetoggle"){
                                this.setState(prevState => ({
                                    selectvalue: {
                                    ...prevState.selectvalue,          
                                    [r]: {                     
                                    ...prevState.selectvalue[r], 
                                    [this.props.list.listcolumn[k]['id']]: false     
                                    }
                                    }
                                    }),() => {this.columnValidation(c)})
                                } 
                            }
                        }
                    }
                this.setState(prevState => ({
                    selectvalue: {
                    ...prevState.selectvalue,          
                    [r]: {                     
                    ...prevState.selectvalue[r], 
                    [c]: true      
                    }
                    }
                    }),() => {this.columnValidation(c)})
                }
        }

    /// updating allocation for r=row, c=column, v=value.  Calcs the total and remaining
    changeAllocation(r, c, v){

        let newtotal = v
        for (let x of Object.keys(this.state.selectvalue)){
          if (x !== r){
              for (let y of Object.keys(this.state.selectvalue[x])){
                  if(y===c){
                    newtotal = newtotal + this.state.selectvalue[x][y]
                  }
              }
            
          }
        }

        let newremain = this.state.allocation - newtotal
        let validcheck = false

       if (newremain < 0){
         v = v + newremain
         newtotal = newtotal + newremain
         newremain = newremain - newremain
        }

       if(newtotal === this.state.allocation){validcheck=true}

       if(this.props.questionprops.validationtype==="none"){validcheck=true}

       this.setState(prevState => ({
        selectvalue: {
        ...prevState.selectvalue,          
        [r]: {                     
        ...prevState.selectvalue[r], 
        [c]: v         
        }},
        total: newtotal,
        remaining: newremain,
        validallocationamount: newremain,
        }),() => {this.columnValidation(c)})

    }


    /// updating selection for r=row, c=column, v=value, t=type
    toggleRadioCheck(r, c, e, t){

        console.log('row',r,'col',c,'var e', e, 'var t', t)

        if(t==='radio'){
            let newvals = {}
            for (let p in this.state.selectvalue[r][c])
                {
                newvals[p]=false
                }
            newvals[e]=true

            this.setState(prevState => ({
                selectvalue: {
                ...prevState.selectvalue,          
                [r]: {                     
                ...prevState.selectvalue[r], 
                [c]: newvals

                }
                }
                }),() => {this.columnValidation(c)})
        } else {

            this.setState(prevState => ({
                selectvalue: {
                ...prevState.selectvalue,          
                [r]: {                     
                ...prevState.selectvalue[r], 
                [c]: {
                ...prevState.selectvalue[r][c], 
                [e]: !prevState.selectvalue[r][c][e]
                }
                }
                }
                }),() => {this.columnValidation(c)})
            }
    }

    newRow(){
        console.log('new row')
    }

    newColumn(){
            let newId = makeId(5)
            let newhead = {}
            newhead['id']= newId
            newhead['header']=defaultcolumn.header
            let copyhead = [...this.props.list.listhead]
            copyhead.push(newhead)
            let newcol = {}
            newcol['id']=newId
            newcol['type'] = defaultcolumn.type
            newcol['value'] = defaultcolumn.value
            newcol['initial'] = defaultcolumn.initial
            newcol['slidermin']= defaultcolumn.slidermin
            newcol['slidermax'] = defaultcolumn.slidermax
            newcol['sliderstep'] = defaultcolumn.sliderstep
            newcol['slidermarks'] = defaultcolumn.slidermarks
            newcol['sliderdefault'] = defaultcolumn.sliderdefault
            newcol['rangedefault'] = defaultcolumn.rangedefault
            newcol['allocationamount'] = defaultcolumn.allocationamount
            newcol['selectoptions'] = defaultcolumn.selectoptions
            newcol['style'] = defaultcolumn.style
            newcol['validationtype'] = defaultcolumn.validationtype
            newcol['toggletype'] = defaultcolumn.toggletype
            let copylist = [...this.props.list.listcolumn]
            copylist.push(newcol)
            let listupdate = {}
            listupdate['type']="Column"
            listupdate['questionId']=this.props.questionId
            listupdate['listId']=this.props.list.listid
            listupdate['listhead']=copyhead
            listupdate['listcolumn'] =copylist
            let copyselectvalue = this.state.selectvalue
            for(const v of Object.keys(copyselectvalue)){
                copyselectvalue[v][newId]=false
            }
            this.setState({selectvalue:copyselectvalue}, ()=>this.props.saveItem(listupdate))
        }
        

    activeItem(action, id){
        let item = {}
        item['id'] = id
        item['action'] = action
        if(action==="row"){item['action']="List"}
        if(action==="column"){item['action']="Column"}
        this.props.activeQuestion(item)
    }

    
    updateListHeaders = (event) => {
        const target = event.target;
        if(target != null){
            let value = target.value;
            const name = target.name;
            const type = target.type
            let item = {}
            item['name']=name
            item['value']=value
            item['type']=type
            this.props.updateListHeaders(item)
        }
        }


    /// Todo - make sure ordering is correct when changing items in array;  Prob better to make this an attribute of column
    /// ROW header always needs to be first in list
     getHeader(item, index){
         if(item.id===index[0].id)
         {
         return(
             <th key={item.id}>
                <div  className="render-list-highlight" style={{display:"flex"}}>
                <Input 
                    name={item.id}
                    value={item.header}
                    placeholder={"Row Header..."}
                    type={"text"}
                    onChange={this.updateListHeaders}
                    style={{backgroundColor:"transparent", borderColor:"#8f9ba640", color:this.props.surveyprops.style.tableheadtext}}  
                    />
                <div>
                    <span><i className="cui-options" style={{fontSize:"1em", cursor:"pointer", color:"#8f9ba6", marginLeft:"auto", marginRight:"10px"}} onClick={()=>this.activeItem('row', item.id)}  ></i></span>
                </div>
                </div>
             </th>
             )
         } else {
            return(
                <th key={item.id}>
                    <div className="render-list-highlight" style={{display:"flex"}} >

                    <Input 
                    name={item.id}
                    value={item.header}
                    placeholder={"Column Header..."}
                    type={"text"}
                    onChange={this.updateListHeaders}
                    style={{backgroundColor:"transparent", borderColor:"#8f9ba640", color:this.props.surveyprops.style.tableheadtext}}  
                    />
                    <div>
                    <span><i className="cui-options" style={{fontSize:"1em", cursor:"pointer", color:"#8f9ba6", marginLeft:"auto", marginRight:"10px"}} onClick={()=>this.activeItem('column', item.id)}  ></i></span>
                    </div>
                    </div>
                </th>
                )

         }
        }


    
    /// calls ChoiceList to create each cell in the grid
    /// checks props to see if new rows were added in editor and therefore need to be initialized with default
    /// values so state.selectvalue has entry for the new item.  If not, RenderListItem will error
    /// UNK not clear why it works without having to call renderlistitems after the if statement for the newly initialized case
    getListItem(item){
        let initialized = Object.keys(this.state.selectvalue)
        if(initialized.includes(item.id)){
        return(
        <RenderListItems
            key={item.id}
            row={item.id}
            surveyprops={this.props.surveyprops}
            list={item}
            listprops={this.props.list.listprops}
            columns={this.props.list.listcolumn}
            remaining={this.state.remaining}
            validallocationamount={this.state.validallocationamount}
            selectvalue={this.state.selectvalue[item.id]}
            questionprops={this.props.questionprops}
            questionId={this.props.questionId}
            onSelectionChange={this.changeSelection}
            onAllocationChange={this.changeAllocation}
            onRadioCheckToggle={this.toggleRadioCheck}
            onDiscreteToggleChange={this.changeDiscreteToggle}
            />
        )
        } else {
            if(item.id !==null && typeof item.id !=='undefined'){
            let val = {}
            let initializefalse = ['checkbox', 'radio']
            for(let c in this.props.list.listcolumn){
                let colid = this.props.list.listcolumn[c]['id']
                val[colid] = this.props.list.listcolumn[c]['initial']          
                if (initializefalse.includes(this.props.list.listcolumn[c]['type']))
                {
                let startvals = {}
                for (let s of this.props.list.listcolumn[c]['selectoptions']){
                    startvals[s['value']]=false
                    }
                        val[colid]=startvals
                    }
            }

            this.setState(prevState => ({
                selectvalue: {
                ...prevState.selectvalue,          
                [item.id]: val
                }}))
            }


        }
    }
    

    updatePageTitle = (event) => {
    const target = event.target;
    if(target != null){
      let value = target.value;
      const name = target.name;
      const type = target.type
      let item = {}
      item['name']=name
      item['value']=value
      item['type']=type
      this.props.updatePageTitle(item)
    }
  }


  renderAllocationCard(){
    return(
      <div style={{display:"flex", marginLeft:"30px"}}>
        <Card style={{backgroundColor:this.props.surveyprops.style.tableheadbackground, color:this.props.surveyprops.style.tableheadtext, height:"50px", width:"50px", marginRight:"5px"}}>
          <p style={{fontSize:"2em", fontWeight:"600", marginLeft:"5px"}}>{this.state.total}</p>
          <p style={{fontSize:"0.5em"}}>allocated</p>
        </Card>
        {this.state.remaining !== 0 &&
        <Card style={{backgroundColor:this.props.surveyprops.style.tableheadtext, color:this.props.surveyprops.style.tableheadbackground, height:"50px", width:"50px", marginRight:"5px"}}>
        <p style={{fontSize:"2em", fontWeight:"600", marginLeft:"5px"}}>{this.state.remaining}</p>
        <p style={{fontSize:"0.5em"}}>remaining</p>
        </Card>
        }
         {this.state.remaining === 0 &&
         <div style={{display:"flex"}}>
        <Card style={{backgroundColor:this.props.surveyprops.style.tableheadbackground, color:this.props.surveyprops.style.tableheadtext, height:"50px", width:"50px", marginRight:"5px"}}>
        <p style={{fontSize:"2em", fontWeight:"600", marginLeft:"5px"}}>{this.state.remaining}</p>
        <p style={{fontSize:"0.5em"}}>remaining</p>
       
        </Card>
        <div>
           <span><i className="icon-check" style={{color:"#20a8d8", fontSize:"2em", marginLeft:"3px"}} ></i></span>
        </div>
        </div>
        }

      </div>

    )
  }
      
      
         render(){
          return(


            <div style={{width:"100%"}}>

                <ReactTooltip />
                
                <div className = "survey-question-headings" style={{color:this.props.surveyprops.style.questionheadtextcolor}}>
                <div style={{display:"flex", width:"100%"}}>

                {this.props.pageprops.showquestiontitle &&
                    <Input 
                        name={"questiontitle"}
                        placeholder={"Question Title..."}
                        value={this.props.pageprops.questiontitle}
                        onChange={this.updatePageTitle}
                        style={{width:"97%", fontSize:"1.7em", fontWeight:"500", borderColor:"#8f9ba640", backgroundColor:"transparent", color:this.props.surveyprops.style.questionheadtextcolor}}    
                        />
                }

                    {this.props.pageprops.showquestiontip &&
                    <a className="icon-info" style={{fontSize:"1.2em", marginLeft:"auto", marginRight:"10px", color:"#5c6873"}} data-place="left" data-tip={this.props.pageprops.questiontip}/>
                    }
                    </div>
                
                {this.props.pageprops.showquestionsubtitle &&
                    <Input 
                        name={"questionsubtitle"}
                        value={this.props.pageprops.questionsubtitle}
                        placeholder={"Question Subtitle..."}
                        type={"textarea"}
                        rows={"1"}
                        onChange={this.updatePageTitle}
                        style={{width:"97%", fontSize:"1.2em", fontWeight:"200", borderColor:"#8f9ba640", backgroundColor:"transparent", color:this.props.surveyprops.style.questionheadtextcolor}}  
                        />
                }
                </div>
            
            <div style={{display:"flex", flexWrap:"nowrap"}}>

                <div style={{marginLeft:"30px", marginRight:"30px"}}>
                <Table className="survey-list-table" 
                bordered={this.props.surveyprops.style.tablecellborders}
                style={{backgroundColor: this.props.surveyprops.style.tablebackground}}
                >

                    <thead style={{backgroundColor: this.props.surveyprops.style.tableheadbackground, color:this.props.surveyprops.style.tableheadtext}}> 
                        {this.props.list.listhead.map((item, key=item.id, index)=>this.getHeader(item, index))}</thead>
                   
                    <tbody style={{padding:"8px"}}>{this.props.list.listrow.map((item, key=item.id)=>this.getListItem(item))}</tbody>

                </Table>
                </div>

                <div style={{alignSelf:"flex-start", marginTop:"15px"}}>
                    <span onClick={()=>{this.newColumn()}} style={{marginLeft:"15px", alignSelf:"center", cursor:"pointer"}}><i className="icon-plus" style={{color:"#20a8d8", fontSize:"1em", marginRight:"5px"}} ></i></span>
                </div>

            </div>

            {this.state.hasallocation && this.renderAllocationCard()}
            </div>
          );
        }
      }
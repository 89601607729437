import React, { Component } from "react";
import {loadStripe} from '@stripe/stripe-js';
import {CardElement, Elements, ElementsConsumer} from '@stripe/react-stripe-js';
import StripeForm from "./StripeForm"
import {  Card, CardBody, CardGroup, Container, Jumbotron} from 'reactstrap';
import { API } from "aws-amplify";
import Loader from "react-loader-spinner";


const stripePromise = loadStripe('pk_test_HKzyrjxfr0pxFHuxOad3f0tC00ey8L5lPI');


export default class PaymentSettings extends Component {
  constructor(props) {
    super(props);
    this.userHasDetails = this.userHasDetails.bind(this)
    this.userHasPayment = this.userHasPayment.bind(this)
    this.state = {
      isLoading: false,
      details: null,
      payment: null,
    };
  }

  userHasDetails(d){
    this.setState({details: d}, () => this.checkComplete())
  }

  userHasPayment(p){
    this.setState({payment: p}, () => this.checkComplete())
  }

  checkComplete(){
      if(this.state.details !== null && this.state.payment !== null){
        this.setState({isLoading: true}, () => this.createAccount())
      }
  }

  async createAccount(){
    let account = {
        email: this.props.email,
        userId: this.props.userId,
        userProduct: this.props.userProduct,
        userRole: this.props.userRole,
        details: this.state.details,
        payment: this.state.payment
    }
    try {
        let stripestatus = await API.post("stripe", "/stripe", {
            body: account
          });
          console.log('stripe status', stripestatus)

          try{
            account['stripestatus'] = stripestatus
            let accountstatus = await API.post("users", "/users", {
              body: account
            });
            console.log('account status', accountstatus)
            this.setState({ isLoading: false })
            this.props.history.replace("/", "urlhistory")
            this.props.history.push("/welcome")

          } catch (e) {
            alert('There was problem creating the account', e);
            this.setState({ isLoading: false });
          }

      } catch (e) {
        alert('There was problem confirming your credit card information', e);
        this.setState({ isLoading: false });
      }
  }

    showLoader(){
        return(
        <div>
            <Jumbotron>
            <h1>Creating Your Account</h1>
            <h4>We are creating and activating your Pricekit account</h4>
            <hr/>
            <p style={{marginTop:"15px"}}>This should only take a minute...</p>
            </Jumbotron>
        <div className='survey-loader'>
            <Loader type="Puff" color="#20a8d8" height={100} width={100} timeout={3000}/>
        </div>
        </div>
        )
    }

    showForm(){
        return(
            <div>
            <Jumbotron>
            <h1>Just one more thing...</h1>
            <h4>We need to collect a few more items in order to create your account</h4>
            <hr/>
            <p style={{marginTop:"15px"}}>You selected the <mark>{this.props.userProduct}</mark> plan. Please enter your information and agree to our terms of service. </p>
            </Jumbotron>
            <Card className="p-3">
            <CardBody>

        <Elements stripe={stripePromise}>
        <ElementsConsumer>
            {({stripe, elements}) => (
            <StripeForm 
                stripe={stripe} 
                elements={elements}
                email={this.props.email}
                userHasLicense= {this.props.userHasLicense}
                userHasDetails= {this.userHasDetails}
                userHasPayment= {this.userHasPayment}
                userId = {this.props.userId}
                userRole = {this.state.userRole}
                userLicense = {this.state.userLicense}
                userProduct = {this.state.userProduct}
                />
            )}
        </ElementsConsumer>
        </Elements>

            </CardBody>
            </Card>
            </div>
        )
    }

  render() {
    return(
      <Container>
          {this.state.isLoading ? this.showLoader() : this.showForm()}
      </Container>
    )
  }
}


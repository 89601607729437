import React, { Component } from "react";
import { API } from "aws-amplify";
import { Button, Card, CardBody, CardGroup, CardHeader, CardFooter, Col, Container, Row, Input, Jumbotron } from 'reactstrap';
import DashBar from '../components/DashBar'


/// charts with crosstabs and filters saved as chart-copy

function getDate(){
  let today = new Date();
  let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
  return(date)
}

/// styling for react-select
const customStyles = (isValid) => ({
    option: (provided, state) => ({
        ...provided,
        padding: 20,
      }),
    control: (styles: any, state:any) => ({
      ...styles,
      padding: 5,
      width: "100%",
      fontSize: "smaller",
      color:"#20a8d8",
      ...(!isValid && { borderColor: 'red', color: 'black' }),
    }),
  });


const stest = [{'value': 'OK', 'label': 'nope'},{'value':'NOPE', 'label':"Digit"}]


export default class Charts extends Component {
    constructor(props) {
      super(props);
      let questionlist = Object.keys(this.props.data['counts'])
      let selectorlist = []
      selectorlist.push({'label': "SHOW ALL", 'value': "SHOW ALL"})
      for (let q of questionlist){
        let qi = {'label':this.props.data['questioninfo'][q]['header'], 'value':q}
        selectorlist.push(qi)
      }
      this.state = {
        isLoading: true,
        isFiltered: false,
        updatebutton: false,
        chartdata:this.props.data['counts'],  
        crosstabs:this.props.data['crosstabs'], 
        filtercharts:this.props.data['filtercharts'],
        allselections:this.props.data['allselections'],
        questioninfo:this.props.data['questioninfo'],
        questionlist:questionlist,
        selectorlist:selectorlist,
      };
    }

        
    renderNoFilterChart(qid, chart){

        let chartwidth = "500px"
        let bord = "#c8ced3"
        let bg = "#FFFFFF"
        let bdw ="1px"

        if (chart.length > 5){
          let cw = chart.length * 80
          chartwidth = cw.toString()+"px"
        }
        let hasnone = false
        let chtkeys = ['responses']
        for (let x of chart){
          if (x['answer'] === "None*"){
            hasnone = true
            chtkeys = ['responses', 'noresponses']
          }
        }

        return(

              <Col sm="6" md="6">
                <Card style={{borderColor:bord, backgroundColor:bg, borderWidth:bdw, flexGrow:"1"}}>

                    <CardHeader>
                    {this.state.questioninfo[qid]['header']}
                    </CardHeader>

                    <DashBar
                        data={chart}
                        groupmode={"stacked"}
                        chartkeys={chtkeys}
                        chartindex={'answer'} 
                        chartwidth={chartwidth}                
                    
                    />

                    <CardFooter style={{fontSize:"0.6em"}}>
                    {`${qid} : ${this.state.questioninfo[qid]['pagetype']} | ${this.state.questioninfo[qid]['type']} `}
                     {hasnone &&
                    <div>
                    {`* None = no answer to this question `}
                    </div>
                    }
                    </CardFooter>
                </Card>
              </Col>

        )
    }



    render() {
        return (
          <div>
          <hr/> 
          <Row>

              <div style={{display:"flex", flexWrap:"wrap", width:"100%", alignItems:"stretch"}}>

              {this.state.questionlist.map((chart, key=chart)=>this.renderNoFilterChart(chart, this.state.chartdata[chart]))}

              </div>

        </Row>
        </div>
        );
      }
    }
    
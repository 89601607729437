import React, { Component } from "react";
import { Button, Card, CardBody, CardGroup, CardHeader, Col, Container, Row, Jumbotron} from 'reactstrap';
import SolidInfoCard from '../components/SolidInfoCard'
import SolidQuestionCard from '../components/SolidQuestionCard'
import InfoCardSmall from '../components/InfoCardSmall'
import DataTableQuestion from '../components/DataTableQuestion'

export default class Question extends Component {
    constructor(props) {
      super(props);

      let nums = {}
      nums['numberquestions']=0
      nums['numberselect']=0
      nums['numberfield']=0
      nums['numbercontent']=0
      this.getRow = this.getRow.bind(this)
      this.updateCard = this.updateCard.bind(this)
      let initialrow = {}
      initialrow['questionid']=null
      this.state = {
        isLoading: false,
        numberquestions:nums,
        row:initialrow,
        activequestion: null,
        content:null
      };
    }


    async componentDidMount() {
      if (!this.props.isAuthenticated) {return};

      /// to handle page refresh will try to reset props from storage

      if(this.props.orgId === null){
        try{
          this.props.userHasEmail(localStorage.getItem('email'))
          this.props.userHasId(localStorage.getItem('userId'))
          this.props.userHasRole(localStorage.getItem('userRole'))
          this.props.userHasLicense(localStorage.getItem('userLicense'))
          this.props.userHasProduct(localStorage.getItem('userProduct'))
          this.props.userHasOrg(localStorage.getItem('orgId'))
        } catch {
          this.props.history.push("/home")
        }
      }

      this.props.userHasActiveSurvey(null)
    }



    updateCard(n){
      this.setState({numberquestions:n})
    }

    getRow(s, r){
      if(s===true){
        let content = JSON.parse(r.content)
        this.setState({row:r, content:content, activequestion:r['questionid']})
      }
    } 


    render() {
        return (
          <div>
          <Jumbotron style={{marginBottom:"30px", marginTop:"0px", padding:"0px"}}>
            <h1>Questions</h1>
            <h5 style={{fontWeight:"200"}}>The list of all the questions you've put into your surveys</h5>
            <hr/>
            <p style={{marginTop:"15px"}}>{` `}</p>
          </Jumbotron>
            <Row>
                <Col sm="6" md="2">
                  <SolidInfoCard  color="dark" header={this.state.numberquestions['numberquestions']} value="100" invert >Total Questions</SolidInfoCard>
                </Col>
                <Col sm="6" md="2">
                  <SolidInfoCard  color="secondary" header={this.state.numberquestions['numberselect']} value="100" >Selectables</SolidInfoCard>
                </Col>
                <Col sm="6" md="2">
                  <SolidInfoCard  color="primary" header={this.state.numberquestions['numberfield']} value="100" >Entry Fields</SolidInfoCard>
                </Col>
                {this.state.activequestion !== null && 
                  <SolidQuestionCard icon="icon-pencil" color="info" 
                  row={this.state.row}
                  content={this.state.content}
                  value="100"
                  >
                  </SolidQuestionCard>
                }


              </Row>
              {this.props.orgId &&
              <DataTableQuestion
                getRow={this.getRow}
                orgId={this.props.orgId}
                updateCard={this.updateCard}
                />
              }

          </div>
    
        );
      }
    }
    
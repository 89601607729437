import React, { Component } from "react";
import { Button, Card, CardBody,  CardHeader, CardFooter, CustomInput, Input, Form, FormGroup, ModalHeader, ModalBody, ModalFooter, Row, Col, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText,} from 'reactstrap';
import FeatureListEditor from '../survey/FeatureListEditor';
import Annotation from 'react-image-annotation';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable'
import Tooltip from 'rc-tooltip';
import Slider from 'rc-slider';
import Toggle from 'react-toggle'
import ReactTooltip from 'react-tooltip'
import  'react-toggle/style.css'

const  selectiontypes = [
    { value: "toggle", label: 'Toggle - yes/no for each' },
    { value: "checkbox", label: 'Checkbox - check all that apply' },
    { value: "radio", label: 'Radio - select one' },
    { value: "select", label: 'Select - pick one choice' },
    { value: "selectcreatable", label: 'Select Creatable - pick one or add your own' },
    { value: "multiselect", label: 'Multiselect - pick all that apply' },
    { value: "multiselectcreatable", label: 'Multiselect Creatable- pick all or add own' },
    { value: "range", label: 'Range - choose the upper/lower boundary values' },
    { value: "slider", label: 'Slider - choose along a scale' },
    { value: "textfield", label: 'Text field - custom entry' },
    { value: "textarea", label: 'Text area - free form comments' },
]

const noannotations = []

/// styling for react-select
const customStylesInModal = (isValid) => ({
    option: (provided, state) => ({
        ...provided,
        fontSize: '1em',
        borderBottom: '1px dotted black',
        backgroundColor: state.isSelected ? '#2f353a' : 'white',
        color: state.isSelected ? '#20a8d8' : '#2f353a',
        padding: 20,
      }),
    control: (styles: any, state:any) => ({
      ...styles,
      padding: 5,
      width:"100%",
      borderColor:"#20a8d8",
      ...(!isValid && { borderColor: 'red' }),
    }),
  });

/// styling for react-select
const customStyles = (isValid) => ({
    control: (styles: any, state:any) => ({
      ...styles,
      padding: 5,
      width:"100%",
      fontSize: "smaller",
      ...(!isValid && { borderColor: 'red' }),
    }),
  });

  /// styling for react-select
const customStyles2 = (isValid) => ({
    control: (styles: any, state:any) => ({
      ...styles,
      backgroundColor:"#f0f3f5",
      padding: 5,
      fontSize: "smaller",
      ...(!isValid && { borderColor: 'red' }),
    }),
  });


  function makeId(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }

 function truncateString(str, num) {
    if (typeof str === 'undefined'){
      return "..."
    }
    if (str.length <= num) {
      return str
    }
    return str.slice(0, num) + '...'
  }


/// styling for range and slider
const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);
const Handle = Slider.Handle;


const handle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <Tooltip
      prefixCls="rc-slider-tooltip"
      overlay={value}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps} />
    </Tooltip>
  );
};

export default class DesignModal extends Component {
  constructor(props) {
    super(props);
    let initialannotations = []
    let initialselections = {}
    let initializefalse = ['checkbox', 'radio']
    initialannotations = this.props.page.questions[0].cards[0]['annotations']
    for (let i of this.props.page.questions[0].cards){
        let radiocheck = {}        
        initialselections[i['id']] = null
        if(this.props.page.questions[0].questionprops.selectiontype==='toggle'){initialselections[i['id']] = false}
        if(this.props.page.questions[0].questionprops.selectiontype==='slider'){initialselections[i['id']] = i['sliderdefault']}
        if(this.props.page.questions[0].questionprops.selectiontype==='range'){initialselections[i['id']] = i['rangedefault']}
        if(initializefalse.includes(this.props.page.questions[0].questionprops.selectiontype))
            {
            for (let s of i['selectoptions']){
                radiocheck[s['value']]=false
            }
            initialselections[i['id']]=radiocheck
            }
    }
    let keylist = []
    let marklist = []
    if(this.props.page.questions[0].questionprops.selectiontype==='slider'){
        let sortedmarks = Object.keys(this.props.page.questions[0].cards[0].slidermarks).sort().reduce((acc, key) => ({...acc, [key]: this.props.page.questions[0].cards[0].slidermarks[key]}), {})
        for (let [key, value] of Object.entries(sortedmarks)){
            marklist.push(value)
            keylist.push(key)
        }
        marklist=marklist.toString()
        keylist=keylist.toString()
    }
    if(this.props.page.questions[0].questionprops.selectiontype==='range'){
        let sortedmarks = Object.keys(this.props.page.questions[0].cards[0].slidermarks).sort().reduce((acc, key) => ({...acc, [key]: this.props.page.questions[0].cards[0].slidermarks[key]}), {})
        for (let [key, value] of Object.entries(sortedmarks)){
            marklist.push(value)
            keylist.push(key)
        }
        marklist=marklist.toString()
        keylist=keylist.toString()
    }
    let selectiontypeindex = null
    for (let x in selectiontypes){
        if(selectiontypes[x].value === this.props.page.questions[0].questionprops.selectiontype){
            selectiontypeindex = x
        }
    }
    this.state = {
        isValid: false,
        isdeleting: false,
        cardcopy: JSON.parse(JSON.stringify(this.props.page.questions[0].cards[0])),
        questionprops: {...this.props.page.questions[0].questionprops},
        annotations: initialannotations,
        annotation: {},
        selectvalue: initialselections,
        allocation:null,
        showfeatures: this.props.page.questions[0].cards[0].showfeatures,
        showprice: this.props.page.questions[0].cards[0].showprice,
        showheader: this.props.page.questions[0].cards[0].showheader,
        showfooter: this.props.page.questions[0].cards[0].showfooter,
        disableannotation: this.props.page.questions[0].cards[0].disableannotation,
        showannotation: this.props.page.questions[0].cards[0].showannotation,
        randomize: this.props.page.questions[0].cards[0].randomize,
        clearannotations:false,
        editannotations:false,
        editfeatures:true,
        editchoicetype:false,
        marklist: marklist,
        keylist:keylist,
        selectiontypeindex: selectiontypeindex,

      };
    }
   
  async componentDidMount(){ 
  }

  
  onChange = (annotation) => {
    this.setState({ annotation })
  }

  onSubmit = (annotation) => {
    const { geometry, data } = annotation
    let allannots = [...this.state.cardcopy.annotations]
    console.log('here is allannots', allannots)
    allannots = allannots.concat({geometry,data: {...data,id: makeId(5)}})
    console.log('after the concat', allannots)
    this.setState(prevState => ({
        cardcopy: {
        ...prevState.cardcopy,          
        annotations: allannots},
        annotation:{}
        }))
  }


///FOR SELECTION CHANGE in sample widget

        changeSelection(i, e){
            this.setState(prevState => ({selectvalue: {...prevState.selectvalue, [i]: e}}))
        }
        /// callback to handle the select or multiselect change, only event used at this point for anything
        handleSelectChange = (selectvalue:any, action: any) => {
            this.changeSelection(this.state.cardcopy.id, selectvalue)
        };

        handleToggleSelect(e){
            this.changeSelection(this.state.cardcopy.id, e)
        }

        handleFieldChange = (event) => {
            const target = event.target;
            let value = target.value;
            const name = target.name;
            this.changeSelection(this.state.cardcopy.id, value)
        }

        handleToggleChange(){
            this.changeSelection(this.state.cardcopy.id, !this.state.selectvalue[this.state.cardcopy.id])
        }

        handleRadioCheckChange = (event) => {
        const target = event.target;
        let type = target.type
        let value = target.value;
        this.toggleRadioCheck(this.state.cardcopy.id, value, type)
        }

        toggleRadioCheck(i, e, t){

            if(t==='radio'){
                let newvals = this.state.selectvalue[i]
                for (let p in newvals)
                    {
                    newvals[p]=false
                    }
                newvals[e]=true

                this.setState(prevState => ({
                    selectvalue: {
                    ...prevState.selectvalue,          
                    [i]: newvals
                    }
                    }))
            } else {

                this.setState(prevState => ({
                    selectvalue: {
                    ...prevState.selectvalue,          
                    [i]: {                     
                    ...prevState.selectvalue[i], 
                    [e]: !this.state.selectvalue[i][e]         
                    }
                    }
                    }))
                }
        }



        /// used to generate the radio and checkbox items
        getSelectItems(item, type, id){
        if(type==="checkbox"){
            return(
            <CustomInput type="checkbox" id={id} value={item['value']} label={item['label']} name={id}></CustomInput>

            )}
            if(type==="radio"){
            return(
            <CustomInput type="radio" id={id} value={item['value']} label={item['label']} name={id}></CustomInput>
            )}


        }


  deleteCard(){
    let cardprops = {}
    cardprops['type']=this.props.modalediting
    cardprops['questionId']=this.props.page.questions[0].questionId
    cardprops['cardId']=this.props.currentcard
    cardprops['questionId']=this.props.currentquestion
    this.props.deleteCard(cardprops)
}

    saveCard(){
        let card = {}
        card['type']=this.props.modalediting
        card['questionId']=this.props.page.questions[0].questionId
        card['cardId']=this.props.currentcard
        card['questionId']=this.props.currentquestion
        card['card'] = JSON.parse(JSON.stringify(this.state.cardcopy))
        card['questionprops'] = JSON.parse(JSON.stringify(this.state.questionprops))
        this.props.saveCard(card)
    }

    /// updates form field entry to update props
    handleFieldPropChange = event => {
        let fieldname = event.target.name
        let value = event.target.value
        this.setState(prevState => ({
            cardcopy: {                   
                ...prevState.cardcopy,    
                [fieldname]:value
        }}))
    }


    /// Changes the state params - AND logic to change the question type
    handleToggleChange(i){
        this.setState({[i]:!this.state[i]}, ()=>this.updateDesignProps(i))
        }

    updateDesignProps(i){
        const selectypes = ["selectmulti", "selectcreate"]
        const nonselecttypes = ["html", "toggle", "textfield", "textarea", "checkbox", "radio", "slider", "range", "bigimg", "img", "video", "gif", "contentlist", "jumbotron2", "jumbotron"]



        if(i==='textfield'){this.setState({textarea:!this.state.textarea},
            ()=>this.setState(prevState => ({questionprops: {...prevState.questionprops, selectiontype: i}})))}

        if(i==='textarea'){this.setState({textfield:!this.state.textfield},
            ()=>this.setState(prevState => ({questionprops: {...prevState.questionprops, selectiontype: i}}
            ),()=>this.setState({datefield:false, emailfield:false, numberfield:false})
            ))}

        if(i==='checkbox'){this.setState({radio:!this.state.radio},
            ()=>this.setState(prevState => ({questionprops: {...prevState.questionprops, selectiontype: i}})))}

        if(i==='radio'){this.setState({checkbox:!this.state.checkbox},
            ()=>this.setState(prevState => ({questionprops: {...prevState.questionprops, selectiontype: i}})))}

        if(i==='sliderticks'){this.setState(prevState => ({questionprops: {...prevState.questionprops, sliderticks: !this.state.question.sliderticks}}))}

        if(i==='slidermarks'){
            if(this.state.slidermarks===true){
            this.getSliderMarks()
        }}

        if(i==='img'){this.setState({bigimg:!this.state.bigimg},
            ()=>this.setState(prevState => ({questionprops: {...prevState.questionprops, selectiontype: i}})))}

        if(i==='slider'){this.setState({range:!this.state.range},
            ()=>this.setState(prevState => ({questionprops: {...prevState.questionprops, selectiontype: i}})))}
        
        if(i==='range'){this.setState({slider:!this.state.slider},
            ()=>this.setState(prevState => ({questionprops: {...prevState.questionprops, selectiontype: i}})))}
        
        if(selectypes.includes(i)){
            let newselecttype = null
            if(this.state.selectmulti === true)
            {
                if(this.state.selectcreate=== true){newselecttype = "multiselectcreatable"} 
                else {newselecttype = "multiselect"}
            } 
            else 
            {
                if(this.state.selectcreate===true){newselecttype = "selectcreatable"}
                else {newselecttype = "select"}
            }
            this.setState(prevState => ({questionprops: {...prevState.questionprops, selectiontype: newselecttype}}))
        }


        if(i==='showprice'){this.setState(prevState => ({cardcopy: {...prevState.cardcopy, showprice: this.state.showprice}}))}
        if(i==='randomize'){this.setState(prevState => ({cardcopy: {...prevState.cardcopy, randomize: this.state.randomize}}))}
        if(i==='showfeatures'){this.setState(prevState => ({cardcopy: {...prevState.cardcopy, showfeatures: this.state.showfeatures}}))}
        if(i==='showheader'){this.setState(prevState => ({cardcopy: {...prevState.cardcopy, showheader: this.state.showheader}}))}
        if(i==='showfooter'){this.setState(prevState => ({cardcopy: {...prevState.cardcopy, showfooter: this.state.showfooter}}))}
        if(i==='showannotation'){this.setState(prevState => ({cardcopy: {...prevState.cardcopy, showannotation: this.state.showannotation}}))}            
        if(i==='disableannotation'){this.setState(prevState => ({cardcopy: {...prevState.cardcopy, disableannotation: this.state.disableannotation}}))}

        if(i==='clearannotations'){this.setState({annotations: noannotations  })}

    }

    updateOptions = (selectvalue, action) => {
        let newoptionlist = []
        let newoption = {}
        if(selectvalue !== null){
            for (const o of selectvalue){
                newoption = {}
                newoption['label']=o.label
                newoption['value']=o.value
                newoptionlist.push(newoption)
            }
        } else {newoptionlist =[{}] }
        this.setState(prevState => ({
           cardcopy: {...prevState.cardcopy,           
                selectoptions: newoptionlist}
            }))
    };


    handleChoiceTypeChange = (selectvalue:any, action: any) => {
        if(this.state.questionprops.selectiontype===selectvalue.value){return}
        let selectiontypeindex = null
        for (let x in selectiontypes){
            if(selectiontypes[x].value === selectvalue.value){
                selectiontypeindex = x
            }
        }
        let initialselections = {}
        let i = this.props.currentcard
        let radiocheck = {}
        initialselections[i] = null
        if(selectvalue.value==='toggle'){initialselections[i] = false}
        if(selectvalue.value==='slider'){initialselections[i] = this.state.cardcopy['sliderdefault']}
        if(selectvalue.value==='range'){initialselections[i] = this.state.cardcopy['rangedefault']}
        if(['checkbox', 'radio'].includes(selectvalue.value))
        {
            if(this.state.cardcopy['selectoptions'] !== [{}] )
            {
                for (let s of this.state.cardcopy['selectoptions'])
                    {radiocheck[s['value']]=false}
                initialselections[i]=radiocheck
            } 
        }
        if(['slider', 'range'].includes(selectvalue.value))
        {
            let marklist = []
            let keylist = []

            let sortedmarks = Object.keys(this.state.cardcopy.slidermarks).sort().reduce((acc, key) => ({...acc, [key]: this.state.cardcopy.slidermarks[key]}), {})
            for (let [key, value] of Object.entries(sortedmarks)){
                marklist.push(value)
                keylist.push(key)
            }
        marklist=marklist.toString()
        keylist=keylist.toString()
        this.setState(prevState => ({questionprops: {...prevState.questionprops, selectiontype: selectvalue.value}, selectvalue:initialselections, 
            selectiontypeindex: selectiontypeindex, marklist:marklist, keylist:keylist }))
        return
        }

    this.setState(prevState => ({questionprops: {...prevState.questionprops, selectiontype: selectvalue.value}, selectvalue:initialselections, selectiontypeindex: selectiontypeindex }))
    };







        updateQuestionProps = (event) => {
            const target = event.target;
            let value = target.value;
            const name = target.name;
            console.log(name, value)
            this.setState(prevState => ({
                questionprops: {...prevState.questionprops,           
                    [name]: value}
                }))
            }
        
        updateItemProps = (event) => {
            const numericlist = ["slidermin", "slidermax", "sliderstep", "allocation"]
            const target = event.target;
            let value = target.value;
            const name = target.name;
            if (numericlist.includes(name)){
                value = Number(value)
                if(isNaN(value)){value=0}
            }
            this.setState(prevState => ({
                cardcopy: {...prevState.cardcopy,           
                   [name]: value}
               }))
            }
    

        getSliderMarks(){
            return(
            <div>
    
                <p style={{marginBottom:"5px"}}><small>Label Locations (numeric values, separated by commas)</small></p>
                <Input type="text" name={"keylist"} id={"keylist"}
                value={this.state.keylist.toString()}
                style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
                onChange={this.updateSliderMarkProps}
                />
                <p style={{marginBottom:"5px"}}><small>Label Values (separated by commas)</small></p>
                <Input type="text"  name={"marklist"} id={"marklist"}
                value={this.state.marklist.toString()}
                style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
                onChange={this.updateSliderMarkProps}
                />
    
            </div>
            )
            }
        ///  marks are a dict in the slider/range component.  Pulled out into two separate lists so you can add and change key values
        updateSliderMarkProps = (event) => {
            const target = event.target;
            let value = target.value;
            const name = target.name;
            this.setState({[name]: value}, ()=>this.updateSliderMarkState())
        }
    
        /// works on blur so not trying to update dict with keys as they are being typed.  converts the strings into lists and then creates dict
        /// based on the same order as shown in the respective lists
        updateSliderMarkState(){
            let keylist=this.state.keylist
            let marklist=this.state.marklist
            let newmarks = {}
            keylist = keylist.split(",")
            marklist = marklist.split(",")
            for (let k in keylist){
                if(!isNaN(k)){
                if (typeof marklist[k]==='undefined'){
                    newmarks[keylist[k]]=""
                } else {
                newmarks[keylist[k]]=marklist[k]
                }
                }
            }
            this.setState(prevState => ({
                cardcopy: {...prevState.cardcopy,           
                    slidermarks: newmarks}
                }))
    
            }
    
        ///  functions to update the Annotation data.text content
        renderAnnotationItem(item){
            
                if (item.geometry.x===0 && item.geometry.y===0 && item.data.text === ""){return}
                else { 
                return(
                <div style={{display:"flex"}}>
    
                    <span onClick={()=>{this.deleteAnnotationItem(item.data.id)}} 
                    style={{marginLeft:"auto", alignSelf:"center", cursor:"pointer"}}>
                    <i className="icon-close" style={{color:"#f86c6b", fontSize:"0.7em", marginRight:"5px"}} ></i>
                    </span>
                    
                    <ListGroupItem style={{marginTop:"0px", marginBottom:"0px", padding:"5px", width:"95%"}}>
                        <ListGroupItemText>
                        <Input autofocus type="textarea" rows="1" name={'Detail'} id={item.data.id}
                            value={item.data.text}
                            style={{marginBottom:"5px", backgroundColor:"#f0f3f5", fontWeight:"300", fontSize:"0.8em"}}
                            onChange={this.updateAnnotationItem}
                            />
                        </ListGroupItemText>
                    </ListGroupItem>
                </div>
            )
            }
          }

        updateAnnotationItem = (event) => {
            let listcopy = [...this.state.annotations]
            const target = event.target;
            let value = target.value;
            const name = target.name;
            const id = target.id
            let index = -1
            for (const x in listcopy){
                if(listcopy[x].data.id===id){
                    index = x
                }
            }
            if (index>-1){
                listcopy[index].data.text = value
                this.setState(prevState => ({
                    annotations: listcopy}
                    ))
                }
        }
    
        deleteAnnotationItem = (i) => {
            let listcopy = [...this.state.annotations]
            let index = -1
            for (const x in listcopy){
                if(listcopy[x].data.id===i){
                    index = x
                }
            }
            if (listcopy.length === 1){
                listcopy = noannotations
            } else {
                if (index > -1) {
                    listcopy.splice(index, 1);
                }
            }
            this.setState(prevState => ({
                annotations: listcopy
                }))
        }


        /// functions to update the Feature list content

        renderListItem(h, d, i){
            return(
                <div style={{display:"flex", marginTop:"15px"}}>

                <span onClick={()=>{this.deleteListItem(i)}} 
                style={{marginLeft:"auto", alignSelf:"center", cursor:"pointer"}}>
                <i className="icon-close" style={{color:"#f86c6b", fontSize:"0.7em", marginRight:"5px"}} ></i>
                </span>

                <ListGroupItem style={{backgroundColor:this.props.surveyprops.style.featurebackgroundcolor, borderColor:this.props.surveyprops.style.featureoutlinecolor, marginTop:"0px", marginBottom:"0px", width:"95%"}}>
                <ListGroupItemHeading>
                <Input type="textarea" rows="1" name={'Header'} id={i}
                    value={h}
                    style={{backgroundColor:"#f0f3f5", fontSize:"0.8em", fontWeight:"400"}}
                    onChange={this.updateFeature}
                    />
                </ListGroupItemHeading>
                <ListGroupItemText style={{marginTop:"0px", marginBottom:"0px" }}>
                <Input type="textarea" rows="2" name={'Detail'} id={i}
                    value={d}
                    style={{backgroundColor:"#f0f3f5", fontWeight:"300", fontSize:"0.7em"}}
                    onChange={this.updateFeature}
                    />
                </ListGroupItemText>
                </ListGroupItem>

            </div>
            )
      }

        getCardFeatures(){
                return(
                <ListGroup className = "survey-feature-list">

                {this.state.cardcopy.features.map((item, key=item.id)=>this.renderListItem(item.header, item.detail, item.id))}

                {this.state.cardcopy.showprice &&

                    <div style={{display:"flex", marginTop:"15px"}}>

                    <div style={{width:"95%", marginLeft:"auto"}}>

                        <ListGroupItem style={{backgroundColor:this.props.surveyprops.style.pricebackgroundcolor, color:this.props.surveyprops.style.pricetextcolor}}>
                        <ListGroupItemHeading style={{fontSize:"1.2em", fontWeight:"400"}}>
                        <Input autofocus type="number" name={'price'} id={'price'}
                            value={this.state.cardcopy.price}
                            style={{backgroundColor:"#f0f3f5", fontSize:"1.2em", fontWeight:"600"}}
                            onChange={this.handleFieldPropChange}
                            />
                        </ListGroupItemHeading>
                        <ListGroupItemText style={{fontSize:"0.8em", fontWeight:"300"}}>
                        <Input autofocus type="text" name={'pricemetric'} id={'pricemetric'}
                            value={this.state.cardcopy.pricemetric}
                            style={{backgroundColor:"#f0f3f5", fontSize:"0.8em", fontWeight:"400"}}
                            onChange={this.handleFieldPropChange}
                            />
                        </ListGroupItemText>
                        </ListGroupItem>

                    </div>
                    </div>
                    }
                    <span onClick={()=>{this.addListItem()}} style={{alignSelf:"center", cursor:"pointer"}}><i className="icon-plus" style={{color:"#20a8d8", fontSize:"1em", marginRight:"5px"}} ></i></span>
                </ListGroup>
                )
        }

        updateFeature = (event) => {
            let listcopy = [...this.state.cardcopy.features]
            const target = event.target;
            let value = target.value;
            const name = target.name;
            const id = target.id
            let index = -1
            for (const x in listcopy){
                if(listcopy[x].id===id){
                    index = x
                }
            }
            if (index>-1){
                if(name === "Header"){
                    listcopy[index].header = value
                } else {
                    listcopy[index].detail = value
                }
                this.setState(prevState => ({
                    cardcopy: {...prevState.cardcopy,           
                        features: listcopy}
                    }))
                }
        }
    
        deleteListItem = (i) => {
            let listcopy = [...this.state.cardcopy.features]
            let index = -1
            for (const x in listcopy){
                if(listcopy[x].id===i){
                    index = x
                }
            }
            if (index > -1) {
                listcopy.splice(index, 1);
            }
            this.setState(prevState => ({
                cardcopy: {...prevState.cardcopy,           
                    features: listcopy}
                }))
            }
    
        addListItem = () => {
            let listcopy = [...this.state.cardcopy.features]
            let id = makeId(5)
            const detail = ""
            const header = ""
            let item ={}
            item['id']=id
            item['header']=header
            item['detail']=detail
            listcopy.push(item)
            this.setState(prevState => ({
                cardcopy: {...prevState.cardcopy,           
                    features: listcopy}
                }))
            }

        getIfRandom(){
            if(this.state.randomize === false){return}
            else {
            return (
                <div style={{fontSize:"0.7em", display:"flex", fontWeight:"300", marginBottom:"0px"}}>
                <p>Feature list order is: </p>
                <p style={{color:'#20a8d8', marginRight:"5px"}}>randomized</p>
                <p style={{color:'#20a8d8', marginRight:"5px"}}></p>
                </div>
                )
            }
        }



    renderSelectType(){

        if (this.state.questionprops.selectiontype === "select"){
             return(
                <Select
                defaultValue={this.state.selectvalue[this.state.cardcopy.id]}
                onChange={this.handleSelectChange}
                options={this.state.cardcopy.selectoptions}
                styles={customStyles(true)}  /// false means there is an error and will error style
              />
            )
            }
        if (this.state.questionprops.selectiontype === "select-creatable"){
            return(
                <CreatableSelect
                defaultValue={this.state.selectvalue[this.state.cardcopy.id]}
                onChange={this.handleSelectChange}
                options={this.state.cardcopy.selectoptions}
                styles={customStyles(true)}  
                />
            )
            }
        if (this.state.questionprops.selectiontype === "multiselect"){
            return(
                <Select
                defaultValue={this.state.selectvalue[this.state.cardcopy.id]}
                isMulti
                onChange={this.handleSelectChange}
                options={this.state.cardcopy.selectoptions}
                styles={customStyles(true)}  
                />
            )
            }   
        if (this.state.questionprops.selectiontype === "multiselect-creatable"){
            return(
                <CreatableSelect
                isMulti
                defaultValue={this.state.selectvalue[this.state.cardcopy.id]}
                onChange={this.handleSelectChange}
                options={this.state.cardcopy.selectoptions}
                styles={customStyles(true)}  
                />
            )
            }
    
        if (this.state.questionprops.selectiontype === "toggle"){
          return(
              <Toggle
              id={this.state.cardcopy.id}
              value={this.state.cardcopy.togglevalue}
              checked= {this.state.selectvalue[this.state.cardcopy.id]}
              icons={{unchecked: null}}
              className = {this.state.cardcopy.togglestyle}
              onChange = {() => this.handleToggleChange()}
          />
          )
        }
    
        if (this.state.questionprops.selectiontype === "textfield"){
            return(
                <td style={{verticalAlign:"middle"}}>
              <Input
              key={this.state.cardcopy.id}
              name={this.state.cardcopy.id}
              type={"text"} 
              value={this.props.selectvalue[this.state.cardcopy.id]}
              onChange={this.handleFieldChange}/>
            </td>
            )
        }
        if (this.state.questionprops.selectiontype === "textarea"){
          return(
            <td style={{verticalAlign:"middle"}}>
               <Input
               key={this.state.cardcopy.id}
              name={this.state.cardcopy.id}
              type={"textarea"} 
              value={this.props.selectvalue[this.state.cardcopy.id]}
              onChange={this.handleFieldChange}/>
          </td>
          )
        }
    
    
        if (this.state.questionprops.selectiontype === "checkbox"){
            return(
            <td style={{verticalAlign:"middle"}}>
          <Form>
            <FormGroup inline name={this.state.cardcopy.id} onChange={this.handleRadioCheckChange}>
                {this.state.cardcopy.selectoptions.map((item)=>this.getSelectItems(item, "checkbox", this.state.cardcopy.id))}
            </FormGroup>
          </Form>
            </td>
            )
        }
        if (this.state.questionprops.selectiontype === "radio"){
        return(
            <td style={{verticalAlign:"middle"}}>
          <Form>
            <FormGroup inline name={this.state.cardcopy.id} onChange={this.handleRadioCheckChange}>
                {this.state.cardcopy.selectoptions.map((item)=>this.getSelectItems(item, "radio", this.state.cardcopy.id))}
            </FormGroup>
          </Form>
            </td>
        )
        }
      
        if (this.state.questionprops.selectiontype === "slider"){
          return(
            <div style={{  margin: 20 }}>
            <Slider 
            marks={this.state.cardcopy.slidermarks}
            min={this.state.cardcopy.slidermin}
            max={this.state.cardcopy.slidermax}
            step={this.state.cardcopy.sliderstep}
            defaultValue={this.state.selectvalue[this.state.cardcopy.id]}
            onAfterChange={this.handleSelectChange}
            dotStyle={{borderColor: '#c8ced3'}}
            activeDotStyle={{borderColor: '#c8ced3'}}
            />
            </div>
          )
        }
        if (this.state.questionprops.selectiontype === "range"){
          return(
            <div style={{  margin: 20 }}>
              <Range 
              marks={this.state.cardcopy.slidermarks}
              min={this.state.cardcopy.slidermin}
              max={this.state.cardcopy.slidermax}
              step={this.state.cardcopy.sliderstep} 
              defaultValue={this.state.selectvalue[this.state.cardcopy.id]}
              tipFormatter={value => `${value}`}
              onAfterChange={this.handleSelectChange}
              />
            </div>
          )
        }
    
    
      }

          /// called from render - this determines which selection component to use in the card footer
    renderSelectTypeInEditMode(){

        if (this.state.questionprops.selectiontype === "select"){
                return(
                <Row>
                <Col>
                        <p style={{marginBottom:"5px"}}><small>Add/Edit Select Options</small></p>
                        <CreatableSelect
                        isMulti
                        defaultValue={this.state.cardcopy.selectoptions}
                        onChange={this.updateOptions}
                        options={this.state.cardcopy.selectoptions}
                        name={this.state.cardcopy.id}
                        styles={customStyles2(true)}
                        />
                    </Col>
                    <Col>
                        <p style={{marginBottom:"5px"}}><small>Your Select Widget</small></p>
                        <Select
                        defaultValue={this.state.selectvalue[this.state.cardcopy.id]}
                        onChange={this.handleSelectChange}
                        options={this.state.cardcopy.selectoptions}
                        styles={customStyles(true)}
                        />
                    </Col>
                </Row>
            )
            }
        if (this.state.questionprops.selectiontype === "selectcreatable"){
            return(
                <Row>
                <Col>
                        <p style={{marginBottom:"5px"}}><small>Add/Edit Select Options</small></p>
                        <CreatableSelect
                        isMulti
                        defaultValue={this.state.cardcopy.selectoptions}
                        onChange={this.updateOptions}
                        options={this.state.cardcopy.selectoptions}
                        name={this.state.cardcopy.id}
                        styles={customStyles2(true)}
                        />
                    </Col>
                    <Col>
                        <p style={{marginBottom:"5px"}}><small>Your Select-Creatable Widget</small></p>
                        <CreatableSelect
                        defaultValue={this.state.selectvalue[this.state.cardcopy.id]}
                        onChange={this.handleSelectChange}
                        options={this.state.cardcopy.selectoptions}
                        styles={customStyles(true)}  
                        />
                    </Col>
                </Row>
            )
            }
        if (this.state.questionprops.selectiontype === "multiselect"){
            return(
                <Row>
                <Col>
                        <p style={{marginBottom:"5px"}}><small>Add/Edit Select Options</small></p>
                        <CreatableSelect
                        isMulti
                        defaultValue={this.state.cardcopy.selectoptions}
                        onChange={this.updateOptions}
                        options={this.state.cardcopy.selectoptions}
                        name={this.state.cardcopy.id}
                        styles={customStyles2(true)}
                        />
                    </Col>
                    <Col>
                        <p style={{marginBottom:"5px"}}><small>Your MultiSelect Widget</small></p>
                        <Select
                        defaultValue={this.state.selectvalue[this.state.cardcopy.id]}
                        isMulti
                        onChange={this.handleSelectChange}
                        options={this.state.cardcopy.selectoptions}
                        styles={customStyles(true)}  
                        />
                    </Col>
                </Row>
                
            )
            }   
        if (this.state.questionprops.selectiontype === "multiselectcreatable"){
            return(
                <Row>
                <Col>
                        <p style={{marginBottom:"5px"}}><small>Add/Edit Select Options</small></p>
                        <CreatableSelect
                        isMulti
                        defaultValue={this.state.cardcopy.selectoptions}
                        onChange={this.updateOptions}
                        options={this.state.cardcopy.selectoptions}
                        name={this.state.cardcopy.id}
                        styles={customStyles2(true)}
                        />
                    </Col>
                    <Col>
                        <p style={{marginBottom:"5px"}}><small>Your MultiSelect-Creatable Widget</small></p>
                        <CreatableSelect
                        isMulti
                        defaultValue={this.state.selectvalue[this.state.cardcopy.id]}
                        onChange={this.handleSelectChange}
                        options={this.state.cardcopy.selectoptions}
                        styles={customStyles(true)}  
                        />
                    </Col>
                </Row>
            )
            }
        if (this.state.questionprops.selectiontype === "discrete"){
            return(
                <Row>
                <Col style={{alignSelf:"flex-end"}}>
                <p style={{marginBottom:"5px"}}><small>Button Name for Discrete Choice</small></p>
                <Input type="text" name={"buttonname"} id={this.state.cardcopy.id}
                value={this.state.questionprops.buttonname}
                style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
                onChange={this.updateQuestionProps}
                />
                </Col>
                <Col style={{alignSelf:"flex-end"}}>
                <button className="btn btn-pill btn-lg btn-outline-dark" onClick={() => this.handleMaxDiffChange("selected")}>{this.state.questionprops.buttonname}</button>
                </Col>
            </Row>
                
            )
            }

        if (this.state.questionprops.selectiontype === "maxdiff"){
            return(
            <div>
            <div style={{display: "flex"}}>
            <button className="btn btn-pill btn-lg btn-outline-dark" onClick={() => this.handleMaxDiffChange("best")}>{this.state.questionprops.bestbuttonname}</button>
            <button className="btn btn-pill btn-lg btn-outline-dark" style={{marginLeft:"auto"}} onClick={() => this.handleMaxDiffChange("worst")}>{this.state.questionprops.worstbuttonname}</button>
            </div>
            <Row style={{marginTop:"15px"}}>
                <Col>
                <p style={{marginBottom:"5px"}}><small>Button Name for Positive Choice</small></p>
                <Input type="text" name={"bestbuttonname"} id={this.state.cardcopy.id}
                value={this.state.questionprops.bestbuttonname}
                style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
                onChange={this.updateQuestionProps}
                />
                </Col>
                <Col>
                <p style={{marginBottom:"5px"}}><small>Button Name for Negative Choice</small></p>
                <Input type="text" name={"worstbuttonname"} id={this.state.cardcopy.id}
                value={this.state.questionprops.worstbuttonname}
                style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
                onChange={this.updateQuestionProps}
                />
                </Col>
            </Row>
            </div>
            )
            }

        if (this.state.questionprops.selectiontype === "allocation"){
            return(
                <Row style={{marginTop:"25px"}}>
                <Col>
                <p style={{marginBottom:"5px"}}><small>Numeric Amount to Allocate</small></p>
                <Input type="number" name={"allocationamount"} id={this.state.cardcopy.id}
                value={this.state.cardcopy.allocationamount}
                style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
                onChange={this.updateItemProps}
                />
                </Col>
                <Col>
                <p style={{marginBottom:"5px"}}><small>Your Allocation Field</small></p>
                <Input 
                name={this.state.cardcopy.id}
                type={"number"}
                max={this.state.validallocationamount} min="0"
                value={this.state.selectvalue[this.state.cardcopy.id]}
                onBlur={this.handleAllocationChange}/>
                </Col>
                </Row>
            )
        }
        if (this.state.questionprops.selectiontype === "toggle"){
            return(
                <div>
            <p style={{marginBottom:"5px"}}><small>Your Toggle Button</small></p>
                <Toggle
                id={this.state.cardcopy.id}
                value={this.state.cardcopy.togglevalue}
                checked= {this.state.selectvalue[this.state.cardcopy.id]}
                icons={{unchecked: null}}
                className = {this.state.cardcopy.togglestyle}
                onChange = {() => this.handleToggleSelectionChange()}
            />
            </div>
            )
        }
        if (this.state.questionprops.selectiontype === "textfield"){
            return(
                <div>
            <p style={{marginBottom:"5px"}}><small>Your Text Input Field</small></p>
            <Input 
            name={this.state.cardcopy.id}
            type={"text"} 
            value={this.state.selectvalue[this.state.cardcopy.id]}
            onChange={this.handleFieldChange}/>
            </div>
            )
        }
        if (this.state.questionprops.selectiontype === "textarea"){
        return(
            <div>
            <p style={{marginBottom:"5px"}}><small>Your Text Area Input Field</small></p>
            <Input 
            name={this.state.cardcopy.id}
            type={"textarea"} 
            value={this.state.selectvalue[this.state.cardcopy.id]}
            onChange={this.handleFieldChange}/>
            </div>
        )
        }

        if (this.state.questionprops.selectiontype === "checkbox"){
            let defaultvals = {}
            let defaultchecks = []
            for (let i in this.state.selectvalue[this.state.cardcopy.id]){
            if(this.state.selectvalue[this.state.cardcopy.id][i]===true){
                defaultchecks.push(i)
            }
            defaultvals[this.state.cardcopy.id]=defaultchecks
            }
            return(
            <Row>
            <Col>
                    <p style={{marginBottom:"5px"}}><small>Add/Edit Checkbox Options</small></p>
                    <CreatableSelect
                    isMulti
                    defaultValue={this.state.cardcopy.selectoptions}
                    onChange={this.updateOptions}
                    options={this.state.cardcopy.selectoptions}
                    name={this.state.cardcopy.id}
                    styles={customStyles2(true)}
                    />
                </Col>
                <Col>
                    <p style={{marginBottom:"5px"}}><small>Your Checkbox Widget</small></p>
                    <Form>
                        <FormGroup inline name={this.state.cardcopy.id} onChange={this.handleRadioCheckChange}>
                            {this.state.cardcopy.selectoptions.map !== null &&
                            this.state.cardcopy.selectoptions.map((item, key=item.id)=>this.getSelectItems(item, "checkbox"))
                            }
                        </FormGroup>
                    </Form>
                </Col>
            </Row>

            )
        }

        if (this.state.questionprops.selectiontype === "radio"){
            let defaultvals = {}
            for (let i in this.state.selectvalue[this.state.cardcopy.id]){
            if(this.state.selectvalue[this.state.cardcopy.id][i]===true){
                defaultvals[this.state.cardcopy.id]=i
            }
            }
            return(
            <Row>
            <Col>
                    <p style={{marginBottom:"5px"}}><small>Add/Edit Checkbox Options</small></p>
                    <CreatableSelect
                    isMulti
                    defaultValue={this.state.cardcopy.selectoptions}
                    onChange={this.updateOptions}
                    options={this.state.cardcopy.selectoptions}
                    name={this.state.cardcopy.id}
                    styles={customStyles2(true)}
                    />
                </Col>
                <Col>
                    <p style={{marginBottom:"5px"}}><small>Your Radio Button Widget</small></p>
                    <Form>
                        <FormGroup inline name={this.state.cardcopy.id} onChange={this.handleRadioCheckChange}>
                            {this.state.cardcopy.selectoptions.map !== null &&
                            this.state.cardcopy.selectoptions.map((item, key=item.id)=>this.getSelectItems(item, "radio"))
                            }
                        </FormGroup>
                    </Form>
                </Col>
            </Row>

            )
        }

        if (this.state.questionprops.selectiontype === "slider"){
            return(
            <div style={{  margin: 20 }}>
            <p style={{marginBottom:"5px"}}><small>Your Slider Widget</small></p>
            <Slider 
            marks={this.state.cardcopy.slidermarks}
            min={this.state.cardcopy.slidermin}
            max={this.state.cardcopy.slidermax}
            step={this.state.cardcopy.sliderstep}
            defaultValue={this.state.selectvalue[this.state.cardcopy.id]}
            onAfterChange={this.handleSliderChange}
            dotStyle={{borderColor: '#c8ced3'}}
            activeDotStyle={{borderColor: '#c8ced3'}}
            />
                <Row style={{marginTop:"30px"}}>
                <Col>
                <p style={{marginBottom:"5px"}}><small>Min</small></p>
                <Input type="number" max={this.state.cardcopy.slidermax} name={"slidermin"} id={this.state.cardcopy.id}
                value={this.state.cardcopy.slidermin}
                style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
                onChange={this.updateItemProps}
                />
                </Col>
                <Col>
                <p style={{marginBottom:"5px"}}><small>Max</small></p>
                <Input type="number" min={this.state.cardcopy.slidermin} name={"slidermax"} id={this.state.cardcopy.id}
                value={this.state.cardcopy.slidermax}
                style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
                onChange={this.updateItemProps}
                />
                </Col>
                <Col>
                <p style={{marginBottom:"5px"}}><small>Step</small></p>
                <Input type="mumber" max={this.state.cardcopy.slidermax} name={"sliderstep"} id={this.state.cardcopy.id}
                value={this.state.cardcopy.sliderstep}
                style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
                onChange={this.updateItemProps}
                />
                </Col>
                </Row>

                {this.getSliderMarks()}

        
            </div>
            )
        }
        if (this.state.questionprops.selectiontype === "range"){
            return(
            <div style={{  margin: 20 }}>
            <p style={{marginBottom:"5px"}}><small>Your Range Widget</small></p>
                <Range 
                marks={this.state.cardcopy.slidermarks}
                min={this.state.cardcopy.slidermin}
                max={this.state.cardcopy.slidermax}
                step={this.state.cardcopy.sliderstep} 
                defaultValue={this.state.selectvalue[this.state.cardcopy.id]}
                tipFormatter={value => `${value}`}
                onAfterChange={this.handleSliderChange}
                />
                <Row style={{marginTop:"25px"}}>
                <Col>
                <p><small>Min</small></p>
                <Input type="number" max={this.state.cardcopy.slidermax} name={"slidermin"} id={this.state.cardcopy.id}
                value={this.state.cardcopy.slidermin}
                style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
                onChange={this.updateItemProps}
                />
                </Col>
                <Col>
                <p><small>Max</small></p>
                <Input type="number" min={this.state.cardcopy.slidermin} name={"slidermax"} id={this.state.cardcopy.id}
                value={this.state.cardcopy.slidermax}
                style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
                onChange={this.updateItemProps}
                />
                </Col>
                <Col>
                <p><small>Step</small></p>
                <Input type="mumber" max={this.state.cardcopy.slidermax} name={"sliderstep"} id={this.state.cardcopy.id}
                value={this.state.cardcopy.sliderstep}
                style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
                onChange={this.updateItemProps}
                />
                </Col>
                </Row>

                {this.getSliderMarks()}


            </div>
            )
        }


    }
    

  render(){

    return(
    <div>
        <ModalHeader style={{backgroundColor:"#2f353a", color:"#8f9ba6", fontSize:"1.2em", fontWeight:"200"}}>
            {`Properties for card ${this.props.currentcard} `}
            {this.getIfRandom()}
            {this.state.isdeleting &&
            <p style={{color:'#f86c6b', fontSize:"0.8em", fontWeight:"200", marginTop:"0px"}}>Please confirm that you want to delete this card and all it's contents</p>
            }
            </ModalHeader>
        <ModalBody>

        <div style={{width:"100%"}}>
          
      <div style={{display:"flex", alignItems:"stretch", flexWrap: "wrap"}}>

      <Card 
      key={this.props.page.questions[0].questionId}
      style={{cursor:'pointer', 
          backgroundColor:this.props.surveyprops.style.formbodycolor,
          color:this.props.surveyprops.style.formcardtextcolor,
          width:"100%"
        }}>
          
          {this.state.cardcopy.showheader &&
            <CardHeader className="survey-form-card-header" style={{backgroundColor:this.props.surveyprops.style.formheadcolor, color:this.props.surveyprops.style.formheadtextcolor}}>
                <Input 
                    name={"optionheader"}
                    value={this.state.cardcopy.optionheader}
                    placeholder={"Design card header..."}
                    type={"text"}
                    onChange={this.handleFieldPropChange}
                    style={{backgroundColor:"transparent", borderColor:"#8f9ba640", color:this.props.surveyprops.style.formheadtextcolor}}  
                    />
            </CardHeader>
          }
      
        
        <CardBody style={{backgroundColor: this.state.questionprops.noselectcardcolor}}>

        <div style={{marginTop:"15px"}}>
        <Annotation
                src={this.state.cardcopy.imageSrc}
                alt='pricekit ux design image'
                annotations={this.state.cardcopy.annotations}
                allowTouch={true}
                value={this.state.annotation}
                onChange={this.onChange}
                onSubmit={this.onSubmit}
                disableOverlay={this.state.disableannotation}
                disableEditor={this.state.disableannotation}
                disableSelector={this.state.disableannotation}

                />
        </div>
        <div>
            <p style={{marginBottom:"5px"}}><small>Design Image</small></p>
            <Input 
                    name={"imageSrc"}
                    value={this.state.cardcopy.imageSrc}
                    placeholder={"https://..."}
                    type={"text"}
                    onChange={this.handleFieldPropChange}
                    style={{fontSize:"0.8em", marginBottom:"5px",}}  
                    />
        </div>
        {this.state.editannotations &&
        <div style={{display:"flex", flexDirection:"column"}}>
            <ListGroup className="editor-content-item-modal">
                {this.state.annotations.map((item, key=item.id)=>this.renderAnnotationItem(item))}
            </ListGroup>
            </div>
        }

        </CardBody>


        {this.state.cardcopy.showfooter &&
        <CardFooter className="survey-form-card-footer" style={{backgroundColor:this.props.surveyprops.style.formfootcolor, color: this.props.surveyprops.style.formfoottextcolor, paddingBottom:"0px"}}>
            <Input 
                name={"helpmessage"}
                value={this.state.cardcopy.helpmessage}
                placeholder={"Instruction text..."}
                type={"text"}
                onChange={this.handleFieldPropChange}
                style={{backgroundColor:"transparent", fontSize:"0.8em", marginBottom:"5px", borderColor:"#8f9ba640", borderColor:"#8f9ba640", color:this.props.surveyprops.style.formfoottextcolor}}  
                />

            {this.state.editchoicetype &&
            this.renderSelectTypeInEditMode(this.state.questionprops.selectiontype)}

            {!this.state.editchoicetype &&
            this.renderSelectType(this.state.questionprops.selectiontype)}


        </CardFooter>
        }

      </Card>
      </div>
      {this.state.editfeatures && 
      <div style={{marginLeft:"5px"}}>
      <p>Edit Feature List</p>
      <Card 
      key={this.props.page.questions[0].questionId+"Features"}
      style={{cursor:'pointer', 
          backgroundColor:this.props.surveyprops.style.formbodycolor,
          color:this.props.surveyprops.style.formcardtextcolor,
          marginTop:"15px"
        }}
      >
        <CardHeader className="survey-form-card-header" style={{backgroundColor:this.props.surveyprops.style.formheadcolor, color:this.props.surveyprops.style.formheadtextcolor}}>
        <Input 
                name={"featureheader"}
                value={this.state.cardcopy.featureheader}
                placeholder={"Feature card header..."}
                type={"text"}
                onChange={this.handleFieldPropChange}
                style={{backgroundColor:"transparent", fontSize:"0.8em", marginBottom:"5px", borderColor:"#8f9ba640", borderColor:"#8f9ba640", color:this.props.surveyprops.style.formfoottextcolor}}  
                />
        </CardHeader>
        <CardBody>

            {this.state.cardcopy.showfeatures &&  

                this.getCardFeatures()
            }

      </CardBody>
      </Card>
      </div>
      }
     
      </div>
      <div style={{ padding:"30px", marginLeft:"-16px", marginRight:"-16px", marginBottom:"-16px", marginTop:"50px", color:"white", fontSize:"0.8em", backgroundColor:"#2f353a" }}>
                

                <Row style={{display:"flex", alignItems:"flex-end"}}>
                    
                    <Col>
                    <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Disable user Annotation</p>
                    <Toggle
                        id={"disableannotation"}
                        checked= {this.state.disableannotation}
                        icons={{unchecked: null}}
                        className = {"custom-success"}
                        onChange = {() => this.handleToggleChange('disableannotation')}
                            />
                    </Col>

                    <Col>
                    <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Show default Annotations</p>
                    <Toggle
                        id={"showannotation"}
                        checked= {this.state.showannotation}
                        icons={{unchecked: null}}
                        className = {"custom-success"}
                        onChange = {() => this.handleToggleChange('showannotation')}
                            />
                    </Col>
                    <Col>
                    <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Edit Annoations</p>
                    <Toggle
                        id={"editannotations"}
                        checked= {this.state.editannotations}
                        icons={{unchecked: null}}
                        className = {"custom-success"}
                        onChange = {() => this.handleToggleChange('editannotations')}
                            />
                    </Col>
                    <Col>
                    <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Clear All Annoations</p>
                    <Toggle
                        id={"clearannotations"}
                        checked= {this.state.clearannotations}
                        icons={{unchecked: null}}
                        className = {"custom-success"}
                        onChange = {() => this.handleToggleChange('clearannotations')}
                            />
                    </Col>
                    <Col>
                    <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Edit Features</p>
                    <Toggle
                        id={"editfeatures"}
                        checked= {this.state.editfeatures}
                        icons={{unchecked: null}}
                        className = {"custom-success"}
                        onChange = {() => this.handleToggleChange('editfeatures')}
                            />
                    </Col>

                </Row>
                
                <hr style={{borderTop: "1px", borderStyle:"dashed", borderColor:"#5c6873"}}></hr>

                <Row style={{display:"flex", alignItems:"flex-end", marginTop:"15px"}}>
                <Col>
                <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Edit Question Type</p>
                <Toggle
                    id={"editchoicetype"}
                    checked= {this.state.editchoicetype}
                    icons={{unchecked: null}}
                    className = {"custom-success"}
                    onChange = {() => this.handleToggleChange('editchoicetype')}
                        />
                </Col>
                <Col>
                <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Show Question</p>
                <Toggle
                    id={"showfooter"}
                    checked= {this.state.showfooter}
                    icons={{unchecked: null}}
                    className = {"custom-success"}
                    onChange = {() => this.handleToggleChange('showfooter')}
                        />
                </Col>
                <Col>
                <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Show Features</p>
                <Toggle
                    id={"showfeatures"}
                    checked= {this.state.showfeatures}
                    icons={{unchecked: null}}
                    className = {"custom-success"}
                    onChange = {() => this.handleToggleChange('showfeatures')}
                        />
                </Col>
                <Col>
                <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Randomize Features</p>
                <Toggle
                    id={"randomize"}
                    checked= {this.state.randomize}
                    icons={{unchecked: null}}
                    className = {"custom-success"}
                    onChange = {() => this.handleToggleChange('randomize')}
                        />
                </Col>

                <Col>
                <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Show Prices</p>
                <Toggle
                    id={"showprice"}
                    checked= {this.state.showprice}
                    icons={{unchecked: null}}
                    className = {"custom-success"}
                    onChange = {() => this.handleToggleChange('showprice')}
                        />
                </Col>

                </Row>

                {this.state.editchoicetype &&
                <div style={{marginTop:"15px"}}>
                    <Select
                    defaultValue={selectiontypes[this.state.selectiontypeindex]}
                    onChange={this.handleChoiceTypeChange}
                    options={selectiontypes}
                    styles={customStylesInModal(true)}
                    />
                </div>
                }

            </div>
        </ModalBody>
        <ModalFooter style={{marginTop:"0"}}>

        {!this.state.isdeleting &&
        <div style={{alignSelf:"flex-end", marginLight:"auto"}}>
        {'  '}
        <Button color="primary" onClick={()=>this.saveCard()} >Save</Button>
        {'  '}
        <Button color="secondary" onClick={()=>this.props.toggleModal()} >Cancel</Button>
        </div>
        }
        </ModalFooter>
  </div>



    );
  }
}
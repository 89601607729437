import React, { Component } from "react";
import { Button, Card, CardBody, CardGroup, CardHeader, Col, Container, Row } from 'reactstrap';


function truncateString(str, num) {
  if (typeof str === 'undefined'){
    return "..."
  }
  if (str === ""){
    return "..."
  }
  if (str === null){
    return "..."
  }
  if (str.length <= num) {
    return str
  }
  return str.slice(0, num) + '...'
}


export default class ResponseExpanderMenu extends Component {
    constructor(props) {
      super(props);
      let details = []
      for (let r in this.props.allresponses){
        if (this.props.allresponses[r]['respondentId']===this.props.data.respondentId){
          details.push(this.props.allresponses[r])
        }
      }
      this.state = {
        details:details
      };
    }

    getSelectOptions(option){
      if(option !=="None" && option!== null){
        let optn = []
        for(let o in option){
          optn.push(option[o]['label'])
        }
      return(
      <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-end",marginLeft:"15px"}}>
      <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Selection Options</small></p>
      <p style={{color:'#5c6873'}}><small>{JSON.stringify(optn)}</small></p>
      </div>
      )
      }
    }

    getShowIf(showif){
      if(showif['id'] !=="None" && showif['id'] !== null){
        return(
    <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-end",marginLeft:"15px"}}>
    <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Show If</small></p>
    <p style={{color:'#5c6873'}}><small>{`${showif['id']} ${showif['value']}`}</small></p>
    </div>
        )
      }
    }


    getFeatures(feature){
      if(feature !=="None" && feature !== null){
        let ft = []
        let h = ""
        for(let f in feature){
          if(feature[f]['header']!==null){
            h = truncateString(feature[f]['header'],10)
            ft.push(`${h}`)
          }
        }
        return(
    <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-end",marginLeft:"15px"}}>
    <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Features</small></p>
    <p style={{color:'#5c6873'}}><small>{JSON.stringify(ft)}</small></p>
    </div>
        )
      }
    }


    getDetails(detail){

      if(["jumbotron", "jumbotron2", "img", "bigimg", "contentlist", "gif", "video"].includes(detail['type'])){ return }
      else {
      return(
        <div>
        <Row>
        <div style={{marginLeft:"15px", marginRight:"15px", marginTop:"10px", display:"flex", justifyContent:"space-around"}}>
  
        <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-end",marginLeft:"15px"}}>
        <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Question Id</small></p>
        <p style={{color:'#5c6873'}}><small>{detail['id']}</small></p>
        </div>

        <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-end",marginLeft:"15px"}}>
        <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Page Type</small></p>
        <p style={{color:'#5c6873'}}><small>{detail['pagetype']}</small></p>
        </div>

        <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-end",marginLeft:"15px"}}>
        <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Question Type</small></p>
        <p style={{color:'#5c6873'}}><small>{detail['type']}</small></p>
        </div>

            {detail['pagetype'] === "SurveyList" &&
            <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-end",marginLeft:"15px"}}>
            <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Question</small></p>
            <p style={{color:'#5c6873', fontWeight:"800"}}><small>{detail['questionheader']}</small></p>
            </div>
            }

            {detail['pagetype'] === "ChoiceCard" &&
            <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-end",marginLeft:"15px"}}>
            <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Question</small></p>
            <p style={{color:'#5c6873'}}><small>{detail['optionheader']}</small></p>
            </div>
            }
            {detail['pagetype'] === "DesignCard" &&
            <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-end",marginLeft:"15px"}}>
            <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Question</small></p>
            <p style={{color:'#5c6873'}}><small>{detail['optionheader']}</small></p>
            </div>
            }


            {detail['pagetype'] === "SurveyCard"  &&
            <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-end",marginLeft:"15px"}}>
            <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Question</small></p>
            <p style={{color:'#5c6873'}}><small>{detail['header']}</small></p>
            </div>
            }

            {detail['answer']!== null &&
            <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-end",marginLeft:"15px"}}>
            <p style={{color:'#2f353a', fontWeight:"500", marginBottom:"5px"}}><small>Answer</small></p>
            <p style={{color:'#5c6873'}}><small><mark>{JSON.stringify(detail['answer'])}</mark></small></p>
            </div>
            }

            {detail['answer']=== null &&
            <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-end",marginLeft:"15px"}}>
            <p style={{color:'#2f353a', fontWeight:"500", marginBottom:"5px"}}><small>Answer</small></p>
            <p style={{color:'#f86c6b'}}><small>{"None"}</small></p>
            </div>
            }

        </div>
        </Row>
        <Row>
        <div style={{marginLeft:"15px", marginRight:"15px", display:"flex", justifyContent:"space-around"}}>

        {detail['pagetype'] !== "SurveyCard" &&
          <div style={{display:"flex"}}>
          <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-end",marginLeft:"15px"}}>
          <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Price</small></p>
          <p style={{color:'#5c6873'}}><small>{detail['price']}</small></p>
          </div>

          {detail['pricemetric'] !== null &&
          <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-end",marginLeft:"15px"}}>
          <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Price Metric</small></p>
          <p style={{color:'#5c6873'}}><small>{detail['pricemetric']}</small></p>
          </div>
          }

          <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-end",marginLeft:"15px"}}>
          <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Show Price</small></p>
          <p style={{color:'#5c6873'}}><small>{detail['showprice'].toString()}</small></p>
          </div>

          <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-end",marginLeft:"15px"}}>
          <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Randomize</small></p>
          <p style={{color:'#5c6873'}}><small>{detail['randomize'].toString()}</small></p>
          </div>
          </div>
        }

        {this.getSelectOptions(detail['selectoptions'])}

        {detail['pagetype']==="SurveyCard" &&

        this.getShowIf(detail['showif'])

        }

        {detail['pagetype']==="SurveyCard" &&
        <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-end",marginLeft:"15px"}}>
        <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Validation Type</small></p>
        <p style={{color:'#5c6873'}}><small>{detail['validationtype']}</small></p>
        </div>
        }

        <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-end",marginLeft:"15px"}}>
        <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Slider Min</small></p>
        <p style={{color:'#5c6873'}}><small>{detail['slidermin']}</small></p>
        </div>

        <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-end",marginLeft:"15px"}}>
        <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Slider Max</small></p>
        <p style={{color:'#5c6873'}}><small>{detail['slidermax']}</small></p>
        </div>

        <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-end",marginLeft:"15px"}}>
        <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Allocation Amount</small></p>
        <p style={{color:'#5c6873'}}><small>{detail['allocationamount']}</small></p>
        </div>

        {detail['pagetype']==="ChoiceCard" || detail['pagetype']==="DesignCard" &&
        
        this.getFeatures(detail['features'])
        
        }

      {detail['pagetype']==="DesignCard" &&
        <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-end",marginLeft:"15px"}}>
        <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Annotations</small></p>
        <p style={{color:'#5c6873'}}><small>{JSON.stringify(detail['annotations'])}</small></p>
        </div>
        }

        </div>
  
        </Row>
        <hr/>
        </div>
      )
    }
  }

    render() {
        return (
          <div style={{backgroundColor:"#f0f3f5", marginLeft:"50px"}}>

            {this.state.details.map((detail, key=detail.id) => (this.getDetails(detail, key)))}


          </div>
      );
    }
}
import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Card, CardBody, CardHeader, CardFooter, Input, Nav, NavLink, Jumbotron} from 'reactstrap';
import RenderContent from './RenderContent'
import ReactTooltip from 'react-tooltip'


const menulist = [{id:"MENU1", name:"select"},{id:"MENU2", name:"toggle"},{id:"MENU3", name:"form"},{id:"MENU4", name:"check"},{id:"MENU5", name:"slider"},{id:"MENU6", name:"img"},{id:"MENU7", name:"video"},{id:"MENU8", name:"list"},{id:"MENU9", name:"jumbo"},{id:"MENU10", name:"text"}]

const dragtip = 'Drag onto page to add.'

const defaultitemprops = {
    'id': null,
    'type': null,
    'value': "",
    'initial': "",
    'style': "",
    'validationtype': "none",
    'header': "",
    'showif': {"id":null, "value": null},
    'selectoptions': [],
    'text': "",
    'texttype': "",
    'slidermin': 0,
    'slidermax': 100,
    'sliderstep': 25,
    'sliderdefault': null,
    'slidermarks':  { 0: "low", 25: "", 50: "medium", 75: "", 100: "high" },
    'rangedefault': [0,100],
    'headline': "",
    'subheadline': "",
    'bodytext': "",
    'list': [],
    'gif': 'https://media.giphy.com/media/10sg105xBIeQfu/giphy.gif',
    'video': 'https://vimeo.com/87110435',
    'img': 'https://pricekit-survey.s3.amazonaws.com/public/Default/pricekit-placeholder-image.png',
    'imageTitle':null,
    'imageCaption':null,
    'isNew': true
    }

/// implements the drag and drop functionality of the canvas

const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding:'0px',
    margin:'0px',
    background: isDragging ? 'rgba(32, 168, 216, 0.1)' : 'transparent',
    ...draggableStyle
});

const getCardStyle = isDraggingOver => ({
    background: isDraggingOver ? 'rgba(240, 243, 245, 0.9)' : 'transparent',
    padding:'0px',
});

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, removed);
    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

function makeId(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }

export default class RenderCards extends Component {
    constructor(props) {
        super(props);
        this.passThruStateChange = this.passThruStateChange.bind(this)
        this.handlePageValid = this.handlePageValid.bind(this)
        let mwidth = 1
        if (typeof this.props.cards.length !== 'undefined'){mwidth = 1/this.props.cards.length}
        let usablewidth = vw - 300
        mwidth = mwidth * usablewidth
        let widthprop = ""
        widthprop = mwidth + "px"
        if(vw < 600){
          mwidth = "100%"
        }
        this.changeSelection = this.changeSelection.bind(this)
        this.toggleRadioCheck = this.toggleRadioCheck.bind(this)
        let validfields = {}
        let validtype = {}
        let initialselections = {}
        let initializefalse = ['checkbox', 'radio']
        let initialforms = this.props.cards
        if (typeof this.props.savedpages != "undefined") {
            if(this.props.savedpages===true){
                initialselections = this.props.savedresponses['selectvalue']
                initialforms = this.props.savedresponses['cards']
            }
            } else {
    
                    for (let i of this.props.cards){
                        for(let j of i['content']){
                            let radiocheck = {}
                            let fieldid = j['id']
                            initialselections[fieldid] = null
                            validfields[fieldid]= false
                            validtype[fieldid]= j['validationtype']
                            if(j['validationtype']==='none'){validfields[fieldid]= true}
                            if(j['type']==='toggle'){initialselections[fieldid] = false}
                            if(j['type']==='slider'){initialselections[fieldid] = null}
                            if(j['type']==='range'){initialselections[fieldid] = [0,100]}
                            if(j['type']==='allocation'){initialselections[fieldid] = 0}
                            if(initializefalse.includes(j['type']))
                                {
                                for (let s of j['selectoptions']){
                                    radiocheck[s['value']]=false
                                }
                                initialselections[fieldid]=radiocheck
                                }
                    }
                    }
                    }
                    
        this.state = {
            selectvalue: initialselections,
            isvalid: false,
            validfields: validfields,
            validtype: validtype,
            mwidth: widthprop,
            showImage: false,
            vw:vw,
          };
        };

/// save state on exit, so if you go back and review this page the content is available to see
componentWillUnmount(){
this.props.stateChangePassThru(this.props.questionId, this.state)
}


/// reset card width if new or deleted card
componentDidUpdate(prevProps, prevState){
    let mwidth = 1
    if (typeof this.props.cards.length !== 'undefined'){
        if(this.props.cards.length !==prevProps.cards.length){
            mwidth = 1/this.props.cards.length
            let usablewidth = vw - 300
            mwidth = mwidth * usablewidth
            let widthprop = ""
            widthprop = mwidth + "px"
            if(vw < 600){mwidth = "100%"}
        this.setState({mwidth:widthprop})
    }
    }
    }


    passThruStateChange(p, s){
        this.props.saveStateOnChange(p, s)
    }

    handlePageValid(v){
        this.props.onPageValid(v)
    }

    /// looks to see if any of the fields have a false validation setting.  called on each input change
    validateAllFields(){
    let checkitems = Object.values(this.state.validfields)
    let validcheck = checkitems.includes(false)
    this.setState({isvalid: !validcheck}, () => {this.handlePageValid(this.state.isvalid)})
        }

    /// updates users input for everything except radio/check.  i is id;  e is the value
    changeSelection(i, e){
        let fieldvalid = false
        if(this.state.validtype[i] !== "none"){
            if(e != null && e != false){fieldvalid = true}
        } else {fieldvalid =true}

        this.setState(prevState => ({selectvalue: {...prevState.selectvalue, [i]: e}}), )
        this.setState(prevState => ({validfields: {...prevState.validfields, [i]: fieldvalid}}), () => {this.validateAllFields()} )
    }
  
    /// looks inside the dict containing the various radio/check options to see if any are true
    validateRadioCheck(i){
        let validcheck = true
        if(this.state.validtype[i] !== "none"){
            const entries = Object.values(this.state.selectvalue[i])
            const entriesvalid = entries.includes(true)
            if (entriesvalid ===false){validcheck = false}
            }
        this.setState(prevState => ({validfields: {...prevState.validfields, [i]: validcheck}}), () => {this.validateAllFields()} )
        }
    
    /// updates the radio/check option bool toggle.  i is id;  e is the value;  t is type
    toggleRadioCheck(i, e, t){

        if(t==='radio'){
            let newvals = this.state.selectvalue[i]
            for (let p in newvals)
                {
                newvals[p]=false
                }
            newvals[e]=true

            this.setState(prevState => ({
                selectvalue: {
                ...prevState.selectvalue,          
                [i]: newvals
                }
                }), () => {this.validateRadioCheck(i)}
                )
        } else {

            this.setState(prevState => ({
                selectvalue: {
                ...prevState.selectvalue,          
                [i]: {                     
                ...prevState.selectvalue[i], 
                [e]: !this.state.selectvalue[i][e]         
                }
                }
                }), () => {this.validateRadioCheck(i)}
                )
            }
    }

    /// the overall drag and drop function
    onDragEnd = result => {

        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {return;}

        /// handle case where dropping a menu item i.e., to create new versus moving an existing item
        if(source.droppableId==="cardmenu"){
            if (destination.droppableId !== "cardmenu"){
               this.newItem(destination.droppableId, destination.index, result.draggableId)
            }
        }

        /// moving and reordering of items amongst items between/within cards on same page
        if (source.droppableId !=="cardmenu"){
        
        this.props.activeCard(destination.droppableId)

        let sourceindex = null
        let destindex = null
        for (const x in this.props.cards){
            if (this.props.cards[x].id===source.droppableId){
                sourceindex = x
            }
            if (this.props.cards[x].id===destination.droppableId){
                destindex = x
            }
        }

        // case of movement in same list
        if (source.droppableId === destination.droppableId) {

            // case of same list and same index in array
            if (source.index === destination.index)
                { 
                    return;

                } else {

                const result = reorder(
                    this.props.cards[destindex].content,
                    source.index,
                    destination.index
                    );

                    let statecopy = [...this.props.cards]
                    statecopy[destindex].content = result
        
                    this.setState({cards: statecopy})
                }

        // case if different lists
        } else {

            const result = move(
                this.props.cards[sourceindex].content,
                this.props.cards[destindex].content,
                source,
                destination
                );

            
            let statecopy = [...this.props.cards]
            statecopy[sourceindex].content = result[source.droppableId]
            statecopy[destindex].content = result[destination.droppableId]


            this.setState({cards: statecopy})
        }
        }

    }

    /// tracking what items and cards are most recently clicked e.g., active
    activeItem(action, id){
        let item = {}
        item['id'] = id
        item['action'] = action
        this.props.activeCard(item)
    }

    /// creates the droppable list that is the menu items for form-content elements to add to a card
    getMenuContainer(){

        return(
            <Droppable droppableId={"cardmenu"} isDropDisabled={true}>

            {(provided, snapshot) => (
                <div
                ref={provided.innerRef}
                style={getCardStyle(snapshot.isDraggingOver)}
                >

            <div style={{display:"flex", flexDirection:"column", justifyContent:"space-around", alignItems:"stretch", flexGrow:"1"}}>
            {menulist.map((item, index) => (this.getMenuItem(item, index)))}
            </div>
        
            {provided.placeholder}
  
            </div>
            )}

            </Droppable>



        )

    }

    /// corresponding menu draggable items used to add new things to a card
    getMenuItem(item, index){
            let cname = null
            switch(item.name) {
                case "select":
                 cname = "fa fa-caret-square-o-down"
                  break;
                case "toggle":
                    cname = "fa fa-toggle-off"
                  break;
                  case "form":
                    cname = "fa fa-pencil-square-o"
                    break;
                  case "check":
                    cname = "fa fa-check-square-o"
                    break;        
                case "slider":
                    cname = "fa fa-arrows-h"
                    break;
                  case "img":
                    cname = "fa fa-picture-o"
                    break;        
                case "video":
                    cname = "fa fa-youtube-play"
                    break;
                case "list":
                    cname = "fa fa-list-ul"
                    break;
                case "jumbo":
                    cname = "fa fa-text-width"
                    break;
                case "text":
                    cname = "fa fa-font"
                    break;
                default:
                  // code block
              }
              return(
                    <Draggable
                    key={item.id}
                    draggableId={item.id}
                    index={index}
                    type="item">
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                            )}>
                            <div style={{padding:"0", margin:"0"}} data-place="right" data-tip={dragtip} >
        
                            <span >
                            <i className={cname} style={{color:"#8f9ba6", fontSize:"1.5em"}} ></i>
                            <p style={{fontSize:"0.8em"}}>{item.name}</p>
                            </span>
        
                            </div>
                        </div>
                    )}
                    </Draggable>
            )
    }

    /// gets a draggable item for the card page - these are existing questions 
    getItem(item, index){
        return(
            <Draggable
            key={item.id}
            draggableId={item.id}
            index={index}
            type="item">
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                    )}>
                    <div style={{display: "flex", justifyContent:"flex-start", cursor:'pointer', padding:"0", margin:"0", height:"100%"}}>

                    <span {...provided.dragHandleProps}><i className="icon-frame" style={{color:"#8f9ba6", fontSize:"0.8em", marginRight:"5px"}} ></i></span>

                    <RenderContent
                        key={item['id']}
                        content={item}
                        maxcardwidth={this.state.cardwidth}
                        surveyprops={this.props.surveyprops}
                        selectvalue={this.state.selectvalue}
                        questionprops={this.props.questionprops}
                        questionId={this.props.questionId}
                        onSelectionChange={this.changeSelection}
                        onRadioCheckToggle={this.toggleRadioCheck}
                        toggleLightbox={this.props.toggleLightbox}
                        activeQuestion={this.props.activeQuestion}
                        currentquestion={this.props.currentquestion}
                        currentcard={this.props.currentcard}
                        currentpage={this.props.currentpage}
                        updateContentHeaders={this.props.updateContentHeaders}
                        />
 

                    </div>
                </div>
            )}
        </Draggable>
        )
    }

    /// gets a droppable area - these are the cards where existing questions are
    getCard(card, key){

        const typelist = ['SurveyCard']

        if (typelist.includes(this.props.questiontype)){
            return(
                <Droppable droppableId={card.id} key={key}>

                {(provided, snapshot) => (
                    <div
                    ref={provided.innerRef}
                    style={getCardStyle(snapshot.isDraggingOver)}
                    >

                    <Card className="survey-form-card" 
                        onClick={()=>this.props.activeCard(card.id)}
                        style={{cursor:'pointer', 
                            backgroundColor:this.props.surveyprops.style.formbodycolor,
                            color:this.props.surveyprops.style.formcardtextcolor,
                            height:"100%",
                            flexGrow: "0",
                            flexShrink: "1",
                            flexWrap: "wrap",
                            width: this.state.mwidth,
                            borderColor: this.getActiveCardStyle(card.id),
                            minWidth:this.props.surveyprops.style.mincardwidth}}
                            key={key}
                            >
                        
                        {card.showheader &&
                        <CardHeader className="survey-form-card-header" style={{backgroundColor:this.props.surveyprops.style.formheadcolor, color:this.props.surveyprops.style.formheadtextcolor}}>                    
                            <Input 
                                name={"optionheader"}
                                value={card.optionheader}
                                placeholder={"Card Title..."}
                                type={"text"}
                                onChange={this.updateCardHeaders}
                                style={{backgroundColor:"transparent", borderColor:"#8f9ba640", color:this.props.surveyprops.style.formheadtextcolor}}  
                                />
                            </CardHeader>
                        } 

                        <CardBody  style={{color:this.props.surveyprops.formcardtextcolor, marginBottom:"15px"}}>
                        
                            {typeof card.content !== 'undefined' && card.content.map((item, index) => (this.getItem(item, index)))}
            
                            {provided.placeholder}


                        </CardBody>
                    
                        {card.showfooter &&
                        <CardFooter className="survey-form-card-footer" style={{backgroundColor:this.props.surveyprops.style.formfootcolor, color: this.props.surveyprops.style.formfoottextcolor, paddingBottom:"0px"}}>
                            <Input 
                                name={"helpmessage"}
                                value={card.helpmessage}
                                type={"text"}
                                onChange={this.updateCardHeaders}
                                style={{backgroundColor:"transparent", fontSize:"0.8em", marginBottom:"5px", borderColor:"#8f9ba640", borderColor:"#8f9ba640", color:this.props.surveyprops.style.formfoottextcolor}}  
                                />
                        </CardFooter>
                        }
                    
                    </Card>    
                    </div>
                )}

                </Droppable>

            )}
    }

    /// triggers a change in border color when card is selected
    getActiveCardStyle(activeId){
        if (activeId===this.props.currentcard)
         {return '#20a8d8'}
        else {return '#c8ced3'}
    }

    /// creates a new card on the canvas
    newCard(){
        console.log('creating new card')
        let optionheader = ""
        let showheader = true
        let content = []
        let showfooter = false
        let helpmessage = ""
        let id = makeId(5)
        let card = {}
        card.optionheader = optionheader
        card.showheader = showheader
        card.content = content
        card.showfooter = showfooter
        card.helpmessage = helpmessage
        card.id = id
        let copycards = [...this.props.cards]
        copycards.push(card)
        this.props.newCard(copycards)
    }

    /// how does this back propogate -- only local state updated??
    newItem(destination, destindex, type){
        console.log('adding a new item to the page', type)
        let cardindex = null
        for (const x in this.props.cards){
            if(this.props.cards[x].id === destination){
                cardindex = x
            }
        }
        let item = {}
        item['id']= makeId(5)
        item['type']= defaultitemprops['type']
        item['value']= defaultitemprops['value']
        item['initial']= defaultitemprops['initial']
        item['style']= defaultitemprops['style']
        item['validationtype']= defaultitemprops['validationtype']
        item['header']= defaultitemprops['header']
        item['showif']= defaultitemprops['showif']
        item['selectoptions']= defaultitemprops['selectoptions']
        item['text']= ""
        item['texttype']=""
        item['slidermin']=0
        item['slidermax']= 100
        item['sliderstep']= 25
        item['sliderdefault']= 0
        item['rangedefault']=[0,100]
        item['slidermarks']= { 0: "A", 25: "B", 50: "C", 75: "D", 100: "E" }
        item['headline']= ""
        item['subheadline']= ""
        item['bodytext']= ""
        item['list']= null
        item['isNew']= true
        item['gif']= ""
        item['video']= ""
        item['img']= ""
        item['imageTitle']= ""
        item['imageCaption']= ""
        let questiontype = null
        let initializevalue = false
        switch(type) {
            case "MENU1":
                questiontype = "select"
              break;
            case "MENU2":
                questiontype = "toggle"
              break;
              case "MENU3":
                questiontype = "textfield"
                item['texttype']= defaultitemprops['texttype']
                initializevalue = ""
                break;
              case "MENU4":
                questiontype = "checkbox"
                break;        
            case "MENU5":
                questiontype = "slider"
                item['slidermin']=defaultitemprops['slidermin']
                item['slidermax']= defaultitemprops['slidermax']
                item['sliderstep']= defaultitemprops['sliderstep']
                item['sliderdefault']= defaultitemprops['sliderdefault']
                item['slidermarks']= defaultitemprops['slidermarks']
                break;
              case "MENU6":
                questiontype = "img"
                item['img']= defaultitemprops['img']
                item['imageTitle']= defaultitemprops['imageTitle']
                item['imageCaption']= defaultitemprops['imageCaption']
                break;        
            case "MENU7":
                questiontype = "video"
                item['gif']= defaultitemprops['gif']
                item['video']= defaultitemprops['video']
                break;
            case "MENU8":
                questiontype = "contentlist"
                item['list']= defaultitemprops['list']
                break;
            case "MENU9":
                questiontype = "jumbotron2"
                item['headline']= defaultitemprops['headline']
                item['subheadline']= defaultitemprops['subheadline']
                item['bodytext']= defaultitemprops['bodytext']
                break;
            case "MENU10":
                questiontype = "html"
                item['text']= defaultitemprops['text']
                initializevalue = ""
                break;
            default:
                questiontype = null
          }
          item['type']= questiontype
          let cardcopy = [...this.props.cards]
          let contentcopy = [...this.props.cards[cardindex].content]
          contentcopy.push(item)
          let sourceidx = contentcopy.length -1
          let result = reorder(contentcopy, sourceidx, destindex)
          cardcopy[cardindex].content =result
          this.setState(prevState => ({
           selectvalue: {...prevState.selectvalue,           
                [item['id']]:initializevalue},
            cards: cardcopy
                }))
    }

    updatePageTitle = (event) => {
        const target = event.target;
        if(target != null){
          let value = target.value;
          const name = target.name;
          const type = target.type
          let item = {}
          item['name']=name
          item['value']=value
          item['type']=type
          this.props.updatePageTitle(item)
        }
      }

    updateCardHeaders = (event) => {
    const target = event.target;
    if(target != null){
        let value = target.value;
        const name = target.name;
        const type = target.type
        let item = {}
        item['name']=name
        item['value']=value
        item['type']=type
        this.props.updateCardHeaders(item)
    }
    }


    render() {
        return (
        <div>
            <ReactTooltip />
            
            <div className = "survey-question-headings" style={{color:this.props.surveyprops.style.questionheadtextcolor}}>
                <div style={{display:"flex", width:"100"}}>

                {this.props.pageprops.showquestiontitle &&
                    <Input 
                        name={"questiontitle"}
                        placeholder={"Question Title..."}
                        value={this.props.pageprops.questiontitle}
                        onChange={this.updatePageTitle}
                        style={{width:"97%", fontSize:"1.7em", fontWeight:"500", borderColor:"#8f9ba640", backgroundColor:"transparent", color:this.props.surveyprops.style.questionheadtextcolor}}    
                        />
                }

                    {this.props.pageprops.showquestiontip &&
                    <a className="icon-info" style={{fontSize:"1.2em", marginLeft:"auto", marginRight:"10px", color:"#5c6873"}} data-place="left" data-tip={this.props.pageprops.questiontip}/>
                    }
                    </div>
                
                {this.props.pageprops.showquestionsubtitle &&
                    <Input 
                        name={"questionsubtitle"}
                        value={this.props.pageprops.questionsubtitle}
                        placeholder={"Question Subtitle..."}
                        type={"textarea"}
                        rows={"1"}
                        onChange={this.updatePageTitle}
                        style={{width:"97%", fontSize:"1.2em", fontWeight:"200", borderColor:"#8f9ba640", backgroundColor:"transparent", color:this.props.surveyprops.style.questionheadtextcolor}}  
                        />
                }
                </div>

            <DragDropContext onDragEnd={this.onDragEnd}>
            
            <div style={{display:"flex", flexDirection:"row", alignItems:"stretch"}}>

                <div style={{marginRight:"15px"}}>

                {this.getMenuContainer()}

                </div>

                
                <div style={{display:"flex", flexDirection:"row", alignItems:"stretch", flexGrow:"1"}} >



                    {this.props.cards.map((card, key=card.id, index) => (

                        this.getCard(card, key)
                    )
                    )}
                    {this.props.cards.length<9 &&
                    <span onClick={()=>{this.newCard()}} style={{marginLeft:"15px", alignSelf:"center", cursor:"pointer"}}><i className="icon-plus" style={{color:"#20a8d8", fontSize:"1em", marginRight:"5px"}} ></i></span>
                    }
                </div>

            </div>
            </DragDropContext>
        </div>


        );
    }
}
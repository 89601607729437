import React, { Component } from 'react';
import { Card, CardBody, CardFooter } from 'reactstrap';
import classNames from 'classnames';
import { mapToCssModules } from 'reactstrap/lib/utils';

export default class InfoCardSmall extends Component {

  render() {
    const { className, cssModule, header, mainText, icon, color, footer, link, children, variant, ...attributes } = this.props;

    const padding = (variant === '0' ? { card: 'p-3', icon: 'p-3', lead: 'mt-2' } : (variant === '1' ? {
      card: 'p-0', icon: 'p-4', lead: 'pt-3',
    } : { card: 'p-0', icon: 'p-4 px-5', lead: 'pt-3' }));

    const card = { style: 'clearfix', color: color, icon: icon, classes: '' };
    card.classes = mapToCssModules(classNames(className, card.style, padding.card), cssModule);

    const lead = { style: 'h1 mt-0', color:"dark", classes: '' };
    lead.classes = classNames(lead.style, 'text-' + lead.color, padding.lead);

    const blockIcon = function (icon) {
      const classes = classNames(icon, 'bg-' + card.color, padding.icon, 'font-2xl mr-3 float-left');
      return (<i className={classes}></i>);
    };

    return (
      <Card>
        <CardBody className={card.classes} {...attributes}>
            <div style={{display:"flex", flexWrap:"nowrap"}}>
          {blockIcon(card.icon)}
          <h1 className={lead.classes}>{header}</h1>
          </div>
        </CardBody>
      </Card>
    );
  }
}
import React, { Component } from "react";
import {  Button, Input, Row, Col, Card, CardBody, CardHeader, CardFooter, CardDeck, Jumbotron, Form, FormGroup, CustomInput} from 'reactstrap';
import Toggle from 'react-toggle'
import 'react-toggle/style.css'
import './SurveyPropsPage.scss';
import {BlockPicker} from 'react-color'
import ReactTooltip from 'react-tooltip'


    const defaultcolors = [
    '#000000', '#2f353a', '#c8ced3', '#f0f3f5','#FFFFFF',
    '#b71c1c', '#d32f2f',  '#f44336',  '#e57373', '#ffcdd2',
    '#ff6f00', '#ffa000',  '#ffc107', '#ffd54f',  '#ffecb3',
    '#827717', '#afb42b', '#cddc39', '#dce775', '#f0f4c3',
    '#33691e', '#689f38',  '#8bc34a',  '#aed581', '#dcedc8',
    '#006064', '#0097a7', '#00bcd4', '#4dd0e1', '#b2ebf2',
    '#0d47a1', '#1976D2',  '#2196F3', '#64B5F6', '#bbdefb',
    '#4a148c', '#7b1fa2',  '#9c27b0', '#ba68c8',  '#e1bee7',
    '#880e4f', '#c2185b',  '#e91e63', '#f06292', '#f8bbd0',
    '#3e2723',  '#5d4037',  '#795548',  '#a1887f', '#d7ccc8',
    '#263238', '#455a64', '#607d8b', '#90a4ae', '#cfd8dc',
    ]

    function makeId(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    function truncateString(str, num) {
        if (typeof str === 'undefined'){
        return "..."
        }
        if (str.length <= num) {
        return str
        }
        return str.slice(0, num) + '...'
    }

    const settingstip = `Click to open survey settings options.`

    const publishtip = `Click to open survey publish options.`

    const urltip = 'Click to open survey in new tab.'

export default class SurveyPropsPage extends Component {
  constructor(props) {
    super(props);
    let notvalid = false
    if(this.props.surveyprops.surveysettings.status === 'LIVE'){notvalid = true}
    let selectedpackages = {}
    for (let g in this.props.packages){
        selectedpackages[this.props.packages[g]['packageId']]=false
    }
    let packageoptions = JSON.parse(JSON.stringify(this.props.surveyprops.packageoptions))
    for(let p in packageoptions){
        selectedpackages[packageoptions[p]['id']]=true
    }
    this.state = {
        notValid:notvalid,
        showprops:false,
        activecolor: "#000000",
        activeitem: null,
        publishsurvey: false,
        editbuttons: false,
        stopsurvey: true,
        selectedpackages: selectedpackages,
        packageoptions: packageoptions,
        
      }
    }
    

    async componentDidMount() {
        let selectedpackages = {}
        let packageoptions = []
        if(!Array.isArray(this.props.surveyprops.packageoptions) || !this.props.surveyprops.packageoptions.length){
                for(let p in this.props.packages){
                    selectedpackages[this.props.packages[p]['packageId']]=true
                    packageoptions.push(JSON.parse(this.props.packages[p]['content']))
            }
        this.setState({selectedpackages:selectedpackages, packageoptions:packageoptions})
        }

    }

    updateField = event => {
        let fieldname = event.target.name
        let value = event.target.value
        let item = {}
        item['name']=fieldname
        item['value']=value
        this.setState({[fieldname]: value}, ()=>this.props.updateSurveyProps(item))
        }

    getBackgroundColor(c){
        if(this.state.activeitem === c){
        return("#d8e4e8")}
        else {return ("transparent")}
    }

    toggleSelection(id, s){
        let item = {}
        item['name']=id
        item['value']=s
        this.setState({[id]:s}, ()=>this.props.updateSurveyProps(item))
    }

    activeSelection(id){
        let activecolor = this.props.surveyprops.style[id]
        this.setState({activeitem:id, activecolor:activecolor})
    }


    changeColor = event => {
        let item = {}
        item['name']=this.state.activeitem
        item['value']=event.hex
        console.log('change color', item)
        this.setState({[item['name']]:event.hex, activecolor:event.hex}, ()=>this.props.updateSurveyProps(item))
    }

    handleToggle(i){
        this.setState({showprops:!this.state.showprops, publishsurvey:false})
    }

    getIconColor(i){
        if(this.state[i]){
            return("#20a8d8")
        } else { return('#f0f3f5')}
    }

    validatePublish(){
        let rightnow = new Date()
        let errormessage = ""
        let notValid = false

        if (this.props.surveyprops.surveysettings.applyquota === true){

            if(this.props.surveyprops.surveysettings.quota !== null){

                if(this.props.surveyprops.surveysettings.quota <= 0){
                    errormessage = errormessage + "A quota greater than 0 is required."
                    notValid = true
                }

            } else {
                
                errormessage = errormessage + "A quota greater than 0 is required."
                notValid = true
            }
        }

        if (this.props.surveyprops.surveysettings.passwordrequired === true){

            if(this.props.surveyprops.surveysettings.password !== null){

                if(this.props.surveyprops.surveysettings.password.length <= 1){
                    errormessage = errormessage + "A password needs to be at least 2 characters."
                    notValid = true
                }

            } else {
                
                errormessage = errormessage + "A password needs to be at least 2 characters."
                notValid = true
            }
        }

        if (this.props.surveyprops.surveysettings.schedulepublish === true){

            if(this.props.surveyprops.surveysettings.surveystart !== null){

                let startdate = new Date(this.props.surveyprops.surveysettings.surveystart)

                if(rightnow > startdate){
                    errormessage = errormessage + "Survey start must be later than today's date."
                    notValid = true
                }

            } else {
                
                errormessage = errormessage + "A scheduled survey publish requires a start date."
                notValid = true

            }
        }

        if (this.props.surveyprops.surveysettings.scheduleend === true){

            if(this.props.surveyprops.surveysettings.surveyend !== null){

                let enddate = new Date(this.props.surveyprops.surveysettings.surveyend)

                if(rightnow > enddate){
                    errormessage = errormessage + "Survey end must be later than today's date."
                    notValid = true
                }

            } else {
                
                errormessage = errormessage + "A scheduled survey end requires a date."
                notValid = true

            }
        }

        if (this.props.surveyprops.surveysettings.schedulepublish === true && this.props.surveyprops.surveysettings.scheduleend === true){

            if(this.props.surveyprops.surveysettings.surveystart !== null && this.props.surveyprops.surveysettings.surveyend !== null){

                let enddate = new Date(this.props.surveyprops.surveysettings.surveyend)
                let startdate = new Date(this.props.surveyprops.surveysettings.surveystart)



                if(startdate > enddate){
                    errormessage = errormessage + "Survey end must be later than the start date."
                    notValid = true
                }

            } 
        }



        if(this.state.isNew){
            errormessage = errormessage + "You need to add at least one page to publish a survey."
            notValid = true
        }

        if(this.state.notValid !== notValid){
            this.setState({notValid:notValid})
        }

        return(errormessage)

        }


    publishSurvey(i){
        let status = 'LIVE'

        if(i==="toggle"){
            this.setState({publishsurvey:!this.state.publishsurvey, showprops:false})
        }

        if(i==="Stop"){
            let item = {}
            item['name']='status'
            item['value']="COMPLETE"
            this.setState({publishsurvey:false, stopsurvey:false}, ()=>this.props.updateSurveyProps(item))
        }

        if(i==="Confirm"){

            if(this.props.surveyprops.surveysettings.publishtest===true){
                status = "TEST"
            }

            if(this.props.surveyprops.surveysettings.schedulepublish===true){
                status = "PENDING"
            }

            if(this.props.surveyprops.surveysettings.schedulepublish===true && this.props.surveyprops.surveysettings.publishtest===true){
                status = "TEST PENDING"
            }

            let item = {}
            item['name']='status'
            item['value']=status
            this.setState({publishsurvey:false}, ()=>this.props.updateSurveyProps(item))
            

        }
        
    }


    getPackages(){
        return(
        <Form>
        <FormGroup inline name={'selectedpackages'} onChange={this.handlePackageChange}>
            {this.props.packages.map((item, key=item.packageId)=>this.getSelectItems(item))}
        </FormGroup>
      </Form>
        )
    }

    getSelectItems(item){
        let pkg = JSON.parse(item['content'])
        let pname = truncateString(pkg['optionheader'], 15)
        return(
            <CustomInput 
            type="checkbox"
            id={pkg['id']} 
            defaultChecked={this.state.selectedpackages[pkg['id']]}
            value={pkg['optionheader']}
            label={pname} name={pkg['id']} key={pkg['id']} type={"checkbox"}
            style={{fontSize:"0.6em"}}
            ></CustomInput>
            )
    }

    handlePackageChange = (event) => {
        const target = event.target;
        if(target != null){
          let type = target.type
          let value = target.value
          const name = target.name;

          if(this.state.selectedpackages[name]===true){
            let pkgoption = JSON.parse(JSON.stringify(this.state.packageoptions))
            for(let p in pkgoption){
                if(pkgoption[p]['id']===name){
                    pkgoption.splice([p],1)
                }
            }

            let item = {}
            item['name'] = 'packageoptions'
            item['value'] = pkgoption

              this.setState(prevState => ({
                selectedpackages: {
                ...prevState.selectedpackages,          
                [name]:false},
                packageoptions:pkgoption}), 
                ()=>this.props.updateSurveyProps(item))
            }


          if(this.state.selectedpackages[name]===false){
            let pkgoption = JSON.parse(JSON.stringify(this.state.packageoptions))
            for(let p in this.props.packages){
                if(this.props.packages[p]['packageId']===name){
                    pkgoption.push(JSON.parse(this.props.packages[p]['content']))
                }
            }

            let item = {}
            item['name'] = 'packageoptions'
            item['value'] = pkgoption

            this.setState(prevState => ({
                selectedpackages: {
                ...prevState.selectedpackages,          
                [name]: true},
                packageoptions:pkgoption,
            }), ()=>this.props.updateSurveyProps(item))
            }
        }
      }


  render(){

    return(

        <div style={{display:"flex"}}>

            <ReactTooltip 
            multiline={true}
            type={'light'}
            />

        <Card style={{backgroundColor:"#2f353a", height:"500px", width:"600px"}}>

        <CardBody>

          <div className="h1 mt-0 mb-0" style={{fontSize:"3em", color:"#f0f3f5", display:"flex"}}>
              
              {this.props.activeSurvey}
          
          <div style={{marginLeft:"auto", marginRight:"5px",}} >
          
          <span><i className="icon-settings" style={{fontSize:"0.5em", cursor:"pointer", fontWeight:"200", color:this.getIconColor("showprops")}} onClick={()=>this.handleToggle("settings")}  data-place="right" data-tip={settingstip}></i></span>
          
          </div>
          
          </div>
          
          <hr style={{borderColor:"#8f9ba6"}}/>
          

          {this.state.publishsurvey &&
          <small className="text-muted text-uppercase font-weight-bold">{this.props.surveyprops.surveysettings.surveydescription}</small>
            }

            {this.state.showprops &&
          <small className="text-muted text-uppercase font-weight-bold">{this.props.surveyprops.surveysettings.surveydescription}</small>
            }
          
            <div style={{marginTop:"5px"}}>
            <small className="text-uppercase font-weight-bold" style={{color:"#20a8d8"}}>
              
              {this.props.surveyprops.surveysettings.status}
            
            </small>
            </div>

            <div style={{marginTop:"5px"}}>
            <small className="text-uppercase font-weight-bold" style={{color:"#5c6873", fontSize:"0.7em"}}>
              
              {`Last saved:  ${this.props.surveyprops.surveysettings.localsavetime}`}
            
            </small>
            </div>


        </CardBody>
        <div style={{display:"flex", alignItems:"flex-end", alignContent:"flex-end", marginTop:"auto", marginLeft:"15px", marginBottom:"15px", marginRight:"15px" }}>
        
        <Button color="primary"style={{marginRight:"15px"}} disabled={this.props.isSaved} onClick={()=>this.props.saveSurvey()} >Save Survey</Button>

        {this.props.surveyprops.surveysettings.status === "LIVE" && !this.state.publishsurvey &&
        <Button color="danger" style={{marginLeft:"15px", marginRight:"15px"}} onClick={()=>this.publishSurvey('Stop')} >Stop Survey</Button>
        }


        <div style={{marginLeft:"auto", marginRight:"15px"}}>
        <span><i className="icon-paper-plane" style={{fontSize:"1.5em", cursor:"pointer", fontWeight:"200", color:this.getIconColor("publishsurvey")}} onClick={()=>this.publishSurvey('toggle')} data-place="right" data-tip={publishtip} ></i></span>
        </div>
        
        </div>
        </Card>



        {!this.state.showprops && !this.state.publishsurvey && !this.props.isNew &&

            <div className="h1 mt-0 mb-0" style={{fontSize:"3.5em", color:"#f0f3f5", display:"flex", alignItems:"center", cursor:"pointer", marginLeft:"100px", marginRight:"100px"}} onClick={()=>this.setState({showprops:true})} >
            
            <div>
                {this.props.surveyprops.surveysettings.surveydescription}

                {this.props.surveyprops.surveysettings.status === "LIVE" &&
                <h1 style={{color:"#f0f3f5", cursor:"pointer", marginTop:"15px"}} data-place="bottom" data-tip={urltip}><a href={this.props.surveyprops.surveysettings.surveyurl} target="_blank">{this.props.surveyprops.surveysettings.surveyurl}</a></h1>
                }


            </div>

            </div>
        }



        {!this.state.showprops && !this.state.publishsurvey && this.props.isNew &&

        <div className="h1 mt-0 mb-0" style={{fontSize:"3em", color:"#f0f3f5", display:"flex", alignItems:"center", cursor:"pointer", marginLeft:"100px", marginRight:"100px"}} onClick={()=>this.setState({showprops:true})} >

            <div style={{width:"400px", marginRight:"100px"}}>
                {`Edit settings or add a page to get started with your new survey...`}
            </div>

            <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
            
                <div>
                <span onClick={()=>{this.props.newPage()}} style={{marginLeft:"auto", marginRight:"50px", alignSelf:"center", cursor:"pointer"}}><i className="icon-plus" style={{color:"#20a8d8", fontSize:"2em", marginRight:"5px"}} ></i></span>
                </div>
                
                <div>
                <p style={{fontSize:"0.4em",color:'#c8ced3', alignSelf:"center", marginTop:"15px"}}>Create New Page</p>
                </div>

            </div>
        </div>
        }



        {this.state.showprops &&

        <div style={{display:"flex", flexWrap:"nowrap"}}>

        <Card style={{backgroundColor:"#f0f3f5", height:"500px", marginLeft:"5px"}} >
        
        <CardBody>
            

            <Row style={{marginTop:"50px", marginLeft:'5px', marginBottom:"15px"}}>
            <small className="text-muted text-uppercase font-weight-bold"> Survey Description </small>
            </Row>

            <Row>
            <div style={{marginLeft:"15px", marginRight:"15px", display:"flex", width:"100%"}}>
            <Input 
            name={"surveydescription"}
            value={this.props.surveyprops.surveysettings.surveydescription}
            placeholder={"Add a title and description for your survey..."}
            type={"textarea"}
            rows={"2"}
            onChange={this.updateField}
            style={{backgroundColor:"transparent", borderColor:"#8f9ba640", width:"100%"}}  
            /> 
            </div>
            </Row>

            <Row style={{marginTop:"40px", marginLeft:'5px', marginBottom:"15px"}}>
            <small className="text-muted text-uppercase font-weight-bold"> Default Page Settings </small>
            </Row>

            <Row style={{display:"flex",  alignItems:"flex-end", marginLeft:'5px'}}>

                <Col>
                    <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Show Page Numbers</p>
                    <Toggle
                    id={"showpagenums"}
                    checked= {this.props.surveyprops.pagepropdefaults.showpagenums}
                    icons={{unchecked: null}}
                    className = {"custom-success"}
                    onChange = {() => this.toggleSelection("showpagenums", !this.props.surveyprops.pagepropdefaults.showpagenums)}
                        />
                </Col>
                <Col>
                    <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Randomize Choices</p>
                    <Toggle
                    id={"randomizechoices"}
                    checked= {this.props.surveyprops.pagepropdefaults.randomizechoices}
                    icons={{unchecked: null}}
                    className = {"custom-success"}
                    onChange = {() => this.toggleSelection("randomizechoices", !this.props.surveyprops.pagepropdefaults.randomizechoices)}
                        />
                </Col>
                <Col>
                    <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Randomize Features</p>
                    <Toggle
                    id={"randomize"}
                    checked= {this.props.surveyprops.questionpropdefaults.randomize}
                    icons={{unchecked: null}}
                    className = {"custom-success"}
                    onChange = {() => this.toggleSelection("randomize", !this.props.surveyprops.questionpropdefaults.randomize)}
                        />
                </Col>
                <Col>
                    <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Show Prices</p>
                    <Toggle
                    id={"showprice"}
                    checked= {this.props.surveyprops.questionpropdefaults.showprice}
                    icons={{unchecked: null}}
                    className = {"custom-success"}
                    onChange = {() => this.toggleSelection("showprice", !this.props.surveyprops.questionpropdefaults.showprice)}
                        />
                </Col>
                <Col>
                    <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Edit Buttons</p>
                    <Toggle
                    id={"editbuttons"}
                    checked= {this.state.editbuttons}
                    icons={{unchecked: null}}
                    className = {"custom-success"}
                    onChange = {() => this.toggleSelection("editbuttons", !this.state.editbuttons)}
                        />
                </Col>

            </Row>

            {this.state.editbuttons &&
                <Row style={{marginTop:"15px"}}>
                <div style={{marginLeft:"15px", marginRight:"5px"}}>
                <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Back Button Text</p>
                          <Input 
                          name={"backbutton"}
                          value={this.props.surveyprops.pagepropdefaults.backbutton}
                          placeholder={"Back..."}
                          type={"text"}
                          onChange={this.updateField}
                          style={{backgroundColor:"transparent", borderColor:"#8f9ba640"}}  
                          /> 
                </div>
                <div>
                <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Forward Button Text</p>
                            <Input 
                            name={"nextbutton"}
                            value={this.props.surveyprops.pagepropdefaults.nextbutton}
                            placeholder={"Next..."}
                            type={"text"}
                            onChange={this.updateField}
                            style={{backgroundColor:"transparent", borderColor:"#8f9ba640"}}  
                            /> 
                </div>
                <div style={{marginRight:"15px", marginLeft:"5px"}}>
                <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Survey End Button Text</p>
                            <Input 
                            name={"completebutton"}
                            value={this.props.surveyprops.pagepropdefaults.completebutton}
                            placeholder={"Complete Survey..."}
                            type={"text"}
                            onChange={this.updateField}
                            style={{backgroundColor:"transparent", borderColor:"#8f9ba640"}}  
                            /> 
                </div>
                </Row>
                }


            <Row style={{marginTop:"40px", marginLeft:'5px', marginBottom:"15px"}}>
            <small className="text-muted text-uppercase font-weight-bold"> Main Color Palette </small>
            </Row>

            <Row style={{display:"flex",  alignItems:"flex-end", marginLeft:'5px', cursor:"pointer", marginBottom:"0px"}}>

            <Col onClick = {() => this.activeSelection("pagebodycolor")} style={{padding:"5px", justifyContent:"center"}}>
                 <p style={{ marginBottom:'0px'}}  ><small>Main Background</small></p>
                    <div style={{backgroundColor:this.getBackgroundColor("pagebodycolor"), display:"flex", flexDirection:"column", alignItems:"center", marginBottom:"5px"}}>
                    <div style={{backgroundColor:this.props.surveyprops.style.pagebodycolor, width:"20px", height:"20px", borderRadius:"50%", marginTop:"5px", marginBottom:"5px"}}></div>
                    
                   </div>
                </Col>

                 <Col onClick = {() => this.activeSelection("formbodycolor")} style={{padding:"5px", justifyContent:"center"}}>
                 <p style={{ marginBottom:'0px'}}><small>Card Body Color</small></p>
                    <div style={{backgroundColor:this.getBackgroundColor("formbodycolor"), display:"flex", flexDirection:"column", alignItems:"center", marginBottom:"5px"}}>
                    <div style={{backgroundColor:this.props.surveyprops.style.formbodycolor, width:"20px", height:"20px", borderRadius:"50%", marginTop:"5px", marginBottom:"5px"}}></div>
                    
                   </div>
                </Col>
                <Col onClick = {() => this.activeSelection("formheadcolor")} style={{padding:"5px", justifyContent:"center"}}>
                <p style={{ marginBottom:'0px'}}><small>Card Accent Color</small></p>
                    <div style={{backgroundColor:this.getBackgroundColor("formheadcolor"), display:"flex", flexDirection:"column", alignItems:"center", marginBottom:"5px"}}>
                    <div style={{backgroundColor:this.props.surveyprops.style.formheadcolor, width:"20px", height:"20px", borderRadius:"50%", marginTop:"5px", marginBottom:"5px"}}></div>
                    
                   </div>
                </Col>
                <Col onClick = {() => this.activeSelection("formcardtextcolor")} style={{padding:"5px", justifyContent:"center"}}>
                <p style={{ marginBottom:'0px'}}><small>Main Text Color</small></p>
                    <div style={{backgroundColor:this.getBackgroundColor("formcardtextcolor"), display:"flex", flexDirection:"column", alignItems:"center", marginBottom:"5px"}}>
                    <div style={{backgroundColor:this.props.surveyprops.style.formcardtextcolor, width:"20px", height:"20px", borderRadius:"50%", marginTop:"5px", marginBottom:"5px"}}></div>
                    
                   </div>
                </Col>
                <Col onClick = {() => this.activeSelection("formheadtextcolor")} style={{padding:"5px", justifyContent:"center"}}>
                <p style={{ marginBottom:'0px'}}><small>Accent Text Color</small></p>
                    <div style={{backgroundColor:this.getBackgroundColor("formheadtextcolor"), display:"flex", flexDirection:"column", alignItems:"center", marginBottom:"5px"}}>
                    <div style={{backgroundColor:this.props.surveyprops.style.formheadtextcolor, width:"20px", height:"20px", borderRadius:"50%", marginTop:"5px", marginBottom:"5px"}}></div>
                    
                   </div>
                </Col>
            </Row>

        </CardBody>

        </Card>

        <div style={{height:"100%",  marginLeft:"5px", display:"flex", alignItems:"stretch"}}>
            <Card style={{backgroundColor:"#f0f3f5", width:"150px",}}>
            <small className="text-muted text-uppercase font-weight-bold" style={{marginTop:"15px", marginLeft:"5px", marginBottom:"5px"}}> TESTABLE PACKAGES </small>
            <div className="survey-package-card" style={{display:"flex", flexDirection:"column"}}> 
            {this.getPackages()}
            </div>
            </Card>
        
        </div>


        <div style={{height:"400px", marginLeft:"5px"}}>
        <BlockPicker

                color={this.state.activecolor}
                colors={defaultcolors}
                triangle={"hide"}
                onChange={this.changeColor}
                />
        </div>



        </div>

        }



        {this.state.publishsurvey &&

        <div style={{display:"flex", flexWrap:"nowrap"}}>

        <Card style={{backgroundColor:"#f0f3f5", height:"500px", marginLeft:"5px"}} >

        <CardBody>

            <h2 style={{fontWeight:"200", color:'#2f353a'}}>{this.props.surveyprops.surveysettings.surveyurl}</h2>
            
            <Row style={{display:"flex", marginTop:"50px", marginLeft:'5px',  marginBottom:"15px"}}>
            <small className="text-muted text-uppercase font-weight-bold"> Publish Properties </small>
            </Row>

            <Row style={{display:"flex",  alignItems:"flex-end", marginLeft:'5px'}}>

                <Col>

                <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Show Logo</p>
                <Toggle
                id={"showlogo"}
                disabled
                checked= {this.props.surveyprops.surveysettings.showlogo}
                icons={{unchecked: null}}
                className = {"custom-success"}
                onChange = {() => this.toggleSelection("showlogo", !this.props.surveyprops.surveysettings.showlogo)}
                    />
                </Col>
                <Col>
                <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Response Quota</p>
                <Toggle
                id={"applyquota"}
                disabled
                checked= {this.props.surveyprops.surveysettings.applyquota}
                icons={{unchecked: null}}
                className = {"custom-success"}
                onChange = {() => this.toggleSelection("applyquota", !this.props.surveyprops.surveysettings.applyquota)}
                    />
                </Col>
                <Col>
                <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Single Response</p>
                <Toggle
                id={"singleresponse"}
                disabled
                checked= {this.props.surveyprops.surveysettings.singleresponse}
                icons={{unchecked: null}}
                className = {"custom-success"}
                onChange = {() => this.toggleSelection("singleresponse", !this.props.surveyprops.surveysettings.singleresponse)}
                    />
                </Col>
                <Col>
                <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Send Notifications</p>
                <Toggle
                id={"notifications"}
                disabled
                checked= {this.props.surveyprops.surveysettings.notifications}
                icons={{unchecked: null}}
                className = {"custom-success"}
                onChange = {() => this.toggleSelection("notifications", !this.props.surveyprops.surveysettings.notifications)}
                    />
                </Col>
                <Col>
                <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Password Required</p>
                <Toggle
                id={"passwordrequired"}
                disabled
                checked= {this.props.surveyprops.surveysettings.passwordrequired}
                icons={{unchecked: null}}
                className = {"custom-success"}
                onChange = {() => this.toggleSelection("passwordrequired", !this.props.surveyprops.surveysettings.passwordrequired)}
                    />
                </Col>
            </Row>

            <div style={{display:"flex", margintTop:"15px", marginBottom:"15px"}}>
            {this.props.surveyprops.surveysettings.showlogo &&
                <div style={{marginRight:"auto", marginLeft:"15px", marginRight:"5px",flexGrow:"4"}} >
                <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Logo File Location</p>
                        <Input 
                        name={"logosrc"}
                        value={this.props.surveyprops.surveysettings.logosrc}
                        placeholder={"File..."}
                        type={"text"}
                        onChange={this.updateField}
                        style={{backgroundColor:"transparent", borderColor:"#8f9ba640"}}  
                        />
                </div>
                }
                {this.props.surveyprops.surveysettings.applyquota &&
                <div style={{ marginRight:"5px", flexGrow:"1"}} >
                <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Stop Quota Amount</p>
                        <Input 
                        name={"quota"}
                        value={this.props.surveyprops.surveysettings.quota}
                        placeholder={"Quota..."}
                        type={"number"}
                        onChange={this.updateField}
                        style={{backgroundColor:"transparent", borderColor:"#8f9ba640"}}  
                        />
                </div>
                }

                {this.props.surveyprops.surveysettings.passwordrequired &&
                <div style={{marginRight:"15px", flexGrow:"2"}}>
                <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Survey Password</p>
                        <Input 
                        name={"password"}
                        value={this.props.surveyprops.surveysettings.password}
                        placeholder={"Password..."}
                        type={"text"}
                        onChange={this.updateField}
                        style={{backgroundColor:"transparent", borderColor:"#8f9ba640"}}  
                        /> 
                </div>
                }
            </div>

            <Row style={{display:"flex", justifyContent:"flex-start", alignItems:"flex-end", marginLeft:'5px', marginTop:"15px"}}>

                <Col>

                <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Schedule Publish</p>
                <Toggle
                id={"schedulepublish"}
                disabled
                checked= {this.props.surveyprops.surveysettings.schedulepublish}
                icons={{unchecked: null}}
                className = {"custom-success"}
                onChange = {() => this.toggleSelection("schedulepublish", !this.props.surveyprops.surveysettings.schedulepublish)}
                    />
                </Col>
                <Col>
                <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Schedule End</p>
                <Toggle
                id={"scheduleend"}
                disabled
                checked= {this.props.surveyprops.surveysettings.scheduleend}
                icons={{unchecked: null}}
                className = {"custom-success"}
                onChange = {() => this.toggleSelection("scheduleend", !this.props.surveyprops.surveysettings.scheduleend)}
                    />
                </Col>
                <Col>
                <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Publish in Test Mode</p>
                <Toggle
                id={"publishtest"}
                disabled
                checked= {this.props.surveyprops.surveysettings.publishtest}
                icons={{unchecked: null}}
                className = {"custom-success"}
                onChange = {() => this.toggleSelection("publishtest", !this.props.surveyprops.surveysettings.publishtest)}
                    />
                </Col>
                <Col>
                <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>URL on Complete</p>
                <Toggle
                id={"showcompleteurl"}
                disabled
                checked= {this.props.surveyprops.surveysettings.showcompleteurl}
                icons={{unchecked: null}}
                className = {"custom-success"}
                onChange = {() => this.toggleSelection("showcompleteurl", !this.props.surveyprops.surveysettings.showcompleteurl)}
                    />
                </Col>
                <Col>

                </Col>
                </Row>

                <div style={{display:"flex", margintTop:"15px", marginBottom:"15px"}}>
            {this.props.surveyprops.surveysettings.schedulepublish &&
                <div style={{marginRight:"auto", marginLeft:"15px", marginRight:"5px",flexGrow:"1"}} >
                <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Survey Start Date</p>
                        <Input 
                        name={"surveystart"}
                        value={this.props.surveyprops.surveysettings.surveystart}
                        placeholder={"Start Date..."}
                        type={"date"}
                        onChange={this.updateField}
                        style={{backgroundColor:"transparent", borderColor:"#8f9ba640"}}  
                        />
                </div>
                }
                {this.props.surveyprops.surveysettings.scheduleend &&
                <div style={{ marginRight:"5px", flexGrow:"1"}} >
                <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Survey End Date</p>
                        <Input 
                        name={"surveyend"}
                        value={this.props.surveyprops.surveysettings.surveyend}
                        placeholder={"End Date..."}
                        type={"date"}
                        onChange={this.updateField}
                        style={{backgroundColor:"transparent", borderColor:"#8f9ba640"}}  
                        />
                </div>
                }
                {this.props.surveyprops.surveysettings.showcompleteurl &&
                <div style={{display:"flex", width:"100%", marginTop:"5px"}}>
                    <div style={{ marginRight:"5px", flexGrow:"1"}} >
                    <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>URL after complete</p>
                            <Input 
                            name={"completeurl"}
                            value={this.props.surveyprops.surveysettings.completeurl}
                            placeholder={"https://..."}
                            onChange={this.updateField}
                            style={{backgroundColor:"transparent", borderColor:"#8f9ba640"}}  
                            />
                    </div>
                    <div style={{marginLeft:"5px", flexGrow:"1"}}  >
                    <p style={{ marginBottom:"5px", fontSize:"0.8em", marginLeft:"5px"}}>Thank you message on completion page</p>
                            <Input 
                            name={"thankyoumessage"}
                            value={this.props.surveyprops.surveysettings.thankyoumessage}
                            placeholder={"Thank you..."}
                            onChange={this.updateField}
                            style={{backgroundColor:"transparent", borderColor:"#8f9ba640"}}  
                            />
                    </div>
                </div>
                }
                

            </div>
            <div style={{fontSize:"0.6em", marginTop:'15px'}}>
            {this.validatePublish()}
            </div>
         
        </CardBody>

            <div style={{display:"flex", alignItems:"flex-end", alignContent:"flex-end", marginTop:"auto", marginLeft:"auto", marginBottom:"15px", marginRight:"15px" }}>



            <Button style={{marginRight:"15px"}} color="secondary" onClick={()=>this.publishSurvey('toggle')} >Cancel </Button>

            {this.props.surveyprops.surveysettings.status !== "LIVE" &&
            <Button color="primary" disabled={this.state.notValid} onClick={()=>this.publishSurvey("Confirm")} >Publish Now </Button>
            }

            {this.props.surveyprops.surveysettings.status === "LIVE" &&
            <Button color="danger" onClick={()=>this.publishSurvey("Stop")} >Stop Survey </Button>
            }


            
            </div>

        </Card>
            
        {this.props.surveyprops.surveysettings.showlogo &&
        <div>
            <img src={this.props.surveyprops.surveysettings.logosrc} height="30"/>
        </div>
        }

        </div>

        }


        
        </div>


    )
  }
}
import React, { Component } from 'react';
import { API } from "aws-amplify";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Input, Card, CardBody, CardHeader, CardFooter, Nav, NavLink, Jumbotron} from 'reactstrap';
import ReactTooltip from 'react-tooltip'
import RenderChoiceContent from '../survey/RenderChoiceContent'


const menulist = [{id:"MENU1", name:"select"},{id:"MENU2", name:"toggle"},{id:"MENU3", name:"form"},{id:"MENU4", name:"check"},{id:"MENU5", name:"slider"},{id:"MENU6", name:"img"},{id:"MENU7", name:"video"},{id:"MENU8", name:"list"},{id:"MENU9", name:"jumbo"},{id:"MENU10", name:"text"}]

const defaultchoiceprops = {
  id: null,
  optionheader: "",
  showheader: true,
  content: [],
  helpmessage: "",
  showfooter: true,
  showimage: false,
  showgif: false,
  showvideo: false,
  showfeatures: true,
  img:"",
  imageSrc: "",
  imageTitle: "",
  imageCaption: "",
  gif:"",
  video:"",
  randomize:false,
  showprice:false,
  price: 0,
  pricemetric:"",
  togglevalue:"",
  togglestyle:"custom-success",
  slidermin:0,
  slidermax:100,
  sliderstep:25,
  slidermarks:{},
  sliderdefault:0,
  rangedefault:[0,100],
  allocationamount:100,
  texttype:"text",
  selectoptions:[{}],
  annotations:null,
  features:[],
  packages:[],
    }

    const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding:'0px',
    margin:'0px',
    background: isDragging ? 'rgba(32, 168, 216, 0.1)' : 'transparent',
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? '#c8ced3' : 'inherit',
    display: 'flex',
    overflow: 'visible',
  });
  

const getCardStyle = isDraggingOver => ({
    background: isDraggingOver ? 'rgba(240, 243, 245, 0.9)' : 'transparent',
    padding:'0px',
});

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, removed);
    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

function makeId(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }

function shuffle(array) {
  var currentIndex = array.length, temporaryValue, randomIndex;
  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }
  return array;
}


function pricetest(price, pricerange) {

  let n = Number(price)
  let r = Number(pricerange)
  r = r/100

  let remainder = n % 1
  let posorneg = Math.floor(Math.random() * 3)
  if(posorneg === 0){posorneg = -1}
  if(posorneg === 1){posorneg = 0}
  if(posorneg === 2){posorneg = 1}
  let newn = n

  if(n<1){
    newn = n + (n * r * posorneg)
    newn= newn.toFixed(2)
    if(newn<0){
      newn = n.toFixed(2)}
  }

  if(n>=1){
    newn =  n + (n * r * posorneg)
    if(remainder===0){
      newn = Math.round(newn).toFixed(0)
    }
    if(remainder !==0){
      newn = Math.round(newn).toFixed(0)
      let keepoldremainder = newn % 1
      keepoldremainder = keepoldremainder.toFixed(2)
      remainder = remainder.toFixed(2)
      newn = Number(newn) - Number(keepoldremainder)
      newn = Number(newn) + Number(remainder)

    }
  }

  return newn
}


export default class RenderWinners extends Component {
  constructor(props) {
    super(props);

    let winners = []
    if(this.props.allresponses !== undefined){
        if(Object.keys(this.props.allresponses).length > 0){
            for (let pg of Object.keys(this.props.allresponses)){
                if(pg!== this.props.questionId){
                    if(this.props.allresponses[pg]['selectvalue'] !== undefined){
                        if(Object.keys(this.props.allresponses[pg]['selectvalue']).length > 0){
                            for (let sv of Object.keys(this.props.allresponses[pg]['selectvalue'])){
                                if (this.props.allresponses[pg]['selectvalue'][sv]==='selected'){
                                    winners.push(sv)
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    let packagesperpage = winners.length

    let packagesonpage = []
    if(winners.length>0){
        for (let w of winners){
            for(let p of this.props.packages){
                if(p['packageId']===w){
                    packagesonpage.push(JSON.parse(p['content']))
                }
            }
        }
    }

    let mwidth = 1
    if (packagesperpage>0){
        mwidth = 1/packagesperpage
    }
    let usablewidth = vw - 300
    mwidth = mwidth * usablewidth
    let widthprop = ""
    widthprop = mwidth + "px"
    if(vw < 600){mwidth = "100%"}


    this.changeSelection = this.changeSelection.bind(this)
    this.changeAllocation = this.changeAllocation.bind(this)
    this.changeMaxDiff = this.changeMaxDiff.bind(this)
    this.toggleRadioCheck = this.toggleRadioCheck.bind(this)
    let initialselections = {}
    let initialcolor = {}
    let initializefalse = ['checkbox', 'radio']
    let inititaltotal = 0
    let initialremaining = parseInt(this.props.questionprops.allocationamount)
    let initialoptions = this.props.cards

    if (typeof this.props.savedpages != "undefined") {
        if(this.props.savedpages===true){
           initialselections = this.props.savedresponses['selectvalue']
           initialoptions = this.props.savedresponses['options']
           initialcolor = this.props.savedresponses['cardcolor']
           initialremaining = this.props.savedresponses['remaining']
           inititaltotal = this.props.savedresponses['total']
        }
        } else {
                for (let i of this.props.cards){
                    let radiocheck = {}
                    initialselections[i['id']] = null
                    if(this.props.questionprops.selectiontype==='toggle'){initialselections[i['id']] = false}
                    if(this.props.questionprops.selectiontype==='slider'){initialselections[i['id']] = i['sliderdefault']}
                    if(this.props.questionprops.selectiontype==='range'){initialselections[i['id']] = i['rangedefault']}
                    if(this.props.questionprops.selectiontype==='allocation'){initialselections[i['id']] = 0}
                    if(initializefalse.includes(this.props.questionprops.selectiontype))
                        {
                        for (let s of i['selectoptions']){
                            radiocheck[s['value']]=false
                        }
                        initialselections[i['id']]=radiocheck
                        }
                    initialcolor[i['id']] = this.props.surveyprops.questionpropdefaults.noselectcolor
                }
                if(this.props.pageprops.randomizechoices){ initialoptions = shuffle(this.props.cards)}
            }
    

    this.state = {
        selectvalue: initialselections,
        cardcolor:initialcolor,
        remaining: initialremaining,
        validallocationamount: initialremaining,
        total: inititaltotal,
        isvalid: false,
        mwidth: widthprop,
        packagesonpage: packagesonpage,
        packagelist:this.props.surveyprops.packageoptions
      };
    }


    async componentDidMount() {
        this.props.newCard(this.state.packagesonpage)

    }


    /// saves state, including survey choices, on unmounting
    componentWillUnmount(){
        this.props.stateChangePassThru(this.props.questionId, this.state)
    }

    /// reset card width if new or deleted card
  componentDidUpdate(prevProps, prevState){
    
    if (typeof this.props.cards !== 'undefined'){
      let mwidth = 1
        if(this.props.cards.length !==prevProps.cards.length){
            mwidth = 1/this.props.cards.length
            let usablewidth = vw - 300
            mwidth = mwidth * usablewidth
            let widthprop = ""
            widthprop = mwidth + "px"
            if(vw < 600){mwidth = "100%"}
        this.setState({mwidth:widthprop})
    }
    }
    if(this.props.surveyprops.questionpropdefaults.noselectcolor !== prevProps.surveyprops.questionpropdefaults.noselectcolor){
      let initialcolor = {}
      for (let i of this.props.cards){
        initialcolor[i['id']] = this.props.surveyprops.questionpropdefaults.noselectcolor
      }
      this.setState({cardcolor:initialcolor})
    }

    if(this.props.surveyprops.questionpropdefaults.noselectcolor !== prevProps.surveyprops.questionpropdefaults.noselectcolor){
      let initialcolor = {}
      for (let i of this.props.cards){
        initialcolor[i['id']] = this.props.surveyprops.questionpropdefaults.noselectcolor
      }
      this.setState({cardcolor:initialcolor})
    }

    if(this.props.page.pageprops.alwaysshow !== prevProps.page.pageprops.alwaysshow ||
      this.props.page.pageprops.alwaysshowpackage !== prevProps.page.pageprops.alwaysshowpackage ||
      this.props.page.pageprops.assembledchoices !== prevProps.page.pageprops.assembledchoices ||
      this.props.page.pageprops.pricerange !== prevProps.page.pageprops.pricerange ||
      this.props.page.pageprops.testwinners !== prevProps.page.pageprops.testwinners
      ){
        
        let packagesperpage = this.props.page.pageprops.assembledchoices
        let packagesonpage = []
        let randomindex = null
        let counter = 0
        let selectpackages = JSON.parse(JSON.stringify(this.props.surveyprops.packageoptions))
        let initialselections = {}
        let initialcolor = {}
        let initializefalse = ['checkbox', 'radio']
        let inititaltotal = 0
        let initialremaining = parseInt(this.props.questionprops.allocationamount)

      if(this.props.page.pageprops.alwaysshow === true){
        if(this.props.page.pageprops.alwaysshowpackage !== null){
          for(let a in selectpackages){
            if(selectpackages[a]['id']===this.props.page.pageprops.alwaysshowpackage){
              packagesonpage.push(selectpackages[a])
              selectpackages.splice(a, 1)
              counter = counter + 1
            }
          }
        }
      }

        while (counter < packagesperpage && selectpackages.length>0){
            randomindex = Math.floor(Math.random() * selectpackages.length)
            packagesonpage.push(selectpackages[randomindex])
            selectpackages.splice(randomindex, 1)
            counter = counter + 1
        }

        for (let i of this.props.cards){
          let radiocheck = {}
          initialselections[i['id']] = null
          if(this.props.questionprops.selectiontype==='toggle'){initialselections[i['id']] = false}
          if(this.props.questionprops.selectiontype==='slider'){initialselections[i['id']] = i['sliderdefault']}
          if(this.props.questionprops.selectiontype==='range'){initialselections[i['id']] = i['rangedefault']}
          if(this.props.questionprops.selectiontype==='allocation'){initialselections[i['id']] = 0}
          if(initializefalse.includes(this.props.questionprops.selectiontype))
              {
              for (let s of i['selectoptions']){
                  radiocheck[s['value']]=false
              }
              initialselections[i['id']]=radiocheck
              }
          initialcolor[i['id']] = this.props.surveyprops.questionpropdefaults.noselectcolor
      }

        this.setState({
          packagesonpage:packagesonpage,
          selectvalue: initialselections,
          cardcolor:initialcolor,
          remaining: initialremaining,
          validallocationamount: initialremaining,
          total: inititaltotal})
        
      }




  }

    /// function to update state with a new selection, is passed in by Choice Card and
    /// this is also where the validation occurs.  i is id, e is value
    changeSelection(i, e){
        let validcheck = false
        let invaliditem = false
        if(this.props.questionprops.validationtype === "all"){
          for (let v in this.state.selectvalue){
            if (v===i){
              if(e===null){invaliditem = true}
            } else {
              if(this.state.selectvalue[v]===null){invaliditem = true}
            }
          }
        } else {
          if(e===null){invaliditem = true}
          else{validcheck=true}
        }
        if (!invaliditem){validcheck=true}
        if(this.props.questionprops.validationtype==="none"){validcheck=true}
        this.setState(prevState => ({selectvalue: {...prevState.selectvalue, [i]: e}}))
        this.setState({isvalid: validcheck}, () => {this.props.handlePageValid(this.state.isvalid)})
        this.setState(prevState => ({cardcolor: {...prevState.cardcolor, [i]: this.props.surveyprops.questionpropdefaults.touchcolor}}))
    }


   /// function to update chip allocation selections as well as determine if you are over/under or valid.  r is id, v is value
    changeAllocation(r, v){

        let newtotal = v
        for (let x of Object.keys(this.state.selectvalue)){
          if (x !== r){
            newtotal = newtotal + this.state.selectvalue[x]
          }
        }
        
        let newremain = this.props.questionprops.allocationamount - newtotal
        let validcheck = false

       if (newremain < 0){
         v = v + newremain
         newtotal = newtotal + newremain
         newremain = newremain - newremain
        }

       if(newtotal === this.props.questionprops.allocationamount){validcheck=true}

       if(this.props.questionprops.validationtype==="none"){validcheck=true}

        this.setState(prevState => ({
          selectvalue: {...prevState.selectvalue, [r]: v},
          cardcolor: {...prevState.cardcolor, [r]: this.props.surveyprops.questionpropdefaults.touchcolor},
          total: newtotal,
          remaining: newremain,
         isvalid: validcheck,
          }), () => {this.props.handlePageValid(this.state.isvalid)}
          )

    }


    /// function to update choices, which also might mean unselecting other options to ensure there is only one
    /// best and one worst at any given time, and that a choice cant be both best and worst.  i is id, e is value
    changeMaxDiff(i, e){
        
        let newcolor = {}
        let newselections = {}
        let validcheck = false
        let validbest = false
        let validworst = false

        for (let b in this.state.selectvalue){
          if (this.state.selectvalue[b]===e){
            newselections[b] = null
            newcolor[b]= this.props.surveyprops.questionpropdefaults.noselectcolor
          } else {
            newselections[b] = this.state.selectvalue[b]
            newcolor[b] = this.state.cardcolor[b]
          }
        }
      
      newselections[i] = e
      if (e=="best"){
        newcolor[i] = this.props.surveyprops.questionpropdefaults.bestselectcolor}
      if (e==="worst"){
        newcolor[i] = this.props.surveyprops.questionpropdefaults.worstselectcolor}
      if (e==="selected"){
          newcolor[i] = this.props.surveyprops.questionpropdefaults.selectcolor}
      
      for(let c in newselections){
        if(newselections[c] === "selected"){validcheck = true}
        if(newselections[c] === "best"){validbest = true}
        if(newselections[c] === "worst"){validworst = true}
      }

      if (validbest && validworst){ validcheck = true}

      if(this.props.questionprops.validationtype==="none"){validcheck=true}


        this.setState({selectvalue:newselections, 
          cardcolor:newcolor,
          isvalid: validcheck
          }, () => {this.props.handlePageValid(this.state.isvalid)})
    }

    /// used to run through each of the of the radio or checkbox choices to see if they are valid (at least one true)
    validateRadioCheck(){
      let validcheck = true
      if(this.props.questionprops.validationtype==="all"){
          for(let i in this.state.selectvalue){
            const entries = Object.values(this.state.selectvalue[i])
            const entriesvalid = entries.includes(true)
            if (entriesvalid ===false){validcheck = false}
            }
      } 
      this.setState({isvalid: validcheck}, () => {this.props.handlePageValid(this.state.isvalid)})
      }
    
    /// used to change the bool values of the radio and checkbox items.  Radio/check are different in that they have
    /// another layer of selection - there is a dict of options, each of which can be true or false.  i is id, e is value, t is type
    toggleRadioCheck(i, e, t){

        if(t==='radio'){
            let newvals = this.state.selectvalue[i]
            for (let p in newvals)
                {
                newvals[p]=false
                }
            newvals[e]=true

            this.setState(prevState => ({
                selectvalue: {
                ...prevState.selectvalue,          
                [i]: newvals
                }
                }), () => {this.validateRadioCheck()}
                )
        } else {

            this.setState(prevState => ({
                selectvalue: {
                ...prevState.selectvalue,          
                [i]: {                     
                ...prevState.selectvalue[i], 
                [e]: !this.state.selectvalue[i][e]         
                }
                }
                }), () => {this.validateRadioCheck()}
                )
            }
    }

    /// the overall drag and drop function
    onDragEnd = result => {

        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {return;}
     
        this.props.activeCard(destination.droppableId)
        let sourceindex = null
        let destindex = null

        for (const x in this.props.cards){
            if (this.props.cards[x].id===source.droppableId){
                sourceindex = x
            }
            if (this.props.cards[x].id===destination.droppableId){
                destindex = x
            }
        }

    // case of movement in same list
    if (source.droppableId === destination.droppableId) {

        // case of same list and same index in array eg no move
        if (source.index === destination.index)
            { 
                return;

            } else {

            const reorderedlist = reorder(
                this.props.cards,
                result.source.index,
                result.destination.index
                );
    
            this.props.moveCards(reorderedlist)
            }
    } 

    }

    /// tracking what items and cards are most recently clicked e.g., active
    activeItem(action, id){
        let item = {}
        item['id'] = id
        item['action'] = action
        this.props.activeCard(item)
    }

    /// creates the droppable list that is the menu items for form-content elements to add to a card
    getMenuContainer(){

        return(
            <Droppable droppableId={"cardmenu"} isDropDisabled={true}>

            {(provided, snapshot) => (
                <div
                ref={provided.innerRef}
                style={getCardStyle(snapshot.isDraggingOver)}
                >

            <div style={{display:"flex", flexDirection:"column", justifyContent:"space-around", alignItems:"stretch", flexGrow:"1"}}>
            {menulist.map((item, index) => (this.getMenuItem(item, index)))}
            </div>
        
            {provided.placeholder}
  
            </div>
            )}

            </Droppable>



        )

    }

    /// corresponding menu draggable items used to add new things to a card
    getMenuItem(item, index){
            let cname = null
            switch(item.name) {
                case "select":
                 cname = "fa fa-caret-square-o-down"
                  break;
                case "toggle":
                    cname = "fa fa-toggle-off"
                  break;
                  case "form":
                    cname = "fa fa-pencil-square-o"
                    break;
                  case "check":
                    cname = "fa fa-check-square-o"
                    break;        
                case "slider":
                    cname = "fa fa-arrows-h"
                    break;
                  case "img":
                    cname = "fa fa-picture-o"
                    break;        
                case "video":
                    cname = "fa fa-youtube-play"
                    break;
                case "list":
                    cname = "fa fa-list-ul"
                    break;
                case "jumbo":
                    cname = "fa fa-text-width"
                    break;
                case "text":
                    cname = "fa fa-font"
                    break;
                default:
                  // code block
              }
              return(
                    <Draggable
                    key={item.id}
                    draggableId={item.id}
                    index={index}
                    type="item">
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                            )}>
                            <div style={{cursor:'pointer', padding:"0", margin:"0"}}>
        
                            <span >
                            <i className={cname} style={{color:"#8f9ba6", fontSize:"1.5em"}} ></i>
                            <p style={{fontSize:"0.8em"}}>{item.name}</p>
                            </span>
        
                            </div>
                        </div>
                    )}
                    </Draggable>
            )
    }

    updatePageTitle = (event) => {
      const target = event.target;
      if(target != null){
        let value = target.value;
        const name = target.name;
        const type = target.type
        let item = {}
        item['name']=name
        item['value']=value
        item['type']=type
        this.props.updatePageTitle(item)
      }
    }

    /// creates a new card on the canvas
    newCard(){
      let id = makeId(5)
      let card = {}
      if (this.props.cards.length>0){
        card = JSON.parse(JSON.stringify(this.props.cards[0]))}
      else {
        card = JSON.parse(JSON.stringify(defaultchoiceprops))}
      card.id = id
      card.optionheader = ""
      card.price = 0
      card.pricemetric = ""
      card.features = []
      card.img = ""
      card.imageSrc = ""
      card.imageTitle = ""
      card.imageCaption = ""
      card.gif =""
      card.video =""
      let copycards = [...this.props.cards]
      copycards.push(card)

      this.setState(prevState => ({
        cardcolor: {
        ...prevState.cardcolor,          
        [id]: this.props.questionprops.noselectcolor}}), ()=> this.props.newCard(copycards))
  }


  renderAllocationCard(){
    return(
      <div style={{display:"flex", marginLeft:"30px"}}>
        <Card style={{backgroundColor:this.props.surveyprops.style.cardheadcolor, color:this.props.surveyprops.style.cardheadtextcolor, height:"50px", width:"50px", marginRight:"5px"}}>
          <p style={{fontSize:"2em", fontWeight:"600", marginLeft:"5px"}}>{this.state.total}</p>
          <p style={{fontSize:"0.5em"}}>allocated</p>
        </Card>
        {this.state.remaining !== 0 &&
        <Card style={{backgroundColor:this.props.surveyprops.style.cardheadtextcolor, color:this.props.surveyprops.style.cardheadcolor, height:"50px", width:"50px", marginRight:"5px"}}>
        <p style={{fontSize:"2em", fontWeight:"600", marginLeft:"5px"}}>{this.state.remaining}</p>
        <p style={{fontSize:"0.5em"}}>remaining</p>
        </Card>
        }
         {this.state.remaining === 0 &&
         <div style={{display:"flex"}}>
        <Card style={{backgroundColor:this.props.surveyprops.style.cardheadcolor, color:this.props.surveyprops.style.cardheadtextcolor, height:"50px", width:"50px", marginRight:"5px"}}>
        <p style={{fontSize:"2em", fontWeight:"600", marginLeft:"5px"}}>{this.state.remaining}</p>
        <span><i className="icon-arrow-up" style={{color:"#f86c6b", marginTop:"0px"}} ></i></span>
        <p style={{fontSize:"0.5em"}}>remaining</p>
       
        </Card>
        <div>
           <span><i className="icon-check" style={{color:"#20a8d8", fontSize:"2em", marginLeft:"3px"}} ></i></span>
        </div>
        </div>
        }

      </div>

    )
  }

   render(){


    return(

      <div style={{width:"100%"}}>
      <ReactTooltip />
        
      <div className = "survey-question-headings" style={{color:this.props.surveyprops.style.questionheadtextcolor}}>
                <div style={{display:"flex", width:"100"}}>

                {this.props.pageprops.showquestiontitle &&
                    <Input 
                        name={"questiontitle"}
                        placeholder={"Question Title..."}
                        value={this.props.pageprops.questiontitle}
                        onChange={this.updatePageTitle}
                        style={{width:"97%", fontSize:"1.7em", fontWeight:"500", borderColor:"#8f9ba640", backgroundColor:"transparent", color:this.props.surveyprops.style.questionheadtextcolor}}    
                        />
                }

                    {this.props.pageprops.showquestiontip &&
                    <a className="icon-info" style={{fontSize:"1.2em", marginLeft:"auto", marginRight:"10px", color:"#5c6873"}} data-place="left" data-tip={this.props.pageprops.questiontip}/>
                    }
                    </div>
                
                {this.props.pageprops.showquestionsubtitle &&
                    <Input 
                        name={"questionsubtitle"}
                        value={this.props.pageprops.questionsubtitle}
                        placeholder={"Question Subtitle..."}
                        type={"textarea"}
                        rows={"2"}
                        onChange={this.updatePageTitle}
                        style={{width:"97%", fontSize:"1.2em", fontWeight:"200",  borderColor:"#8f9ba640", backgroundColor:"transparent", color:this.props.surveyprops.style.questionheadtextcolor}}  
                        />
                }
        </div>

      <div className = "survey-row-of-cards">

      <DragDropContext onDragEnd={this.onDragEnd}>
        <div style={{display:"flex"}}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
              {...provided.droppableProps}
            >

              {this.state.packagesonpage.map((card, index) => (
                <Draggable draggableId={card.id} index={index} key={card.id}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                          <div style={{backgroundColor:'transparent', border:"0", fontSize:"1em", padding:"10px", cursor:"pointer"}}>
                          <span {...provided.dragHandleProps}><i className="icon-frame" style={{color:"#8f9ba6", fontSize:"0.8em", marginRight:"3px"}} ></i></span>

                          <RenderChoiceContent
                            key={card['id']}
                            maxcardwidth={this.state.mwidth}
                            surveyprops={this.props.surveyprops}
                            option={card}
                            selectvalue={this.state.selectvalue}
                            validallocationamount={this.state.validallocationamount}
                            remaining={this.state.remaining}
                            value={this.state.selectvalue[card['id']]}
                            cardcolor={this.state.cardcolor[card['id']]}
                            questionprops={this.props.questionprops}
                            questionId={this.props.questionId}
                            onSelectionChange={this.changeSelection}
                            onAllocationChange={this.changeAllocation}
                            onMaxDiffChange={this.changeMaxDiff}
                            onRadioCheckToggle={this.toggleRadioCheck}
                            activeCard={this.props.activeCard}
                            currentcard={this.props.currentcard}
                            currentpage={this.props.currentpage}
                            newCard={this.props.newCard}
                            toggleLightbox={this.props.toggleLightbox}
                            activeQuestion={this.props.activeQuestion}
                            packages={this.props.packages}
                            swapCard={this.props.swapCard}
                            packagelist={this.state.packagelist}
                            packagesonpage={this.state.packagesonpage}
                            />
                          </div>

                    </div>
                  )}
                </Draggable>


              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        </div>
      </DragDropContext>
            
            {this.state.packagesonpage.length === 0 &&

                <Card style={{height:"25%", marginTop:"100px", backgroundColor:"#2f353aD9", alignSelf:"center"}}>
                <CardBody>
                <div className="h1 mt-0 mb-0" style={{fontSize:"2em"}}></div>
                <div><small className="text-muted text-uppercase font-weight-bold">{`Placeholder`}</small></div>
                <div><small className="text-muted text-uppercase font-weight-light">{`Prior selected choices will be assembed on this page to be tested against each other`}</small></div>
                </CardBody>
                </Card>

            }


      </div>
      

      {this.props.questionprops.selectiontype === "allocation" &&
          this.renderAllocationCard()
        }
      </div>

    );
  }
}
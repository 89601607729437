import React, { Component } from "react";
import { API } from "aws-amplify";
import {Link} from "react-router-dom"
import { Button, Card, CardBody, CardGroup, CardHeader, CardFooter, Col, Container, Row, Input, Jumbotron } from 'reactstrap';
import Loader from "react-loader-spinner";
import SolidInfoCard from '../components/SolidInfoCard'
import DashBar from '../components/DashBar'
import DashHeatmap from '../components/DashHeatmap'
import ResponseToggleCard from '../components/ResponseToggleCard'
import Select from 'react-select';
import Toggle from 'react-toggle'

function getDate(){
  let today = new Date();
  let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
  return(date)
}

/// styling for react-select
const customStyles = (isValid) => ({
    option: (provided, state) => ({
        ...provided,
        padding: 20,
      }),
    control: (styles: any, state:any) => ({
      ...styles,
      padding: 5,
      width: "100%",
      fontSize: "smaller",
      color:"#20a8d8",
      ...(!isValid && { borderColor: 'red', color: 'black' }),
    }),
  });


const stest = [{'value': 'OK', 'label': 'nope'},{'value':'NOPE', 'label':"Digit"}]


export default class Crosstabs extends Component {
    constructor(props) {
      super(props);
      let nums = {}
      let now = new Date()

      nums['numberresponses']=0
      nums['numbercomplete']=0
      nums['avgtime']=0
      nums['todayresponse']=0
      let applyquota = false
      let quota = 0
      let passwordrequired = false
      let password = "None"
      let singleresponse = true
      let scheduleend = false
      let surveystart = null
      let surveyend = null
      let surveydescription = null
      this.updateCard = this.updateCard.bind(this)
      this.routeAction = this.routeAction.bind(this)
      let initialrow = {}
      initialrow['responseId']=null
      this.state = {
        isLoading: true,
        isFiltered: false,
        updatebutton: false,
        numberresponses:nums,
        questionlist: [],
        data:{},
        activequestion: null,
        applyquota: applyquota,
        quota: quota,
        passwordrequired :passwordrequired,
        password: password,
        singleresponse: singleresponse,
        scheduleend: scheduleend,
        surveystart: surveystart,
        surveyend: surveyend,
        surveydescription: surveydescription,
        targetId: "None",
        showcrosstabs: false,
        minduration: false,
        maxdaysago: false,
        onlycompletes: false,
        mindurationamount: null,
        maxdaysagoamount: null,
      };
    }


    async componentDidMount() {
      if (!this.props.isAuthenticated) {return};

      /// to handle page refresh will try to reset props from storage

      if(this.props.orgId === null){
        try{
          this.props.userHasEmail(localStorage.getItem('email'))
          this.props.userHasId(localStorage.getItem('userId'))
          this.props.userHasRole(localStorage.getItem('userRole'))
          this.props.userHasLicense(localStorage.getItem('userLicense'))
          this.props.userHasProduct(localStorage.getItem('userProduct'))
          this.props.userHasOrg(localStorage.getItem('orgId'))
        } catch {
          this.props.history.push("/home")
        }
      }

      if(this.props.activeSurvey === null){
        this.props.history.push("/surveys")
      }

      if(this.props.activeSurvey !== null){


        try{
            let querystring = {}
            let orgquery = {}
            orgquery['orgId']=this.props.orgId
            orgquery['querytype']=this.props.activeSurvey
            orgquery['timefilter']="None"
            orgquery['durationfilter']="None"
            orgquery['statusfilter']="None"
            orgquery['targetId']="None"
            querystring['queryStringParameters'] = orgquery
            let allresponses = {}


            if (this.props.activeSurvey !== null){
              allresponses = await API.get("charts", "/charts", querystring)
 
            } else {
              allresponses['summary'] = [{}]
            }
          
            let start = null
            let end = null
            let today = new Date()
            let diff = 0.0
            let tottime = 0.0
            let totcomplete = 0
            let numberresponses = 0
            let todayresponse = 0
            numberresponses = allresponses['summary'].length

            for (let r in allresponses['summary']){

              start = new Date(allresponses['summary'][r]['surveystart'])
              end = new Date(allresponses['summary'][r]['lastupdate'])
              let isToday = today - end
              
              if(isToday/1000/60/60/24 <1){ todayresponse = todayresponse + 1}

              diff = end - start
              diff = diff / 1000 / 60
              allresponses['summary'][r]['surveytime'] = diff.toFixed(2)
              tottime = tottime + diff
              
              if(allresponses['summary'][r]['surveystatus']==="RESPONDENT COMPLETE"){totcomplete = totcomplete + 1}
            
            }

            let avgtime = 0
            if (numberresponses !== 0){avgtime = tottime/numberresponses}


            let nums ={}       
            nums['numberresponses']=numberresponses
            nums['numbercomplete']=totcomplete
            nums['avgtime']=avgtime.toFixed(2)
            nums['todayresponse']=todayresponse
            let questionlist = Object.keys(allresponses['counts'])
            let selectorlist = []
            selectorlist.push({'label': "SHOW ALL", 'value': "SHOW ALL"})
            for (let q of questionlist){
              let qi = {'label':allresponses['questioninfo'][q]['header'], 'value':q}
              selectorlist.push(qi)
            }

            this.setState({
              data:allresponses['counts'],  
              crosstabs:allresponses['crosstabs'], 
              filtercharts:allresponses['filtercharts'],
              allselections:allresponses['allselections'],
              questioninfo:allresponses['questioninfo'],
              questionlist:questionlist,
              selectorlist:selectorlist,
              isLoading:false}, ()=>this.updateCard(nums))

        } catch (e) {
            alert('There was problem getting response charts', e);
        }
        }

    }

    routeAction(survey, stat){
        this.props.history.push("/surveys/responses/")
      }


    updateCard(n){
      this.setState({numberresponses:n})
    }


    getHeadline(){
      if (this.props.activeSurveyData !== null) {

        if(typeof this.props.activeSurveyData.status !== 'undefined'){

            if(this.props.activeSurveyData.status === 'LIVE'){
                return(
                <div style={{display:"flex", marginTop:"15px"}}>
                  <p>Up to the minute data for survey: <mark>{this.props.activeSurvey}</mark></p>
                  <p style={{color:"#8f9ba6", marginLeft:"5px"}}> {this.props.activeSurveyData.surveytitle}</p>
                  <p style={{color:"#8f9ba6", marginLeft:"5px"}}> |  Average time per survey: <mark>{this.state.numberresponses['avgtime']}</mark></p>
                  <p style={{color:"#8f9ba6", marginLeft:"5px"}}> |  Taken in last 24 hours: <mark>{this.state.numberresponses['todayresponse']}</mark></p>
                </div>
                )
              }
    
              if(this.props.activeSurveyData.status === 'COMPLETE'){
                return(
                  <div style={{display:"flex", marginTop:"15px"}}>
                  <p>Results for survey: <mark>{this.props.activeSurvey}</mark></p>
                  <p style={{color:"#8f9ba6", marginLeft:"5px"}}> {this.props.activeSurveyData.surveytitle}  |  Published: {this.props.activeSurveyData.surveystart} |  End: {this.props.activeSurveyData.surveyend}</p>
                </div>
                )
              }

        } else {

          return(
            <p style={{marginTop:"15px"}}>Data for survey: <mark>{this.props.activeSurvey}</mark> </p>
          )

        }
      }
    }

    handleSelectChange = (selectvalue:any, action: any) => {
        if(selectvalue['value']!=='SHOW ALL'){
          this.setState({targetId:selectvalue['value'], updatebutton:true})
        } else {
          this.setState({targetId:selectvalue['value'], isFiltered:false, updatebutton:true})
        }
    };

    /// calls Selection Change callback:  Used by TOGGLE
    handleToggleChange(i){
        this.setState({i: !this.state[i], updatebutton:true})
        }

        
    renderNoFilterChart(qid, chart){

        let chartwidth = "500px"
        let bord = "#c8ced3"
        let bg = "#FFFFFF"
        let bdw ="1px"

        if (chart.length > 5){
          let cw = chart.length * 80
          chartwidth = cw.toString()+"px"
        }
        let hasnone = false
        let chtkeys = ['responses']
        for (let x of chart){
          if (x['answer'] === "None*"){
            hasnone = true
            chtkeys = ['responses', 'noresponses']
          }
        }

        return(
            <div style={{marginLeft:"10px", marginRight:"10px", width:chartwidth}}>
                <Card style={{borderColor:bord, backgroundColor:bg, borderWidth:bdw, flexGrow:"1"}}>

                    <CardHeader>
                    {this.state.questioninfo[qid]['header']}
                    </CardHeader>

                    <DashBar
                        data={chart}
                        groupmode={"stacked"}
                        chartkeys={chtkeys}
                        chartindex={'answer'} 
                        chartwidth={chartwidth}                
                    
                    />

                    <CardFooter style={{fontSize:"0.6em"}}>
                    {`${qid} : ${this.state.questioninfo[qid]['pagetype']} | ${this.state.questioninfo[qid]['type']} `}
                     {hasnone &&
                    <div>
                    {`* None = no answer to this question `}
                    </div>
                    }
                    </CardFooter>
                </Card>
            </div>
        )
    }


    renderFilterChart(qid, chart){

      let chartitems = new Set()
      for(let anz of chart){
        for(let idx of Object.keys(anz)){
          if(idx != 'answer'){
            chartitems.add(idx.toString())
          }
        }
      }

      let chartkeys = Array.from(chartitems)

      let chartwidth = "500px"
      let bord = "#c8ced3"
      let bg = "#FFFFFF"
      let bdw ="1px"
      if(qid === this.state.targetId){
        bord = "#20a8d8"
        bg ="#FFFFFFD9"
        bdw = "3px"
      }

      if (chart.length > 5){
        let cw = chart.length * 80
        chartwidth = cw.toString()+"px"
      }

      let hasnone = false
      for (let x of chart){
        if (x['answer'] === "None*"){
          hasnone = true
          
        }
      }


      return(
          <div style={{marginLeft:"10px", marginRight:"10px", width:chartwidth}}>

              {!this.state.showcrosstabs &&
              <Card style={{borderColor:bord, backgroundColor:bg, borderWidth:bdw, flexGrow:"1"}}>

                  <CardHeader>
                  {this.state.questioninfo[qid]['header']}

                  {this.state.targetId !=='SHOW ALL' && this.state.targetId !== 'None' &&
                    <p style={{fontSize:"0.5em"}}>by responses to {this.state.questioninfo[this.state.targetId]['header']}</p>
                  }
                  </CardHeader>

                  <DashBar
                      data={chart}
                      groupmode={"grouped"}
                      chartkeys={chartkeys}
                      chartindex={'answer'}  
                      chartwidth={chartwidth}                
                  
                  />

                  <CardFooter style={{fontSize:"0.6em"}}>
                  {`${qid} : ${this.state.questioninfo[qid]['pagetype']} | ${this.state.questioninfo[qid]['type']} `}
                    {hasnone &&
                    <div>
                    {`* None = no answer to this question `}
                    </div>
                    }
                  </CardFooter>
              </Card>
              }
              
              {this.state.showcrosstabs &&
              <Card style={{borderColor:bord, backgroundColor:bg, borderWidth:bdw, flexGrow:"1"}}>

                <CardHeader>
                {this.state.questioninfo[qid]['header']}
                {this.state.targetId !=='SHOW ALL' && this.state.targetId !== 'None' &&
                    <p style={{fontSize:"0.5em"}}>by responses to {this.state.questioninfo[this.state.targetId]['header']}</p>
                }
                </CardHeader>

                <DashHeatmap
                    data={chart}
                    groupmode={"grouped"}
                    chartkeys={chartkeys}
                    chartindex={'answer'}  
                    chartwidth={chartwidth}                

                />

                <CardFooter style={{fontSize:"0.6em"}}>
                {`${qid} : ${this.state.questioninfo[qid]['pagetype']} | ${this.state.questioninfo[qid]['type']} `}
                  {hasnone &&
                  <div>
                  {`* None = no answer to this question `}
                  </div>
                  }
                </CardFooter>
                </Card>
                }
          </div>
      )
    }



    getFilter(){
        return(
          <Button disabled={!this.state.updatebutton} color="primary" size="sm" onClick={()=>this.filterResults()}>
            OK
          </Button>
        )
    }

    /// updates the question header text without need to open modal
    updateFilterProps = (event) => {
      const target = event.target;
      if(target != null){
          let value = target.value;
          const name = target.name;
          this.setState({[name]:value, updatebutton:true})
      }
      }


      /// not using the isFiltered toggle in state yet - will do it to update the result counts in an infocard

      filterResults(){
        this.setState({isLoading:true, updatebutton:false},()=>this.updateCharts())
      }


      async updateCharts(){

        let target = this.state.targetId
        let mindur = this.state.mindurationamount
        let maxage = this.state.maxdaysagoamount
        let onlycomp = "Yes"
        if(this.state.targetId==="SHOW ALL"){target = "None"}
        if(this.state.minduration === false){mindur = "None"}
        if(this.state.maxdaysago === false){maxage = "None"}
        if(this.state.onlycompletes === false){onlycomp = "None"}

        if(target==="None" && mindur ==="None" && maxage === "None" && onlycomp === "None"){
          this.setState({targetId:"SHOW ALL", isLoading:false, isFiltered:false})
        } else {

            try{
              let querystring = {}
              let orgquery = {}
              orgquery['orgId']=this.props.orgId
              orgquery['querytype']=this.props.activeSurvey
              orgquery['timefilter']=maxage
              orgquery['durationfilter']=mindur
              orgquery['statusfilter']=onlycomp
              orgquery['targetId']=target
              querystring['queryStringParameters'] = orgquery
              let newresponses = {}


              if (this.props.activeSurvey !== null){
                newresponses = await API.get("charts", "/charts", querystring)

              } else {
                newresponses['summary'] = [{}]
              }
            
              let filterresponses = newresponses['summary'].length

              this.setState({
                crosstabs:newresponses['crosstabs'], 
                filtercharts:newresponses['filtercharts'],
                isFiltered:true,
                isLoading:false})

          } catch (e) {
              alert('There was problem getting response charts', e);
          }
      }
    }


    render() {
        return (
          <div>

          <Jumbotron style={{marginBottom:"30px", marginTop:"0px", padding:"0px"}}>
          <h1>Charts and Correlations</h1>
          <h5 style={{fontWeight:"200"}}>Crosstabs and filtering of survey responses</h5>
          <hr/>
          {this.getHeadline()}
          
          </Jumbotron>

            <Row>
                <Col sm="6" md="2">
                  <SolidInfoCard  color="dark" header={this.state.numberresponses['numberresponses']} value="100" invert >Total Responses</SolidInfoCard>
                </Col>
                <Col sm="6" md="2">
                  <SolidInfoCard  color="info" header={this.state.numberresponses['numbercomplete']} value="100" >Total Completes</SolidInfoCard>
                </Col>
                <Col sm="6" md="4">
                    <div style={{display:"flex", alignItems:"stretch"}}>
                    <Card style={{width:"100%", padding:"0px"}}>
                    <div style={{display:"flex", flexDirection:"column", marginLeft:"15px", marginRight:"15px", marginBottom:"0px"}}>
                    
                      <div style={{display:"flex", justifyContent:"space-around", alignItems:"flex-start"}}>

                        <div style={{display:"flex", flexDirection:"column", flexGrow:"1"}}>
                        <small className="text-muted text-uppercase font-weight-bold" style={{marginTop:"15px", marginBottom:"15px"}}>Correlate with:</small>
                        <Select
                            defaultValue={this.state.targetId}
                            onChange={this.handleSelectChange}
                            options={this.state.selectorlist}
                            name={"targetId"}
                            styles={customStyles(true)} 
                        />
                        </div>
                        {this.state.minduration &&
                        <div style={{display:"flex", flexDirection:"column", width:"75px", marginLeft:"5px"}}>
                        <small className="text-muted text-uppercase font-weight-bold" style={{marginTop:"15px", marginBottom:"15px", }}>Duration</small>
                        <Input 
                          name={"mindurationamount"}
                          value={this.state.mindurationamount}
                          type={"number"}
                          onChange={this.updateFilterProps}
                          style={{backgroundColor:"transparent", borderColor:"#8f9ba640" }}  
                          />
                        </div>
                        }
                        {this.state.maxdaysago &&
                        <div style={{display:"flex", flexDirection:"column", width:"75px", marginLeft:"5px"}}>
                        <small className="text-muted text-uppercase font-weight-bold" style={{marginTop:"15px", marginBottom:"15px", }}>Days Ago</small>
                        <Input 
                          name={"maxdaysagoamount"}
                          value={this.state.maxdaysagoamount}
                          type={"number"}
                          onChange={this.updateFilterProps}
                          style={{backgroundColor:"transparent", borderColor:"#8f9ba640"}}  
                          />
                        </div>
                        }

                      </div>

                      <div style={{display:"flex", justifyContent:"space-around", alignItems:"flex-end", marginBottom:"15px"}}>

                        <div style={{display:"flex", flexDirection:"column"}}>
                        <small className="text-muted text-uppercase font-weight-bold" style={{marginTop:"15px", marginBottom:"15px"}}>Only Completes</small>
                        <Toggle
                                id={"onlycompletes"}
                                value={"onlycompletes"}
                                checked= {this.state.onlycompletes}
                                icons={{unchecked: null}}
                                onChange = {() => this.setState({onlycompletes: !this.state.onlycompletes, updatebutton:true})}
                            />
                        </div>

                        <div style={{display:"flex", flexDirection:"column"}}>
                        <small className="text-muted text-uppercase font-weight-bold" style={{marginTop:"15px", marginBottom:"15px"}}>Min Duration</small>
                        <Toggle
                                id={"minduration"}
                                value={"minduration"}
                                checked= {this.state.minduration}
                                icons={{unchecked: null}}
                                onChange = {() => this.setState({minduration: !this.state.minduration})}
                            />
                        </div>

                        <div style={{display:"flex", flexDirection:"column"}}>
                        <small className="text-muted text-uppercase font-weight-bold" style={{marginTop:"15px", marginBottom:"15px"}}>Max Days Ago</small>
                        <Toggle
                                id={"maxdaysago"}
                                value={"maxdaysago"}
                                checked= {this.state.maxdaysago}
                                icons={{unchecked: null}}
                                onChange = {() => this.setState({maxdaysago: !this.state.maxdaysago})}
                            />
                        </div>

                        <div style={{display:"flex", flexDirection:"column"}}>
                        <small className="text-muted text-uppercase font-weight-bold" style={{marginTop:"15px", marginBottom:"15px"}}>Crosstabs</small>
                        <Toggle
                                id={"showcrosstabs"}
                                value={"crosstab"}
                                checked= {this.state.showcrosstabs}
                                disabled={this.state.targetId == "None" || this.state.targetId == "SHOW ALL"}
                                icons={{unchecked: null}}
                                onChange = {() => this.setState({showcrosstabs: !this.state.showcrosstabs})}
                            />
                        </div>

                        <div>{this.getFilter()}</div>
                      </div>
                    
                    </div>
                    </Card>
                    </div>
                </Col>
                <Col>
                <div style={{display:"flex", flexDirection:"column", justifyContent:"flex-end", height:"100%", paddingBottom:"1.5rem"}}>
                <div>
                <Link to={`/surveys/responses/analysis`} style={{color:"primary"}} activeStyle={{color:"white"}}>Survey Analysis</Link>
                </div>
                <div style={{marginTop:"10px"}}>
                <Link to={`/surveys/responses`} style={{color:"primary"}} activeStyle={{color:"white"}}>Survey Responses</Link>
                </div>
                </div>
                 </Col>

              </Row>

              <hr/>


              {this.state.isLoading && <Loader type="Puff" color="#20a8d8" height={100} width={100} timeout={3000}/>}

              <div style={{marginRight:"30px", marginLeft:"15px", width:"95%"}}>


              <div style={{display:"flex", flexWrap:"wrap", width:"100%", alignItems:"stretch"}}>


              {this.props.orgId && this.state.isFiltered === false &&

                  this.state.questionlist.map((chart, key=chart)=>this.renderNoFilterChart(chart, this.state.data[chart]))

              }

              {this.props.orgId && this.state.isFiltered === true &&

                this.state.questionlist.map((chart, key=chart)=>this.renderFilterChart(chart, this.state.filtercharts[chart]))

              }

              </div>

          </div>

          </div>
    
        );
      }
    }
    
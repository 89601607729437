import React, { Component } from "react";
import {Container,Jumbotron,} from 'reactstrap';


export default class Welcome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

showIntro(){
  return(
    <div>
    <Jumbotron>
      <h1>Congratulations!</h1>
      <h4>You now have the best product, packaging and concept testing tools ready for use</h4>
      <hr/>
      <p>Let's get started...</p>
    </Jumbotron>

  </div>
  )
}
 
  render() {
    return (
      <Container>


        <div className="survey-wrapper"> 
        {this.showIntro()}
        </div>
      </Container>


    );
  }

}

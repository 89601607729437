import React, { Component } from "react";
import {  Button, Input, Row, Col, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Jumbotron,  ModalHeader, ModalBody, ModalFooter, CustomInput} from 'reactstrap';
import Toggle from 'react-toggle'
import 'react-toggle/style.css'



export default class CardEditorModal extends Component {
  constructor(props) {
    super(props);
    let currentcardindex = null
    for(let i in this.props.page.questions[0].cards){
        if (this.props.page.questions[0].cards[i].id === this.props.currentcard){
            console.log('got a hit at', i)
            currentcardindex = i
        }
    }
    this.state = {
        isValid: false,
        isdeleting: false,
        currentcardindex: currentcardindex,
        cardcopy: JSON.parse(JSON.stringify(this.props.page.questions[0].cards[currentcardindex]))

      };
    }
   
  async componentDidMount(){ 
  }

  deleteCard(){
    let cardprops = {}
    cardprops['type']=this.props.modalediting
    cardprops['questionId']=this.props.page.questions[0].questionId
    cardprops['cardId']=this.props.currentcard
    cardprops['questionId']=this.props.currentquestion
    this.props.deleteCard(cardprops)
}

    saveCard(){
        let card = {}
        card['type']=this.props.modalediting
        card['questionId']=this.props.page.questions[0].questionId
        card['cardId']=this.props.currentcard
        card['questionId']=this.props.currentquestion
        card['card'] = this.state.cardcopy
        card['questionprops'] = this.props.page.questions[0].questionprops
        this.props.saveSurveyCard(card)
    }

    /// updates form field entry and then verifies if valid
    handleChange = event => {
        let fieldname = event.target.name
        let value = event.target.value
        this.setState(prevState => ({
            cardcopy: {                   
                ...prevState.cardcopy,    
                [fieldname]:value
        }}))
    }


    /// updates the toggle switch and then validates
    changeSelection(id, e){

        this.setState(prevState => ({
            cardcopy: {                   
                ...prevState.cardcopy,    
                [id]:e
            }}))
    }

  render(){

    return(
    <div>
        <ModalHeader style={{backgroundColor:"#2f353a", color:"#8f9ba6", fontSize:"1.2em", fontWeight:"200"}}>
            {`Properties for card ${this.props.currentcard} `}
            {this.state.isdeleting &&
            <p style={{color:'#f86c6b', fontSize:"0.8em", fontWeight:"200", marginTop:"0px"}}>Please confirm that you want to delete this card and all it's contents</p>
            }
            </ModalHeader>
        <ModalBody>

        <p style={{marginBottom:"5px"}}><small>Card Title</small></p>
            <Input type="text" name={"optionheader"} id={"optionheader"}
            value={this.state.cardcopy.optionheader}
            style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
            onChange={this.handleChange}
            />
        
        <p style={{marginBottom:"5px"}}><small>Card Help Message</small></p>
            <Input type="text" name={"helpmessage"} id={"helpmessage"}
            value={this.state.cardcopy.helpmessage}
            style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
            onChange={this.handleChange}
            />

            <div style={{ padding:"30px", marginLeft:"-16px", marginRight:"-16px", marginBottom:"-16px", marginTop:"50px", color:"white", fontSize:"0.8em", backgroundColor:"#2f353a" }}>
                

                <Row style={{display:"flex", alignItems:"flex-end"}}>
                    <Col>
                    <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Show Card Title</p>
                            <Toggle
                            id={"showtitle"}
                            checked= {this.state.cardcopy.showheader}
                            icons={{unchecked: null}}
                            className = {"custom-success"}
                            onChange = {() => this.changeSelection("showheader", !this.state.cardcopy.showheader)}
                                />
                    </Col>

                    <Col>
                    <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Show Card Help Message</p>
                    <Toggle
                            id={"showquestiontitle"}
                            checked= {this.state.cardcopy.showfooter}
                            icons={{unchecked: null}}
                            className = {"custom-success"}
                            onChange = {() => this.changeSelection("showfooter", !this.state.cardcopy.showfooter)}
                                />
                    </Col>

                </Row>
                
 
            </div>

        </ModalBody>
        <ModalFooter style={{marginTop:"0"}}>
        <div style={{alignSelf:"flex-start", marginRight:"auto"}}>
        {!this.state.isdeleting &&
        <Button color="danger" onClick={()=>this.setState({isdeleting:true})}>Delete Card</Button>
        }
        {this.state.isdeleting &&
        <Button color="danger" onClick={()=>this.deleteCard()}>Confirm Delete</Button>
        }
        </div>
        {this.state.isdeleting &&
        <Button color="secondary" onClick={()=>this.setState({isdeleting:false})} >Cancel Delete</Button>
        }
        {!this.state.isdeleting &&
        <div>
        {'  '}
        <Button color="primary" onClick={()=>this.saveCard()} >Save</Button>
        {'  '}
        <Button color="secondary" onClick={()=>this.props.toggleModal()} >Cancel</Button>
        </div>
        }
        </ModalFooter>
  </div>



    );
  }
}
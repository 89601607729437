import React, { Component } from "react";
import { API } from "aws-amplify";
import Loader from "react-loader-spinner";
import './SurveyEditor.scss';
import {Button,  Card, CardBody, CardFooter, Nav, NavLink, Jumbotron, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import SurveyCanvas from "../survey/SurveyCanvas"
import DraggableTabs from "../survey/DraggableTabs"
import { ToastContainer} from 'react-toastify';
import Lightbox from 'react-image-lightbox';
import 'react-toastify/dist/ReactToastify.css';
import ContentEditorModal from "./ContentEditorModal"
import ChoiceEditorModal from "./ChoiceEditorModal"
import ListEditorModal from "./ListEditorModal"
import ColumnEditorModal from "./ColumnEditorModal"
import PageEditorModal from "./PageEditorModal"
import CardEditorModal from "./CardEditorModal"
import SurveyStyleModal from "./SurveyStyleModal"
import DesignModal from "./DesignModal"
import LayoutHelper from '../../node_modules/@coreui/react/lib/Shared/layout/layout'



/// **** EDITOR **********************************************************


const defaultnewsurvey ={ 
    "surveyprops":{
        "style":{
            'featurewcolwidth': '40%',
            'mincardwidth': '250px',
            'showbrandheader': true,
            'forwardbuttoncolor': '#832161',
            'backbuttoncolor': '#f86c6b',
            'completebuttoncolor': '#20a8d8',
            'buttontextcolor': '#ffff00',
            'pagebodycolor': '#ffffff',
            'pagefootcolor': '#c8ced3',
            'pageheadtextcolor': '#3D2645',
            'questionheadtextcolor': '#2f353a',
            'formheadcolor': '#c8ced3',
            'formfootcolor': '#c8ced3',
            'formbodycolor': '#f0f3f5',
            'formheadtextcolor': '#ffffff',
            'formcardtextcolor': '#2f353a',
            'formfoottextcolor': '#ffffff',
            'contentlistbackground': '#f0f3f5',
            'contentlisttext': '#3D2645',
            'contentlistborder': '#f0f3f5',
            'tablecellborders': false,
            'tablebackground': '#f0f3f5',
            'tablebodytext': '#2f353a',
            'tableheadbackground': '#c8ced3',
            'tableheadtext': '#ffffff',
            'jumbobackground': '#c8ced3',
            'jumbotrontext': '#ffffff',
            'jumboline': '#ffc107',
            'cardheadcolor': '#c8ced3',
            'cardfootcolor': '#c8ced3',
            'cardheadtextcolor': '#F9F9ED',
            'cardcardtextcolor': '#2f353a',
            'cardfoottextcolor': '#F9F9ED',
            'featurebackgroundcolor': '#f0f3f5',
            'featureoutlinecolor': '#c8ced3',
            'pricebackgroundcolor': '#c8ced3',
            'pricetextcolor': '#ffffff'
        },
        "questionpropdefaults":
        {
            "questiontitle": "",
            "questionsubtitle": "",
            "questiontip": "",
            "questiontoast": "",
            "helpmessage": "",
            "randomize": false,
            "selectcolor": "#8E9DCC",
            "noselectcolor": "#FFFFFF",
            "touchcolor": "#f05423",
            "buttonname": "Select",
            "bestselectcolor":"#4dbd74",
            "worstselectcolor":"#f86c6b",
            "bestbuttonname": "Best",
            "worstbuttonname": "Worst",
            "selectcreate": true,
            "allocationamount": 0,
            "showLightbox": false,
            "validationtype": "none",
            "selectiontype": "discrete",
            "showprice": false, /// need to wire this into default
            "slidermin":0,
            "slidermax":100,
            "sliderstep": 25,
            "slidermarks": { 0: "A", 25: "B", 50: "C", 75: "D", 100: "E" },
            "sliderdefault": null,
            "rangedefault":[0,100],
            "allocationamount":100,
            "selectoptions":[],
        },
        "pagepropdefaults":
        {
            "thispage":null,
            "totalpages": null,
            "isfirst": false,
            "islast": true,
            "type": "NewPage",
            "showtitle": false,
            "pagetitle":"",
            "pagesubtitle":null,
            "backbutton":"BACK",
            "nextbutton":"NEXT",
            "completebutton": "COMPLETE SURVEY",
            "showpagenums": false,
            "showpagehint": false,
            "pagehint": "",
            "questiontype":"NewPage",
            "questiontitle":"",
            "questionsubtitle": "",
            "showquestiontip": false,
            "showquestiontoast": false,
            "questiontip": "",
            "questiontoast": "",
            "showquestiontitle": true,
            "showquestionsubtitle": true,
            "randomizechoices":false,
            "autoassembled": false,
            "testwinners": false,
            "testprices": false,
            "alwaysshow": null,
            "pricerange": 0,
            "assembledchoices":2,
        },
        "surveysettings":
        {
            "showlogo": false,
            "passwordrequired": false,
            "applyquota": false,
            "singleresponse":false,
            "surveydescription": "",
            "quota": 0,
            "password": "None",
            "status": "DRAFT",
            "localsavetime": "None",
            "notifications": false,
            "logosrc": "None",
            "schedulepublish": false,
            "scheduleend": false,
            "surveystart": "",
            "surveyend": "",
            "surveyurl": "None",
            "publishtest": false,
            "thankyoumessage": "thank you for participating in this survey...",
            "showcompleteurl": false,
            "completeurl":"https://pricekit.io/",
        },
        "packageoptions": [],
    },

    "pageorder": [
        {"pageId":"SurveyProps", "description":"SurveyProps", "isFirst":true, "isLast":true},
    ],

    "pages":    
    {
        "SurveyProps":
        {
        "isSaved" : false,
        "pageprops":
            {
                "thispage":1,
                "totalpages": 1,
                "isfirst": true,
                "islast": false,
                "type": "SurveyProps",
                "showtitle": false,
                "pagetitle":"Suvey Settings",
                "pagesubtitle":null,
                "backbutton":"BACK",
                "nextbutton":"NEXT",
                "showpagenums": false,
                "showpagehint": false,
                "pagehint": null,
                "questiontype":"SurveyProps",
                "questiontitle":"",
                "questionsubtitle": "",
                "showquestiontip": false,
                "showquestiontoast": false,
                "questiontip": null,
                "questiontoast": null,
                "showquestiontitle": false,
                "showquestionsubtitle": false,
                "randomizechoices":false,
            },
        "questions ": [{}],
        },

    }
}

const choicecarddefaults = {
    id: null,
    showimage: false,
    showgif: false,
    showvideo: false,
    gif: "",
    video: "",
    showfeatures:true,
    optionheader: "",
    img: "",
    imageSrc: null,
    imageTitle: null,
    imageCaption: null,
    imageFit: true,
    randomize: false,
    showprice: false,
    price: 0,
    pricemetric: "",
    togglevalue: "",
    annotations:null,
    showquestionhelpmessage: false,
    helpmessage: "",
    features:[],

}
const surveycarddefaults = {
    id: null,
    optionheader: "",
    showheader: true,
    showfooter: false,
    helpmessage: "",
    content:[],
}
const designdefaults = {
        "questionId":null,
        "questiontype":"DesignCard",
        "questionprops":
        {
            "questiontitle":"",
            "questionsubtitle": "",
            "questiontip": "",
            "questiontoast": "",
            "helpmessage": null,
            "randomize":false,
            "selectcolor":null,
            "noselectcolor":null,
            "touchcolor": null,
            "buttonname":null,
            "bestselectcolor":null,
            "worstselectcolor":null,
            "bestbuttonname":null,
            "worstbuttonname":null,
            "selectcreate": true,
            "allocationamount": false,
            "selectiontype": "toggle",
        },

        "cards":
        [
            {
                "id":null,
                "showimage": true,
                "showgif": false,
                "showvideo":false,
                "showfeatures": true,
                "featureheader": "",
                "optionheader": "",
                "showheader": true,
                "showfooter": true,
                "helpmessage": "",
                "img": null,
                "imageSrc": "https://pricekit-survey.s3.amazonaws.com/public/Default/pricekit-placeholder-image.png",
                "imageTitle": null,
                "imageCaption": null,
                "gif": null,
                "video":null,
                "randomize": true,
                "showprice": false,
                "price": 0,
                "pricemetric": "",
                "togglevalue":null,
                "togglestyle":null,
                "slidermin":0,
                "slidermax":100,
                "sliderstep": 25,
                "slidermarks": { 0: "", 25: "", 50: "", 75: "", 100: "" },
                "sliderdefault": null,
                "rangedefault":[0,100],
                "allocationamount":null,
                "selectoptions": [],
                "disableannotation":false,
                "showannotation":true,
                "annotations": [],
                "features":
                    [
                    ],
            },

        ]
        }
const defaultlistprops = {randomize: true, showprice: false, type: "featurelist", rowheaderid: null}
const defaultlisthead = [{"id":null, "header":""}]
const defaultlistrow = []
const defaultlistcolumn = []

function makeId(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }

function getTime(){
    let today = new Date();
    let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    let dateTime = date+' '+time;
    return(dateTime)
}

function isMobileDevice() {
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
};

const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
const vh = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

export default class SurveyEditor extends Component {
  constructor(props) {
    super(props);

    this.changePage = this.changePage.bind(this)
    this.newPage = this.newPage.bind(this)
    this.toggleUpdatedItem = this.toggleUpdatedItem.bind(this)
    this.updatePageorder = this.updatePageorder.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
    this.activeQuestion = this.activeQuestion.bind(this)
    this.activeCard = this.activeCard.bind(this)
    this.updateNewPageType = this.updateNewPageType.bind(this)
    this.newCard = this.newCard.bind(this)
    this.toggleLightbox = this.toggleLightbox.bind(this)
    this.saveSurvey = this.saveSurvey.bind(this)
    let isMobile = isMobileDevice()

    let currentpage = null
    let totpages = 0

    this.state = {
        isLoading: true,
        isNew: true,
        imgSrc:null,
        showImage: false,
        isMobile: isMobile,
        vw: vw,
        vh: vh,
        imageTitle:null,
        imageCaption:null,
        currentpagenum: 0,
        totalpages: totpages,
        surveyprops: null,
        pageflow: null,
        pageorder: null,
        pages: null,
        page: null,
        currentpage: currentpage,
        survey: null,
        showSidebar: false,
        sidebarType: null,
        isnewpage: false,
        updateditem: false,
        showModal: false,
        currentquestion:null,
        currentcard:null,
        modalediting:null,
        modalsize:"md",
        isSaved: true,
        featurelist: [],
        featuretype:[],
        packages:[],
      };
    }
   
    async componentDidMount() {

        let refresponse = []
        let flresponses = []
        let pkgresponses = []
        let querystring = {}

        LayoutHelper.sidebarToggle(true)

        if (!this.props.isAuthenticated) {return};
  
        /// to handle page refresh will try to reset props from storage
  
        if(this.props.orgId === null){
          try{
            this.props.userHasEmail(localStorage.getItem('email'))
            this.props.userHasId(localStorage.getItem('userId'))
            this.props.userHasRole(localStorage.getItem('userRole'))
            this.props.userHasLicense(localStorage.getItem('userLicense'))
            this.props.userHasProduct(localStorage.getItem('userProduct'))
            this.props.userHasOrg(localStorage.getItem('orgId'))
            let localorg = localStorage.getItem('orgId')
            try{
                querystring = {'queryStringParameters':{'orgId':localorg}}
                refresponse = await API.get("reference", "/reference", querystring)
                flresponses = await API.get("features", "/features", querystring)
                pkgresponses = await API.get("packages", "/packages", querystring)
              } catch (e) {
                  alert('There was problem getting survey properties', e);
              }
          } catch {
            this.props.history.push("/home")
          }
        }

        if(this.props.orgId !== null){
            try{
              querystring = {'queryStringParameters':{'orgId':this.props.orgId}}
              refresponse = await API.get("reference", "/reference", querystring)
              flresponses = await API.get("features", "/features", querystring)
              pkgresponses = await API.get("packages", "/packages", querystring)
            } catch (e) {
                alert('There was problem getting survey properties', e);
            }
          } 

        if(this.props.activeSurvey !== null){
            let isnew = false
            let responses = null
            let surveydatapages = null
            let surveydataorder = null
            let surveydataprops = null
            let surveydatastartpage = null
            try {
                let surveyquery = {}
                surveyquery['orgId']=this.props.orgId
                surveyquery['querytype']=this.props.activeSurvey
                querystring['queryStringParameters'] = surveyquery
                responses = await API.get("survey", "/survey", querystring)
                surveydatapages = JSON.parse(responses[0]['surveyPages'])
                surveydataorder = JSON.parse(responses[0]['surveyPageOrder'])
                surveydataprops = JSON.parse(responses[0]['surveyProps'])

                /// this block is to handle existing surveys before added packageoptions prop.  will be obsolete
                if(surveydataprops['packageoptions']===undefined){
                    let packageoptions = []
                    if(pkgresponses.length>0){
                    for(let p in pkgresponses){
                        packageoptions.push(JSON.parse(pkgresponses[p]['content']))
                    }
                    }
                    surveydataprops['packageoptions'] = packageoptions 
                    console.log('package options',surveydataprops['packageoptions'] )
                }


                surveydatastartpage = surveydatapages['SurveyProps']
                if(surveydataorder.length<2){isnew = true}

              }
            
              catch(e) {
                if (e !== 'There was a problem loading survey:', this.props.activeSurvey) {
                  alert(e);
                }
              }

              this.setState({ survey: responses[0], 
                pages:surveydatapages, 
                pageorder: surveydataorder, 
                surveyprops: surveydataprops, 
                page:surveydatastartpage,
                featuretype: refresponse['featuretype'], 
                featurelist:flresponses,
                packages:pkgresponses,
                isLoading:false, 
                isNew:isnew});
            }

        if(this.props.activeSurvey === null){
            let survey={}
            let surveyId=makeId(5)
            let surveydatapages = defaultnewsurvey.pages
            let surveydataorder = defaultnewsurvey.pageorder
            let surveydataprops = defaultnewsurvey.surveyprops
            surveydataprops['surveysettings']['surveyurl']="https://pricekit.io/?S="+surveyId
            let surveydatastartpage = surveydatapages['SurveyProps']
            survey['surveyId'] = surveyId
            survey['pageorder'] = surveydataorder
            survey['pages'] = surveydatapages
            survey['surveyprops'] = surveydataprops

            let packageoptions = []
            if(pkgresponses.length>0){
                for(let p in pkgresponses){
                    packageoptions.push(JSON.parse(pkgresponses[p]['content']))
                }
                }
                surveydataprops['packageoptions'] = packageoptions 

            this.setState({pages:surveydatapages, 
                pageorder: surveydataorder, 
                surveyprops: surveydataprops,
                page:surveydatastartpage, 
                featuretype: refresponse['featuretype'], 
                featurelist:flresponses,
                packages:pkgresponses,
                isLoading:false, 
                isNew:true}, ()=>this.props.userHasActiveSurvey(survey));
            } 
    }


    componentWillUnmount(){
        LayoutHelper.sidebarToggle(false)
    }
   

        getSurvey(querystring) {
            return API.get("survey", "/survey", querystring);
        }


        putSurvey(survey){
            let response = API.put("survey", "/survey",{body:survey})
            const ftype = {}
            ftype['localsavetime']=getTime()
            ftype['email']= this.props.email
            ftype['orgId']=this.props.orgId
            ftype['userId']=this.props.userId
            ftype['featuretype']=this.state.featuretype
            let respon = API.put("reference", "/reference", {body:ftype})
            this.setState({isSaved:true})
        }

        
        saveSurvey(){
            
            let copypageorder = JSON.parse(JSON.stringify(this.state.pageorder))
            for (let p in copypageorder){
                let page = copypageorder[p]['pageId']
                if(page !=="SurveyProps"){
                    copypageorder[p]['pagetitle']=this.state.pages[page].pageprops.questiontitle
                    copypageorder[p]['questionId']=this.state.pages[copypageorder[p]['pageId']]['questions'][0]['questionId']
                }
            }
            const survey = {}
            survey['id']=this.props.activeSurvey
            survey['lastupdate']=getTime()
            survey['email']= this.props.email
            survey['orgId']=this.props.orgId
            survey['userId']=this.props.userId
            survey['pages']=this.state.pages
            survey['pageorder']=copypageorder
            survey['surveyprops']=this.state.surveyprops
            survey['action']= 'Save'
            let localsavetime = getTime()
            this.setState(prevState => ({
                surveyprops: {...prevState.surveyprops,
                    surveysettings: {...prevState.surveyprops.surveysettings,
                        localsavetime:localsavetime}},
                pageorder: copypageorder
            }), ()=> this.putSurvey(survey))
            
        }

        changePage(c){
            if (c==="B"){
                let newpage = this.state.currentpagenum - 1
                let pageId = this.state.pageorder[newpage].pageId
                this.setState({
                    currentpagenum: Number(newpage),
                    currentpage: pageId,
                    currentcard: null,
                    page:  JSON.parse(JSON.stringify(this.state.pages[pageId])),
                })
            } else {
                if (c==="F"){
                    let newpage = this.state.currentpagenum + 1
                    let pageId = this.state.pageorder[newpage].pageId
                    this.setState({
                        currentpagenum: Number(newpage),
                        currentpage: pageId,
                        currentcard: null,
                        page: JSON.parse(JSON.stringify(this.state.pages[pageId])),
                    })
                } else {
                    let newpage = 0
                    for (const p in this.state.pageorder){
                        if (this.state.pageorder[p].pageId == c){newpage = p}
                    }
                    let pageId = this.state.pageorder[newpage].pageId
                    this.setState({
                        currentpagenum: Number(newpage),
                        currentpage: c,
                        currentcard: null,
                        page: JSON.parse(JSON.stringify(this.state.pages[pageId])),
                    })
                }
            }
        }

        showLoader(){
            return(
            <div className='survey-loader'>
                <Loader type="Puff" color="#20a8d8" height={100} width={100} timeout={3000}/>
            </div>
            )
        }


        toggleLightbox(img, title, caption){
            this.setState({showImage:!this.state.showImage, imageSrc:img, imageTitle:title, imageCaption:caption})
          }

        toggleUpdatedItem= item => {
            this.setState({ updateditem: item});
        }

        toggleModal= modal => {
                this.setState({ showModal: false, modalediting: "Complete"})
          }

        /// called by draggabletabs to reorder the 
        /// changes isfirst/last  note that index 1 is the first actual page since 0 is the surveyprops
        updatePageorder = pageorder => {
            let cpage = this.state.currentpagenum
            let totalpages = pageorder.length
            for (const p in pageorder){
                if (pageorder[p].pageId === this.state.currentpage){cpage = Number(p)}
                pageorder[p].isFirst=false
                pageorder[p].isLast=false
            }
            pageorder[1].isFirst=true
            pageorder[pageorder.length-1].isLast=true
            this.setState({ pageorder: pageorder, currentpagenum: cpage, isSaved:false})
        }

        /// creates the new page and updates the page order and then up to surveycanvas renderblank() to ask user to pick the type
        /// need to pick up the PROPS from the default settings created in SurveyPropsPage... these are hard coded


        newPage(){
            let pId = makeId(5)
            let copypageorder = [...this.state.pageorder]
            let pagenum = copypageorder.length
            for(let x in copypageorder){
                copypageorder[x].isFirst = false
                copypageorder[x].isLast = false
            }
            let newpageprops = JSON.parse(JSON.stringify(this.state.surveyprops.pagepropdefaults))
            let newquestions = []
            let newquestion = {}
            newquestion['id']= makeId(5)
            newquestion['questiontype']="NewPage"
            newquestion['questionprops']=JSON.parse(JSON.stringify(this.state.surveyprops.questionpropdefaults))
            newpageprops['thispage'] = pagenum
            newpageprops['totalpages']= pagenum
            newquestions.push(newquestion)
            copypageorder.push({pageId: pId, pagetitle: newpageprops['pagetitle'], isFirst:false, isLast:true })
            let newpg = {}
            newpg['pageprops'] = newpageprops
            newpg['questions'] = newquestions
            newpg['isSaved'] = false
            copypageorder[1].isFirst = true

            this.setState(prevState => ({
                page: newpg,
                pages: {...prevState.pages, [pId]: newpg},
                currentpage: pId,
                currentpagenum: pagenum,
                isnewpage: true,
                isNew: false,
                isSaved: false,
                pageorder: copypageorder,
                totalpages: copypageorder.length,
              }))
        }

        /// takes the page edits from pageeditormodal and updates  page and pages[currentpage]
        savePage = page =>{

            if(page.pageprops.questiontype === "AutoChoice"){
                page.questions[0].cards = []
            }
            
            this.setState(prevState => ({
                page: page,         
                pages: {...prevState.pages,
                    [this.state.currentpage]: page},
                showModal:false,
                modalediting:"Complete"
                    }), ()=>this.saveSurvey())

        }

        /// takes the page edits from pageeditormodal and updates  page and pages[currentpage]
        //// *** need to account for delete the one and only page...
        deletePage = page =>{

            let pageorderindex = null
            let newpageId = null
            let deletedlastpage = false
            let newpage = null
            let copyofall = JSON.parse(JSON.stringify(this.state.pages))
            let newactivepage = 0
            let totalpages = null
            let pagenum = null

            for (let i in this.state.pageorder){
                if(this.state.pageorder[i].pageId===this.state.currentpage){
                    pageorderindex = Number(i)
                    newactivepage = pageorderindex-1
                }
            }
            console.log('here is the new active page', newactivepage)

            let copyoforder = JSON.parse(JSON.stringify(this.state.pageorder))

            if(copyoforder.length===2){
                deletedlastpage = true
            } else {
                if(newactivepage === 0){
                    newpageId = copyoforder[2].pageId
                } else {
                    newpageId = copyoforder[newactivepage].pageId
                }

                newpage = copyofall[newpageId]

                copyoforder.splice(pageorderindex, 1)

                for (const p in copyoforder){
                    copyoforder[p].isFirst=false
                    copyoforder[p].isLast=false
                    if(copyoforder[p].pageId===newpageId){
                        console.log('got it')
                        pagenum = Number(p)}
                }
                copyoforder[1].isFirst=true
                copyoforder[copyoforder.length-1].isLast=true
                totalpages = copyoforder.length
            }

            delete copyofall[this.state.currentpage]
           
            /// deletes old page and creates a new blank one at position 0
            if(deletedlastpage){
                let pId = makeId(5)
                let pagenum = 1
                let copypageorder = []
                copypageorder.push(this.state.pageorder[0])
                copypageorder.push({pageId:pId, description:"", isFirst:true, isLast:true})
                let newpageprops = {...defaultnewsurvey.surveyprops.pagepropdefaults}
                let newquestions = []
                let newquestion = {}
                let copypages = {}
                copypages['SurveyProps']=JSON.parse(JSON.stringify(this.state.pages['SurveyProps']))
                let copypage = {}
                newquestion['id']= makeId(5)
                newquestion['questiontype']= "NewPage"
                newquestion['type']= "NewPage"
                newquestion['questionprops']={...defaultnewsurvey.surveyprops.questionpropdefaults}
                newpageprops['thispage'] = pagenum
                newpageprops['totalpages']= pagenum
                newquestions.push(newquestion)
                copypage['isSaved']=false
                copypage['pageprops'] = newpageprops
                copypage['questions'] = newquestions
                copypages[pId]=copypage

                this.setState(prevState => ({
                    page: copypage,
                    pages: copypages,
                    currentpage: pId,
                    currentpagenum: pagenum,
                    isnewpage: true,
                    isSaved:false,
                    pageorder: copypageorder,
                    totalpages: copypageorder.length,
                    showModal:false,
                    modalediting:"Complete"
                  }),)


            } else {
                this.setState(prevState => ({
                    page: newpage,         
                    pageorder: copyoforder,       
                    pages: copyofall,
                    currentpage: newpageId,
                    currentpagenum: pagenum,
                    totalpages: totalpages,
                    showModal:false,
                    isSaved:false,
                    modalediting:"Complete"
                        }))
            }



        }

        
        

        /// creates the first page based on the type selected on surveycanvas renderblank()
        /// changes pageprops.type from NewPage to Survey
        /// adds default cards, choices and list items
        updateNewPageType(t, id){
            

            let questions = []
            let questiondefault = {}
            let cards = []
            let copypageprops =  {...this.state.page.pageprops}
            copypageprops['type'] = "Survey"
            copypageprops['questiontype'] = t


            switch (t){

                case "SurveyCard":
                    let surveycard = JSON.parse(JSON.stringify(surveycarddefaults))
                    surveycard['id'] = makeId(5)
                    cards.push(surveycard)  
                    questiondefault['questionId'] = makeId(5)
                    questiondefault['questionprops'] = JSON.parse(JSON.stringify(this.state.surveyprops.questionpropdefaults))
                    questiondefault['questiontype'] = "SurveyCard"
                    questiondefault['cards'] = cards
                    questions.push(questiondefault)
                    break;
                
                case "SurveyChoice":
                    let choicecard = JSON.parse(JSON.stringify(choicecarddefaults))
                    choicecard['showprice'] = this.state.surveyprops.questionpropdefaults.showprice
                    choicecard['randomize'] = this.state.surveyprops.questionpropdefaults.randomize
                    choicecard['id']= makeId(5)
                    cards.push(choicecard)
                    questiondefault['questionId'] = makeId(5)
                    questiondefault['questionprops'] = JSON.parse(JSON.stringify(this.state.surveyprops.questionpropdefaults))
                    questiondefault['questiontype'] = "SurveyChoice"
                    questiondefault['cards'] = cards
                    questions.push(questiondefault)
                    break;


                case "SurveyList":
                    let rowheadid = makeId(5)
                    let defaultlist = {}
                    defaultlist['listid'] = makeId(5)
                    defaultlist['listhead'] = [{"id":null, "header":""}]
                    defaultlist['listrow'] = []
                    defaultlist['listcolumn'] = []
                    defaultlist['listprops'] = {randomize: true, showprice: false, type: "featurelist", rowheaderid: null}
                    defaultlist.listhead[0].id=rowheadid
                    defaultlist.listprops.rowheaderid =rowheadid
                    questiondefault['questionId'] = makeId(5)
                    questiondefault['questionprops'] = JSON.parse(JSON.stringify(this.state.surveyprops.questionpropdefaults))
                    questiondefault['questiontype'] = "SurveyList"
                    questiondefault['list'] = defaultlist
                    questions.push(questiondefault)
                    break;

                case "DesignCard":
                    questiondefault = JSON.parse(JSON.stringify(designdefaults))
                    questiondefault['questionId'] = makeId(5)
                    questiondefault['questionprops'] = {...this.state.surveyprops.questionpropdefaults}
                    questiondefault['questionprops']['selectiontype'] = "toggle"
                    questiondefault['questiontype'] = "DesignCard"
                    questiondefault['cards'][0]['id'] = makeId(5)
                    questions.push(questiondefault)
                    break;
                
                default:
                    console.log('switch case not found', t)

            }

            this.setState(prevState => ({
                isSaved:false,
                page: {                   
                    ...prevState.page,    
                    questions: questions,
                    pageprops: copypageprops
                },
                pages: {                   
                    ...prevState.pages,    
                    [id]: {...prevState.pages[id],
                        questions: questions,
                        pageprops: copypageprops

                    }}}))

        }


        /// passes up a new card created from rendercards
        newCard= cards => {

            let bigcopy = [...this.state.pages[this.state.currentpage].questions]
            bigcopy[0].cards = cards
            this.setState(prevState => ({
                isSaved:false,
                page: {...prevState.page,           
                    questions:bigcopy},
                pages: {...prevState.pages,
                    [this.state.currentpage]: {...prevState.pages[this.state.currentpage],
                        questions: bigcopy}},
                }))
        }

        /// changes a newly created card to use an existing card.  Pushed up from Render Choice Content
        swapCard = card => {
            let bigcopy = [...this.state.pages[this.state.currentpage].questions]
            let newcards = bigcopy[0]['cards']
            let idx = -1
            let swapcard = {}
            for(let x in newcards){
                if(newcards[x]['id']===card.fromcard){
                    idx = x
                    swapcard = newcards[x]
                }
            }
            for (let y in this.state.packages){
                if(this.state.packages[y]['packageId']===card.tocard){
                    swapcard=JSON.parse(this.state.packages[y]['content'])
                }
            }

            if(idx>-1){
            newcards.splice(idx,1,swapcard) 
            }
            bigcopy[0].cards = newcards

            this.setState(prevState => ({
                isSaved:false,
                page: {...prevState.page,           
                    questions:bigcopy},
                pages: {...prevState.pages,
                    [this.state.currentpage]: {...prevState.pages[this.state.currentpage],
                        questions: bigcopy}},
                }))
        }


        /// delete card from modal
        deleteCard= card => {
            let cardindex = null
            let contentcopy = null
            for(let i in this.state.page.questions[0].cards){
                if(this.state.page.questions[0].cards[i].id === card.cardId){
                    cardindex = i
                }
            }
            if(cardindex !==null){
                contentcopy = [...this.state.page.questions[0].cards]
                contentcopy.splice(cardindex,1)
            }

            let bigcopy = [...this.state.pages[this.state.currentpage].questions]
            bigcopy[0].cards = contentcopy

            
            this.setState(prevState => ({
                isSaved:false,
                page: {...prevState.page,           
                    questions:bigcopy},
                pages: {...prevState.pages,
                    [this.state.currentpage]: {...prevState.pages[this.state.currentpage],
                        questions: bigcopy}},
                showModal:false
                }))
        }


        /// save survey card (not choice card) from the card modal
        saveSurveyCard = updatecard => {
            let card = updatecard.card
            let cardindex = null
            let bigcopy = [...this.state.pages[this.state.currentpage].questions]

            for(let i in this.state.page.questions[0].cards){
                if(this.state.page.questions[0].cards[i].id === updatecard.cardId){
                    cardindex = i
                }

            }
            if(cardindex !==null){
                let contentcopy = [...this.state.page.questions[0].cards]
                contentcopy.splice(cardindex, 1, card)
                bigcopy[0].cards = contentcopy
            }

            this.setState(prevState => ({
                isSaved:false,
                page: {...prevState.page,           
                    questions:bigcopy},
                pages: {...prevState.pages,
                    [this.state.currentpage]: {...prevState.pages[this.state.currentpage],
                        questions: bigcopy}},
                showModal:false,
                modalediting:"Complete"
                }),()=>this.saveSurvey())

    
        }

       /// update choice card when save and exit from modal
        saveCard= updatecard => {

            let card = updatecard.card
            let questionprops = updatecard.questionprops
            let cardindex = null
            let contentcopy = null
            let bigcopy = [...this.state.pages[this.state.currentpage].questions]
            let newfeaturetypes = updatecard.featuretype

            console.log('----> the new questionprops', questionprops)

            for(let i in this.state.page.questions[0].cards){
                if(this.state.page.questions[0].cards[i].id === updatecard.cardId){
                    cardindex = i
                }

            }
            if(cardindex !==null){
                contentcopy = [...this.state.page.questions[0].cards]
                contentcopy.splice(cardindex, 1, card)
                bigcopy[0].cards = contentcopy
                bigcopy[0].questionprops = questionprops
            }
            
            let allpkgs = JSON.parse(JSON.stringify(this.state.packages))
            for (let d in allpkgs){
                if(allpkgs[d]['id']===card.id){
                    allpkgs[d]=card
                    allpkgs[d]['content']=JSON.stringify(card)
                }
            }

            let pkgopts = JSON.parse(JSON.stringify(this.state.surveyprops.packageoptions))
            for (let d in pkgopts){
                if(pkgopts[d]['id']===card.id){
                    pkgopts[d]=card
                }
            }
            
            this.setState(prevState => ({
                isSaved:false,
                packages: allpkgs,
                page: {...prevState.page,           
                    questions:bigcopy},
                pages: {...prevState.pages,
                    [this.state.currentpage]: {...prevState.pages[this.state.currentpage],
                        questions: bigcopy}},
                surveyprops: {...prevState.surveyprops,           
                    packageoptions:pkgopts},
                showModal:false,
                featuretype: newfeaturetypes,
                modalediting:"Complete"
                }),()=>this.saveSurvey())
        }

        /// update card order based on drag-drop from render-choices
        moveCards= cards => {
            let bigcopy = [...this.state.pages[this.state.currentpage].questions]
            bigcopy[0].cards = cards

            this.setState(prevState => ({
                isSaved:false,
                page: {...prevState.page,           
                    questions:bigcopy},
                pages: {...prevState.pages,
                    [this.state.currentpage]: {...prevState.pages[this.state.currentpage],
                        questions: bigcopy}},
                }))
        }

        deleteItem= item => {

            if(item.type !=="List"){

                let cardindex = null
                let itemindex = null
                let contentcopy = null
                for(let i in this.state.page.questions[0].cards){
                    if(this.state.page.questions[0].cards[i].id === item.cardId){
                        cardindex = i
                    }
                }
                if(cardindex !== null){
                    for (let c in this.state.page.questions[0].cards[cardindex].content){
                        if(this.state.page.questions[0].cards[cardindex].content[c].id === item.questionId){
                            itemindex = c
                        }
                    }
                }
                if(cardindex !==null && itemindex !==null){
                    contentcopy = [...this.state.page.questions[0].cards[cardindex].content]
                    contentcopy.splice(itemindex,1)
                }

                let bigcopy = [...this.state.pages[this.state.currentpage].questions]
                bigcopy[0].cards[cardindex].content = contentcopy

                
                this.setState(prevState => ({
                    page: {...prevState.page,           
                        questions:bigcopy},
                    pages: {...prevState.pages,
                        [this.state.currentpage]: {...prevState.pages[this.state.currentpage],
                            questions: bigcopy}},
                    showModal:false
                        }))
            } else {
                
                let bigcopy = [...this.state.pages[this.state.currentpage].questions]
                bigcopy[0].list.listrow = [{
                    header:"", detail:"", price:"", pricemetric:"", id:makeId(5)
                }]

                this.setState(prevState => ({
                    isSaved:false,
                    page: {...prevState.page,           
                        questions:bigcopy},
                    pages: {...prevState.pages,
                        [this.state.currentpage]: {...prevState.pages[this.state.currentpage],
                            questions: bigcopy}},
                    showModal:false
                        }))


            }

        }

        /// saves the question from modal
        saveItem= item => {

            if(!["List", "Column"].includes(item.type)){
                let cardindex = null
                let itemindex = null
                let contentcopy = null
                console.log('... saving item,', item.type)
                for(let i in this.state.page.questions[0].cards){
                    if(this.state.page.questions[0].cards[i].id === item.cardId){
                        cardindex = i
                    }
                }
                if(cardindex !== null){
                    for (let c in this.state.page.questions[0].cards[cardindex].content){
                        if(this.state.page.questions[0].cards[cardindex].content[c].id === item.questionId){
                            itemindex = c
                        }
                    }
                }
                if(cardindex !==null && itemindex !==null){
                    contentcopy = [...this.state.page.questions[0].cards[cardindex].content]
                    contentcopy.splice(itemindex, 1, item.question)
                }

                let bigcopy = [...this.state.pages[this.state.currentpage].questions]
                bigcopy[0].cards[cardindex].content = contentcopy

                console.log('saving the bigcopy', bigcopy)

                
                this.setState(prevState => ({
                    isSaved:false,
                    page: {...prevState.page,           
                        questions:bigcopy},
                    pages: {...prevState.pages,
                        [this.state.currentpage]: {...prevState.pages[this.state.currentpage],
                            questions: bigcopy}},
                    showModal:false
                        }),()=>this.saveSurvey())

            }

            if(item.type === "Column"){
                let bigcopy = [...this.state.pages[this.state.currentpage].questions]
                bigcopy[0].list.listcolumn = item.listcolumn
                bigcopy[0].list.listhead = item.listhead

                this.setState(prevState => ({
                    isSaved:false,
                    page: {...prevState.page,           
                        questions:bigcopy},
                    pages: {...prevState.pages,
                        [this.state.currentpage]: {...prevState.pages[this.state.currentpage],
                            questions: bigcopy}},
                    showModal:false
                        }),()=>this.saveSurvey())


            } 
            
            
            if(item.type === 'List') {

                let bigcopy = [...this.state.pages[this.state.currentpage].questions]
                bigcopy[0].list.listrow = item.listrow
                bigcopy[0].list.listprops = item.listprops
                bigcopy[0].list.listhead = item.listhead

                this.setState(prevState => ({
                    isSaved:false,
                    page: {...prevState.page,           
                        questions:bigcopy},
                    pages: {...prevState.pages,
                        [this.state.currentpage]: {...prevState.pages[this.state.currentpage],
                            questions: bigcopy}},
                    showModal:false
                        }),()=>this.saveSurvey())


            }

        }

        deleteColumn= item => {

            let columnindex = null
            let headindex = null
            for(const i in this.state.page.questions[0].list.listcolumn){
                if (this.state.page.questions[0].list.listcolumn[i].id === item.columnid){
                    columnindex = i
                }
            }
            for(const j in this.state.page.questions[0].list.listhead){
                if (this.state.page.questions[0].list.listhead[j].id === item.columnid){
                    headindex = j
                }
            }

            let bigcopy = [...this.state.pages[this.state.currentpage].questions]
            if (columnindex !== null)
            {
            bigcopy[0].list.listcolumn.splice(columnindex,1)
            bigcopy[0].list.listhead.splice(headindex,1)
            }


                this.setState(prevState => ({
                    isSaved:false,
                    page: {...prevState.page,           
                        questions:bigcopy},
                    pages: {...prevState.pages,
                        [this.state.currentpage]: {...prevState.pages[this.state.currentpage],
                            questions: bigcopy}},
                    showModal:false
                        }))


        }

        /// saves the question from canvas without going through modal
        updateCardHeaders= item => {

        let updatedfield = item.name
        let cardindex = null
        let cardcopy = [...this.state.page.questions[0].cards]

        for(let c in cardcopy){
            if(cardcopy[c].id===this.state.currentcard){
                cardindex = c
            }
        }
        cardcopy[cardindex][updatedfield]=item.value
        let bigcopy = [...this.state.page.questions]
        bigcopy[0].cards = cardcopy
   
        this.setState(prevState => ({
            isSaved:false,
            page: {...prevState.page,
                questions: bigcopy,
            pages: {...prevState.pages,
                [this.state.currentpage]: {...prevState.pages[this.state.currentpage],
                   questions: bigcopy}
                }}}))
        }


        updateListHeaders= item => {

            let updatedfield = item.name
            let updatedvalue = item.value
            let listheaders = [...this.state.page.questions[0].list.listhead]
    
            for(let c in listheaders){
                if(listheaders[c].id===updatedfield){
                    listheaders[c]['header']= updatedvalue
                }
            }

            let bigcopy = [...this.state.page.questions]
            bigcopy[0].list.listhead = listheaders
       
            this.setState(prevState => ({
                isSaved:false,
                page: {...prevState.page,
                    questions: bigcopy,
                pages: {...prevState.pages,
                    [this.state.currentpage]: {...prevState.pages[this.state.currentpage],
                       questions: bigcopy}
                    }}}))
            }


        /// saves the question from canvas without going through modal
        updateContentHeaders= item => {

            let updatedfield = item.name
            let cardindex = null
            let cardcopy = [...this.state.page.questions[0].cards]

    
            for(let c in cardcopy){
                if(cardcopy[c].id===this.state.currentcard){
                    cardindex = c
                }
            }


            let contentindex = null
            let contentcopy = [...this.state.page.questions[0].cards[cardindex].content]

            for(let p in contentcopy){
                if(contentcopy[p].id===item.id){
                   contentindex = p
                }
            }

            contentcopy[contentindex][updatedfield]=item.value
            let bigcopy = [...this.state.page.questions]
            bigcopy[0].cards[cardindex].content = contentcopy
        
            this.setState(prevState => ({
                isSaved:false,
                page: {...prevState.page,
                    questions: bigcopy,
                pages: {...prevState.pages,
                    [this.state.currentpage]: {...prevState.pages[this.state.currentpage],
                        questions: bigcopy}
                    }}}))
            }


        /// saves the question from canvas without going through modal
        updatePageTitle= item => {

            let updatedfield = item.name
        
            this.setState(prevState => ({
                isSaved:false,
                page: {...prevState.page,
                    pageprops: {...prevState.page.pageprops,       
                        [updatedfield]:item.value}},
                pages: {...prevState.pages,
                    [this.state.currentpage]: {...prevState.pages[this.state.currentpage],
                        pageprops:  {...prevState.pages[this.state.currentpage].pageprops,
                            [updatedfield]:item.value}}},
                    }))
    
            }

        /// saves the updates from the surveypropspage 
        updateSurveyProps= item => {

            let updatedfield = item.name
            let updatedvalue = item.value

            if(["status",].includes(updatedfield)){

                let currentdate = getTime()

                if(updatedvalue === "LIVE"){
                    this.setState(prevState => ({
                        isSaved:false,
                        surveyprops: {...prevState.surveyprops,
                            surveysettings: {...prevState.surveyprops.surveysettings,
                                [updatedfield]:item.value,
                                surveystart: currentdate,
                            }},
                    }), ()=>this.saveSurvey())

                }

                if(updatedvalue === "COMPLETE"){
                    this.setState(prevState => ({
                        isSaved:false,
                        surveyprops: {...prevState.surveyprops,
                            surveysettings: {...prevState.surveyprops.surveysettings,
                                [updatedfield]:item.value,
                                surveyend: currentdate,
                            }},
                    }), ()=>this.saveSurvey())

                }

                if(["DRAFT", "TEST", "TEST PENDING"].includes(updatedvalue)){

                    this.setState(prevState => ({
                        isSaved:false,
                        surveyprops: {...prevState.surveyprops,
                            surveysettings: {...prevState.surveyprops.surveysettings,
                                [updatedfield]:item.value}},
                    }), ()=>this.saveSurvey())
            
                }
            
            }

            if(["applyquota","password", "passwordrequired", "quota","showlogo","singleresponse", "surveydescription", "surveystart", "surveyend", "publishtest", "scheduleend",
                "schedulepublish", "localsavetime", "notifications", "logosrc", "surveyurl", 'showcompleteurl', 'completeurl', 'thankyoumessage'].includes(updatedfield)){
                    this.setState(prevState => ({
                        isSaved:false,
                        surveyprops: {...prevState.surveyprops,
                            surveysettings: {...prevState.surveyprops.surveysettings,
                                [updatedfield]:item.value}},
                    }))
                }


            if(["backbutton","nextbutton", "completebutton", "showpagenums", "randomizechoices"].includes(updatedfield)){
                this.setState(prevState => ({
                    isSaved:false,
                    surveyprops: {...prevState.surveyprops,
                        pagepropdefaults: {...prevState.surveyprops.pagepropdefaults,
                            [updatedfield]:item.value}},
                }))
            }

            if(["randomize", "showprice"].includes(updatedfield)){
                this.setState(prevState => ({
                    isSaved:false,
                    surveyprops: {...prevState.surveyprops,
                        questionpropdefaults: {...prevState.surveyprops.questionpropdefaults,
                            [updatedfield]:item.value}},
                }))
            }

            if(["pagebodycolor","formbodycolor", "formheadcolor", "formcardtextcolor", "formheadtextcolor"].includes(updatedfield)){

                let updatelist = []
                updatelist.push(updatedfield)

                if(updatedfield === "formbodycolor"){
                    updatelist.push("tablebackground")
                    updatelist.push("contentlistbackground")
                    updatelist.push("contentlistborder")
                    updatelist.push("featurebackgroundcolor")
                }

                if(updatedfield === "formheadcolor"){
                    updatelist.push("formfootcolor")
                    updatelist.push("cardfootcolor")
                    updatelist.push("cardheadcolor")
                    updatelist.push("jumbobackground")
                    updatelist.push("pagefootcolor")
                    updatelist.push("pricebackgroundcolor")
                    updatelist.push("featureoutlinecolor")
                    updatelist.push("tableheadbackground")

                }

                if(updatedfield === "formcardtextcolor"){
                    updatelist.push("cardcardtextcolor")
                    updatelist.push("tablebodytext")
                    updatelist.push("contentlisttext")
                    updatelist.push("cardheadtextcolor")
                    updatelist.push("cardfoottextcolor")
                }

                if(updatedfield==="formheadtextcolor"){
                    updatelist.push("formfoottextcolor")
                    updatelist.push("jumbotrontext")
                    updatelist.push("pricetextcolor")
                    updatelist.push("tableheadtext")
                }

                for (let i in updatelist){
                
                    let j = updatelist[i]

                    this.setState(prevState => ({
                        isSaved:false,
                        surveyprops: {...prevState.surveyprops,
                            style: {...prevState.surveyprops.style,
                                [j]:item.value}},
                    }))
                }
            }

            if(['packageoptions'].includes(updatedfield)){
                this.setState(prevState => ({
                    isSaved:false,
                    surveyprops: {...prevState.surveyprops,
                        packageoptions:updatedvalue},
                }))
            }


        }


        /// saves the updates from the survey style modal

        updateSurveyStyleProps= item => {

            console.log('updating survey props', item)

            let updatedfield = item.name
            let updatedvalue = item.value

            if(["selectcolor", "noselectcolor", "bestselectcolor", "worstselectcolor", "touchcolor"].includes(updatedfield)){
                this.setState(prevState => ({
                    isSaved:false,
                    surveyprops: {...prevState.surveyprops,
                        questionpropdefaults: {...prevState.surveyprops.questionpropdefaults,
                            [updatedfield]:item.value}},
                }))
            }

            this.setState(prevState => ({
                isSaved:false,
                surveyprops: {...prevState.surveyprops,
                    style: {...prevState.surveyprops.style,
                        [updatedfield]:item.value}},
            }),()=>this.saveSurvey())

        
        }

        
        /// resets surveyprop to the snapshot captured in modal before all changes made
        cancelSurveyPropChanges = item => {

            this.setState(prevState => ({
                surveyprops: item,
                showModal:false,
                modalediting:"Complete"
                    }))
            }

        

        /// triggers the modal to show

        activeQuestion= item => {
            this.setState({currentquestion:item.id, modalediting:item.action, showModal:true, modalsize:item.modalsize})
        }

        activeCard= card => {
            this.setState({currentcard:card})
        }


        getModal(){

            if(this.state.modalediting === 'SurveyCard'){
                return(
                <ContentEditorModal
                page={this.state.page}
                currentquestion={this.state.currentquestion}
                currentcard={this.state.currentcard}
                showModal={this.state.showModal}
                toggleModal={this.toggleModal}
                toggleLightbox={this.toggleLightbox}
                modalediting={this.state.modalediting}
                deleteItem={this.deleteItem}
                saveItem={this.saveItem}
                />
                )
                }

            if(this.state.modalediting === 'ChoiceCard'){   
                return(
                <ChoiceEditorModal
                page={this.state.page}
                surveyprops = {this.state.surveyprops}
                currentquestion={this.state.currentquestion}
                currentcard={this.state.currentcard}
                showModal={this.state.showModal}
                toggleModal={this.toggleModal}
                toggleLightbox={this.toggleLightbox}
                modalediting={this.state.modalediting}
                deleteCard={this.deleteCard}
                saveCard={this.saveCard}
                orgId={this.props.orgId}
                featurelist={this.state.featurelist}
                featuretype={this.state.featuretype}
                />
                )
                } 
            
            
            if(this.state.modalediting === 'List'){ 
                return(
                <ListEditorModal
                page={this.state.page}
                currentquestion={this.state.currentquestion}
                showModal={this.state.showModal}
                toggleModal={this.toggleModal}
                modalediting={this.state.modalediting}
                saveItem={this.saveItem}
                deleteItem={this.deleteItem}
                />
                )
                }
            
            if(this.state.modalediting === 'Column'){ 
                return(
                <ColumnEditorModal
                page={this.state.page}
                currentquestion={this.state.currentquestion}
                showModal={this.state.showModal}
                toggleModal={this.toggleModal}
                modalediting={this.state.modalediting}
                deleteColumn={this.deleteColumn}
                saveItem={this.saveItem}
                />
                )
                }

            if(this.state.modalediting === 'Page'){ 
                return(
                <PageEditorModal
                page={this.state.page}
                currentquestion={this.state.currentquestion}
                currentpagenum={this.state.currentpagenum}
                showModal={this.state.showModal}
                toggleModal={this.toggleModal}
                modalediting={this.state.modalediting}
                savePage={this.savePage}
                deletePage={this.deletePage}
                surveyprops={this.state.surveyprops}
                />
                )
                }

            if(this.state.modalediting === 'Card'){ 
                return(
                <CardEditorModal
                page={this.state.page}
                currentquestion={this.state.currentquestion}
                currentpagenum={this.state.currentpagenum}
                currentcard={this.state.currentcard}
                showModal={this.state.showModal}
                toggleModal={this.toggleModal}
                modalediting={this.state.modalediting}
                saveSurveyCard={this.saveSurveyCard}
                deleteCard={this.deleteCard}
                />
                )
                }

            if(this.state.modalediting === 'Survey'){ 
                return(
                <SurveyStyleModal
                page={this.state.page}
                surveyprops={this.state.surveyprops}
                currentquestion={this.state.currentquestion}
                currentpagenum={this.state.currentpagenum}
                currentcard={this.state.currentcard}
                showModal={this.state.showModal}
                toggleModal={this.toggleModal}
                modalediting={this.state.modalediting}
                cancelSurveyPropChanges={this.cancelSurveyPropChanges}
                updateSurveyStyleProps={this.updateSurveyStyleProps}
                />
                )
                }
            if(this.state.modalediting === 'Design'){ 
                return(
                <DesignModal
                page={this.state.page}
                surveyprops={this.state.surveyprops}
                currentquestion={this.state.currentquestion}
                currentpagenum={this.state.currentpagenum}
                currentcard={this.state.currentcard}
                showModal={this.state.showModal}
                toggleModal={this.toggleModal}
                modalediting={this.state.modalediting}
                saveCard={this.saveCard}
                deleteCard={this.deleteCard}
                />
                )
                }

        }


  render(){
    return(
        <div>

        {this.state.isLoading && <Loader type="Puff" color="#20a8d8" height={100} width={100} timeout={5000}/>}

        {this.state.showModal && 
        <Modal isOpen={this.state.showModal} autoFocus={false} size={this.state.modalsize} centered >
            {this.getModal()}
        </Modal>
        }

        {this.state.showImage && (
        <Lightbox
          mainSrc={this.state.imageSrc}
          enableZoom={true}
          imageTitle={this.state.imageTitle}
          imageCaption={this.state.imageCaption}
          onCloseRequest={() => this.setState({ showImage: false })}
          />
        )}

        <ToastContainer
        autoClose = {5000}
        toastClassName = "survey-toast"
        bodyClassName = "survey-toast-text"
        />

        {this.state.pages !== null &&

        <div>

        <DraggableTabs 
            onPageChange={this.changePage} 
            pageorder={this.state.pageorder}
            pages={this.state.pages}
            currentpagenum = {this.state.currentpagenum}
            currentpage = {this.state.currentpage}
            currentcard = {this.state.currentcard}
            activeQuestion={this.activeQuestion}
            questiontype ={this.state.page.pageprops.questiontype}
            newPage={this.newPage}
            totalpages = {this.state.totalpages}
            updateditem = {this.state.updateditem}
            toggleUpdatedItem = {this.toggleUpdatedItem}
            updatePageorder={this.updatePageorder}
            modalediting={this.state.modalediting}
            saveSurvey={this.saveSurvey}
            activeSurvey={this.props.activeSurvey}
            isSaved={this.state.isSaved}
        />
        

        <div style={{display:"flex", flexWrap:"wrap-reverse", height:"100%", alignItems:"stretch"}}>

            <div style={{flex:"1", width:"100%", minHeight:"100%"}}>

            <SurveyCanvas
                surveyprops ={this.state.surveyprops}
                page={this.state.page} 
                pageorder={this.state.pageorder}
                currentpage = {this.state.currentpage}
                currentpagenum = {this.state.currentpagenum}
                totalpages = {this.state.totalpages}
                userId = {this.props.userId}
                orgId = {this.props.userId}
                userLicense ={this.props.userLicense}
                userRole ={this.props.userRole}
                userProduct ={this.props.userProduct}
                onPageChange={this.changePage}
                toggleSidebar={this.toggleSidebar}
                newQuestion={this.newQuestion}
                isnewpage={this.state.isnewpage}
                updateNewPageType={this.updateNewPageType}
                activeQuestion={this.activeQuestion}
                currentquestion={this.state.currentquestion}
                activeCard={this.activeCard}
                currentcard={this.state.currentcard}
                newCard={this.newCard}
                toggleLightbox={this.toggleLightbox}
                moveCards={this.moveCards}
                updatePageTitle={this.updatePageTitle}
                updateCardHeaders={this.updateCardHeaders}
                updateListHeaders={this.updateListHeaders}
                updateContentHeaders={this.updateContentHeaders}
                saveItem={this.saveItem}
                updateSurveyProps={this.updateSurveyProps}
                activeSurvey={this.props.activeSurvey}
                saveSurvey={this.saveSurvey}
                isNew={this.state.isNew}
                isSaved={this.state.isSaved}
                newPage={this.newPage}
                packages={this.state.packages}
                swapCard={this.swapCard}
            />
            

            </div>

        </div>

        </div>

        }


        </div>
        

    );
  }
}
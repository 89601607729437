import React, { Component } from 'react';
import { Auth } from "aws-amplify";

export default class Logout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated : this.props.isAuthenticated,
      isLoading: false,
      email: "",
      password: ""
    };
    
  }


  userExit = authenticated => {
    this.setState({ isAuthenticated: authenticated }, () =>{this.props.userHasAuthenticated(false)});
  }
  
  handleLogout = async event => {
    await Auth.signOut();
    this.userExit(false);
  }

  showExit(){
    this.handleLogout()
    if (this.state.isAuthenticated===false){
      return(
        <div class="jumbotron">
          <h1 class="display-3">Work Tracker</h1>
          <p class="lead">The easiest way to record, document and search for the help you need to do your best work.</p>
          <hr class="my-4"></hr>
          <p>Log back in to add and search for help.</p>
          <p class="lead">
          <a class="btn btn-primary btn-lg" href="/login" role="button">Login</a>
          </p>
          </div>
        )
    } else {
      return null
    }
  }
  
  render() {
    return (
        this.showExit()
    );
  }
}

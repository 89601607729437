import React, { Component } from "react";
import { API } from "aws-amplify";
import Loader from "react-loader-spinner";
import DataTable, {createTheme} from 'react-data-table-component';
import AssetExpanderMenu from './AssetExpanderMenu'

/// also search, filter and download csv - see examples https://jbetancur.github.io/react-data-table-component/?path=/story/filtering--example-1
/// need to only show those with same ORG ID

const conditionalRowStyles = [
  {
    when: row => row.status === "LIVE",
    style: {
      fontWeight: "500",
      },
    },
    {
      when: row => row.selectedrow === true,
      style: {
        fontWeight: "500",
        backgroundColor:"#2f353a",
        color: "#20a8d8",
        },
      },
];

createTheme('pricekit', {
  text: {
    primary: '#2f353a',
    secondary: '#5c6873',
  },
  background: {
    default: 'transparent',
  },
  context: {
    background: '#20a8d8',
    text: '#f0f3f5',
  },
  divider: {
    default: '#f0f3f5',
  },
  action: {
    button: 'rgba(0,0,0,.54)',
    hover: 'rgba(0,0,0,.08)',
    disabled: 'rgba(0,0,0,.12)',
  },
});

const columns = [
  {
    name: 'Asset ID',
    selector: 'assetId',
    sortable: true,
    width:'100px'
  },
  {
    name: 'Type',
    selector: 'questiontype',
    sortable: true,
    width:'100px'
  },
  {
    name: 'Last Update',
    selector: 'localsavetime',
    sortable: true,
    width:'150px'
  },
  {
    name: 'Image Location',
    selector: 'img',
    sortable: true,
    width:'200px'
  },

  {
    name: 'Video Location',
    selector: 'video',
    sortable: true,
    width:'200px'
  },
  {
    name: 'Gif Location',
    selector: 'gif',
    sortable: true,
    width:'200px'
  },
  {
    name: 'Surveys',
    selector: 'surveyid',
    sortable: true,
    width:'100px'
  },
];



export default class DataTableAsset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      data: []
    };
  }

  async componentDidMount() {
      try{
        let querystring = {}
        let orgquery = {}
        orgquery['orgId']=this.props.orgId
        orgquery['querytype']='ALL'
        querystring['queryStringParameters'] = orgquery
        let responses = await API.get("assets", "/assets", querystring)
        let numberquestions = responses.length
        let nums = {}
        let numimg = 0
        let numvid = 0
        let numgif = 0
        nums['numberassets']= numberquestions
        for(let i in responses){
          if(responses[i]['img']!== "None"){
            numimg = numimg+1
          }
          if(responses[i]['video']!== "None"){
            numvid=numvid+1
          }
          if(responses[i]['gif']!== "None"){
            numgif=numgif+1
          }
        }

        nums['numberimg']=numimg
        nums['numbervideo']=numvid
        nums['numbergif']=numgif
        this.setState({data: responses, isLoading:false}, ()=>this.props.updateCard(nums))

      } catch (e) {
          alert('There was problem getting survey list', e);
      }
      }


      updateState = state => {

        if(state.assetId !== this.state.selectedquestion){

          let datacopy = [...this.state.data]
          
          for(let i in datacopy){
            if(datacopy[i]['assetId']===state.assetId){
              datacopy[i]['selectedrow']=true
            }
            if(datacopy[i]['assetId']===this.state.selectedquestion){
              datacopy[i]['selectedrow']=false
            }
          }

        this.setState({ selectedquestion: state.assetId, data:datacopy},()=>this.props.getRow(true, state));
        }
      }



      updateStateToggle = (togglestate, row) => {

      }



  render() {
      return (
        <div>
          
          {this.state.isLoading && <Loader type="Puff" color="#20a8d8" height={100} width={100} timeout={3000}/>}


          <DataTable style={{overflowX:"hidden"}}
          keyField={"surveyId"}
          title="Asset List"
          theme="pricekit"
          columns={columns}
          data={this.state.data}
          defaultSortField="localsavetime"
          defaultSortAsc={false}
          selectableRows={false}
          selectableRowsNoSelectAll={true}
          selectableRowsHighlight={true}
          expandableRows={true}
          expandableRowsComponent={<AssetExpanderMenu />}
          onRowExpandToggled={this.updateStateToggle}
          onRowClicked={this.updateState}
          expandOnRowClicked={true}
          pagination={false}
          highlightOnHover={false}
          striped={false}
          pointerOnHover={true}
          dense={false}
          noTableHead={false}
          persistTableHead={true}
          progressPending={false}
          noHeader={true}
          subHeader={false}
          subHeaderAlign={"right"}
          fixedHeader={true}
          fixedHeaderScrollHeight="400px"
          conditionalRowStyles={conditionalRowStyles}
    />
    </div>
    );
  }
}
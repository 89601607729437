import React, { Component } from "react";
import { API } from "aws-amplify";
import Loader from "react-loader-spinner";
import DataTable, {createTheme} from 'react-data-table-component';
import { Button, Card, CardBody, CardGroup, CardHeader, Col, Container, Row } from 'reactstrap';
import QuestionExpanderMenu from './QuestionExpanderMenu'

/// also search, filter and download csv - see examples https://jbetancur.github.io/react-data-table-component/?path=/story/filtering--example-1
/// need to only show those with same ORG ID

/// gets all survey responses
const conditionalRowStyles = [
  {
    when: row => row.status === "LIVE",
    style: {
      fontWeight: "500",
      },
    },
    {
      when: row => row.selectedrow === true,
      style: {
        fontWeight: "500",
        backgroundColor:"#2f353a",
        color: "#20a8d8",
        },
      },
];

createTheme('pricekit', {
  text: {
    primary: '#2f353a',
    secondary: '#5c6873',
  },
  background: {
    default: 'transparent',
  },
  context: {
    background: '#20a8d8',
    text: '#f0f3f5',
  },
  divider: {
    default: '#f0f3f5',
  },
  action: {
    button: 'rgba(0,0,0,.54)',
    hover: 'rgba(0,0,0,.08)',
    disabled: 'rgba(0,0,0,.12)',
  },
});


const columns = [
  {
    name: 'Question',
    selector: 'questionid',
    sortable: true,
    width:'100px'
  },
  {
    name: 'Type',
    selector: 'questiontype',
    sortable: true,
    width:'150px'
  },
  {
    name: 'Question Text',
    selector: 'questionheader',
    sortable: true,
    width:'350px'
  },
  {
    name: 'Selection Options',
    selector: 'selectoptions',
    sortable: true,
    width:'200px'
  },
  {
    name: 'Last Update',
    selector: 'localsavetime',
    sortable: true,
    width:'150px'
  },
  {
    name: 'Survey',
    selector: 'surveyid',
    sortable: true,
    width:'100px'
  },
]

export default class DataTableQuestion extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isLoading: true,
        data: []
      };
    }

    async componentDidMount() {
        try{
          let querystring = {}
          let orgquery = {}
          orgquery['orgId']=this.props.orgId
          orgquery['querytype']='ALL'
          querystring['queryStringParameters'] = orgquery
          let responses = await API.get("questions", "/questions", querystring)
          let numberquestions = responses.length
          let numberselect=0
          let numberfield=0
          let numbercontent=0
          let nums ={}
          for (let x in responses){


            if(["select", "selectcreatable", "multiselect", "multiselectcreatable", "toggle", "checkbox", "radio", "slider", "range"].includes(responses[x]['questiontype'])){
              numberselect=numberselect+1
            }
            if(["textfield", "textarea", "allocation"].includes(responses[x]['questiontype'])){
                numberfield=numberfield+1
              }
              if(["jumbotron", "jumbotron2", "html", "contentlist", "img", "bigimg", "video", "gif"].includes(responses[x]['questiontype'])){
                numbercontent=numbercontent+1
              }
          }

          /// excluding content items from the datatable but they are in the dynamodb table

          nums['numberquestions']=numberselect+numberfield
          nums['numberselect']=numberselect
          nums['numberfield']=numberfield
          nums['numbercontent']=numbercontent

          for(let i in responses){
            responses[i]['selectedrow'] = false
            if (responses[i]['selectoptions']==='[]'){responses[i]['selectoptions']=""}
          }

          this.setState({data: responses, isLoading:false}, ()=>this.props.updateCard(nums))
        } catch (e) {
            alert('There was problem getting survey list', e);
        }
        }


        updateState = state => {

          if(state.questionid !== this.state.selectedquestion){

            let datacopy = [...this.state.data]
            
            for(let i in datacopy){
              if(datacopy[i]['questionid']===state.questionid){
                datacopy[i]['selectedrow']=true
              }
              if(datacopy[i]['questionid']===this.state.selectedquestion){
                datacopy[i]['selectedrow']=false
              }
            }

          this.setState({ selectedquestion: state.questionid, data:datacopy }, ()=>this.props.getRow(true, state));
          }
        }



        updateStateToggle = (togglestate, row) => {
        }



    render() {
        return (
          <div>
            
            {this.state.isLoading && <Loader type="Puff" color="#20a8d8" height={100} width={100} timeout={3000}/>}

        <DataTable style={{overflowX:"hidden"}}
            keyField={"surveyId"}
            title="Survey List"
            theme="pricekit"
            columns={columns}
            data={this.state.data}
            defaultSortField="localsavetime"
            defaultSortAsc={false}
            selectableRows={false}
            selectableRowsNoSelectAll={true}
            selectableRowsHighlight={true}
            expandableRows={true}
            expandableRowsComponent={<QuestionExpanderMenu />}
            onRowExpandToggled={this.updateStateToggle}
            onRowClicked={this.updateState}
            expandOnRowClicked={false}
            pagination={false}
            paginationPerPage={7}
            highlightOnHover={false}
            striped={false}
            pointerOnHover={true}
            dense={false}
            noTableHead={false}
            persistTableHead={true}
            progressPending={false}
            noHeader={true}
            subHeader={false}
            subHeaderAlign={"right"}
            fixedHeader={true}
            fixedHeaderScrollHeight="400px"
            conditionalRowStyles={conditionalRowStyles}
      />
      </div>
      );
    }
}
import React from 'react';

const Breadcrumbs = React.lazy(() => import('./views/Base/Breadcrumbs'));
const Cards = React.lazy(() => import('./views/Base/Cards'));
const Carousels = React.lazy(() => import('./views/Base/Carousels'));
const Collapses = React.lazy(() => import('./views/Base/Collapses'));
const Dropdowns = React.lazy(() => import('./views/Base/Dropdowns'));
const Forms = React.lazy(() => import('./views/Base/Forms'));
const Jumbotrons = React.lazy(() => import('./views/Base/Jumbotrons'));
const ListGroups = React.lazy(() => import('./views/Base/ListGroups'));
const Navbars = React.lazy(() => import('./views/Base/Navbars'));
const Navs = React.lazy(() => import('./views/Base/Navs'));
const Paginations = React.lazy(() => import('./views/Base/Paginations'));
const Popovers = React.lazy(() => import('./views/Base/Popovers'));
const ProgressBar = React.lazy(() => import('./views/Base/ProgressBar'));
const Switches = React.lazy(() => import('./views/Base/Switches'));
const Tables = React.lazy(() => import('./views/Base/Tables'));
const Tabs = React.lazy(() => import('./views/Base/Tabs'));
const Tooltips = React.lazy(() => import('./views/Base/Tooltips'));
const BrandButtons = React.lazy(() => import('./views/Buttons/BrandButtons'));
const ButtonDropdowns = React.lazy(() => import('./views/Buttons/ButtonDropdowns'));
const ButtonGroups = React.lazy(() => import('./views/Buttons/ButtonGroups'));
const Buttons = React.lazy(() => import('./views/Buttons/Buttons'));
const Charts2 = React.lazy(() => import('./views/Charts'));

const CoreUIIcons = React.lazy(() => import('./views/Icons/CoreUIIcons'));
const Flags = React.lazy(() => import('./views/Icons/Flags'));
const FontAwesome = React.lazy(() => import('./views/Icons/FontAwesome'));
const SimpleLineIcons = React.lazy(() => import('./views/Icons/SimpleLineIcons'));
const Alerts = React.lazy(() => import('./views/Notifications/Alerts'));
const Badges = React.lazy(() => import('./views/Notifications/Badges'));
const Modals = React.lazy(() => import('./views/Notifications/Modals'));
const Colors = React.lazy(() => import('./views/Theme/Colors'));
const Typography = React.lazy(() => import('./views/Theme/Typography'));
const Widgets = React.lazy(() => import('./views/Widgets/Widgets'));
const Users = React.lazy(() => import('./views/Users/Users'));
const User = React.lazy(() => import('./views/Users/User'));

const Home = React.lazy(() => import("./containers/Home"));
const Login = React.lazy(() => import("./containers/Login"));
const Logout = React.lazy(() => import("./containers/Logout"));
const ResetPassword = React.lazy(() => import("./containers/ResetPassword"));
const ChangePassword = React.lazy(() => import("./containers/ChangePassword"));
const ChangeEmail = React.lazy(() => import("./containers/ChangeEmail"));

const SignUp = React.lazy(() => import("./containers/Signup"));
const SelectPlan = React.lazy(() => import("./containers/SelectPlan"));
const PaymentSettings = React.lazy(() => import("./containers/PaymentSettings"));
const Welcome = React.lazy(() => import("./containers/Welcome"));
const SetUp = React.lazy(() => import("./containers/SetUp"));

const Search = React.lazy(() => import("./containers/Search"));
const Profile = React.lazy(() => import("./containers/Profile"));
const NewOrganization = React.lazy(() => import("./containers/NewOrganization"));
const Settings = React.lazy(() => import("./containers/Settings"));

const Dashboard = React.lazy(() => import('./containers/Dashboard'));

const Survey = React.lazy(() => import("./containers/Survey"));
const Responses = React.lazy(() => import("./containers/Responses"));
const Crosstabs = React.lazy(() => import("./containers/Crosstabs"));
const Analysis = React.lazy(() => import("./containers/Analysis"));
const PackageAnalysis = React.lazy(() => import("./containers/PackageAnalysis"));
const Questions = React.lazy(() => import("./containers/Questions"));
const Packages = React.lazy(() => import("./containers/Packages"));
const Features = React.lazy(() => import("./containers/Features"));
const Designs = React.lazy(() => import("./containers/Designs"));
const Assets = React.lazy(() => import("./containers/Assets"));
const SurveyEditor = React.lazy(() => import("./survey/SurveyEditor"));

const AppNotLive = React.lazy(() => import("./components/AppNotLive"));

const routes = [
  { path: '/', exact: true, name: 'Home', component:Dashboard },

  { path: '/theme', exact: true, name: 'Theme', component: Colors },
  { path: '/theme/colors', name: 'Colors', component: Colors },
  { path: '/theme/typography', name: 'Typography', component: Typography },
  { path: '/base', exact: true, name: 'Base', component: Cards },
  { path: '/base/cards', name: 'Cards', component: Cards },
  { path: '/base/forms', name: 'Forms', component: Forms },
  { path: '/base/switches', name: 'Switches', component: Switches },
  { path: '/base/tables', name: 'Tables', component: Tables },
  { path: '/base/tabs', name: 'Tabs', component: Tabs },
  { path: '/base/breadcrumbs', name: 'Breadcrumbs', component: Breadcrumbs },
  { path: '/base/carousels', name: 'Carousel', component: Carousels },
  { path: '/base/collapses', name: 'Collapse', component: Collapses },
  { path: '/base/dropdowns', name: 'Dropdowns', component: Dropdowns },
  { path: '/base/jumbotrons', name: 'Jumbotrons', component: Jumbotrons },
  { path: '/base/list-groups', name: 'List Groups', component: ListGroups },
  { path: '/base/navbars', name: 'Navbars', component: Navbars },
  { path: '/base/navs', name: 'Navs', component: Navs },
  { path: '/base/paginations', name: 'Paginations', component: Paginations },
  { path: '/base/popovers', name: 'Popovers', component: Popovers },
  { path: '/base/progress-bar', name: 'Progress Bar', component: ProgressBar },
  { path: '/base/tooltips', name: 'Tooltips', component: Tooltips },
  { path: '/buttons', exact: true, name: 'Buttons', component: Buttons },
  { path: '/buttons/buttons', name: 'Buttons', component: Buttons },
  { path: '/buttons/button-dropdowns', name: 'Button Dropdowns', component: ButtonDropdowns },
  { path: '/buttons/button-groups', name: 'Button Groups', component: ButtonGroups },
  { path: '/buttons/brand-buttons', name: 'Brand Buttons', component: BrandButtons },
  { path: '/icons', exact: true, name: 'Icons', component: CoreUIIcons },
  { path: '/icons/coreui-icons', name: 'CoreUI Icons', component: CoreUIIcons },
  { path: '/icons/flags', name: 'Flags', component: Flags },
  { path: '/icons/font-awesome', name: 'Font Awesome', component: FontAwesome },
  { path: '/icons/simple-line-icons', name: 'Simple Line Icons', component: SimpleLineIcons },
  { path: '/notifications', exact: true, name: 'Notifications', component: Alerts },
  { path: '/notifications/alerts', name: 'Alerts', component: Alerts },
  { path: '/notifications/badges', name: 'Badges', component: Badges },
  { path: '/notifications/modals', name: 'Modals', component: Modals },
  { path: '/widgets', name: 'Widgets', component: Widgets },
  { path: '/charts', name: 'Charts', component: Charts2 },
  { path: '/users', exact: true,  name: 'Users', component: Users },
  { path: '/users/:id', exact: true, name: 'User Details', component: User },

  { path: '/login', exact: true, name: 'Login', component: Login},
  { path: '/logout', exact: true, name: 'Logout', component: Logout},
  { path: '/ResetPassword', exact: true, name: 'ResetPassword', component: ResetPassword},
  { path: '/settings/password', exact: true, name: 'ChangePassword', component: ChangePassword},
  { path: '/settings/email', exact: true, name: 'ChangeEmail', component: ChangeEmail},
  { path: '/settings', exact: true, name: 'Account Settings', component: Settings},

  { path: '/audience', exact: true, name: 'Audience', component: AppNotLive},

  { path: '/signup', exact: true, name: 'Sign Up', component: SignUp },  
  { path: '/selectplan', exact: true, name: 'Select Plan', component: SelectPlan }, 
  { path: '/paymentsettings', exact: true, name: 'Payment Settings', component: PaymentSettings }, 
  { path: '/welcome', exact: true, name: 'Welcome', component: Welcome }, 
  { path: '/setup', exact: true, name: 'Set Up', component: SetUp }, 

  { path: '/dashboard', name: 'Dashboard', component: Dashboard },

  { path: '/surveys', exact: true, name: 'Surveys', component: Survey }, 
  { path: '/surveys/questions', exact: true, name: 'Questions', component: Questions }, 
  { path: '/surveys/packages', exact: true, name: 'Packages', component: Packages }, 
  { path: '/surveys/packages/analysis', exact: true, name: 'Analysis', component: PackageAnalysis }, 
  { path: '/surveys/features', exact: true, name: 'Features', component: Features }, 
  { path: '/surveys/designs', exact: true, name: 'Designs', component: Designs }, 
  { path: '/surveys/assets', exact: true, name: 'Assets', component: Assets }, 
  { path: '/surveys/editor', exact: true, name: 'Survey Editor', component: SurveyEditor }, 
  { path: '/surveys/responses', exact: true, name: 'Responses', component: Responses }, 
  { path: '/surveys/responses/crosstabs', exact: true, name: 'Crosstabs', component: Crosstabs }, 
  { path: '/surveys/responses/analysis', exact: true, name: 'Analysis', component: Analysis }, 

  { path: '/Search', exact: true, name: 'Search', component: Search },
  { path: '/Profile', exact: true, name: 'Profile', component: Profile },
  { path: '/Organization', exact: true, name: 'New Organization', component: NewOrganization},
  { path: '/Settings', exact: true, name: 'Settings', component: Settings},

];

export default routes;

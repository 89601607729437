import React, { Component } from "react";
import { Table,} from 'reactstrap';
import ChoiceListEditor from '../survey/ChoiceListEditor'

/// SurveyList - is used as a list or grid to handle multiple questions (columns) with multiple subjects (rows)
/// only limit is one Slider or one Range, otherwise can have multiple of any types


/// used to optionally shuffle rows
function shuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }
    return array;
    }

    export default class SurveyList extends Component {
        constructor(props) {
          super(props);
          let initialselections = {}
          let initializefalse = ['checkbox', 'radio']
          let val = {}
          let initialtotal = 0
          let initialize = {}
          let initialremaining = this.props.questionprops.allocationamount
          let newlistrow = this.props.list.listrow
          let validcolumns = {}
          let pageisvalid = false
          if (typeof this.props.savedpages != "undefined") {
              if(this.props.savedpages===true){
                  initialselections = this.props.savedresponses['selectvalue']
                  newlistrow = this.props.savedresponses['listrow']
                  initialremaining = this.props.savedresponses['remaining']
                  initialtotal = this.props.savedresponses['total']
                  validcolumns = this.props.savedresponses['validcolumns']
              }
              } else {
                    for (let r in this.props.list.listrow){
                        let rowid = this.props.list.listrow[r]['id']
                        for(let c in this.props.list.listcolumn){
                            let colid = this.props.list.listcolumn[c]['id']
                            val[colid] = this.props.list.listcolumn[c]['initial']
                            if(this.props.list.listcolumn[c]['validationtype']==="none"){
                                validcolumns[colid]=true
                            } else {validcolumns[colid] = false}
                           
                            if (initializefalse.includes(this.props.list.listcolumn[c]['type']))
                            {
                            let startvals = {}
                            for (let s of this.props.list.listcolumn[c]['selectoptions']){
                                startvals[s['value']]=false
                                }
                                    val[colid]=startvals
                                }
                            initialize[rowid]=val
                        }
                    }
                    initialselections = initialize
                    if (this.props.list.listprops.randomize){
                        newlistrow = shuffle(newlistrow)}
                  }

          this.changeSelection = this.changeSelection.bind(this)
          this.changeAllocation = this.changeAllocation.bind(this)
          this.toggleRadioCheck = this.toggleRadioCheck.bind(this)
          this.changeDiscreteToggle = this.changeDiscreteToggle.bind(this)
          this.state = {
              selectvalue: initialselections,
              listrow: newlistrow,
              remaining: initialremaining,
              total: initialtotal,
              validallocationamount:this.props.questionprops.allocationamount,
              isvalid: pageisvalid,
              validcolumns: validcolumns
            };
          }
        
    /// TODO not sure if this validation check on mount is necessary...
    async componentDidMount() {
    this.pageValidation()
    }

    /// used to save state on going forward/back a page - so if you come back here the order (if random row) and selections you made are preserved
    componentWillUnmount(){
        this.props.stateChangePassThru(this.props.questionId, this.state)
    }
      
    /// NOTE *** does NOTE reset page validation or column validation if you go from valid and then make invalid change
    /// this is checking of all the columns are valid
    pageValidation(){
        let checkitems = Object.values(this.state.validcolumns)
        let validcheck = checkitems.includes(false)
        this.setState({isvalid: !validcheck}, () => {this.props.handlePageValid(this.state.isvalid)})
    }

    /// this is checking if the individual column which was just updated is valid
    columnValidation(c){
        let validcheck = true
        let validtype = "none"
        let questtype = null
        let deepchecking = ['checkbox', 'radio']
        let colvals = []
        for(let j in this.props.list.listcolumn){
            if(this.props.list.listcolumn[j]['id']===c){
                validtype = this.props.list.listcolumn[j]['validationtype']
                questtype = this.props.list.listcolumn[j]['type']
            }
        }

        for(let i in this.state.selectvalue){
            if (deepchecking.includes(questtype)){
                let deepanswers = Object.values(this.state.selectvalue[i][c])
                let deepanswercheck = deepanswers.includes(true)
                colvals.push(deepanswercheck)
            } else {
            colvals.push(this.state.selectvalue[i][c])
            }
        }

        if(validtype==="all"){
            if (colvals.includes(false)){
                validcheck= false
            }
            if (colvals.includes(null)){
                validcheck = false
            }
        }

        if(validtype==="one")
            {
            validcheck = false
            if (colvals.includes(true)){
                validcheck= true
            } else {
            validcheck = colvals.some(function (el) {return el !== null})
            }
            }

        this.setState(prevState => ({validcolumns: {...prevState.validcolumns, [c]: validcheck}}), () => {this.pageValidation()} )
        
    }

    /// updating selection for r=row, c=column, v=value
    changeSelection(r, c, v){

        this.setState(prevState => ({
                selectvalue: {
                ...prevState.selectvalue,          
                [r]: {                     
                ...prevState.selectvalue[r], 
                [c]: v         
                }
                }
                }),() => {this.columnValidation(c)})
        }

    /// updating selection for r=row, c=column, v=value, t=type (row - cant have more than one toggle in row, likewise for column, and can also enforce both for max-diff type)
    changeDiscreteToggle(r, c, v, t){

        if(t==="column" && v===true){
            for (let i of Object.keys(this.state.selectvalue)){

            this.setState(prevState => ({
                selectvalue: {
                ...prevState.selectvalue,          
                [i]: {                     
                ...prevState.selectvalue[i], 
                [c]: false      
                }
                }
                }))
            }
            this.setState(prevState => ({
                selectvalue: {
                ...prevState.selectvalue,          
                [r]: {                     
                ...prevState.selectvalue[r], 
                [c]: true     
                }
                }
                }),() => {this.columnValidation(c)})
        }

        if(t==="row" && v===true){
                for(let j of Object.keys(this.state.selectvalue[r])){
                    for(let k in this.props.list.listcolumn){
                        if(this.props.list.listcolumn[k]['id']===j){
                            if (this.props.list.listcolumn[k]['type']==="discrete-toggle"){
                                this.setState(prevState => ({
                                    selectvalue: {
                                    ...prevState.selectvalue,          
                                    [r]: {                     
                                    ...prevState.selectvalue[r], 
                                    [this.props.list.listcolumn[k]['id']]: false     
                                    }
                                    }
                                    }),() => {this.columnValidation(c)})
                                } 
                            }
                        }
                    }
                this.setState(prevState => ({
                    selectvalue: {
                    ...prevState.selectvalue,          
                    [r]: {                     
                    ...prevState.selectvalue[r], 
                    [c]: true      
                    }
                    }
                    }),() => {this.columnValidation(c)})
                }

            if(t==="both" && v===true){
                for (let i of Object.keys(this.state.selectvalue)){

                    this.setState(prevState => ({
                        selectvalue: {
                        ...prevState.selectvalue,          
                        [i]: {                     
                        ...prevState.selectvalue[i], 
                        [c]: false      
                        }
                        }
                        }),() => {this.columnValidation(c)})
                    }
                for(let j of Object.keys(this.state.selectvalue[r])){
                    for(let k in this.props.list.listcolumn){
                        if(this.props.list.listcolumn[k]['id']===j){
                            if (this.props.list.listcolumn[k]['type']==="discrete-toggle"){
                                this.setState(prevState => ({
                                    selectvalue: {
                                    ...prevState.selectvalue,          
                                    [r]: {                     
                                    ...prevState.selectvalue[r], 
                                    [this.props.list.listcolumn[k]['id']]: false     
                                    }
                                    }
                                    }),() => {this.columnValidation(c)})
                                } 
                            }
                        }
                    }
                this.setState(prevState => ({
                    selectvalue: {
                    ...prevState.selectvalue,          
                    [r]: {                     
                    ...prevState.selectvalue[r], 
                    [c]: true      
                    }
                    }
                    }),() => {this.columnValidation(c)})
                }
        }

    /// updating allocation for r=row, c=column, v=value.  Calcs the total and remaining
    changeAllocation(r, c, v){

        if(v<0){v=0}
        if(v>this.props.questionprops.allocationamount){v=0}
        let newvalue = 0
        let newtotal = 0
    
        this.setState((prevState) => {
    
            for (let a of Object.keys(prevState.selectvalue)){
                if (a===r){
                    newtotal = newtotal + v
                } else {
                newtotal = newtotal + prevState.selectvalue[a][c]
                }
            }
                    
            newvalue = this.props.questionprops.allocationamount - newtotal
            let validamount = 0

            if(newvalue<1){validamount = newvalue} else {validamount=this.props.questionprops.allocationamount}
    
            return {
                selectvalue:{
                    ...prevState.selectvalue,          
                    [r]: {                     
                    ...prevState.selectvalue[r], 
                    [c]: v         
                    }
                    }, 
                total: newtotal,
                remaining: newvalue,
                validallocationamount:validamount
                }          
            }, () => {this.columnValidation(c)})
    }

    /// updating selection for r=row, c=column, v=value, t=type
    toggleRadioCheck(r, c, e, t){

        if(t==='radio'){
            let newvals = {}
            for (let p in this.state.selectvalue[r][c])
                {
                newvals[p]=false
                }
            newvals[e]=true

            this.setState(prevState => ({
                selectvalue: {
                ...prevState.selectvalue,          
                [r]: {                     
                ...prevState.selectvalue[r], 
                [c]: newvals

                }
                }
                }),() => {this.columnValidation(c)})
        } else {

            this.setState(prevState => ({
                selectvalue: {
                ...prevState.selectvalue,          
                [r]: {                     
                ...prevState.selectvalue[r], 
                [c]: {
                ...prevState.selectvalue[r][c], 
                [e]: !prevState.selectvalue[r][c][e]
                }
                }
                }
                }),() => {this.columnValidation(c)})
            }
    }

    
    /// Todo - make sure ordering is correct when changing items in array;  Prob better to make this an attribute of column
     getHeader(item){
         return(
             <td>
                 {item.header}
             </td>
         )
         }

    /// calls ChoiceList to create each cell in the grid
    getListItem(item){
        return(
        <ChoiceListEditor
            key={item.id}
            surveyprops={this.props.surveyprops}
            list={item}
            listprops={this.props.list.listprops}
            columns={this.props.list.listcolumn}
            remaining={this.state.remaining}
            validallocationamount={this.state.validallocationamount}
            selectvalue={this.state.selectvalue[item.id]}
            questionprops={this.props.questionprops}
            questionId={this.props.questionId}
            onSelectionChange={this.changeSelection}
            onAllocationChange={this.changeAllocation}
            onRadioCheckToggle={this.toggleRadioCheck}
            onDiscreteToggleChange={this.changeDiscreteToggle}
            />
        )
    }
      
      
      
         render(){
          return(
              <div>
            <Table className="survey-list-table">
                <thead className = "survey-list-col-header">
                    <tr>
                {this.props.list.listhead.map((item, key=item.id)=>this.getHeader(item))}
                    </tr>
                </thead>
                {this.state.listrow.map((item, key=item.id)=>this.getListItem(item))}
            </Table>
            {this.state.remaining>0 && (
                <h4>{this.state.total} of {this.props.questionprops.allocationamount}.  {this.state.remaining} to go </h4>)}
                {this.state.remaining<0 && (
                <h4>{this.state.total} of {this.props.questionprops.allocationamount}.  {this.state.remaining * -1} too many </h4>)}
                {this.state.remaining===0 && (
                <h4>{this.state.total} of {this.props.questionprops.allocationamount}.  OK! </h4>)}
            </div>
          );
        }
      }
import React, { Component } from "react";
import {Input, Form, FormGroup, CustomInput, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Jumbotron} from 'reactstrap';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable'
import Toggle from 'react-toggle'
import  'react-toggle/style.css'
import GifPlayer from 'react-gif-player';
import ReactPlayer from 'react-player';
import './RenderContent.scss';
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';


const edittip = 'Click to edit settings.'

/// styling for react-select
const customStyles = (isValid) => ({
    control: (styles: any, state:any) => ({
      ...styles,
      padding: 5,
      width:"100%",
      fontSize: "smaller",
      ...(!isValid && { borderColor: 'red' }),
    }),
  });

export default class RenderContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
        isValid: false,
      };
    }
   
  async componentDidMount(){ 
  }

    /// callback to send option id (i) and the value of the event(e) back to parent component
    handleSelectionChange(i, e){
        this.props.onSelectionChange(i, e)
    }
    
    /// calls Selection Change callback:  Used by SELECT and MULTI --- not slider range here as it doesnt pass name via action
    handleSelectChange = (selectvalue:any, action: any) => {
        this.handleSelectionChange(action.name, selectvalue)
    };

    /// calls Selection Change callback:  Used by SELECT and MULTI --- not slider range here as it doesnt pass name via action
    handleSliderChange(id, e) {

      this.handleSelectionChange(id, e)
    };

    /// calls Selection Change
    handleToggleSelect(e){
      this.handleSelectionChange(this.props.form.fields.id, e)
    }

    /// calls Selection Change callback:  Used by TEXT-FIELD and TEXT-AREA
    handleFieldChange = (event) => {
      const target = event.target;
      let value = target.value;
      const name = target.name;
      this.handleSelectionChange(name, value)
    }

    /// calls Selection Change callback:  Used by TOGGLE
    handleToggleChange(i){
      this.props.onSelectionChange(i, !this.props.selectvalue[i])
    }

    /// callback:  used by RADIO and CHECKBOX
    handleRadioCheckChange = (event) => {
      const target = event.target;
      if(target != null){
        let type = target.type
        let value = target.value
        const name = target.name;
        this.props.onRadioCheckToggle(name, value, type)
      }
    }

    /// used to generate the radio and checkbox items
    getSelectItems(item, type, qid){
      let itemkey = qid + item['value']
      if(type==="checkbox"){
        return(
        <CustomInput type="checkbox"
        id={itemkey} 
        checked={this.props.selectvalue[qid][item['value']]} 
        value={item['value']}
        label={item['label']} name={qid} key={itemkey} type={"checkbox"}
        style={{color:this.props.surveyprops.style.formcardtextcolor}}
        ></CustomInput>
  
        )}
        if(type==="radio"){
          return(
          <CustomInput type="radio" id={itemkey} 
          checked={this.props.selectvalue[qid][item['value']]}  
          label={item['label']} value={item['value']}
          name={qid} key={itemkey} type={"radio"}
          style={{color:this.props.surveyprops.style.formcardtextcolor}}
          ></CustomInput>
        )}
    }

    /// a function to render the map of list details used for content only items (per ContentCard)
    renderListItem(h, d, key){
      return(
      <ListGroupItem style={{borderColor:this.props.surveyprops.style.contentlistborder,
      backgroundColor:this.props.surveyprops.style.contentlistbackground,
      color:this.props.surveyprops.style.contentlisttext}}
      key={key}>
      <ListGroupItemHeading>{h}</ListGroupItemHeading>
      <ListGroupItemText>{d}</ListGroupItemText>
      </ListGroupItem>
      )
    }

    /// a callback to toggle the lightbox image component on SurveyContent
    handleImageToggle(i){
        this.props.onImageToggle(i)
    }

    showField(target, test){

      if(test === null){return true}
      
      if(typeof(target)==='object'){

        if(target !== null){
        
          if(Array.isArray(target)){
            let targetlist = []
            for(let i of target){
              targetlist.push(i.value)
            }
            if(targetlist.includes(test.value)){return true} else {return false}}
          
          else {if(target.value === test.value){return true} else {return false}}
        }
        else {return false}
      }
      else {if(test === target){return true} else {return false}}
    }

    /// triggers modal
    activeItem(action, id){
        let item = {}
        item['id'] = id
        item['action'] = action
        this.props.activeQuestion(item)
    }

    /// updates the question header text without need to open modal
    updateContentHeaders = (event) => {
      const target = event.target;
      if(target != null){
          let value = target.value;
          const name = target.name;
          const type = target.type
          let item = {}
          item['name']=name
          item['value']=value
          item['type']=type
          item['id']=this.props.content.id
          this.props.updateContentHeaders(item)
      }
      }

    /// this determines which selection component to use in the cards
    renderSelectType(field){


    /// if(this.showField(this.props.selectvalue[field['showif']['id']], field['showif']['value'])){

    if (field['type'] === "html"){
        return(
            <div onClick={()=>this.activeItem('SurveyCard', field.id)} style={{width:"100%", flexGrow:"1", marginBottom:"30px", color:this.props.surveyprops.style.formcardtextcolor}}>
            {field['text']==="" && <p style={{fontSize:"0.5em", color:"#8f9ba6"}}>Blank Text</p>}
                <div dangerouslySetInnerHTML={{ __html: field.text }} />
        </div>
        )
        }

    if (field['type'] === "select"){
         return(
           <div className="editor-content-item" style={{width:"100%", flexGrow:"1", marginBottom:"30px"}}>
            <Input 
              name={"header"}
              placeholder={"New question..."}
              value={this.props.content.header}
              type={"text"}
              onChange={this.updateContentHeaders}
              style={{backgroundColor:"transparent", width:"100%", borderColor:"#8f9ba640", color:this.props.surveyprops.style.formcardtextcolor}}  
              />

            <Select
            defaultValue={this.props.selectvalue[field.id]}
            onChange={this.handleSelectChange}
            options={field.selectoptions}
            name={field.id}
            styles={customStyles(true)}
          />
        </div>
        )
        }

    if (field['type']  === "selectcreatable"){
        return(
          <div className="editor-content-item" style={{width:"100%", flexGrow:"1", marginBottom:"30px"}}>
            <Input 
              name={"header"}
              placeholder={"New question..."}
              value={this.props.content.header}
              type={"text"}
              onChange={this.updateContentHeaders}
              style={{backgroundColor:"transparent", borderColor:"#8f9ba640", color:this.props.surveyprops.style.formcardtextcolor}}  
              />
         
            <CreatableSelect
            defaultValue={this.props.selectvalue[field.id]}
            onChange={this.handleSelectChange}
            options={field.selectoptions}
            name={field.id}
            styles={customStyles(true)}  
            />
          </div>
        )
        }
    if (field['type']  === "multiselect"){
        return(
          <div className="editor-content-item" style={{width:"100%", flexGrow:"1", marginBottom:"30px"}}>
            <Input 
              name={"header"}
              placeholder={"New question..."}
              value={this.props.content.header}
              type={"text"}
              onChange={this.updateContentHeaders}
              style={{backgroundColor:"transparent", borderColor:"#8f9ba640", color:this.props.surveyprops.style.formcardtextcolor}}  
              />
         
            <Select
            defaultValue={this.props.selectvalue[field.id]}
            isMulti
            onChange={this.handleSelectChange}
            options={field.selectoptions}
            name={field.id}
            styles={customStyles(true)}  
            />
          </div>
        )
        }   
    if (field['type']  === "multiselectcreatable"){
        return(
          <div className="editor-content-item" style={{width:"100%", flexGrow:"1", marginBottom:"30px"}} >
            <Input 
              name={"header"}
              placeholder={"New question..."}
              value={this.props.content.header}
              type={"text"}
              onChange={this.updateContentHeaders}
              style={{backgroundColor:"transparent", borderColor:"#8f9ba640", color:this.props.surveyprops.style.formcardtextcolor}}  
              />
         
            <CreatableSelect
            isMulti
            defaultValue={this.props.selectvalue[field.id]}
            onChange={this.handleSelectChange}
            options={field.selectoptions}
            name={field.id}
            styles={customStyles(true)}  
            />
          </div>
        )
        }
    if (field['type']  === "toggle"){
      return(
        <div className="editor-content-item" style={{width:"100%", flexGrow:"1", marginBottom:"30px"}}>
            <Input 
              name={"header"}
              placeholder={"New question..."}
              value={this.props.content.header}
              type={"text"}
              onChange={this.updateContentHeaders}
              style={{backgroundColor:"transparent", borderColor:"#8f9ba640", color:this.props.surveyprops.style.formcardtextcolor}}  
              />
       
          <Toggle
          id={field.id}
          value={field.togglevalue}
          checked= {this.props.selectvalue[field.id]}
          icons={{unchecked: null}}
          className = {field.togglestyle}
          onChange = {() => this.handleToggleChange(field.id)}
      />
      </div>
      )
    }
    if (field['type']  === "textfield"){
      return(
        <div className="editor-content-item" style={{width:"100%", flexGrow:"1", marginBottom:"30px"}}>
            <Input 
              name={"header"}
              placeholder={"New question..."}
              value={this.props.content.header}
              type={"text"}
              onChange={this.updateContentHeaders}
              style={{backgroundColor:"transparent", borderColor:"#8f9ba640", color:this.props.surveyprops.style.formcardtextcolor}}  
              />
          <Input 
          name={field.id}
          type={field.texttype} 
          value={this.props.selectvalue[field.id]}
          onChange={this.handleFieldChange}/>
      </div>
      )
    }

    if (field['type']  === "textarea"){
      return(
        <div className="editor-content-item" style={{width:"100%", flexGrow:"1", marginBottom:"30px"}}>
            <Input 
              name={"header"}
              placeholder={"New question..."}
              value={this.props.content.header}
              type={"text"}
              onChange={this.updateContentHeaders}
              style={{backgroundColor:"transparent", borderColor:"#8f9ba640", color:this.props.surveyprops.style.formcardtextcolor}}  
              />
          <Input 
          name={field.id}
          type={"textarea"} 
          value={this.props.selectvalue[field.id]}
          onChange={this.handleFieldChange}/>
      </div>
      )
    }


    if (field['type']  === "checkbox"){
      let defaultvals = {}
      let defaultchecks = []
      for (let i in this.props.selectvalue[field.id]){
        if(this.props.selectvalue[field.id][i]===true){
          defaultchecks.push(i)
        }
        defaultvals[field.id]=defaultchecks
      }
      return(
        <div className="editor-content-item" style={{width:"100%", flexGrow:"1", marginBottom:"30px"}}>
            <Input 
              name={"header"}
              placeholder={"New question..."}
              value={this.props.content.header}
              type={"text"}
              onChange={this.updateContentHeaders}
              style={{backgroundColor:"transparent", borderColor:"#8f9ba640", color:this.props.surveyprops.style.formcardtextcolor}}  
              />
        <Form>
          <FormGroup inline name={field.id} onChange={this.handleRadioCheckChange}>
              {field.selectoptions.map((item, key=item.id)=>this.getSelectItems(item, "checkbox", field['id']))}
          </FormGroup>
        </Form>

      </div>
      )
  }
    if (field['type']  === "radio"){
      let defaultvals = {}
      for (let i in this.props.selectvalue[field.id]){
        if(this.props.selectvalue[field.id][i]===true){
          defaultvals[field.id]=i
        }
      }
      return(
        <div className="editor-content-item" style={{width:"100%", flexGrow:"1", marginBottom:"30px"}}>
            <Input 
              name={"header"}
              placeholder={"New question..."}
              value={this.props.content.header}
              type={"text"}
              onChange={this.updateContentHeaders}
              style={{backgroundColor:"transparent", borderColor:"#8f9ba640", color:this.props.surveyprops.style.formcardtextcolor}}  
              />
       
        <Form>
          <FormGroup inline name={field.id} onChange={this.handleRadioCheckChange}>
              {this.props.content.selectoptions.map((item, key=item.id)=>this.getSelectItems(item, "radio", field['id']))}
          </FormGroup>
        </Form>
      </div>
      )
    }

    if (field['type']  === "slider"){


      return(
        <div className="editor-content-item" style={{width:"100%", flexGrow:"1", marginBottom:"30px"}}>
            <Input 
              name={"header"}
              placeholder={"New question..."}
              value={this.props.content.header}
              type={"text"}
              onChange={this.updateContentHeaders}
              style={{backgroundColor:"transparent", borderColor:"#8f9ba640", color:this.props.surveyprops.style.formcardtextcolor}}  
              />
        <div style={{marginTop:"15px", marginLeft:"15px", marginRight:"15px", marginBottom:"30px"}}>
        <Slider
          min={field['slidermin']}
          max={field['slidermax']}
          step={field['sliderstep']}
          marks={field['slidermarks']}
          dots={true}
          defaultValue={this.props.selectvalue[field.id]}

          onAfterChange={(e)=>this.handleSliderChange(field['id'], e)}

          dotStyle={{borderColor: '#c8ced3'}}
          activeDotStyle={{borderColor: '#c8ced3'}}
          id={field['id']}
          name={field['id']}
          />
        </div>
        </div>
      )
    }

    if (field['type']  === "range"){


      let rngdefault = [field['slidermin'], field['slidermax']]
      if(Array.isArray(this.props.selectvalue[field.id])){
        rngdefault = this.props.selectvalue[field.id]
      }


      return(
        <div className="editor-content-item" style={{width:"100%", flexGrow:"1", marginBottom:"30px"}}>
            <Input 
              name={"header"}
              placeholder={"New question..."}
              value={this.props.content.header}
              type={"text"}
              onChange={this.updateContentHeaders}
              style={{backgroundColor:"transparent", borderColor:"#8f9ba640", color:this.props.surveyprops.style.formcardtextcolor}}  
              />
        <div style={{marginTop:"15px", marginLeft:"15px", marginRight:"15px", marginBottom:"30px"}}>
        <Range
          min={field['slidermin']}
          max={field['slidermax']}
          step={field['sliderstep']}
          marks={field['slidermarks']}
          dots={true}
          id={field['id']}
          name={field['id']}
          defaultValue={rngdefault}
          onAfterChange={(e)=>this.handleSliderChange(field['id'], e)}
          />
        </div>
        </div>
      )
    }

    if (field['type'] === "img"){
      return(
          <div onClick={()=>this.activeItem('SurveyCard', field.id)} style={{marginBottom:"30px"}}>
          <img width="100%" src={field.img} alt="Survey Image" />
          </div>
      )
  }

  if (field['type'] === "bigimg"){
    return(
        <div tag="a"  onClick={() => this.props.toggleLightbox(field['img'], field['imageTitle'], field['imageCaption'])} style={{cursor: "pointer", marginBottom:"30px"}} >
        <p  style={{color:'#20a8d8'}} onClick={()=>this.activeItem('SurveyCard', field.id)}><small><i>Edit Lightbox Image</i></small></p>
        <img width="100%" src={field['img']} alt="Survey Image" />
        </div>
    )
}

if (field['type'] === "gif"){
        return(
            <div style={{cursor: "pointer", marginBottom:"30px"}}>
            <p  style={{color:'#20a8d8'}} onClick={()=>this.activeItem('SurveyCard', field.id)}><small><i>Edit Gif</i></small></p>
            <GifPlayer
                gif={field['gif']}
                width="100%"
                />
            <p  style={{color:this.props.surveyprops.style.formcardtextcolor}}><small>Click to play</small></p>
            </div>
    )
}

if (field['type'] === "video"){
    return(
        <div style={{display:"flex", flexGrow:"1"}}>
        <p  style={{color:'#20a8d8'}} onClick={()=>this.activeItem('SurveyCard', field.id)}><small><i>Edit Video</i></small></p>
        <ReactPlayer
            url={field['video']}
            controls={true}
            muted={true}
            width='100%'
            height='100%'
            />
        </div>
        )
}

  if (field['type'] === "contentlist"){
      return(
        <div style={{marginLeft:"0px", marginTop:"0px"}}>
        {field['list'].length === 0 && <p style={{fontSize:"0.5em", color:"#8f9ba6"}}>Empty List</p>}
          <ListGroup className="editor-content-item" onClick={()=>this.activeItem('SurveyCard', field.id)} >
          {field.list.map((item, key=item.id)=>this.renderListItem(item.header, item.detail, key))}
          </ListGroup>
        </div>
          )
  }

  if (field['type']=== "jumbotron"){
      return(
          <Jumbotron fluid onClick={()=>this.activeItem('SurveyCard', field.id)}>
      <div style={{
        backgroundColor:this.props.surveyprops.style.jumbobackground,
        color:this.props.surveyprops.style.jumbotrontext,
        width:"100%"
        }}>
          <h1>
              {field.headline}
          </h1>
      </div>
      <div>
          <h4>
              {field.subheadline}
          </h4>
      </div>
          </Jumbotron>
          )
  }

  if (field['type'] === 'jumbotron2'){
      return(
          <Jumbotron className="editor-content-item-jumbotron2" onClick={()=>this.activeItem('SurveyCard', field.id)} 
          style={{
            backgroundColor:this.props.surveyprops.style.jumbobackground,
            color:this.props.surveyprops.style.jumbotrontext,
            width:"100%"
          }}>
              <h1>{field.headline}</h1>
              <h4>{field.subheadline}</h4>
              <hr style={{borderColor:this.props.surveyprops.style.jumboline }}/>
              <p style={{marginTop:"15px"}}>{field.bodytext}</p>
          </Jumbotron>
      )
  }


// for the showif  }

}

  render(){

    return(

        <div  className="render-content-highlight" style={{width:"100%", display:"flex", flexGrow:"1"}}>
       
        {this.renderSelectType(this.props.content)}

        <div onClick={()=>this.activeItem('SurveyCard', this.props.content.id)}  >

        <span><i className="cui-options" style={{fontSize:"1em", cursor:"pointer", color:"#8f9ba6"}}  data-place="right" data-tip={edittip} ></i></span>

        {this.props.content.showif.id !== null && <p style={{fontSize:"0.5em", color:"#20a8d8", marginLeft:"3px", marginBottom:"0px"}}>IF</p>}
        {this.props.content.validationtype !== "none" && <p style={{fontSize:"0.5em", color:"#20a8d8", marginLeft:"3px", marginTop:"0px"}}>REQ</p>}
        {['select', 'multiselect', 'selectcreatable', 'multiselectcreatable', 'checkbox', 'radio'].includes(this.props.content.type)  && this.props.content.selectoptions.length === 0 &&
        <p><i className="icon-wrench" style={{fontSize:"0.6em", cursor:"pointer", color:"#8f9ba6", marginLeft:"3px"}} ></i></p>
        }
        </div>
        
        </div>
        
        


    );
  }
}
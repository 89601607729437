import React, { Component } from 'react';
import {Editor, EditorState, RichUtils, Modifier, ContentState, convertFromHTML, convertToRaw} from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';
import {stateFromHTML} from 'draft-js-import-html';

import './SurveyTextEditor.scss';



function getBlockStyle(block) {
    switch (block.getType()) {
      case 'blockquote': return 'RichEditor-blockquote';
      default: return null;
    }
  }

const BLOCK_TYPES = [
    {label: 'H1', style: 'header-one'},
    {label: 'H2', style: 'header-two'},
    {label: 'H3', style: 'header-three'},
    {label: 'H4', style: 'header-four'},
    {label: 'H5', style: 'header-five'},
    {label: 'H6', style: 'header-six'},
    {label: 'Bullets', style: 'unordered-list-item'},
    {label: 'Num List', style: 'ordered-list-item'},
  ];

  const BlockStyleControls = (props) => {
    const {editorState} = props;
    const selection = editorState.getSelection();
    const blockType = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType();

    return (
      <div className="RichEditor-controls">
        {BLOCK_TYPES.map((type) =>
          <StyleButton
            key={type.label}
            active={type.style === blockType}
            label={type.label}
            onToggle={props.onToggle}
            style={type.style}
          />
        )}
      </div>
    );
  };

  var INLINE_STYLES = [
    {label: 'Bold', style: 'BOLD'},
    {label: 'Italic', style: 'ITALIC'},
    {label: 'Underline', style: 'UNDERLINE'},
  ];

  const InlineStyleControls = (props) => {
    var currentStyle = props.editorState.getCurrentInlineStyle();
    return (
      <div className="RichEditor-controls">
        {INLINE_STYLES.map(type =>
          <StyleButton
            key={type.label}
            active={currentStyle.has(type.style)}
            label={type.label}
            onToggle={props.onToggle}
            style={type.style}
          />
        )}
      </div>
    );
  };

    /// color change has been disabled, will use survey and card props instead
  var COLORS = [
      {label: 'Red', style: 'red'},
      {label: 'Orange', style: 'orange'},
      {label: 'Yellow', style: 'yellow'},
      {label: 'Green', style: 'green'},
      {label: 'Blue', style: 'blue'},
      {label: 'Purple', style: 'purple'},
      {label: 'White', style: 'white'},
    ];

    const ColorControls = (props) => {
      var currentStyle = props.editorState.getCurrentInlineStyle();
      return (
        <div style={styles.controls}>
          {COLORS.map(type =>
            <StyleButton
              key={type.label}
              active={currentStyle.has(type.style)}
              label={type.label}
              onToggle={props.onToggle}
              style={type.style}
            />
          )}
        </div>
      );
    };

    // This object provides the styling information for our custom color
    // styles.
    const colorStyleMap = {
      red: {
        color: 'rgba(255, 0, 0, 1.0)',
      },
      orange: {
        color: 'rgba(255, 127, 0, 1.0)',
      },
      yellow: {
        color: 'rgba(180, 180, 0, 1.0)',
      },
      green: {
        color: 'rgba(0, 180, 0, 1.0)',
      },
      blue: {
        color: 'rgba(0, 0, 255, 1.0)',
      },
      purple: {
        color: 'rgba(127, 0, 255, 1.0)',
      },
      white: {
        color: 'rgba(255, 255, 255, 1.0)',
      },
    };

    const styles = {
      root: {
        fontFamily: '\'Helvetica\', sans-serif',
        fontSize: 14,
        padding: 20,
        width: 470,
      },
      editor: {
        borderTop: '1px solid #ddd',
        cursor: 'text',
        fontSize: 16,
        marginTop: 20,
        minHeight: 250,
        paddingTop: 20,
      },
      controls: {
        fontFamily: '\'Helvetica\', sans-serif',
        fontSize: 14,
        marginBottom: 10,
        userSelect: 'none',
      },
      styleButton: {
        color: '#999',
        cursor: 'pointer',
        marginRight: 16,
        padding: '2px 0',
      },
    };

    export default class SurveyTextEditor extends Component {

          constructor(props) {
            super(props);
            let contentState = stateFromHTML(this.props.content);
            const newEditorState = EditorState.createWithContent(contentState);
            this.state = {editorState: newEditorState};
  
            this.focus = () => this.refs.editor.focus();
            this.onChange = (editorState) => this.setState({editorState, htmlContent:stateToHTML(this.state.editorState.getCurrentContent())}, ()=>this.props.getHTML(this.state.htmlContent));
  
            this.handleKeyCommand = (command) => this._handleKeyCommand(command);
            this.onTab = (e) => this._onTab(e);
            this.toggleBlockType = (type) => this._toggleBlockType(type);
            this.toggleInlineStyle = (style) => this._toggleInlineStyle(style)
            this.toggleColor = (toggledColor) => this._toggleColor(toggledColor);
          }
  
          _handleKeyCommand(command) {
            const {editorState} = this.state;
            const newState = RichUtils.handleKeyCommand(editorState, command);
            if (newState) {
              this.onChange(newState);
              return true;
            }
            return false;
          }
  
          _onTab(e) {
            const maxDepth = 4;
            this.onChange(RichUtils.onTab(e, this.state.editorState, maxDepth));
          }
  
          _toggleBlockType(blockType) {
            this.onChange(
              RichUtils.toggleBlockType(
                this.state.editorState,
                blockType
              )
            );
          }
  
          _toggleInlineStyle(inlineStyle) {
            this.onChange(
              RichUtils.toggleInlineStyle(
                this.state.editorState,
                inlineStyle
              )
            );
          }
  
          _toggleColor(toggledColor) {
            const {editorState} = this.state;
            const selection = editorState.getSelection();
  
            // Let's just allow one color at a time. Turn off all active colors.
            const nextContentState = Object.keys(colorStyleMap)
              .reduce((contentState, color) => {
                return Modifier.removeInlineStyle(contentState, selection, color)
              }, editorState.getCurrentContent());
  
            let nextEditorState = EditorState.push(
              editorState,
              nextContentState,
              'change-inline-style'
            );
  
            const currentStyle = editorState.getCurrentInlineStyle();
  
            // Unset style override for current color.
            if (selection.isCollapsed()) {
              nextEditorState = currentStyle.reduce((state, color) => {
                return RichUtils.toggleInlineStyle(state, color);
              }, nextEditorState);
            }
  
            // If the color is being toggled on, apply it.
            if (!currentStyle.has(toggledColor)) {
              nextEditorState = RichUtils.toggleInlineStyle(
                nextEditorState,
                toggledColor
              );
            }
  
            this.onChange(nextEditorState);
          }

        convertHtml() {

          console.log('coverting to html.......')

            const contentState = this.state.editorState.getCurrentContent();
            const editorContentHtml = stateToHTML(contentState)
            this.setState({htmlContent: editorContentHtml})

        }


          render() {
            {
                const {editorState} = this.state;
                return (
                  <div style={{width:"200px"}}>
                  {this.props.saveHtml && this.convertHtml}

                  <div style={styles.root}>
                    <BlockStyleControls
                    editorState={editorState}
                    onToggle={this.toggleBlockType}
                    />
                    <InlineStyleControls
                    editorState={editorState}
                    onToggle={this.toggleInlineStyle}
                    />

                    <div style={styles.editor} onClick={this.focus}>
                      <Editor
                        blockStyleFn={getBlockStyle}
                        customStyleMap={colorStyleMap}
                        handleKeyCommand={this.handleKeyCommand}
                        editorState={editorState}
                        onChange={this.onChange}
                        onTab={this.onTab}
                        ref="editor"
                        spellCheck={true}
                      />
                    </div>
                  </div>
                  </div>
                );
              }
            }
        }  


  
        class StyleButton extends React.Component {
          constructor() {
            super();
            this.onToggle = (e) => {
              e.preventDefault();
              this.props.onToggle(this.props.style);
            };
          }
  
          render() {
            let style
            let className = 'RichEditor-styleButton';
            if (this.props.active) {
              className += ' RichEditor-activeButton';
              style = {...styles.styleButton, ...colorStyleMap[this.props.style]}
            } else {
                style = styles.styleButton;
              }
  
            return (
              <span className={className} onMouseDown={this.onToggle}>
                {this.props.label}
              </span>
            );
          }
        }
  

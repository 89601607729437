import React, { Component } from 'react';
import { Card, CardBody, Progress, Button } from 'reactstrap';
import classNames from 'classnames';
import { mapToCssModules } from 'reactstrap/lib/utils';
import GifPlayer from 'react-gif-player';
import ReactPlayer from 'react-player';




export default class SolidAssetCard extends Component {
        constructor(props) {
          super(props);
          this.state = {
          };
        }


getAsset(){


    if(this.props.row.questiontype === "img" || this.props.row.questiontype === "bigimg" || this.props.row.questiontype === "design"){
      return(
        <div >
        <img width="100%" src={this.props.row.img} alt="Survey Image" />
        </div>
      )
    }

    if(this.props.row.questiontype === "video"){
      return(
        <div >
      <ReactPlayer
        url={this.props.row['video']}
        controls='true'
        muted='true'
        width='100%'
        height='100%'
        />
        </div>
      )
    }

    if(this.props.row.questiontype === "gif"){
      return(
        <div >
          <GifPlayer
            gif={this.props.row['gif']}
            width="100%"
            />
        </div>
      )
    }

    if(this.props.row.questiontype === "choice"){

      if(this.props.row.img !== "None"){
        return(
          <div >
          <img width="100%" src={this.props.row.img} alt="Survey Image" />
          </div>
        )
      }
  
      if(this.props.row.video !== "None"){
        return(
          <div >
        <ReactPlayer
          url={this.props.row['video']}
          controls='true'
          muted='true'
          width='100%'
          height='100%'
          />
          </div>
        )
      }
  
      if(this.props.row.gif !== "None"){
        return(
          <div >
            <GifPlayer
              gif={this.props.row['gif']}
              width="100%"
              />
          </div>
        )
      }

    }

    if(!['img', 'design', 'choice', 'gif', 'video'].includes(this.props.row.questiontype)){

      if(this.props.row.imageSrc !== "None"){
        return(
          <div >
          <img width="100%" src={this.props.row.imageSrc} alt="Survey Image" />
          </div>
        )
      }
    }

}

  render() {
    const { className, cssModule, header, icon, color, value, children, linkto, invert, ...attributes } = this.props;
    const progress = { style: '', color: color, value: value };
    const card = { style: '', bgColor: '', icon: icon };

    if (invert) {
      progress.style = 'progress-white';
      progress.color = '';
      card.style = 'text-white';
      card.bgColor = 'bg-' + color;
    }

    const classes = mapToCssModules(classNames(className, card.style, card.bgColor), cssModule);
    progress.style = classNames('progress-xs mt-3 mb-0', progress.style);

    return (
      <div style={{ height:"100%", display:"flex", alignItems:"stretch"}}>
      <Card style={{padding:"0px"}} className={classes} {...attributes}>
          
        <CardBody>
        
        {this.getAsset()}
 
        </CardBody>

      </Card>
      </div>
    );
  }
}

import React, { Component } from "react";
import { API } from "aws-amplify";
import Loader from "react-loader-spinner";
import DataTable, {createTheme} from 'react-data-table-component';
import DesignExpanderMenu from './DesignExpanderMenu'

/// also search, filter and download csv - see examples https://jbetancur.github.io/react-data-table-component/?path=/story/filtering--example-1
/// need to only show those with same ORG ID

const conditionalRowStyles = [
  {
    when: row => row.status === "LIVE",
    style: {
      fontWeight: "500",
      },
    },
    {
      when: row => row.selectedrow === true,
      style: {
        fontWeight: "500",
        backgroundColor:"#2f353a",
        color: "#20a8d8",
        },
      },
];

createTheme('pricekit', {
  text: {
    primary: '#2f353a',
    secondary: '#5c6873',
  },
  background: {
    default: 'transparent',
  },
  context: {
    background: '#20a8d8',
    text: '#f0f3f5',
  },
  divider: {
    default: '#f0f3f5',
  },
  action: {
    button: 'rgba(0,0,0,.54)',
    hover: 'rgba(0,0,0,.08)',
    disabled: 'rgba(0,0,0,.12)',
  },
});

const columns = [
  {
    name: 'Design',
    selector: 'designid',
    sortable: true,
    width:'80px'
  },
  {
    name: 'Type',
    selector: 'questiontype',
    sortable: true,
    width:'80px'
  },
  {
    name: 'Last Update',
    selector: 'localsavetime',
    sortable: true,
    width:'150px'
  },
  {
    name: 'Image Location',
    selector: 'imageSrc',
    sortable: true,
    width:'200px'
  },

  {
    name: 'Annotations',
    selector: 'annotations',
    sortable: true,
    width:'200px'
  },
  {
    name: 'Option Header',
    selector: 'optionheader',
    sortable: true,
    width:'200px'
  },
  {
    name: 'Feature Header',
    selector: 'featureheader',
    sortable: true,
    width:'200px'
  },
];



export default class DataTableDesign extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        isLoading: true,
        data: []
      };
    }

    async componentDidMount() {
        try{
          let querystring = {}
          let orgquery = {}
          orgquery['orgId']=this.props.orgId
          orgquery['querytype']='ALL'
          querystring['queryStringParameters'] = orgquery
          let responses = await API.get("designs", "/designs", querystring)
          let numberassets = responses.length
          let numberimg=0
          let numbervideo=0
          let numbergif=0
          let nums ={}
          nums['numberassets']=numberassets

          for(let i in responses){
            responses[i]['selectedrow'] = false
          }

          this.setState({data: responses, isLoading:false}, ()=>this.props.updateCard(nums))

        } catch (e) {
            alert('There was problem getting asset list', e);
        }
        

        }


        updateState = state => {

          if(state.designid !== this.state.selectedasset){

            let datacopy = [...this.state.data]
            
            for(let i in datacopy){
              if(datacopy[i]['designid']===state.designid){
                datacopy[i]['selectedrow']=true
              }
              if(datacopy[i]['designid']===this.state.selectedasset){
                datacopy[i]['selectedrow']=false
              }
            }

          this.setState({ selectedasset: state.designid, data:datacopy }, ()=>this.props.getRow(true, state));
          }
        }



        updateStateToggle = (togglestate, row) => {
        }


    render() {
        return (
          <div>
            
            {this.state.isLoading && <Loader type="Puff" color="#20a8d8" height={100} width={100} timeout={3000}/>}

        <DataTable style={{overflowX:"hidden"}}
            keyField={"assetId"}
            title="Asset List"
            theme="pricekit"
            columns={columns}
            data={this.state.data}
            defaultSortField="localsavetime"
            defaultSortAsc={false}
            selectableRows={false}
            selectableRowsNoSelectAll={true}
            selectableRowsHighlight={true}
            expandableRows={true}
            expandableRowsComponent={<DesignExpanderMenu />}
            onRowExpandToggled={this.updateStateToggle}
            onRowClicked={this.updateState}
            expandOnRowClicked={true}
            pagination={false}
            highlightOnHover={true}
            striped={false}
            pointerOnHover={true}
            dense={false}
            noTableHead={false}
            persistTableHead={true}
            progressPending={false}
            noHeader={true}
            subHeader={false}
            subHeaderAlign={"right"}
            fixedHeader={true}
            fixedHeaderScrollHeight="400px"
            conditionalRowStyles={conditionalRowStyles}
      />
      </div>
      );
    }
}
import React, { Component } from "react";
import {loadStripe} from '@stripe/stripe-js';
import {CardElement,} from '@stripe/react-stripe-js';
import { Button, Col, Row, Form,  } from 'reactstrap';
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";

import Toggle from 'react-toggle'
import 'react-toggle/style.css'

const privacylink = <a href="https://pricekit.io/privacy" target="_blank">privacy policy</a>
const termslink = <a href="https://pricekit.io/terms" target="_blank">terms of service</a>

export default class StripeForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
          isLoading: true,
          email:  this.props.email,
          firstname: "",
          lastname: "",
          orgname: "",
          role: "Admin",
          license: "",
          pageValid: false,
          terms: false,
        };
      }

    changeSelection(e){
      this.setState({terms:e}, () => this.validateForm())
  }

  validateForm(){
    if(this.state.firstname.length > 0 && this.state.lastname.length > 0 && this.state.orgname.length > 0 && this.state.terms){
      this.setState({pageValid: true}, () => this.props.userHasDetails(this.state))
    } else {this.setState({pageValid: false})}
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    }, () => this.validateForm());
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const {stripe, elements} = this.props;
    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    })
    console.log('error', error)
    console.log('payment method', paymentMethod);
    this.props.userHasPayment(paymentMethod)
  };

  render() {
    const {stripe} = this.props;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row form>
          <Col>
    <FormGroup controlId="firstname" bsSize="large">
      <ControlLabel>First Name</ControlLabel>
      <FormControl
      autoFocus
      type="text"
      value={this.state.firstname}
      onChange={this.handleChange}
      />
    </FormGroup>
    </Col>
    <Col>
    <FormGroup controlId="lastname" bsSize="large">
      <ControlLabel>Last Name</ControlLabel>
      <FormControl
      value={this.state.lastname}
      onChange={this.handleChange}
      type="text"
      />
    </FormGroup>
    </Col>
    </Row>
    <Row>
      <Col x6="6">
    <FormGroup controlId="orgname" bsSize="large">
      <ControlLabel>Organization Name</ControlLabel>
      <FormControl
      value={this.state.orgname}
      onChange={this.handleChange}
      type="text"
      />
    </FormGroup>
    </Col>
    <Col xs="6"></Col>
    </Row>
            <p style={{fontWeight:"bold"}}>Credit Card Details</p>
      <Row form>
        <Col xs="6" style={{border: "1px solid", borderColor:"#e4e7ea", borderRadius: "4px", padding: "12px", marginRight: "7px"}}><CardElement/></Col>
      </Row>
      <p style={{fontWeight:"bold", marginTop:"15px"}}>Please review and agree to our {privacylink} and our {termslink}</p>
      <p><muted>Among other things, these terms say that we won't share or disclose your information with third parties and that you will not collect or use sensitive PII on our platform</muted></p>
      <Row form>
      <Col style={{marginRight:"5px"}}>
        <div style={{display:"flex"}}>
      <Toggle
                id={"Terms"}
                checked= {this.state.terms}
                icons={{unchecked: null}}
                className = {"custom-success"}
                onChange = {() => this.changeSelection(!this.state.terms)}
                />
      <p style={{marginLeft:"5px"}}><muted>Check here to agree</muted></p>
      </div>
      </Col>
      </Row>
      <Row className="justify-content-right" >
      <Col xs="6">

      <Button color="primary" className="px-4" size="lg" type="submit" disabled={!this.state.pageValid} style={{marginTop:"25px"}}>Submit</Button>

      </Col>
      </Row>
    </Form>
    );
  }

}


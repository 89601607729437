import React, { Component } from 'react';
import { Card, CardBody, Progress } from 'reactstrap';
import classNames from 'classnames';
import { mapToCssModules } from 'reactstrap/lib/utils';




export default class SolidInfoCard extends Component {
  render() {
    const { className, cssModule, header, icon, color, value, children, pname, linkto, invert, ...attributes } = this.props;
    const progress = { style: '', color: color, value: value };
    const card = { style: '', bgColor: '', icon: icon };

    if (invert) {
      progress.style = 'progress-white';
      progress.color = '';
      card.style = 'text-white';
      card.bgColor = 'bg-' + color;
    }

    const classes = mapToCssModules(classNames(className, card.style, card.bgColor), cssModule);
    progress.style = classNames('progress-xs mt-3 mb-0', progress.style);

    return (
    
        <div style={{height:"100%", display:"flex", alignItems:"stretch", width:"100%"}}>
      <Card className={classes} {...attributes} style={{width:"100%"}}>
          
        <CardBody>
          <div className="h1 text-muted text-right mb-0 mt-0"  >
          <a href={linkto} style={{color:"white", textDecoration:"none"}}>
           <i className={card.icon}></i>
          </a>
          </div>
          <div className="h1 mt-0 mb-0" style={{fontSize:this.props.fontsize, fontWeight:"600"}}>{header}</div>
          <div><p className="text-muted text-uppercase" style={{fontSize:"1em"}}>{pname}</p></div>
          <small className="text-muted text-uppercase font-weight-bold">{children}</small>
          <Progress className={progress.style} color={progress.color} value={progress.value} />
        </CardBody>
      </Card>
      </div>
    );
  }
}

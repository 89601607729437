import React, { Component } from "react";
import { API } from "aws-amplify";
import Loader from "react-loader-spinner";
import DataTable, {createTheme} from 'react-data-table-component';
import { Button, Card, CardBody, CardGroup, CardHeader, Col, Container, Row } from 'reactstrap';
import SurveyExpanderMenu from './SurveyExpanderMenu'

/// also search, filter and download csv - see examples https://jbetancur.github.io/react-data-table-component/?path=/story/filtering--example-1
/// need to only show those with same ORG ID

/// gets all survey responses
const conditionalRowStyles = [
  {
    when: row => row.status === "LIVE",
    style: {
      fontWeight: "500",
      },
    },
    {
      when: row => row.selectedrow === true,
      style: {
        fontWeight: "500",
        backgroundColor:"#2f353a",
        color: "#20a8d8",
        },
      },
];

createTheme('pricekit', {
  text: {
    primary: '#2f353a',
    secondary: '#5c6873',
  },
  background: {
    default: 'transparent',
  },
  context: {
    background: '#20a8d8',
    text: '#f0f3f5',
  },
  divider: {
    default: '#f0f3f5',
  },
  action: {
    button: 'rgba(0,0,0,.54)',
    hover: 'rgba(0,0,0,.08)',
    disabled: 'rgba(0,0,0,.12)',
  },
});


const columns = [
  {
    name: 'Survey ID',
    selector: 'surveyId',
    sortable: true,
    width:'100px'
  },
  {
    name: 'Title',
    selector: 'surveytitle',
    sortable: true,
    width:'200px'
  },
  {
    name: 'Last Update',
    selector: 'localsavetime',
    sortable: true,
    width:'150px'
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true,
    width:'100px'
  },
  {
    name: 'Survey URL',
    selector: 'surveyurl',
    sortable: false,
    width:'250px'
  },
  {
    name: 'Total Pages',
    selector: 'totalpages',
    sortable: true,
    width:'80px'
  },
  { name:"Survey Colors",
  cell: row => <div style={{display:"flex"}}>
    <div style={{width:"20px", height:'20px', marginRight:"2px", backgroundColor:row.pagebodycolor}}></div>
    <div style={{width:"20px", height:'20px', marginRight:"2px", backgroundColor:row.formbodycolor}}></div>
    <div style={{width:"20px", height:'20px', marginRight:"2px", backgroundColor:row.formheadcolor}}></div>
    <div style={{width:"20px", height:'20px', marginRight:"2px", backgroundColor:row.formcardtextcolor}}></div>
    <div style={{width:"20px", height:'20px', marginRight:"2px", backgroundColor:row.formheadtextcolor}}></div>
    </div>,
  ignoreRowClick: false,
  allowOverflow: true,
  },
]

export default class DataTableSurvey extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isLoading: true,
        data: []
      };
    }

    async componentDidMount() {
        try{
          let querystring = {}
          let orgquery = {}
          orgquery['orgId']=this.props.orgId
          orgquery['querytype']='ALL'
          querystring['queryStringParameters'] = orgquery
          let responses = await API.get("survey", "/survey", querystring)
          let numbersurvey = responses.length
          let draftsurvey=0
          let livesurvey=0
          let completesurvey=0
          let pendingsurvey = 0
          let nums ={}
          for (let x in responses){
            if(responses[x]['status']==='DRAFT' || responses[x]['status']==='TEST'){
              draftsurvey=draftsurvey+1
            }
            if(responses[x]['status']==='LIVE'){
              livesurvey=livesurvey+1
            }
            if(responses[x]['status']==='COMPLETE'){
              completesurvey=completesurvey+1
            }
            if(responses[x]['status']==='PENDING' || responses[x]['status']==='TEST PENDING'){
              pendingsurvey=pendingsurvey+1
            }
          }

          nums['numbersurvey']=numbersurvey
          nums['draftsurvey']=draftsurvey
          nums['completesurvey']=completesurvey
          nums['livesurvey']=livesurvey
          nums['pendingsurvey']=pendingsurvey

          for(let i in responses){
            responses[i]['selectedrow'] = false
          }

          this.setState({data: responses, isLoading: false}, ()=>this.props.updateCard(nums))
        } catch (e) {
            alert('There was problem getting survey list', e);
        }
        }




        async componentDidUpdate(prevProps, prevState){
    
          if (this.props.updatetable !==prevProps.updatetable && this.props.updatetable === true ){

            try{
            let querystring = {}
            let orgquery = {}
            orgquery['orgId']=this.props.orgId
            orgquery['querytype']='ALL'
            querystring['queryStringParameters'] = orgquery
            let responses = await API.get("survey", "/survey", querystring)
            let numbersurvey = responses.length
            let draftsurvey=0
            let livesurvey=0
            let completesurvey=0
            let pendingsurvey = 0
            let nums ={}
            for (let x in responses){
              if(responses[x]['status']==='DRAFT' || responses[x]['status']==='TEST'){
                draftsurvey=draftsurvey+1
              }
              if(responses[x]['status']==='LIVE'){
                livesurvey=livesurvey+1
              }
              if(responses[x]['status']==='COMPLETE'){
                completesurvey=completesurvey+1
              }
              if(responses[x]['status']==='PENDING' || responses[x]['status']==='TEST PENDING'){
                pendingsurvey=pendingsurvey+1
              }
            }
            nums['numbersurvey']=numbersurvey
            nums['draftsurvey']=draftsurvey
            nums['completesurvey']=completesurvey
            nums['livesurvey']=livesurvey
            nums['pendingsurvey']=pendingsurvey
  
            for(let i in responses){
              responses[i]['selectedrow'] = false
            }
  
            this.setState({data: responses, isLoading: false}, ()=>this.props.updateCard(nums))
          } catch (e) {
              alert('There was problem getting survey list', e);
          }

        }
      }



        updateState = state => {

          if(state.surveyId !== this.state.selectedsurvey){

            let datacopy = [...this.state.data]
            
            for(let i in datacopy){
              if(datacopy[i]['surveyId']===state.surveyId){
                datacopy[i]['selectedrow']=true
              }
              if(datacopy[i]['surveyId']===this.state.selectedsurvey){
                datacopy[i]['selectedrow']=false
              }
            }

          this.setState({ selectedsurvey: state.surveyId, data:datacopy }, ()=>this.props.getRow(true, state));
          }
        }



        updateStateToggle = (togglestate, row) => {

          this.props.getRow(togglestate, row)

        }



    render() {
        return (
          <div>
            
            {this.state.isLoading && <Loader type="Puff" color="#20a8d8" height={100} width={100} timeout={5000}/>}

        <DataTable style={{overflowX:"hidden"}}
            keyField={"surveyId"}
            title="Survey List"
            theme="pricekit"
            columns={columns}
            data={this.state.data}
            defaultSortField="localsavetime"
            defaultSortAsc={false}
            selectableRows={false}
            selectableRowsNoSelectAll={true}
            selectableRowsHighlight={true}
            expandableRows={true}
            expandableRowsComponent={<SurveyExpanderMenu />}
            onRowExpandToggled={this.updateStateToggle}
            onRowClicked={this.updateState}
            expandOnRowClicked={false}
            pagination={false}
            highlightOnHover={false}
            striped={false}
            pointerOnHover={true}
            dense={false}
            noTableHead={false}
            persistTableHead={true}
            progressPending={false}
            noHeader={true}
            subHeader={false}
            subHeaderAlign={"right"}
            fixedHeader={true}
            fixedHeaderScrollHeight="400px"
            conditionalRowStyles={conditionalRowStyles}
      />
      </div>
      );
    }
}
export default {
  items: [
    {
      name: 'HOME',
      url: '/home',
      icon: 'icon-home',
    },
    {
      title: true,
      name: 'DASHBOARD',
      wrapper: {            // optional wrapper object
        element: '',        // required valid HTML5 element tag
        attributes: {}        // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: ''             // optional class names space delimited list for title item ex: "text-center"
    },
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'icon-speedometer',
      attributes: {}
    },
     {
      title: true,
      name: 'SURVEYS ',
      wrapper: {            // optional wrapper object
        element: '',        // required valid HTML5 element tag
        attributes: {}        // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: ''             // optional class names space delimited list for title item ex: "text-center"
    },
    {
      name: 'Quick Start',
      url: '/setup',
     icon: 'icon-heart',
///     icon: 'icon-ban',
///      attributes: { disabled: true }
   },
    {
      name: 'Surveys',
      url: '/surveys',
      icon: 'icon-pencil',
    },
    {
      name: 'Survey Editor',
      url: '/surveys/editor',
      icon: 'icon-size-actual',
    },
 ///   {
///      name: 'Questions',
///      url: '/surveys/questions',
///      icon: 'icon-book-open',
///    },
    {
      name: 'Packages',
      url: '/surveys/packages',
      icon: 'icon-grid',
    },
    {
      name: 'Features',
      url: '/surveys/features',
      icon: 'icon-layers',
    },
///    {
///      name: 'Designs',
///      url: '/surveys/designs',
///      icon: 'icon-picture',
///    },
///    {
///      name: 'Assets',
///      url: '/surveys/assets',
///      icon: 'icon-folder-alt',
///    },
///    {
///      title: true,
///      name: 'AUDIENCES',
///      wrapper: {            // optional wrapper object
///        element: '',        // required valid HTML5 element tag
///        attributes: {}        // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
///      },
///      class: ''             // optional class names space delimited list for title item ex: "text-center"
///    },
///    {
///      name: 'Audience',
///      url: '/audience',
///      icon: 'icon-cursor',
///      icon: 'icon-ban',
///      attributes: { disabled: true }
///    },
    {
      title: true,
      name: 'ACCOUNT ',
      wrapper: {            // optional wrapper object
        element: '',        // required valid HTML5 element tag
        attributes: {}        // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: ''             // optional class names space delimited list for title item ex: "text-center"
    },
    {
      name: 'Settings',
      url: '/settings',
      icon: 'icon-settings',
    },
    {
      name: 'Log Out',
      url: '/logout',
      icon: 'icon-logout',
    },
    {
      title: true,
      name: 'HELP',
      wrapper: {            // optional wrapper object
        element: '',        // required valid HTML5 element tag
        attributes: {}        // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: ''             // optional class names space delimited list for title item ex: "text-center"
    },
    {
      name: 'Support Center',
      url: '/docs',
  ///    icon: 'icon-info',
      icon: 'icon-ban',
      attributes: { disabled: true }
    },
 ///   {
///      name: 'Support',
///      url: '/support',
///      icon: 'icon-flag',
///      icon: 'icon-ban',
///      attributes: { disabled: true }
///    },
  ],
};

import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Row, Jumbotron } from 'reactstrap';
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";


export default class Signup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: "",
      password: "",
      confirmPassword: "",
      confirmationCode: "",
      newUser: null,
      passmatch: false
    };
  }

  validateForm() {
    return (
      this.state.email.length > 0 &&
      this.state.password.length > 0 &&
      this.state.password === this.state.confirmPassword
    );
  }

  validateConfirmationForm() {
    return this.state.confirmationCode.length > 0;
  }

  validatePassmatch(){
    if(this.state.password.length > 0){
    if(this.state.password === this.state.confirmPassword){
      this.setState({passmatch: true})}
    }
    }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    }, () => this.validatePassmatch());
  }

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({ isLoading: true });
    if(this.validateForm()){
    try {
      const newUser = await Auth.signUp({
        username: this.state.email,
        password: this.state.password,
      });
      this.setState({
        newUser
      });
      this.props.userHasId(newUser.userSub)

    } catch (e) {
      alert(e.message);
    }
    this.setState({ isLoading: false });
    }  else {
      alert("Please make sure your password fields are valid and match each other")
    }
  }

 
  handleConfirmationSubmit = async event => {
    event.preventDefault();
    this.setState({ isLoading: true });

      try {
        await Auth.confirmSignUp(this.state.email, this.state.confirmationCode);
        await Auth.signIn(this.state.email, this.state.password);
        this.props.userHasAuthenticated(true);
        this.props.userHasEmail(this.state.email);
        this.props.history.push("/selectplan");

      } catch (e) {
        alert(e.message);
        this.setState({ isLoading: false });
      }
  }
  

  renderConfirmationForm() {
    return (
    <div className="app flex-row align-items-top">
    <Container>

      <Row className="justify-content-center">
       <Col md="8">
        <CardGroup>
        <Card className="p-4">
        <CardBody>
          <Form onSubmit={this.handleConfirmationSubmit}>
            <h1>Confirmation</h1>
            <p className="text-muted">Please check the email you used to register to get your confirmation code</p>
          <FormGroup controlId="confirmationCode" bsSize="large">
          <ControlLabel>Confirmation Code</ControlLabel>
          <FormControl
            autoFocus
            type="tel"
            value={this.state.confirmationCode}
            onChange={this.handleChange}
              />
          </FormGroup>
      <Row>
      <Col xs="6">
      <Button color="primary" className="px-4" disabled={this.state.confirmationCode.length<1}size="lg">Submit</Button>
      </Col>
      </Row>
      </Form>
      </CardBody>
      </Card>
      </CardGroup>
      </Col>
      </Row>
    </Container>
    </div>
    );
  } 
  
  renderForm() {
    return (
      <div className="app flex-row align-items-top">
      <Container>
        <Row >
        <Col md="8">
        <CardGroup>
        <Card className="p-4">
          <CardBody>
          <Form onSubmit={this.handleSubmit}>
            <p className="text-muted">Your email will be the username.</p>
          <FormGroup controlId="email" bsSize="large">
            <ControlLabel>Email</ControlLabel>
            <FormControl
            autoFocus
            type="email"
            value={this.state.email}
            onChange={this.handleChange}
            />
          </FormGroup>
          <div style={{marginTop:"15px"}}>
          <p className="text-muted">Passwords are 8 characters minimum with at least one upper, lower, number and special character.</p>
          </div>
          <FormGroup controlId="password" bsSize="large">
            <ControlLabel>Password</ControlLabel>
            <FormControl
            value={this.state.password}
            onChange={this.handleChange}
            type="password"
            />
          </FormGroup>
          <FormGroup controlId="confirmPassword" bsSize="large">
            <ControlLabel>Confirm Password</ControlLabel>
            <FormControl
            value={this.state.confirmPassword}
            onChange={this.handleChange}
            type="password"
            />
          </FormGroup>
          <Row>
          <Col xs="6" style={{marginTop:"25px"}}>
          <Button color="primary" className="px-4" disabled={!this.state.passmatch} size="lg">Submit</Button>
          </Col>
          </Row>
          </Form>
          </CardBody>
        </Card>
        </CardGroup>
        </Col>
        </Row>
        </Container>
      </div>
    );
  }

  render() {
    return (
      <Container>
      <Jumbotron>
        <h1>Sign-Up for Pricekit!</h1>
        <h4>You've found the easiest, cheapest and most accurate way to get the data you need to make smart product decisions</h4>
        <hr/>
        {!this.state.newUser &&
        <p style={{marginTop:"15px"}}>Use your business email address to create an account</p>}
      </Jumbotron>
        {this.state.newUser === null
          ? this.renderForm()
          : this.renderConfirmationForm()}
      </Container>
    );
  }
}

import React, { Component } from 'react';
import { Card, CardBody, Progress } from 'reactstrap';
import classNames from 'classnames';
import { mapToCssModules } from 'reactstrap/lib/utils';


function truncateString(str, num) {
  if (typeof str === 'undefined'){
    return "..."
  }
  if (str === ""){
    return "..."
  }
  if (str === null){
    return "..."
  }
  if (str.length <= num) {
    return str
  }
  return str.slice(0, num) + '...'
}


export default class SurveyInfoCard extends Component {
  constructor(props) {
    super(props);
    let statuscolor = "white"
    if(this.props.status === "LIVE"){statuscolor = "primary"}
    this.state = {
      statuscolor: statuscolor,
    };
  }

  activeSurvey(id, stat){
    let survey={}
    survey['surveyId']=id
    this.props.routeAction(survey, stat)
}

    getColor(){
      if (this.props.status === "LIVE"){
      return(
        "#20a8d8"
      )} else {
        return (
        "#FFFFFF"
        )
      }
    }


    render() {
      const { className, cssModule, header, icon, color, value, status, title, update, linkto, invert, ...attributes } = this.props;
      const progress = { style: '', color: color, value: value };
      const card = { style: '', bgColor: '', icon: icon };
  
      if (invert) {
        progress.style = 'progress-white';
        progress.color = '';
        card.style = 'text-white';
        card.bgColor = 'bg-' + color;
      }
  
      const classes = mapToCssModules(classNames(className, card.style, card.bgColor), cssModule);
      progress.style = classNames('progress-xs mt-3 mb-0', progress.style);
  
      return (
        <Card className={classes} {...attributes}>
            
          <CardBody>
            <div className="h1 text-muted text-right mb-0 mt-0" >
            <a href={linkto} style={{color:"white", textDecoration:"none"}}>
             <i className={card.icon}></i>
            </a>
            </div>
            <div className="h1 mt-0 mb-0" style={{fontSize:"2.5em", cursor:"pointer"}} onClick={()=>(this.activeSurvey(header, status))} >{header}</div>
            <small className="text-muted text-uppercase font-weight-bold">{truncateString(title,15)}</small>
            <p style={{color:this.getColor(), textTransform:"uppercase", fontSize:"0.8em", fontWeight:"600", marginBottom:"0px"}} > {status}</p>
            <Progress className={progress.style} color={this.state.statuscolor} value={progress.value} />
            <p style={{fontSize:"0.6em", color:"#8f9ba6", marginTop:"3px", marginBottom:"3px"}}>{`Last Update: ${update}`}</p>
          </CardBody>
        </Card>
      );
    }
  }

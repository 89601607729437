import React, { Component } from "react";
import { Card, CardBody,  CardHeader, CardFooter, } from 'reactstrap';
import FeatureListEditor from '../survey/FeatureListEditor';
import Annotation from 'react-image-annotation';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable'
import { AvForm, AvField, AvCheckbox, AvCheckboxGroup, AvRadio, AvRadioGroup } from 'availity-reactstrap-validation';
import Tooltip from 'rc-tooltip';
import Slider from 'rc-slider';
import Toggle from 'react-toggle'
import  'react-toggle/style.css'


/// styling for react-select
const customStyles = (isValid) => ({
  control: (styles: any) => ({
    ...styles,
    padding: 5,
    fontSize: "smaller",
    ...(!isValid && { borderColor: 'red' }),
  }),
});

/// styling for range and slider
const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);
const Handle = Slider.Handle;


const handle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <Tooltip
      prefixCls="rc-slider-tooltip"
      overlay={value}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps} />
    </Tooltip>
  );
};

export default class UXAnnotationEditor extends Component {
  constructor(props) {
    super(props);
    let initialannotations = []
    let initialselections = {}
    let initializefalse = ['checkbox', 'radio']
    initialannotations = this.props.options[0]['annotations']
    if (typeof this.props.savedpages != "undefined") {
      if(this.props.savedpages===true){
          initialannotations =  this.props.savedresponses['annotations']
          initialselections = this.props.savedresponses['selectvalue']
      }
    }  else {
      for (let i of this.props.options){
          let radiocheck = {}        
          initialselections[i['id']] = null
          if(this.props.questionprops.selectiontype==='toggle'){initialselections[i['id']] = false}
          if(this.props.questionprops.selectiontype==='slider'){initialselections[i['id']] = i['sliderdefault']}
          if(this.props.questionprops.selectiontype==='range'){initialselections[i['id']] = i['rangedefault']}
          if(initializefalse.includes(this.props.questionprops.selectiontype))
              {
              for (let s of i['selectoptions']){
                  radiocheck[s['value']]=false
              }
              initialselections[i['id']]=radiocheck
              }
      }
  }

    this.state = {
      options: this.props.options,
      annotations: initialannotations,
      annotation: {},
      selectvalue: initialselections,
      allocation:null,
    }
  }

  async componentDidMount(){ 

  }

  componentWillUnmount(){
    this.props.stateChangePassThru(this.props.questionId, this.state)
}


  onChange = (annotation) => {
    this.setState({ annotation })
  }

  onSubmit = (annotation) => {
    const { geometry, data } = annotation
    this.setState({
      annotation: {},
      annotations: this.state.annotations.concat({
        geometry,
        data: {
          ...data,
          id: Math.random()
        }
      })
    })
  }

  changeSelection(i, e){
    this.setState(prevState => ({selectvalue: {...prevState.selectvalue, [i]: e}}))
}

  /// callback to handle the select or multiselect change, only event used at this point for anything
  handleSelectChange = (selectvalue:any, action: any) => {
      this.changeSelection(this.props.options[0].id, selectvalue)
  };

  handleToggleSelect(e){
    this.changeSelection(this.props.options[0].id, e)
  }

  handleFieldChange = (event) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    this.changeSelection(this.props.options[0].id, value)
  }

  handleToggleChange(){
    this.changeSelection(this.props.options[0].id, !this.state.selectvalue[this.props.options[0].id])
  }

  handleRadioCheckChange = (event) => {
  const target = event.target;
  let type = target.type
  let value = target.value;
  this.toggleRadioCheck(this.props.options[0].id, value, type)
  }

  toggleRadioCheck(i, e, t){

    if(t==='radio'){
        let newvals = this.state.selectvalue[i]
        for (let p in newvals)
            {
            newvals[p]=false
            }
        newvals[e]=true

        this.setState(prevState => ({
            selectvalue: {
            ...prevState.selectvalue,          
            [i]: newvals
            }
            }))
    } else {

        this.setState(prevState => ({
            selectvalue: {
            ...prevState.selectvalue,          
            [i]: {                     
            ...prevState.selectvalue[i], 
            [e]: !this.state.selectvalue[i][e]         
            }
            }
            }))
        }
  }


  getSelectItems(i, t){
  if(t==="checkbox"){
  return(
    <AvCheckbox label={i['label']} value={i['value']}/>
  )}
  if(t==="radio"){
    return(
      <AvRadio label={i['label']} value={i['value']}/>
    )}
  }

  renderSelectType(){

    if (this.props.questionprops.selectiontype === "select"){
         return(
            <Select
            defaultValue={this.state.selectvalue[this.props.options[0].id]}
            onChange={this.handleSelectChange}
            options={this.props.options[0].selectoptions}
            styles={customStyles(true)}  /// false means there is an error and will error style
          />
        )
        }
    if (this.props.questionprops.selectiontype === "select-creatable"){
        return(
            <CreatableSelect
            defaultValue={this.state.selectvalue[this.props.options[0].id]}
            onChange={this.handleSelectChange}
            options={this.props.options[0].selectoptions}
            styles={customStyles(true)}  
            />
        )
        }
    if (this.props.questionprops.selectiontype === "multiselect"){
        return(
            <Select
            defaultValue={this.state.selectvalue[this.props.options[0].id]}
            isMulti
            onChange={this.handleSelectChange}
            options={this.props.options[0].selectoptions}
            styles={customStyles(true)}  
            />
        )
        }   
    if (this.props.questionprops.selectiontype === "multiselect-creatable"){
        return(
            <CreatableSelect
            isMulti
            defaultValue={this.state.selectvalue[this.props.options[0].id]}
            onChange={this.handleSelectChange}
            options={this.props.options[0].selectoptions}
            styles={customStyles(true)}  
            />
        )
        }

    if (this.props.questionprops.selectiontype === "toggle"){
      return(
          <Toggle
          id={this.props.options[0].id}
          value={this.props.options[0].togglevalue}
          checked= {this.state.selectvalue[this.props.options[0].id]}
          icons={{unchecked: null}}
          className = {this.props.options[0].togglestyle}
          onChange = {() => this.handleToggleChange()}
      />
      )
    }
    if (this.props.questionprops.selectiontype === "text-field"){
      return(
      <AvForm model={this.state.selectvalue}>
      <AvField name={this.props.options[0].id} type="text" 
      onBlur={this.handleFieldChange} style={{fontSize:'smaller'}}
      />
      </AvForm>
      )
    }
    if (this.props.questionprops.selectiontype === "text-area"){
    return(
    <AvForm model={this.state.selectvalue}>
    <AvField name={this.props.options[0].id} type="textarea" 
    onBlur={this.handleFieldChange} style={{fontSize:'smaller'}}
    />
    </AvForm>
    )
    }
    if (this.props.questionprops.selectiontype === "checkbox"){
      let defaultvals = {}
      let defaultchecks = []
      for (let i of Object.keys(this.state.selectvalue[this.props.options[0].id])){
        if(this.state.selectvalue[this.props.options[0].id][i]===true){
          defaultchecks.push(i)
        }
        defaultvals[this.props.options[0].id]=defaultchecks
      }
      return(
      <AvForm model={defaultvals}>
            <AvCheckboxGroup inline name={this.props.options[0].id} onBlur={this.handleRadioCheckChange} >
              {this.props.options[0].selectoptions.map((item, key=item.id)=>this.getSelectItems(item, "checkbox"))}
            </AvCheckboxGroup>
      </AvForm>
      )
  }
    if (this.props.questionprops.selectiontype === "radio"){
      let defaultvals = {}
      for (let i of Object.keys(this.state.selectvalue[this.props.options[0].id])){
        if(this.state.selectvalue[this.props.options[0].id][i]===true){
          defaultvals[this.props.options[0].id]=i
        }
      }
      return(
      <AvForm model={defaultvals}>
            <AvRadioGroup inline name={this.props.options[0].id} onBlur={this.handleRadioCheckChange} >
              {this.props.options[0].selectoptions.map((item, key=item.id)=>this.getSelectItems(item, "radio"))}
            </AvRadioGroup>
      </AvForm>
      )
    }
    if (this.props.questionprops.selectiontype === "slider"){
      return(
        <div style={{  margin: 20 }}>
        <Slider 
        marks={this.props.options[0].slidermarks}
        min={this.props.options[0].slidermin}
        max={this.props.options[0].slidermax}
        step={this.props.options[0].sliderstep}
        defaultValue={this.state.selectvalue[this.props.options[0].id]}
        onAfterChange={this.handleSelectChange}
        dotStyle={{borderColor: '#c8ced3'}}
        activeDotStyle={{borderColor: '#c8ced3'}}
        />
        </div>
      )
    }
    if (this.props.questionprops.selectiontype === "range"){
      return(
        <div style={{  margin: 20 }}>
          <Range 
          marks={this.props.options[0].slidermarks}
          min={this.props.options[0].slidermin}
          max={this.props.options[0].slidermax}
          step={this.props.options[0].sliderstep} 
          defaultValue={this.state.selectvalue[this.props.options[0].id]}
          tipFormatter={value => `${value}`}
          onAfterChange={this.handleSelectChange}
          />
        </div>
      )
    }


  }


  render () {
    return (

      <div style={{display:"flex", alignItems:"stretch", flexWrap: "wrap"}}>

      <Card style={{width:"80%"}}>
          
          {this.props.options[0].optionheader &&
            <CardHeader>{this.props.options[0].optionheader}</CardHeader>
          }
      
        
        <CardBody style={{backgroundColor: this.props.questionprops.noselectcardcolor}}>


        <div style={{marginTop:"15px"}}>
        <Annotation
                src={this.state.options[0].imageSrc}
                alt='image to be annotated by respondent'
                annotations={this.state.annotations}
                allowTouch={true}
                value={this.state.annotation}
                onChange={this.onChange}
                onSubmit={this.onSubmit}
                />
        </div>
        </CardBody>

        <CardFooter>

          {this.renderSelectType()}

          <p style={{fontSize:"smaller", marginTop:"15px"}}><small>{this.props.questionprops.helpmessage}</small></p>

        </CardFooter>

      </Card>
      {this.props.options[0].showfeatures &&  
      <Card style={{width:"20%"}}>
        <CardHeader>{this.props.options[0].featureheader}</CardHeader>
        <CardBody>
              <FeatureListEditor
              key={this.props.options[0].id}
              surveyprops={this.props.surveyprops}
              features={this.props.options[0].features}
              randomize={this.props.options[0].randomize}
              showprice={this.props.options[0].showprice}
              price={this.props.options[0].price}
              pricemetric={this.props.options[0].pricemetric}
          />
      </CardBody>
      </Card>
      }
      </div>
    )
  }
}
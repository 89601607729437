import React, { Component } from "react";
import {ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText,} from 'reactstrap';

/// FeatureList - component that assembles individual features that are in a array for use in a card




function shuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }


export default class FeatureListEditor extends Component {
    constructor(props) {
      super(props);
      console.log('feature list editor...', this.props.features)
      this.state = {
          isLoading: true,
      }
    }
     
    async componentWillMount() {
      if(this.props.randomize){
          this.setState({features: shuffle(this.props.features)})
      }
    }

    renderListItem(h, d){
        return(
        <ListGroupItem  style={{backgroundColor:this.props.surveyprops.style.featurebackgroundcolor, borderColor:this.props.surveyprops.style.featureoutlinecolor}}>
        <ListGroupItemHeading style={{fontSize:"0.8em", fontWeight:"200"}}>{h}</ListGroupItemHeading>
        <ListGroupItemText style={{fontSize:"0.6em", fontWeight:"300", marginBottom:"15px"}}>{d}</ListGroupItemText>
        </ListGroupItem>
        )
    }

    render(){

        return(
        <ListGroup className = "survey-feature-list">
        {this.props.features.map((item, key=item.id)=>this.renderListItem(item.header, item.detail))}
        {this.props.showprice &&
                <ListGroupItem style={{backgroundColor:this.props.surveyprops.style.pricebackgroundcolor, color:this.props.surveyprops.style.pricetextcolor}}>
                <ListGroupItemHeading style={{fontSize:"1em", fontWeight:"400"}}>{this.props.price}</ListGroupItemHeading>
                <ListGroupItemText style={{fontSize:"0.6em", fontWeight:"300"}}>{this.props.pricemetric}</ListGroupItemText>
                </ListGroupItem>
        }
        </ListGroup>
        )
    }
}
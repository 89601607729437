import React, { Component,  Suspense } from "react";
import { Auth, API } from "aws-amplify";
import {  withRouter } from "react-router-dom";
import * as router from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import CompRoutes from "./Routes";
import "./App.scss";
import {AppAside, AppFooter, AppHeader, AppSidebar, AppSidebarFooter, AppSidebarForm, AppSidebarHeader, AppSidebarMinimizer, AppBreadcrumb2 as AppBreadcrumb, AppSidebarNav2 as AppSidebarNav,} from '@coreui/react';


import navigation from './_nav';
import routes from './routes2';

const DefaultAside = React.lazy(() => import('./containers/DefaultLayout/DefaultAside'));
const DefaultFooter = React.lazy(() => import('./containers/DefaultLayout/DefaultFooter'));
const DefaultHeader = React.lazy(() => import('./containers/DefaultLayout/DefaultHeader'));


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      email: null,
      userId: null,
      userRole: null,
      userLicense: null,
      userProduct: null,
      orgId: null,
      activeSurvey: null,
      activeSurveyData: null,
    };
  }

  async componentDidMount() {
    try {
      let usersession = await Auth.currentSession();
      this.userHasAuthenticated(true);
      if (this.props.userId === null){
        try{
          let accountinfo = await API.get("users", "/users")
          this.userHasEmail(accountinfo.email.S)
          this.userHasId(accountinfo.userId.S)
          this.userHasRole(accountinfo.role.S)
          this.userHasLicense(accountinfo.subscriptionstatus.S)
          this.userHasProduct(accountinfo.product.S)
          this.userHasOrg(accountinfo.orgId.S)
        } catch (e) {
          alert('There was problem loading the account', e);
        }
      }
    }
    catch(e) {
      if (e !== 'No current user') {
        alert(e);
      }
    }
    this.setState({ isAuthenticating: false });
  }

  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  }

  userHasEmail = email => {
    localStorage.setItem('email', email)
    this.setState({ email: email });
  }

  userHasId = userId => {
    localStorage.setItem('userId', userId)
    this.setState({ userId: userId });
  }

  userHasRole = role => {
    localStorage.setItem('userRole', role)
    this.setState({ userRole: role });
  }

  userHasLicense = license => {
    localStorage.setItem('userLicense', license)
    this.setState({ userLicense: license });
  }

  userHasProduct = product => {
    localStorage.setItem('userProduct', product)
    this.setState({ userProduct: product });
  }

  userHasOrg = org => {
    localStorage.setItem('orgId', org)
    this.setState({ orgId: org });
  }

  userHasActiveSurvey = survey => {
    if(survey !== null){
    this.setState({ activeSurvey: survey.surveyId, activeSurveyData:survey })
    } else {this.setState({ activeSurvey: null, activeSurveyData:null })}
  }

  userHasActivePackage = pkg => {
    if(pkg !== null){
    this.setState({ activePackage: pkg.packageId, activePackageData:pkg })
    } else {this.setState({ activePackage: null, activePackageData:null })}
  }




  handleLogout = async event => {
    await Auth.signOut();
    this.userHasAuthenticated(false);
  }

  loading = () => <div className="animated fadeIn pt-1 text-center">Loading...</div>

  signOut(e) {
    e.preventDefault()
    this.props.history.push('/login')
  }

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      userHasEmail: this.userHasEmail,
      userHasId: this.userHasId,
      userHasRole: this.userHasRole,
      userHasLicense: this.userHasLicense,
      userHasProduct: this.userHasProduct,
      userHasOrg: this.userHasOrg,
      userHasActiveSurvey: this.userHasActiveSurvey,
      userHasActivePackage: this.userHasActivePackage,
      toggleSidebar: this.toggleSidebar,
      email: this.state.email,
      userId: this.state.userId,
      userRole: this.state.userRole,
      userLicense: this.state.userLicense,
      userProduct: this.state.userProduct,
      orgId: this.state.orgId,
      activeSurvey: this.state.activeSurvey,
      activeSurveyData: this.state.activeSurveyData,
      activePackage: this.state.activePackage,
      activePackageData: this.state.activePackageData,
    };

    return (
      !this.state.isAuthenticating &&
      <div className="app">
      <AppHeader fixed>
        <Suspense  fallback={this.loading()}>
          <DefaultHeader onLogout={e=>this.signOut(e)}/>
        </Suspense>
      </AppHeader>
      <div className="app-body">
        {this.state.isAuthenticated && 
            <AppSidebar fixed display="lg" minimized={this.state.navMinimized}>
              <AppSidebarHeader/>
              <AppSidebarForm/>
              <Suspense>
              <AppSidebarNav navConfig={navigation} {...this.props} router={router}/>
              </Suspense>
              <AppSidebarFooter/>
              <AppSidebarMinimizer/>
            </AppSidebar>
        }


        <main className="main">

        <AppBreadcrumb appRoutes={routes} router={router}/>
          
          <Container fluid>

            <CompRoutes childProps={childProps}/>

          </Container>

        </main>
      </div>
      <AppFooter>
          <Suspense fallback={this.loading()}>
            <DefaultFooter />
          </Suspense>
        </AppFooter>
      </div>
    );
  }
}

export default withRouter(App);

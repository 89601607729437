import React, { Component } from "react";
import { Button, Card, CardBody, Container, Col,  CardHeader, CardFooter, Row, Jumbotron, Input, CustomInput, ListGroupItem, ListGroupItemHeading, ListGroupItemText } from 'reactstrap';
import { Auth, API } from "aws-amplify";
import Toggle from 'react-toggle'
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable'
import 'react-toggle/style.css'


    /// styling for react-select
    const customStyles2 = (isValid) => ({
      option: (provided, state) => ({
        ...provided,
        fontSize: '1em',
        borderBottom: '1px dotted black',
        backgroundColor: state.isSelected ? '#2f353a' : 'white',
        color: state.isSelected ? '#20a8d8' : '#2f353a',
        padding: 20,
      }),
        control: (styles: any, state:any) => ({
          ...styles,
          color:"#2f353a",
          backgroundColor:"#f0f3f5",
          padding: 5,
          fontSize: "smaller",
          ...(!isValid && { borderColor: 'red' }),
        }),
      });
    

      function getTime(){
        let today = new Date();
        let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        let dateTime = date+' '+time;
        return(dateTime)
      }

/// TODO - manage invoices, and billing... and update user fields
/// has some hardcoded info in there now

export default class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      pageValid: false,
      firstname: null,
      lastname: null,
      orgname: null,
      country: null,
      postalcode: null,
      termsdate: null,
      createdate: null,
      featuretype:null,
      hasfeatures:false,


    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {return};
        try{
          let accountinfo = await API.get("users", "/users")
          this.setState({ 
            accountinfo: accountinfo,
            country: accountinfo.country.S,
            firstname: accountinfo.firstname.S,
            lastname: accountinfo.lastname.S,
            orgname: accountinfo.orgname.S,
            postalcode:accountinfo.postalcode.S,
            createdate:accountinfo.createdate.S,
            termsdate:accountinfo.termsdate.S,
            paymentstatus: "current",
            nextpaymentdate: '2020-12-31',
            price: "99",
            pricemetric: "per month",
            currency: "USD",
            expmo: accountinfo.card_exp_month.S,
            expyr: accountinfo.card_exp_year.S,
            isLoading:false });
        } catch (e) {
          alert('There was problem loading the account', e);
        }
      if(this.props.orgId === null){
        try{
          this.props.userHasEmail(localStorage.getItem('email'))
          this.props.userHasId(localStorage.getItem('userId'))
          this.props.userHasRole(localStorage.getItem('userRole'))
          this.props.userHasLicense(localStorage.getItem('userLicense'))
          this.props.userHasProduct(localStorage.getItem('userProduct'))
          this.props.userHasOrg(localStorage.getItem('orgId'))
        } catch {
          this.props.history.push("/home")
        }
      }

  }



  //// not updating any fields yet

  updateField(){

  }






  render() {
    return (
        <div>

        <Jumbotron style={{marginBottom:"30px", marginTop:"0px", padding:"0px"}}>
              <h1>Account Settings</h1>
              <h5 style={{fontWeight:"200"}}>This is where you can change your profile, payment and other application settings</h5>
              <hr/>
              <p style={{marginTop:"15px"}}>You are signed in as <mark>{this.props.email}</mark> </p>
         </Jumbotron>

      <div style={{display:"flex", flexWrap: "nowrap", justifyContent:"space-evenly", alignItems:"stretch", marginTop:"0px", height:"430px"}}>

        <Card style={{width:"40"}}>
              
            <CardHeader>Account Info</CardHeader>
            
            <CardBody >

            <div style={{display:"flex",}}>
            <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-end",marginLeft:"10px", marginRight:"5px", flexGrow:'1' }}>
            <p style={{color:'#2f353a', fontWeight:"500", marginBottom:"5px"}}><small>First Name</small></p>
              <Input autofocus type="text" name={'firstname'} id={'firstname'}
              value={this.state.firstname}
              style={{marginBottom:"10px", backgroundColor:"#f0f3f5", fontSize:"0.7em"}}
              onChange={this.updateField}
              />
            </div>
            <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-end",marginRight:"10px", flexGrow:'1'}}>
            <p style={{color:'#2f353a', fontWeight:"500", marginBottom:"5px"}}><small>First Name</small></p>
              <Input autofocus type="text" name={'lastname'} id={'lastname'}
              value={this.state.lastname}
              style={{marginBottom:"10px", backgroundColor:"#f0f3f5",fontSize:"0.7em" }}
              onChange={this.updateField}
              />
            </div>
            </div>
            <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-end",marginLeft:"10px", marginRight:"15px",flexGrow:'1' }}>
            <p style={{color:'#2f353a', fontWeight:"500", marginBottom:"5px"}}><small>Organization Name</small></p>
              <Input autofocus type="text" name={'orgname'} id={'orgname'}
              value={this.state.orgname}
              style={{marginBottom:"10px", backgroundColor:"#f0f3f5", fontSize:"0.7em"}}
              onChange={this.updateField}
              />
            </div>
            <div style={{display:"flex"}}>
            <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-end",marginLeft:"10px", marginRight:"5px", flexGrow:'1'}}>
            <p style={{color:'#2f353a', fontWeight:"500", marginBottom:"5px"}}><small>Organization ID</small></p>
              <Input autofocus type="text" name={'orgnid'} id={'orgid'}
              value={this.props.orgId}
              disabled
              style={{marginBottom:"10px", backgroundColor:"#FFFFFF", fontSize:"0.7em"}}
              />
            </div>
            <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-end",marginRight:"5px", flexGrow:'1'}}>
            <p style={{color:'#2f353a', fontWeight:"500", marginBottom:"5px"}}><small>Country</small></p>
              <Input autofocus type="text" name={'country'} id={'country'}
              value={this.state.country}
              style={{marginBottom:"10px", backgroundColor:"#f0f3f5", fontSize:"0.7em"}}
              onChange={this.updateField}
              />
            </div>
            <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-end",marginRight:"10px", flexGrow:'1'}}>
            <p style={{color:'#2f353a', fontWeight:"500", marginBottom:"5px"}}><small>Postal Code</small></p>
              <Input autofocus type="text" name={'postalcode'} id={'postalcode'}
              value={this.state.postalcode}
              style={{marginBottom:"10px", backgroundColor:"#f0f3f5", fontSize:"0.7em" }}
              onChange={this.updateField}
              />
            </div>
            </div>
            <div style={{display:"flex", alignItems:"flex-end", alignContent:"flex-end"}}>
              <p style={{fontSize:"0.8em", color:"#20a8d8", marginLeft:"10px", marginRight:"15px", marginTop:"50px", cursor:"pointer"}} onClick={()=>console.log('change email')}>
                Change Email
              </p>
              <p style={{fontSize:"0.8em", color:"#20a8d8", marginRight:"5px", cursor:"pointer"}} onClick={()=>console.log('change email')}>
                Reset Password
              </p>

            </div>
          
            </CardBody>

        </Card>

          <Card style={{width:"30%"}}>
              
              <CardHeader>Account Settings</CardHeader>
              
              <CardBody>

              <div style={{display:"flex"}}>
            <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-end",marginLeft:"10px", marginRight:"5px", flexGrow:'1'}}>
            <p style={{color:'#2f353a', fontWeight:"500", marginBottom:"5px"}}><small>Create Date</small></p>
            <Input autofocus type="text" name={'createdate'} id={'createdate'}
              value={this.state.createdate}
              disabled
              style={{marginBottom:"10px", backgroundColor:"#FFFFFF", fontSize:"0.7em"}}
              onChange={this.updateField}
              />
            </div>

            <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-end",marginRight:"10px", flexGrow:'1'}}>
            <p style={{color:'#2f353a', fontWeight:"500", marginBottom:"5px"}}><small>Terms of Service</small></p>
            <Input autofocus type="text" name={'termsdate'} id={'termsdate'}
              value={this.state.termsdate}
              disabled
              style={{marginBottom:"10px", backgroundColor:"#FFFFFF", fontSize:"0.7em"}}
              />
            </div>

            </div>
            <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-end",marginLeft:"10px", marginRight:"15px", flexGrow:'1'}}>
            <p style={{color:'#2f353a', fontWeight:"500", marginBottom:"5px"}}><small>Subscription Status</small></p>
              <Input autofocus type="text" name={'userLicense'} id={'userLicense'}
              disabled
              value={this.props.userLicense}
              style={{marginBottom:"10px", backgroundColor:"#FFFFFF", fontSize:"0.7em", color:"#4dbd74"}}
              />
            </div>
            <div style={{display:"flex"}}>
            <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-end",marginLeft:"10px", marginRight:"5px",flexGrow:'1' }}>
            <p style={{color:'#2f353a', fontWeight:"500", marginBottom:"5px"}}><small>User Role</small></p>
              <Input autofocus type="text" name={'userRole'} id={'userRole'}
              value={this.props.userRole}
              disabled
              style={{marginBottom:"10px", backgroundColor:"#FFFFFF",fontSize:"0.7em" }}
              />
            </div>
            <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-end",marginRight:"10px", flexGrow:'1'}}>
            <p style={{color:'#2f353a', fontWeight:"500", marginBottom:"5px"}}><small>Subscription</small></p>
              <Input autofocus type="text" name={'userProduct'} id={'userProduct'}
              disabled
              value={this.props.userProduct}
              style={{marginBottom:"10px", backgroundColor:"#FFFFFF", fontSize:"0.7em"}}
              />
            </div>
            </div>
            <div style={{display:"flex", alignItems:"flex-end", alignContent:"flex-end"}}>
              <p style={{fontSize:"0.8em", color:"#20a8d8", marginLeft:"10px", marginRight:"15px", marginTop:"50px", cursor:"pointer"}} onClick={()=>console.log('change subscription')}>
                Change Subscription
              </p>
              <p style={{fontSize:"0.8em", color:"#20a8d8", marginRight:"5px", cursor:"pointer"}} onClick={()=>console.log('manage users')}>
                Manage Users
              </p>

            </div>
            
              </CardBody>
  
          </Card>  
          <Card style={{width:"30%"}}>
              
              <CardHeader>Payment Settings</CardHeader>
              
              <CardBody>
  
              <div style={{display:"flex"}}>
            <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-end",marginLeft:"10px", marginRight:"5px", flexGrow:'1'}}>
            <p style={{color:'#2f353a', fontWeight:"500", marginBottom:"5px"}}><small>Payment Status</small></p>
            <Input autofocus type="text" name={'paymentstatus'} id={'paymentstatus'}
              value={this.state.paymentstatus}
              disabled
              style={{marginBottom:"10px", backgroundColor:"#FFFFFF", fontSize:"0.7em", color:"#4dbd74"}}
              />
            </div>

            <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-end",marginRight:"10px", flexGrow:'1'}}>
            <p style={{color:'#2f353a', fontWeight:"500", marginBottom:"5px"}}><small>Next Payment</small></p>
            <Input autofocus type="text" name={'nextpaymentdate'} id={'nextpaymentdate'}
              value={this.state.nextpaymentdate}
              disabled
              style={{marginBottom:"10px", backgroundColor:"#FFFFFF", fontSize:"0.7em"}}
              />
            </div>

            </div>
            <div style={{display:"flex"}}>
            <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-end",marginLeft:"10px", marginRight:"5px",flexGrow:'1' }}>
            <p style={{color:'#2f353a', fontWeight:"500", marginBottom:"5px"}}><small>Price</small></p>
              <Input autofocus type="text" name={'price'} id={'price'}
              value={this.state.price}
              disabled
              style={{marginBottom:"10px", backgroundColor:"#FFFFFF",fontSize:"0.7em" }}
              />
            </div>
            <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-end",marginRight:"10px", flexGrow:'1'}}>
            <p style={{color:'#2f353a', fontWeight:"500", marginBottom:"5px"}}><small>Metric</small></p>
              <Input autofocus type="text" name={'pricemetric'} id={'pricemetric'}
              disabled
              value={this.state.pricemetric}
              style={{marginBottom:"10px", backgroundColor:"#FFFFFF", fontSize:"0.7em"}}
              />
            </div>
            <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-end",marginRight:"10px", flexGrow:'1'}}>
            <p style={{color:'#2f353a', fontWeight:"500", marginBottom:"5px"}}><small>Currency</small></p>
              <Input autofocus type="text" name={'currency'} id={'currency'}
              disabled
              value={this.state.currency}
              style={{marginBottom:"10px", backgroundColor:"#FFFFFF", fontSize:"0.7em"}}
              />
            </div>
            </div>
            <div style={{display:"flex"}}>
            <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-end",marginLeft:"10px", marginRight:"5px", flexGrow:'1'}}>
            <p style={{color:'#2f353a', fontWeight:"500", marginBottom:"5px"}}><small>Expire Month</small></p>
            <Input autofocus type="text" name={'expmo'} id={'expmo'}
              value={this.state.expmo}
              disabled
              style={{marginBottom:"10px", backgroundColor:"#FFFFFF", fontSize:"0.7em"}}
              />
            </div>

            <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-end",marginRight:"10px", flexGrow:'1'}}>
            <p style={{color:'#2f353a', fontWeight:"500", marginBottom:"5px"}}><small>Expire Year</small></p>
            <Input autofocus type="text" name={'expyr'} id={'expyr'}
              value={this.state.expyr}
              disabled
              style={{marginBottom:"10px", backgroundColor:"#FFFFFF", fontSize:"0.7em"}}
              />
            </div>

            </div>
            <div style={{display:"flex", alignItems:"flex-end", alignContent:"flex-end"}}>
              <p style={{fontSize:"0.8em", color:"#20a8d8", marginLeft:"10px", marginRight:"15px", marginTop:"50px", cursor:"pointer"}} onClick={()=>console.log('change subscription')}>
                Update Credit Card
              </p>
              <p style={{fontSize:"0.8em", color:"#20a8d8", marginLeft:"10px", marginRight:"15px", marginTop:"50px", cursor:"pointer"}} onClick={()=>console.log('change subscription')}>
                See Past Invoices
              </p>
            </div>
            <div style={{display:"flex", alignItems:"flex-end", alignContent:"flex-end", marginBottom:"10px"}}>
              <p style={{fontSize:"0.7em", color:"#c8ced3", marginLeft:"10px", marginRight:"15px" }}>
                Note:  Pricekit does not store your credit card information.  We use Stripe to securely process all our transactions.
              </p>
            </div>
            
              </CardBody>
  
          </Card>

        
 
      </div>

</div>


    );
  }

}
import React, { Component } from "react";
import { Card, CardBody, CardHeader, CardFooter, Col, Nav, NavItem, NavLink} from 'reactstrap';
import FeatureListEditor from '../survey/FeatureListEditor';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable'
import GifPlayer from 'react-gif-player';
import ReactPlayer from 'react-player';
import { AvForm, AvField, AvCheckbox, AvCheckboxGroup, AvRadio, AvRadioGroup } from 'availity-reactstrap-validation';
import Tooltip from 'rc-tooltip';
import Slider from 'rc-slider';
import Toggle from 'react-toggle'
import  'react-toggle/style.css'


/// ChoiceCard - assembles each card for SurveyCard
/// each card has the same select type function
/// there are various functions to handle input/events since the various
/// components used pass slightly different info
/// typically use onChange, but onBlur needed to handle numbers properly
/// this HAS STYLING which is needed to change the background color of selected and max-diff



/// styling for react-select
const customStyles = (isValid) => ({
    control: (styles: any) => ({
      ...styles,
      padding: 5,
      fontSize: "smaller",
      ...(!isValid && { borderColor: 'red' }),
    }),
  });

/// styling for range and slider
const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);
const Handle = Slider.Handle;
const handle = (props) => {
    const { value, dragging, index, ...restProps } = props;
    return (
      <Tooltip
        prefixCls="rc-slider-tooltip"
        overlay={value}
        visible={dragging}
        placement="top"
        key={index}
      >
        <Handle value={value} {...restProps} />
      </Tooltip>
    );
  };


/// for randomizing elements in a list, like options and features
function shuffle(array) {
  var currentIndex = array.length, temporaryValue, randomIndex;
  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }
  return array;
}


export default class ChoiceCardEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
        isValid: false,
      };
    }
   
  async componentDidMount(){ 
  }

  /// callback to send option id (i) and the value of the event(e) back to parent component
  handleSelectionChange(i, e){
      this.props.onSelectionChange(i, e)
  }

  /// callback to send option id (i) to display full screen image for the lightbox component
  handleImageToggle(){
    this.props.onImageToggle(this.props.option.id)
  }

  /// callback :  Used by DISCRETE and MAXDIFF
  handleMaxDiffChange(e){
    this.props.onMaxDiffChange(this.props.option.id, e)
}

  /// calls Selection Change callback:  Used by SELECT and MULTI AND SLIDER and RANGE
  handleSelectChange = (selectvalue:any, action: any) => {
      this.handleSelectionChange(this.props.option.id, selectvalue)
  };

  /// calls Selection Change
  handleToggleSelect(e){
    this.handleSelectionChange(this.props.option.id, e)
  }

  /// callback:  used by ALLOCATION
  handleAllocationChange = (event) => {
    const target = event.target;
    let value = Number(target.value);
    this.props.onAllocationChange(this.props.option.id, value)
  }

  /// calls Selection Change callback:  Used by TEXT-FIELD and TEXT-AREA
  handleFieldChange = (event) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    this.handleSelectionChange(this.props.option.id, value)
  }

   /// calls Selection Change callback:  Used by TOGGLE
  handleToggleChange(){
    this.props.onSelectionChange(this.props.option.id, !this.props.selectvalue[this.props.option.id])
}

  /// callback:  used by RADIO and CHECKBOX
  handleRadioCheckChange = (event) => {
    const target = event.target;
    if(target != null){
      let type = target.type
      let value = target.value
      this.props.onRadioCheckToggle(this.props.option.id, value, this.props.questionprops.selectiontype)
    }
  }

  /// function to generate the individual radio or checkbox items. i is id;  t is type
  getSelectItems(i, t){

    if(t==="checkbox"){
    return(
      <AvCheckbox label={i['label']} value={i['value']}/>
    )}
    if(t==="radio"){
      return(
        <AvRadio label={i['label']} value={i['value']}/>
      )}


  }


  /// called from render - this determines which selection component to use in the card footer
  renderSelectType(){

    if (this.props.questionprops.selectiontype === "select"){
         return(
            <Select
            defaultValue={this.props.selectvalue[this.props.option.id]}
            onChange={this.handleSelectChange}
            options={this.props.option.selectoptions}
            styles={customStyles(true)}  /// false means there is an error and will error style
          />
        )
        }
    if (this.props.questionprops.selectiontype === "select-creatable"){
        return(
            <CreatableSelect
            defaultValue={this.props.selectvalue[this.props.option.id]}
            onChange={this.handleSelectChange}
            options={this.props.option.selectoptions}
            styles={customStyles(true)}  
            />
        )
        }
    if (this.props.questionprops.selectiontype === "multiselect"){
        return(
            <Select
            defaultValue={this.props.selectvalue[this.props.option.id]}
            isMulti
            onChange={this.handleSelectChange}
            options={this.props.option.selectoptions}
            styles={customStyles(true)}  
            />
        )
        }   
    if (this.props.questionprops.selectiontype === "multiselect-creatable"){
        return(
            <CreatableSelect
            isMulti
            defaultValue={this.props.selectvalue[this.props.option.id]}
            onChange={this.handleSelectChange}
            options={this.props.option.selectoptions}
            styles={customStyles(true)}  
            />
        )
        }
    if (this.props.questionprops.selectiontype === "discrete"){
        return(
            <button className="btn btn-pill btn-lg btn-outline-dark" onClick={() => this.handleMaxDiffChange("selected")}>{this.props.questionprops.buttonname}</button>
        )
        }
    if (this.props.questionprops.selectiontype === "maxdiff"){
        return(
        <div style={{display: "flex"}}>
        <button className="btn btn-pill btn-lg btn-outline-dark" onClick={() => this.handleMaxDiffChange("best")}>{this.props.questionprops.bestbuttonname}</button>
        <button className="btn btn-pill btn-lg btn-outline-dark" style={{marginLeft:"auto"}} onClick={() => this.handleMaxDiffChange("worst")}>{this.props.questionprops.worstbuttonname}</button>
        </div>
        )
        }
    if (this.props.questionprops.selectiontype === "allocation"){
        return(
        <AvForm model={this.props.selectvalue}>
        <AvField name={this.props.option.id}  type="number" max={this.props.validallocationamount} min="0"
        onBlur={this.handleAllocationChange} style={{fontSize:'smaller'}}
        />
        </AvForm>
        )
    }
    if (this.props.questionprops.selectiontype === "toggle"){
      return(
          <Toggle
          id={this.props.option.id}
          value={this.props.option.togglevalue}
          checked= {this.props.selectvalue[this.props.option.id]}
          icons={{unchecked: null}}
          className = {this.props.option.togglestyle}
          onChange = {() => this.handleToggleChange()}
      />
      )
    }
    if (this.props.questionprops.selectiontype === "text-field"){
      return(
      <AvForm model={this.props.selectvalue}>
      <AvField name={this.props.option.id} type={this.props.option.texttype}
      onBlur={this.handleFieldChange} style={{fontSize:'smaller'}}
      />
      </AvForm>
      )
    }
    if (this.props.questionprops.selectiontype === "text-area"){
    return(
    <AvForm model={this.props.selectvalue}>
    <AvField name={this.props.option.id} type="textarea" style={{fontSize:'smaller'}}
    onBlur={this.handleFieldChange}
    />
    </AvForm>
    )
    }
    if (this.props.questionprops.selectiontype === "checkbox"){
      let defaultvals = {}
      let defaultchecks = []
      for (let i in this.props.selectvalue[this.props.option.id]){
        if(this.props.selectvalue[this.props.option.id][i]===true){
          defaultchecks.push(i)
        }
        defaultvals[this.props.option.id]=defaultchecks
      }
      return(
      <AvForm model={defaultvals}>
            <AvCheckboxGroup inline name={this.props.option.id} onChange={this.handleRadioCheckChange} >
              {this.props.option.selectoptions.map((item, key=item.id)=>this.getSelectItems(item, "checkbox"))}
            </AvCheckboxGroup>
      </AvForm>
      )
  }
    if (this.props.questionprops.selectiontype === "radio"){
      let defaultvals = {}
      for (let i in this.props.selectvalue[this.props.option.id]){
        if(this.props.selectvalue[this.props.option.id][i]===true){
          defaultvals[this.props.option.id]=i
        }
      }
      return(
      <AvForm model={defaultvals}>
            <AvRadioGroup inline name={this.props.option.id} onChange={this.handleRadioCheckChange} >
              {this.props.option.selectoptions.map((item, key=item.id)=>this.getSelectItems(item, "radio"))}
            </AvRadioGroup>
      </AvForm>
      )
    }
    if (this.props.questionprops.selectiontype === "slider"){
      return(
        <div style={{  margin: 20 }}>
        <Slider 
        marks={this.props.option.slidermarks}
        min={this.props.option.slidermin}
        max={this.props.option.slidermax}
        step={this.props.option.sliderstep}
        defaultValue={this.props.selectvalue[this.props.option.id]}
        onAfterChange={this.handleSelectChange}
        dotStyle={{borderColor: '#c8ced3'}}
        activeDotStyle={{borderColor: '#c8ced3'}}
        />
        </div>
      )
    }
    if (this.props.questionprops.selectiontype === "range"){
      return(
        <div style={{  margin: 20 }}>
          <Range 
          marks={this.props.option.slidermarks}
          min={this.props.option.slidermin}
          max={this.props.option.slidermax}
          step={this.props.option.sliderstep} 
          defaultValue={this.props.selectvalue[this.props.option.id]}
          tipFormatter={value => `${value}`}
          onAfterChange={this.handleSelectChange}
          />
        </div>
      )
    }


  }

  render(){

    return(  


        <Card className="survey-choice-card" style={{maxWidth:this.props.maxcardwidth, minWidth:this.props.surveyprops.style.mincardwidth}}>
            
            {this.props.option.optionheader &&
          <CardHeader style={{backgroundColor:this.props.surveyprops.style.cardheadcolor, color:this.props.surveyprops.style.cardheadtextcolor}}>{this.props.option.optionheader}</CardHeader>
            }
          
          <CardBody style={{backgroundColor: this.props.cardcolor, marginBottom:"15px" }}>

            {this.props.option.showimage &&  
              <div>
              <div tag="a"  onClick={() => this.handleImageToggle()} style={{cursor: "pointer"}} >
              <img width="100%" src={this.props.option.img} alt="Survey Image" />
              </div>
              { this.props.questionprops.showLightbox &&
                <p style={{fontSize:"50%"}}>Click to enlarge</p>
              }
              </div>
            }

            {this.props.option.showgif &&
                <div style={{cursor: "pointer"}}>
                <GifPlayer
                    gif={this.props.option.gif}
                    width="100%"
                    />
                <p><small>Click to play</small></p>
                </div>
                
            }

            {this.props.option.showvideo &&
                <div>
                <ReactPlayer
                    url={this.props.option.video}
                    controls='true'
                    muted='true'
                    width='100%'
                    />
                </div>
            }

            {this.props.option.showfeatures &&  
                <FeatureListEditor
                key={this.props.option.id}
                surveyprops={this.props.surveyprops}
                features={this.props.option.features}
                randomize={this.props.option.randomize}
                showprice={this.props.option.showprice}
                price={this.props.option.price}
                pricemetric={this.props.option.pricemetric}
            />}
        
          </CardBody>

          <CardFooter className="survey-choice-card-footer" style={{backgroundColor:this.props.surveyprops.style.cardfootcolor, color: this.props.surveyprops.style.cardfoottextcolor}}>

          {this.renderSelectType()}

          <div style={{ marginTop:"15px"}}>
            <p><small>{this.props.questionprops.helpmessage}</small></p>
          </div>

          </CardFooter>

        </Card>           

        
    );
  }
}
import React, { Component } from "react";
import { API } from "aws-amplify";
import Loader from "react-loader-spinner";
import DataTable, {createTheme} from 'react-data-table-component';
import { Button, Card, CardBody, CardGroup, CardHeader, Col, Container, Row } from 'reactstrap';
import ResponseExpanderMenu from './ResponseExpanderMenu'

/// also search, filter and download csv - see examples https://jbetancur.github.io/react-data-table-component/?path=/story/filtering--example-1
/// need to only show those with same ORG ID

/// gets all survey responses


function getTime(){
  let today = new Date();
  let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
  let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  let dateTime = date+' '+time;
  return(dateTime)
}

const conditionalRowStyles = [
  {
    when: row => row.status === "LIVE",
    style: {
      fontWeight: "500",
      },
    },
    {
      when: row => row.selectedrow === true,
      style: {
        fontWeight: "500",
        backgroundColor:"#2f353a",
        color: "#20a8d8",
        },
      },
];

createTheme('pricekit', {
  text: {
    primary: '#2f353a',
    secondary: '#5c6873',
  },
  background: {
    default: 'transparent',
  },
  context: {
    background: '#20a8d8',
    text: '#f0f3f5',
  },
  divider: {
    default: '#f0f3f5',
  },
  action: {
    button: 'rgba(0,0,0,.54)',
    hover: 'rgba(0,0,0,.08)',
    disabled: 'rgba(0,0,0,.12)',
  },
});


const columns = [
  {
    name: 'Response',
    selector: 'responseId',
    sortable: true,
    width:'100px'
  },
  {
    name: 'IP Address',
    selector: 'ipaddress',
    sortable: true,
    width:'120px'
  },
  {
    name: 'Tracking',
    selector: 'tracking',
    sortable: true,
    width:'250px'
  },
  {
    name: 'Last Update',
    selector: 'lastupdate',
    sortable: true,
    width:'150px'
  },
  {
    name: 'Pages Viewed',
    selector: 'numberpages',
    sortable: true,
    width:'80px'
  },
  {
    name: 'Survey Time',
    selector: 'surveytime',
    sortable: true,
    width:'80px'
  },
  {
    name: 'Questions Answered',
    selector: 'numberanswered',
    sortable: true,
    width:'80px'
  },
  {
    name: 'Status',
    selector: 'surveystatus',
    sortable: true,
    width:'200px'
  },
]

export default class DataTableResponse extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isLoading: true,
        data: [],
        allresponses: []
      };
    }

    async componentDidMount() {
        try{
          let querystring = {}
          let orgquery = {}
          orgquery['orgId']=this.props.orgId
          orgquery['querytype']=this.props.activeSurvey
          querystring['queryStringParameters'] = orgquery
          let allresponses = {}
          if (this.props.activeSurvey !== null){
            allresponses = await API.get("responses", "/responses", querystring)
          } else {
            allresponses['summary'] = [{}]
          }
          
          let start = null
          let end = null
          let diff = 0.0
          let tottime = 0.0
          let totcomplete = 0
          let numberresponses = 0
          let today = new Date()
          let todayresponse = 0
          numberresponses = allresponses['summary'].length
          for (let r in allresponses['summary']){
            start = new Date(allresponses['summary'][r]['surveystart'])
            end = new Date(allresponses['summary'][r]['lastupdate'])
            let isToday = today - end
            if(isToday/1000/60/60/24 <1){ todayresponse = todayresponse + 1}
            diff = end - start
            diff = diff / 1000 / 60
            allresponses['summary'][r]['surveytime'] = diff.toFixed(2)
            tottime = tottime + diff
            if(allresponses['summary'][r]['surveystatus']==="RESPONDENT COMPLETE"){
              totcomplete = totcomplete + 1
            }
          }
          let avgtime = 0
          if (numberresponses !== 0){avgtime = tottime/numberresponses}
          let nums ={}       
          nums['numberresponses']=numberresponses
          nums['numbercomplete']=totcomplete
          nums['avgtime']=avgtime.toFixed(2)
          nums['todayresponse']=todayresponse

          this.setState({data:allresponses['summary'],  allresponses:allresponses['all'], isLoading:false}, ()=>this.props.updateCard(nums))
        } catch (e) {
            alert('There was problem getting survey list', e);
        }
        }


        updateState = state => {

          if(state.responseId !== this.state.selectedquestion){

            let datacopy = [...this.state.data]
            
            for(let i in datacopy){
              if(datacopy[i]['responseId']===state.responseId){
                datacopy[i]['selectedrow']=true
              }
              if(datacopy[i]['responseId']===this.state.selectedquestion){
                datacopy[i]['selectedrow']=false
              }
            }

          this.setState({ selectedquestion: state.responseId, data:datacopy }, ()=>this.props.getRow(true, state));
          }
        }



        updateStateToggle = (togglestate, row) => {
        }



    render() {
        return (
          <div>
            
            {this.state.isLoading && <Loader type="Puff" color="#20a8d8" height={100} width={100} timeout={3000}/>}

        <DataTable style={{overflowX:"hidden"}}
            keyField={"responseId"}
            title="Response List"
            theme="pricekit"
            columns={columns}
            data={this.state.data}
            defaultSortField="lastupdate"
            defaultSortAsc={false}
            selectableRows={false}
            selectableRowsNoSelectAll={true}
            selectableRowsHighlight={true}
            expandableRows={true}
            expandableRowsComponent={<ResponseExpanderMenu allresponses={this.state.allresponses}/>}
            onRowExpandToggled={this.updateStateToggle}
            onRowClicked={this.updateState}
            expandOnRowClicked={false}
            pagination={false}
            paginationPerPage={7}
            highlightOnHover={false}
            striped={false}
            pointerOnHover={true}
            dense={false}
            noTableHead={false}
            persistTableHead={true}
            progressPending={false}
            noHeader={true}
            subHeader={false}
            subHeaderAlign={"right"}
            fixedHeader={true}
            fixedHeaderScrollHeight="400px"
            conditionalRowStyles={conditionalRowStyles}
      />
      </div>
      );
    }
}
import React, { Component } from 'react';
import { Card, CardBody, Progress } from 'reactstrap';
import classNames from 'classnames';
import { mapToCssModules } from 'reactstrap/lib/utils';


function truncateString(str, num) {
  if (typeof str === 'undefined'){
    return "..."
  }
  if (str === ""){
    return "..."
  }
  if (str === null){
    return "..."
  }
  if (str.length <= num) {
    return str
  }
  return str.slice(0, num) + '...'
}


export default class ResponseToggleCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
        showcharts: true,
    };
  }

  async componentDidMount() {
    this.setState({showcharts: this.props.showcharts})
  }

  showCharts(){
    this.props.routeAction(!this.state.showcharts)
}


    render() {
      const { className, cssModule, header, icon, color, value, status, title, update, linkto, invert, ...attributes } = this.props;
      const progress = { style: '', color: color, value: value };
      const card = { style: '', bgColor: '', icon: icon };
  
      if (invert) {
        progress.style = 'progress-white';
        progress.color = '';
        card.style = 'text-white';
        card.bgColor = 'bg-' + color;
      }
  
      const classes = mapToCssModules(classNames(className, card.style, card.bgColor), cssModule);
      progress.style = classNames('progress-xs mt-3 mb-0', progress.style);
  
      return (
        <Card className={classes} {...attributes}>
            
          <CardBody>
            <div className="h1 mt-0 mb-0" style={{display:"flex", justifyContent:"center"}}>
            <span onClick={()=>{this.showCharts()}} style={{alignSelf:"center", cursor:"pointer"}}>
                <i className={card.icon} style={{color:"#20a8d8", fontSize:"2em"}} ></i></span>
            </div>
            <small className="text-muted text-uppercase font-weight-bold">{header}</small>
            <Progress className={progress.style} color={progress.color} value={progress.value} />
            <p style={{fontSize:"0.6em", color:"#8f9ba6", marginTop:"3px", marginBottom:"3px"}}>{` `}</p>
          </CardBody>
        </Card>
      );
    }
  }

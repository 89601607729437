import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import Login from "./containers/Login";
import Logout from "./containers/Logout";
import SignUp from "./containers/Signup";

import SelectPlan from "./containers/SelectPlan";
import PaymentSettings from "./containers/PaymentSettings";
import Welcome from "./containers/Welcome";
import SetUp from "./containers/SetUp";

import Survey from "./containers/Survey"
import Questions from "./containers/Questions"
import Packages from "./containers/Packages"
import Features from "./containers/Features"
import Designs from "./containers/Designs"
import Assets from "./containers/Assets"
import Responses from "./containers/Responses"
import Crosstabs from "./containers/Crosstabs"
import Analysis from "./containers/Analysis"
import PackageAnalysis from "./containers/PackageAnalysis"

import SurveyEditor from "./survey/SurveyEditor"

import NotFound from "./views/Pages/Page404";
import AppNotLive from "./components/AppNotLive"

import Profile from "./containers/Profile";
import Settings from "./containers/Settings";
import ResetPassword from "./containers/ResetPassword";
import ChangePassword from "./containers/ChangePassword";
import ChangeEmail from "./containers/ChangeEmail";


import NewOrganization from "./containers/NewOrganization";
import Search from "./containers/Search";

import Dashboard from "./containers/Dashboard";

import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";


export default ({ childProps }) =>
  <Switch>
    <AppliedRoute path="/" exact component={Home} props={childProps} />
    <AppliedRoute path="/login" exact component={Login} props={childProps} />
    <AppliedRoute path="/signup" exact component={SignUp} props={childProps} />
    <AppliedRoute path='/contact' component={() => {window.location.href = 'https://pricekit.io/contact'; return null;}}/>

    <UnauthenticatedRoute path="/login/reset" exact component={ResetPassword} props={childProps}/>

    <AuthenticatedRoute path="/selectplan" exact component={SelectPlan} props={childProps}/>
    <AuthenticatedRoute path="/settings/password" exact component={ChangePassword} props={childProps}/>
    <AuthenticatedRoute path="/settings/email" exact component={ChangeEmail} props={childProps}/>
    <AuthenticatedRoute path="/settings" exact component={Settings} props={childProps}/>  

    <AuthenticatedRoute path="/paymentsettings" exact component={PaymentSettings} props={childProps}/>
    <AuthenticatedRoute path="/logout" exact component={Logout} props={childProps}/>
    <AuthenticatedRoute path="/home" exact component={Home} props={childProps}/>

 
    <AuthenticatedRoute path="/organization" exact component={NewOrganization} props={childProps}/>
    <AuthenticatedRoute path="/dashboard" exact component={Dashboard} props={childProps}/>
    <AuthenticatedRoute path="/surveys" exact component={Survey} props={childProps}/>
    <AuthenticatedRoute path="/surveys/questions" exact component={Questions} props={childProps}/>
    <AuthenticatedRoute path="/surveys/packages" exact component={Packages} props={childProps}/>
    <AuthenticatedRoute path="/surveys/packages/analysis" exact component={PackageAnalysis} props={childProps}/>
    <AuthenticatedRoute path="/surveys/features" exact component={Features} props={childProps}/>
    <AuthenticatedRoute path="/surveys/designs" exact component={Designs} props={childProps}/>
    <AuthenticatedRoute path="/surveys/assets" exact component={Assets} props={childProps}/>
    <AuthenticatedRoute path="/surveys/editor" exact component={SurveyEditor} props={childProps}/>
    <AuthenticatedRoute path="/surveys/responses" exact component={Responses} props={childProps}/>
    <AuthenticatedRoute path="/surveys/responses/crosstabs" exact component={Crosstabs} props={childProps}/>
    <AuthenticatedRoute path="/surveys/responses/analysis" exact component={Analysis} props={childProps}/>


    <AuthenticatedRoute path="/audience" exact component={AppNotLive} props={childProps}/>
    <AuthenticatedRoute path="/search" exact component={AppNotLive} props={childProps}/>
    <AuthenticatedRoute path="/profile" exact component={AppNotLive} props={childProps}/>
    <AuthenticatedRoute path="/welcome" exact component={AppNotLive} props={childProps}/>
    <AuthenticatedRoute path="/setup" exact component={SetUp} props={childProps}/>

    { /* Finally, catch all unmatched routes */ }
    <Route component={NotFound} />
  </Switch>;


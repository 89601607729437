import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Row } from 'reactstrap';
import { Auth } from "aws-amplify";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";


export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: "",
      password: ""
    };
    
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();
  
    this.setState({ isLoading: true });
  
    try {
      await Auth.signIn(this.state.email, this.state.password);
      this.props.userHasAuthenticated(true);
      this.props.history.replace("/", "urlhistory")
      this.props.history.push("/home")
    } catch (e) {
      alert(e.message);
      this.setState({ isLoading: false });
    }
  }
  
  
  render() {
    return (
    <div className="app flex-row align-items-top">
    <Container>
      <Row className="justify-content-center">
      <Col md="8">
      <CardGroup>
      <Card className="p-4">
      <CardBody>
      <Form onSubmit={this.handleSubmit}>
          <h1>Login</h1>
          <p className="text-muted">Sign In to your account</p>
          <FormGroup controlId="email" bsSize="large">
          <ControlLabel>Email</ControlLabel>
          <FormControl
              autoFocus
              type="email"
              value={this.state.email}
              onChange={this.handleChange}
          />
          </FormGroup>
          <FormGroup controlId="password" bsSize="large">
          <ControlLabel>Password</ControlLabel>
          <FormControl
            value={this.state.password}
            onChange={this.handleChange}
            type="password"
            />
            </FormGroup>
                      <Row>
                        <Col xs="6">
                          <Button color="primary" size="lg"className="px-4">Login</Button>
                        </Col>
                        <Col xs="6" className="text-right">
                          <Button color="link" className="px-0">Forgot password?</Button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
                <Card className="text-white bg-dark py-5 d-md-down-none" style={{ width: '44%' }}>
                  <CardBody className="text-center">
                    <div>
                      <h1>Sign up</h1>
                      <p style={{marginTop:"15px"}}>Enroll now to get access to the fast, easy and accurate way to test concepts, create offers and optimize price.</p>
                      <Link to="./contact">
                        <Button color="light" size="lg" className="mt-3" active tabIndex={-1}>Register Now!</Button>
                      </Link>
                    </div>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

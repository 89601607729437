import React, { Component } from "react";
import { API } from "aws-amplify";
import { Button, Card, CardBody, CardGroup, CardHeader, Col, Container, Row, Jumbotron, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SolidInfoCard from '../components/SolidInfoCard'
import SolidActionCard from '../components/SolidActionCard'
import DataTablePackage from '../components/DataTablePackage'
import PackageEditorModal from "../survey/PackageEditorModal"
import GifPlayer from 'react-gif-player';
import ReactPlayer from 'react-player';
import SurveyAddCard from '../components/SurveyAddCard'

/// same as in survey editor
const choicecarddefaults = {
  id: null,
  showimage: false,
  showgif: false,
  showvideo: false,
  gif: null,
  video: null,
  showfeatures:true,
  optionheader: "",
  img: null,
  imageSrc: null,
  imageTitle: null,
  imageCaption: null,
  randomize: false,
  showprice: false,
  price: 0,
  pricemetric: "",
  togglevalue: "",
  slidermin:0,
  slidermax:100,
  sliderstep: 25,
  slidermarks: { 0: "A", 25: "B", 50: "C", 75: "D", 100: "E" },
  sliderdefault: null,
  rangedefault:[0,100],
  allocationamount:100,
  selectoptions:[],
  annotations:null,
  showquestionhelpmessage: false,
  helpmessage: "",
  features:[],}

function truncateString(str, num) {
  if (typeof str === 'undefined'){
    return "..."
  }
  if (str === ""){
    return "..."
  }
  if (str === null){
    return "..."
  }
  if (str.length <= num) {
    return str
  }
  return str.slice(0, num) + '...'
}

function makeId(length) {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}


function getTime(){
  let today = new Date();
  let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
  let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  let dateTime = date+' '+time;
  return(dateTime)
}

export default class Package extends Component {
    constructor(props) {
      super(props);
      this.getRow = this.getRow.bind(this)
      this.updateCard = this.updateCard.bind(this)
      this.handleAction = this.handleAction.bind(this)
      this.routeAction = this.routeAction.bind(this)
      let initialrow = {}
      initialrow['questionid']=null
      let nums = {}
      nums['numberquestions']=0
      this.state = {
        isLoading: false,
        numberquestions:nums,
        activequestion: null,
        row:initialrow,
        content: null,
        showModal: false,
        modalsize:"lg",
        featurelist:[],
        featuretype:[],
        modifiedpackage:null,
        updatepackage:true
      };
    }


    async componentDidMount() {

      if (!this.props.isAuthenticated) {return};

      if(this.props.orgId === null){
        try{
          this.props.userHasEmail(localStorage.getItem('email'))
          this.props.userHasId(localStorage.getItem('userId'))
          this.props.userHasRole(localStorage.getItem('userRole'))
          this.props.userHasLicense(localStorage.getItem('userLicense'))
          this.props.userHasProduct(localStorage.getItem('userProduct'))
          this.props.userHasOrg(localStorage.getItem('orgId'))
        } catch {
          this.props.history.push("/home")
        }
      }

      if(this.props.orgId !== null){
        let refresponse = []
        let flresponses = []
        let querystring = {}
        try{
        querystring = {'queryStringParameters':{'orgId':this.props.orgId}}
        refresponse = await API.get("reference", "/reference", querystring)
        flresponses = await API.get("features", "/features", querystring)
        this.setState({featurelist:flresponses, featuretype:refresponse['featuretype']})
      } catch (e) {
        alert('There was problem getting package list', e);
      }
      }

    this.props.userHasActiveSurvey(null)
    }
  

    updateCard(n){
      this.setState({numberquestions:n, updatepackage:false})
    }


    routeAction(pkg, stat){
  
      if (stat === "New"){
        let newrow = {}
        newrow['packageId'] = makeId(5)
        newrow['optionheader'] = ""
        newrow['detail'] = ""
        newrow['price'] = 0
        newrow['pricemetric'] = ""
        newrow['features'] = []
        let content = choicecarddefaults
        content['id'] = newrow['packageId']
        newrow['content'] = content        
        this.setState({row:newrow, content:newrow['content'], showModal:true})
      }

    }



    getAsset(){

      if(this.state.row.img !== "None"){
          return(

            
            <div style={{margin:"0px"}}>
              <img height="184px" src={this.state.row.img} alt="Survey Image" />
              </div>
         


            )
      }
 
      if(this.state.row.video !== "None"){
        return(
          <Card style={{padding:"0px", height:"100%", width:"100%"}}>
        
          <CardBody style={{padding:"0px"}} >
          
          <div style={{margin:"0px", padding:"0px"}}>

              <ReactPlayer
                url={this.state.row['video']}
                controls={true}
                muted={true}
                height="185px"
                width="250px"
                />
            </div>
            </CardBody>
    
          </Card>
        )
      }
  
      if(this.state.row.gif !== "None"){
        return(
          <Card style={{padding:"0px", height:"100%", width:"100%"}}>
        
          <CardBody style={{padding:"0px"}} >
          
          <div style={{margin:"0px", padding:"0px"}}>

            <GifPlayer
              gif={this.state.row['gif']}
              height="185px"
              />
            </div>
            </CardBody>
    
          </Card>
        )
      }



  }

  getPriceMetric(){
      let pm = this.state.row.pricemetric
      if(pm !=="None"){
          return(
              <p style={{marginLeft:"10px", marginTop:"0px"}} ><small>{pm}</small></p>
          )
      }
      if(pm ==="None"){
          return(
              <p style={{marginLeft:"10px", marginTop:"0px"}} ><small>{"No price metric defined"}</small></p>
          )
      }
  }


  getFeatureNames(f){
    return(
      <div>
        <p style={{fontWeight:"400", fontSize:"0.8em", marginTop:"0px", marginBottom:"0px"}}>{truncateString(f,60)}</p>
      </div>
    )
  }

  getFeatures(){

    if (this.state.row.features !== ""){

      return(
        <Card style={{padding:"0px", marginRight:"15px", marginBottom:"0px", height:"100%", width:"100%", backgroundColor:"#f0f3f5"}}>
        
        <CardBody style={{padding:"0px"}}>
        
        <div style={{marginTop:"15px", marginLeft:"15px", marginRight:"15px", marginBottom:"15px", width:"100%"}}>
        <div><small className="text-muted text-uppercase font-weight-bold" style={{marginBottom:"10px"}}>Features</small></div>

        {this.state.row.features.map((feature)=>this.getFeatureNames(feature))}
                
        </div>


        </CardBody>

      </Card>
        )
    }

  }
       


      /// delete card from modal

      /// TODO - delete
      /// send to dynamo to delete record and then update the datatable
      deletePackage= id => {
        this.setState({showModal: false, activequestion:null, updatepackage:true}, ()=>this.putPackage(id, "Delete"), ()=> this.props.userHasActivePackage(null))
    }

    


    /// TODO - feature list and feature type too....
    savePackage = pkg => {

      this.setState({content:pkg, showModal:false, updatepackage:true}, ()=>this.putPackage(pkg, "save"))

    }

    putPackage(pkg, action){
      const pkgs = {}
      pkgs['localsavetime']=getTime()
      pkgs['email']= this.props.email
      pkgs['orgId']=this.props.orgId
      pkgs['userId']=this.props.userId
      pkgs['package']=pkg
      pkgs['action']= action
      let respon = API.put("packages", "/packages", {body:pkgs})
      this.setState({isSaved:true, showModal:false, updatepackage: false, modifiedpackage:pkg})
    }




    getRow(s, r){
      if(s===true){
        let content = JSON.parse(r.content)
        this.setState({row:r, content:content, activequestion:r['packageId']},()=> this.props.userHasActivePackage(r))
      }
    } 

    toggleModal= modal => {
      this.setState({ showModal: false})
    }


        /// need to handle other actions like publish, stop and delete
        handleAction(action){

              if(action === "analysis"){
              this.props.history.push("/surveys/packages/analysis")
              }

              if(action === "edit"){
                this.setState({showModal:true})
                }

                if(action === "delete"){
                  this.setState({showModal:false}, ()=>this.deletePackage(this.state.content.id))
                  }
        }

        getModal(){
          return(
            <PackageEditorModal
            content={this.state.content}
            showModal={this.state.showModal}
            deletePackage={this.deletePackage}
            savePackage={this.savePackage}
            orgId={this.props.orgId}
            featurelist={this.state.featurelist}
            featuretype={this.state.featuretype}
            toggleModal={this.toggleModal}
            />
            )
          }



    render() {
        return (
          <div>

            {this.state.showModal && 
            <Modal isOpen={this.state.showModal} autoFocus={false} size={this.state.modalsize} centered >
                {this.getModal()}
            </Modal>
            }

            <Jumbotron style={{marginBottom:"30px", marginTop:"0px", padding:"0px"}}>
              <h1>Packages</h1>
              <h5 style={{fontWeight:"200"}}>The bundles that make up alternative choices that you test with customers</h5>
              <hr/>
              <p style={{marginTop:"15px"}}>{` `}</p>
            </Jumbotron>
            <Row>
              <div style={{display:"flex", width:"100%"}}>
                <Col sm="6" md="2">
                  <SolidInfoCard  color="dark" header={this.state.numberquestions['numberquestions']} value="100" invert >Total Packages</SolidInfoCard>
                </Col>


                {this.state.activequestion !== null &&
                <Col sm="6" md="2">
                  <div style={{width:"100%", height:"100%", paddingBottom:"1.5rem"}}>
                  <SolidActionCard icon="icon-layer" color="primary" invert
                  value="100"
                  title={this.state.row['optionheader']}
                  price={this.state.row['price']}
                  pricemetric={this.state.row['pricemetric']}
                  actionstatus={this.state.row.status}
                  surveyId={this.props.activeSurvey}
                  handleAction={this.handleAction}
                  actiontype="Package">
                  </SolidActionCard>
                  </div>
                </Col>
                }
                {this.state.activequestion !== null &&
                <Col sm="6" md="2">
                  <div style={{width:"100%", height:"100%", paddingBottom:"1.5rem"}}>
                {this.getFeatures()}
                </div>
                </Col>
                }

                {this.state.activequestion !== null &&
                <Col sm="6" md="2">
                  <div style={{width:"100%", height:"100%", paddingBottom:"1.5rem"}}>
                {this.getAsset()}
                </div>
                </Col>
                }
                 {this.state.activequestion !== null &&
                <Col sm="6" md="2">
                </Col>
                }
              <Col sm="6" md="2" >
                <SurveyAddCard
                  color="dark"
                  invert
                  value="100"
                  type="package"
                  routeAction={this.routeAction}
                  />
                </Col>

              </div>

              </Row>
              {this.props.orgId &&
              <DataTablePackage
                getRow={this.getRow}
                orgId={this.props.orgId}
                updateCard={this.updateCard}
                modifiedpackage={this.state.modifiedpackage}
                updatepackage={this.state.updatepackage}
                />
              }

          </div>
    
        );
      }
    }
    
import React, { Component } from "react";
import { Button, Card, CardBody, CardGroup, CardHeader, Col, Container, Row } from 'reactstrap';

export default class FeatureExpanderMenu extends Component {
    constructor(props) {
      super(props);
      this.state = {
      };
    }


    render() {
        return (
          <div style={{backgroundColor:"#f0f3f5", marginLeft:"50px"}}>
            <Row>
            <div style={{marginLeft:"15px", marginRight:"15px", marginTop:"10px", display:"flex", justifyContent:"space-around"}}>

            <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-start",marginLeft:"15px"}}>
                <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Updated By</small></p>
                <p style={{color:'#5c6873'}}><small>{this.props.data.email}</small></p>
            </div>
            <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-start",marginLeft:"15px"}}>
                <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Page Type</small></p>
                <p style={{color:'#5c6873'}}><small>{this.props.data.pagetype}</small></p>
            </div>
            <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-start",marginLeft:"15px"}}>
                <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Survey</small></p>
                <p style={{color:'#5c6873'}}><small>{this.props.data.surveyid}</small></p>
            </div>

            <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-start",marginLeft:"15px"}}>
                <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Feature Header</small></p>
                <p style={{color:'#5c6873'}}><small>{this.props.data.header}</small></p>
            </div>

            <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-start",marginLeft:"15px"}}>
                <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Feature Detail</small></p>
                <p style={{color:'#5c6873'}}><small>{this.props.data.detail}</small></p>
            </div>


            </div>
            </Row>

          </div>
      );
    }
}
import React, { Component } from "react";
import { API } from "aws-amplify";
import {  Button, Input, Form, FormGroup, Card, CardHeader, CardBody, CardFooter, Row, Col, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Jumbotron,  ModalHeader, ModalBody, ModalFooter, CustomInput} from 'reactstrap';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel} from 'react-accessible-accordion';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable'
import Slider, { Range } from 'rc-slider';
import Toggle from 'react-toggle';
import GifPlayer from 'react-gif-player';
import ReactPlayer from 'react-player';
import 'react-accessible-accordion/dist/fancy-example.css'
import  'react-toggle/style.css'
import 'rc-slider/assets/index.css';
import './RenderContent.scss';


/// styling for react-select
const customStyles = (isValid) => ({
    control: (styles: any, state:any) => ({
      ...styles,
      padding: 5,
      width:"100%",
      fontSize: "smaller",
      ...(!isValid && { borderColor: 'red' }),
    }),
  });
/// styling for react-select
const customStylesInModal = (isValid) => ({
    option: (provided, state) => ({
        ...provided,
        fontSize: '1em',
        borderBottom: '1px dotted black',
        backgroundColor: state.isSelected ? '#2f353a' : 'white',
        color: state.isSelected ? '#20a8d8' : '#2f353a',
        padding: 20,
      }),
    control: (styles: any, state:any) => ({
      ...styles,
      padding: 5,
      width:"100%",
      borderColor:"#20a8d8",
      ...(!isValid && { borderColor: 'red' }),
    }),
  });

  /// styling for react-select
const customStyles2 = (isValid) => ({
    control: (styles: any, state:any) => ({
      ...styles,
      backgroundColor:"#f0f3f5",
      padding: 5,
      fontSize: "smaller",
      ...(!isValid && { borderColor: 'red' }),
    }),
  });


  function makeId(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }

 function shuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }

  function getTime(){
    let today = new Date();
    let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    let dateTime = date+' '+time;
    return(dateTime)
  }

const  selectiontypes = [
    { value: "discrete", label: 'Discrete Choice - choose one' },
    { value: "maxdiff", label: 'Best/Worst - identify maxdiff and rankings'},
    { value: "allocation", label: 'Allocation - scaled preferences' },
    { value: "toggle", label: 'Toggle - yes/no for each' },
    { value: "checkbox", label: 'Checkbox - check all that apply' },
    { value: "radio", label: 'Radio - select one' },
    { value: "select", label: 'Select - pick one choice' },
    { value: "selectcreatable", label: 'Select Creatable - pick one or add your own' },
    { value: "multiselect", label: 'Multiselect - pick all that apply' },
    { value: "multiselectcreatable", label: 'Multiselect Creatable- pick all or add own' },
    { value: "range", label: 'Range - choose the upper/lower boundary values' },
    { value: "slider", label: 'Slider - choose along a scale' },
    { value: "textfield", label: 'Text field - custom entry' },
    { value: "textarea", label: 'Text area - free form comments' },
]

export default class PackageEditorModal extends Component {
  constructor(props) {
    super(props);
    let option = this.props.content
    console.log('here is what is passed', option)
    let checklist = []
    if(option.features.length>0){
        for(let f in option['features']){
            checklist.push('check'+option['features'][f]['id'])
        }}
    this.state = {
        isValid: false,
        isLoading: true,
        isDeleting:false,
        option: option,
        checklist:checklist,
        featurelist:this.props.featurelist,
        featuretype:this.props.featuretype

      };
    }
   
  async componentDidMount(){
  }

getIfRandom(){
    if(this.state.option.randomize === false){return}
    else {
    return (
        <div style={{fontSize:"0.7em", display:"flex", fontWeight:"300", marginBottom:"0px"}}>
        <p>Features are: </p>
        <p style={{color:'#20a8d8', marginRight:"5px"}}>randomized</p>
        <p style={{color:'#20a8d8', marginRight:"5px"}}></p>
        </div>
        )
    }
}

/// a function to render the map of list details used for content only items (per ContentCard)
renderListItem(item){

let it2 = {'label': item['featuretype'], 'value':item['feturetype']}

return(
    <div style={{display:"flex", marginTop:"10px"}}>

    <span onClick={()=>{this.deleteListItem(item.id)}} 
    style={{marginLeft:"auto", alignSelf:"center", cursor:"pointer"}}>
    <i className="icon-close" style={{color:"#f86c6b", fontSize:"0.7em", marginRight:"5px"}} ></i>
    </span>

    <ListGroupItem 
        style={{marginTop:"0px", marginBottom:"0px", width:"95%", padding:"5px"}}
        key={item.id}>
    <ListGroupItemHeading style={{marginTop:"0px", marginBottom:"0px" }}>
    <Input type="textarea" rows="1" name={'Header'} id={item.id}
        value={item.header}
        placeholder={"Feature Name..."}
        style={{backgroundColor:"#f0f3f5", fontSize:"0.8em", fontWeight:"400"}}
        onChange={this.updateList}
        />
    </ListGroupItemHeading>
    <ListGroupItemText style={{marginTop:"0px", marginBottom:"0px" }}>
    <Input type="textarea" rows="1" name={'Detail'} id={item.id}
        value={item.detail}
        placeholder={"Detail Description..."}
        style={{backgroundColor:"#f0f3f5", fontWeight:"300", fontSize:"0.7em"}}
        onChange={this.updateList}
        />
    </ListGroupItemText>
    <div style={{flexDirection:"column", alignItems:"flex-end", marginLeft:"5px", marginRight:"15px", width:"100%"}}>
    <p style={{fontSize:"0.6em", marginTop:"5px", marginBottom:"5px"}}>Feature Type | Level | Category</p>
        <CreatableSelect
        defaultValue={it2}
        onChange={this.updateFeatureType}
        options={this.state.featuretype}
        name={item.id}
        id={'type'+item.id}
        styles={customStylesInModal(true)}
        />

    </div>
    </ListGroupItem>

</div>
)
}

getCardFeatures(){
          return(
          <ListGroup className = "survey-feature-list">

          {this.state.option.features.map((item, key=item.id)=>this.renderListItem(item))}

          {this.state.option.showprice &&

            <div style={{display:"flex", marginTop:"15px"}}>

            <div style={{width:"95%", marginLeft:"auto"}}>

                <ListGroupItem >
                <ListGroupItemHeading style={{fontSize:"1.2em", fontWeight:"400"}}>
                  <Input autoFocus type="number" name={'price'} id={'price'}
                    value={this.state.option.price}
                    style={{backgroundColor:"#f0f3f5", fontSize:"1.2em", fontWeight:"600"}}
                    onChange={this.updateItemProps}
                    />
                </ListGroupItemHeading>
                  <ListGroupItemText style={{fontSize:"0.8em", fontWeight:"300"}}>
                  <Input autoFocus type="text" name={'pricemetric'} id={'pricemetric'}
                    value={this.state.option.pricemetric}
                    style={{backgroundColor:"#f0f3f5", fontSize:"0.8em", fontWeight:"400"}}
                    onChange={this.updateItemProps}
                    />
                </ListGroupItemText>
                </ListGroupItem>

            </div>
            </div>
            }
            <span onClick={()=>{this.addListItem()}} style={{alignSelf:"center", cursor:"pointer"}}><i className="icon-plus" style={{color:"#20a8d8", fontSize:"1em", marginRight:"5px"}} ></i></span>
          </ListGroup>
          )
}

getChecked(i){
    let ischecked = this.state.checklist.indexOf(i)
    if(ischecked>-1){return true} else {return false}
}

addFeature(e, i){
    let featcopy = [...this.state.option.features]
    let checklist = [...this.state.checklist]
    let itexists = false

    if (featcopy.length > 0){
        for (let x in featcopy){
            if (featcopy[x]['featureId']===i){
                featcopy.splice(x, 1)
                itexists = true
                if (checklist.indexOf('check'+i)>-1){
                    checklist.splice(checklist.indexOf('check'+i), 1)
                }
            }}}

    if (!itexists){
        if (this.state.featurelist.length >0){
            for (let y in this.state.featurelist){
                if (this.state.featurelist[y]['featureId']===i){
                    let copywithid = JSON.parse(JSON.stringify(this.state.featurelist[y]))
                    copywithid['id']=copywithid['featureId']
                    featcopy.push(copywithid)
                checklist.push('check'+i)
                }}}}

    this.setState(prevState => ({
        option: {...prevState.option,           
             features: featcopy},
        checklist:checklist
            }))
}


addListItem = () => {
    let listcopy = [...this.state.option.features]
    let flist = [...this.state.featurelist]
    let checklist = [...this.state.checklist]
    let id = makeId(5)
    const detail = ""
    const header = ""
    const price = ""
    const pricemetric = ""
    const featuretype = ""
    const isActive = true
    let item ={}
    item['id']=id
    item['featureId']=id
    item['header']=header
    item['detail']=detail
    item['price']=price
    item['pricemetric']=pricemetric
    item['featuretype']=featuretype
    item['isActive']=isActive
    item['isNew']=true
    listcopy.push(item)
    flist.push(item)
    checklist.push('check'+id)
    this.setState(prevState => ({
        option: {...prevState.option,           
            features: listcopy},
        featurelist:flist,
        checklist:checklist
        }))
    }

deleteListItem = (id) => {
    let listcopy = [...this.state.option.features]
    let flist = [...this.state.featurelist]
    let checklist = [...this.state.checklist]

    let index = -1
    for(let x in listcopy){
        if(listcopy[x]['id']===id){
            index = x
        }
    }
    let index2 = -1
    for(let x in flist){
        if(flist[x]['featureId']===id){
            index2 = x
        }
    }

    if (index > -1) {
        listcopy.splice(index, 1);
    }
    if (index2 > -1) {
        if(typeof flist[index2]['isNew']!=='undefined'){
            flist.splice(index2, 1);
        }
        
    }
    if (checklist.indexOf(id)>-1){
        checklist.splice(checklist.indexOf(id), 1)
    }


    this.setState(prevState => ({
        option: {...prevState.option,           
            features: listcopy},
        featurelist:flist,
        checklist:checklist
        }))
    }

updateList = (event) => {
    let listcopy = [...this.state.option.features]
    let flist = [...this.state.featurelist]
    const target = event.target;
    let value = target.value;
    const name = target.name;
    const id = target.id

    let index = -1
    for(let x in listcopy){
        if(listcopy[x]['id']===id){
            index = x
        }
    }
    let index2 = -1
    for(let x in flist){
        if(flist[x]['featureId']===id){
            index2 = x
        }
    }

    if (index>-1){
        if(name === "Header"){
            listcopy[index].header = value
        } else {
            listcopy[index].detail = value
        }
    }

    if (index2>-1){
        if(name === "Header"){
            flist[index2].header = value
        } else {
            flist[index2].detail = value
        }
    }
    this.setState(prevState => ({
        option: {...prevState.option,           
            features: listcopy},
            featurelist:flist
        }))
    }

updateFeatureType = (selectvalue, action) => {

    let listcopy = [...this.state.option.features]
    let flist = [...this.state.featurelist]

    let index = -1
    for(let x in listcopy){
        if(listcopy[x]['id']===action.name){
            index = x
        }
    }
    let index2 = -1
    for(let x in flist){
        if(flist[x]['featureId']===action.name){
            index2 = x
        }
    }


    if(index2>-1){
        flist[index2].featuretype = selectvalue['label']
    }
    if (index>-1){
        listcopy[index].featuretype = selectvalue['label']
        this.setState(prevState => ({
            option: {...prevState.option,           
                features: listcopy},
            featurelist:flist,
            }))
        }       

    /// adds a new featuretype into the global reference list
    if (selectvalue['__isNew__']===true){
      
      let ftyperef = this.state.featuretype
      let ftype={}
///         ftype['localsavetime']=getTime()
///         ftype['email']= this.props.email
///         ftype['orgId']=this.props.orgId
      ftyperef.push({'label':selectvalue['label'],'value':selectvalue['value'], 'isNew':true})
///         ftype['featuretype']=ftyperef
///         let respon = API.put("reference", "/reference", {body:ftype})
        this.setState({featuretype: ftyperef})
    }
    }

getFeatureList(item){
    return(
    <CustomInput type="checkbox" 
        id={'check'+item['featureId']} label={item['header']} name={item['featureId']}
        checked={this.getChecked('check'+item['featureId'])}
        style={{fontSize:"0.8em"}} 
        onChange={(e)=>this.addFeature(e, item['featureId'])}
    ></CustomInput>
    )
}

getAccordionItem(item){
    let itemlist = []
    for(let x in this.state.featurelist){
        if (!["None", null].includes(this.state.featurelist[x]['featuretype'])){
            if (this.state.featurelist[x]['featuretype']===item.label){
                itemlist.push(this.state.featurelist[x])
            }
        }
    }

    return(
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton style={{fontSize:"0.8em"}}>
                   {item.label}
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel style={{fontSize:"0.8em"}}>

            {itemlist.map((item, key=item.featureId) => (this.getFeatureList(item)))}

            </AccordionItemPanel>
        </AccordionItem>
    )
}

selectFeatures(){

    if(this.state.featurelist !== null && this.state.option.showfeatures){

        let nonelist = []
        for(let x in this.state.featurelist){
            if ([null, "None"].includes(this.state.featurelist[x]['featuretype'])){
                nonelist.push(this.state.featurelist[x])
            }
        }

        return(

            <div style={{display:"flex", flexDirection:"column", width:"30%", marginLeft:"15px", marginRight:"-16px", 
            marginTop:"-17px", marginBottom:"-50px", padding:"10px", backgroundColor:"#c8ced3"}}>

            <Card className="survey-choice-card" style={{marginTop:"5px", backgroundColor:"transparent"}}>
            
            <CardHeader style={{backgroundColor:"transparent"}}>
            <h5 style={{fontSize:"1.2em", fontWeight:"300", marginBottom:"10px"}}>Feature List</h5>
            </CardHeader>
      
            <Accordion
                allowMultipleExpanded={true}
                allowZeroExpanded={true}
                >

            {this.state.featuretype.map((item, key=item.label) => (this.getAccordionItem(item)))}


            {nonelist.length >0 &&

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton style={{fontSize:"0.8em"}}>
                    {"No Category"}
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel style={{fontSize:"0.8em"}}>

                {nonelist.map((item, key=item.featureId) => (this.getFeatureList(item)))}

                </AccordionItemPanel>
            </AccordionItem>

            }

            </Accordion>
            </Card>

            </div>
            
        )
}
}

deletePackage(){
    this.props.deletePackage(this.state.option.id)
}

savePackage(){
    this.props.savePackage(this.state.option)
}

getChecked(i){
    let ischecked = this.state.checklist.indexOf(i)
    if(ischecked>-1){return true} else {return false}
}

addFeature(e, i){
    let featcopy = [...this.state.option.features]
    let checklist = [...this.state.checklist]
    let itexists = false

    if (featcopy.length > 0){
        for (let x in featcopy){
            if (featcopy[x]['featureId']===i){
                featcopy.splice(x, 1)
                itexists = true
                if (checklist.indexOf('check'+i)>-1){
                    checklist.splice(checklist.indexOf('check'+i), 1)
                }
            }}}

    if (!itexists){
        if (this.state.featurelist.length >0){
            for (let y in this.state.featurelist){
                if (this.state.featurelist[y]['featureId']===i){
                    let copywithid = JSON.parse(JSON.stringify(this.state.featurelist[y]))
                    copywithid['id']=copywithid['featureId']
                    featcopy.push(copywithid)
                checklist.push('check'+i)
                }}}}

    this.setState(prevState => ({
        option: {...prevState.option,           
             features: featcopy},
        checklist:checklist
            }))
}

/// calls Selection Change callback:  Used by TOGGLE
handleToggleChange(t){

this.setState(prevState => ({
    option: {...prevState.option,
        [t]: !this.state.option[t]}}))
}

updateItemProps = (event) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    this.setState(prevState => ({
        option: {...prevState.option,           
           [name]: value}
       }))
    }



  render(){

    return(
    <div>
        <ModalHeader style={{backgroundColor:"#2f353a", color:"#8f9ba6", fontSize:"1.2em", fontWeight:"200"}}>
            {`${this.state.option.id} ${this.state.option.optionheader}`}
            {this.getIfRandom()}
            {this.state.isdeleting &&
            <p style={{color:'#f86c6b', fontSize:"0.8em", fontWeight:"200", marginTop:"0px"}}>Please confirm that you want to delete this card</p>
            }
        </ModalHeader>
        <ModalBody >

        <div style={{display:"flex"}}>

        <Card className="survey-choice-card"  
        style={{
        width:"400px"}}>
            
          <CardHeader 
          style={{padding:"5px"}}>
             <h5 style={{fontSize:"1.2em", fontWeight:"300", marginBottom:"0px"}}>

                    <Input type="text" name={"optionheader"} id={"optionheader"}
                    value={this.state.option.optionheader}
                    placeholder={"Card Title"}
                    style={{marginBottom:"10px", backgroundColor:"transparent", fontSize:"1.2em", fontWeight:"200"}}
                    onChange={this.updateItemProps}
                    />
            </h5>
            </CardHeader>
          
          <CardBody style={{marginBottom:"0px" }}>

            <div>

            {this.state.option.showimage && 
            <div>
            <img width="100%" src={this.state.option.img} alt="Survey Image" />
            <div style={{display:"flex", alignItems:"flex-end"}}>
                <div style={{flexDirection:"column", width:"70%", marginLeft:"15px"}}>
                    <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Image Location</p>
                    <Input type="text" name={'img'} id={'img'}
                    value={this.state.option.img}
                    style={{backgroundColor:"#f0f3f5", fontSize:"0.8em", fontWeight:"400"}}
                    onChange={this.updateItemProps}
                    />
                </div>
            </div>

              {this.state.editimageprops &&
                <div style={{flexDirection:"column"}}>
                    <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Image Title</p>
                    <Input type="text" name={'imageTitle'} id={'imageTitle'}
                    value={this.state.option.imageTitle}
                    style={{backgroundColor:"#f0f3f5", fontSize:"0.8em", fontWeight:"400"}}
                    onChange={this.updateItemProps}
                    />
                    <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Image Caption</p>
                    <Input type="textarea" rows="2" name={'imageCaption'} id={'imageCaption'}
                    value={this.state.option.imageCaption}
                    style={{backgroundColor:"#f0f3f5", fontSize:"0.8em", fontWeight:"400"}}
                    onChange={this.updateItemProps}
                    />
                </div>
                }
                <hr/>
              </div>
            }


            {this.state.option.showgif &&

            <div style={{display:"flex", alignItems:"flex-start"}}>
                <div style={{cursor: "pointer", width:"30%"}}>
                <GifPlayer
                    gif={this.state.option.gif}
                    width="100%"
                    />
                <p><small>Click to play</small></p>
                </div>
                <div style={{flexDirection:"column", width:"70%", marginLeft:"15px"}}>
                    <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Gif Location</p>
                    <Input type="text" name={'gif'} id={'gif'}
                    value={this.state.option.gif}
                    style={{backgroundColor:"#f0f3f5", fontSize:"0.8em", fontWeight:"400"}}
                    onChange={this.updateItemProps}
                    />
                </div>

            </div>
            }

            {this.state.option.showvideo &&

            <div style={{display:"flex", alignItems:"flex-start"}}>
                <div style={{width:"30%", height:"100px"}}>
                <ReactPlayer
                    url={this.state.option.video}
                    controls='true'
                    muted='true'
                    width="100%"
                    height="50%"
                    />
                </div>
                <div style={{flexDirection:"column", width:"70%", marginLeft:"15px"}}>
                    <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Video Location</p>
                    <Input type="text" name={'video'} id={'video'}
                    value={this.state.option.video}
                    style={{backgroundColor:"#f0f3f5", fontSize:"0.8em", fontWeight:"400"}}
                    onChange={this.updateItemProps}
                    />
                </div>

            </div>
            
            }

            </div>

            {this.state.option.showfeatures &&  

                this.getCardFeatures()
            }
        
          </CardBody>

          <CardFooter className="survey-choice-card-footer">

            {this.state.option.showquestionhelpmessage &&
            <p style={{marginTop:"5px"}}>
                <Input type="text" name={"helpmessage"} id={"helpmessage"}
                    value={this.state.option.helpmessage}
                    style={{marginBottom:"10px", backgroundColor:"#f0f3f5", fontSize:"1em"}}
                    onChange={this.updateItemProps}
                    />
            </p>
        }

          </CardFooter>

        </Card>           

        {this.selectFeatures()}

        </div>

        <div style={{ padding:"30px", marginLeft:"-16px", marginRight:"-16px", marginBottom:"-16px", marginTop:"50px", color:"white", fontSize:"0.8em", backgroundColor:"#2f353a" }}>
                

                <Row style={{display:"flex", alignItems:"flex-end"}}>
                    <Col>
                    <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Show Image</p>
                    <Toggle
                        id={"showimage"}
                        checked= {this.state.option.showimage}
                        icons={{unchecked: null}}
                        className = {"custom-success"}
                        onChange = {() => this.handleToggleChange('showimage')}
                            />
                    </Col>
                    <Col>
                    <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Show Video</p>
                    <Toggle
                        id={"showvideo"}
                        checked= {this.state.option.showvideo}
                        icons={{unchecked: null}}
                        className = {"custom-success"}
                        onChange = {() => this.handleToggleChange('showvideo')}
                            />
                    </Col>

                    <Col>
                    <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Show Gif</p>
                    <Toggle
                        id={"showgif"}
                        checked= {this.state.option.showgif}
                        icons={{unchecked: null}}
                        className = {"custom-success"}
                        onChange = {() => this.handleToggleChange('showgif')}
                            />
                    </Col>
                    <Col>
                    <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Show Help Message</p>
                    <Toggle
                        id={"showquestionhelpmessage"}
                        checked= {this.state.option.showquestionhelpmessage}
                        icons={{unchecked: null}}
                        className = {"custom-success"}
                        onChange = {() => this.handleToggleChange('showquestionhelpmessage')}
                            />
                    </Col>

                <Col>
                <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Show Features</p>
                <Toggle
                    id={"showfeatures"}
                    checked= {this.state.option.showfeatures}
                    icons={{unchecked: null}}
                    className = {"custom-success"}
                    onChange = {() => this.handleToggleChange('showfeatures')}
                        />
                </Col>
                <Col>
                <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Randomize Features</p>
                <Toggle
                    id={"randomizefeatures"}
                    checked= {this.state.option.randomize}
                    icons={{unchecked: null}}
                    className = {"custom-success"}
                    onChange = {() => this.handleToggleChange('randomize')}
                        />
                </Col>

                <Col>
                <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Show Prices</p>
                <Toggle
                    id={"showprice"}
                    checked= {this.state.option.showprice}
                    icons={{unchecked: null}}
                    className = {"custom-success"}
                    onChange = {() => this.handleToggleChange('showprice')}
                        />
                </Col>
                </Row>


            </div>

        </ModalBody>

        <ModalFooter style={{marginTop:"0"}}>
      <div style={{alignSelf:"flex-start", marginRight:"auto"}}>
        {!this.state.isdeleting &&
        <Button color="danger" onClick={()=>this.setState({isdeleting:true})}>Delete Package</Button>
        }
        {this.state.isdeleting &&
        <Button color="danger" onClick={()=>this.deletePackage()}>Confirm Delete</Button>
        }
      </div>
    {this.state.isdeleting &&
    <Button color="secondary" onClick={()=>this.setState({isdeleting:false})} >Cancel Delete</Button>
    }
    {!this.state.isdeleting &&
    <div>
    {'  '}
    <Button color="primary" onClick={()=>this.savePackage()} >Save</Button>
    {'  '}
        <Button color="secondary" onClick={()=>this.props.toggleModal()} >Cancel</Button>
    </div>
    }
  </ModalFooter>
  </div>
    );
  }
}
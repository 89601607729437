import React, { Component } from "react";
import { ResponsiveHeatMap } from '@nivo/heatmap'

export default class DashHeatmap extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        isLoading: false,
      };
    }


    render() {
        return (
          <div style={{height:"400px"}}>   
    <ResponsiveHeatMap
        data={this.props.data}

        keys={this.props.chartkeys}
        indexBy={this.props.chartindex}

        margin={{ top: 45, right: 30, bottom: 30, left: 45 }}
        forceSquare={true}
        padding={2}
        colors="blues"
        axisTop={{ orient: 'top', tickSize: 5, tickPadding: 5, tickRotation: -90, legend: '', legendOffset: 36 }}
        axisRight={null}
        axisBottom={null}
        axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: '',
            legendPosition: 'middle',
            legendOffset: -40
        }}
        cellOpacity={1}
        cellBorderColor={{ from: 'color', modifiers: [ [ 'darker', 0.4 ] ] }}
        labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.8 ] ] }}
        defs={[
            {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: 'rgba(0, 0, 0, 0.1)',
                rotation: -45,
                lineWidth: 4,
                spacing: 7
            }
        ]}
        fill={[ { id: 'lines' } ]}
        animate={true}
        motionStiffness={80}
        motionDamping={9}
        hoverTarget="cell"
        cellHoverOthersOpacity={0.25}
    />

          </div>
    
        );
      }
    }
    
import React, { Component } from "react";
import { Button, Card, CardBody, CardGroup, CardHeader, Col, Container, Row } from 'reactstrap';

export default class DesignExpanderMenu extends Component {
    constructor(props) {
      super(props);
      this.getAnnotations = this.getAnnotations.bind(this)
      let jsoncontent = JSON.parse(this.props.data.content)
      this.state = {
          content: jsoncontent

      };
    }


    getAnnotations(annotate){
        return(
            <div style={{marginTop:"0px", marginBottom:"0px"}}>
            <p style={{color:'#5c6873'}}><small>{` ${annotate['data']['id']} - ${annotate['data']['text']}`}</small></p>
            </div>
        )
    }

    getFeatures(feat){
        return(
            <div style={{marginTop:"0px", marginBottom:"0px"}} >
            <p style={{color:'#5c6873'}}><small>{` ${feat['id']} - ${feat['header']} - ${feat['detail']}`}</small></p>
            </div>
        )
    }

    getSelectOptions(option){
        return(
            <div style={{marginTop:"0px", marginBottom:"0px"}}>
            <p style={{color:'#5c6873'}}><small>{` ${option['label']}`}</small></p>
            </div>
        )
    }

    render() {
        return (
 
            <div style={{backgroundColor:"#f0f3f5", marginLeft:"50px"}}>
            <Row>
            <div style={{marginLeft:"15px", marginRight:"15px", marginTop:"10px", display:"flex", justifyContent:"space-around"}}>
        
  
            <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-start",marginLeft:"15px"}}>
            <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Updated By</small></p>
            <p style={{color:'#5c6873'}}><small>{this.props.data.email}</small></p>
            </div>

            <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-start",marginLeft:"15px"}}>
            <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Price</small></p>
            <p style={{color:'#5c6873'}}><small>{this.state.content.price}</small></p>
            </div>


            <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-start",marginLeft:"15px"}}>
            <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Price Metric</small></p>
            {this.state.content.pricemetric !== "" &&
            <p style={{color:'#5c6873'}}><small>{this.state.content.pricemetric}</small></p>
            }
            {this.state.content.pricemetric === "" &&
            <p style={{color:'#5c6873'}}><small>{"None"}</small></p>
            }
            </div>


            <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-start",marginLeft:"15px"}}>
            <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Help Message</small></p>
            {this.state.content.helpmessage !== "" &&
            <p style={{color:'#5c6873'}}><small>{this.state.content.helpmessage}</small></p>
            }
            {this.state.content.helpmessage === "" &&
            <p style={{color:'#5c6873'}}><small>{"None"}</small></p>
            }
            </div>

            {this.state.content.selectoptions.length>0 && 
            <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-start",marginLeft:"15px"}}>
                <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Select Options</small></p>
                {this.state.content.selectoptions.map((option) => this.getSelectOptions(option))}
            </div>
            }

            {this.state.content.annotations.length>0 && 
            <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-start",marginLeft:"15px"}}>
                <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Default Annotations</small></p>
                {this.state.content.annotations.map((annotate) => this.getAnnotations(annotate))}
            </div>
            }

            {this.state.content.features.length>0 && 
            <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-start",marginLeft:"15px"}}>
                <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Design Features</small></p>
                {this.state.content.features.map((feat) => this.getFeatures(feat))}
            </div>
            }


            </div>
            </Row>
            <Row>

            </Row>
            </div>
      );
    }
}
import React, { Component } from "react";
import { API } from "aws-amplify";
import { Button, Card, CardBody, CardGroup, CardHeader, Col, Container, Row, Jumbotron } from 'reactstrap';
import Loader from "react-loader-spinner";
import PackageAnalysis from '../containers/PackageAnalysis'
import ReactTooltip from 'react-tooltip'


export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      data: []
    };
  }


  async componentDidMount() {

    if (!this.props.isAuthenticated) {
      return;
    }
  
    if(this.props.orgId === null){
        try{
        this.props.userHasEmail(localStorage.getItem('email'))
        this.props.userHasId(localStorage.getItem('userId'))
        this.props.userHasRole(localStorage.getItem('userRole'))
        this.props.userHasLicense(localStorage.getItem('userLicense'))
        this.props.userHasProduct(localStorage.getItem('userProduct'))
        this.props.userHasOrg(localStorage.getItem('orgId'))
        } catch {
        this.props.history.push("/home")
        }
    }
  

    try{

        let querystring = {}
        let orgquery = {}
        orgquery['orgId']=this.props.orgId
        orgquery['querytype']='All'
        querystring['queryStringParameters'] = orgquery

        let allresponses = await API.get("analysis", "/analysis", querystring)

        this.setState({
          data: allresponses,
          isLoading:false})

    } catch (e) {
        alert('There was problem getting the dashboard charts', e);
    }

}




  showLoader(){
    return(
    <div className='survey-loader'>
        <Loader type="Puff" color="#20a8d8" height={100} width={100} timeout={10000}/>
    </div>
    )
  }



  getGraphs(survey){

    return(
    
    <PackageAnalysis
        key={survey.surveyId}
        surveyId={survey.surveyId}
        survey={survey.survey}
        avgprice={survey.avgprice}
        numberresponses={survey.numberresponses}
        graphstoshow={survey.graphstoshow}
        data={survey.pkgraph}
        showgraphs={false}
        showcards={true}
    />
    
    )
  }



  render() {
    return (
      <div> 

        <Jumbotron style={{marginBottom:"30px", marginTop:"0px", padding:"0px"}}>
          <h1>Dashboard</h1>
          <h5 style={{fontWeight:"200"}}>Graphs and analysis for your live and completed surveys</h5>     
        </Jumbotron>

        {this.state.isLoading && this.showLoader()}

        {this.state.data !== [] && this.state.data.map((survey, key=survey.surveyId, index) => (this.getGraphs(survey, key)))}



      </div>

    );
  }
}

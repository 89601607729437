import React, { Component } from 'react';
import {Input, Form, FormGroup, CustomInput, Card, CardBody, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Jumbotron} from 'reactstrap';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable'
import Toggle from 'react-toggle'
import  'react-toggle/style.css'
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import classNames from 'classnames';
import { mapToCssModules } from 'reactstrap/lib/utils';



/// styling for react-select
const customStyles = (isValid) => ({
    control: (styles: any, state:any) => ({
      ...styles,
      padding: 5,
      width:"100%",
      fontSize: "smaller",
      ...(!isValid && { borderColor: 'red' }),
    }),
  });


export default class SolidQuestionCard extends Component {
        constructor(props) {
          super(props);
          let sample = false
          if(this.props.row.questiontype==="textfield" || this.props.row.questiontype==="textarea"){sample = ""}
          this.state = {
              sample: sample,
              toggle: false,
              toggle2: false,
              bkgrnd: "#FFFFFF"
          };
        }



    getSelectItems(item, type, id){
        let itemkey = id + item['value']
        if(type==="checkbox"){
            return(
            <CustomInput type="checkbox" id={item['value']} label={item['label']} name={id} key={itemkey}
            ></CustomInput>
    
            )}
            if(type==="radio"){
            return(
            <CustomInput type="radio" id={item['value']} label={item['label']} name={id} key={itemkey}
            ></CustomInput>
            )}
        }

    getSampleToggle(t){
        let bkgrnd = "#c8ced3"
        if(t === "toggle"){
            this.setState({toggle: !this.state.toggle})
        }
        if(t === "discrete"){
            if(this.state.toggle === false){
                let bkgrnd = '#f0f3f5'
            } 
            this.setState({toggle: !this.state.toggle, bkgrnd:bkgrnd})
        }
        if(t === "good"){
            if(this.state.toggle === false){
                let bkgrnd = '#4dbd74'
                this.setState({toggle: !this.state.toggle, bkgrnd:bkgrnd})
            } else {
                let bkgrnd = '#FFFFFF'
                this.setState({toggle: !this.state.toggle, bkgrnd:bkgrnd, toggle2:false})
            }
        }
        if(t === "bad"){
            if(this.state.toggle2 === false){
                let bkgrnd = '#f86c6b'
                this.setState({toggle2: !this.state.toggle2, bkgrnd:bkgrnd})
            } else {
                let bkgrnd = '#FFFFFF'
                this.setState({toggle2: !this.state.toggle2, bkgrnd:bkgrnd, toggle:false})
            }
        }
    }

     /// this determines which selection component to use in the cards
     getQuestion(field){

        if(this.props.row.pagetype === "SurveyChoice"){
            console.log('hit')
            field['type'] = this.props.row.questiontype
        }

        console.log(field['type'])
   
        if (field['type'] === "select"){
             return(
               <div className="editor-content-item" style={{width:"100%", flexGrow:"1", }}>
                <Select
                defaultValue={null}
                onChange={console.log('select')}
                options={field.selectoptions}
                name={field.id}
                styles={customStyles(true)}
              />
            </div>
            )
            }
    
        if (field['type']  === "selectcreatable"){
            return(
              <div className="editor-content-item" style={{width:"100%", flexGrow:"1",}}>
                <CreatableSelect
                onChange={console.log('selectcreate')}
                options={field.selectoptions}
                name={field.id}
                styles={customStyles(true)}  
                />
              </div>
            )
            }
        if (field['type']  === "multiselect"){
            return(
              <div className="editor-content-item" style={{width:"100%", flexGrow:"1",}}>
                <Select
                isMulti
                onChange={console.log('multi')}
                options={field.selectoptions}
                name={field.id}
                styles={customStyles(true)}  
                />
              </div>
            )
            }   
        if (field['type']  === "multiselectcreatable"){
            return(
              <div className="editor-content-item" style={{width:"100%", flexGrow:"1", }} >
                <CreatableSelect
                isMulti
                onChange={this.handleSelectChange}
                options={field.selectoptions}
                name={field.id}
                styles={customStyles(true)}  
                />
              </div>
            )
            }
        if (field['type']  === "toggle"){
          return(
            <div className="editor-content-item" style={{width:"100%", flexGrow:"1", }}>
              <Toggle
              id={field.id}
              value={field.togglevalue}
              checked= {this.state.toggle}
              icons={{unchecked: null}}
              className = {field.togglestyle}
              onChange = {()=>this.getSampleToggle('toggle')}
          />
          </div>
          )
        }

        if (field['type']  === "discrete"){
            return(
              <div className="editor-content-item" style={{width:"100%", flexGrow:"1", }}>

                <Card style={{backgroundColor:this.state.bkgrnd, marginTop:"20px", marginBottom:"20px"}}> 

                <button className="btn btn-pill btn-sm btn-outline-dark" style={{marginLeft:"15px"}}  onClick={() => this.getSampleToggle("discrete")}>SELECT</button>

                </Card>

            </div>
            )
          }

          if (field['type']  === "maxdiff"){
            return(
              <div className="editor-content-item" style={{width:"100%", flexGrow:"1", }}>

                <Card style={{backgroundColor:this.state.bkgrnd}}>
                
                <div style={{display:"flex", marginTop:"20px", marginBottom:"20px"}}>

                <button className="btn btn-pill btn-sm btn-outline-dark" style={{marginLeft:"15px"}} onClick={() => this.getSampleToggle("good")}>BEST</button>

                <button className="btn btn-pill btn-sm btn-outline-dark" style={{marginLeft:"auto", marginRight:"15px"}} onClick={() => this.getSampleToggle("bad")}>WORST</button>

                </div>
                </Card>

            </div>
            )
          }



        if (field['type']  === "textfield"){
          return(
            <div className="editor-content-item" style={{width:"100%", flexGrow:"1", }}>
              <Input 
              name={field.id}
              type={field.texttype}
              placeholder={"Text field..."}
              value={this.state.sample}
              onChange={console.log('text')}/>
          </div>
          )
        }
    
        if (field['type']  === "textarea"){
          return(
            <div className="editor-content-item" style={{width:"100%", flexGrow:"1", }}>
              <Input 
              name={field.id}
              type={"textarea"}
              placeholder={"Text area..."}
              value={this.state.sample}
              onChange={console.log('text')}/>
          </div>
          )
        }
    
    
        if (field['type']  === "checkbox"){

          return(
            <div className="editor-content-item" style={{width:"100%", flexGrow:"1",}}>
            <Form>
              <FormGroup inline name={field.id} onChange={console.log('checkbox')}>
                  {field.selectoptions.map((item, key=item.id)=>this.getSelectItems(item, "checkbox", field['id']))}
              </FormGroup>
            </Form>
    
          </div>
          )
      }
        if (field['type']  === "radio"){

          return(
            <div className="editor-content-item" style={{width:"100%", flexGrow:"1", }}>

            <Form>
              <FormGroup inline name={field.id} onChange={console.log('radio')}>
                  {this.props.content.selectoptions.map((item, key=item.id)=>this.getSelectItems(item, "radio", field['id']))}
              </FormGroup>
            </Form>
          </div>
          )
        }
    
        if (field['type']  === "slider"){
          return(
            <div className="editor-content-item" style={{width:"100%", flexGrow:"1", }}>
   
            <div style={{marginLeft:"15px", marginRight:"15px", marginBottom:"15px"}}>
            <Slider
              min={field['slidermin']}
              max={field['slidermax']}
              step={field['sliderstep']}
              marks={field['slidermarks']}
              dots={true}
              defaultValue={field['sliderdefault']}
              onChange={console.log('slider')}
              dotStyle={{borderColor: '#c8ced3'}}
              activeDotStyle={{borderColor: '#c8ced3'}}
              />
            </div>
            </div>
          )
        }
    
        if (field['type']  === "range"){
          return(
            <div className="editor-content-item" style={{width:"100%", flexGrow:"1", }}>
            <div style={{marginLeft:"15px", marginRight:"15px", marginBottom:"15px"}}>
            <Range
              min={field['slidermin']}
              max={field['slidermax']}
              step={field['sliderstep']}
              marks={field['slidermarks']}
              dots={true}
              defaultValue={field['sliderdefault']}
              onChange={console.log('range')}
              />
            </div>
            </div>
          )
        }
     }

       /// the :: pattern I used to concat col and row into single question header
    getRowColumn(option){

        let head = option.search("::")
        let colhead = option.slice(0,head)
        let rowname = option.slice(head+2)

        return (
        <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-start", marginTop:"5px"}}>
            <p style={{color:'#5c6873', marginBottom:"0px"}}>{`Column: ${colhead}`}</p>
            <p style={{color:'#5c6873'}}>{`Row: ${rowname}`}</p>
        </div>

        )
    }
            
  render() {
    const { className, cssModule, header, icon, color, value, children, linkto, invert, ...attributes } = this.props;
    const progress = { style: '', color: color, value: value };
    const card = { style: '', bgColor: '', icon: icon };

    if (invert) {
      progress.style = 'progress-white';
      progress.color = '';
      card.style = 'text-white';
      card.bgColor = 'bg-' + color;
    }

    const classes = mapToCssModules(classNames(className, card.style, card.bgColor), cssModule);
    progress.style = classNames('progress-xs mt-3 mb-0', progress.style);

    return (
      <Card style={{padding:"0px", width:"400px"}} className={classes} {...attributes}>
          
        <CardBody>
        
        <div style={{marginTop:"15px", marginLeft:"15px", marginRight:"15px", marginBottom:"15px"}}>

      {this.props.row.pagetype !== "SurveyList" &&

            <p style={{marginBottom:"10px"}}>{this.props.row.questionheader}</p>
        }

        {this.props.row.pagetype === "SurveyList" &&
            <div style={{margin:"0px"}}>
            {this.getRowColumn(this.props.row.questionheader)}
            </div>
        }
        
 
        {this.getQuestion(this.props.content)}


        </div>
        </CardBody>

      </Card>
    );
  }
}

import React, { Component } from "react";
import { API } from "aws-amplify";
import {Link} from "react-router-dom"
import { Button, Card, CardBody, CardGroup, CardHeader, CardFooter, Col, Container, Row, Input, Jumbotron } from 'reactstrap';
import Loader from "react-loader-spinner";
import SolidInfoCard from '../components/SolidInfoCard'
import SolidInfoCardText from '../components/SolidInfoCardText'
import DashBar from '../components/DashBar'
import DashHeatmap from '../components/DashHeatmap'
import ResponseToggleCard from '../components/ResponseToggleCard'
import Select from 'react-select';
import Toggle from 'react-toggle'
import PackageAnalysis from '../containers/PackageAnalysis'
import Charts from '../containers/Charts'

export default class Analysis extends Component {
    constructor(props) {
      super(props);
      let nums ={}       
      nums['numberresponses']=0
      nums['numbercomplete']=0
      nums['avgtime']=0
      nums['todayresponse']=0
      this.state = {
            isLoading: true,
            data: [],
            chartdata: null,
            showanalysis: true,
            showcharts: false,
            nums: nums,
          };
      }


    async componentDidMount() {

        if (!this.props.isAuthenticated) {
          return;
        }

        if(this.props.activeSurvey === null){
            this.props.history.push("/surveys")
          }
      
        if(this.props.orgId === null){
            try{
            this.props.userHasEmail(localStorage.getItem('email'))
            this.props.userHasId(localStorage.getItem('userId'))
            this.props.userHasRole(localStorage.getItem('userRole'))
            this.props.userHasLicense(localStorage.getItem('userLicense'))
            this.props.userHasProduct(localStorage.getItem('userProduct'))
            this.props.userHasOrg(localStorage.getItem('orgId'))
            } catch {
            this.props.history.push("/home")
            }
        }
      
        if (this.props.activeSurvey !== null){

            try{
            let querystring = {}
            let orgquery = {}
            orgquery['orgId']=this.props.orgId
            orgquery['querytype']=this.props.activeSurvey
            orgquery['timefilter']="None"
            orgquery['durationfilter']="None"
            orgquery['statusfilter']="None"
            orgquery['targetId']="None"
            querystring['queryStringParameters'] = orgquery
            querystring['queryStringParameters'] = orgquery
    
            let allresponses = await API.get("models", "/models", querystring)

/// taking chrt responses out due to performance reasons
///            let chartresponses = await API.get("charts", "/charts", querystring)

            let start = null
            let end = null
            let today = new Date()
            let diff = 0.0
            let tottime = 0.0
            let totcomplete = 0
            let numberresponses = 0
            let todayresponse = 0
            numberresponses = allresponses[0]['numberresponses']

            for (let r in allresponses[0]['summary']){

              start = new Date(allresponses[0]['summary'][r]['surveystart'])
              end = new Date(allresponses[0]['summary'][r]['lastupdate'])
              let isToday = today - end
              if(isToday/1000/60/60/24 <1){ todayresponse = todayresponse + 1}
              diff = end - start
              diff = diff / 1000 / 60
              allresponses[0]['summary'][r]['surveytime'] = diff.toFixed(2)
              tottime = tottime + diff
              if(allresponses[0]['summary'][r]['surveystatus']==="RESPONDENT COMPLETE"){totcomplete = totcomplete + 1}
            }

            let avgtime = 0
            if (numberresponses !== 0){avgtime = tottime/numberresponses}
            let nums ={}       
            nums['numberresponses']=numberresponses
            nums['numbercomplete']=totcomplete
            nums['avgtime']=avgtime.toFixed(2)
            nums['todayresponse']=todayresponse

            this.setState({
              data: allresponses,
              nums: nums,
              chartdata: null,
              isLoading:false})
    
        } catch (e) {
            alert('There was problem getting the data for this survey', e);
        }
        }
    }

    routeAction(survey, stat){
        this.props.history.push("/surveys/responses/")
      }

      handleToggleChange(i){
        this.setState({[i]: !this.state[i]})
      }

      showLoader(){
        return(
        <div className='survey-loader'>
            <Loader type="Puff" color="#20a8d8" height={100} width={100} timeout={15000}/>
        </div>
        )
      }

      getGraphs(survey){

        return(
        
        <PackageAnalysis
            key={survey.surveyId}
            surveyId={survey.surveyId}
            survey={survey.survey}
            avgprice={survey.avgprice}
            numberresponses={survey.numberresponses}
            graphstoshow={survey.graphstoshow}
            data={survey.pkgraph}
            models={survey.pkmodels}
            showgraphs={true}
            showcards={false}
        />
        
        )
      }

      getCharts(){
        return(
        <Charts
            data={this.state.chartdata}
            showgraphs={true}
            showcards={false}
        />
        )
      }

      getHeadline(){
 
        if(this.props.activeSurvey !== undefined && this.props.activeSurvey !== null){

            let splitdate = this.props.activeSurveyData.surveystart.split(' ')

            return(
            <div style={{display:"flex", marginTop:"25px", marginBottom:"25px", alignItems:"baseline"}}>
              <p>Current data for survey: <mark>{` ${this.props.activeSurvey} ${this.props.activeSurveyData.surveytitle} `}</mark></p>
              <small className="text-muted font-weight-light" style={{marginLeft:"15px"}}>{`Start Date: ${splitdate[0]}  `}</small>
              <a style={{fontSize:"0.8em", marginLeft:"5px"}} target="_blank" href={this.props.activeSurveyData.surveyurl}>{this.props.activeSurveyData.surveyurl}</a>

              {this.state.isLoading && <small className="text-muted" style={{marginLeft:"15px", color:"#f866b"}}>{`  Please wait.  This will take a minute. `}</small>}

            </div>
            )
          }
      }

    getPackageCards(){

        

        if(this.state.data.length>0){

        return(
            <div>
            <Row>
            <Col sm="6" md="2">
            {this.state.data[0]['pkgraph']['bestfreqpkg']!==null &&
            <SolidInfoCardText  color="primary" pname={this.state.data[0]['pkgraph']['bestfreqpkg']} header={this.state.data[0]['pkgraph'].pkgnames[this.state.data[0]['pkgraph']['bestfreqpkg']]} value="100" fontsize="1.5em" invert >Strongest Demand</SolidInfoCardText>
            }
            {this.state.data[0]['pkgraph']['bestfreqpkg']===null &&
            <SolidInfoCardText  color="secondary" header={this.state.data[0]['pkgraph']['bestfreqpkg']} value="100" fontsize="3em" invert >Strongest Demand</SolidInfoCardText>
            }
            </Col>

            <Col sm="6" md="2">
            {this.state.data[0]['pkgraph']['bestpkg']!==null &&
            <SolidInfoCardText color="primary" pname={this.state.data[0]['pkgraph']['bestpkg']} header={this.state.data[0]['pkgraph'].pkgnames[this.state.data[0]['pkgraph']['bestpkg']]} value="100" fontsize="1.5em" invert >Strongest Revenue</SolidInfoCardText>
            }
            {this.state.data[0]['pkgraph']['bestpkg']===null &&
            <SolidInfoCardText color="secondary" header={this.state.data[0]['pkgraph']['bestpkg']} value="100" fontsize="3em" invert >Strongest Revenue</SolidInfoCardText>
            }
            </Col>
            
            
            <Col sm="6" md="2">
            {this.state.data[0]['pkgraph']['mostdiff']!==null &&
            <SolidInfoCardText  color="primary" pname={this.state.data[0]['pkgraph']['mostdiff']} header={this.state.data[0]['pkgraph'].pkgnames[this.state.data[0]['pkgraph']['mostdiff']]} value="100" fontsize="1.5em" invert  >Best Net Diff</SolidInfoCardText>
            }
            {this.state.data[0]['pkgraph']['mostdiff']===null &&
            <SolidInfoCardText  color="secondary" header={this.state.data[0]['pkgraph']['mostdiff']} value="100" fontsize="3em" invert  >Best Net Diff</SolidInfoCardText>
            }
            
            </Col>

            <Col sm="6" md="2">
            {this.state.data[0]['pkgraph']['mostbest']!==null &&
            <SolidInfoCardText color="primary" pname={this.state.data[0]['pkgraph']['mostbest']} header={this.state.data[0]['pkgraph'].pkgnames[this.state.data[0]['pkgraph']['mostbest']]} value="100" fontsize="1.5em" invert >Most Frequent Best</SolidInfoCardText>
            }
            {this.state.data[0]['pkgraph']['mostbest']===null &&
            <SolidInfoCardText color="secondary" header={this.state.data[0]['pkgraph']['mostbest']} value="100" fontsize="3em" invert >Most Frequent Best</SolidInfoCardText>
            }
            </Col>
            
            <Col sm="6" md="2">
            {this.state.data[0]['pkgraph']['mostworst']!=null &&
            <SolidInfoCardText color="info" pname={this.state.data[0]['pkgraph']['mostworst']} header={this.state.data[0]['pkgraph'].pkgnames[this.state.data[0]['pkgraph']['mostworst']]}  value="100" fontsize="1.5em" invert >Most Frequent Worst</SolidInfoCardText>
            }
            {this.state.data[0]['pkgraph']['mostworst']===null &&
            <SolidInfoCardText color="secondary" header={this.state.data[0]['pkgraph']['mostworst']} value="100" fontsize="3em" invert >Most Frequent Worst</SolidInfoCardText>
            }
            </Col>
            </Row>
            <Row>
            
            <Col sm="6" md="2">
            {this.state.data[0]['pkgraph']['priceindex'] !== 0 &&
            <SolidInfoCardText  color="dark" header={this.state.data[0]['pkgraph']['priceindex']} value="100" fontsize="4em"  >Price Sensitivity Index</SolidInfoCardText>
            }
            {this.state.data[0]['pkgraph']['priceindex'] === 0 &&
            <SolidInfoCardText  color="secondary" header={this.state.data[0]['pkgraph']['priceindex']} value="100" fontsize="4em"  invert >Price Sensitivity Index</SolidInfoCardText>
            }
            </Col>

            <Col sm="6" md="2">
            {this.props.avgprice !== 0 &&
            <SolidInfoCardText  color="dark" header={this.state.data[0].avgprice} value="100" fontsize="4em" >Avg Selected Price</SolidInfoCardText>
            }
            {this.props.avgprice === 0 &&
            <SolidInfoCardText  color="secondary" header={this.state.data[0].avgprice} value="100" fontsize="4em" invert >Avg Selected Price</SolidInfoCardText>
            }
            </Col>
            
            <Col sm="6" md="2">
            {this.state.data[0]['pkgraph']['percentcheapest'] !== 0 &&
            <SolidInfoCardText  color="dark" header={this.state.data[0]['pkgraph']['percentcheapest'].toFixed()} value="100" fontsize="4em" >Percent Selected Cheapest</SolidInfoCardText>
            }
            {this.state.data[0]['pkgraph']['percentcheapest'] === 0 &&
            <SolidInfoCardText  color="secondary" header={this.state.data[0]['pkgraph']['percentcheapest'].toFixed()} value="100" fontsize="4em" invert >Percent Selected Cheapest</SolidInfoCardText>
            }
            </Col>
            
            <Col sm="6" md="2">
            {this.state.data[0]['pkgraph']['bestchosenpriceavg'] !== 0 &&
            <SolidInfoCardText  color="dark" header={this.state.data[0]['pkgraph']['bestchosenpriceavg']} value="100" fontsize="4em" >Avg Best Selected Price</SolidInfoCardText>
            }
            {this.state.data[0]['pkgraph']['bestchosenpriceavg'] === 0 &&
            <SolidInfoCardText  color="secondary" header={this.state.data[0]['pkgraph']['bestchosenpriceavg']} value="100" fontsize="4em" invert >Avg Best Selected Price</SolidInfoCardText>
            }
            </Col>
            
            <Col sm="6" md="2">
            {this.state.data[0]['pkgraph']['bestpriceindexavg'] !== 0 &&
            <SolidInfoCardText  color="dark" header={this.state.data[0]['pkgraph']['bestpriceindexavg']} value="100" fontsize="4em"  >Best Price Sensitivity</SolidInfoCardText>
            }
            {this.state.data[0]['pkgraph']['bestpriceindexavg'] === 0 &&
            <SolidInfoCardText  color="secondary" header={this.state.data[0]['pkgraph']['bestpriceindexavg']} value="100" fontsize="4em" invert >Best Price Sensitivity</SolidInfoCardText>
            }
            </Col>
            <Col>
            <div style={{height:"80%", display:"flex", flexDirection:"column", justifyContent:"flex-end", alignItems:"flex-end", marginRight:"30px"}}>
            <div style={{display:"flex", justifyContent:"flex-end", alignItems:"flex-end"}}>
            <small className="text-muted text-uppercase font-weight-bold" style={{marginRight:"5px"}}>Package Analysis</small>    
            <Toggle
            id={"showanalysis"}
            value={"ok"}
            checked= {this.state.showanalysis}
            icons={{unchecked: null}}
            onChange = {() => this.handleToggleChange('showanalysis')}
            />
            </div>
            <div style={{display:"flex", justifyContent:"flex-end", alignItems:"flex-end", marginTop:"30px"}}>
            <Link to={`/surveys/responses/crosstabs`} style={{color:"primary"}} activeStyle={{color:"white"}}>Survey Charts</Link>
            </div>
            <div style={{display:"flex", justifyContent:"flex-end", alignItems:"flex-end", marginTop:"5px"}}>
            <Link to={`/surveys/responses`} style={{color:"primary"}} activeStyle={{color:"white"}}>Survey Responses</Link>
            </div>
            </div>
        </Col>
        </Row>
        </div>
        )
        }
    }


    render() {
        return (
          <div>

        <Jumbotron style={{marginBottom:"30px", marginTop:"0px", padding:"0px"}}>
          <h1>Survey Results</h1>
          <h5 style={{fontWeight:"200"}}>Charts and analysis of the survey responses</h5>     
        </Jumbotron>

        {this.getHeadline()}


            <Row>
            <Col sm="6" md="2">
                  <SolidInfoCard  color="dark" header={this.state.nums.numberresponses} value="100" invert >Total Responses</SolidInfoCard>
                </Col>
                <Col sm="6" md="2">
                  <SolidInfoCard  color="info" header={this.state.nums.numbercomplete} value="100" >Total Completes</SolidInfoCard>
                </Col>
                <Col sm="6" md="2">
                  <SolidInfoCard  color="primary" header={this.state.nums.avgtime} value="100" >Avg Time per Survey</SolidInfoCard>
                </Col>
                <Col sm="6" md="2">
                  <SolidInfoCard  color="secondary" header={this.state.nums.todayresponse} value="100" >Last 24 Hours</SolidInfoCard>
                </Col>

        </Row>

        {this.getPackageCards()}


        {this.state.isLoading && this.showLoader()}

        {this.state.data !== [] && this.state.showanalysis && this.state.data.map((survey, key=survey.surveyId, index) => (this.getGraphs(survey, key)))}

        {this.state.chartdata !== null && this.state.showcharts && this.getCharts()}

        </div>

    
        );
      }
    }
    
import React, { Component } from "react";
import { Button, Card, CardBody, CardGroup, CardHeader, Col, Container, Row } from 'reactstrap';

export default class SurveyExpanderMenu extends Component {
    constructor(props) {
      super(props);
      this.state = {
      };
    }


        editSurvey(s){
            console.log('sending the survey', s)
            this.props.userHasActiveSurvey(s)
            console.log('now on to the edit...')
        }



    render() {
        return (
 
            <div style={{backgroundColor:"#f0f3f5", marginLeft:"50px"}}>
            <Row>
              <div style={{marginLeft:"15px", marginRight:"15px", marginTop:"10px", display:"flex", justifyContent:"space-around"}}>
        
              <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-end", marginLeft:"30px"}}>
              <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Total Pages</small></p>
              <p style={{color:'#5c6873'}}><small>{this.props.data.totalpages}</small></p>
              </div>
        
              <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-end", marginLeft:"15px"}}>
              <p style={{color:'#2f353a', fontWeight:"400",marginBottom:"5px" }}><small>Survey Card Pages</small></p>
              <p style={{color:'#5c6873'}}><small>{this.props.data.numbersurveycard}</small></p>
              </div>
        
              <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-end",marginLeft:"15px" }}>
              <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Choice Card Pages</small></p>
              <p style={{color:'#5c6873'}}><small>{this.props.data.numbersurveychoice}</small></p>
              </div>
        
              <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-end",marginLeft:"15px" }}>
              <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>List Pages</small></p>
              <p style={{color:'#5c6873'}}><small>{this.props.data.numbersurveylist}</small></p>
              </div>
        
              <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-end",marginLeft:"15px"}}>
              <p style={{color:'#2f353a', fontWeight:"400",marginBottom:"5px" }}><small>Design Card Pages</small></p>
              <p style={{color:'#5c6873'}}><small>{this.props.data.numberdesigncard}</small></p>
              </div>
      
        
              <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-end",marginLeft:"15px"}}>
              <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Updated By</small></p>
              <p style={{color:'#5c6873'}}><small>{this.props.data.userEmail}</small></p>
              </div>
        
              </div>
            </Row>
            <Row>
              <div style={{marginLeft:"15px", marginRight:"15px", marginTop:"10px", display:"flex", justifyContent:"space-around"}}>

        
              <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-end",marginLeft:"30px" }}>
              <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Show Logo</small></p>
              <p style={{color:'#5c6873'}}><small>{this.props.data.showlogo}</small></p>
              </div>
        
              <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-end",marginLeft:"15px" }}>
              <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Apply Quota</small></p>
              <p style={{color:'#5c6873'}}><small>{this.props.data.applyquota}</small></p>
              </div>

              {this.props.data.applyquota === "True" &&
              <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-end",marginLeft:"15px" }}>
              <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Quota</small></p>
              <p style={{color:'#5c6873'}}><small>{this.props.data.quota}</small></p>
              </div>
              }
        
              <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-end", marginLeft:"15px"}}>
              <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Single Response</small></p>
              <p style={{color:'#5c6873'}}><small>{this.props.data.singleresponse}</small></p>
              </div>
        
              <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-end",marginLeft:"15px",}}>
              <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Password Required</small></p>
              <p style={{color:'#5c6873'}}><small>{this.props.data.passwordrequired}</small></p>
              </div>

              {this.props.data.passwordrequired === "True" &&
              <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-end",marginLeft:"15px",}}>
              <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Password</small></p>
              <p style={{color:'#5c6873'}}><small>{this.props.data.password}</small></p>
              </div>
              }
        
              <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-end",marginLeft:"15px"}}>
              <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Notifications</small></p>
              <p style={{color:'#5c6873'}}><small>{this.props.data.notifications}</small></p>
              </div>

              <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-end",marginLeft:"15px" }}>
              <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Publish as Test</small></p>
              <p style={{color:'#5c6873'}}><small>{this.props.data.publishtest}</small></p>
              </div>

              <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-end",marginLeft:"15px" }}>
              <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Future Publish</small></p>
              <p style={{color:'#5c6873'}}><small>{this.props.data.schedulepublish}</small></p>
              </div>

              <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-end",marginLeft:"15px" }}>
              <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Schedule End</small></p>
              <p style={{color:'#5c6873'}}><small>{this.props.data.scheduleend}</small></p>
              </div>
 
              {this.props.data.surveystart !== "" &&
              <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-end",marginLeft:"15px" }}>
              <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Survey Start</small></p>
              <p style={{color:'#5c6873'}}><small>{this.props.data.surveystart}</small></p>
              </div>
              }

              {this.props.data.surveyend !== "" &&
              <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-end", marginLeft:"15px"}}>
              <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Survey End</small></p>
              <p style={{color:'#5c6873'}}><small>{this.props.data.surveyend}</small></p>
              </div>
              }

              </div>
            </Row>            
            </div>
      );
    }
}
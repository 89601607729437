import React, { Component } from "react";
import UXAnnotationEditor from '../survey/UXAnnotationEditor'
import SurveyCardEditor from '../survey/SurveyCardEditor'
import SurveyListEditor from '../survey/SurveyListEditor'
import SurveyFormEditor from '../survey/SurveyFormEditor'
import SurveyContentEditor from '../survey/SurveyContentEditor'
import ReactTooltip from 'react-tooltip'

export default class SurveyQuestionEditor extends Component {
  constructor(props) {
    super(props);
    this.passThruStateChange = this.passThruStateChange.bind(this)
    this.handlePageValid = this.handlePageValid.bind(this)
    this.state = {
      };
    }
   
  async componentDidMount() {
  }

  passThruStateChange(p, s){
    this.props.saveStateOnChange(p, s)
}

  handlePageValid(v){
    this.props.onPageValid(v)
  }

  getQuestion(questiontype){

      if(questiontype === 'SurveyCard'){
          return(<SurveyCardEditor 
            key={this.props.pageprops.thispage} 
            surveyprops={this.props.surveyprops}
            options={this.props.options} 
            questionprops={this.props.questionprops} 
            questionId={this.props.questionId}
            savedpages={this.props.savedpages}
            savedresponses={this.props.savedresponses}
            stateChangePassThru={this.passThruStateChange}
            handlePageValid={this.handlePageValid}
            />)}

      if(questiontype === 'UXAnnotation'){
          return(<UXAnnotationEditor 
            key={this.props.pageprops.thispage}
            surveyprops={this.props.surveyprops}
            options={this.props.options} 
            questionprops={this.props.questionprops} 
            questionId={this.props.questionId}
            savedpages={this.props.savedpages}
            savedresponses={this.props.savedresponses}
            stateChangePassThru={this.passThruStateChange}
            handlePageValid={this.handlePageValid}
            />)}

      if(questiontype === 'SurveyList'){
        return(<SurveyListEditor 
          key={this.props.pageprops.thispage}
          surveyprops={this.props.surveyprops}
          list={this.props.list} 
          questionprops={this.props.questionprops} 
          questionId={this.props.questionId}
          savedpages={this.props.savedpages}
          savedresponses={this.props.savedresponses}
          stateChangePassThru={this.passThruStateChange}
          handlePageValid={this.handlePageValid}
          />)}

        if(questiontype === 'SurveyForm'){
          return(<SurveyFormEditor 
            key={this.props.pageprops.thispage}
            surveyprops={this.props.surveyprops}
            forms={this.props.forms} 
            questionprops={this.props.questionprops} 
            questionId={this.props.questionId}
            savedpages={this.props.savedpages}
            savedresponses={this.props.savedresponses}
            stateChangePassThru={this.passThruStateChange}
            handlePageValid={this.handlePageValid}
            />)}
          
        if(questiontype === 'SurveyContent'){
          return(<SurveyContentEditor
            key={this.props.pageprops.thispage}
            surveyprops={this.props.surveyprops}
            options={this.props.options}
            questionprops={this.props.questionprops} 
            questionId={this.props.questionId}
            savedpages={this.props.savedpages}
            savedresponses={this.props.savedresponses}
            stateChangePassThru={this.passThruStateChange}
            handlePageValid={this.handlePageValid}
            toggleSidebar={this.props.toggleSidebar}
            />)}

    }



  render(){
    return(
    
    <div>
      <ReactTooltip />
    <div className = "survey-question-headings" style={{color:this.props.surveyprops.style.questionheadtextcolor}}>
        <div style={{display:"flex"}}>
          <h1>{this.props.pageprops.questiontitle}</h1>
          {this.props.pageprops.showquestiontip &&
          <a className="icon-info" style={{fontSize:"1.25em", marginLeft:"auto", marginRight:"10px", color:"#5c6873"}} data-place="left" data-tip={this.props.pageprops.questiontip}/>
          }
          </div>
        <h4>{this.props.pageprops.questionsubtitle}</h4>

    </div>
        {this.getQuestion(this.props.questiontype)}
     </div>
    );
  }
}
import React, { Component } from 'react';
import {Button, Badge, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane, CardTitle, CustomInput, Form, FormGroup} from 'reactstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ReactTooltip from 'react-tooltip'

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  margin: `0 4px 0 0`,
  fontSize: '0.8em',
  background: isDragging ? '#2f353a' : 'white',
  color: isDragging ? '#20a8d8' : 'black',
  ...draggableStyle,
});

const getActiveStyle = (isActive) => ({
    borderBottom: isActive ? '2px solid #20a8d8' : '0',
    background: isActive ? '#2f353a' : 'white',
    color: isActive ? '#20a8d8' : 'black',
    fontWeight: isActive ? '600' : '400',
    fontSize:"0.8em",
    margin:"0",
    padding:"0",
    width:"75px"
  });

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? '#c8ced3' : 'inherit',
  display: 'flex',
  overflow: 'auto',
});

function truncateString(str, num) {
  if (typeof str === 'undefined'){
    return "..."
  }
  if (str === ""){
    return "..."
  }
  if (str === null){
    return "..."
  }
  if (str.length <= num) {
    return str
  }
  return str.slice(0, num) + '...'
}

const cardtip = `Click to open settings for selected card.`

const colortip = `Click to open survey style options.`

const pagetip = 'Click to open page settings.'


export default class DraggableTabs extends Component {
  constructor(props) {
    super(props);
    let disablecardtoggle = false
    if(this.props.currentcard === null){
      disablecardtoggle = true
    }
    if(typeof this.props.currentcard === 'undefined'){
      disablecardtoggle = true
    }
    this.state = {
      items: this.props.pageorder,
      disablesurveytoggle: false,
      surveytoggle: false,
      disablecardtoggle: disablecardtoggle,
      cardtoggle: false,
      disablepagetoggle: false,
      pagetoggle: false,
      toggleoptions: false,
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }


  componentDidUpdate(prevProps, prevState){
    if(this.props.modalediting !==prevProps.modalediting){
      if(this.props.modalediting === "Complete"){
        this.setState({pagetoggle:false, cardtoggle:false, surveytoggle:false})
      }
    }
    if(this.props.pageorder.length !== this.state.items.length){
      this.setState({items:this.props.pageorder})
    }
    }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    this.setState({
      items, 
    },()=>this.props.updatePageorder(this.state.items));
  }

  /// updates state with pageorder props
  /// used to get new entry in pageorder array
  /// used to get updated description in pageorder
  /// reason for this is we dont want to use props.pageorder
  /// because we are mutating that with the drag-drop
  getNewItems(){
    this.setState({items: this.props.pageorder}, ()=> this.props.toggleUpdatedItem(false))
  }


handleChange = event =>{
  let copypages = [...this.state.items]
  copypages[event.target.id]['description'] = event.target.value
  this.setState({items:copypages})
}

handleToggle(id){
  let stoggle = false
  let ptoggle = false
  let ctoggle = false

  let newstate = !this.state[id]
  if(id==="surveytoggle"){
    if(this.state.surveytoggle === false){
      ptoggle = true
      ctoggle = true
    }
  }
  if(id==="pagetoggle"){
    if(this.state.pagetoggle === false){
      stoggle = true
      ctoggle = true

    }
  }
  if(id==="cardtoggle"){
    if(this.state.cardtoggle === false){
      stoggle = true
      ptoggle = true

    }
  }
  if(this.props.currentcard === null){
    ctoggle = true
  }

   this.setState({
      [id]: newstate,
      disablesurveytoggle: stoggle,
      disablepagetoggle: ptoggle,
      disablecardtoggle: ctoggle,
    }, ()=>{this.activateModal(id)})

}


/// activates modal with type and size
/// designmodal is a type of card modal
activateModal(id){
  let item = {}
  if(this.state.surveytoggle===true){
    item['id']=id
    item['action']="Survey"
    item['modalsize']="md"
    this.props.activeQuestion(item)
  }
  if(this.state.pagetoggle===true){
    item['id']=id
    item['action']="Page"
    item['modalsize']="md"
    this.props.activeQuestion(item)
  }
  if(this.state.cardtoggle===true){
    if(this.props.questiontype !== "DesignCard"){
      item['id']=id
      item['action']="Card"
      item['modalsize']="md"
      }
    else{
      item['id']=id
      item['action']="Design"
      item['modalsize']="lg"
      }
    this.props.activeQuestion(item)
  }
}


getColor(t){
if(this.state[t]===true){
  return('#20a8d8')
} else {return('#2f353a')}
}


getAutoChoice(){
  if(this.props.questiontype==="SurveyChoice"){
    return(
      <small className="text-uppercase font-weight-light" style={{marginRight:"15px", cursor:"pointer", color:"#20a8d8", marginBottom:"0px"}} onClick={()=>this.handleToggle("pagetoggle")}>MANUAL</small>
    )
  }
  if(this.props.questiontype==="AutoChoice"){
    return(
      <small className="text-uppercase font-weight-light" style={{marginRight:"15px", cursor:"pointer",  color:"#20a8d8", marginBottom:"0px"}} onClick={()=>this.handleToggle("pagetoggle")}>RANDOM</small>
    )
  }
  if(this.props.questiontype==="ChoiceWinners"){
    return(
      <small className="text-uppercase font-weight-light" style={{marginRight:"15px", cursor:"pointer",  color:"#20a8d8", marginBottom:"0px"}} onClick={()=>this.handleToggle("pagetoggle")}>WINNERS</small>
    )
  }
}

getPages(item, index){
  if(item.pageId !== "SurveyProps"){
    return(
      <Draggable key={item.pageId} draggableId={item.pageId} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
        >
            <NavItem style={{margin:"0", padding:"0"}} onClick={()=>{this.props.onPageChange(item.pageId)}}>

            <NavLink style ={getActiveStyle(this.props.currentpage === item.pageId)}>

              <div style={{backgroundColor:'transparent', border:"0", fontSize:"1em", padding:"10px", cursor:"pointer"}}>
              
              <span {...provided.dragHandleProps}><i className="icon-frame" style={{color:"#8f9ba6", fontSize:"0.8em", marginRight:"3px"}} ></i></span>
              
                {`${index} ${truncateString(this.props.pages[item.pageId].pageprops.questiontitle,5)}`}
              
              </div>
            </NavLink>

          </NavItem>

        </div>
      )}
    </Draggable>
    )
  }
}

  render() {
    return (

      <div>
      <ReactTooltip 
      multiline={true}
      type={'light'}
      />

      <DragDropContext onDragEnd={this.onDragEnd}>
        <div style={{display:"flex"}}>
        <Nav>

        <NavItem style={{margin:"0", padding:"0", marginRight:"5px"}} onClick={()=>{this.props.onPageChange("SurveyProps")}}>

    <NavLink style ={{color:"#f0f3f5", backgroundColor:"#2f353a", fontSize:"0.8em", width:"75px", margin:"0", borderBottom:'3px solid #2f353a'}}>

      <div style={{backgroundColor:'#2f353a', border:"0",  cursor:"pointer"}}>
      
            <small className="text-uppercase font-weight-bold" style={{color:"#5c6873"}}>
              
              {this.props.activeSurvey}
            
            </small>

      </div>
    </NavLink>

    </NavItem>


        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
              {...provided.droppableProps}
            >
              {JSON.stringify(this.state.items) !== JSON.stringify(this.props.pageorder) && this.getNewItems()}

              {this.props.pageorder.map((item, index) => (

                this.getPages(item, index)

              ))}

              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <span onClick={()=>{this.props.newPage()}} style={{marginLeft:"15px", alignSelf:"center", cursor:"pointer"}}><i className="icon-plus" style={{color:"#20a8d8", fontSize:"1em", marginRight:"5px"}} ></i></span>
        </Nav>

          {this.props.currentpagenum !== 0 &&
        
          <div style={{marginLeft:"auto", justifySelf:"flex-end"}}>
            {this.props.currentcard !== null && this.props.questiontype === "SurveyCard" &&
            <span><i className="cui-dashboard" style={{color:this.getColor("cardtoggle"), fontSize:"1.2em", marginRight:"5px", cursor:"pointer"}} onClick={()=>this.handleToggle("cardtoggle")} data-place="bottom" data-tip={cardtip}></i></span>
            }

            {this.getAutoChoice()}

            <span><i className="cui-brush" style={{color:this.getColor("surveytoggle"), fontSize:"1.2em", marginRight:"5px", cursor:"pointer"}} onClick={()=>this.handleToggle("surveytoggle")} data-place="bottom" data-tip={colortip}></i></span>
 
            <span><i className="icon-settings" style={{color:this.getColor("pagetoggle"), fontSize:"1.2em", cursor:"pointer", marginRight:"5px"}} onClick={()=>this.handleToggle("pagetoggle")} data-place="bottom" data-tip={pagetip} ></i></span>

            <Button color="primary" size="sm" disabled={this.props.isSaved} onClick={()=>this.props.saveSurvey()}>SAVE</Button>
          </div>

          }

        </div>
      </DragDropContext>
      </div>
    );
  }
}
import React, { Component } from 'react';
import { Card, CardBody, Progress } from 'reactstrap';
import classNames from 'classnames';
import { mapToCssModules } from 'reactstrap/lib/utils';


function truncateString(str, num) {
  if (typeof str === 'undefined'){
    return "..."
  }
  if (str === ""){
    return "..."
  }
  if (str === null){
    return "..."
  }
  if (str.length <= num) {
    return str
  }
  return str.slice(0, num) + '...'
}


export default class SurveyAddCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  activeSurvey(){
    this.props.routeAction(null, "New")
}

    render() {
      const { className, cssModule, header, icon, color, value, status, title, update, linkto, invert, ...attributes } = this.props;
      const progress = { style: '', color: color, value: value };
      const card = { style: '', bgColor: '', icon: icon };
  
      if (invert) {
        progress.style = 'progress-white';
        progress.color = '';
        card.style = 'text-white';
        card.bgColor = 'bg-' + color;
      }
  
      const classes = mapToCssModules(classNames(className, card.style, card.bgColor), cssModule);
      progress.style = classNames('progress-xs mt-3 mb-0', progress.style);
  
      return (
        <div style={{height:"100%", display:"flex", alignItems:"stretch", width:"100%"}}>
        <Card className={classes} {...attributes}>
            
          <CardBody>
            <div className="h1 text-muted text-right mb-0 mt-0" >
            <a href={linkto} style={{color:"white", textDecoration:"none"}}>
             <i className={card.icon}></i>
            </a>
            </div>
            <div className="h1 mt-0 mb-0">
            <span onClick={()=>{this.activeSurvey()}} style={{alignSelf:"center", cursor:"pointer"}}>
                <i className="icon-plus" style={{color:"#20a8d8", fontSize:"2em"}} ></i></span>
            </div>

            {this.props.type==="survey" &&
            <small className="text-muted text-uppercase font-weight-bold">{"NEW SURVEY"}</small>
            }

            {this.props.type==="feature" &&
            <small className="text-muted text-uppercase font-weight-bold">{"NEW FEATURE"}</small>
            }

            {this.props.type==="package" &&
            <small className="text-muted text-uppercase font-weight-bold">{"NEW PACKAGE"}</small>
            }
            
            
            <Progress className={progress.style} color={progress.color} value={progress.value} />
            <p style={{fontSize:"0.6em", color:"#8f9ba6", marginTop:"3px", marginBottom:"3px"}}>{` `}</p>
          </CardBody>
        </Card>
        </div>
      );
    }
  }

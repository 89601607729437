import React, { Component } from "react";


export default class AppNotLive extends Component {
  constructor(props) {
    super(props);

    this.state = {
       
      };
    }
   
  async componentDidMount() {
  }

  render(){
    return(
        <div style={{
            height:"100vh",
            width:"100vw",
            marginTop:"-30px",
            marginLeft:"-28px",
            overflow:"hidden",
            backgroundColor:"#2f353a",
            backgroundImage: "url(" + "https://pricekit-survey.s3.amazonaws.com/public/Default/bee-transparent.png" + ")",
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
        
        }}>

<div style={{display:"flex", alignItems:"center", justifyContent:"center", height:"80vh"}}>
    <div style={{maxWidth:"50%", fontSize:"3em", color:"#f0f3f5", alignItems:"center"}}>
        <h1>this feature isn't live yet...</h1>
        <div style={{marginTop:"30px"}}>
            <button className="btn btn-lg btn-primary" onClick={()=>this.props.history.push("/home")}>OK</button>
        </div>
    </div>
</div>



        </div>
    );
  }
}
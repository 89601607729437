import React, { Component } from "react";
import { ResponsiveScatterPlot } from '@nivo/scatterplot'

export default class DashScatter extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        isLoading: false,
      };
    }


    render() {
        return (
          <div style={{height:"400px"}}>   
                <ResponsiveScatterPlot
                    data={this.props.data}
                    margin={{ top: 60, right: 140, bottom: 70, left: 90 }}
                    xScale={{ type: 'linear', min: 0, max: 'auto' }}
                    yScale={{ type: 'linear', min: 0, max: 'auto' }}
                    colors={{ scheme: 'paired' }}
                    blendMode="multiply"
                    nodeSize={10}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        orient: 'bottom',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: this.props.legend,
                        legendPosition: 'middle',
                        legendOffset: 46
                    }}
                    axisLeft={{
                        orient: 'left',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: this.props.axis,
                        legendPosition: 'middle',
                        legendOffset: -60
                    }}
                    legends={[
                        {
                            anchor: 'bottom-right',
                            direction: 'column',
                            justify: false,
                            translateX: 130,
                            translateY: 0,
                            itemWidth: 100,
                            itemHeight: 12,
                            itemsSpacing: 5,
                            itemDirection: 'left-to-right',
                            symbolSize: 12,
                            symbolShape: 'circle',
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemOpacity: 1
                                    }
                                }
                            ]
                        }
                    ]}
                />

          </div>
    
        );
      }
    }
    
import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ListGroup, ListGroupItem, ListGroupItemText, ListGroupItemHeading, Input, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap';
import ReactTooltip from 'react-tooltip'
import Toggle from 'react-toggle'
import  'react-toggle/style.css'

const menulist = [{id:"MENU1", name:"select"},{id:"MENU2", name:"toggle"},{id:"MENU3", name:"form"},{id:"MENU4", name:"check"},{id:"MENU5", name:"slider"},{id:"MENU6", name:"img"},{id:"MENU7", name:"video"},{id:"MENU8", name:"list"},{id:"MENU9", name:"jumbo"},{id:"MENU10", name:"text"}]

const defaultitemprops = {
id: null,
header: "",
detail: "",
price: null,
pricemetric: null,
}

/// implements the drag and drop functionality of the canvas

const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding:'0px',
    margin:'0px',
    background: isDragging ? 'rgba(32, 168, 216, 0.1)' : 'transparent',
    ...draggableStyle
});

const getCardStyle = isDraggingOver => ({
    background: isDraggingOver ? 'rgba(240, 243, 245, 0.9)' : 'transparent',
    padding:'0px',
});

function shuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? '#c8ced3' : 'inherit',
    display: 'flex',
    overflow: 'auto',
  });

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, removed);
    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

function makeId(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }

export default class ListEditorModal extends Component {
    constructor(props) {
        super(props);
        let initialitems = this.props.page.questions[0].list.listrow
        let initialheader = this.props.page.questions[0].list.listhead[0].header
        let initialprops = this.props.page.questions[0].list.listprops
        let pricetoggle = initialprops.showprice
        let randomtoggle = initialprops.randomize

        this.state = {

            items: initialitems,
            listprops: initialprops,
            rowhead: initialheader,
            randomtoggle: randomtoggle,
            pricetoggle: pricetoggle,
            isdeleting: false,

          };
        };

  /// save state on exit, so if you go back and review this page the content is available to see
  componentWillUnmount(){

}

async componentDidMount() {
    if (this.state.items.length === 0 ){
        this.addListItem()
    }
    
}



    passThruStateChange(p, s){
        this.props.saveStateOnChange(p, s)
    }

    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
          return;
        }

        console.log('on drag end', result)
        console.log('state before reorder')
    
        const items = reorder(
          this.state.items,
          result.source.index,
          result.destination.index
        );
    
        this.setState({
          items, 
        });
      }


    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };
    

    activeItem(action, id){
        console.log('action', action)
        console.log('id', id)
    }

    updateHeader = (event) => {
        const target = event.target;
        let value = target.value;
        const name = target.name;
        this.setState(prevState => ({
            rowhead: value
            }))
        }


    getIfRandom(){
        if(this.props.page.questions[0].list.listprops.randomize === false){return}
        else {
        return (
            <div style={{fontSize:"0.7em", display:"flex", fontWeight:"300", marginBottom:"0px"}}>
            <p>List item order is: </p>
            <p style={{color:'#20a8d8', marginRight:"5px"}}>randomized</p>
            <p style={{color:'#20a8d8', marginRight:"5px"}}></p>
            </div>
            )
        }
    }

    /// Changes the state params - AND logic to change the question type
    handleToggleChange(i){
        this.setState({[i]:!this.state[i]}, ()=>this.updateListProps(i))
        }

    updateListProps(i){
        if(i==='randomtoggle'){this.setState(prevState => ({listprops: {...prevState.listprops, randomize: this.state.randomtoggle}}))}
        if(i==='pricetoggle'){this.setState(prevState => ({listprops: {...prevState.listprops, showprice: this.state.pricetoggle}}))}
    }

    /// a function to render the map of list details used for content only items (per ContentCard)
    renderListItem(h, d, i, p, m){
        return(
            <div style={{display:"flex"}}>

                <span onClick={()=>{this.deleteListItem(i)}} 
                style={{marginLeft:"auto", alignSelf:"center", cursor:"pointer"}}>
                <i className="icon-close" style={{color:"#f86c6b", fontSize:"0.7em", marginRight:"5px"}} ></i>
                </span>
                
                <ListGroupItem style={{width:"95%", marginTop:"0px", marginBottom:"5px", paddingRight:"5px", paddingLeft:"5px", paddingRight:"5px", paddingTop:"5px"}}>
                    <ListGroupItemHeading>
                    <Input type="text" name={'Header'} id={i}
                        value={h}
                        style={{marginBottom:"10px", backgroundColor:"#f0f3f5", fontWeight:"600", fontSize:"0.8em"}}
                        onChange={this.updateListItem}
                        />
                    </ListGroupItemHeading>
                    <ListGroupItemText style={{marginBottom:"5px"}}>
                    <Input type="textarea" rows="2" name={'Detail'} id={i}
                        value={d}
                        style={{marginBottom:"10px", backgroundColor:"#f0f3f5", fontWeight:"300", fontSize:"0.7em"}}
                        onChange={this.updateListItem}
                        />
                    {this.state.pricetoggle &&
                    <div style={{display:"flex"}}>
                        <div style={{display:"flex", flexDirection:"column", marginTop:"0px", marginRight:"5px" , fontSize:"0.8em", width:"15%"}}>
                        <Input type="number" name={'Price'} id={i}
                            value={p}
                            placeholder="Price"
                            style={{marginBottom:"10px", backgroundColor:"#f0f3f5", fontWeight:"600", fontSize:"0.8em"}}
                            onChange={this.updateListItem}
                            />
                        </div>
                        <div style={{display:"flex", flexDirection:"column", marginTop:"0px", fontSize:"0.8em", width:"85%"}}>
                        <Input type="text" name={'Pricemetric'} id={i}
                            value={m}
                            placeholder="Price Metric"
                            style={{marginBottom:"10px", backgroundColor:"#f0f3f5", fontWeight:"600", fontSize:"0.8em"}}
                            onChange={this.updateListItem}
                            />
                        </div>
                    </div>
                    }
                    </ListGroupItemText>
                </ListGroupItem>
        </div>
        )
      }

    updateListItem = (event) => {
        let listcopy = [...this.state.items]
        const target = event.target;
        let value = target.value;
        const name = target.name;
        const id = target.id
        let index = -1
        for (const x in listcopy){
            if(listcopy[x].id===id){
                index = x
            }
        }
        if (index>-1)
            if(name === "Header"){listcopy[index].header = value}
            if(name === "Detail"){listcopy[index].detail = value}
            if(name === "Price"){listcopy[index].price = value}
            if(name === "Pricemetric"){listcopy[index].pricemetric = value}

            this.setState(prevState => ({
                items:  listcopy}))
        }
    

    deleteListItem = (i) => {
        let listcopy = [...this.state.items]
        let index = -1
        for (const x in listcopy){
            if(listcopy[x].id===i){
                index = x
            }
        }
        if (index > -1) {
            listcopy.splice(index, 1);
        }
        this.setState(prevState => ({
            items: listcopy
            }))
        }

    addListItem = () => {
        let listcopy = [...this.state.items]
        let item ={}
        item['id']=makeId(5)
        item['header']=defaultitemprops.header
        item['detail']=defaultitemprops.detail
        item['price']=defaultitemprops.price
        item['pricemetric']=defaultitemprops.pricemetric
        listcopy.push(item)
        console.log('here is listcopy', listcopy)

        this.setState(prevState => ({
            items: listcopy
            }))
        }

    deleteList(){
        let listupdate = {}
        listupdate['type']=this.props.modalediting
        listupdate['questionId']=this.props.page.questions[0].questionId
        listupdate['listId']=this.props.page.questions[0].list.listid
        listupdate['questionId']=this.props.currentquestion
        this.props.deleteItem(listupdate)
    }

    updateList(){
        let copyhead = [...this.props.page.questions[0].list.listhead]
        copyhead[0].header=this.state.rowhead
        console.log('the new head', copyhead)
        let listupdate = {}
        listupdate['type']=this.props.modalediting
        listupdate['questionId']=this.props.page.questions[0].questionId
        listupdate['listId']=this.props.page.questions[0].list.listid
        listupdate['questionId']=this.props.currentquestion
        listupdate['listrow'] = this.state.items
        listupdate['listhead']=copyhead
        listupdate['listprops']=this.state.listprops
        this.props.saveItem(listupdate)
    }

    render() {
        return (
            <div>
            <ModalHeader style={{backgroundColor:"#2f353a", color:"#8f9ba6", fontSize:"1.2em", fontWeight:"200"}}>
            {`SurveyList ${this.props.page.questions[0].list.listid}`}
            {this.getIfRandom()}
            {this.state.isdeleting &&
            <p style={{color:'#f86c6b', fontSize:"0.8em", fontWeight:"200", marginTop:"0px"}}>Please confirm that you want to delete this item</p>
            }
            
            </ModalHeader>
            <ModalBody>

            <div>
            <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Table header</p>
            <h5 style={{fontSize:"1.2em", fontWeight:"300", marginBottom:"15px"}}>

            <Input type="text" name={"rowhead"} id={"rowhead"}
            value={this.state.rowhead}
            placeholder={"Row title..."}
            style={{marginBottom:"10px", backgroundColor:"#f0f3f5", fontSize:"1.2em", fontWeight:"200"}}
            onChange={this.updateHeader}
            />
            </h5>
            <hr/>
            <p style={{ marginBottom:"15px", fontSize:"0.8em"}}>Add, delete or re-order rows</p>
            </div>
       
            <DragDropContext onDragEnd={this.onDragEnd}>
            <div>
            <Droppable droppableId="droppable">
            {(provided, snapshot) => (
                <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
                {...provided.droppableProps}
                >
            <div style={{width:"100%"}}>
              {this.state.items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                        
                          <div style={{backgroundColor:'transparent', border:"0", fontSize:"1em", cursor:"pointer", marginBottom:"-20px"}}>
                          <span {...provided.dragHandleProps}><i className="icon-frame" style={{color:"#8f9ba6", fontSize:"0.8em", marginRight:"3px"}} ></i></span>
                          </div>

                          {this.renderListItem(item.header, item.detail, item.id, item.price, item.pricemetric)}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
                <div onClick={()=>{this.addListItem()}} style={{display:"flex", justifyContent:"center", cursor:"pointer"}}>
                  <i className="icon-plus" style={{color:"#20a8d8", fontSize:"1em", marginRight:"5px"}} ></i>
                </div>
              </div>
            </div>
          )}
        </Droppable>

        </div>

      </DragDropContext>

                
                <div style={{display:"flex", flexDirection:"row",  padding:"30px", marginLeft:"-16px", marginRight:"-16px", marginBottom:"-16px", marginTop:"50px", color:"white", fontSize:"0.8em", backgroundColor:"#2f353a" }}>
                    
                    <div style={{flexDirection:"column", alignItems:"flex-end", marginLeft:"15px"}}>
                    <p>Randomize</p>
                    <Toggle
                        id={"randomtoggle"}
                        checked= {this.state.randomtoggle}
                        icons={{unchecked: null}}
                        className = {"custom-success"}
                        onChange = {() => this.handleToggleChange('randomtoggle')}
                            />
                    </div>
    
                    <div style={{flexDirection:"column", alignItems:"flex-end", marginLeft:"15px"}}>
                    <p>Show Prices</p>
                    <Toggle
                        id={"pricetoggle"}
                        checked= {this.state.pricetoggle}
                        icons={{unchecked: null}}
                        className = {"custom-success"}
                        onChange = {() => this.handleToggleChange('pricetoggle')}
                            />
                    </div>
                </div>
        
            </ModalBody>

            <ModalFooter style={{marginTop:"0"}}>
            <div style={{alignSelf:"flex-start", marginRight:"auto"}}>
            {!this.state.isdeleting &&
            <Button color="danger" onClick={()=>this.setState({isdeleting:true})}>Delete List</Button>
            }
            {this.state.isdeleting &&
            <Button color="danger" onClick={()=>this.deleteList()}>Confirm Delete</Button>
            }
            </div>
            {this.state.isdeleting &&
            <Button color="secondary" onClick={()=>this.setState({isdeleting:false})} >Cancel Delete</Button>
            }
            {'  '}
            <Button color="primary" onClick={()=>this.updateList()} >Save</Button>
            {'  '}
        <Button color="secondary" onClick={()=>this.props.toggleModal()} >Cancel</Button>
            </ModalFooter>
      </div>

        );
    }
}
import React, { Component } from "react";
import {  Button, Input, Form, FormGroup, Row, Col, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Jumbotron,  ModalHeader, ModalBody, ModalFooter, CustomInput} from 'reactstrap';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable'
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import Toggle from 'react-toggle'
import  'react-toggle/style.css'
import GifPlayer from 'react-gif-player';
import ReactPlayer from 'react-player';
import './RenderContent.scss';
import SurveyTextEditor from './SurveyTextEditor'


/// styling for react-select
const customStyles = (isValid) => ({
    control: (styles: any, state:any) => ({
      ...styles,
      padding: 5,
      width:"100%",
      fontSize: "smaller",
      ...(!isValid && { borderColor: 'red' }),
    }),
  });

  /// styling for react-select
const customStyles2 = (isValid) => ({
    control: (styles: any, state:any) => ({
      ...styles,
      backgroundColor:"#f0f3f5",
      padding: 5,
      fontSize: "smaller",
      ...(!isValid && { borderColor: 'red' }),
    }),
  });


  function makeId(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }

 function truncateString(str, num) {
    if (typeof str === 'undefined'){
      return "..."
    }
    if (str.length <= num) {
      return str
    }
    return str.slice(0, num) + '...'
  }

export default class ContentEditorModal extends Component {
  constructor(props) {
    super(props);
    this.getHTML = this.getHTML.bind(this)
    let cardindex = null
    let questionindex = null
    let question = null
    for (const c in this.props.page.questions[0].cards){
        if(this.props.page.questions[0].cards[c].id === this.props.currentcard){
            cardindex = c
            for (const t in this.props.page.questions[0].cards[c].content){
                if(this.props.page.questions[0].cards[c].content[t].id === this.props.currentquestion){
                    questionindex = t
                    question = {...this.props.page.questions[0].cards[c].content[t]}
                }
            }
        }
    }
    let validfields = {}
    let validtype = {}
    let initialselections = {}
    initialselections[this.props.currentquestion]=null
    let initializefalse = ['checkbox', 'radio']
    let radiocheck = {}
    let fieldid = question['id']
    let selectmulti = null
    let selectcreate = null
    let textfield = true
    let numberfield = false
    let emailfield = false
    let datefield = false
    let textarea = false
    let img = true
    let bigimg = false
    let video = true
    let gif = false
    let jumbotron = false
    let jumbotron2 = true
    let slider = true
    let range = false
    let sliderticks = true
    let slidermarks = true
    let keylist = []
    let marklist = []
    let requiredtoggle = false
    if(question['validationtype']!=="none"){requiredtoggle = true}
    if (question['type']==='textfield'){
        if(question['texttype']==='number'){numberfield = true}
        if(question['texttype']==='email'){emailfield=true}
        if(question['texttype']==='date'){datefield=true}
    }
    if(question['type']==='textarea'){
        textarea = true
        textfield = false
    }
    validfields[fieldid]= false
    validtype[fieldid]= question['validationtype']
    if(question['validationtype']==='none'){validfields[fieldid]= true}
    if(question['type']==='toggle'){initialselections[fieldid] = false}
    if(question['type']==='slider'){initialselections[fieldid] = null}
    if(question['type']==='range'){initialselections[fieldid] = question['rangedefault']}
    if(initializefalse.includes(question['type']))
        {
        for (let s of question['selectoptions']){
            radiocheck[s['value']]=false
        }
        initialselections[fieldid]=radiocheck
        }
    if(question['type']==='select'){
        selectmulti = false
        selectcreate = false
    }
    if(question['type']==='selectcreatable'){
        selectmulti = false
        selectcreate = true
    }
    if(question['type']==='multiselect')
    {
        selectmulti = true
        selectcreate = false
    }
    if(question['type']==='multiselectcreatable'){
        selectmulti = true
        selectcreate = true
    }
    let checkbox = false
    let radio = false
    if(question['type']==='checkbox'){
        checkbox = true
    }
    if(question['type']==='radio'){
        radio = true
    }
    if(question['type']==='img'){
        bigimg = false
        img = true
    }
    if(question['type']==='bigimg'){
        img = false
        bigimg = true
    }
    if(question['type']==='video'){
        video = true
        gif = false
    }
    if(question['type']==='gif'){
        video = false
        gif = true
    }
    if(question['type']==='jumbotron'){
        jumbotron2 = false
        jumbotron = true
    }
    if(question['type']==='jumbotron2'){
        jumbotron = false
        jumbotron2 = true
    }
    if(question['type']==='slider'){
        slider = true
        range = false
        let sortedmarks = Object.keys(question.slidermarks).sort().reduce((acc, key) => ({...acc, [key]: question.slidermarks[key]}), {})
        for (let [key, value] of Object.entries(sortedmarks)){
            marklist.push(value)
            keylist.push(key)
        }
        marklist=marklist.toString()
        keylist=keylist.toString()
    }
    if(question['type']==='range'){
        slider = false
        range = true
        let sortedmarks = Object.keys(question.slidermarks).sort().reduce((acc, key) => ({...acc, [key]: question.slidermarks[key]}), {})
        for (let [key, value] of Object.entries(sortedmarks)){
            marklist.push(value)
            keylist.push(key)
        }
        marklist=marklist.toString()
        keylist=keylist.toString()
    }

    let showiftoggle = false
    if(question['showif'].id!==null){
        showiftoggle = true
    }

    this.state = {
        isValid: false,
        cardindex: cardindex,
        questionindex: questionindex,
        question: question,
        validfields: validfields,
        validtype: validtype,
        selectvalue: initialselections,
        saveHtml: false,
        requiredtoggle:requiredtoggle,
        showiftoggle: showiftoggle,
        selectmulti: selectmulti,
        selectcreate: selectcreate,
        textfield: textfield,
        numberfield: numberfield,
        emailfield: emailfield,
        datefield: datefield,
        textarea: textarea,
        checkbox: checkbox,
        radio: radio,
        img: img,
        bigimg: bigimg,
        gif: gif,
        video: video,
        jumbotron: jumbotron,
        jumbotron2: jumbotron2,
        slider: slider,
        range: range,
        sliderticks: sliderticks,
        slidermarks: slidermarks,
        keylist: keylist,
        marklist: marklist,
        showiftrigger: null,
        showifvalue:{},
        isdeleting:false,

      };
    }
   
  async componentDidMount(){ 
  }

    /// looks to see if any of the fields have a false validation setting.  called on each input change
    validateAllFields(){
        let checkitems = Object.values(this.state.validfields)
        let validcheck = checkitems.includes(false)
        this.setState({isValid: !validcheck})
            }

    /// looks inside the dict containing the various radio/check options to see if any are true
    validateRadioCheck(i){
        let validcheck = true
        if(this.state.validtype[i] !== "none"){
                const entries = Object.values(this.state.selectvalue[i])
                const entriesvalid = entries.includes(true)
                if (entriesvalid ===false){validcheck = false}
                }
        this.setState(prevState => ({validfields: {...prevState.validfields, [i]: validcheck}}), () => {this.validateAllFields()} )
        }
        
    /// calls Selection Change callback:  Used by TEXT-FIELD and TEXT-AREA
    handleFieldChange = (event) => {
      const target = event.target;
      let value = target.value;
      const name = target.name;
      this.changeSelection(name, value)
    }

    /// callback:  used by RADIO and CHECKBOX
    handleRadioCheckChange = (event) => {
      const target = event.target;
      if(target != null){
        let type = target.type
        let id = target.id
        this.toggleRadioCheck(type, id)
      }
    }

    /// used to generate the radio and checkbox items
    getCheckboxItems(i, t){

      if(t==="checkbox"){
      return(
      <CustomInput type="checkbox" id={i['value']} label={i['label']} name={this.props.currentquestion}></CustomInput>

      )}
      if(t==="radio"){
        return(
            <CustomInput type="radio" id={i['value']} label={i['label']} name={this.props.currentquestion}></CustomInput>
        )}

        let copyselectvals = this.state.selectvalue
        copyselectvals[i]= false
        this.setState({selectvalue:copyselectvals})
    }

    /// calls Selection Change callback:  Used by SELECT and MULTI --- not slider range here as it doesnt pass name via action
    handleSelectChange = (selectvalue:any, action: any) => {
        this.changeSelection(action.name, selectvalue)
    };

    /// updates users input for everything except radio/check.  i is id;  e is the value
    changeSelection(i, e){
        let fieldvalid = false
        if(this.state.validtype[i] !== "none"){
            if(e !== null && e !== false){fieldvalid = true}
        } else {fieldvalid =true}

        this.setState(prevState => ({selectvalue: {...prevState.selectvalue, [i]: e}}), )
        this.setState(prevState => ({validfields: {...prevState.validfields, [i]: fieldvalid}}), () => {this.validateAllFields()} )
    }



    /// updates the radio/check option bool toggle.  i is id;  e is the value;  t is type
    toggleRadioCheck(t, i){

        if(t==='radio'){
            let newvals = this.state.selectvalue
            for (let p in newvals)
                {
                newvals[p]=false
                }
            newvals[i]=true

            this.setState({selectvalue:newvals})

        } else {

            this.setState(prevState => ({
                selectvalue: {
                ...prevState.selectvalue,          
                [i]: !this.state.selectvalue[i]       
                }
                })
                )
            }
    }
    







//// fix the select value IO for slider






    /// calls Selection Change callback:  Used by SELECT and MULTI --- not slider range here as it doesnt pass name via action
    handleSliderChange = (selectvalue:any, action: any) => {

        console.log('actioninfo', action, Object.keys(action))

      this.handleSelectionChange("slider-range", selectvalue)
    };

  













    /// a function to render the map of list details used for content only items (per ContentCard)
    renderListItem(h, d, i){
        return(
            <div style={{display:"flex"}}>

                <span onClick={()=>{this.deleteListItem(i)}} 
                style={{marginLeft:"auto", alignSelf:"center", cursor:"pointer"}}>
                <i className="icon-close" style={{color:"#f86c6b", fontSize:"0.7em", marginRight:"5px"}} ></i>
                </span>
                
                <ListGroupItem style={{marginTop:"0px", marginBottom:"0px", width:"95%"}}>
                    <ListGroupItemHeading>
                    <Input autoFocus type="textarea" rows="1" name={'Header'} id={i}
                        value={h}
                        placeholder={"List Item"}
                        style={{marginBottom:"10px", backgroundColor:"#f0f3f5", fontWeight:"600", fontSize:"1em"}}
                        onChange={this.updateList}
                        />
                    </ListGroupItemHeading>
                    <ListGroupItemText>
                    <Input autoFocus type="textarea" rows="3" name={'Detail'} id={i}
                        value={d}
                        placeholder={"List Item Details"}
                        style={{marginBottom:"10px", backgroundColor:"#f0f3f5", fontWeight:"300", fontSize:"0.8em"}}
                        onChange={this.updateList}
                        />
                    </ListGroupItemText>
                </ListGroupItem>
        </div>
        )
      }

    updateList = (event) => {
        let listcopy = [...this.state.question.list]
        const target = event.target;
        let value = target.value;
        const name = target.name;
        const id = target.id
        let index = -1
        for (const x in listcopy){
            if(listcopy[x].id===id){
                index = x
            }
        }
        if (index>-1){
            if(name === "Header"){
                listcopy[index].header = value
            } else {
                listcopy[index].detail = value
            }
            this.setState(prevState => ({
                question: {...prevState.question,           
                    list: listcopy}
                }))
            }
    }

    deleteListItem = (i) => {
        let listcopy = [...this.state.question.list]
        let index = -1
        for (const x in listcopy){
            if(listcopy[x].id===i){
                index = x
            }
        }
        if (index > -1) {
            listcopy.splice(index, 1);
        }
        this.setState(prevState => ({
            question: {...prevState.question,           
                list: listcopy}
            }))
        }

    addListItem = () => {
        let listcopy = [...this.state.question.list]
        let id = makeId(5)
        const detail = " "
        const header = " "
        let item ={}
        item['id']=id
        item['header']=header
        item['detail']=detail
        listcopy.push(item)
        this.setState(prevState => ({
            question: {...prevState.question,           
                list: listcopy}
            }))
        }

    updateQuestion = (event) => {
        const target = event.target;
        let value = target.value;
        this.setState(prevState => ({
            question: {...prevState.question,           
                header: value}
            }))
        }

    /// updates slider and range items and ensures they are numeric
    
    updateItemProps = (event) => {
        const numericlist = ["slidermin", "slidermax", "sliderstep"]
        const target = event.target;
        let value = target.value;
        const name = target.name;
        if (numericlist.includes(name)){
            value = Number(value)
            if(isNaN(value)){value=0}
        }
       
        this.setState(prevState => ({
            question: {...prevState.question,           
               [name]: value}
           }), ()=>this.updateRangeDefault())
        }

    /// updates the range defaults to be the latest min/max - will error if leave null
    updateRangeDefault(){
        let newrange = []
        newrange.push(this.state.question.slidermin)
        newrange.push(this.state.question.slidermax)
        this.setState(prevState => ({
            question: {...prevState.question,           
               rangedefault: newrange}
           }))
    }

    ///  marks are a dict in the slider/range component.  Pulled out into two separate lists so you can add and change key values
    updateSliderMarkProps = (event) => {
        const target = event.target;
        let value = target.value;
        const name = target.name;
        this.setState({[name]: value}, ()=>this.updateSliderMarkState())
    }

    /// works on blur so not trying to update dict with keys as they are being typed.  converts the strings into lists and then creates dict
    /// based on the same order as shown in the respective lists
    /// for some reason not able to access STATE in this function....?...
    updateSliderMarkState(){
        let keylist=this.state.keylist
        let marklist=this.state.marklist
        let newmarks = {}
        keylist = keylist.split(",")
        marklist = marklist.split(",")
        for (let k in keylist){
            if(!isNaN(k)){
            if (typeof marklist[k]==='undefined'){
                newmarks[keylist[k]]=""
            } else {
            newmarks[keylist[k]]=marklist[k]
            }
            }
        }
        this.setState(prevState => ({
            question: {...prevState.question,           
                slidermarks: newmarks}
            }))

        }
            

    updateSelectType = (event) => {
        const target = event.target;
        let value = target.value;
        const name = target.name;
        console.log(name, value)
        this.setState(prevState => ({
            question: {...prevState.question,           
                header: value}
            }))
        }

    updateOptions = (selectvalue, action) => {
        let newoptionlist = []
        let newoption = {}
        for (const o of selectvalue){
            newoption = {}
            newoption['label']=o.label
            newoption['value']=o.value
            newoptionlist.push(newoption)
        }
        this.setState(prevState => ({
            question: {...prevState.question,           
                selectoptions: newoptionlist}
            }))
    };

    getSliderMarks(){
        return(
        <div>

            <p><small>Label Locations (numeric values, separated by commas)</small></p>
            <Input autofocus type="text" name={"keylist"} id={"keylist"}
            value={this.state.keylist.toString()}
            style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
            onChange={this.updateSliderMarkProps}
            />
            <p><small>Label Values (separated by commas)</small></p>
            <Input autofocus type="text"  name={"marklist"} id={"marklist"}
            value={this.state.marklist.toString()}
            style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
            onChange={this.updateSliderMarkProps}
            />

        </div>
        )
        }

    updateQuestionType(i){


        const selectypes = ["selectmulti", "selectcreate"]
        const nonselecttypes = ["html", "toggle", "textfield", "textarea", "checkbox", "radio", "slider", "range", "bigimg", "img", "video", "gif", "contentlist", "jumbotron2", "jumbotron"]

        if(i==='requiredtoggle'){
            if(this.state.requiredtoggle === true){
                this.setState(prevState => ({question: {...prevState.question, 
                    validationtype: "one",
                    header: this.state.question.header + " *"}}), ()=>console.log('....', this.state.question.validationtype))
                
            } else {
                let newheader = this.state.question.header
                let asterisk = newheader[newheader.length-1]
                if (asterisk = "*"){
                    newheader = newheader.slice(0,newheader.length-1)
                }
                this.setState(prevState => ({question: {...prevState.question, validationtype: "none"}, question: {...prevState.question, header: newheader}}))
            }
        }
            

        if(i==='textfield'){this.setState({textarea:!this.state.textarea},
            ()=>this.setState(prevState => ({question: {...prevState.question, type: i}})))}

        if(i==='textarea'){this.setState({textfield:!this.state.textfield},
            ()=>this.setState(prevState => ({question: {...prevState.question, type: i}}
            ),()=>this.setState({datefield:false, emailfield:false, numberfield:false})
            ))}

        if(i==='checkbox'){this.setState({radio:!this.state.radio},
            ()=>this.setState(prevState => ({question: {...prevState.question, type: i}})))}

        if(i==='radio'){this.setState({checkbox:!this.state.checkbox},
            ()=>this.setState(prevState => ({question: {...prevState.question, type: i}})))}

        if(i==='video'){this.setState({gif:!this.state.gif},
            ()=>this.setState(prevState => ({question: {...prevState.question, type: i}})))}

        if(i==='gif'){this.setState({video:!this.state.video},
            ()=>this.setState(prevState => ({question: {...prevState.question, type: i}})))}
        

/// how to reset these when toggling all options to off

        if(i==='datefield'){this.setState({emailfield:false, numberfield:false},
            ()=>this.setState(prevState => ({question: {...prevState.question, texttype: "date"}})))}
        if(i==='emailfield'){this.setState({datefield:false, numberfield:false},
            ()=>this.setState(prevState => ({question: {...prevState.question, texttype: "email"}})))}
        if(i==='numberfield'){this.setState({emailfield:false, datefield:false},
            ()=>this.setState(prevState => ({question: {...prevState.question, texttype: "number"}})))}

        if(i==='sliderticks'){this.setState(prevState => ({question: {...prevState.question, sliderticks: !this.state.question.sliderticks}}))}

        if(i==='slidermarks'){
            if(this.state.slidermarks===true){
            this.getSliderMarks()
        }}

        if(i==='img'){this.setState({bigimg:!this.state.bigimg},
            ()=>this.setState(prevState => ({question: {...prevState.question, type: i}})))}
        
        if(i==='bigimg'){this.setState({img:!this.state.img},
            ()=>this.setState(prevState => ({question: {...prevState.question, type: i}})))}

        if(i==='jumbotron'){this.setState({jumbotron2:!this.state.jumbotron2},
            ()=>this.setState(prevState => ({question: {...prevState.question, type: i}})))}
        
        if(i==='jumbotron2'){this.setState({jumbotron:!this.state.jumbotron},
            ()=>this.setState(prevState => ({question: {...prevState.question, type: i}})))}

        if(i==='slider'){this.setState({range:!this.state.range},
            ()=>this.setState(prevState => ({question: {...prevState.question, type: i}})))}
        
        if(i==='range'){this.setState({slider:!this.state.slider},
            ()=>this.setState(prevState => ({question: {...prevState.question, type: i}})))}
        
        if(selectypes.includes(i)){
            let newselecttype = null
            if(this.state.selectmulti === true)
            {
                if(this.state.selectcreate=== true){newselecttype = "multiselectcreatable"} 
                else {newselecttype = "multiselect"}
            } 
            else 
            {
                if(this.state.selectcreate===true){newselecttype = "selectcreatable"}
                else {newselecttype = "select"}
            }
            this.setState(prevState => ({question: {...prevState.question, type: newselecttype}}))
        }
    }
    
    /// Changes the state params - AND logic to change the question type
    handleToggleChange(i){

        this.setState({[i]:!this.state[i]}, ()=>this.updateQuestionType(i))

        }



/// ***** FOR SHOW IF LOGIC *******

    /// use for SHOWIF - not applicable for this single question modal
    showField(target, test){

        if(test === null){return true}
        
        if(typeof(target)==='object'){
  
          if(target !== null){
          
            if(Array.isArray(target)){
              let targetlist = []
              for(let i of target){
                targetlist.push(i.value)
              }
              if(targetlist.includes(test.value)){return true} else {return false}}
            
            else {if(target.value === test.value){return true} else {return false}}
          }
          else {return false}
        }
        else {if(test === target){return true} else {return false}}
      }

    /// gets the show if params to show on the card header
    getShowIf(){
        if(this.state.question.showif.id === null){return}
        let qheader = ""
        let qvalue = ""
        if(this.state.question.showif.id !== null){
            for (let x of this.props.page.questions[0].cards){
                for (let y of x.content){
                    if(y.id === this.state.question.showif.id){
                        qheader = y.header
                        if(qheader.length>9)
                        {
                        qheader = truncateString(qheader, 30)
                        qheader = qheader + "..."}
                    }
                }
            }
            if(typeof this.state.question.showif.value.label ==='undefined' || this.state.question.showif.value.label === null){
                    qvalue = ""
                    if(typeof this.state.question.showif.value.value !== null ){qvalue = JSON.stringify(this.state.question.showif.value)}
            } else {qvalue = this.state.question.showif.value.label}
        }

        return (
            <div style={{fontSize:"0.7em", display:"flex", fontWeight:"300"}}>
            <p style={{color:'#20a8d8', marginRight:"5px"}}>Show-if:</p>
            {qheader}
            <p style={{color:'#20a8d8', marginRight:"5px"}}>=</p>
            {qvalue}
            </div>
            )
    }

        /// used to generate the radio and checkbox items
        getShowIfCheckboxItems(i, t, id){
            let showifid = 'showif'+id+i['value']
            if(typeof this.state.showifvalue[id]==='undefined'){
                let initialize = {}
                initialize[id]={}
                this.setState({showifvalue:initialize})
            }

            if(t==="checkbox"){

            return(
            <CustomInput type="checkbox" id={showifid} label={i['label']} value={i['value']} name={id}></CustomInput>
      
            )}
            if(t==="radio"){

              return(
                  <CustomInput type="radio" id={showifid} label={i['label']} value={i['value']} name={id}></CustomInput>
              )}
      
          }

    /// handles the selected question to be the trigger
    handleShowIfChange = (selectvalue:any, action: any) => {
        let showiftrigger = {}
        for(let q in this.props.page.questions[0].cards){
            for (let c in this.props.page.questions[0].cards[q].content){
                if(this.props.page.questions[0].cards[q].content[c].id === selectvalue.value){
                    showiftrigger = this.props.page.questions[0].cards[q].content[c]
                }
            }
        }
        this.setState(prevState => ({
            question: {...prevState.question,
                showif: {...prevState.question.showif,
                    id: showiftrigger.id,
                    type: showiftrigger.type,
                    value: {},
                    }},
            showiftrigger:showiftrigger
                }))
        };

    /// gets all the questions on the page and turns into a selection 
    getShowIfQuestionList(id){
        let validquestions = ['select', 'selectcreatable', 'multiselect', 'multiselectcreatable', 'toggle', 'slider', 'range', 'checkbox', 'radio']
        let showifquestionlist = []
        let showifquestion ={}
        for(let q of this.props.page.questions[0].cards){
            for (let c of q.content){
                if(c.id !== id){
                    if(validquestions.includes(c.type))
                    {
                        showifquestion['value']=c.id
                        showifquestion['label']=c.header
                    }
                    if (showifquestion !=={} )
                    {
                        showifquestionlist.push(showifquestion)
                    }
                }
                showifquestion = {}
            }
        }
        
        return(
            <div style={{marginBottom:"15px"}}>
                    <Select
                    onChange={this.handleShowIfChange}
                    options={showifquestionlist}
                    name={id}
                    styles={customStyles(true)} 
                />

            </div>

        )
    }

   /// show the answers for selected trigger question
    renderShowIfTrigger(field){

        if (field['type'] === "select"){
            return(

                <Row>
                    <Col>
                    <p><small>Trigger Value</small></p>
                        <Select
                        onChange={this.handleShowIfSelectChange}
                        options={this.state.showiftrigger.selectoptions}
                        name={field.id}
                        type={"select"}
                        styles={customStyles(true)}  /// false means there is an error and will error style
                    />
                    </Col>
                </Row>

            )
        }

        if (field['type']  === "selectcreatable"){
            return(
                <Row>
                    <Col>
                    <p><small>Trigger Value</small></p>
                        <CreatableSelect
                        onChange={this.handleShowIfSelectChange}
                        options={this.state.showiftrigger.selectoptions}
                        name={field.id}
                        type={"selectcreatable"}
                        styles={customStyles(true)}
                    />
                    </Col>
                </Row>

            )
        }

        if (field['type']  === "multiselect"){
            return(
                <Row>
                    <Col>
                    <p><small>Trigger Value</small></p>
                        <Select
                        isMulti
                        onChange={this.handleShowIfSelectChange}
                        options={this.state.showiftrigger.selectoptions}
                        name={field.id}
                        styles={customStyles(true)} 
                    />
                    </Col>
                </Row>
            )
        }

        if (field['type']  === "multiselectcreatable"){
            return(
                <Row>
                    <Col>
                    <p><small>Trigger Value</small></p>
                        <CreatableSelect
                        isMulti
                        onChange={this.handleShowIfToggleChange}
                        options={this.state.showiftrigger.selectoptions}
                        name={field.id}
                        styles={customStyles(true)}  /// false means there is an error and will error style
                    />
                    </Col>
                </Row>
            )
        }

        if (field['type']  === "toggle"){
            return(
                <div>
                <p><small>Trigger Value</small></p>
                <Toggle
                id={field.id}
                value={field.togglevalue}
                icons={{unchecked: null}}
                className = {field.togglestyle}
                onChange = {() => this.changeShowIfSelection(field.id, !this.state.showiftrigger[field.id])}
            />
                </div>

            )
        }

        if (field['type']  === "checkbox"){
        return(
            <Row>
                <Col>
                <p><small>Trigger Value</small></p>
                    <Form>
                        <FormGroup inline name={field.id} onChange={this.handleShowIfRadioCheckChange}>
                            {this.state.showiftrigger.selectoptions.map((item, key=item.id)=>this.getShowIfCheckboxItems(item, "checkbox", field['id']))}
                        </FormGroup>
                    </Form>
                </Col>
            </Row>
        
        )
        }

        if (field['type']  === "radio"){
        return(
            <Row>
                <Col>
                <p><small>Trigger Value</small></p>
                    <Form>
                        <FormGroup inline name={field.id} onChange={this.handleShowIfRadioCheckChange}>
                            {this.state.showiftrigger.selectoptions.map((item, key=item.id)=>this.getShowIfCheckboxItems(item, "radio", field['id']))}
                        </FormGroup>
                    </Form>
                </Col>
            </Row>
        )
        }

        if (field['type']  === "slider"){
            return(
            <div style={{marginLeft:"15px", marginRight:"15px", marginBottom:"15px"}}>
                <p><small>Trigger Value</small></p>
            <Slider
                min={field['slidermin']}
                max={field['slidermax']}
                step={field['sliderstep']}
                marks={field['slidermarks']}
                dots={field['sliderticks']}
                defaultValue={field['sliderdefault']}
                onAfterChange={()=>this.handleShowIfSliderChange}
                />
            </div>
            )
        }

        if (field['type']  === "range"){
            let initialize = {}
            initialize[field['id']]={}
            this.setState({showifvalue:initialize})
            return(
            <div style={{marginLeft:"15px", marginRight:"15px", marginBottom:"15px"}}>
                <p><small>Trigger Value</small></p>
            <Range
                min={field['slidermin']}
                max={field['slidermax']}
                step={field['sliderstep']}
                marks={field['slidermarks']}
                dots={field['sliderticks']}
                onAfterChange={()=>this.handleShowIfSliderChange}
                />
            </div>

            )
        }



    }

    /// removes the current question props and leaves canvas for selecting question and answers
    renderShowIfEditor(field){

        /// if(this.showField(this.props.selectvalue[field['showif']['id']], field['showif']['value'])){

        if (field['type'] === "html"){
            return(
                <div style={{width:"100%"}}>
                {field.text}


                <Row>
                <Col>
                <p><small>Trigger Question</small></p>

                {this.getShowIfQuestionList(field['id'])}

                {this.state.showiftrigger !== null &&
                this.renderShowIfTrigger(this.state.showiftrigger)
                }

                </Col>
                </Row>
                </div>
            )
            }

        if (field['type'] === "select"){
            return(
            <div className="editor-content-item-modal">
                <p><small>Question Text</small></p>
                <Input autofocus type="textarea" rows="2" name={field.id} id={field.id}
                value={this.state.question.header}
                style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
                onChange={this.updateQuestion}
                />
                <Row>
                <Col>
                <p><small>Trigger Question</small></p>

                {this.getShowIfQuestionList(field['id'])}

                {this.state.showiftrigger !== null &&
                this.renderShowIfTrigger(this.state.showiftrigger)
                }
                
                </Col>
                </Row>
            </div>


            )
        }

        if (field['type']  === "selectcreatable"){
            return(
                <div className="editor-content-item-modal">
                <p><small>Question Text</small></p>
                <Input autofocus type="textarea" rows="2" name={field.id} id={field.id}
                value={this.state.question.header}
                style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
                onChange={this.updateQuestion}
                />
                <Row>
                    <Col>
                    <p><small>Trigger Question</small></p>

                    {this.getShowIfQuestionList(field['id'])}

                    {this.state.showiftrigger !== null &&
                    this.renderShowIfTrigger(this.state.showiftrigger)
                    }

                    </Col>
                </Row>
            </div>
            )
        }

        if (field['type']  === "multiselect"){
            return(
                <div className="editor-content-item-modal">
                <p><small>Question Text</small></p>
                <Input autofocus type="textarea" rows="2" name={field.id} id={field.id}
                value={this.state.question.header}
                style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
                onChange={this.updateQuestion}
                />
                <Row>
                    <Col>
                    <p><small>Trigger Question</small></p>

                    {this.getShowIfQuestionList(field['id'])}

                    {this.state.showiftrigger !== null &&
                    this.renderShowIfTrigger(this.state.showiftrigger)
                    }

                    </Col>
                </Row>
            </div>
            )
        }

        if (field['type']  === "multiselectcreatable"){
            return(
                <div className="editor-content-item-modal">
                <p><small>Question Text</small></p>
                <Input autofocus type="textarea" rows="2" name={field.id} id={field.id}
                value={this.state.question.header}
                style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
                onChange={this.updateQuestion}
                />
                <Row>
                    <Col>
                    <p><small>Trigger Question</small></p>

                    {this.getShowIfQuestionList(field['id'])}

                    {this.state.showiftrigger !== null &&
                    this.renderShowIfTrigger(this.state.showiftrigger)
                    }

                    </Col>
                </Row>
            </div>
            )
        }

        if (field['type']  === "toggle"){
            return(
                <div className="editor-content-item-modal">
                <p><small>Question Text</small></p>
                <Input autofocus type="textarea" rows="2" name={field.id} id={field.id}
                value={this.state.question.header}
                style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
                onChange={this.updateQuestion}
                />
                <Row>
                    <Col>
                    <p><small>Trigger Question</small></p>

                    {this.getShowIfQuestionList(field['id'])}

                    {this.state.showiftrigger !== null &&
                    this.renderShowIfTrigger(this.state.showiftrigger)
                    }

                    </Col>
                </Row>
            </div>
            )
        }

        if (field['type']  === "textfield"){
            return(
                <div className="editor-content-item-modal">
                <p><small>Question Text</small></p>
                <Input autofocus type="textarea" rows="2" name={field.id} id={field.id}
                value={this.state.question.header}
                style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
                onChange={this.updateQuestion}
                />
                <Row>
                    <Col>
                    <p><small>Trigger Question</small></p>

                    {this.getShowIfQuestionList(field['id'])}

                    {this.state.showiftrigger !== null &&
                    this.renderShowIfTrigger(this.state.showiftrigger)
                    }

                    </Col>
                </Row>

            </div>
            )
        }

        if (field['type']  === "textarea"){
            return(
            <div className="editor-content-item-modal">
                <p><small>Question Text</small></p>
                <Input autofocus type="textarea" rows="2" name={field.id} id={field.id}
                value={this.state.question.header}
                style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
                onChange={this.updateQuestion}
                />
                <Row>
                    <Col>
                    <p><small>Trigger Question</small></p>

                    {this.getShowIfQuestionList(field['id'])}

                    {this.state.showiftrigger !== null &&
                    this.renderShowIfTrigger(this.state.showiftrigger)
                    }

                    </Col>
                </Row>
        
            </div>
        )
        }

        if (field['type']  === "checkbox"){
        return(
            <div className="editor-content-item-modal">
            <p><small>Question Text</small></p>
            <Input autofocus type="textarea" rows="2" name={field.id} id={field.id}
            value={this.state.question.header}
            style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
            onChange={this.updateQuestion}
            />
                <Row>
                    <Col>
                    <p><small>Trigger Question</small></p>

                    {this.getShowIfQuestionList(field['id'])}

                    {this.state.showiftrigger !== null &&
                    this.renderShowIfTrigger(this.state.showiftrigger)
                    }

                    </Col>
                </Row>
        
        </div>
        )
        }

        if (field['type']  === "radio"){
        return(
            <div className="editor-content-item-modal">
            <p><small>Question Text</small></p>
            <Input autofocus type="textarea" rows="2" name={field.id} id={field.id}
            value={this.state.question.header}
            style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
            onChange={this.updateQuestion}
            />
                <Row>
                    <Col>
                    <p><small>Trigger Question</small></p>

                    {this.getShowIfQuestionList(field['id'])}

                    {this.state.showiftrigger !== null &&
                    this.renderShowIfTrigger(this.state.showiftrigger)
                    }

                    </Col>
                </Row>
        </div>
        )
        }

        if (field['type']  === "slider"){
            return(
                <div className="editor-content-item-modal">
                <p><small>Question Text</small></p>
                <Input autofocus type="textarea" rows="2" name={field.id} id={field.id}
                value={this.state.question.header}
                style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
                onChange={this.updateQuestion}
                />
                <Row>
                    <Col>
                    <p><small>Trigger Question</small></p>

                    {this.getShowIfQuestionList(field['id'])}

                    {this.state.showiftrigger !== null &&
                    this.renderShowIfTrigger(this.state.showiftrigger)
                    }

                    </Col>
                </Row>
    
            </div>
            )
        }

        if (field['type']  === "range"){
            return(
                <div className="editor-content-item-modal">
                <p><small>Question Text</small></p>
                <Input autofocus type="textarea" rows="2" name={field.id} id={field.id}
                value={this.state.question.header}
                style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
                onChange={this.updateQuestion}
                />
                <Row>
                    <Col>
                    <p><small>Trigger Question</small></p>

                    {this.getShowIfQuestionList(field['id'])}

                    {this.state.showiftrigger !== null &&
                    this.renderShowIfTrigger(this.state.showiftrigger)
                    }

                    </Col>
                </Row>
    
            </div>
            )
        }

        if (field['type'] === "img"){
        return(
            <div>
            <img width="50%" src={field.img} alt="Survey Image" />
            <Row>
                    <Col>
                    <p><small>Trigger Question</small></p>

                    {this.getShowIfQuestionList(field['id'])}

                    {this.state.showiftrigger !== null &&
                    this.renderShowIfTrigger(this.state.showiftrigger)
                    }

                    </Col>
                </Row>
            </div>
        )
        }

        if (field['type'] === "bigimg"){
        return(
            <div tag="a"  onClick={() => this.props.toggleLightbox(field['id'])} style={{cursor: "pointer"}} >
            <img width="50%" src={field['img']} alt="Survey Image" />
            <Row>
                    <Col>
                    <p><small>Trigger Question</small></p>

                    {this.getShowIfQuestionList(field['id'])}

                    {this.state.showiftrigger !== null &&
                    this.renderShowIfTrigger(this.state.showiftrigger)
                    }

                    </Col>
                </Row>
                
            </div>
        )
        }

        if (field['type'] === "gif"){
                return(
                    <div style={{cursor: "pointer"}}>
                    <GifPlayer
                        gif={field['gif']}
                        width="50%"
                        />
                <Row>
                    <Col>
                    <p><small>Trigger Question</small></p>

                    {this.getShowIfQuestionList(field['id'])}

                    {this.state.showiftrigger !== null &&
                    this.renderShowIfTrigger(this.state.showiftrigger)
                    }

                    </Col>
                </Row>
            </div>
            )
        }

        if (field['type'] === "video"){
            return(
                <div>
                <ReactPlayer
                    url={field['video']}
                    controls='true'
                    muted='true'
                    width='50%'
                    />
                <Row>
                    <Col>
                    <p><small>Trigger Question</small></p>

                    {this.getShowIfQuestionList(field['id'])}

                    {this.state.showiftrigger !== null &&
                    this.renderShowIfTrigger(this.state.showiftrigger)
                    }

                    </Col>
                </Row>
                </div>
                )
        }

        if (field['type'] === "contentlist"){
            return(
                <div style={{display:"flex", flexDirection:"column"}}>
                    <Row>
                    <Col>
                    <p><small>Trigger Question</small></p>

                    {this.getShowIfQuestionList(field['id'])}

                    {this.state.showiftrigger !== null &&
                    this.renderShowIfTrigger(this.state.showiftrigger)
                    }

                    </Col>
                </Row>
        </div>
                )
        }

    if (field['type']=== "jumbotron"){
        return(
            <div>
                <Row>
                    <Col>
                    <p><small>Trigger Question</small></p>

                    {this.getShowIfQuestionList(field['id'])}

                    {this.state.showiftrigger !== null &&
                    this.renderShowIfTrigger(this.state.showiftrigger)
                    }

                    </Col>
                </Row>
            </div>
            )
    }

    if (field['type'] === 'jumbotron2'){
        return(
            <div>
                <p></p>
                <Row>
                    <Col>
                    <p><small>Trigger Question</small></p>

                    {this.getShowIfQuestionList(field['id'])}

                    {this.state.showiftrigger !== null &&
                    this.renderShowIfTrigger(this.state.showiftrigger)
                    }

                    </Col>
                </Row>
            </div>
        )
    }


    // the final bracket for the showif  }

    }

        /// slider and range
        handleShowIfSliderChange = (selectvalue:any, action: any) => {
            console.log('slider range', selectvalue, action)
            this.changeShowIfSelection("slider-range", selectvalue)
        };
        
        ///select
        handleShowIfSelectChange = (selectvalue:any, action: any) => {
            console.log('selection', selectvalue, action)
            this.changeShowIfSelection(action.name, selectvalue)
        };

        /// callback:  used by RADIO and CHECKBOX
        handleShowIfRadioCheckChange = (event) => {
            const target = event.target;
            if(target != null){
                let type = target.type
                let name = target.name
                let value = target.value
                let id = target.id
                if(type==='radio'){
                    let newvals = this.state.question.showif
                    for (let p in newvals['value'])
                        {
                        newvals['value'][p]=false
                        }
                    newvals['value'][value]=true
                    this.setState({showifvalue:newvals})

                } else {
                    this.setState(prevState => ({
                        question: {...prevState.question,
                            showif: {...prevState.question.showif,
                                value:{...prevState.question.showif.value,
                                    [value]: !this.state.question.showif.value[value]}}}}))
                    }
            }
            }

        /// update state from toggle, select, slider, range
        changeShowIfSelection(i, e, t){
            this.setState(prevState => ({
                question: {...prevState.question,
                    showif: {...prevState.question.showif,
                        value: e}}}))
        }

        /// gets the html content from survey text editor
        getHTML(h){
            this.setState(prevState => ({
                question: {...prevState.question,
                    text: h}}))
        }

        deleteItem(){
            let cardprops = {}
            cardprops['type']=this.props.modalediting
            cardprops['questionId']=this.props.page.questions[0].questionId
            cardprops['cardId']=this.props.currentcard
            cardprops['questionId']=this.props.currentquestion
            this.props.deleteItem(cardprops)
        }

        saveItem(){
            let card = {}
            card['type']=this.props.modalediting
            card['questionId']=this.props.page.questions[0].questionId
            card['cardId']=this.props.currentcard
            card['questionId']=this.props.currentquestion
            card['question'] = this.state.question
            console.log('updating this thang.....................', card)
            this.props.saveItem(card)
        }
    



  /// this determines which selection component to use in the cards
  renderSelectType(field){

    /// if(this.showField(this.props.selectvalue[field['showif']['id']], field['showif']['value'])){

    if (field['type'] === "html"){
        return(
            <div style={{width:"100%"}}>
            <SurveyTextEditor
            content = {field.text}
            saveHtml={this.state.saveHtml}
            getHTML={this.getHTML}/>
            </div>
        )
        }

    if (field['type'] === "select"){
         return(
           <div className="editor-content-item-modal">
            <p><small>Question Text</small></p>
            <Input autofocus type="textarea" rows="2" name={field.id} id={field.id}
            value={this.state.question.header}
            style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
            onChange={this.updateQuestion}
            />
            <Row>
            <Col>
                    <p><small>Edit Select Options</small></p>
                    <CreatableSelect
                    isMulti
                    defaultValue={this.state.question.selectoptions}
                    onChange={this.updateOptions}
                    options={this.state.question.selectoptions}
                    name={field.id}
                    styles={customStyles2(true)}
                    />
                </Col>
                <Col>
                    <p><small>Your Select Widget</small></p>
                    <Select
                    defaultValue={this.state.selectvalue}
                    onChange={this.handleSelectChange}
                    options={this.state.question.selectoptions}
                    name={field.id}
                    styles={customStyles(true)}  /// false means there is an error and will error style
                />
                </Col>
            </Row>
        </div>


        )
    }

    if (field['type']  === "selectcreatable"){
        return(
            <div className="editor-content-item-modal">
            <p><small>Question Text</small></p>
            <Input autofocus type="textarea" rows="2" name={field.id} id={field.id}
            value={this.state.question.header}
            style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
            onChange={this.updateQuestion}
            />
            <Row>
                <Col>
                    <p><small>Edit Select Options</small></p>
                    <CreatableSelect
                    isMulti
                    defaultValue={this.state.question.selectoptions}
                    onChange={this.updateOptions}
                    options={this.state.question.selectoptions}
                    name={field.id}
                    styles={customStyles2(true)}
                    />
                </Col>
                <Col>
                    <p><small>Your Select-Creatable Widget</small></p>
                    <CreatableSelect
                    defaultValue={this.state.selectvalue}
                    onChange={this.handleSelectChange}
                    options={this.state.question.selectoptions}
                    name={field.id}
                    styles={customStyles(true)}
                />
                </Col>
            </Row>
        </div>
        )
    }

    if (field['type']  === "multiselect"){
        return(
            <div className="editor-content-item-modal">
            <p><small>Question Text</small></p>
            <Input autofocus type="textarea" rows="2" name={field.id} id={field.id}
            value={this.state.question.header}
            style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
            onChange={this.updateQuestion}
            />
            <Row>
                <Col>
                    <p><small>Edit Select Options</small></p>
                    <CreatableSelect
                    isMulti
                    defaultValue={this.state.question.selectoptions}
                    onChange={this.updateOptions}
                    options={this.state.question.selectoptions}
                    name={field.id}
                    styles={customStyles2(true)}
                    />
                </Col>
                <Col>
                    <p><small>Your MultiSelect Widget</small></p>
                    <Select
                    isMulti
                    defaultValue={this.state.selectvalue}
                    onChange={this.handleSelectChange}
                    options={this.state.question.selectoptions}
                    name={field.id}
                    styles={customStyles(true)} 
                />
                </Col>
            </Row>
        </div>
        )
    }

    if (field['type']  === "multiselectcreatable"){
        return(
            <div className="editor-content-item-modal">
            <p><small>Question Text</small></p>
            <Input autofocus type="textarea" rows="2" name={field.id} id={field.id}
            value={this.state.question.header}
            style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
            onChange={this.updateQuestion}
            />
            <Row>
                <Col>
                    <p><small>Edit Select Options</small></p>
                    <CreatableSelect
                    isMulti
                    defaultValue={this.state.question.selectoptions}
                    onChange={this.updateOptions}
                    options={this.state.question.selectoptions}
                    name={field.id}
                    styles={customStyles2(true)}
                    />
                </Col>
                <Col>
                    <p><small>Your MultiSelect-Creatable Widget</small></p>
                    <CreatableSelect
                    isMulti
                    defaultValue={this.state.selectvalue}
                    onChange={this.handleSelectChange}
                    options={this.state.question.selectoptions}
                    name={field.id}
                    styles={customStyles(true)}  /// false means there is an error and will error style
                />
                </Col>
            </Row>
        </div>
        )
    }

    if (field['type']  === "toggle"){
        return(
            <div className="editor-content-item-modal">
            <p><small>Question Text</small></p>
            <Input autofocus type="textarea" rows="2" name={field.id} id={field.id}
            value={this.state.question.header}
            style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
            onChange={this.updateQuestion}
            />
            <p><small>Toggle</small></p>
            <Toggle
            id={field.id}
            value={field.togglevalue}
            icons={{unchecked: null}}
            className = {field.togglestyle}
            onChange = {() => this.changeSelection(field.id, !this.state.selectvalue[field.id])}
        />
        </div>
        )
    }

    if (field['type']  === "textfield"){
        return(
            <div className="editor-content-item-modal">
            <p><small>Question Text</small></p>
            <Input autofocus type="textarea" rows="2" name={field.id} id={field.id}
            value={this.state.question.header}
            style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
            onChange={this.updateQuestion}
            />
            <p><small>Text Field</small></p>
        
            <Input name={field.id} id={field.id} type="text" onBlur={this.handleFieldChange}/>

        </div>
        )
    }

    if (field['type']  === "textarea"){
        return(
        <div className="editor-content-item-modal">
            <p><small>Question Text</small></p>
            <Input autofocus type="textarea" rows="2" name={field.id} id={field.id}
            value={this.state.question.header}
            style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
            onChange={this.updateQuestion}
            />
            <p><small>Text Area</small></p>
     
            <Input name={field.id} id={field.id} type="textarea" rows="3" onBlur={this.handleFieldChange}/>
       
        </div>
    )
    }

    if (field['type']  === "checkbox"){
      return(
        <div className="editor-content-item-modal">
        <p><small>Question Text</small></p>
        <Input autofocus type="textarea" rows="2" name={field.id} id={field.id}
        value={this.state.question.header}
        style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
        onChange={this.updateQuestion}
        />
        <Row>
            <Col>
                <p><small>Selection Options</small></p>
                <CreatableSelect
                isMulti
                defaultValue={this.state.question.selectoptions}
                onChange={this.updateOptions}
                options={this.state.question.selectoptions}
                name={field.id}
                styles={customStyles2(true)}
                />
            </Col>
            <Col>
                <p><small>Your Checkbox Widget</small></p>
                <Form>
                    <FormGroup inline name={field.id} onChange={this.handleRadioCheckChange}>
                        {this.state.question.selectoptions.map((item, key=item.id)=>this.getCheckboxItems(item, "checkbox"))}
                    </FormGroup>
                </Form>
            </Col>
        </Row>
       
    </div>
      )
    }

    if (field['type']  === "radio"){
      return(
        <div className="editor-content-item-modal">
        <p><small>Question Text</small></p>
        <Input autofocus type="textarea" rows="2" name={field.id} id={field.id}
        value={this.state.question.header}
        style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
        onChange={this.updateQuestion}
        />
        <Row>
            <Col>
                <p><small>Selection Options</small></p>
                <CreatableSelect
                isMulti
                defaultValue={this.state.question.selectoptions}
                onChange={this.updateOptions}
                options={this.state.question.selectoptions}
                name={field.id}
                styles={customStyles2(true)}
                />
            </Col>
            <Col>
                <p><small>Your Radio Button Widget</small></p>
                <Form>
                    <FormGroup inline name={field.id} onChange={this.handleRadioCheckChange}>
                        {this.state.question.selectoptions.map((item, key=item.id)=>this.getCheckboxItems(item, "radio"))}
                    </FormGroup>
                </Form>
            </Col>
        </Row>
    </div>
      )
    }

    if (field['type']  === "slider"){
        return(
            <div className="editor-content-item-modal">
            <p><small>Question Text</small></p>
            <Input autofocus type="textarea" rows="2" name={field.id} id={field.id}
            value={this.state.question.header}
            style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
            onChange={this.updateQuestion}
            />
          <div style={{marginLeft:"15px", marginRight:"15px", marginBottom:"15px"}}>
          <Slider
            min={field['slidermin']}
            max={field['slidermax']}
            step={field['sliderstep']}
            marks={field['slidermarks']}
            dots={field['sliderticks']}
            defaultValue={field['sliderdefault']}
            id={field['id']}
            onChange={()=>this.handleSliderChange}
            />
          </div>
          <Row style={{marginTop:"25px"}}>
            <Col>
            <p><small>Min</small></p>
            <Input type="number" max={this.state.question.slidermax} name={"slidermin"} id={field.id}
            value={this.state.question.slidermin}
            style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
            onChange={this.updateItemProps}
            />
                </Col>
                <Col>
                <p><small>Max</small></p>
            <Input type="number" min={this.state.question.slidermin} name={"slidermax"} id={field.id}
            value={this.state.question.slidermax}
            style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
            onChange={this.updateItemProps}
            />
                </Col>
                <Col>
                <p><small>Step</small></p>
            <Input type="mumber" max={this.state.question.slidermax} name={"sliderstep"} id={field.id}
            value={this.state.question.sliderstep}
            style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
            onChange={this.updateItemProps}
            />
                </Col>
            </Row>

            {this.state.slidermarks && this.getSliderMarks()}
   
          </div>
        )
      }

      if (field['type']  === "range"){



        return(

            <div className="editor-content-item-modal">
            <p><small>Question Text</small></p>
            <Input autofocus type="textarea" rows="2" name={field.id} id={field.id}
            value={this.state.question.header}
            style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
            onChange={this.updateQuestion}
            />
          <div style={{marginLeft:"15px", marginRight:"15px", marginBottom:"15px"}}>
          <Range
            min={field['slidermin']}
            max={field['slidermax']}
            step={field['sliderstep']}
            marks={field['slidermarks']}
            dots={field['sliderticks']}
            defaultValue={field['rangedefault']}
            onChange={()=>this.handleSliderChange}
            id={field['id']}
            />
          </div>
          <Row style={{marginTop:"25px"}}>
            <Col>
            <p><small>Min</small></p>
            <Input type="number" max={this.state.question.slidermax} name={"slidermin"} id={field.id}
            value={this.state.question.slidermin}
            style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
            onChange={this.updateItemProps}
            />
                </Col>
                <Col>
                <p><small>Max</small></p>
            <Input type="number" min={this.state.question.slidermin} name={"slidermax"} id={field.id}
            value={this.state.question.slidermax}
            style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
            onChange={this.updateItemProps}
            />
                </Col>
                <Col>
                <p><small>Step</small></p>
            <Input type="mumber" max={this.state.question.slidermax} name={"sliderstep"} id={field.id}
            value={this.state.question.sliderstep}
            style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
            onChange={this.updateItemProps}
            />
                </Col>
            </Row>

            {this.state.slidermarks && this.getSliderMarks()}
   
          </div>
        )
      }

    if (field['type'] === "img"){
      return(
          <div>
          <img width="100%" src={field.img} alt="Survey Image" />
          <p><small>Image Location</small></p>
            <Input autofocus type="textarea" rows="1" name={"img"} id={field.id}
            value={this.state.question.img}
            style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
            onChange={this.updateItemProps}
            />
          </div>
      )
    }

    if (field['type'] === "bigimg"){
    return(
        <div tag="a"  onClick={() => this.props.toggleLightbox(field['id'])} style={{cursor: "pointer"}} >
        <img width="100%" src={field['img']} alt="Survey Image" />
        <p><small>Image Location</small></p>
            <Input autofocus type="textarea" rows="1" name={"img"} id={field.id}
            value={this.state.question.img}
            style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
            onChange={this.updateItemProps}
            />
            <p><small>Image Title</small></p>
            <Input type="textarea" rows="1" name={"imageTitle"} id={field.id}
            value={this.state.question.imgTitle}
            style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
            onChange={this.updateItemProps}
            />
            <p><small>Image Caption</small></p>
            <Input type="textarea" rows="2" name={"imageCaption"} id={field.id}
            value={this.state.question.imgCaption}
            style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
            onChange={this.updateItemProps}
            />
            
        </div>
    )
    }

    if (field['type'] === "gif"){
            return(
                <div style={{cursor: "pointer"}}>
                <GifPlayer
                    gif={field['gif']}
                    width="100%"
                    />
                <p><small>Gif file location</small></p>
                <Input autofocus type="textarea" rows="1" name={"gif"} id={field.id}
                value={this.state.question.gif}
                style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
                onChange={this.updateItemProps}
                />
        </div>
        )
    }

    if (field['type'] === "video"){
        return(
            <div>
            <ReactPlayer
                url={field['video']}
                controls='true'
                muted='true'
                width='100%'
                />
                <p><small>Youtube or Vimeo file location</small></p>
                <Input autofocus type="textarea" rows="1" name={"video"} id={field.id}
                value={this.state.question.video}
                style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
                onChange={this.updateItemProps}
                />
            </div>
            )
    }

    if (field['type'] === "contentlist"){
        if(field['list'].length === 0){this.addListItem()}
        return(
            <div style={{display:"flex", flexDirection:"column"}}>
            <ListGroup className="editor-content-item-modal">
                {this.state.question.list.map((item, key=item.id)=>this.renderListItem(item.header, item.detail, item.id))}
            </ListGroup>
            <span onClick={()=>{this.addListItem()}} style={{alignSelf:"center", cursor:"pointer"}}><i className="icon-plus" style={{color:"#20a8d8", fontSize:"1em", marginRight:"5px"}} ></i></span>
            </div>
            )
    }

  if (field['type']=== "jumbotron"){
      return(
        <div>
          <Jumbotron fluid >
            <div><h1>{field.headline}</h1></div>
            <div><h4>{field.subheadline}</h4></div>
          </Jumbotron>
          
          <p><small>Headline</small></p>
          <Input autofocus type="textarea" rows="1" name={"headline"} id={field.id}
          value={this.state.question.headline}
          style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
          onChange={this.updateItemProps}
          />
          <p><small>Subheadline</small></p>
          <Input autofocus type="textarea" rows="2" name={"subheadline"} id={field.id}
          value={this.state.question.subheadline}
          style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
          onChange={this.updateItemProps}
          />

        </div>
          )
  }

  if (field['type'] === 'jumbotron2'){
      return(
        <div>
          <Jumbotron className="editor-content-item-jumbotron2" >
              <h1>{field.headline}</h1>
              <h4>{field.subheadline}</h4>
              <hr/>
              <p style={{marginTop:"15px"}}>{field.bodytext}</p>
          </Jumbotron>
          <p><small>Headline</small></p>
          <Input autofocus type="textarea" rows="1" name={"headline"} id={field.id}
          value={this.state.question.headline}
          style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
          onChange={this.updateItemProps}
          />
          <p><small>Subheadline</small></p>
          <Input autofocus type="textarea" rows="2" name={"subheadline"} id={field.id}
          value={this.state.question.subheadline}
          style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
          onChange={this.updateItemProps}
          />
          <p><small>Body Text</small></p>
          <Input autofocus type="textarea" rows="3" name={"bodytext"} id={field.id}
          value={this.state.question.bodytext}
          style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
          onChange={this.updateItemProps}
          />

        </div>
      )
  }


// the final bracket for the showif  }

}



  render(){

    return(
    <div>
        <ModalHeader style={{backgroundColor:"#2f353a", color:"#8f9ba6", fontSize:"1.2em", fontWeight:"200"}}>
            {`SurveyQuestion ${this.props.currentquestion}`}
            {this.getShowIf()}
            {this.state.isdeleting &&
            <p style={{color:'#f86c6b', fontSize:"0.8em", fontWeight:"200", marginTop:"0px"}}>Please confirm that you want to delete this item</p>
            }
            </ModalHeader>
        <ModalBody>

            {!this.state.showiftoggle &&
            this.renderSelectType(this.state.question)
            }

            {this.state.showiftoggle &&
            this.renderShowIfEditor(this.state.question)}

            
            <div style={{display:"flex", flexDirection:"row",  padding:"30px", marginLeft:"-16px", marginRight:"-16px", marginBottom:"-16px", marginTop:"50px", color:"white", fontSize:"0.8em", backgroundColor:"#2f353a" }}>
                
                {!['img', 'bigimg', 'html', 'video', 'gif', 'jumbotron', 'jumbotron2', 'contentlist'].includes(this.state.question.type)  &&
                <div style={{flexDirection:"column", alignItems:"flex-end", marginLeft:"15px"}}>
                <p>Required</p>
                <Toggle
                    id={"requiredtoggle"}
                    checked= {this.state.requiredtoggle}
                    icons={{unchecked: null}}
                    className = {"custom-success"}
                    onChange = {() => this.handleToggleChange('requiredtoggle')}
                        />
                </div>
                }

                <div style={{flexDirection:"column", alignItems:"flex-end", marginLeft:"15px"}}>
                <p>Show If </p>
                <Toggle
                    id={"pagehint"}
                    checked= {this.state.showiftoggle}
                    icons={{unchecked: null}}
                    className = {"custom-success"}
                    onChange = {() => this.handleToggleChange('showiftoggle')}
                        />
                </div>

                {['select', 'selectcreatable', 'multiselect', 'multiselectcreatable'].includes(this.state.question.type) &&
                <div style={{display:"flex"}}>
                <div style={{borderLeft: "1px", borderRight:"0px", borderTop:"0px", borderBottom:"0px", borderStyle:"dashed", borderColor:"#5c6873", marginLeft:"15px"}}></div>
                <div style={{flexDirection:"column", alignItems:"flex-end", marginLeft:"15px"}}>
                <p>Multi Select</p>
                <Toggle
                    id={"multiselect"}
                    checked= {this.state.selectmulti}
                    icons={{unchecked: null}}
                    className = {"custom-success"}
                    onChange = {() => this.handleToggleChange('selectmulti')}
                        />
                </div>
                <div style={{flexDirection:"column", alignItems:"flex-end", marginLeft:"15px"}}>
                <p>Creatable </p>
                <Toggle
                    id={"creatable"}
                    checked= {this.state.selectcreate}
                    icons={{unchecked: null}}
                    className = {"custom-success"}
                    onChange = {() => this.handleToggleChange('selectcreate')}
                        />
                </div>
                </div>
                }

                {['jumbotron', 'jumbotron2'].includes(this.state.question.type) &&
                    <div style={{display:"flex"}}>
                    <div style={{borderLeft: "1px", borderRight:"0px", borderTop:"0px", borderBottom:"0px", borderStyle:"dashed", borderColor:"#5c6873", marginLeft:"15px"}}></div>
                    <div style={{flexDirection:"column", alignItems:"flex-end", marginLeft:"15px"}}>
                    <p>Plain Jumbotron</p>
                    <Toggle
                        id={"jumbo1"}
                        checked= {this.state.jumbotron}
                        icons={{unchecked: null}}
                        className = {"custom-success"}
                        onChange = {() => this.handleToggleChange('jumbotron')}
                            />
                    </div>

                    <div style={{flexDirection:"column", alignItems:"flex-end", marginLeft:"15px"}}>
                    <p>Fancy Jumbotron</p>
                    <Toggle
                        id={"jumbo2"}
                        checked= {this.state.jumbotron2}
                        icons={{unchecked: null}}
                        className = {"custom-success"}
                        onChange = {() => this.handleToggleChange('jumbotron2')}
                            />
                    </div>
                    </div>
                }

                {['textfield', 'textarea'].includes(this.state.question.type) &&
                    <div style={{display:"flex"}}>
                    <div style={{borderLeft: "1px", borderRight:"0px", borderTop:"0px", borderBottom:"0px", borderStyle:"dashed", borderColor:"#5c6873", marginLeft:"15px"}}></div>
                    <div style={{flexDirection:"column", alignItems:"flex-end", marginLeft:"15px"}}>
                    <p>Text Field</p>
                    <Toggle
                        id={"multiselect"}
                        checked= {this.state.textfield}
                        icons={{unchecked: null}}
                        className = {"custom-success"}
                        onChange = {() => this.handleToggleChange('textfield')}
                            />
                    </div>

                    <div style={{flexDirection:"column", alignItems:"flex-end", marginLeft:"15px"}}>
                    <p>Text Area </p>
                    <Toggle
                        id={"creatable"}
                        checked= {this.state.textarea}
                        icons={{unchecked: null}}
                        className = {"custom-success"}
                        onChange = {() => this.handleToggleChange('textarea')}
                            />
                    </div>

                    <div style={{flexDirection:"column", alignItems:"flex-end", marginLeft:"15px"}}>
                    <p>Number </p>
                    <Toggle
                        id={"creatable"}
                        checked= {this.state.numberfield}
                        disabled={this.state.textarea}
                        icons={{unchecked: null}}
                        className = {"custom-success"}
                        onChange = {() => this.handleToggleChange('numberfield')}
                            />
                    </div>
                    <div style={{flexDirection:"column", alignItems:"flex-end", marginLeft:"15px"}}>
                    <p>Email </p>
                    <Toggle
                        id={"creatable"}
                        checked= {this.state.emailfield}
                        disabled={this.state.textarea}
                        icons={{unchecked: null}}
                        className = {"custom-success"}
                        onChange = {() => this.handleToggleChange('emailfield')}
                            />
                    </div>
                    <div style={{flexDirection:"column", alignItems:"flex-end", marginLeft:"15px"}}>
                    <p>Date </p>
                    <Toggle
                        id={"creatable"}
                        checked= {this.state.datefield}
                        disabled={this.state.textarea}
                        icons={{unchecked: null}}
                        className = {"custom-success"}
                        onChange = {() => this.handleToggleChange('datefield')}
                            />
                    </div>
                </div>
                } 

                {['checkbox', 'radio'].includes(this.state.question.type) &&
                <div style={{display:"flex"}}>
                <div style={{borderLeft: "1px", borderRight:"0px", borderTop:"0px", borderBottom:"0px", borderStyle:"dashed", borderColor:"#5c6873", marginLeft:"15px"}}></div>
                <div style={{flexDirection:"column", alignItems:"flex-end", marginLeft:"15px"}}> 
                <p>Checkbox</p>
                <Toggle
                    id={"ischeckbox"}
                    checked= {this.state.checkbox}
                    icons={{unchecked: null}}
                    className = {"custom-success"}
                    onChange = {() => this.handleToggleChange('checkbox')}
                        />
                </div>

                <div style={{flexDirection:"column", alignItems:"flex-end", marginLeft:"15px"}}>
                <p>Radio</p>
                <Toggle
                    id={"isradio"}
                    checked= {this.state.radio}
                    icons={{unchecked: null}}
                    className = {"custom-success"}
                    onChange = {() => this.handleToggleChange('radio')}
                        />
                </div>
                </div>
                }

                {['slider', 'range'].includes(this.state.question.type) &&
                <div style={{display:"flex"}}>
                <div style={{borderLeft: "1px", borderRight:"0px", borderTop:"0px", borderBottom:"0px", borderStyle:"dashed", borderColor:"#5c6873", marginLeft:"15px"}}></div>
                <div style={{flexDirection:"column", alignItems:"flex-end", marginLeft:"15px"}}>
                <p>Slider</p>
                <Toggle
                    id={"slider"}
                    checked= {this.state.slider}
                    icons={{unchecked: null}}
                    className = {"custom-success"}
                    onChange = {() => this.handleToggleChange('slider')}
                        />
                </div>

                <div style={{flexDirection:"column", alignItems:"flex-end", marginLeft:"15px"}}>
                <p>Range </p>
                <Toggle
                    id={"range"}
                    checked= {this.state.range}
                    icons={{unchecked: null}}
                    className = {"custom-success"}
                    onChange = {() => this.handleToggleChange('range')}
                        />
                </div>
                <div style={{flexDirection:"column", alignItems:"flex-end", marginLeft:"15px"}}>
                <p>Labels</p>
                <Toggle
                    id={"showlabels"}
                    checked= {this.state.slidermarks}
                    icons={{unchecked: null}}
                    className = {"custom-success"}
                    onChange = {() => this.handleToggleChange('slidermarks')}
                        />
                </div>
                <div style={{flexDirection:"column", alignItems:"flex-end", marginLeft:"15px"}}>
                <p>Show Steps</p>
                <Toggle
                    id={"showeachstep"}
                    checked= {this.state.question.sliderticks}
                    icons={{unchecked: null}}
                    className = {"custom-success"}
                    onChange = {() => this.setState(prevState => ({
                            question: {...prevState.question,           
                               sliderticks: !this.state.question.sliderticks}
                           }))}/>
                </div>
                </div>
                }

                {['img', 'bigimg'].includes(this.state.question.type) &&
                <div style={{display:"flex"}}>
                <div style={{borderLeft: "1px", borderRight:"0px", borderTop:"0px", borderBottom:"0px", borderStyle:"dashed", borderColor:"#5c6873", marginLeft:"15px"}}></div>
                <div style={{flexDirection:"column", alignItems:"flex-end", marginLeft:"15px"}}>
                <p>Standard Image</p>
                <Toggle
                    id={"img"}
                    checked= {this.state.img}
                    icons={{unchecked: null}}
                    className = {"custom-success"}
                    onChange = {() => this.handleToggleChange('img')}
                        />
                </div>

                <div style={{flexDirection:"column", alignItems:"flex-end", marginLeft:"15px"}}>
                <p>Lightbox Image </p>
                <Toggle
                    id={"bigimg"}
                    checked= {this.state.bigimg}
                    icons={{unchecked: null}}
                    className = {"custom-success"}
                    onChange = {() => this.handleToggleChange('bigimg')}
                        />
                </div>
                </div>
                }

                {['video', 'gif'].includes(this.state.question.type) &&
                <div style={{display:"flex"}}>
                <div style={{flexDirection:"column", alignItems:"flex-end", marginLeft:"15px"}}>
                <p>Video</p>
                <Toggle
                    id={"video"}
                    checked= {this.state.video}
                    icons={{unchecked: null}}
                    className = {"custom-success"}
                    onChange = {() => this.handleToggleChange('video')}
                        />
                </div>

                <div style={{flexDirection:"column", alignItems:"flex-end", marginLeft:"15px"}}>
                <p>Gif</p>
                <Toggle
                    id={"gif"}
                    checked= {this.state.gif}
                    icons={{unchecked: null}}
                    className = {"custom-success"}
                    onChange = {() => this.handleToggleChange('gif')}
                        />
                </div>
                </div>
                }

            </div>



        </ModalBody>
        <ModalFooter style={{marginTop:"0"}}>
        <div style={{alignSelf:"flex-start", marginRight:"auto"}}>
        {!this.state.isdeleting &&
        <Button color="danger" onClick={()=>this.setState({isdeleting:true})}>Delete Items</Button>
        }
        {this.state.isdeleting &&
        <Button color="danger" onClick={()=>this.deleteItem()}>Confirm Delete</Button>
        }
        </div>
        {this.state.isdeleting &&
        <Button color="secondary" onClick={()=>this.setState({isdeleting:false})} >Cancel Delete</Button>
        }
         {!this.state.isdeleting &&
        <div>
        {'  '}
        <Button color="primary" onClick={()=>this.saveItem()} >Save</Button>
        {'  '}
        <Button color="secondary" onClick={()=>this.props.toggleModal()} >Cancel</Button>
        </div>
        }
        </ModalFooter>
  </div>



    );
  }
}
import React, { Component } from "react";
import { API } from "aws-amplify";
import {  Button, Input, Form, FormGroup, Card, CardHeader, CardBody, CardFooter, Row, Col, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Jumbotron,  ModalHeader, ModalBody, ModalFooter, CustomInput} from 'reactstrap';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel} from 'react-accessible-accordion';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable'
import Slider, { Range } from 'rc-slider';
import Toggle from 'react-toggle';
import GifPlayer from 'react-gif-player';
import ReactPlayer from 'react-player';
import 'react-accessible-accordion/dist/fancy-example.css'
import  'react-toggle/style.css'
import 'rc-slider/assets/index.css';
import './RenderContent.scss';


/// todo - wire up the image mode:  fit or fill
/// todo - new allocation


/// styling for react-select
const customStyles = (isValid) => ({
    control: (styles: any, state:any) => ({
      ...styles,
      padding: 5,
      width:"100%",
      fontSize: "smaller",
      ...(!isValid && { borderColor: 'red' }),
    }),
  });
/// styling for react-select
const customStylesInModal = (isValid) => ({
    option: (provided, state) => ({
        ...provided,
        fontSize: '1em',
        borderBottom: '1px dotted black',
        backgroundColor: state.isSelected ? '#2f353a' : 'white',
        color: state.isSelected ? '#20a8d8' : '#2f353a',
        padding: 20,
      }),
    control: (styles: any, state:any) => ({
      ...styles,
      fontSize: '0.8em',
      padding: 2,
      width:"100%",
      borderColor:"#20a8d8",
      ...(!isValid && { borderColor: 'red' }),
    }),
  });

    /// styling for react-select
    const customStyles2 = (isValid) => ({
        option: (provided, state) => ({
          ...provided,
          fontSize: '0.9em',
          borderBottom: '1px gray',
          backgroundColor: state.isSelected ? '#2f353a' : 'white',
          color: state.isSelected ? '#20a8d8' : '#2f353a',
          padding: 10,
          overflow: 'visible',
        }),
          control: (styles: any, state:any) => ({
            ...styles,
            color:"#2f353a",
            backgroundColor:"#f0f3f5",
            padding: 5,
            fontSize: "smaller",
            overflow: "visible",
            ...(!isValid && { borderColor: 'red' }),
          }),
        });


  function makeId(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }

 function shuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }

  function getTime(){
    let today = new Date();
    let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    let dateTime = date+' '+time;
    return(dateTime)
  }

  /// disabled allocation!!!!!!!!!!!!!!!!!!
const  selectiontypes = [
    { value: "discrete", label: 'Discrete Choice' },
    { value: "maxdiff", label: 'Maxdiff: Best/Worst'},
    { value: "checkbox", label: 'Checkbox' },
    { value: "radio", label: 'Radio' },
    { value: "select", label: 'Select' },
    { value: "selectcreatable", label: 'Select Creatable' },
    { value: "multiselect", label: 'Multiselect' },
    { value: "multiselectcreatable", label: 'Multiselect Creatable' },
    { value: "slider", label: 'Slider' },
    { value: "range", label: 'Range' },
    { value: "toggle", label: 'Toggle' },
    { value: "textfield", label: 'Text field ' },
    { value: "textarea", label: 'Text area' },
]

export default class ChoiceEditorModal extends Component {
  constructor(props) {
    super(props);
    let option = {}
    let optionindex = null
    for (let x in this.props.page.questions[0].cards){
        if (this.props.page.questions[0].cards[x].id === this.props.currentcard){
            optionindex = x
            option = this.props.page.questions[0].cards[x]
        }
    }
    let initialselections = {}
    let i = this.props.currentcard
    let radiocheck = {}
    initialselections[i] = null
    if(this.props.page.questions[0].questionprops.selectiontype==='toggle'){initialselections[i] = false}
    if(this.props.page.questions[0].selectiontype==='slider'){initialselections[i] = this.props.page.questions[0].questionprops['sliderdefault']}
    if(this.props.page.questions[0].selectiontype==='allocation'){initialselections[i] = this.props.page.questions[0].questionprops['allocation']}
    if(this.props.page.questions[0].selectiontype==='range'){initialselections[i] = this.props.page.questions[0].questionprops['rangedefault']}
    if(['checkbox', 'radio'].includes(this.props.page.questions[0].questionprops.selectiontype))
        {
        for (let s of option['selectoptions']){radiocheck[s['value']]=false}
        initialselections[i]=radiocheck
        }
    let randomizefeatures = option.randomize
    let randomize = this.props.page.questions[0].questionprops.randomize
    let showprice= option.showprice
    let showfeatures= option.showfeatures
    let cardcolor = {}
    cardcolor[option.id]=this.props.surveyprops.questionpropdefaults.noselectcolor
    let features = option.features
    if(option.randomize == true){features= shuffle(option.features)}
    let showquestionhelpmessage = option.showquestionhelpmessage
    let showimage = option.showimage
    let showvideo = option.showvideo
    let showgif = option.showgif
    let selectiontypeindex = null

    for (let x in selectiontypes){
        if(selectiontypes[x].value === this.props.page.questions[0].questionprops.selectiontype){
            selectiontypeindex = x
        }
    }
    let keylist = []
    let marklist = []
    if(this.props.page.questions[0].questionprops.selectiontype==='slider'){
        let sortedmarks = Object.keys(this.props.page.questions[0].questionprops.slidermarks).sort().reduce((acc, key) => ({...acc, [key]: this.props.page.questions[0].questionprops.slidermarks[key]}), {})
        for (let [key, value] of Object.entries(sortedmarks)){
            marklist.push(value)
            keylist.push(key)
        }
        marklist=marklist.toString()
        keylist=keylist.toString()
    }
    if(this.props.page.questions[0].questionprops.selectiontype==='allocation'){
        let sortedmarks = Object.keys(this.props.page.questions[0].questionprops.slidermarks).sort().reduce((acc, key) => ({...acc, [key]: this.props.page.questions[0].questionprops.slidermarks[key]}), {})
        for (let [key, value] of Object.entries(sortedmarks)){
            marklist.push(value)
            keylist.push(key)
        }
        marklist=marklist.toString()
        keylist=keylist.toString()
    }
    if(this.props.page.questions[0].questionprops.selectiontype==='range'){
        let sortedmarks = Object.keys(this.props.page.questions[0].questionprops.slidermarks).sort().reduce((acc, key) => ({...acc, [key]: this.props.page.questions[0].questionprops.slidermarks[key]}), {})
        for (let [key, value] of Object.entries(sortedmarks)){
            marklist.push(value)
            keylist.push(key)
        }
        marklist=marklist.toString()
        keylist=keylist.toString()
    }
    let initialsetup = {}
    initialsetup[this.props.currentcard] = null
    let checklist = []
    if(option.features && option.features.length>0){
        for(let f in option['features']){
            checklist.push('check'+option['features'][f]['id'])
        }}
    this.state = {
        isValid: false,
        isLoading: true,
        cardcopy:option,
        option: option,
        optionindex: optionindex,
        questionprops: JSON.parse(JSON.stringify(this.props.page.questions[0].questionprops)),
        randomize: randomize,
        randomizefeatures: randomizefeatures,
        showprice: showprice,
        showfeatures: showfeatures,
        cardcolor:cardcolor,
        editimageprops: false,
        editchoicetype: false,
        showquestionhelpmessage:showquestionhelpmessage,
        showimage: showimage,
        showvideo: showvideo,
        showgif: showgif,
        testprices: false,
        selectiontypeindex: selectiontypeindex,
        selectvalue: initialselections,
        marklist: marklist,
        keylist:keylist,
        isdeleting:false,
        featuretype: JSON.parse(JSON.stringify(this.props.featuretype)),
        featurelist: JSON.parse(JSON.stringify(this.props.featurelist)),
        checklist:checklist

      };
    }
   
  async componentDidMount(){
  }

       
    /// calls Selection Change callback:  Used by TEXT-FIELD and TEXT-AREA
    handleFieldChange = (event) => {
      const target = event.target;
      let value = target.value;
      const name = target.name;
      this.changeSelection(name, value)
    }

    /// calls Selection Change callback:  Used by  slider range here as it doesnt pass name via action
    handleSliderChange = (selectvalue:any, action: any) => {
        this.changeSelection("slider-range", selectvalue)
      };

    /// calls Selection Change callback:  Used by SELECT and MULTI --- not slider range here as it doesnt pass name via action
    handleSelectChange = (selectvalue:any, action: any) => {
        this.changeSelection(action.name, selectvalue)
    };

    /// calls Selection Change callback:  Used by TOGGLE
    handleToggleSelectionChange(){
        let i = this.state.cardcopy.id
        let e = !this.state.selectvalue[i]
        this.setState(prevState => ({selectvalue: {...prevState.selectvalue, [i]: e}}))
    }

    /// calls Selection Change callback:  Used by TEXT-FIELD and TEXT-AREA
    handleFieldChange = (event) => {
        const target = event.target;
        let value = target.value;
        const name = target.name;
        this.changeSelection(this.state.cardcopy.id, value)
        }

    /// updates users input for everything except radio/check.  i is id;  e is the value
    changeSelection(i, e){

        this.setState(prevState => ({selectvalue: {...prevState.selectvalue, [i]: e}}), )
    }

    /// callback:  used by RADIO and CHECKBOX
    handleRadioCheckChange = (event) => {
        const target = event.target;
        if(target != null){
            let type = target.type
            let id = target.id
            this.toggleRadioCheck(type, id)
        }
        }
         
    /// updates the radio/check option bool toggle.  i is id;  e is the value;  t is type
    toggleRadioCheck(t, i){

        if(t==='radio'){
            let newvals = this.state.selectvalue
            for (let p in newvals)
                {
                newvals[p]=false
                }
            newvals[i]=true

            this.setState({selectvalue:newvals})

        } else {

            this.setState(prevState => ({
                selectvalue: {
                ...prevState.selectvalue,          
                [i]: !this.state.selectvalue[i]       
                }
                })
                )
            }
    }

    /// callback :  Used by DISCRETE and MAXDIFF
    handleMaxDiffChange(e){
        let i = this.state.cardcopy.id
        let newcolor = {}
        let newselections = {}
        let validcheck = false
        let validbest = false
        let validworst = false
    
        for (let b in this.state.selectvalue){
            if (this.state.selectvalue[b]===e){
            newselections[b] = null
            newcolor[b]= this.props.surveyprops.questionpropdefaults.noselectcolor
            } else {
            newselections[b] = this.state.selectvalue[b]
            newcolor[b] = this.state.cardcolor[b]
            }
        }
        
        newselections[i] = e
        if (e=="best"){
        newcolor[i] = this.props.surveyprops.questionpropdefaults.bestselectcolor}
        if (e==="worst"){
        newcolor[i] = this.props.surveyprops.questionpropdefaults.worstselectcolor}
        if (e==="selected"){
            newcolor[i] = this.props.surveyprops.questionpropdefaults.noselectcolor}
        
        for(let c in newselections){
        if(newselections[c] === "selected"){validcheck = true}
        if(newselections[c] === "best"){validbest = true}
        if(newselections[c] === "worst"){validworst = true}
        }
    
        if (validbest && validworst){ validcheck = true}
    
        if(this.state.questionprops.validationtype==="none"){validcheck=true}
    
    
        this.setState({selectvalue:newselections, 
            cardcolor:newcolor,
            isvalid: validcheck
            })
        }
    
    /// callback:  used by ALLOCATION
    handleAllocationChange(id, e) {

          }

    /// use for SHOWIF
    showField(target, test){

      if(test === null){return true}
      
      if(typeof(target)==='object'){

        if(target !== null){
        
          if(Array.isArray(target)){
            let targetlist = []
            for(let i of target){
              targetlist.push(i.value)
            }
            if(targetlist.includes(test.value)){return true} else {return false}}
          
          else {if(target.value === test.value){return true} else {return false}}
        }
        else {return false}
      }
      else {if(test === target){return true} else {return false}}
    }

    updateList = (event) => {
        let listcopy = [...this.state.cardcopy.features]
        let flist = [...this.state.featurelist]
        const target = event.target;
        let value = target.value;
        const name = target.name;
        const id = target.id

        let index = -1
        for(let x in listcopy){
            if(listcopy[x]['id']===id){
                index = x
            }
        }
        let index2 = -1
        for(let x in flist){
            if(flist[x]['featureId']===id){
                index2 = x
            }
        }

        if (index>-1){
            if(name === "Header"){
                listcopy[index].header = value
            } else {
                listcopy[index].detail = value
            }
        }

        if (index2>-1){
            if(name === "Header"){
                flist[index2].header = value
            } else {
                flist[index2].detail = value
            }
        }
        this.setState(prevState => ({
            cardcopy: {...prevState.cardcopy,           
                features: listcopy},
                featurelist:flist
            }))
        }

    deleteListItem = (id) => {
        let listcopy = [...this.state.cardcopy.features]
        let flist = [...this.state.featurelist]
        let checklist = [...this.state.checklist]

        let index = -1
        for(let x in listcopy){
            if(listcopy[x]['id']===id){
                index = x
            }
        }
        let index2 = -1
        for(let x in flist){
            if(flist[x]['featureId']===id){
                index2 = x
            }
        }

        if (index > -1) {
            listcopy.splice(index, 1);
        }
        if (index2 > -1) {
            if(typeof flist[index2]['isNew']!=='undefined'){
                flist.splice(index2, 1);
            }
            
        }
        if (checklist.indexOf(id)>-1){
            checklist.splice(checklist.indexOf(id), 1)
        }


        this.setState(prevState => ({
            cardcopy: {...prevState.cardcopy,           
                features: listcopy},
            featurelist:flist,
            checklist:checklist
            }))
        }


    /// id us used in cardcopy;  but featureId used in featurelist

    addListItem = () => {
        let listcopy = [...this.state.cardcopy.features]
        let flist = [...this.state.featurelist]
        let checklist = [...this.state.checklist]
        let id = makeId(5)
        const detail = ""
        const header = ""
        const price = ""
        const pricemetric = ""
        const featuretype = ""
        const isActive = true
        let item ={}
        item['id']=id
        item['featureId']=id
        item['header']=header
        item['detail']=detail
        item['price']=price
        item['pricemetric']=pricemetric
        item['featuretype']=featuretype
        item['isActive']=isActive
        item['isNew']=true
        listcopy.push(item)
        flist.push(item)
        checklist.push('check'+id)
        this.setState(prevState => ({
            cardcopy: {...prevState.cardcopy,           
                features: listcopy},
            featurelist:flist,
            checklist:checklist
            }))
        }

    updateHeader = (event) => {
        const target = event.target;
        let value = target.value;
        const name = target.name;
        this.setState(prevState => ({
            cardcopy: {...prevState.cardcopy,           
                optionheader: value}
            }))
        }

    updateQuestionProps = (event) => {
        const target = event.target;
        let value = target.value;
        const name = target.name;

        const numericlist = ["slidermin", "slidermax", "sliderstep", "allocation"]
        if (numericlist.includes(name)){
            value = Number(value)
            if(isNaN(value)){value=0}
            if(value<0){value=0}
        }
        
        this.setState(prevState => ({
            questionprops: {...prevState.questionprops,           
                [name]: value}
            }))
        }
    
        /// updates slider props and ensures they are numeric
    updateItemProps = (event) => {
        const target = event.target;
        let value = target.value;
        const name = target.name;
        this.setState(prevState => ({
            cardcopy: {...prevState.cardcopy,           
               [name]: value}
           }))
        }


    /// updates the range defaults to be the latest min/max - will error if leave null
    /// Currently NOT CALLED anywhere....

    updateRangeDefault(){
        let newrange = []
        newrange.push(this.state.questionprops.slidermin)
        newrange.push(this.state.questionprops.slidermax)
        this.setState(prevState => ({
            questionprops: {...prevState.questionprops,           
               rangedefault: newrange}
           }))
    }

    ///  marks are a dict in the slider/range component.  Pulled out into two separate lists so you can add and change key values
    updateSliderMarkProps = (event) => {
        const target = event.target;
        let value = target.value;
        const name = target.name;
        this.setState({[name]: value}, ()=>this.updateSliderMarkState())
    }

    /// works on blur so not trying to update dict with keys as they are being typed.  converts the strings into lists and then creates dict
    /// based on the same order as shown in the respective lists
    /// for some reason not able to access STATE in this function....?...
    updateSliderMarkState(){
        let keylist=this.state.keylist
        let marklist=this.state.marklist
        let newmarks = {}
        keylist = keylist.split(",")
        marklist = marklist.split(",")
        for (let k in keylist){
            if(!isNaN(k)){
            if (typeof marklist[k]==='undefined'){
                newmarks[keylist[k]]=""
            } else {
            newmarks[keylist[k]]=marklist[k]
            }
            }
        }
        this.setState(prevState => ({
            questionprops: {...prevState.questionprops,           
                slidermarks: newmarks}
            }))

        }
        

    updateSelectType = (event) => {
        const target = event.target;
        let value = target.value;
        const name = target.name;
        console.log(name, value)
        this.setState(prevState => ({
            question: {...prevState.question,           
                header: value}
            }))
        }

    updateFeatureType = (selectvalue, action) => {

        let listcopy = [...this.state.cardcopy.features]
        let flist = [...this.state.featurelist]

        let index = -1
        for(let x in listcopy){
            if(listcopy[x]['id']===action.name){
                index = x
            }
        }
        let index2 = -1
        for(let x in flist){
            if(flist[x]['featureId']===action.name){
                index2 = x
            }
        }


        if(index2>-1){
            flist[index2].featuretype = selectvalue['label']
        }
        if (index>-1){
            listcopy[index].featuretype = selectvalue['label']
            this.setState(prevState => ({
                cardcopy: {...prevState.cardcopy,           
                    features: listcopy},
                featurelist:flist,
                }))
            }       

        /// adds a new featuretype into the global reference list
        if (selectvalue['__isNew__']===true){
          
          let ftyperef = this.state.featuretype
          let ftype={}
 ///         ftype['localsavetime']=getTime()
 ///         ftype['email']= this.props.email
 ///         ftype['orgId']=this.props.orgId
          ftyperef.push({'label':selectvalue['label'],'value':selectvalue['value'], 'isNew':true})
 ///         ftype['featuretype']=ftyperef
 ///         let respon = API.put("reference", "/reference", {body:ftype})
            this.setState({featuretype: ftyperef})
        }
        }


    getSliderMarks(){
        return(
        <div>

            <p style={{marginBottom:"5px"}}><small>Label Locations (numeric values, separated by commas)</small></p>
            <Input type="text" name={"keylist"} id={"keylist"}
            value={this.state.keylist.toString()}
            style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
            onChange={this.updateSliderMarkProps}
            />
            <p style={{marginBottom:"5px"}}><small>Label Values (separated by commas)</small></p>
            <Input type="text"  name={"marklist"} id={"marklist"}
            value={this.state.marklist.toString()}
            style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
            onChange={this.updateSliderMarkProps}
            />

        </div>
        )
        }

    /// function to generate the individual radio or checkbox items. i is id;  t is type
    getSelectItems(i, t){

        if(t==="checkbox"){
            return(
            <CustomInput type="checkbox" id={this.props.currentquestion+i['value']} label={i['label']} name={this.props.currentquestion}></CustomInput>
    
            )}
            if(t==="radio"){
            return(
            <CustomInput type="radio" id={this.props.currentquestion+i['value']} label={i['label']} name={this.props.currentquestion}></CustomInput>
            )}

    }


    updateChoiceProps(i){

        const selectypes = ["selectmulti", "selectcreate"]
        const nonselecttypes = ["html", "toggle", "textfield", "textarea", "checkbox", "radio", "slider", "range", "bigimg", "img", "video", "gif", "contentlist", "jumbotron2", "jumbotron"]

        if(i==='textfield'){this.setState({textarea:!this.state.textarea},
            ()=>this.setState(prevState => ({questionprops: {...prevState.questionprops, selectiontype: i}})))}

        if(i==='textarea'){this.setState({textfield:!this.state.textfield},
            ()=>this.setState(prevState => ({questionprops: {...prevState.questionprops, selectiontype: i}}
            ),()=>this.setState({datefield:false, emailfield:false, numberfield:false})
            ))}

        if(i==='checkbox'){this.setState({radio:!this.state.radio},
            ()=>this.setState(prevState => ({questionprops: {...prevState.questionprops, selectiontype: i}})))}

        if(i==='radio'){this.setState({checkbox:!this.state.checkbox},
            ()=>this.setState(prevState => ({questionprops: {...prevState.questionprops, selectiontype: i}})))}

        if(i==='video'){this.setState({gif:!this.state.gif},
            ()=>this.setState(prevState => ({questionprops: {...prevState.questionprops, selectiontype: i}})))}

        if(i==='gif'){this.setState({video:!this.state.video},
            ()=>this.setState(prevState => ({questionprops: {...prevState.questionprops, selectiontype: i}})))}
    

        if(i==='sliderticks'){this.setState(prevState => ({questionprops: {...prevState.questionprops, sliderticks: !this.state.question.sliderticks}}))}

        if(i==='slidermarks'){
            if(this.state.slidermarks===true){
            this.getSliderMarks()
        }}

        if(i==='img'){this.setState({bigimg:!this.state.bigimg},
            ()=>this.setState(prevState => ({questionprops: {...prevState.questionprops, selectiontype: i}})))}
        
        if(i==='bigimg'){this.setState({img:!this.state.img},
            ()=>this.setState(prevState => ({questionprops: {...prevState.questionprops, selectiontype: i}})))}

        if(i==='slider'){this.setState({range:!this.state.range},
            ()=>this.setState(prevState => ({questionprops: {...prevState.questionprops, selectiontype: i}})))}
        
        if(i==='range'){this.setState({slider:!this.state.slider},
            ()=>this.setState(prevState => ({questionprops: {...prevState.questionprops, selectiontype: i}})))}
        
        if(selectypes.includes(i)){
            let newselecttype = null
            if(this.state.selectmulti === true)
            {
                if(this.state.selectcreate=== true){newselecttype = "multiselectcreatable"} 
                else {newselecttype = "multiselect"}
            } 
            else 
            {
                if(this.state.selectcreate===true){newselecttype = "selectcreatable"}
                else {newselecttype = "select"}
            }
            this.setState(prevState => ({questionprops: {...prevState.questionprops, selectiontype: newselecttype}}))
        }

        if(i==='showprice'){this.setState(prevState => ({cardcopy: {...prevState.cardcopy, showprice: this.state.showprice}}))}
        if(i==='randomize'){this.setState(prevState => ({cardcopy: {...prevState.questionprops, randomize: this.state.randomize}}))}
        if(i==='randomizefeatures'){this.setState(prevState => ({cardcopy: {...prevState.cardcopy, randomize: this.state.randomizefeatures}}))}
        if(i==='showfeatures'){this.setState(prevState => ({cardcopy: {...prevState.cardcopy, showfeatures: this.state.showfeatures}}))}
        if(i==='imageFit'){this.setState(prevState => ({cardcopy: {...prevState.imageFit, showfeatures: this.state.imageFit}}))}

        if(i==='showimage'){this.setState(prevState => ({cardcopy: {...prevState.cardcopy, showimage: this.state.showimage}}),this.toggleOffInvalidOptions(['showgif', 'showvideo']))}
        if(i==='showgif'){this.setState(prevState => ({cardcopy: {...prevState.cardcopy, showgif: this.state.showgif}}),this.toggleOffInvalidOptions(['showimage', 'showvideo']))}
        if(i==='showvideo'){this.setState(prevState => ({cardcopy: {...prevState.cardcopy, showvideo: this.state.showvideo}}),this.toggleOffInvalidOptions(['showgif', 'showimage']))}
        
        if(i==='showquestionhelpmessage'){this.setState(prevState => ({cardcopy: {...prevState.cardcopy, showquestionhelpmessage: this.state.showquestionhelpmessage}}))}


    }

    toggleOffInvalidOptions(options){
        for (let o of options){
        this.setState({[o]:false}, ()=>this.setState(prevState => ({cardcopy: {...prevState.cardcopy, [o]: false}})))
        }
    }
    
    /// Changes the state params - AND logic to change the question type
    handleToggleChange(i){

        this.setState({[i]:!this.state[i]}, ()=>this.updateChoiceProps(i))

        }

    /// a function to render the map of list details used for content only items (per ContentCard)
    renderListItem(item){

        let it2 = {'label': item['featuretype'], 'value':item['feturetype']}

        return(
            <div style={{display:"flex", marginTop:"5px"}}>

            <span onClick={()=>{this.deleteListItem(item.id)}} 
            style={{marginLeft:"auto", alignSelf:"center", cursor:"pointer"}}>
            <i className="icon-close" style={{color:"#f86c6b", fontSize:"0.7em", marginRight:"5px"}} ></i>
            </span>

            <ListGroupItem 
                style={{backgroundColor:this.props.surveyprops.style.featurebackgroundcolor, borderColor:this.props.surveyprops.style.featureoutlinecolor,
                marginTop:"0px", marginBottom:"0px", width:"95%", padding:"5px"}}
                key={item.id}>
            <ListGroupItemHeading style={{marginTop:"0px", marginBottom:"0px" }}>
            <Input type="textarea" rows="1" name={'Header'} id={item.id}
                value={item.header}
                placeholder={"Feature Name..."}
                style={{backgroundColor:"#f0f3f5", fontSize:"0.8em", fontWeight:"400"}}
                onChange={this.updateList}
                />
            </ListGroupItemHeading>
            <ListGroupItemText style={{marginTop:"0px", marginBottom:"0px" }}>
            <Input type="textarea" rows="1" name={'Detail'} id={item.id}
                value={item.detail}
                placeholder={"Detail Description..."}
                style={{backgroundColor:"#f0f3f5", fontWeight:"300", fontSize:"0.7em"}}
                onChange={this.updateList}
                />
            </ListGroupItemText>
            <div style={{flexDirection:"column", alignItems:"flex-end", marginLeft:"5px", marginRight:"15px", width:"100%"}}>
            <p style={{fontSize:"0.6em", marginTop:"5px", marginBottom:"5px"}}>Feature Type | Level | Category</p>
                <CreatableSelect
                defaultValue={it2}
                onChange={this.updateFeatureType}
                options={this.state.featuretype}
                name={item.id}
                id={'type'+item.id}
                styles={customStylesInModal(true)}
                />

            </div>
            </ListGroupItem>

        </div>
        )
      }

    getCardFeatures(){
              return(
              <ListGroup className = "survey-feature-list">

              {this.state.cardcopy.features.map((item, key=item.id)=>this.renderListItem(item))}

              {this.state.cardcopy.showprice &&

                <div style={{display:"flex", marginTop:"15px"}}>

                <div style={{width:"95%", marginLeft:"auto"}}>

                    <ListGroupItem style={{backgroundColor:this.props.surveyprops.style.pricebackgroundcolor, color:this.props.surveyprops.style.pricetextcolor}}>
                    <ListGroupItemHeading style={{fontSize:"1.2em", fontWeight:"400"}}>
                      <Input autoFocus type="number" name={'price'} id={'price'}
                        value={this.state.cardcopy.price}
                        style={{backgroundColor:"#f0f3f5", fontSize:"1.2em", fontWeight:"600"}}
                        onChange={this.updateItemProps}
                        />
                    </ListGroupItemHeading>
                      <ListGroupItemText style={{fontSize:"0.8em", fontWeight:"300"}}>
                      <Input autoFocus type="text" name={'pricemetric'} id={'pricemetric'}
                        value={this.state.cardcopy.pricemetric}
                        style={{backgroundColor:"#f0f3f5", fontSize:"0.8em", fontWeight:"400"}}
                        onChange={this.updateItemProps}
                        />
                    </ListGroupItemText>
                    </ListGroupItem>

                </div>
                </div>
                }
                <span onClick={()=>{this.addListItem()}} style={{alignSelf:"center", cursor:"pointer"}}><i className="icon-plus" style={{color:"#20a8d8", fontSize:"1em", marginRight:"5px"}} ></i></span>
              </ListGroup>
              )
    }

    getIfRandom(){
        if(this.state.questionprops.randomize === false){return}
        else {
        return (
            <div style={{fontSize:"0.7em", display:"flex", fontWeight:"300", marginBottom:"0px"}}>
            <p>Cards order is: </p>
            <p style={{color:'#20a8d8', marginRight:"5px"}}>randomized</p>
            <p style={{color:'#20a8d8', marginRight:"5px"}}></p>
            </div>
            )
        }
    }
   
/// changes the question type for the choice cards

    handleChoiceTypeChange = (selectvalue:any, action: any) => {

        if(this.state.questionprops.selectiontype===selectvalue.value){return}
        let selectiontypeindex = null
        for (let x in selectiontypes){
            if(selectiontypes[x].value === selectvalue.value){
                selectiontypeindex = x
            }
        }
        let initialselections = {}
        let i = this.props.currentcard
        let radiocheck = {}
        initialselections[i] = null
        if(selectvalue.value==='toggle'){initialselections[i] = false}
        if(selectvalue.value==='slider'){initialselections[i] = this.state.cardcopy['sliderdefault']}
        if(selectvalue.value==='range'){initialselections[i] = this.state.cardcopy['rangedefault']}
        if(['checkbox', 'radio'].includes(selectvalue.value))
        {
            if(this.state.questionprops['selectoptions'] !== [] )
            {
                for (let s of this.state.questionprops['selectoptions'])
                    {radiocheck[s['value']]=false}
                initialselections[i]=radiocheck
            } 
        }


        if(['slider', 'range'].includes(selectvalue.value))
        {
            let marklist = []
            let keylist = []

            let sortedmarks = Object.keys(this.state.questionprops.slidermarks).sort().reduce((acc, key) => ({...acc, [key]: this.state.questionprops.slidermarks[key]}), {})
            for (let [key, value] of Object.entries(sortedmarks)){
                marklist.push(value)
                keylist.push(key)
            }
        marklist=marklist.toString()
        keylist=keylist.toString()
        this.setState(prevState => ({questionprops: {...prevState.questionprops, selectiontype: selectvalue.value}, selectvalue:initialselections, 
            selectiontypeindex: selectiontypeindex, marklist:marklist, keylist:keylist }))
        return
        }

    this.setState(prevState => ({questionprops: {...prevState.questionprops, selectiontype: selectvalue.value}, selectvalue:initialselections, selectiontypeindex: selectiontypeindex }))
    };



    //// CHECK THIS ONE ????????????????????????????????????????????????????????????????????????

    updateSelectOptions = (selectvalue, action) => {
        let newoptionlist = []
        let newoption = {}
        if(selectvalue !== null){
            for (const o of selectvalue){
                newoption = {}
                newoption['label']=o.label
                newoption['value']=o.value
                newoptionlist.push(newoption)
            }
        } else {newoptionlist =[{}] }
        this.setState(prevState => ({
           questionprops: {...prevState.questionprops,           
                selectoptions: newoptionlist}
            }))
    };



    deleteCard(){
        let cardprops = {}
        cardprops['type']=this.props.modalediting
        cardprops['questionId']=this.props.page.questions[0].questionId
        cardprops['cardId']=this.props.currentcard
        cardprops['questionId']=this.props.currentquestion
        this.props.deleteCard(cardprops)
    }

    saveCard(){
        let card = {}
        card['type']=this.props.modalediting
        card['questionId']=this.props.page.questions[0].questionId
        card['cardId']=this.props.currentcard
        card['questionId']=this.props.currentquestion
        card['card'] = this.state.cardcopy
        card['questionprops'] = this.state.questionprops
        card['featuretype']=this.state.featuretype
        this.props.saveCard(card)
    }


    getChecked(i){
        let ischecked = this.state.checklist.indexOf(i)
        if(ischecked>-1){return true} else {return false}
    }

    addFeature(e, i){
        let featcopy = [...this.state.cardcopy.features]
        let checklist = [...this.state.checklist]
        let itexists = false

        if (featcopy.length > 0){
            for (let x in featcopy){
                if (featcopy[x]['featureId']===i){
                    featcopy.splice(x, 1)
                    itexists = true
                    if (checklist.indexOf('check'+i)>-1){
                        checklist.splice(checklist.indexOf('check'+i), 1)
                    }
                }}}

        if (!itexists){
            if (this.state.featurelist.length >0){
                for (let y in this.state.featurelist){
                    if (this.state.featurelist[y]['featureId']===i){
                        let copywithid = JSON.parse(JSON.stringify(this.state.featurelist[y]))
                        copywithid['id']=copywithid['featureId']
                        featcopy.push(copywithid)
                    checklist.push('check'+i)
                    }}}}

        this.setState(prevState => ({
            cardcopy: {...prevState.cardcopy,           
                 features: featcopy},
            checklist:checklist
                }))
    }



    getFeatureList(item){
        return(
        <CustomInput type="checkbox" 
            id={'check'+item['featureId']} label={item['header']} name={item['featureId']}
            checked={this.getChecked('check'+item['featureId'])}
            style={{fontSize:"0.8em"}} 
            onChange={(e)=>this.addFeature(e, item['featureId'])}
        ></CustomInput>
        )
    }

    getAccordionItem(item){
        let itemlist = []
        for(let x in this.state.featurelist){
            if (!["None", null].includes(this.state.featurelist[x]['featuretype'])){
                if (this.state.featurelist[x]['featuretype']===item.label){
                    itemlist.push(this.state.featurelist[x])
                }
            }
        }

        return(
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton style={{fontSize:"0.8em"}}>
                       {item.label}
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel style={{fontSize:"0.8em"}}>

                {itemlist.map((item, key=item.featureId) => (this.getFeatureList(item)))}

                </AccordionItemPanel>
            </AccordionItem>
        )
    }


    selectFeatures(){

        if(this.state.featurelist !== null && this.state.cardcopy.showfeatures){

            let nonelist = []
            for(let x in this.state.featurelist){
                if ([null, "None"].includes(this.state.featurelist[x]['featuretype'])){
                    nonelist.push(this.state.featurelist[x])
                }
            }

            return(

                <div style={{display:"flex", flexDirection:"column", width:"30%", marginLeft:"15px", marginRight:"-16px", 
                marginTop:"-17px", marginBottom:"-50px", padding:"10px", backgroundColor:"#c8ced3"}}>

                <Card className="survey-choice-card" style={{marginTop:"5px", backgroundColor:"transparent"}}>
                
                <CardHeader style={{backgroundColor:"transparent"}}>
                <h5 style={{fontSize:"1.2em", fontWeight:"300", marginBottom:"10px"}}>Feature List</h5>
                </CardHeader>
          
                <Accordion
                    allowMultipleExpanded={true}
                    allowZeroExpanded={true}
                    >

                {this.state.featuretype.map((item, key=item.label) => (this.getAccordionItem(item)))}


                {nonelist.length >0 &&

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton style={{fontSize:"0.8em"}}>
                        {"No Category"}
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel style={{fontSize:"0.8em"}}>

                    {nonelist.map((item, key=item.featureId) => (this.getFeatureList(item)))}

                    </AccordionItemPanel>
                </AccordionItem>

                }

                </Accordion>
                </Card>

                </div>
                
            )
    }
    }



    /// called from render - this determines which selection component to use in the card footer
    renderSelectType(){

        if (this.state.questionprops.selectiontype === "select"){
            return(
            <Row>
                <Col>
                    <Select
                        defaultValue={this.state.selectvalue[this.state.cardcopy.id]}
                        onChange={this.handleSelectChange}
                        options={this.state.questionprops.selectoptions}
                        styles={customStyles2(true)}
                        />
                </Col>
            </Row>
            )
            }
        if (this.state.questionprops.selectiontype === "selectcreatable"){
            return(
                <Row>
                    <Col>
                        <CreatableSelect
                        defaultValue={this.state.selectvalue[this.state.cardcopy.id]}
                        onChange={this.handleSelectChange}
                        options={this.state.questionprops.selectoptions}
                        styles={customStyles2(true)}  
                        />
                    </Col>
                </Row>
            )
            }
        if (this.state.questionprops.selectiontype === "multiselect"){
            return(
                <Row>
                    <Col>
                        <Select
                        defaultValue={this.state.selectvalue[this.state.cardcopy.id]}
                        isMulti
                        onChange={this.handleSelectChange}
                        options={this.state.questionprops.selectoptions}
                        styles={customStyles2(true)}  
                        />
                    </Col>
                </Row>
                
            )
            }   
        if (this.state.questionprops.selectiontype === "multiselectcreatable"){
            return(
                <Row>
                    <Col>
                        <CreatableSelect
                        isMulti
                        defaultValue={this.state.selectvalue[this.state.cardcopy.id]}
                        onChange={this.handleSelectChange}
                        options={this.state.questionprops.selectoptions}
                        styles={customStyles2(true)}  
                        />
                    </Col>
                </Row>
            )
            }
        if (this.state.questionprops.selectiontype === "discrete"){
            return(
                <Row>
                <Col style={{alignSelf:"flex-end"}}>
                <button className="btn btn-pill btn-lg btn-outline-dark" onClick={() => this.handleMaxDiffChange("selected")}>{this.state.questionprops.buttonname}</button>
                </Col>
            </Row>
                
            )
            }

        if (this.state.questionprops.selectiontype === "maxdiff"){
            return(

            <div style={{display: "flex"}}>
            <button className="btn btn-pill btn-lg btn-outline-dark" onClick={() => this.handleMaxDiffChange("best")}>{this.state.questionprops.bestbuttonname}</button>
            <button className="btn btn-pill btn-lg btn-outline-dark" style={{marginLeft:"auto"}} onClick={() => this.handleMaxDiffChange("worst")}>{this.state.questionprops.worstbuttonname}</button>
            </div>

            )
            }

        if (this.state.questionprops.selectiontype === "allocation"){

            return(
                <Row style={{marginTop:"25px"}}>
                <Col>
                <Slider 
                    marks={this.state.questionprops.slidermarks}
                    min={0}
                    max={this.state.questionprops.allocationamount}
                    step={this.state.questionprops.sliderstep}
                    defaultValue={this.state.selectvalue[this.state.cardcopy.id]}
                    onAfterChange={(e)=>this.handleAllocationChange(this.state.cardcopy.id, e)}
                    dotStyle={{borderColor: '#c8ced3'}}
                    activeDotStyle={{borderColor: '#c8ced3'}}
                    />
                </Col>
                </Row>
            )
        }
        if (this.state.questionprops.selectiontype === "toggle"){
        return(
            <div>
            <Toggle
            id={this.state.cardcopy.id}
            value={this.state.cardcopy.togglevalue}
            checked= {this.state.selectvalue[this.state.cardcopy.id]}
            icons={{unchecked: null}}
            className = {this.state.cardcopy.togglestyle}
            onChange = {() => this.handleToggleSelectionChange()}
        />
        </div>
        )
        }
        if (this.state.questionprops.selectiontype === "textfield"){
        return(
            <div>
            <Input 
            name={this.state.cardcopy.id}
            type={"text"} 
            value={this.state.selectvalue[this.state.cardcopy.id]}
            onChange={this.handleFieldChange}/>
            </div>
        )
        }
        if (this.state.questionprops.selectiontype === "textarea"){
        return(
            <div>
            <Input 
            name={this.state.cardcopy.id}
            type={"textarea"} 
            value={this.state.selectvalue[this.state.cardcopy.id]}
            onChange={this.handleFieldChange}/>
            </div>
        )
        }

        if (this.state.questionprops.selectiontype === "checkbox"){
        return(
            <Row>
                <Col>
                    <Form>
                        <FormGroup check inline name={this.state.cardcopy.id} onChange={this.handleRadioCheckChange}>
                            {this.state.questionprops.selectoptions.map((item, key=item.id)=>this.getSelectItems(item, "checkbox"))}
                        </FormGroup>
                    </Form>
                </Col>
            </Row>

        )
    }

        if (this.state.questionprops.selectiontype === "radio"){
        return(
            <Row>
                <Col>
                    <Form>
                        <FormGroup check inline name={this.state.cardcopy.id} onChange={this.handleRadioCheckChange}>
                            {this.state.questionprops.selectoptions.map((item, key=item.id)=>this.getSelectItems(item, "radio"))}
                        </FormGroup>
                    </Form>
                </Col>
            </Row>

        )
        }

        if (this.state.questionprops.selectiontype === "slider"){
        return(
            <div style={{  margin: 20 }}>
            <p style={{marginBottom:"5px"}}><small>Your Slider Widget</small></p>
            <Slider 
            marks={this.state.questionprops.slidermarks}
            min={this.state.questionprops.slidermin}
            max={this.state.questionprops.slidermax}
            step={this.state.questionprops.sliderstep}
            defaultValue={this.state.selectvalue[this.state.cardcopy.id]}
            onAfterChange={this.handleSliderChange}
            dotStyle={{borderColor: '#c8ced3'}}
            activeDotStyle={{borderColor: '#c8ced3'}}
            />
    
            </div>
        )
        }
        if (this.state.questionprops.selectiontype === "range"){
        return(
            <div style={{  margin: 20 }}>
            <p style={{marginBottom:"5px"}}><small>Your Range Widget</small></p>
            <Range 
            marks={this.state.questionprops.slidermarks}
            min={this.state.questionprops.slidermin}
            max={this.state.questionprops.slidermax}
            step={this.state.questionprops.sliderstep} 
            defaultValue={this.state.selectvalue[this.state.cardcopy.id]}
            tipFormatter={value => `${value}`}
            onAfterChange={this.handleSliderChange}
            />

            </div>
        )
        }


    }

    /// is the question widget and properties that is edited in the modal
    renderSelectTypeInEditMode(){

        if (this.state.questionprops.selectiontype === "select"){
                return(
                <Row>
                <Col>
                        <p style={{marginBottom:"5px"}}><small>Add/Edit Select Options</small></p>
                        <CreatableSelect
                        isMulti
                        defaultValue={this.state.questionprops.selectoptions}
                        onChange={this.updateSelectOptions}
                        options={this.state.questionprops.selectoptions}
                        name={this.state.cardcopy.id}
                        styles={customStyles2(true)}
                        />
                    </Col>
                    <Col>
                        <p style={{marginBottom:"5px"}}><small>Your Select Widget</small></p>
                        <Select
                        defaultValue={this.state.selectvalue[this.state.cardcopy.id]}
                        onChange={this.handleSelectChange}
                        options={this.state.questionprops.selectoptions}
                        styles={customStyles(true)}
                        />
                    </Col>
                </Row>
            )
            }
        if (this.state.questionprops.selectiontype === "selectcreatable"){
            return(
                <Row>
                <Col>
                        <p style={{marginBottom:"5px"}}><small>Add/Edit Select Options</small></p>
                        <CreatableSelect
                        isMulti
                        defaultValue={this.state.questionprops.selectoptions}
                        onChange={this.updateSelectOptions}
                        options={this.state.questionprops.selectoptions}
                        name={this.state.cardcopy.id}
                        styles={customStyles2(true)}
                        />
                    </Col>
                    <Col>
                        <p style={{marginBottom:"5px"}}><small>Your Select-Creatable Widget</small></p>
                        <CreatableSelect
                        defaultValue={this.state.selectvalue[this.state.cardcopy.id]}
                        onChange={this.handleSelectChange}
                        options={this.state.questionprops.selectoptions}
                        styles={customStyles(true)}  
                        />
                    </Col>
                </Row>
            )
            }
        if (this.state.questionprops.selectiontype === "multiselect"){
            return(
                <Row>
                <Col>
                        <p style={{marginBottom:"5px"}}><small>Add/Edit Select Options</small></p>
                        <CreatableSelect
                        isMulti
                        defaultValue={this.state.questionprops.selectoptions}
                        onChange={this.updateSelectOptions}
                        options={this.state.questionprops.selectoptions}
                        name={this.state.cardcopy.id}
                        styles={customStyles2(true)}
                        />
                    </Col>
                    <Col>
                        <p style={{marginBottom:"5px"}}><small>Your MultiSelect Widget</small></p>
                        <Select
                        defaultValue={this.state.selectvalue[this.state.cardcopy.id]}
                        isMulti
                        onChange={this.handleSelectChange}
                        options={this.state.questionprops.selectoptions}
                        styles={customStyles2(true)}  
                        />
                    </Col>
                </Row>
                
            )
            }   
        if (this.state.questionprops.selectiontype === "multiselectcreatable"){
            return(
                <Row>
                <Col>
                        <p style={{marginBottom:"5px"}}><small>Add/Edit Select Options</small></p>
                        <CreatableSelect
                        isMulti
                        defaultValue={this.state.questionprops.selectoptions}
                        onChange={this.updateSelectOptions}
                        options={this.state.questionprops.selectoptions}
                        name={this.state.cardcopy.id}
                        styles={customStyles2(true)}
                        />
                    </Col>
                    <Col>
                        <p style={{marginBottom:"5px"}}><small>Your MultiSelect-Creatable Widget</small></p>
                        <CreatableSelect
                        isMulti
                        defaultValue={this.state.selectvalue[this.state.cardcopy.id]}
                        onChange={this.handleSelectChange}
                        options={this.state.questionprops.selectoptions}
                        styles={customStyles2(true)}  
                        />
                    </Col>
                </Row>
            )
            }
        if (this.state.questionprops.selectiontype === "discrete"){
            return(
                <Row>
                <Col style={{alignSelf:"flex-end"}}>
                <p style={{marginBottom:"5px"}}><small>Button Name for Discrete Choice</small></p>
                <Input type="text" name={"buttonname"} id={this.state.cardcopy.id}
                value={this.state.questionprops.buttonname}
                style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
                onChange={this.updateQuestionProps}
                />
                </Col>
                <Col style={{alignSelf:"flex-end"}}>
                <button className="btn btn-pill btn-lg btn-outline-dark" onClick={() => this.handleMaxDiffChange("selected")}>{this.state.questionprops.buttonname}</button>
                </Col>
            </Row>
                
            )
            }

        if (this.state.questionprops.selectiontype === "maxdiff"){
            return(
            <div>
            <div style={{display: "flex"}}>
            <button className="btn btn-pill btn-lg btn-outline-dark" onClick={() => this.handleMaxDiffChange("best")}>{this.state.questionprops.bestbuttonname}</button>
            <button className="btn btn-pill btn-lg btn-outline-dark" style={{marginLeft:"auto"}} onClick={() => this.handleMaxDiffChange("worst")}>{this.state.questionprops.worstbuttonname}</button>
            </div>
            <Row style={{marginTop:"15px"}}>
                <Col>
                <p style={{marginBottom:"5px"}}><small>Button Name for Positive Choice</small></p>
                <Input type="text" name={"bestbuttonname"} id={this.state.cardcopy.id}
                value={this.state.questionprops.bestbuttonname}
                style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
                onChange={this.updateQuestionProps}
                />
                </Col>
                <Col>
                <p style={{marginBottom:"5px"}}><small>Button Name for Negative Choice</small></p>
                <Input type="text" name={"worstbuttonname"} id={this.state.cardcopy.id}
                value={this.state.questionprops.worstbuttonname}
                style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
                onChange={this.updateQuestionProps}
                />
                </Col>
            </Row>
            </div>
            )
            }

        if (this.state.questionprops.selectiontype === "allocation"){
                return(
                <div style={{  margin: 20 }}>
                <p style={{marginBottom:"5px"}}><small>Allocation Widget</small></p>
                <Slider 
                marks={this.state.questionprops.slidermarks}
                min={this.state.questionprops.slidermin}
                max={this.state.validallocationamount}
                step={this.state.questionprops.sliderstep}
                defaultValue={this.state.selectvalue[this.state.cardcopy.id]}
                onAfterChange={(e)=>this.handleAllocationChange(this.state.cardcopy.id, e)}
                dotStyle={{borderColor: '#c8ced3'}}
                activeDotStyle={{borderColor: '#c8ced3'}}
                />
                    <Row style={{marginTop:"30px"}}>
                    <Col>
                    <p style={{marginBottom:"5px"}}><small>Min</small></p>
                    <Input 
                    disabled
                    type="number" max={0} name={"slidermin"} id={this.state.cardcopy.id}
                    value={0}
                    style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
                    onChange={this.updateQuestionProps}
                    />
                    </Col>
                    <Col>
                    <p style={{marginBottom:"5px"}}><small>Allocation Amount</small></p>
                    <Input type="number" min={this.state.cardcopy.slidermin} name={"allocationamount"} id={this.state.cardcopy.id}
                    value={this.state.questionprops.allocationamount}
                    style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
                    onChange={this.updateQuestionProps}
                    />
                    </Col>
                    <Col>
                    <p style={{marginBottom:"5px"}}><small>Step</small></p>
                    <Input type="mumber" max={this.state.cardcopy.slidermax} name={"sliderstep"} id={this.state.cardcopy.id}
                    value={this.state.cardcopy.sliderstep}
                    style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
                    onChange={this.updateQuestionProps}
                    />
                    </Col>
                    </Row>
    
                    {this.getSliderMarks()}
    
                </div>
                )

        }
        if (this.state.questionprops.selectiontype === "toggle"){
            return(
                <div>
            <p style={{marginBottom:"5px"}}><small>Your Toggle Button</small></p>
                <Toggle
                id={this.state.cardcopy.id}
                value={this.state.cardcopy.togglevalue}
                checked= {this.state.selectvalue[this.state.cardcopy.id]}
                icons={{unchecked: null}}
                className = {this.state.cardcopy.togglestyle}
                onChange = {() => this.handleToggleSelectionChange()}
            />
            </div>
            )
        }
        if (this.state.questionprops.selectiontype === "textfield"){
            return(
                <div>
            <p style={{marginBottom:"5px"}}><small>Your Text Input Field</small></p>
            <Input 
            name={this.state.cardcopy.id}
            type={"text"} 
            value={this.state.selectvalue[this.state.cardcopy.id]}
            onChange={this.handleFieldChange}/>
            </div>
            )
        }
        if (this.state.questionprops.selectiontype === "textarea"){
        return(
            <div>
            <p style={{marginBottom:"5px"}}><small>Your Text Area Input Field</small></p>
            <Input 
            name={this.state.cardcopy.id}
            type={"textarea"} 
            value={this.state.selectvalue[this.state.cardcopy.id]}
            onChange={this.handleFieldChange}/>
            </div>
        )
        }

        if (this.state.questionprops.selectiontype === "checkbox"){
            return(
            <Row>
            <Col>
                    <p style={{marginBottom:"5px"}}><small>Add/Edit Checkbox Options</small></p>
                    <CreatableSelect
                    isMulti
                    defaultValue={this.state.questionprops.selectoptions}
                    onChange={this.updateSelectOptions}
                    options={this.state.questionprops.selectoptions}
                    name={this.state.cardcopy.id}
                    styles={customStyles2(true)}
                    />
                </Col>
                <Col>
                    <p style={{marginBottom:"5px"}}><small>Your Checkbox Widget</small></p>
                    <Form>
                        <FormGroup inline name={this.state.cardcopy.id} onChange={this.handleRadioCheckChange}>
                            {this.state.questionprops.selectoptions.map !== null &&
                            this.state.questionprops.selectoptions.map((item, key=item.id)=>this.getSelectItems(item, "checkbox"))
                            }
                        </FormGroup>
                    </Form>
                </Col>
            </Row>

            )
        }

        if (this.state.questionprops.selectiontype === "radio"){
            return(
            <Row>
            <Col>
                    <p style={{marginBottom:"5px"}}><small>Add/Edit Checkbox Options</small></p>
                    <CreatableSelect
                    isMulti
                    defaultValue={this.state.questionprops.selectoptions}
                    onChange={this.updateSelectOptions}
                    options={this.state.questionprops.selectoptions}
                    name={this.state.cardcopy.id}
                    styles={customStyles2(true)}
                    />
                </Col>
                <Col>
                    <p style={{marginBottom:"5px"}}><small>Your Radio Button Widget</small></p>
                    <Form>
                        <FormGroup inline name={this.state.cardcopy.id} onChange={this.handleRadioCheckChange}>
                            {this.state.questionprops.selectoptions.map !== null &&
                            this.state.questionprops.selectoptions.map((item, key=item.id)=>this.getSelectItems(item, "radio"))
                            }
                        </FormGroup>
                    </Form>
                </Col>
            </Row>

            )
        }

        if (this.state.questionprops.selectiontype === "slider"){
            return(
            <div style={{  margin: 20 }}>
            <p style={{marginBottom:"5px"}}><small>Your Slider Widget</small></p>
            <Slider 
            marks={this.state.questionprops.slidermarks}
            min={this.state.questionprops.slidermin}
            max={this.state.questionprops.slidermax}
            step={this.state.questionprops.sliderstep}
            defaultValue={this.state.selectvalue[this.state.cardcopy.id]}
            onAfterChange={this.handleSliderChange}
            dotStyle={{borderColor: '#c8ced3'}}
            activeDotStyle={{borderColor: '#c8ced3'}}
            />
                <Row style={{marginTop:"30px"}}>
                <Col>
                <p style={{marginBottom:"5px"}}><small>Min</small></p>
                <Input type="number" max={this.state.questionprops.slidermax} name={"slidermin"} id={this.state.cardcopy.id}
                value={this.state.questionprops.slidermin}
                style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
                onChange={this.updateQuestionProps}
                />
                </Col>
                <Col>
                <p style={{marginBottom:"5px"}}><small>Max</small></p>
                <Input type="number" min={this.state.questionprops.slidermin} name={"slidermax"} id={this.state.cardcopy.id}
                value={this.state.questionprops.slidermax}
                style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
                onChange={this.updateQuestionProps}
                />
                </Col>
                <Col>
                <p style={{marginBottom:"5px"}}><small>Step</small></p>
                <Input type="mumber" max={this.state.questionprops.slidermax} name={"sliderstep"} id={this.state.cardcopy.id}
                value={this.state.questionprops.sliderstep}
                style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
                onChange={this.updateQuestionProps}
                />
                </Col>
                </Row>

                {this.getSliderMarks()}

        
            </div>
            )
        }

        if (this.state.questionprops.selectiontype === "range"){
            return(
            <div style={{  margin: 20 }}>
            <p style={{marginBottom:"5px"}}><small>Your Range Widget</small></p>
                <Range 
                marks={this.state.cardcopy.slidermarks}
                min={this.state.cardcopy.slidermin}
                max={this.state.cardcopy.slidermax}
                step={this.state.cardcopy.sliderstep} 
                defaultValue={this.state.selectvalue[this.state.cardcopy.id]}
                tipFormatter={value => `${value}`}
                onAfterChange={this.handleSliderChange}
                />
                <Row style={{marginTop:"25px"}}>
                <Col>
                <p><small>Min</small></p>
                <Input type="number" max={this.state.questionprops.slidermax} name={"slidermin"} id={this.state.cardcopy.id}
                value={this.state.questionprops.slidermin}
                style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
                onChange={this.updateQuestionProps}
                />
                </Col>
                <Col>
                <p><small>Max</small></p>
                <Input type="number" min={this.state.questionprops.slidermin} name={"slidermax"} id={this.state.cardcopy.id}
                value={this.state.questionprops.slidermax}
                style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
                onChange={this.updateQuestionProps}
                />
                </Col>
                <Col>
                <p><small>Step</small></p>
                <Input type="mumber" max={this.state.questionprops.slidermax} name={"sliderstep"} id={this.state.cardcopy.id}
                value={this.state.questionprops.sliderstep}
                style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
                onChange={this.updateQuestionProps}
                />
                </Col>
                </Row>

                {this.getSliderMarks()}


            </div>
            )
        }


    }

  render(){

    return(
    <div>
        <ModalHeader style={{backgroundColor:"#2f353a", color:"#8f9ba6", fontSize:"1.2em", fontWeight:"200"}}>
            {`${this.props.page.pageprops.questiontype} ${this.props.currentcard} ${this.state.questionprops.selectiontype}`}
            {this.getIfRandom()}
            {this.state.isdeleting &&
            <p style={{color:'#f86c6b', fontSize:"0.8em", fontWeight:"200", marginTop:"0px"}}>Please confirm that you want to delete this card</p>
            }
        </ModalHeader>
        <ModalBody >

        <div style={{display:"flex"}}>

        <Card className="survey-choice-card"  
        style={{
        width:"400px"}}>
            
          <CardHeader 
          style={{backgroundColor:this.props.surveyprops.style.cardheadcolor, color:this.props.surveyprops.style.cardheadtextcolor, padding:"5px"}}>
             <h5 style={{fontSize:"1.2em", fontWeight:"300", marginBottom:"0px"}}>

                    <Input type="text" name={"optionheader"} id={"optionheader"}
                    value={this.state.cardcopy.optionheader}
                    placeholder={"Card Title"}
                    style={{marginBottom:"10px", backgroundColor:"transparent", color:this.props.surveyprops.style.cardheadtextcolor, fontSize:"1.2em", fontWeight:"200"}}
                    onChange={this.updateHeader}
                    />
            </h5>
            </CardHeader>
          
          <CardBody style={{backgroundColor: this.state.cardcolor[this.state.cardcopy.id], marginBottom:"0px" }}>

            <div>

            {this.state.cardcopy.showimage && 
            <div>
            <div style={{display:"flex", alignItems:"flex-end"}}>

              <div tag="a"  onClick={() => this.props.toggleLightbox(this.state.cardcopy.imageSrc, this.state.cardcopy.imageTitle, this.state.cardcopy.imageCaption)} style={{cursor: "pointer", width:"30%"}} >
              <img width="100%" src={this.state.cardcopy.img} alt="Survey Image" />
              </div>

              { this.state.questionprops.showLightbox &&
                <p style={{fontSize:"50%"}}>Click to enlarge</p>
              }

                <div style={{flexDirection:"column", width:"70%", marginLeft:"15px"}}>
                    <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Image Location</p>
                    <Input type="text" name={'img'} id={'img'}
                    value={this.state.cardcopy.img}
                    style={{backgroundColor:"#f0f3f5", fontSize:"0.8em", fontWeight:"400"}}
                    onChange={this.updateItemProps}
                    />
                </div>
            </div>

              {this.state.editimageprops &&
                <div style={{flexDirection:"column"}}>
                    <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Image Title</p>
                    <Input type="text" name={'imageTitle'} id={'imageTitle'}
                    value={this.state.cardcopy.imageTitle}
                    style={{backgroundColor:"#f0f3f5", fontSize:"0.8em", fontWeight:"400"}}
                    onChange={this.updateItemProps}
                    />
                    <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Image Caption</p>
                    <Input type="textarea" rows="2" name={'imageCaption'} id={'imageCaption'}
                    value={this.state.cardcopy.imageCaption}
                    style={{backgroundColor:"#f0f3f5", fontSize:"0.8em", fontWeight:"400"}}
                    onChange={this.updateItemProps}
                    />
                </div>
                }
                <hr/>
              </div>
            }


            {this.state.cardcopy.showgif &&

            <div style={{display:"flex", alignItems:"flex-start"}}>
                <div style={{cursor: "pointer", width:"30%"}}>
                <GifPlayer
                    gif={this.state.cardcopy.gif}
                    width="100%"
                    />
                <p><small>Click to play</small></p>
                </div>
                <div style={{flexDirection:"column", width:"70%", marginLeft:"15px"}}>
                    <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Gif Location</p>
                    <Input type="text" name={'gif'} id={'gif'}
                    value={this.state.cardcopy.gif}
                    style={{backgroundColor:"#f0f3f5", fontSize:"0.8em", fontWeight:"400"}}
                    onChange={this.updateItemProps}
                    />
                </div>

            </div>
            }

            {this.state.cardcopy.showvideo &&

            <div style={{display:"flex", alignItems:"flex-start"}}>
                <div style={{width:"30%", height:"100px"}}>
                <ReactPlayer
                    url={this.state.cardcopy.video}
                    controls='true'
                    muted='true'
                    width="100%"
                    height="50%"
                    />
                </div>
                <div style={{flexDirection:"column", width:"70%", marginLeft:"15px"}}>
                    <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Video Location</p>
                    <Input type="text" name={'video'} id={'video'}
                    value={this.state.cardcopy.video}
                    style={{backgroundColor:"#f0f3f5", fontSize:"0.8em", fontWeight:"400"}}
                    onChange={this.updateItemProps}
                    />
                </div>

            </div>
            
            }

            </div>

            {this.state.cardcopy.showfeatures &&  

                this.getCardFeatures()
            }
        
          </CardBody>

          <CardFooter className="survey-choice-card-footer" style={{backgroundColor:this.props.surveyprops.style.cardfootcolor, color: this.props.surveyprops.style.cardfoottextcolor}}>

            {this.state.editchoicetype &&
            this.renderSelectTypeInEditMode()}

            {!this.state.editchoicetype &&
            this.renderSelectType()}

            {this.state.showquestionhelpmessage &&
            <p style={{marginTop:"5px"}}>
                <Input type="text" name={"helpmessage"} id={"helpmessage"}
                    value={this.state.cardcopy.helpmessage}
                    style={{marginBottom:"10px", backgroundColor:"#f0f3f5", fontSize:"1em"}}
                    onChange={this.updateItemProps}
                    />
            </p>
        }

          </CardFooter>

        </Card>           

        {this.selectFeatures()}

        </div>

        <div style={{ padding:"30px", marginLeft:"-16px", marginRight:"-16px", marginBottom:"-16px", marginTop:"50px", color:"white", fontSize:"0.8em", backgroundColor:"#2f353a" }}>
                

                <Row style={{display:"flex", alignItems:"flex-end"}}>
                    <Col>
                    <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Show Image</p>
                    <Toggle
                        id={"showimage"}
                        checked= {this.state.showimage}
                        icons={{unchecked: null}}
                        className = {"custom-success"}
                        onChange = {() => this.handleToggleChange('showimage')}
                            />
                    </Col>

                    <Col>
                    <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Show Video</p>
                    <Toggle
                        id={"showvideo"}
                        checked= {this.state.showvideo}
                        icons={{unchecked: null}}
                        className = {"custom-success"}
                        onChange = {() => this.handleToggleChange('showvideo')}
                            />
                    </Col>

                    <Col>
                    <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Show Gif</p>
                    <Toggle
                        id={"showgif"}
                        checked= {this.state.showgif}
                        icons={{unchecked: null}}
                        className = {"custom-success"}
                        onChange = {() => this.handleToggleChange('showgif')}
                            />
                    </Col>

                    <Col>
                    <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Show Help Message</p>
                    <Toggle
                        id={"showquestionhelpmessage"}
                        checked= {this.state.showquestionhelpmessage}
                        icons={{unchecked: null}}
                        className = {"custom-success"}
                        onChange = {() => this.handleToggleChange('showquestionhelpmessage')}
                            />
                    </Col>

                <Col>
                <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Show Features</p>
                <Toggle
                    id={"showfeatures"}
                    checked= {this.state.showfeatures}
                    icons={{unchecked: null}}
                    className = {"custom-success"}
                    onChange = {() => this.handleToggleChange('showfeatures')}
                        />
                </Col>
                <Col>
                <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Randomize Features</p>
                <Toggle
                    id={"randomizefeatures"}
                    checked= {this.state.randomizefeatures}
                    icons={{unchecked: null}}
                    className = {"custom-success"}
                    onChange = {() => this.handleToggleChange('randomizefeatures')}
                        />
                </Col>

                <Col>
                <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Show Prices</p>
                <Toggle
                    id={"showprice"}
                    checked= {this.state.showprice}
                    icons={{unchecked: null}}
                    className = {"custom-success"}
                    onChange = {() => this.handleToggleChange('showprice')}
                        />
                </Col>
                <Col>
                <p style={{color:"#20a8d8", fontSize:"0.7em", marginBottom:"-2px"}}>For all cards:</p>
                <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Edit Choice Type</p>
                <Toggle
                    id={"editchoicetype"}
                    checked= {this.state.editchoicetype}
                    icons={{unchecked: null}}
                    className = {"custom-success"}
                    onChange = {() => this.handleToggleChange('editchoicetype')}
                        />
                
                </Col>
                </Row>

                {this.state.editchoicetype &&
                <div style={{marginTop:"15px"}}>
                    
                    <hr style={{borderTop: "1px", borderStyle:"dashed", borderColor:"#5c6873"}}></hr>

                    <Select
                    defaultValue={selectiontypes[this.state.selectiontypeindex]}
                    onChange={this.handleChoiceTypeChange}
                    options={selectiontypes}
                    styles={customStylesInModal(true)}
                    />
                </div>
                }

            </div>

        </ModalBody>

        <ModalFooter style={{marginTop:"0"}}>
      <div style={{alignSelf:"flex-start", marginRight:"auto"}}>
        {!this.state.isdeleting &&
        <Button color="danger" onClick={()=>this.setState({isdeleting:true})}>Delete Card</Button>
        }
        {this.state.isdeleting &&
        <Button color="danger" onClick={()=>this.deleteCard()}>Confirm Delete</Button>
        }
      </div>
    {this.state.isdeleting &&
    <Button color="secondary" onClick={()=>this.setState({isdeleting:false})} >Cancel Delete</Button>
    }
    {!this.state.isdeleting &&
    <div>
    {'  '}
    <Button color="primary" onClick={()=>this.saveCard()} >Save</Button>
    {'  '}
        <Button color="secondary" onClick={()=>this.props.toggleModal()} >Cancel</Button>
    </div>
    }
  </ModalFooter>
  </div>
    );
  }
}
import React, { Component } from "react";
import { API } from "aws-amplify";
import Loader from "react-loader-spinner";
import { Button, Card, CardBody, CardGroup, CardHeader, CardFooter, Col, Container, Row, Jumbotron, Modal, ModalHeader, ModalBody, ModalFooter, Input, CustomInput } from 'reactstrap';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel} from 'react-accessible-accordion';
import CreatableSelect from 'react-select/creatable'
import Select from 'react-select/creatable'
import ReactTooltip from 'react-tooltip'
import Toggle from 'react-toggle';
import {BlockPicker} from 'react-color'

/// same as in survey editor
const choicecarddefaults = {
    id: makeId(5),
    showimage: false,
    showgif: false,
    showvideo: false,
    gif: null,
    video: null,
    showfeatures:true,
    optionheader: "",
    img: "",
    imageSrc: null,
    imageTitle: null,
    imageCaption: null,
    randomize: false,
    showprice: false,
    price: "",
    pricemetric: "",
    togglevalue: "",
    slidermin:0,
    slidermax:100,
    sliderstep: 25,
    slidermarks: { 0: "A", 25: "B", 50: "C", 75: "D", 100: "E" },
    sliderdefault: null,
    rangedefault:[0,100],
    allocationamount:100,
    selectoptions:[],
    annotations:null,
    showquestionhelpmessage: false,
    helpmessage: "",
    features:[],}
  
  function truncateString(str, num) {
    if (typeof str === 'undefined'){
      return "..."
    }
    if (str === ""){
      return "..."
    }
    if (str === null){
      return "..."
    }
    if (str.length <= num) {
      return str
    }
    return str.slice(0, num) + '...'
  }
  
  function makeId(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  
  function getTime(){
    let today = new Date();
    let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    let dateTime = date+' '+time;
    return(dateTime)
  }

  const defaultcolors = [
    '#000000', '#2f353a', '#c8ced3', '#f0f3f5','#FFFFFF',
    '#b71c1c', '#d32f2f',  '#f44336',  '#e57373', '#ffcdd2',
    '#ff6f00', '#ffa000',  '#ffc107', '#ffd54f',  '#ffecb3',
    '#33691e', '#689f38',  '#8bc34a',  '#aed581', '#dcedc8',
    '#006064', '#0097a7', '#00bcd4', '#4dd0e1', '#b2ebf2',
    '#0d47a1', '#1976D2',  '#2196F3', '#64B5F6', '#bbdefb',
    '#4a148c', '#7b1fa2',  '#9c27b0', '#ba68c8',  '#e1bee7',
    '#880e4f', '#c2185b',  '#e91e63', '#f06292', '#f8bbd0',
    ]
  
    /// styling for react-select
    const customStyles2 = (isValid) => ({
    option: (provided, state) => ({
        ...provided,
        fontSize: '1em',
        borderBottom: '1px dotted black',
        backgroundColor: state.isSelected ? '#2f353a' : 'white',
        color: state.isSelected ? '#20a8d8' : '#2f353a',
        padding: 20,
    }),
        control: (styles: any, state:any) => ({
        ...styles,
        color:"#2f353a",
        backgroundColor:"#f0f3f5",
        padding: 5,
        boxShadow: 'none',
        '&:hover': { borderColor: '#bce0ee', borderWidth:'1px' },
        ...(!isValid && { borderColor: 'red' }),
        }),
    });


const featuretip = `It is not necessary or even desirable to include every possible product feature.  Instead only add the most important and differentiated. <br> 
This will ensure that survey respondents are not overwhelmed with details and are able to make informed decisions based on the most critical and essential items.`

const packagetip = `You may also consider adding your competitors packages to the list.  <br> 
In this way you can compare customer preferences for your packages to the alternatives in the market.`

const questiontip = `These are starter questions we will use to build your first sample survey. <br> You can delete, add and edit these to suit your 
needs in the Survey Editor.`

const styletip = `These are 34 different elements that accept styling choices in Pricekit. <br>  In addition to the palette options, you can enter your own custom Hex color codes to exactly match your brand requirements.`

const surveytip = `This survey, your features and packages are saved to your account so you can edit and re-use them.`



export default class SetUp extends Component {
  constructor(props) {
    super(props);
    let feature = {}
    let newfeatures = []
    let newpackages = []
    let packagelist = []
    let checklist = []
    let question = {}
    let b2b = true
    let b2c = false
    let style = {}
    style['pagebodycolor']="#c8ced3"
    style['formbodycolor']="#ffffff"
    style['formheadcolor']="#2f353a"
    style['formcardtextcolor']='#0d47a1'
    style['formheadtextcolor']= '#bbdefb'
    style['questionheadtextcolor']='#000000'
    question['category']=""
    question['competitors']=""
    question['questionlist']=[]
    feature['featureId']=makeId(5)
    feature['header']=""
    feature['detail']=""
    feature['featuretype']=null
    feature['price']=""
    feature['pricemetric']=""
    feature['isActive']=true
    let samplesurvey={}
    samplesurvey['surveyId']=""
    samplesurvey['surveytitle']=""
    samplesurvey['localsavetime']=""
    samplesurvey['surveyurl']=""
    this.state = {
      isLoading: true,
      step: "1",
      feature: feature,
      package: choicecarddefaults,
      newfeatures: newfeatures,
      newpackages: newpackages,
      checklist: checklist,
      question:question,
      b2b:b2b,
      b2c:b2c,
      samples:{},
      activecolor: "#000000",
      activeitem: "pagebodycolor",
      style:style,
      packagelist:packagelist,
      samplesurvey: samplesurvey,
    };
  }

  async componentDidMount() {

    if (!this.props.isAuthenticated) {return};

    if(this.props.orgId === null){
      try{
        this.props.userHasEmail(localStorage.getItem('email'))
        this.props.userHasId(localStorage.getItem('userId'))
        this.props.userHasRole(localStorage.getItem('userRole'))
        this.props.userHasLicense(localStorage.getItem('userLicense'))
        this.props.userHasProduct(localStorage.getItem('userProduct'))
        this.props.userHasOrg(localStorage.getItem('orgId'))
      } catch {
        this.props.history.push("/home")
      }
    }

    if(this.props.orgId !== null){
      let refresponse = []
      let flresponses = []
      let pkgs = {}
      let querystring = {}
      let samples = {}
      try{
      querystring = {'queryStringParameters':{'orgId':this.props.orgId}}
      refresponse = await API.get("reference", "/reference", querystring)
      flresponses = await API.get("features", "/features", querystring)
      samples = await API.get("samples", "/samples", querystring)
      pkgs = await API.get("packages", "/packages", querystring)
      this.setState({featurelist:flresponses, featuretype:refresponse['featuretype'], samples:samples, packagelist:pkgs, isLoading:false})
    } catch (e) {
      alert('There was a problem getting data from the server', e);
    }
    }

  }

    /// step 1 = the multiselect for feature types
    getFeatureSelect(){
        if(!this.state.isLoading){
        return(
            <CreatableSelect
            isMulti
            defaultValue={this.state.featuretype}
            onChange={this.updateFeatureType}
            options={this.state.featuretype}
            name={'settings'}
            id={'settings'}
            styles={customStyles2(true)}
            />
        )
        }
    }

    updateFeatureType = (selectvalue, action) => {
        if(selectvalue !== null){
            let ftype={}
            ftype['localsavetime']=getTime()
            ftype['email']= this.props.email
            ftype['orgId']=this.props.orgId
            ftype['updatetype']='ALL'         
            ftype['featuretype']=selectvalue
            let respon = API.put("reference", "/reference", {body:ftype})
            this.setState({featuretype: selectvalue})
        }
        }

    handleFeatureChange = (event) => {
        const target = event.target;
        let value = target.value;
        const name = target.name;
        this.setState(prevState => ({
            feature: {
              ...prevState.feature,
                [name]: value},
          }))
      }

    handlePackageChange = (event) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    this.setState(prevState => ({
        package: {
            ...prevState.package,
            [name]: value},
        }))
    }

    handleQuestionChange = (event) => {
        const target = event.target;
        let value = target.value;
        const name = target.name;
        this.setState(prevState => ({
            question: {
                ...prevState.question,
                [name]: value},
            }))
        }
    



    updateOptions = (selectvalue, action) => {
        this.setState(prevState => ({
        feature: {
            ...prevState.feature,
            featuretype: selectvalue['label'] 
        }
        }))
    };

    addFeatures(){
        let featurelist = [...this.state.newfeatures]
        let allfeatures = JSON.parse(JSON.stringify(this.state.featurelist))
        let newfeat = JSON.parse(JSON.stringify(this.state.feature))
        featurelist.push(newfeat)
        allfeatures.push(newfeat)
        let feature = {}
        feature['featureId']=makeId(5)
        feature['header']=""
        feature['detail']=""
        feature['featuretype']=null
        feature['price']=""
        feature['pricemetric']=""
        feature['isActive']=true
        this.setState({feature:feature, newfeatures:featurelist, featurelist:allfeatures}, ()=>this.putFeature(newfeat))
    }



    getSelectClear(){ 
        if(this.state.feature.featuretype!==null){
        return({value:this.state.feature.featuretype, label:this.state.feature.featuretype})
        } else{return null}
    }



    getAddedFeatureListItems(f){
            return(     
                <p style={{fontSize:"0.8em", fontWeight:"200", marginLeft:"15px"}}>{f.header}</p>
            )
    }

    getAddedFeatureList(){
        return(
        <div style={{display:"flex"}}>
        <p style={{fontSize:"0.8em",marginLeft:"15px" }}>Added:</p>    
        {this.state.newfeatures.map((feature)=>this.getAddedFeatureListItems(feature))}
        </div>
        )
    }

    getExistingFeatureCount(){
        return(
        <p style={{fontSize:"0.8em",marginLeft:"15px" }}>You have {this.state.featurelist.length} saved features.</p>
        )
        
    }

    getExistingFeatureTypeCount(){
        return(
        <p style={{fontSize:"0.8em",marginLeft:"15px" }}>You have {this.state.featuretype.length} saved feature types.</p>
        )
        
    }


    async putFeature(f){

        let feature = {}
        feature['localsavetime']=getTime()
        feature['email']= this.props.email
        feature['orgId']=this.props.orgId
        feature['userId']=this.props.userId
        feature['featureId'] = f['featureId']
        feature['header'] = f['header']
        feature['detail']=f['detail']
        feature['price'] = f['price']
        feature['pricemetric']=f['pricemetric']
        feature['featuretype']=f['featuretype']
        feature['isActive']=f['isActive']
        feature['action']="Save"
        let resp = await API.put("features", "/features", {body:feature})
    }


    /// step 3

    getPackageListItems(p){
        return(     
            <p style={{fontSize:"0.8em", fontWeight:"200", marginLeft:"15px"}}>{p.optionheader}</p>
        )
}

    getPackageList(){
        return(
        <div style={{display:"flex"}}>
        <p style={{fontSize:"0.8em",marginLeft:"15px" }}>Added:</p>    
        {this.state.newpackages.map((pkg)=>this.getPackageListItems(pkg))}
        </div>
        )
    }

    getChecked(i){
        let ischecked = this.state.checklist.indexOf(i)
        if(ischecked>-1){return true} else {return false}
    }
    
    addFeature(e, i){
        let featcopy = [...this.state.package.features]
        let checklist = [...this.state.checklist]
        let itexists = false
    
        if (featcopy.length > 0){
            for (let x in featcopy){
                if (featcopy[x]['featureId']===i){
                    featcopy.splice(x, 1)
                    itexists = true
                    if (checklist.indexOf('check'+i)>-1){
                        checklist.splice(checklist.indexOf('check'+i), 1)
                    }
                }}}
    
        if (!itexists){
            if (this.state.featurelist.length >0){
                for (let y in this.state.featurelist){
                    if (this.state.featurelist[y]['featureId']===i){
                        let copywithid = JSON.parse(JSON.stringify(this.state.featurelist[y]))
                        copywithid['id']=copywithid['featureId']
                        featcopy.push(copywithid)
                    checklist.push('check'+i)
                    }}}}
    
        this.setState(prevState => ({
            package: {...prevState.package,           
                 features: featcopy},
            checklist:checklist
                }))
    }

    getFeatureList(item){
        return(
        <CustomInput type="checkbox" 
            id={'check'+item['featureId']} label={item['header']} name={item['featureId']}
            checked={this.getChecked('check'+item['featureId'])}
            style={{fontSize:"0.8em"}} 
            onChange={(e)=>this.addFeature(e, item['featureId'])}
        ></CustomInput>
        )
    }
    
    getAccordionItem(item){
        let itemlist = []
        for(let x in this.state.featurelist){
            if (!["None", null].includes(this.state.featurelist[x]['featuretype'])){
                if (this.state.featurelist[x]['featuretype']===item.label){
                    itemlist.push(this.state.featurelist[x])
                }
            }
        }
    
        return(
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton style={{fontSize:"0.8em"}}>
                       {item.label}
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel style={{fontSize:"0.8em"}}>
    
                {itemlist.map((item, key=item.featureId) => (this.getFeatureList(item)))}
    
                </AccordionItemPanel>
            </AccordionItem>
        )
    }

    selectFeatures(){

        if(this.state.featurelist !== [] && this.state.featuretype !== []){
            let nonelist = []
            for(let x in this.state.featurelist){
                if ([null, "None"].includes(this.state.featurelist[x]['featuretype'])){
                    nonelist.push(this.state.featurelist[x])
                }
            }
    
            return(
    
                <div style={{display:"flex", flexDirection:"column"}}>
                <Card className="survey-choice-card" style={{backgroundColor:"transparent"}}>
                <CardHeader style={{backgroundColor:"transparent", height:"48px"}}>
                <p style={{marginBottom:"10px", color:"#5c6873"}}>Select included features...</p>
                </CardHeader>
                        <Accordion
                            allowMultipleExpanded={false}
                            allowZeroExpanded={true}
                            >
            
                        {this.state.featuretype.map((item, key=item.label) => (this.getAccordionItem(item)))}
            
            
                        {nonelist.length >0 &&
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton style={{fontSize:"0.8em"}}>
                                {"No Category"}
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel style={{fontSize:"0.8em"}}>
            
                            {nonelist.map((item, key=item.featureId) => (this.getFeatureList(item)))}
            
                            </AccordionItemPanel>
                        </AccordionItem>
                        }
            
                        </Accordion>
                </Card>
    
                </div>
                
            )
    }
    }

    getExistingPackageCount(){
        return(
        <p style={{fontSize:"0.8em",marginLeft:"15px" }}>You have {this.state.packagelist.length} saved packages.</p>
        ) 
    }

    addPackage(){
        let newpkg = JSON.parse(JSON.stringify(this.state.package))
        let packagelist = JSON.parse(JSON.stringify(this.state.newpackages))
        packagelist.push(newpkg)
        let allpackages = JSON.parse(JSON.stringify(this.state.packagelist))
        allpackages.push(newpkg)
        let pkg = choicecarddefaults
        this.setState({package:pkg, newpackages:packagelist, packagelist:allpackages, checklist:[]}, ()=>this.putPackage(newpkg))
    }

    async putPackage(pkg){
        const pkgs = {}
        pkgs['localsavetime']=getTime()
        pkgs['email']= this.props.email
        pkgs['orgId']=this.props.orgId
        pkgs['userId']=this.props.userId
        pkgs['package']=pkg
        pkgs['action']= "save"
        let respon = await API.put("packages", "/packages", {body:pkgs})
      }


///step 4

    getAddedSampleListItems(p){
        let item = ""
        for(let x in this.state.samples.b2b){
            if(this.state.samples.b2b[x]['id']===p){item =this.state.samples.b2b[x]['shortname']  }
        }
        for(let x in this.state.samples.b2c){
            if(this.state.samples.b2c[x]['id']===p){item =this.state.samples.b2c[x]['shortname']  }
        }
        return(     
            <p style={{fontSize:"0.8em", fontWeight:"200", marginLeft:"15px"}}>{item}</p>
        )
}

    getAddedSampleList(){
        return(
        <div style={{display:"flex"}}>
        <p style={{fontSize:"0.8em",marginLeft:"15px" }}>Added:</p>    
        {this.state.question.questionlist.map((pkg)=>this.getAddedSampleListItems(pkg))}
        </div>
        )
    }


    getSampleChecked(i){
        let ischecked = this.state.question.questionlist.indexOf(i)
        if(ischecked>-1){return true} else {return false}
    }


    addSample(e, i){

        let samplecopy = JSON.parse(JSON.stringify(this.state.question))

        let ischecked = samplecopy['questionlist'].indexOf(i)
        if(ischecked>-1){
                samplecopy['questionlist'].splice(ischecked, 1)
        } else {
            samplecopy['questionlist'].push(i)
        }

        this.setState({question:samplecopy})

    }

    getSampleList(sample){

        return(
        <CustomInput type="checkbox" 
            id={sample['id']} label={sample['header']} name={sample['id']}
            checked={this.getSampleChecked(sample['id'])}
            style={{fontSize:"0.8em"}} 
            onChange={(e)=>this.addSample(e, sample['id'])}
        ></CustomInput>
        )
    }
    
    getSampleAccordionItem(item){
        let itemlist = []

        if(this.state.b2b){
            for(let x in this.state.samples.b2b){
                if (this.state.samples.b2b[x]['category']===item){
                    itemlist.push(this.state.samples.b2b[x])
                }
            }
        }

        if(this.state.b2c){
            for(let x in this.state.samples.b2c){
                if (this.state.samples.b2c[x]['category']===item){
                    itemlist.push(this.state.samples.b2c[x])
                }
            }
            }
    
        return(
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton style={{fontSize:"0.8em"}}>
                       {item}
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel style={{fontSize:"0.8em"}}>
    
                {itemlist.map((sample, key=item['id']) => (this.getSampleList(sample)))}
    
                </AccordionItemPanel>
            </AccordionItem>
        )
    }

    selectSamples(){

        if(this.state.featurelist !== [] && this.state.featuretype !== []){
            let nonelist = []
            for(let x in this.state.featurelist){
                if ([null, "None"].includes(this.state.featurelist[x]['featuretype'])){
                    nonelist.push(this.state.featurelist[x])
                }
            }
    
            return(
    
                <div style={{display:"flex", flexDirection:"column"}}>
                <Card className="survey-choice-card" style={{backgroundColor:"transparent"}}>
                <CardHeader style={{backgroundColor:"transparent", height:"48px"}}>
                <p style={{marginBottom:"10px", color:"#5c6873"}}>Select Profiling Questions...</p>
                </CardHeader>

                        <Accordion
                            allowMultipleExpanded={false}
                            allowZeroExpanded={true}
                            >
            
                        {this.state.b2b && this.state.samples.b2bcategory.map((item, key=item.label) => (this.getSampleAccordionItem(item)))}

                        {this.state.b2c && this.state.samples.b2ccategory.map((item, key=item.label) => (this.getSampleAccordionItem(item)))}
                        
                        </Accordion>
                </Card>
    
                </div>
                
            )
    }
    }



    isValidPackage(){
        if(this.state.package.optionheader !== "" && this.state.packageoptionheader !== null){ return true}
        return false
    }
    
    handleToggleChange(t){
        if(t='b2b'){
            this.setState({[t]: !this.state[t], b2c:!this.state['b2c']})
        } else {
        this.setState({[t]: !this.state[t], b2b:!this.state['b2b']})
        }
    }


/// step 5
    changeColor = event => {
        let item = {}
        item['name']=this.state.activeitem
        item['value']=event.hex

        let newstyle = JSON.parse(JSON.stringify(this.state.style))

        newstyle[item['name']]=event.hex

        this.setState({style:newstyle, activecolor:event.hex})
    }

    getBackgroundColor(c){
        if(this.state.activeitem === c){
        return("#d8e4e8")}
        else {return ("transparent")}
    }

    activeSelection(id){
        let activecolor = this.state.style[id]
        this.setState({activeitem:id, activecolor:activecolor})
    }


    goEdit(){

        this.props.userHasActiveSurvey(this.state.samplesurvey)

        this.props.history.push("surveys/editor")

    }


    goSurvey(){

        window.open(this.state.samplesurvey['surveyurl'], "_blank")

    }




    async buildSample(){
        this.setState({isLoading:true})
        let sample = {}
        sample['data']=this.state
        sample['orgId']=this.props.orgId
        sample['email']=this.props.email
        sample['userId']=this.props.userId
        let response = await API.put("samples", "/samples",{body:sample})
        this.setState({step:"6", isLoading:false, samplesurvey:response})
    }


    showStep1(){
    return(
        <div>
        <Jumbotron style={{marginBottom:"30px", marginTop:"0px", padding:"0px"}}>
        <h1>Quick Start:  Feature Categories</h1>
        <h5 style={{fontWeight:"200"}}>Step 1 of 5</h5>
        <hr/>
        <p>Define your feature categories.  Think about how you normally group your product features by types, levels, size, role or by similar capabilities. Typically you would have 5 or 6 categories, but it could be more or less depending on your specific situation.</p>
        <div>
        <p style={{fontWeight:"200", fontSize:"0.8em", fontStyle:"italic"}}>For example:  security, analytics, support services, storage capacity, screen size, flavor, and so on.</p>
        </div>
        </Jumbotron>
        {this.state.isLoading && <Loader type="Puff" color="#20a8d8" height={100} width={100} timeout={7000}/>}
        <Row>
            <Col>
            <div style={{width:"100%", height:"100%"}}>
            <Card style={{ height:"100%", backgroundColor:"#f0f3f5"}}>     
            <CardBody>
            <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-end",marginRight:"10px", flexGrow:'1', marginTop:"15px"}}>
            <div style={{marginBottom:"10px", marginLeft:"5px"}}><small className="text-muted text-uppercase font-weight-bold">Feature Categories</small></div>
            <div style={{flexDirection:"column", alignItems:"flex-end", marginLeft:"5px", marginTop:"5px", marginRight:"15px", width:"100%"}}>

            {this.getFeatureSelect()}

            </div>
            <p style={{marginTop:"30px"}}>Type directly into the box to add a new category.  You can add, edit and delete these at any time from the <mark>Features</mark> menu option.  When you are done hit Next.</p>
            </div>

            </CardBody>
            <CardFooter style={{backgroundColor:"#FFFFFF"}}>
            <div style={{display:"flex", width:"100%"}}>
            <p>You can access these pages again from the <mark>Quick Start</mark> menu option.</p>
            <div style={{alignSelf:"flex-end", marginLeft:"auto"}}>
            <Button color="primary" disabled={this.state.feature.header !==""} onClick={()=>this.setState({step:"2"})}>Next</Button>
        </div>
        </div>
            </CardFooter>
            </Card>
            </div>
            </Col>

        </Row>
       

    </div>
    )
    }

    showStep2(){
        return(
            <div>
            <ReactTooltip 
            multiline={true}
            type={'light'}
            />
            <Jumbotron style={{marginBottom:"30px", marginTop:"0px", padding:"0px"}}>
            <h1>Quick Start:  Features</h1>
            <h5 style={{fontWeight:"200"}}>Step 2 of 5</h5>
            <hr/>
            <p>Add your features.  Think about the main attributes or capabilities of your products.  These are the items which describe what your product does, how it works or what you
                offer to customers.   Define these in industry standard terms and plain language that your prospective customers would understand.</p>

            <div>
            <p style={{fontWeight:"200", fontSize:"0.8em", fontStyle:"italic"}}>For example:  single sign-on, drag-and-drop text editor, 250 GB storage, 24x7 live support, and so on.</p>
            </div>
            </Jumbotron>
            <Row>
                <Col>
                <div style={{width:"100%", height:"100%"}}>
                <Card style={{ height:"100%", backgroundColor:"#f0f3f5"}}> 
                    <a className="icon-info" style={{fontSize:"1.2em", marginLeft:"auto", marginTop:"10px", marginRight:"10px", color:"#5c6873"}} data-place="left" data-tip={featuretip}/>
                <CardBody>
                <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-end",marginRight:"10px", flexGrow:'1', marginTop:"-15px"}}>
                <div style={{marginBottom:"10px", marginLeft:"5px"}}><small className="text-muted text-uppercase font-weight-bold">Feature Name | Description | Category</small></div>
                <div style={{display:"flex", flexDirection:"row", alignItems:"flex-end", marginLeft:"5px", marginTop:"5px", marginRight:"15px", width:"100%"}}>
    
                <div style={{width:"30%", marginRight:"15px"}}>
                <Input type="text" name={"header"} id={"header"}
                    placeholder={'Feature name...'}
                    value={this.state.feature.header}
                    style={{backgroundColor:"#f0f3f5", borderColor:"#c8ced3", padding:"15px", height:"48px", fontSize:"1em"}}
                    onChange={this.handleFeatureChange}
                    />

                </div>
                <div style={{width:"30%", marginRight:"15px"}}>
                <Input type="text" name={"detail"} id={"detail"}
                    placeholder={'Detailed description (optional)...'}
                    value={this.state.feature.detail}
                    style={{backgroundColor:"#f0f3f5",borderColor:"#c8ced3", padding:"15px", height:"48px", fontSize:"1em"}}
                    onChange={this.handleFeatureChange}
                    />

                </div>
                <div style={{width:"30%", marginRight:"15px"}}>
                    <Select
                    placeholder={'Select the feature category...'}
                    value={this.getSelectClear()}
                    onChange={this.updateOptions}
                    options={this.state.featuretype}
                    id={'featuretype'}
                    name={"featuretype"}
                    styles={customStyles2(true)}
                    />

                </div>
                
                
                <div style={{alignSelf:"flex-end", marginLeft:"auto"}}>
                <Button color="primary" onClick={()=>this.addFeatures()}>Add</Button>
                </div>
                </div>




                <p style={{marginTop:"30px"}}>Complete the fields and select Add.  You can add as many features as you like.   You can also add, edit and delete these at any time from the <mark>Features</mark> menu option.  
                When you are done hit Next.</p>
                </div>
    
                </CardBody>
                <CardFooter style={{backgroundColor:"#FFFFFF"}}>
                <div style={{display:"flex", width:"100%"}}>
                <Button color="secondary" onClick={()=>this.setState({step:"1"})}>Back</Button>
                <div style={{alignSelf:"flex-end", marginLeft:"auto"}}>
                <Button color="primary" onClick={()=>this.setState({step:"3"})}>Next</Button>
                </div>
                </div>
                </CardFooter>
                </Card>
                </div>
                </Col>
            </Row>
            {this.state.featurelist.length>0 && this.getExistingFeatureCount()}
            {this.state.newfeatures.length>0 && this.getAddedFeatureList()}
    
        </div>
        )
        }

        
    showStep3(){
        return(
            <div>
            <ReactTooltip 
            multiline={true}
            type={'light'}
            />
            <Jumbotron style={{marginBottom:"30px", marginTop:"0px", padding:"0px"}}>
            <h1>Quick Start:  Packages</h1>
            <h5 style={{fontWeight:"200"}}>Step 3 of 5</h5>
            <hr/>
            <p>Create some packages.  Package is the term we use to describe the product offering or bundle that you sell to customers.  If your company has different
                versions, editions, options, add-ons or tiers - each would be a different package.</p>


            <div>
            <p style={{fontWeight:"200", fontSize:"0.8em", fontStyle:"italic"}}>For example:  Hubspot Marketing Hub Enterprise $3200/mo,  Jira Core Standard $5/user/mo, Samsung Galaxy S10 128GB $900, etc.</p>
            </div>
            </Jumbotron>
            <Row>
                <Col>
                <div style={{width:"100%", height:"100%", padding:"0px"}}>
                <Card style={{ height:"100%", backgroundColor:"#f0f3f5", marginTop:"0px", paddingBottom:"0px", paddingTop:"0px"}}> 
                <a className="icon-info" style={{fontSize:"1.2em", marginLeft:"auto", marginTop:"10px", marginRight:"10px", color:"#5c6873"}} data-place="left" data-tip={packagetip}/>
                <CardBody style={{paddingBottom:"0px"}}>
                <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-start",marginRight:"10px", flexGrow:'1', marginTop:"-15px"}}>
                <div style={{marginLeft:"5px", marginBottom:"10px"}}><small className="text-muted text-uppercase font-weight-bold">Product Package Name | Features | Price</small></div>
                <div style={{display:"flex", flexDirection:"row", alignItems:"flex-start", marginLeft:"5px", marginTop:"5px", marginRight:"15px", width:"100%"}}>
    
                <div style={{width:"30%", marginRight:"15px"}}>
                <Input type="text" name={"optionheader"} id={"optionheader"}
                    placeholder={'Package name...'}
                    value={this.state.package.optionheader}
                    style={{backgroundColor:"#f0f3f5", borderColor:"#c8ced3", padding:"15px", height:"48px", fontSize:"1em", marginBottom:"15px"}}
                    onChange={this.handlePackageChange}
                    />

                <Input type="text" name={"img"} id={"img"}
                    placeholder={'Image/Screenshot/Logo URL (optional)...'}
                    value={this.state.package.img}
                    style={{backgroundColor:"#f0f3f5",borderColor:"#c8ced3", padding:"15px", height:"48px", fontSize:"1em"}}
                    onChange={this.handlePackageChange}
                    />

                {this.state.package.img !== "" &&
                <div style={{marginTop:"15px"}}>
                <img width="50%" src={this.state.package.img} alt="Survey Image" />
                </div>
                }
                </div>


                <div style={{width:"30%", marginRight:"15px"}}>
                <Input type="number" name={"price"} id={"price"}
                    placeholder={'Package price (e.g., 25 or 3.99)...'}
                    value={this.state.package.price}
                    style={{backgroundColor:"#f0f3f5", borderColor:"#c8ced3", padding:"15px", height:"48px", fontSize:"1em", marginBottom:"15px"}}
                    onChange={this.handlePackageChange}
                    />

                <Input type="text" name={"pricemetric"} id={"pricemetric"}
                    placeholder={'Price metric (e.g., per user per month)...'}
                    value={this.state.package.pricemetric}
                    style={{backgroundColor:"#f0f3f5",borderColor:"#c8ced3", padding:"15px", height:"48px", fontSize:"1em"}}
                    onChange={this.handlePackageChange}
                    />
                </div>

                <div style={{width:"30%", marginRight:"15px"}}>
                    {this.selectFeatures()}
                </div>
                
                <div style={{alignSelf:"flex-start", marginLeft:"auto"}}>
                <Button color="primary" disabled={!this.isValidPackage()} onClick={()=>this.addPackage()}>Add</Button>
                </div>
                </div>




                <p style={{marginTop:"15px", marginBottom:"0px"}}>Complete the fields and select Add.  You can add as many packages as you wish.  You can also add, edit, delete and adjust various advanced settings from the <mark>Packages</mark> menu option.  
                When you are done hit Next.</p>
                </div>
    
                </CardBody>
                <CardFooter style={{backgroundColor:"#FFFFFF"}}>
                <div style={{display:"flex", width:"100%"}}>
                <Button color="secondary" onClick={()=>this.setState({step:"2"})}>Back</Button>
                <div style={{alignSelf:"flex-end", marginLeft:"auto"}}>
                <Button color="primary" onClick={()=>this.setState({step:"4"})}>Next</Button>
                </div>
                </div>
                </CardFooter>
                </Card>
                </div>
                </Col>
            </Row>
            {this.state.packagelist.length>0 && this.getExistingPackageCount()}
            {this.state.newpackages.length>0 && this.getPackageList()}
    
        </div>
        )
        }

    showStep4(){
        return(
            <div>
            <ReactTooltip 
            multiline={true}
            type={'light'}
            />
            <Jumbotron style={{marginBottom:"30px", marginTop:"0px", padding:"0px"}}>
            <h1>Quick Start: Segment Questions</h1>
            <h5 style={{fontWeight:"200"}}>Step 4 of 5</h5>
            <hr/>
            <p>Select some baseline profiling questions.  We will use these to correlate package preferences to different customer segments.</p>


            <div>
            <p style={{fontWeight:"200", fontSize:"0.8em", fontStyle:"italic"}}>For example:  Industry,  Company size,  Decision Authority, Category Awareness,  Current Satisfaction, etc.</p>
            </div>
            </Jumbotron>
            <Row>
                <Col>
                <div style={{width:"100%", height:"100%", padding:"0px"}}>
                <Card style={{ height:"100%", backgroundColor:"#f0f3f5", marginTop:"0px", paddingBottom:"0px", paddingTop:"0px"}}> 
                <a className="icon-info" style={{fontSize:"1.2em", marginLeft:"auto", marginTop:"10px", marginRight:"10px", color:"#5c6873"}} data-place="left" data-tip={questiontip}/>
                <CardBody>
                <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-start",marginRight:"10px", flexGrow:'1', marginTop:"-15px"}}>
                <div style={{marginLeft:"5px", marginBottom:"10px"}}><small className="text-muted text-uppercase font-weight-bold">Market and Customer Questions</small></div>
                <div style={{display:"flex", flexDirection:"row", alignItems:"flex-start", marginLeft:"5px", marginTop:"5px", marginRight:"15px", width:"100%"}}>
    
                <div style={{width:"30%", marginRight:"15px"}}>
                <Input type="text" name={"category"} id={"category"}
                    placeholder={'Your product/market category name (e.g., Cybersecurity, Online Travel, Email Marketing)...'}
                    value={this.state.question.category}
                    style={{backgroundColor:"#f0f3f5", borderColor:"#c8ced3", padding:"15px", height:"48px", fontSize:"1em", marginBottom:"15px"}}
                    onChange={this.handleQuestionChange}
                    />
                    <div style={{marginTop:"10px", display:"flex"}}>
                    <div style={{display:"flex", flexDirection:"column", color:"#5c6873"}}>
                    <p style={{marginBottom:"5px", fontSize:"0.8em"}}>Primarily B2B</p>
                    <Toggle
                        id={"b2b"}
                        checked= {this.state.b2b}
                        icons={{unchecked: null}}
                        className = {"custom-success"}
                        onChange = {() => this.handleToggleChange('b2b')}
                    />
                    </div>
                    <div style={{display:"flex", flexDirection:"column", color:"#5c6873", marginLeft:"10px"}}>
                    <p style={{marginBottom:"5px", fontSize:"0.8em"}}>Primarily B2C</p>
                    <Toggle
                        id={"b2c"}
                        checked= {this.state.b2c}
                        icons={{unchecked: null}}
                        className = {"custom-success"}
                        onChange = {() => this.handleToggleChange('b2c')}
                    />
                    </div>
                    </div>


                </div>
                <div style={{width:"30%", marginRight:"15px"}}>
                <Input type="text" name={"competitors"} id={"competitors"}
                    placeholder={'List your top competitors separated by commas (e.g., Apple, Microsoft, Google)...'}
                    value={this.state.question.competitors}
                    style={{backgroundColor:"#f0f3f5", borderColor:"#c8ced3", padding:"15px", height:"48px", fontSize:"1em", marginBottom:"15px"}}
                    onChange={this.handleQuestionChange}
                    />
                </div>

                <div style={{width:"30%", marginRight:"15px"}}>
                    {this.selectSamples()}
                </div>
                
                </div>




                <p style={{marginTop:"15px", marginBottom:"0px"}}>Complete the fields and select Add.  You can edit these and create a wide range of your own questions in the <mark>Survey Editor</mark>.  
                When you are done hit Next.</p>
                </div>
    
                </CardBody>
                <CardFooter style={{backgroundColor:"#FFFFFF"}}>
                <div style={{display:"flex", width:"100%"}}>
                <Button color="secondary" onClick={()=>this.setState({step:"3"})}>Back</Button>
                <div style={{alignSelf:"flex-end", marginLeft:"auto"}}>
                <Button color="primary" onClick={()=>this.setState({step:"5"})}>Next</Button>
                </div>
                </div>
                </CardFooter>
                </Card>
                </div>
                </Col>
            </Row>
            {this.state.question.questionlist.length>0 && this.getAddedSampleList()}
    
        </div>
        )
        }

    showStep5(){
        return(
            <div>
            <ReactTooltip 
            multiline={true}
            type={'light'}
            />
            <Jumbotron style={{marginBottom:"30px", marginTop:"0px", padding:"0px"}}>
            <h1>Quick Start:  Styling</h1>
            <h5 style={{fontWeight:"200"}}>Step 5 of 5</h5>
            <hr/>
            <p>Make some styling choices.  These are the main colors for your survey.</p>
            </Jumbotron>
            {this.state.isLoading && <Loader type="Puff" color="#20a8d8" height={100} width={100} timeout={5000}/>}
            <Row>
            
                <Col>
                <div style={{width:"100%", height:"100%", padding:"0px"}}>
                <Card style={{ height:"100%", backgroundColor:"#f0f3f5", marginTop:"0px", paddingBottom:"0px", paddingTop:"0px"}}> 
                <a className="icon-info" style={{fontSize:"1.5em", marginLeft:"auto", marginTop:"10px", marginRight:"10px", color:"#5c6873"}} data-place="left" data-tip={styletip}/>
                <CardBody>
                <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-start",marginRight:"10px", flexGrow:'1', marginTop:"-15px"}}>
                    <div style={{marginLeft:"5px", marginBottom:"10px"}}><small className="text-muted text-uppercase font-weight-bold">Color Selection</small></div>
                    
                    <div style={{display:"flex", flexDirection:"row", alignItems:"flex-start", marginLeft:"5px", marginTop:"5px", marginRight:"15px", width:"100%"}}>
    

                        <div style={{width:"20%"}}>

                        <Col onClick = {() => this.activeSelection("pagebodycolor")} style={{padding:"5px", justifyContent:"center"}}>
                        <p style={{ marginBottom:'0px'}}  ><small>Main Background</small></p>
                        <div style={{backgroundColor:this.getBackgroundColor("pagebodycolor"), display:"flex", flexDirection:"column",  marginBottom:"5px"}}>
                        <div style={{backgroundColor:this.state.style.pagebodycolor, width:"20px", height:"20px", borderRadius:"50%", marginTop:"5px", marginBottom:"5px"}}></div>    
                        </div>
                        </Col>

                        <Col onClick = {() => this.activeSelection("questionheadtextcolor")} style={{padding:"5px", justifyContent:"center"}}>
                        <p style={{ marginBottom:'0px'}}  ><small>Title Text Color</small></p>
                        <div style={{backgroundColor:this.getBackgroundColor("questionheadtextcolor"), display:"flex", flexDirection:"column",  marginBottom:"5px"}}>
                        <div style={{backgroundColor:this.state.style.questionheadtextcolor, width:"20px", height:"20px", borderRadius:"50%", marginTop:"5px", marginBottom:"5px"}}></div>    
                        </div>
                        </Col>

                        <Col onClick = {() => this.activeSelection("formbodycolor")} style={{padding:"5px", justifyContent:"center"}}>
                        <p style={{ marginBottom:'0px'}}><small>Card Body Color</small></p>
                        <div style={{backgroundColor:this.getBackgroundColor("formbodycolor"), display:"flex", flexDirection:"column", marginBottom:"5px"}}>
                        <div style={{backgroundColor:this.state.style.formbodycolor, width:"20px", height:"20px", borderRadius:"50%", marginTop:"5px", marginBottom:"5px"}}></div>
                        </div>
                        </Col>

                        <Col onClick = {() => this.activeSelection("formcardtextcolor")} style={{padding:"5px", justifyContent:"center"}}>
                        <p style={{ marginBottom:'0px'}}><small>Main Text Color</small></p>
                        <div style={{backgroundColor:this.getBackgroundColor("formcardtextcolor"), display:"flex", flexDirection:"column", marginBottom:"5px"}}>
                        <div style={{backgroundColor:this.state.style.formcardtextcolor, width:"20px", height:"20px", borderRadius:"50%", marginTop:"5px", marginBottom:"5px"}}></div>    
                        </div>
                        </Col>

                        <Col onClick = {() => this.activeSelection("formheadcolor")} style={{padding:"5px", justifyContent:"center"}}>
                        <p style={{ marginBottom:'0px'}}><small>Card Accent Color</small></p>
                        <div style={{backgroundColor:this.getBackgroundColor("formheadcolor"), display:"flex", flexDirection:"column",  marginBottom:"5px"}}>
                        <div style={{backgroundColor:this.state.style.formheadcolor, width:"20px", height:"20px", borderRadius:"50%", marginTop:"5px", marginBottom:"5px"}}></div>    
                        </div>
                        </Col>

                        <Col onClick = {() => this.activeSelection("formheadtextcolor")} style={{padding:"5px", justifyContent:"center"}}>
                        <p style={{ marginBottom:'0px'}}><small>Accent Text Color</small></p>
                        <div style={{backgroundColor:this.getBackgroundColor("formheadtextcolor"), display:"flex", flexDirection:"column",  marginBottom:"5px"}}>
                        <div style={{backgroundColor:this.state.style.formheadtextcolor, width:"20px", height:"20px", borderRadius:"50%", marginTop:"5px", marginBottom:"5px"}}></div>    
                        </div>
                        </Col>

                        </div>
                    

                    <div style={{width:"20%", height:"400px", marginRight:"15px", marginTop:"-30px"}}>

                        <BlockPicker
                        color={this.state.activecolor}
                        colors={defaultcolors}
                        triangle={"hide"}
                        onChange={this.changeColor}
                        />

                    </div>

                    <div style={{width:"50%",  marginTop:"-30px"}}>

                    <Card style={{height:"400px", width:"100%", backgroundColor:this.state.style.pagebodycolor}}>
                        <CardBody>
                    <div className = "survey-question-headings" style={{color:this.state.style.questionheadtextcolor}}>
                       <h1 style={{fontSize:"1.2em"}}>Title Text</h1>
                    </div>
                    <div style={{marginTop:"15px", marginBottom:"5px"}}>
                        <Row>
                            <Col style={{padding:"5px", marginLeft:"15px"}}>
                            <Card>
                                <CardHeader style={{backgroundColor:this.state.style.formheadcolor, color:this.state.style.formheadtextcolor}}>
                                Accent Text
                                </CardHeader>
                                <CardBody style={{backgroundColor:this.state.style.formbodycolor, height:"150px"}}>
                                <p style={{color:this.state.style.formcardtextcolor}}>Main Text</p>
                                </CardBody>
                            </Card>
                            </Col>
                            <Col style={{padding:"5px",marginRight:"15px"}}>
                            <Card>
                                <CardHeader style={{backgroundColor:this.state.style.formheadcolor, color:this.state.style.formheadtextcolor}}>
                                Accent Text
                                </CardHeader>
                                <CardBody style={{backgroundColor:this.state.style.formbodycolor, height:"150px"}}>
                                <p style={{color:this.state.style.formcardtextcolor}}>Main Text</p>
                                </CardBody>
                            </Card>
                            </Col>
                        </Row>
                    </div>

                        </CardBody>
                    </Card>

                </div>

                    </div>
                



                <div>
                <p style={{marginTop:"15px", marginBottom:"0px"}}>Select Item and choose color from the palette.  You can change color styles in the <mark>Survey Editor</mark>.  
                Select Done to assemble your choices into a sample survey.</p>
                </div>
                </div>
    
                </CardBody>
                <CardFooter style={{backgroundColor:"#FFFFFF"}}>
                <div style={{display:"flex", width:"100%"}}>
                <Button color="secondary" onClick={()=>this.setState({step:"4"})}>Back</Button>
                <div style={{alignSelf:"flex-end", marginLeft:"auto"}}>
                <Button color="primary" onClick={()=>this.buildSample()}>Done</Button>
                </div>
                </div>
                </CardFooter>
                </Card>
                </div>
                </Col>
            </Row>
    
        </div>
        )
        }


    showStep6(){
        return(
            <div>
            <ReactTooltip 
            multiline={true}
            type={'light'}
            />
            <Jumbotron style={{marginBottom:"30px", marginTop:"0px", padding:"0px"}}>
            <h1>Congratulations</h1>
            <h5 style={{fontWeight:"200"}}>You just made a new survey</h5>
            <hr/>
            <p>You can add, edit and tailor this sample survey any way you wish.  It is a live survey so you can share the URL and start collecting responses right away.</p>

            </Jumbotron>
            
            <Row>
            
            <Card style={{backgroundColor:"#2f353a", height:"500px", width:"400px"}}>
            <a className="icon-info" style={{fontSize:"1.2em", marginLeft:"auto", marginTop:"10px", marginRight:"10px", color:"#5c6873"}} data-place="right" data-tip={surveytip}/>
            <CardBody>
            <div style={{marginTop:"5px"}}>
                <p className="text-uppercase font-weight-bold" style={{color:"#5c6873", fontSize:"0.8em"}}>
                  {`Survey ID:`}</p>
                </div>
              <div className="h1 mt-0 mb-0" style={{fontSize:"3em", color:"#f0f3f5", display:"flex"}}>
                  
                  {this.state.samplesurvey['surveyId']}
              
            
              </div>
              
              <hr style={{borderColor:"#8f9ba6"}}/>
                        
                <div style={{marginTop:"5px"}}>
                <p className="text-uppercase font-weight-bold" style={{color:"#20a8d8"}}>
                  
                  {`LIVE`}
                
                </p>
                </div>

                <div style={{marginTop:"5px"}}>
                <p className="font-weight-light" style={{color:"#20a8d8"}}>
                  
                  {this.state.samplesurvey['surveyurl']}
                
                </p>
                </div>
    
                <div style={{marginTop:"5px"}}>
                <p className="text-uppercase font-weight-bold" style={{color:"#5c6873", fontSize:"0.8em"}}>
                  {`${this.state.samplesurvey['surveytitle']}  `}
                </p>
                <p className="text-uppercase font-weight-bold" style={{color:"#5c6873", fontSize:"0.8em"}}>
                {`Saved:  ${this.state.samplesurvey['localsavetime']}`}
                </p>
                </div>
    
            </CardBody>
            <div style={{display:"flex", alignItems:"flex-end", alignContent:"flex-end", marginTop:"auto", marginLeft:"15px", marginBottom:"15px", marginRight:"15px" }}>
            
            <Button color="primary"style={{marginRight:"30px"}}  onClick={()=>this.goEdit()}>Edit Survey</Button>
    
            <Button color="primary"style={{marginRight:"15px"}}  onClick={()=>this.goSurvey()}>View Survey</Button>
            
            </div>
            </Card>
            </Row>
            </div>
        )

    }

 
  render() {
    return (


        <div className="survey-wrapper">

        {this.state.step === "1" && this.showStep1()}

        {this.state.step === "2" && this.showStep2()}

        {this.state.step === "3" && this.showStep3()}

        {this.state.step === "4" && this.showStep4()}

        {this.state.step === "5" && this.showStep5()}

        {this.state.step === "6" && this.showStep6()}

        </div>


   );
  }

}

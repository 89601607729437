import React, { Component } from 'react';
import { Card, CardBody, Progress, Button } from 'reactstrap';
import classNames from 'classnames';
import { mapToCssModules } from 'reactstrap/lib/utils';




export default class SolidActionCard extends Component {
        constructor(props) {
          super(props);
        }

getActions(){
if(this.props.actiontype==="Survey"){

    if(["DRAFT", "PENDING", "TEST PENDING", "TEST"].includes(this.props.actionstatus)){
      
    return(
        <div style={{display:"flex", flexWrap:"wrap"}}>
        
        <Button color="light" size="md" style={{marginRight:"15px", marginTop:"5px", borderColor:"#2f353a80"}} onClick={()=>this.props.handleAction("Edit")} >Edit</Button>
        <Button color="danger" size="md" style={{marginRight:"5px", marginTop:"5px", marginLeft:"15px"}} onClick={()=>this.props.handleAction("Delete")} >Delete</Button>
        </div>
        )
    }
    if(this.props.actionstatus==="LIVE"){
        return(
            <div style={{display:"flex"}}>
            <Button color="light" size="md" style={{marginRight:"10px", marginTop:"5px", borderColor:"#2f353a80"}} onClick={()=>this.props.handleAction("Results")} >Results</Button>
            <Button color="light" size="md" style={{marginRight:"15px", marginTop:"5px", borderColor:"#2f353a80"}} onClick={()=>this.props.handleAction("Edit")} >Edit</Button>
            <Button color="danger" size="md" style={{marginRight:"5px", marginTop:"5px",}} onClick={()=>this.props.handleAction("Stop")} >Stop</Button>
            </div>
            )
        }
    if(this.props.actionstatus==="COMPLETE"){
        return(
            <div style={{display:"flex"}}>
            <Button color="light" size="md" style={{marginRight:"10px", marginTop:"5px", borderColor:"#2f353a80"}} onClick={()=>this.props.handleAction("Results")} >Results</Button>
            <Button color="light" size="md" style={{marginRight:"15px", marginTop:"5px", borderColor:"#2f353a80"}} onClick={()=>this.props.handleAction("Edit")} >Edit</Button>
            <Button color="danger" size="md" style={{marginRight:"5px", marginTop:"5px"}} onClick={()=>this.props.handleAction("Delete")} >Delete</Button>
            </div>
            )
        }

    }

    if(this.props.actiontype==="Feature"){
      return(
      <div style={{display:"flex", flexWrap:"wrap"}}>
    
      <Button color="light" size="md" style={{marginRight:"15px", marginTop:"5px", borderColor:"#2f353a80"}} onClick={()=>this.props.handleAction("Edit")} >Edit</Button>
      <Button color="danger" size="md" style={{marginRight:"5px", marginTop:"5px", marginLeft:"15px"}} onClick={()=>this.props.handleAction("Delete")} >Delete</Button>
      </div>
      )
    }

    if(this.props.actiontype==="Package"){
      return(
        <div style={{display:"flex", flexWrap:"wrap"}}>
        
        <Button color="light" size="md" style={{marginRight:"5px", marginTop:"5px", borderColor:"#2f353a80"}} onClick={()=>this.props.handleAction("edit")} >Edit</Button>
        
        {['LIVE', 'TEST', 'COMPLETE'].includes(this.props.actionstatus) && 
        <Button color="light" size="md" style={{ marginTop:"5px", marginLeft:"15px", borderColor:"#2f353a80"}} onClick={()=>this.props.handleAction("analysis")} >Analysis</Button>
        }
        <Button color="danger" size="md" style={{marginRight:"15px", marginTop:"5px", marginLeft:"15px"}} onClick={()=>this.props.handleAction("delete")} >Delete</Button>
        </div>
        )
    }
}


getPriceMetric(){
  let pm = this.props.pricemetric
  if(pm !=="None"){
      return(
          <p style={{marginLeft:"10px", marginTop:"0px", marginBottom:"0px"}} ><small>{pm}</small></p>
      )
  }
  if(pm ==="None"){
      return(
          <p style={{marginLeft:"10px", marginTop:"0px", marginBottom:"0px"}} ><small>{"No price metric defined"}</small></p>
      )
  }
}

getPrice(){
  return(
  <Card style={{backgroundColor:"#f0f3f5BF", padding:"0px", marginTop:"5px"}}>
  <div style={{marginTop:"5px", marginLeft:"5px", color:"#2f353a"}}>
      <h2 style={{fontSize:"1.5em", marginBottom:"0px", }}>{`$ ${this.props.price}`}</h2>
      {this.getPriceMetric()}
  </div>
  </Card>
)
}

  render() {
    const { className, cssModule, header, icon, color, value, children, title, linkto, invert, ...attributes } = this.props;
    const progress = { style: '', color: color, value: value };
    const card = { style: '', bgColor: '', icon: icon };

    if (invert) {
      progress.style = 'progress-white';
      progress.color = '';
      card.style = 'text-white';
      card.bgColor = 'bg-' + color;
    }

    const classes = mapToCssModules(classNames(className, card.style, card.bgColor), cssModule);
    progress.style = classNames('progress-xs mt-3 mb-0', progress.style);

    return (
      <Card className={classes} {...attributes} style={{height:"100%", marginBottom:"0px"}}>
          
        <CardBody>
          <div className="h1 text-muted text-right mb-0 mt-0" ><a href={linkto} style={{color:"white", textDecoration:"none"}}></a></div>
          <div className="h1 mt-0 mb-0" style={{fontSize:"2em"}}>{header}</div>
          <small className="text-uppercase font-weight-bold">{title}</small>
          <div><small className="text-muted text-uppercase font-weight-bold">{children}</small></div>
          {this.props.actiontype==='Package' &&

          this.getPrice()
          
          }
        
        <div style={{display:"flex", alignItems:"flex-end", marginTop:"15px"}}>
        
        {this.getActions()}
        
        </div>
        </CardBody>

      </Card>
    );
  }
}

import React, { Component } from "react";
import { Row, Input, CustomInput, Form, FormGroup} from 'reactstrap';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable'
import Tooltip from 'rc-tooltip';
import Slider from 'rc-slider';
import Toggle from 'react-toggle'
import  'react-toggle/style.css'


/// ChoiceList - component that assembles each cell within the SurveyList grid
/// mostly built around columns, each of which can be thought of as a question



/// styling for react-select
const customStyles = (isValid) => ({
    control: (styles: any) => ({
      ...styles,
      padding: 5,
      fontSize: "smaller",
      ...(!isValid && { borderColor: 'red' }),
    }),
  });

/// styling for range and slider
const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);
const Handle = Slider.Handle;
const handle = (props) => {
    const { value, dragging, index, ...restProps } = props;
    return (
      <Tooltip
        prefixCls="rc-slider-tooltip"
        overlay={value}
        visible={dragging}
        placement="top"
        key={index}
      >
        <Handle value={value} {...restProps} />
      </Tooltip>
    );
  };


/// for randomizing elements in a list, like options and features
function shuffle(array) {
  var currentIndex = array.length, temporaryValue, randomIndex;
  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }
  return array;
}


export default class RenderListItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
        isLoading: true,
      };
    }
   
  async componentDidMount(){
  }

  /// callback to send selection update - for row, column, and bool toggle
  handleToggleChange(colid){
      this.props.onSelectionChange(this.props.list.id, colid, !this.props.selectvalue[colid])
  }

  /// callback to send selection update - for row, column, and bool toggle.  discrete toggle has type - logic to keep it frm being
  /// valid one per column, one per row or both of these
  handleDiscreteToggle(colid, type){

    let changevalue = !this.props.selectvalue[colid]
    if (changevalue===null){changevalue = true}
    if(type === null)(type= 'both')
    this.props.onDiscreteToggleChange(this.props.list.id, colid, changevalue, type)
}

  /// below functions are to handle different selection types
  handleSelectChange = (selectvalue:any, action:any) => {

    this.props.onSelectionChange(this.props.list.id, action.name, selectvalue)
  };

  /// NOTE the SLIDER-RANGE column id name - there is no variable-passed names, so this is ** HARD-CODED ** and therefore ONLY ONE per page
  handleSliderChange(col, val){
    this.props.onSelectionChange(this.props.list.id, col, val)
  }
 
  /// below handles allocation updates
  handleAllocationChange(col, val){
  
    this.props.onAllocationChange(this.props.list.id, col, val)
  }

  /// below handles field input updates.  since event listening are shared, when use onChange - need to stop the null target or will error
  handleFieldChange = (event) => {
    const target = event.target;
    if(target != null){
      let value = target.value;
      const name = target.name;
      this.props.onSelectionChange(this.props.list.id, name, value)
    }
  }

  /// below handles radio/check input updates.  since event listening are shared, when use onChange - need to stop the null target or will error
  handleRadioCheckChange = (event) => {
    const target = event.target;
    console.log('target', target)
    if(target != null){
      let type = target.type
      let value = target.value;
      const name = target.name;
      this.props.onRadioCheckToggle(this.props.list.id, name, value, type)
    }
  }

    /// used to generate the radio and checkbox items
    getSelectItems(item, type, id, key){
      if(type==="checkbox"){
        return(
        <CustomInput type="checkbox" id={key+item['value']} value={item['value']} label={item['label']} name={id}></CustomInput>
  
        )}
        if(type==="radio"){
          return(
          <CustomInput type="radio" id={key+item['value']} value={item['value']} label={item['label']} name={id}></CustomInput>
        )}


    }

  /// this determines which selection component to use in the card footer
  renderSelectType(column){

    let rowcolkey = this.props.row + column.id

    if (column.type === "select"){
        return(
          <td style={{verticalAlign:"middle"}}>
          <Select
          key={rowcolkey}
          id={column.id}
          name={column.id}
          defaultValue={this.props.selectvalue[column.id]}
          value={this.props.selectvalue[column.id]}
          onChange={this.handleSelectChange}
          options={column.selectoptions}
          styles={customStyles(true)}  /// false means there is an error and will error style
        />
        </td>
      )
      }
    if (column.type === "selectcreatable"){
        return(
            <td style={{verticalAlign:"middle"}}>
            <CreatableSelect
            key={rowcolkey}
            id={column.id}
            name={column.id}
            defaultValue={this.props.selectvalue[column.id]}
            value={this.props.selectvalue[column.id]}
            onChange={this.handleSelectChange}
            options={column.selectoptions}
            styles={customStyles(true)}  
            />
            </td>
        )
        }
    if (column.type === "multiselect"){
        return(
            <td style={{verticalAlign:"middle"}}>
            <Select
            isMulti
            key={rowcolkey}
            id={column.id}
            name={column.id}
            defaultValue={this.props.selectvalue[column.id]}
            onChange={this.handleSelectChange}
            options={column.selectoptions}
            styles={customStyles(true)}  
            />
            </td>
        )
        }   
    if (column.type === "multiselectcreatable"){
        return(
            <td style={{verticalAlign:"middle"}}>
            <CreatableSelect
            key={rowcolkey}
            id={column.id}
            name={column.id}
            defaultValue={this.props.selectvalue[column.id]}
            isMulti
            onChange={this.handleSelectChange}
            options={column.selectoptions}
            styles={customStyles(true)}  
            />
            </td>
        )
        }

    if (column.type === "allocation"){
      return(
        <div style={{display:"flex", marginTop:"15px", marginBottom:"30px"}}>
        <Slider 
        marks={{0:"0", [this.props.remaining + this.props.selectvalue[column.id]]: <div style={{display:"flex", flexDirection:"column", fontSize:"0.8em"}}><span><i className="icon-arrow-up" style={{color:"#f86c6b", marginTop:"0px"}} ></i></span><p>{`+ ${this.props.remaining}`}</p></div>}}
        min={0}
        max={column.allocationamount}
        step={column.sliderstep}
        defaultValue={this.props.selectvalue[column.id]}
        onAfterChange={(e)=>this.handleAllocationChange(column.id, e)}
        tipFormatter={value => `${value}`}
        handle={handle}
        dotStyle={{borderColor: '#f86c6b'}}
        activeDotStyle={{borderColor: '#c8ced3'}}
        />
        <div style={{width:"30px", marginLeft:"20px", fontSize:"1em", fontWeight:"600"}}>
        {this.props.selectvalue[column.id]}
        </div>
        </div>
      )
      

    }




    if (column.type === "slider"){
      return(
        <td style={{ padding:"20px", verticalAlign:"middle", width:"20%" }}>
        <Slider
        key={rowcolkey}
        name={column.id}
        marks={column.slidermarks}
        min={column.slidermin}
        max={column.slidermax}
        step={column.sliderstep} 
        defaultValue={this.props.selectvalue[column.id]}
        onAfterChange={(e)=>this.handleSliderChange(column.id, e)}
        dotStyle={{borderColor: '#c8ced3'}}
        activeDotStyle={{borderColor: '#c8ced3'}}
        />
        </td>
      )
    }
    if (column.type === "range"){
      return(
        <td style={{ padding:"20px", verticalAlign:"middle", width:"20%" }}>
          <Range
        key={rowcolkey}
        name={column.id}
        marks={column.slidermarks}
        min={column.slidermin}
        max={column.slidermax}
        step={column.sliderstep} 
        defaultValue={this.props.selectvalue[column.id]}
        onAfterChange={(e)=>this.handleSliderChange(column.id, e)}
          />
        </td>
      )
    }

    if (column.type === "toggle"){
    return(
    <td style={{verticalAlign:"middle"}}>
        <Toggle
        key={rowcolkey}
        id={this.props.row+column.id}
        value={column.value}
        checked= {this.props.selectvalue[this.props.row][column.id]}
        icons={{unchecked: null}}
        className = {column.style}
        onChange = {() => this.handleToggleChange(column.id)}
    />
    </td>
    )
    }
    if (column.type === "discretetoggle"){

        return(
        <td style={{verticalAlign:"middle"}}>
            <Toggle
            key={rowcolkey}
            id={this.props.row+column.id}
            value={column.value}
            checked= {this.props.selectvalue[column.id]}
            icons={{unchecked: null}}
            className = {column.style}
            onChange = {() => this.handleDiscreteToggle(column.id, column.toggletype)}
        />
        </td>
        )
        }
    if (column.type === "textfield"){
        return(
            <td style={{verticalAlign:"middle"}}>
          <Input
          key={rowcolkey}
          name={column.id}
          type={column.texttype} 
          value={this.props.selectvalue[column.id]}
          onChange={this.handleFieldChange}/>
        </td>
        )
    }
    if (column.type === "textarea"){
      return(
        <td style={{verticalAlign:"middle"}}>
           <Input
           key={rowcolkey}
          name={column.id}
          type={"textarea"} 
          value={this.props.selectvalue[column.id]}
          onChange={this.handleFieldChange}/>
      </td>
      )
    }

    if (column.type === "checkbox"){
        return(
        <td style={{verticalAlign:"middle"}}>
      <Form>
        <FormGroup inline name={column.id} onChange={this.handleRadioCheckChange}>
            {column.selectoptions.map((item, key=rowcolkey)=>this.getSelectItems(item, "checkbox", column['id'], rowcolkey))}
        </FormGroup>
      </Form>
        </td>
        )
    }
    if (column.type === "radio"){
    return(
        <td style={{verticalAlign:"middle"}}>
      <Form>
        <FormGroup inline name={column.id} onChange={this.handleRadioCheckChange}>
            {column.selectoptions.map((item, key=rowcolkey)=>this.getSelectItems(item, "radio", column['id'], rowcolkey))}
        </FormGroup>
      </Form>
        </td>
    )
    }
  }


  render(){

    return(  

        <tr>

        <td style={{width:this.props.surveyprops.style.featurewcolwidth}}>
        
        <div className="survey-list-features" style={{color:this.props.surveyprops.style.tablebodytext}} >
        
        <p style={{marginBottom:"5px", marginTop:"5px", fontSize:"1.3em", fontWeight:300, marginLeft:"15px"}}>{this.props.list.header}</p>
        
        {this.props.list.detail !== null && this.props.list.detail !== "" &&
        <p style={{marginBottom:"5px", fontsize:"0.8em", marginLeft:"15px"}}>{this.props.list.detail}</p>
        }
        
            {this.props.listprops.showprice &&
            <div style={{display:"flex", marginTop:"5px", marginBottom:"5px"}}> 
            <p style={{fontWeight:500}}>{this.props.list.price}</p>
            <p>{' '}</p>
            <p style={{fontSize:"smaller", fontWeight:200}}>{this.props.list.pricemetric}</p>
            </div>
            }
        
          </div>
       
        </td>

        {this.props.columns.map((column, key=column.id)=>this.renderSelectType(column))}
        
        </tr>
    )
}
}
import React, { Component } from "react";
import { ResponsiveCalendar } from '@nivo/calendar'


export default class DashCalendar extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        isLoading: false,
      };
    }


    render() {
        return (
          <div style={{height:"200px", width:"1000px"}}>   
                <ResponsiveCalendar
                    data={this.props.data}
                    from={"2020-07-01"}
                    to={"2020-12-31"}
                    emptyColor="#FFFFFF"
                    colors={[ '#BFD7FF', '#9BB1FF', '#788BFF', '#5465FF' ]}
                    minValue={0}
                    margin={{ top: 5, right: 5, bottom: 5, left: 30 }}
                    yearSpacing={5}
                    yearLegendOffset={5}
                    monthBorderWidth={2}
                    monthBorderColor="#5c687380"
                    dayBorderWidth={1}
                    dayBorderColor="#8f9ba680"

                />

          </div>
    
        );
      }
    }
    
import React, { Component } from "react";
import { API } from "aws-amplify";
import { Button, Card, CardBody, CardGroup, CardHeader, Col, Container, Row, Jumbotron } from 'reactstrap';
import SolidInfoCard from '../components/SolidInfoCard'
import SolidActionCard from '../components/SolidActionCard'
import InfoCardSmall from '../components/InfoCardSmall'
import DataTableSurvey from '../components/DataTableSurvey'
import SurveyAddCard from '../components/SurveyAddCard'

function truncateString(str, num) {
  if (typeof str === 'undefined'){
    return "..."
  }
  if (str === ""){
    return "..."
  }
  if (str === null){
    return "..."
  }
  if (str.length <= num) {
    return str
  }
  return str.slice(0, num) + '...'
}

function getTime(){
  let today = new Date();
  let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
  let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  let dateTime = date+' '+time;
  return(dateTime)
}

export default class Survey extends Component {
    constructor(props) {
      super(props);

      let nums = {}
      nums['numbersurvey']=0
      nums['draftsurvey']=0
      nums['completesurvey']=0
      nums['livesurvey']=0
      nums['completesurvey']=0
      let updatetable = true
      this.getRow = this.getRow.bind(this)
      this.updateCard = this.updateCard.bind(this)
      this.handleAction = this.handleAction.bind(this)
      this.routeAction = this.routeAction.bind(this)
      let initialrow = {}
      initialrow['surveyId']=null
      initialrow['status']=null
      this.state = {
        isLoading: false,
        numbersurvey:nums,
        row:initialrow,
        updatetable:updatetable
      };
    }


    async componentDidMount() {
      if (!this.props.isAuthenticated) {return};

      /// to handle page refresh will try to reset props from storage

      if(this.props.orgId === null){
        try{
          this.props.userHasEmail(localStorage.getItem('email'))
          this.props.userHasId(localStorage.getItem('userId'))
          this.props.userHasRole(localStorage.getItem('userRole'))
          this.props.userHasLicense(localStorage.getItem('userLicense'))
          this.props.userHasProduct(localStorage.getItem('userProduct'))
          this.props.userHasOrg(localStorage.getItem('orgId'))
        } catch {
          this.props.history.push("/home")
        }
      }

      this.props.userHasActiveSurvey(null)
    }


    

    async deleteSurvey() {
      const ftype = {}
      ftype['lastupdate']=getTime()
      ftype['email']= this.props.email
      ftype['orgId']=this.props.orgId
      ftype['userId']=this.props.userId
      ftype['id']=this.props.activeSurvey
      ftype['action']='Delete'
      let response = await API.put("survey", "/survey",{body:ftype})
      this.props.userHasActiveSurvey(null)
      this.setState({updatetable:true})
     
  }


    /// need to handle other actions like publish, stop and delete
    handleAction(action){

      if(this.state.row !==null){

        if(["DRAFT", "PENDING", "TEST PENDING", "TEST", "LIVE", "COMPLETE"].includes(this.state.row.status)){
          if(action === 'Edit'){
            this.props.history.push("/surveys/editor")
            }
        }

        if(["LIVE", "COMPLETE"].includes(this.state.row.status)){
          if(action === "Results"){
          this.props.history.push("/surveys/responses/analysis")
          }
          if(action === "Stop"){
            this.props.history.push("/surveys/editor")
          }
        }


        if(this.props.activeSurvey !== undefined && this.props.activeSurvey !== null) {

          if(action==='Delete'){

            this.deleteSurvey()

          }
        }
        

        
      }
    }



/// same as function in survey editor which allows you to publish and change settings in the SurveyPropsPage


    updateCard(n){
      this.setState({numbersurvey:n, updatetable:false})
    }

    getRow(s, r){
      if(s===true){
        this.setState({row:r}, ()=> this.props.userHasActiveSurvey(r))
      }
    } 

    routeAction(survey, stat){

      this.props.userHasActiveSurvey(null)

      this.props.history.push("surveys/editor")

    }

    render() {
        return (
          <div>

          <Jumbotron style={{marginBottom:"30px", marginTop:"0px", padding:"0px"}}>
                <h1>Surveys</h1>
                <h5 style={{fontWeight:"200"}}>The list of your surveys, their publishing status and links to edit or review</h5>
                <hr/>
                <p style={{marginTop:"15px"}}>{` `}</p>
           </Jumbotron>

            <Row>
                <Col sm="6" md="2">
                  
                  <SolidInfoCard  color="dark" header={this.state.numbersurvey['numbersurvey']} value="100" invert >Total Surveys</SolidInfoCard>

                </Col>
                <Col sm="6" md="2">
                  <SolidInfoCard  color="secondary" header={this.state.numbersurvey['draftsurvey']} value="100" >Draft</SolidInfoCard>
                </Col>
                <Col sm="6" md="2">
                  <SolidInfoCard  color="primary" header={this.state.numbersurvey['livesurvey']} value="100" >Live</SolidInfoCard>
                </Col>
                <Col sm="6" md="2">
                  <SolidInfoCard color="light" header={this.state.numbersurvey['completesurvey']} value="100" >Complete</SolidInfoCard>
                </Col>
               

                {this.props.activeSurvey !==null && 
                  <Col sm="6" md="2">
                    <div style={{width:"100%", height:"100%", paddingBottom:"1.5rem"}}>
                      <SolidActionCard icon="icon-layer" color="primary" invert
                      header={this.props.activeSurvey} value="100"
                      title={truncateString(this.props.activeSurveyData['surveytitle'],22)}
                      actionstatus={this.props.activeSurveyData.status}
                      surveyId={this.props.activeSurvey}
                      handleAction={this.handleAction}
                      actiontype="Survey">
                        {`${this.state.row.status} SURVEY`}</SolidActionCard>
                  </div>
                  </Col>
                }
                <Col sm="6" md="2" >
                <SurveyAddCard
                  color="dark"
                  invert
                  value="100"
                  type="survey"
                  routeAction={this.routeAction}
                  />
                </Col>
              </Row>
              {this.props.orgId &&
              <DataTableSurvey
                getRow={this.getRow}
                orgId={this.props.orgId}
                activeSurvey={this.props.activeSurvey}
                userHasActiveSurvey={this.props.userHasActiveSurvey}
                updateCard={this.updateCard}
                updatetable={this.state.updatetable}
                />
              }

          </div>
    
        );
      }
    }
    
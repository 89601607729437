import React, { Component } from "react";
import { Button, Card, CardBody, CardGroup, CardHeader, Col, Container, Row, Jumbotron } from 'reactstrap';
import { ResponsiveBar } from '@nivo/bar'

export default class DashBar extends Component {
    constructor(props) {
      super(props);
      let showlegend = true
      if(this.props.chartkeys[0]==="responses"){
        showlegend = false
      }
      this.state = {
        isLoading: false,
        showlegend: showlegend
      };
    }

    getLegend(){

      if(this.state.showlegend){
        return(
          [
            {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 100,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 60,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 10,
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]
        )
      } else {
        return []
    }
    }

    getLegendLoc(){
      if(this.state.showlegend){
        return 120
      } else {
        return 15
      }
    }


    render() {
        return (
          <div style={{height:"400px"}}>   
                <ResponsiveBar
                    data={this.props.data}
                    keys={this.props.chartkeys}
                    indexBy={this.props.chartindex}
                    groupMode={this.props.groupmode}
                    margin={{ top: 15, right: this.getLegendLoc(), bottom: 45, left: 15 }}
                    padding={0.4}
                    colors={{ scheme: 'paired' }}
                    borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                    enableGridY={false}
                    defs={[
                      {
                          id: 'lines',
                          type: 'patternLines',
                          background: "#20a8d8",
                          color: '#63c2d33f',
                          rotation: -45,
                          lineWidth: 1,
                          spacing: 20
                      },
                      {
                        id: 'best',
                        type: 'patternLines',
                        background: "#a1d99b",
                        color: '#63c2d33f',
                        rotation: -45,
                        lineWidth: 1,
                        spacing: 20
                      },
                      {
                        id: 'worst',
                        type: 'patternLines',
                        background: "#fc9272",
                        color: '#63c2d33f',
                        rotation: -45,
                        lineWidth: 1,
                        spacing: 20
                    },
                    {
                      id: 'none',
                      type: 'patternDots',
                      background: "#f0f3f5",
                      color: '#8f9ba6',
                      size: 1,
                      padding: 8,
                  }
                  ]}
                  fill={[
                      {
                          match: {
                              id: 'responses'
                          },
                          id: 'lines'
                      },
                      {
                        match: {
                            id: 'best'
                        },
                        id: 'best'
                    },
                    {
                      match: {
                          id: 'worst'
                      },
                      id: 'worst'
                    },
                    {
                      match: {
                          id: 'noresponses'
                      },
                      id: 'none'
                    },



                  ]}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                    }}
                    axisLeft={null}
                    labelSkipWidth={12}
                    labelSkipHeight={12}
                    labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                    animate={true}
                    motionStiffness={90}
                    motionDamping={15}
                    legends={this.getLegend()}
                />

          </div>
    
        );
      }
    }
    
import React, { Component } from "react";
import { API } from "aws-amplify";
import Loader from "react-loader-spinner";
import DataTable, {createTheme} from 'react-data-table-component';
import { Button, Card, CardBody, CardGroup, CardHeader, Col, Container, Row } from 'reactstrap';
import PackageExpanderMenu from './PackageExpanderMenu'

/// also search, filter and download csv - see examples https://jbetancur.github.io/react-data-table-component/?path=/story/filtering--example-1
/// need to only show those with same ORG ID

/// gets all survey responses
const conditionalRowStyles = [
  {
    when: row => row.status === "LIVE",
    style: {
      fontWeight: "500",
      },
    },
    {
      when: row => row.selectedrow === true,
      style: {
        fontWeight: "500",
        backgroundColor:"#2f353a",
        color: "#20a8d8",
        },
      },
];

createTheme('pricekit', {
  text: {
    primary: '#2f353a',
    secondary: '#5c6873',
  },
  background: {
    default: 'transparent',
  },
  context: {
    background: '#20a8d8',
    text: '#f0f3f5',
  },
  divider: {
    default: '#f0f3f5',
  },
  action: {
    button: 'rgba(0,0,0,.54)',
    hover: 'rgba(0,0,0,.08)',
    disabled: 'rgba(0,0,0,.12)',
  },
});


const columns = [
  {
    name: 'Package',
    selector: 'packageId',
    sortable: true,
    width:'100px'
  },
  {
    name: 'Option Header',
    selector: 'optionheader',
    sortable: true,
    width:'200px'
  },
  {
    name: 'Features',
    selector: 'features',
    sortable: true,
    width:'300px'
  },
  {
    name: 'Show Features',
    selector: 'showfeatures',
    sortable: true,
    width:'80px'
  },
  {
    name: 'Price',
    selector: 'price',
    sortable: true,
    width:'80px'
  },
  {
    name: 'Price Metric',
    selector: 'pricemetric',
    sortable: true,
    width:'100px'
  },
  {
    name: 'Show Price',
    selector: 'showprice',
    sortable: true,
    width:'80px'
  },
  {
    name: 'Last Update',
    selector: 'localsavetime',
    sortable: true,
    width:'100px'
  },
]

export default class DataTablePackage extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isLoading: true,
        data: []
      };
    }

    async componentDidMount() {
        try{
          let querystring = {}
          let orgquery = {}
          orgquery['orgId']=this.props.orgId
          orgquery['querytype']='ALL'
          querystring['queryStringParameters'] = orgquery
          let responses = await API.get("packages", "/packages", querystring)
          let numberquestions = responses.length
          let nums = {}
          nums['numberquestions']= numberquestions

          let featheads = []
          let feat = []
          for(let i in responses){
            featheads = []
            responses[i]['selectedrow'] = false

            if(responses[i]['features']==='[]'){
              responses[i]['features']=''
            } else {
              feat = JSON.parse(responses[i]['features'])
              for(let f in feat){
                featheads.push(feat[f]['header'])
              }
              responses[i]['features']=featheads
            }
          }


          this.setState({data: responses, isLoading:false}, ()=>this.props.updateCard(nums))

        } catch (e) {
            alert('There was problem getting package list', e);
        }
        }


        componentDidUpdate(prevProps, prevState){
          if(this.props.modifiedpackage !==prevProps.modifiedpackage){
            this.setState({isLoading:true},()=>this.updateDataTable())
          }
          if(this.props.updatepackage !==prevProps.updatepackage){
            if(this.props.updatepackage === true){
              this.setState({isLoading:true},()=>this.updateDataTable())
            }
          }
        }


        async updateDataTable(){
          try{
            let querystring = {'queryStringParameters':{'orgId':this.props.orgId, 'updateitem':this.props.modifiedfeature}}
            let updateresponses = await API.get("packages", "/packages", querystring)
            
            let numberquestions = updateresponses.length
            let nums = {}
            nums['numberquestions']= numberquestions
  
            let featheads = []
            let feat = []
            for(let i in updateresponses){
              featheads = []
              updateresponses[i]['selectedrow'] = false
  
              if(updateresponses[i]['features']==='[]'){
                updateresponses[i]['features']=''
              } else {
                feat = JSON.parse(updateresponses[i]['features'])
                for(let f in feat){
                  featheads.push(feat[f]['header'])
                }
                updateresponses[i]['features']=featheads
              }
            }
            
            this.setState({data: updateresponses, isLoading:false}, ()=>this.props.updateCard(nums))
  
          } catch (e) {
              alert('There was problem getting package list', e);
          }
          }

          



        updateState = state => {

          if(state.packageId !== this.state.selectedquestion){

            let datacopy = [...this.state.data]
            
            for(let i in datacopy){
              if(datacopy[i]['packageId']===state.packageId){
                datacopy[i]['selectedrow']=true
              }
              if(datacopy[i]['packageId']===this.state.selectedquestion){
                datacopy[i]['selectedrow']=false
              }
            }

          this.setState({ selectedquestion: state.packageId, data:datacopy},()=>this.props.getRow(true, state));
          }
        }



        updateStateToggle = (togglestate, row) => {

        }



    render() {
        return (
          <div>
            
            {this.state.isLoading && <Loader type="Puff" color="#20a8d8" height={100} width={100} timeout={3000}/>}


            <DataTable style={{overflowX:"hidden"}}
            keyField={"surveyId"}
            title="Package List"
            theme="pricekit"
            columns={columns}
            data={this.state.data}
            defaultSortField="localsavetime"
            defaultSortAsc={false}
            selectableRows={false}
            selectableRowsNoSelectAll={true}
            selectableRowsHighlight={true}
            expandableRows={true}
            expandableRowsComponent={<PackageExpanderMenu />}
            onRowExpandToggled={this.updateStateToggle}
            onRowClicked={this.updateState}
            expandOnRowClicked={true}
            pagination={false}
            highlightOnHover={false}
            striped={false}
            pointerOnHover={true}
            dense={false}
            noTableHead={false}
            persistTableHead={true}
            progressPending={false}
            noHeader={true}
            subHeader={false}
            subHeaderAlign={"right"}
            fixedHeader={true}
            fixedHeaderScrollHeight="400px"
            conditionalRowStyles={conditionalRowStyles}
      />
      </div>
      );
    }
}
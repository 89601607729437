import React, { Component } from "react";
import {Button,  Card, CardBody, CardFooter, CardDeck, Nav, NavLink, Jumbotron, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import SurveyPropsPage from "./SurveyPropsPage"
import SurveyQuestionEditor from '../survey/SurveyQuestionEditor'
import {toast } from 'react-toastify';
import RenderCards from './RenderCards'
import RenderChoices from './RenderChoices'
import RenderList from './RenderList'
import RenderDesign from './RenderDesign'
import RenderAutoChoices from './RenderAutoChoices'
import RenderWinners from './RenderWinners'

/// SurveyCanvas - blank pages for SurveyEditor
/// see *** SurveyPage ***

function shuffle(array) {
  var currentIndex = array.length, temporaryValue, randomIndex;
  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }
  return array;
}

export default class SurveyCanvas extends Component {
  constructor(props) {
    super(props);
    this.onChangeSaveState = this.onChangeSaveState.bind(this)
    this.onValidPage = this.onValidPage.bind(this)
    this.state = {
        pagevalid: true,
        savedresponses: {},
        savedpages:{},
      };
    }
   
  async componentDidMount() {
  }

  /// will show TOAST if in props on page change and will push back up the page to surveyeditor
  async handlePageChange(e){
    if(this.props.page.pageprops.showquestiontoast){
      toast.info(this.props.page.pageprops.questiontoast)
    }
    this.props.onPageChange(e)
  }

  /// TODO page validation logic
  onValidPage(v){
    this.setState({pagevalid:v})
  }

  /// TODO - field validations
  validateForm() {
    return (true)
    }


  
    /// used to save the changes for the survey page selections, used so customer can see  selection behaviors.  
    /// for session use only, will not save to aws or local storage
  onChangeSaveState(p, s){
    this.setState(prevState => ({
      savedresponses: {
      ...prevState.savedresponses,          
      [p]: s
      },
      savedpages: {
        ...prevState.savedpages,          
        [p]: true
      }
      }))
  }


  /// shows a blank card with instructions before the user has selected the question type to work with
  renderBlank(){      
    return(
      <CardDeck>
      <Card>
        <CardBody>
        <Jumbotron className="survey-content-jumbotron2" style={{cursor:"pointer"}} onClick={()=>this.props.updateNewPageType("SurveyCard", this.props.currentpage)}>
                    <h1>Survey Page</h1>
                    <h4>Used for asking <mark>questions</mark> and showing <mark>content</mark> </h4>
                    <hr/>
                </Jumbotron>
        </CardBody>
      </Card>
      <Card>
      <CardBody>
      <Jumbotron className="survey-content-jumbotron2" style={{cursor:"pointer"}} onClick={()=>this.props.updateNewPageType("SurveyChoice", this.props.currentpage)}>
                    <h1>Choice Page</h1>
                    <h4>Used for choosing amongst <mark>options</mark> and understanding <mark>preferences</mark> </h4>
                    <hr/>
              </Jumbotron>
      </CardBody>
      </Card>
          <Card>
          <CardBody>
          <Jumbotron className="survey-content-jumbotron2" style={{cursor:"pointer"}} onClick={()=>this.props.updateNewPageType("SurveyList", this.props.currentpage)}>
                    <h1>Grid Page</h1>
                    <h4>Used for capturing <mark>details</mark> on related items and surfacing <mark>correlations</mark> </h4>
                    <hr/>
                  </Jumbotron>
          </CardBody>
        </Card>
            <Card>
            <CardBody>
            <Jumbotron className="survey-content-jumbotron2" style={{cursor:"pointer"}} onClick={()=>this.props.updateNewPageType("DesignCard", this.props.currentpage)}>
                     <h1>Design Page</h1>
                    <h4>Used for getting <mark>feedback</mark> on concepts and identifying sources of <mark>issues</mark> </h4>
                    <hr/>
                    </Jumbotron>
            </CardBody>
          </Card>
          </CardDeck>
    )}

  renderSurveyProps(){
      return(
        <SurveyPropsPage
          surveyprops={this.props.surveyprops}
          stateChangePassThru={this.onChangeSaveState}
          onPageValid={this.onValidPage}
          toggleSidebar={this.props.toggleSidebar}
          activeQuestion={this.props.activeQuestion}
          currentquestion={this.props.currentquestion}
          activeCard={this.props.activeCard}
          currentcard={this.props.currentcard}
          currentpage={this.props.currentpage}
          updateSurveyProps={this.props.updateSurveyProps}
          activeSurvey={this.props.activeSurvey}
          saveSurvey={this.props.saveSurvey}
          isNew={this.props.isNew}
          newPage={this.props.newPage}
          isSaved={this.props.isSaved}
          packages={this.props.packages}
          />
      )}

    renderCards(qid, qtype, qprops, qcards, qlist, pprops, savedpages, savedresponses){

      if(qtype === 'SurveyChoice'){
        return(<RenderChoices 
          key={qid} 
          surveyprops={this.props.surveyprops}
          page={this.props.page}
          pageprops={pprops}
          cards={qcards} 
          questionprops={qprops} 
          questionId={qid}
          savedpages={savedpages}
          savedresponses={savedresponses}
          stateChangePassThru={this.onChangeSaveState}
          handlePageValid={this.onValidPage}
          activeQuestion={this.props.activeQuestion}
          currentquestion={this.props.currentquestion}
          activeCard={this.props.activeCard}
          currentcard={this.props.currentcard}
          currentpage={this.props.currentpage}
          newCard={this.props.newCard}
          toggleLightbox={this.props.toggleLightbox}
          moveCards={this.props.moveCards}
          updatePageTitle={this.props.updatePageTitle}
          packages={this.props.packages}
          swapCard={this.props.swapCard}
          />)}

      if(qtype === 'AutoChoice'){
        return(<RenderAutoChoices 
          key={qid} 
          surveyprops={this.props.surveyprops}
          page={this.props.page}
          pageprops={pprops}
          cards={qcards} 
          questionprops={qprops} 
          questionId={qid}
          savedpages={savedpages}
          savedresponses={savedresponses}
          stateChangePassThru={this.onChangeSaveState}
          handlePageValid={this.onValidPage}
          activeQuestion={this.props.activeQuestion}
          currentquestion={this.props.currentquestion}
          activeCard={this.props.activeCard}
          currentcard={this.props.currentcard}
          currentpage={this.props.currentpage}
          newCard={this.props.newCard}
          toggleLightbox={this.props.toggleLightbox}
          moveCards={this.props.moveCards}
          updatePageTitle={this.props.updatePageTitle}
          packages={this.props.packages}
          swapCard={this.props.swapCard}
          />)}
      
        if(qtype === 'ChoiceWinners'){
          return(<RenderWinners 
            key={qid} 
            surveyprops={this.props.surveyprops}
            page={this.props.page}
            pageprops={pprops}
            cards={qcards} 
            questionprops={qprops} 
            questionId={qid}
            savedpages={savedpages}
            savedresponses={savedresponses}
            allresponses={this.state.savedresponses}
            stateChangePassThru={this.onChangeSaveState}
            handlePageValid={this.onValidPage}
            activeQuestion={this.props.activeQuestion}
            currentquestion={this.props.currentquestion}
            activeCard={this.props.activeCard}
            currentcard={this.props.currentcard}
            currentpage={this.props.currentpage}
            newCard={this.props.newCard}
            toggleLightbox={this.props.toggleLightbox}
            moveCards={this.props.moveCards}
            updatePageTitle={this.props.updatePageTitle}
            packages={this.props.packages}
            swapCard={this.props.swapCard}
            />)}

      if(qtype === 'DesignCard'){
          return(<RenderDesign
            key={qid}
            surveyprops={this.props.surveyprops}
            page={this.props.page}
            options={this.props.page.questions[0].cards} 
            questionprops={qprops} 
            pageprops={pprops}
            questionId={qid}
            savedpages={savedpages}
            savedresponses={savedresponses}
            stateChangePassThru={this.onChangeSaveState}
            handlePageValid={this.onValidPage}
            updatePageTitle={this.props.updatePageTitle}
            updateCardHeaders={this.props.updateCardHeaders}
            activeCard={this.props.activeCard}
            currentcard={this.props.currentcard}
            currentpage={this.props.currentpage}
            activeQuestion={this.props.activeQuestion}
            />)}

      if(qtype === 'SurveyList'){
        return(<RenderList 
          key={qid}
          surveyprops={this.props.surveyprops}
          pageprops={pprops}
          list={qlist} 
          questionprops={qprops} 
          questionId={qid}
          savedpages={savedpages}
          savedresponses={savedresponses}
          stateChangePassThru={this.onChangeSaveState}
          handlePageValid={this.onValidPage}
          activeQuestion={this.props.activeQuestion}
          updatePageTitle={this.props.updatePageTitle}
          saveItem={this.props.saveItem}
          updateListHeaders={this.props.updateListHeaders}
          />)}
      
      if(qtype === 'SurveyCard'){
      return(
        <RenderCards 
          key={qid} 
          surveyprops={this.props.surveyprops}
          cards={qcards} 
          questiontype={qtype} 
          questionprops={qprops} 
          questionId={qid}
          list={qlist} 
          pageprops={pprops}
          savedpages={savedpages}
          savedresponses={savedresponses}
          stateChangePassThru={this.onChangeSaveState}
          onPageValid={this.onValidPage}
          toggleSidebar={this.props.toggleSidebar}
          activeQuestion={this.props.activeQuestion}
          currentquestion={this.props.currentquestion}
          activeCard={this.props.activeCard}
          currentcard={this.props.currentcard}
          currentpage={this.props.currentpage}
          newCard={this.props.newCard}
          toggleLightbox={this.props.toggleLightbox}
          moveCards={this.props.moveCards}
          updatePageTitle={this.props.updatePageTitle}
          updateCardHeaders={this.props.updateCardHeaders}
          updateContentHeaders={this.props.updateContentHeaders}
          />
          )}

      }

    getBackground(){
      if(this.props.page.pageprops.questiontype === "SurveyProps"){
        return("#2f353a")
      } else {
        return(this.props.surveyprops.style.pagebodycolor)
      }
    }




  render(){
    return(
      
      <div>


        <div>
            {this.props.page.pageprops.showtitle &&
            <div className="survey-page-headings" style={{color:this.props.surveyprops.style.pageheadtextcolor}}>
                <h2>{this.props.page.pageprops.pagetitle}</h2>
                <h4>{this.props.page.pageprops.pagesubtitle}</h4>
            </div>
            }

        <Card style={{minHeight:"70vh", backgroundColor:this.getBackground()}}>
      
            <CardBody className="survey-page-card-body">
        
            <div style={{display:"flex", flexGrow:"1",  flexWrap:"wrap"}}>
            
            {this.props.page.pageprops.questiontype === "NewPage" && this.renderBlank()
            }

            {this.props.page.pageprops.questiontype === "SurveyProps" && this.renderSurveyProps()
            }

            {this.props.page.pageprops.questiontype !== "NewPage" && this.props.page.pageprops.questiontype !== "SurveyProps" && 
            
            this.props.page.questions.map((question, key=question.questionId)=>this.renderCards(
              question.questionId,
              question.questiontype, 
              question.questionprops, 
              question.cards,
              question.list, 
              this.props.page.pageprops,
              this.state.savedpages[question.questionId],
              this.state.savedresponses[question.questionId]))}
                    
            </div>

            </CardBody>


            {this.props.page.pageprops.questiontype !== "SurveyProps" && 

            <CardFooter style={{backgroundColor:this.props.surveyprops.style.pagefootcolor}}>

            <div style={{display:"flex"}}>

              {!this.props.pageorder[this.props.currentpagenum].isFirst &&
              <button className="btn btn-pill btn-md btn-secondary" 
              onClick={() => this.handlePageChange("B")}><span className="fa fa-chevron-left"/>{this.props.page.pageprops.backbutton}</button>
              }

              {this.props.pageorder[this.props.currentpagenum].isFirst &&
              <button className="btn btn-pill btn-md btn-secondary" 
              disabled>{this.props.page.pageprops.backbutton}</button>
              }

              {!this.props.pageorder[this.props.currentpagenum].isLast && 
              <button className="btn btn-pill btn-md btn-primary" 
              style={{marginLeft:"auto"}} 
              onClick={() => this.handlePageChange("F")}>{this.props.page.pageprops.nextbutton}<span className="fa fa-chevron-right"/></button>
              }

              {this.props.pageorder[this.props.currentpagenum].isLast && 
              <button className="btn btn-pill btn-md btn-primary" disabled style={{marginLeft:"auto"}} 
              onClick={() => console.log("DO SOMETHING AT THE END")}>{this.props.surveyprops.pagepropdefaults.completebutton}</button>
              }

            </div>

            </CardFooter>

            }


        </Card>
        <div style={{display:"flex", fontSize:"0.75em"}}>
            {this.props.page.pageprops.showpagehint && this.props.page.pageprops.pagehint}
            {this.props.page.pageprops.showpagenums && 
              <div style={{marginLeft:"auto"}}>page {this.props.currentpagenum + 1} of {this.props.totalpages}</div>}  
        </div>
        </div>
        </div>
    );
  }
}
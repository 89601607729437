import React, { Component } from "react";
import { Button, Card, CardBody, CardGroup, CardHeader, Col, Container, Row } from 'reactstrap';

export default class QuestionExpanderMenu extends Component {
    constructor(props) {
      super(props);
      let jsoncontent = JSON.parse(this.props.data.content)
      this.state = {
        content: jsoncontent
      };
    }

    getSelectOptions(option){
      return(
          <div style={{marginTop:"0px", marginBottom:"0px"}}>
          <p style={{color:'#5c6873'}}><small>{` ${option['label']}`}</small></p>
          </div>
      )
  }

  /// the :: pattern I used to concat col and row into single question header
  getRowColumn(option){

    let head = option.search("::")
    let colhead = option.slice(0,head)
    let rowname = option.slice(head+2)

    return (
      <Row>
      <div style={{marginLeft:"15px", marginRight:"15px", marginTop:"10px", display:"flex", justifyContent:"space-around"}}>
      <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-start",marginLeft:"15px"}}>
          <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Question Text</small></p>
          <p style={{color:'#5c6873'}}><small>{`Column Question:  ${colhead}`}</small></p>
          <p style={{color:'#5c6873'}}><small>{`Row Question Target:  ${rowname}`}</small></p>
      </div>
      </div>
      </Row>

    )
  }

    render() {
        return (
          <div style={{backgroundColor:"#f0f3f5", marginLeft:"50px"}}>
            {this.props.data.pagetype !== "SurveyList" &&
            <Row>
            <div style={{marginLeft:"15px", marginRight:"15px", marginTop:"10px", display:"flex", justifyContent:"space-around"}}>
            <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-start",marginLeft:"15px"}}>
                <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Question Text</small></p>
                <p style={{color:'#5c6873'}}><small>{this.props.data.questionheader}</small></p>
            </div>
            </div>
            </Row>
            }
            {this.props.data.pagetype === "SurveyList" &&
            <div>
            {this.getRowColumn(this.props.data.questionheader)}
            </div>
            }
            <Row>
          <div style={{marginLeft:"15px", marginRight:"15px", marginTop:"10px", display:"flex", justifyContent:"space-around"}}>
      

          <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-start",marginLeft:"15px"}}>
          <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Updated By</small></p>
          <p style={{color:'#5c6873'}}><small>{this.props.data.email}</small></p>
          </div>

          <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-start",marginLeft:"15px"}}>
          <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Page Type</small></p>
          <p style={{color:'#5c6873'}}><small>{this.props.data.pagetype}</small></p>
          </div>


          {this.props.data.questiontype === "slider"  &&
          <div style={{display:"flex", flexDirection:"row"}}>

            <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-start",marginLeft:"15px"}}>
            <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Slider Default</small></p>
            {this.state.content.sliderdefault !== null &&
            <p style={{color:'#5c6873'}}><small>{this.state.content.sliderdefault}</small></p>
            }
            {this.state.content.sliderdefault === null &&
            <p style={{color:'#5c6873'}}><small>{"n/a"}</small></p>
            }
            </div>

            <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-start",marginLeft:"15px"}}>
            <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Range Default</small></p>
            {this.state.content.rangedefault !== null &&
            <p style={{color:'#5c6873'}}><small>{this.state.content.rangedefault}</small></p>
            }
            {this.state.content.rangedefault === null &&
            <p style={{color:'#5c6873'}}><small>{"n/a"}</small></p>
            }
            </div>

            <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-start",marginLeft:"15px"}}>
            <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Slider Max</small></p>
            {this.state.content.slidermax !== null &&
            <p style={{color:'#5c6873'}}><small>{this.state.content.slidermax}</small></p>
            }
            {this.state.content.slidermax === null &&
            <p style={{color:'#5c6873'}}><small>{"n/a"}</small></p>
            }
            </div>

            <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-start",marginLeft:"15px"}}>
            <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Slider Step</small></p>
            {this.state.content.sliderstep !== null &&
            <p style={{color:'#5c6873'}}><small>{this.state.content.sliderstep}</small></p>
            }
            {this.state.content.sliderstep === null &&
            <p style={{color:'#5c6873'}}><small>{"n/a"}</small></p>
            }
            </div>
            <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-start",marginLeft:"15px"}}>
            <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Slider Marks</small></p>
            {this.state.content.slidermarks !== null &&
            <p style={{color:'#5c6873'}}><small>{JSON.stringify(this.state.content.slidermarks)}</small></p>
            }
            {this.state.content.slidermarks === null &&
            <p style={{color:'#5c6873'}}><small>{"n/a"}</small></p>
            }
            </div>

          </div>
          }
          {this.props.data.questiontype === "allocation" &&
          <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-start",marginLeft:"15px"}}>
          <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Allocation Amount</small></p>
          <p style={{color:'#5c6873'}}><small>{this.state.content.allocationamount}</small></p>
          </div>
          }

          
            {["radio", "checkbox", "select", "multiselect", "selectcreatable", "multiselectcreatable"].includes(this.props.data.questiontype) && 
            <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-start",marginLeft:"15px"}}>
                <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Select Options</small></p>
                {this.state.content.selectoptions.map((option) => this.getSelectOptions(option))}
            </div>
            }


          </div>
          </Row>
          </div>
      );
    }
}
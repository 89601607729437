import React, { Component } from "react";
import { API } from "aws-amplify";
import {  Button, Input, Form, FormGroup, Row, Col, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Jumbotron,  ModalHeader, ModalBody, ModalFooter, CustomInput} from 'reactstrap';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable'
import 'rc-slider/assets/index.css';
import Toggle from 'react-toggle'
import  'react-toggle/style.css'



/// styling for react-select
const customStyles = (isValid) => ({
    control: (styles: any, state:any) => ({
      ...styles,
      padding: 5,
      width:"100%",
      fontSize: "smaller",
      ...(!isValid && { borderColor: 'red' }),
    }),
  });

    /// styling for react-select
const customStyles2 = (isValid) => ({
  option: (provided, state) => ({
    ...provided,
    fontSize: '1em',
    borderBottom: '1px dotted black',
    backgroundColor: state.isSelected ? '#2f353a' : 'white',
    color: state.isSelected ? '#20a8d8' : '#2f353a',
    padding: 20,
  }),
    control: (styles: any, state:any) => ({
      ...styles,
      color:"#2f353a",
      backgroundColor:"#f0f3f5",
      padding: 5,
      fontSize: "smaller",
      ...(!isValid && { borderColor: 'red' }),
    }),
  });


  function makeId(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }

 function truncateString(str, num) {
    if (typeof str === 'undefined'){
      return "..."
    }
    if (str.length <= num) {
      return str
    }
    return str.slice(0, num) + '...'
  }

  function getTime(){
    let today = new Date();
    let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    let dateTime = date+' '+time;
    return(dateTime)
  }

export default class FeatureEditorModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
        isValid: false,
        isdeleting: false,
        pricetoggle: false,
        featureId:this.props.feature.featureId,
        header: this.props.feature.header,
        detail:this.props.feature.detail,
        featuretype:this.props.feature.featuretype,
        price:this.props.feature.price,
        pricemetric:this.props.feature.pricemetric,
        isActive:this.props.feature.isActive,

       };
    }
   
  async componentDidMount(){ 
  }


        
  getIsActive(){
    if(this.state.isActive === false){
        return(
            <div style={{fontSize:"0.7em", display:"flex", fontWeight:"300", marginBottom:"0px"}}>
            <p>Feature is: not active</p>
            <p style={{color:'#20a8d8', marginLeft:"3px"}}>{` `}</p>
            <p style={{color:'#20a8d8', marginRight:"5px"}}></p>
            </div>
        )
    }
    else {
    return (
        <div style={{fontSize:"0.7em", display:"flex", fontWeight:"300", marginBottom:"0px"}}>
        <p>Feature is: </p>
        <p style={{color:'#20a8d8', marginLeft:"3px"}}>active</p>
        <p style={{color:'#20a8d8', marginRight:"5px"}}></p>
        </div>
        )
    }
}
    handleFieldChange = (event) => {
      const target = event.target;
      let value = target.value;
      const name = target.name;
      this.setState({[name]: value})
    }

    handleToggleChange(i){
        this.setState({[i]:!this.state[i]})
        }
    
    updateOptions = (selectvalue, action) => {

        this.setState({[action.name]:selectvalue['label']})

        if (selectvalue['__isNew__']===true){
          
          let ftyperef = this.props.featuretype
          let ftype={}
          ftype['localsavetime']=getTime()
          ftype['email']= this.props.email
          ftype['orgId']=this.props.orgId
          ftyperef.push({'label':selectvalue['label'],'value':selectvalue['value']})
          ftype['featuretype']=ftyperef

          let response = API.put("reference", "/reference", {body:ftype})

        }
    };


    deleteItem(){
        let feature = {}
        feature['featureId']=this.state.featureId
        this.props.deleteItem(feature)
    }

    saveFeature(){
      console.log('modal saving features....')
        let feature = {}
        feature['featureId']=this.state.featureId
        feature['header']=this.state.header
        feature['detail']=this.state.detail
        feature['price']=this.state.price
        feature['pricemetric']=this.state.pricemetric
        feature['featuretype']=this.state.featuretype
        feature['isActive']=this.state.isActive
        feature['action']='Save'
        this.props.saveFeature(feature)
    }
    



  render(){

    return(
    <div>
        <ModalHeader style={{backgroundColor:"#2f353a", color:"#8f9ba6", fontSize:"1.2em", fontWeight:"200"}}>
            {`Feature:  ${this.props.feature['featureId']} ${this.props.feature['header']}`}
            {this.getIsActive()}
            {this.state.isdeleting &&
            <p style={{color:'#f86c6b', fontSize:"0.8em", fontWeight:"200", marginTop:"0px"}}>Please confirm that you want to delete this item</p>
            }
            </ModalHeader>
        <ModalBody>


        <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Feature Name</p>

            <Input type="text" name={"header"} id={"header"}
            value={this.state.header}
            placeholder={"Feature..."}
            style={{marginBottom:"10px", backgroundColor:"#f0f3f5", fontSize:"1.2em", fontWeight:"200"}}
            onChange={this.handleFieldChange}
            />

            <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Feature Description</p>

            <Input type="text" name={"detail"} id={"detail"}
            value={this.state.detail}
            placeholder={"Detail description..."}
            style={{marginBottom:"10px", backgroundColor:"#f0f3f5", fontSize:"1.2em", fontWeight:"200"}}
            onChange={this.handleFieldChange}
            />

            {this.state.pricetoggle &&
            <div style={{display:"flex", width:"100%"}}>

            <div style={{display:"flex", flexDirection:"column"}}>
            <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Component Price</p>

            <Input type="number" name={"price"} id={"price"}
            value={this.state.price}
            style={{marginBottom:"10px", backgroundColor:"#f0f3f5", fontSize:"1.2em", fontWeight:"200"}}
            onChange={this.handleFieldChange}
            />
            </div>
            <div style={{display:"flex", flexDirection:"column", flexGrow:"1", marginLeft:"15px"}}>
            <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Price Metric</p>

            <Input type="text" name={"pricemetric"} id={"pricemetric"}
            value={this.state.pricemetric}
            style={{marginBottom:"10px", backgroundColor:"#f0f3f5", fontSize:"1.2em", fontWeight:"200", width:"100%"}}
            onChange={this.handleFieldChange}
            />
            </div>

            </div>
            
            }
        
            <div style={{display:"flex", flexDirection:"row",  padding:"30px", marginLeft:"-16px", marginRight:"-16px", marginBottom:"-16px", marginTop:"50px", color:"white", fontSize:"0.8em", backgroundColor:"#2f353a" }}>
                
                <div style={{flexDirection:"column", alignItems:"flex-end", marginLeft:"15px", width:"200px"}}>
                <p>Feature Type/Level</p>
                    <CreatableSelect
                    defaultValue={{label:this.state.featuretype, value:this.state.featuretype}}
                    onChange={this.updateOptions}
                    options={this.props.featuretype}
                    id={'select'+this.state.featureId}
                    name={"featuretype"}
                    styles={customStyles2(true)}
                    />

                </div>
                <div style={{flexDirection:"column", alignItems:"flex-end", marginLeft:"15px"}}>
                <p>Active Feature</p>
                <Toggle
                    id={'isActive'}
                    checked= {this.state.isActive}
                    icons={{unchecked: null}}
                    className = {"custom-success"}
                    onChange = {() => this.handleToggleChange('isActive')}
                        />
                </div>
                <div style={{flexDirection:"column", alignItems:"flex-end", marginLeft:"15px"}}>
                <p>Component Price</p>
                <Toggle
                    id={'pricetoggle'}
                    checked= {this.state.pricetoggle}
                    icons={{unchecked: null}}
                    className = {"custom-success"}
                    onChange = {() => this.handleToggleChange('pricetoggle')}
                        />
                </div>
            </div>

        </ModalBody>
        <ModalFooter style={{marginTop:"0"}}>
        <div style={{alignSelf:"flex-start", marginRight:"auto"}}>
        {!this.state.isdeleting &&
        <Button color="danger" onClick={()=>this.setState({isdeleting:true})}>Delete Feature</Button>
        }
        {this.state.isdeleting &&
        <Button color="danger" onClick={()=>this.deleteItem()}>Confirm Delete</Button>
        }
        </div>
        {this.state.isdeleting &&
        <Button color="secondary" onClick={()=>this.setState({isdeleting:false})} >Cancel Delete</Button>
        }
         {!this.state.isdeleting &&
        <div>
        {'  '}
        <Button color="primary" onClick={()=>this.saveFeature()} >Save</Button>
        {'  '}
        <Button color="secondary" onClick={()=>this.props.toggleModal()} >Cancel</Button>
        </div>
        }
        </ModalFooter>
  </div>



    );
  }
}
import React, { Component } from "react";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import { Button, Card, CardBody, CardGroup, Col, Container, Row, Form } from 'reactstrap';
import config from "../config";
import { API } from "aws-amplify";


export default class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: null,
      hasProfile: null,
      fname: "",
      lname: "",
      title: "",
      team: "",
      loc: ""
    };
  }
  async componentDidMount() {
    try {
        const profile = await this.getProfile();
        const { fname, lname, title, team, loc  } = profile;

      this.setState({
        fname,
        lname,
        title,
        team,
        loc,
        hasProfile: true
      });
    } catch (e) {
      alert(e);
    }
  }

  getProfile() {
    console.log('getting profile')
    let x = API.get("users", "/users")
    console.log(x)
    return x;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }
  
  saveProfile(profile) {
    console.log('saving profile')
    console.log(profile)
    let y = API.put("users", "/users", {
      body: profile
    });
    this.props.history.push("/");
    console.log(y)
  }
  
  handleSubmit = async event => {
 
    event.preventDefault();
  
    this.setState({ isLoading: true });
  
    try {
      await this.saveProfile({
        fname: this.state.fname,
        lname: this.state.lname,
        title:  this.state.title,
        team: this.state.team,
        loc: this.state.loc,
      });
    } catch (e) {
      alert(e);
      this.setState({ isLoading: false });
    }
    }

  render() {
    return (
      <div className="app flex-row align-items-top">
      <Container>
        <Row className="justify-content-center">
        <Col md="8">
        <CardGroup>
        <Card className="p-6">
        <CardBody>

        <h1 class="display-4">Profile</h1>
        <Form onSubmit={this.handleSubmit}>
          <h1>First Name</h1>
          <p className="text-muted">Your first name</p>
            <FormGroup controlId="fname">
            <FormControl
              label="fname"
              onChange={this.handleChange}
              value={this.state.fname}
              componentClass="textarea"
              rows="1"
            />
          </FormGroup>
          <h1>Last Name</h1>
          <p className="text-muted">Your last name</p>
            <FormGroup controlId="lname">
            <FormControl
              label="lname"
              onChange={this.handleChange}
              value={this.state.lname}
              componentClass="textarea"
              rows="1"
            />
          </FormGroup>    
          <h1>Title</h1>
          <p className="text-muted">Your job title</p>
            <FormGroup controlId="title">
            <FormControl
              label="title"
              onChange={this.handleChange}
              value={this.state.title}
              componentClass="textarea"
              rows="1"
            />
          </FormGroup> 
          <h1>Team</h1>
          <p className="text-muted">Your department or team</p>
            <FormGroup controlId="team">
            <FormControl
              label="team"
              onChange={this.handleChange}
              value={this.state.team}
              componentClass="textarea"
              rows="1"
            />
          </FormGroup> 
          <h1>Location</h1>
          <p className="text-muted">Your location</p>
            <FormGroup controlId="loc">
            <FormControl
              label="location"
              onChange={this.handleChange}
              value={this.state.loc}
              componentClass="textarea"
              rows="1"
            />
          </FormGroup> 
          <Row className="justify-content-right" >
          <Col xs="6">
          <Button color="primary" className="px-4">Submit</Button>
          </Col>
          </Row>
        </Form>
        </CardBody>
        </Card>
        </CardGroup>
        </Col>
        </Row>
        </Container>
      </div>
    );
  }
}

import React, { Component } from "react";
import {  Card, CardBody, CardHeader, CardFooter, Col,ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Jumbotron, Nav, NavItem, NavLink} from 'reactstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable'
import { AvForm, AvField, AvCheckbox, AvCheckboxGroup, AvRadio, AvRadioGroup } from 'availity-reactstrap-validation';
import Tooltip from 'rc-tooltip';
import Slider from 'rc-slider';
import Toggle from 'react-toggle'
import  'react-toggle/style.css'

/// FormCard - component that assembles each card with the items for the body

/// styling for react-select
const customStyles = (isValid) => ({
    control: (styles: any, state:any) => ({
      ...styles,
      padding: 5,
      fontSize: "smaller",
      ...(!isValid && { borderColor: 'red' }),
    }),
  });

/// styling for range and slider
const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);
const Handle = Slider.Handle;
const handle = (props) => {
    const { value, dragging, index, ...restProps } = props;
    return (
      <Tooltip
        prefixCls="rc-slider-tooltip"
        overlay={value}
        visible={dragging}
        placement="top"
        key={index}
      >
        <Handle value={value} {...restProps} />
      </Tooltip>
    );
  };

export default class FormCardEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
        isValid: false,
      };
    }
   
  async componentDidMount(){ 
  }

    /// callback to send option id (i) and the value of the event(e) back to parent component
    handleSelectionChange(i, e){
        this.props.onSelectionChange(i, e)
    }
    
    /// calls Selection Change callback:  Used by SELECT and MULTI --- not slider range here as it doesnt pass name via action
    handleSelectChange = (selectvalue:any, action: any) => {
        this.handleSelectionChange(action.name, selectvalue)
    };

    /// calls Selection Change callback:  Used by SELECT and MULTI --- not slider range here as it doesnt pass name via action
    handleSliderChange = (selectvalue:any, action: any) => {
      this.handleSelectionChange("slider-range", selectvalue)
    };

    /// calls Selection Change
    handleToggleSelect(e){
      this.handleSelectionChange(this.props.form.fields.id, e)
    }

    /// calls Selection Change callback:  Used by TEXT-FIELD and TEXT-AREA
    handleFieldChange = (event) => {
      const target = event.target;
      let value = target.value;
      const name = target.name;
      this.handleSelectionChange(name, value)
    }

    /// calls Selection Change callback:  Used by TOGGLE
    handleToggleChange(i){
      this.props.onSelectionChange(i, !this.props.selectvalue[i])
    }

    /// callback:  used by RADIO and CHECKBOX
    handleRadioCheckChange = (event) => {
      const target = event.target;
      if(target != null){
        let type = target.type
        let value = target.value
        const name = target.name;
        this.props.onRadioCheckToggle(name, value, this.props.questionprops.selectiontype)
      }
    }

    /// used to generate the radio and checkbox items
    getSelectItems(i, t){

      if(t==="checkbox"){
      return(
        <AvCheckbox label={i['label']} value={i['value']} />
      )}
      if(t==="radio"){
        return(
          <AvRadio label={i['label']} value={i['value']} />
        )}


    }

    /// a function to render the map of list details used for content only items (per ContentCard)
    renderListItem(h, d){
      return(
      <ListGroupItem>
      <ListGroupItemHeading>{h}</ListGroupItemHeading>
      <ListGroupItemText>{d}</ListGroupItemText>
      </ListGroupItem>
      )
    }


    showField(target, test){

      if(test === null){return true}
      
      if(typeof(target)==='object'){

        if(target !== null){
        
          if(Array.isArray(target)){
            let targetlist = []
            for(let i of target){
              targetlist.push(i.value)
            }
            if(targetlist.includes(test.value)){return true} else {return false}}
          
          else {if(target.value === test.value){return true} else {return false}}
        }
        else {return false}
      }
      else {if(test === target){return true} else {return false}}
    }

    
  /// this determines which selection component to use in the cards
  renderSelectType(field){


    if(this.showField(this.props.selectvalue[field['showif']['id']], field['showif']['value'])){

    if (field['type'] === "select"){
         return(
           <div className="survey-form-card-label">
            <p><small>{field.header}</small></p>

            <Select
            defaultValue={this.props.selectvalue[field.id]}
            onChange={this.handleSelectChange}
            options={field.selectoptions}
            name={field.id}
            styles={customStyles(true)}  /// false means there is an error and will error style
          />
        </div>
        )
        }
    if (field['type']  === "select-creatable"){
        return(
          <div className="survey-form-card-label">
          <p><small>{field.header}</small></p>
         
            <CreatableSelect
            defaultValue={this.props.selectvalue[field.id]}
            onChange={this.handleSelectChange}
            options={field.selectoptions}
            name={field.id}
            styles={customStyles(true)}  
            />
          </div>
        )
        }
    if (field['type']  === "multiselect"){
        return(
          <div className="survey-form-card-label">
            <p><small>{field.header}</small></p>
         
            <Select
            defaultValue={this.props.selectvalue[field.id]}
            isMulti
            onChange={this.handleSelectChange}
            options={field.selectoptions}
            name={field.id}
            styles={customStyles(true)}  
            />
          </div>
        )
        }   
    if (field['type']  === "multiselect-creatable"){
        return(
          <div className="survey-form-card-label">
          <p><small>{field.header}</small></p>
         
            <CreatableSelect
            isMulti
            defaultValue={this.props.selectvalue[field.id]}
            onChange={this.handleSelectChange}
            options={field.selectoptions}
            name={field.id}
            styles={customStyles(true)}  
            />
          </div>
        )
        }
    if (field['type']  === "toggle"){
      return(
        <div className="survey-form-card-label">
        <p><small>{field.header}</small></p>
       
          <Toggle
          id={field.id}
          value={field.togglevalue}
          checked= {this.props.selectvalue[field.id]}
          icons={{unchecked: null}}
          className = {field.togglestyle}
          onChange = {() => this.handleToggleChange(field.id)}
      />
      </div>
      )
    }
    if (field['type']  === "text-field"){
      return(
        <div className="survey-form-card-label">
        <p><small>{field.header}</small></p>
      
      <AvForm model={this.props.selectvalue}>
      <AvField name={field.id} type={field.texttype} style={{fontSize:'smaller'}}
      onBlur={this.handleFieldChange}
      />
      </AvForm>
      </div>
      )
    }
    if (field['type']  === "text-area"){
    return(
      <div className="survey-form-card-label">
      <p><small>{field.header}</small></p>
     
    <AvForm model={this.props.selectvalue}>
    <AvField name={field.id} type="textarea" style={{fontSize:'smaller'}}
    onBlur={this.handleFieldChange}
    />
    </AvForm>
    </div>
    )
    }
    if (field['type']  === "checkbox"){
      let defaultvals = {}
      let defaultchecks = []
      for (let i in this.props.selectvalue[field.id]){
        if(this.props.selectvalue[field.id][i]===true){
          defaultchecks.push(i)
        }
        defaultvals[field.id]=defaultchecks
      }
      return(
        <div className="survey-form-card-label">
        <p><small>{field.header}</small></p>
       
      <AvForm model={defaultvals}>
            <AvCheckboxGroup inline name={field.id} onChange={this.handleRadioCheckChange}>
              {field.selectoptions.map((item, key=item.id)=>this.getSelectItems(item, "checkbox"))}
            </AvCheckboxGroup>
      </AvForm>
      </div>
      )
  }
    if (field['type']  === "radio"){
      let defaultvals = {}
      for (let i in this.props.selectvalue[field.id]){
        if(this.props.selectvalue[field.id][i]===true){
          defaultvals[field.id]=i
        }
      }
      return(
        <div className="survey-form-card-label">
        <p><small>{field.header}</small></p>
       
      <AvForm model={defaultvals}>
            <AvRadioGroup inline name={field.id} onChange={this.handleRadioCheckChange}>
              {field.selectoptions.map((item, key=item.id)=>this.getSelectItems(item, "radio"))}
            </AvRadioGroup>
      </AvForm>
      </div>
      )
    }
    if (field['type']  === "slider"){
      return(
        <div className="survey-form-card-label">
        <p><small>{field.header}</small></p>
        <div style={{marginLeft:"15px", marginRight:"15px", marginBottom:"15px"}}>
       <Slider 
        marks={field.slidermarks}
        min={field.slidermin}
        max={field.slidermax}
        step={field.sliderstep}
        defaultValue={this.props.selectvalue[field.id]}
        onAfterChange={this.handleSliderChange}
        dotStyle={{borderColor: '#c8ced3'}}
        activeDotStyle={{borderColor: '#c8ced3'}}
        />
        </div>
        </div>
      )
    }
    if (field['type']  === "range"){
      return(
        <div className="survey-form-card-label">
        <p><small>{field.header}</small></p>
        <div style={{marginLeft:"15px", marginRight:"15px", marginBottom:"15px"}}>
          <Range 
          marks={field.slidermarks}
          min={field.slidermin}
          max={field.slidermax}
          step={field.sliderstep} 
          defaultValue={this.props.selectvalue[field.id]}
          tipFormatter={value => `${value}`}
          onAfterChange={this.handleSliderChange}
          />
        </div>
        </div>
      )
    }
    if (field['type'] === "img"){
      return(
          <img width="100%" src={field.img} alt="Survey Image" />
      )
  }

  if (field['type'] === "title"){
      return(
          <div className="survey-form-card-text">
              <h1>
                  {field.text}
              </h1>

          </div>
          )
  }

  if (field['type'] === "subtitle"){
      return(
          <div className="survey-form-card-text">
              <h3>
                  {field.text}
              </h3>

          </div>
          )
  }

  if (field['type'] === "small"){
      return(
          <div className="survey-form-card-text">
              <p><small>
                  {field.text}
              </small></p>
          </div>
          )
  }

  if (field['type'] === "bodytext"){
      return(
          <div className="survey-form-card-text">
              <p>
                  {field.text}
              </p>
          </div>
          )
  }

  if (field['type'] === "italictext"){
    return(
        <div>
            <p style={{fontStyle:"italic", fontSize:"0.8em"}}>
                {field.text}
            </p>
        </div>
        )
}

  if (field['type'] === "contentlist"){
      return(
          <ListGroup className="survey-form-card-text">
          {field.list.map((item, key=item.id)=>this.renderListItem(item.header, item.detail))}
          </ListGroup>
          )
  }

  if (field['type']=== "jumbotron"){
      return(
          <Jumbotron fluid>
      <div>
          <h1>
              {field.headline}
          </h1>
      </div>
      <div>
          <h4>
              {field.subheadline}
          </h4>
      </div>
          </Jumbotron>
          )
  }

  if (field['type'] === 'jumbotron2'){
      return(
          <Jumbotron className="survey-content-jumbotron2">
              <h1>{field.headline}</h1>
              <h4>{field.subheadline}</h4>
              <hr/>
              <p style={{marginTop:"15px"}}>{field.bodytext}</p>
          </Jumbotron>
      )
  }


  }
}

  render(){

    return(




        <Card className="survey-form-card" style={{backgroundColor:this.props.surveyprops.style.formbodycolor, maxWidth:this.props.maxcardwidth, minWidth:this.props.surveyprops.style.mincardwidth}}>
            
            {this.props.form.optionheader &&
          <CardHeader className="survey-form-card-header" style={{backgroundColor:this.props.surveyprops.style.formheadcolor, color:this.props.surveyprops.style.formheadtextcolor}}>{this.props.form.optionheader}</CardHeader>
            }
            
          <CardBody  style={{color:this.props.surveyprops.formcardtextcolor, marginBottom:"15px"}}>



            {this.props.form.fields.map((field, key=field.id)=>this.renderSelectType(field))}




          </CardBody>

          {this.props.form.showfooter &&
          <CardFooter className="survey-form-card-footer" style={{backgroundColor:this.props.surveyprops.style.formfootcolor, color: this.props.surveyprops.style.formfoottextcolor, paddingBottom:"0px"}}>

            <p style={{marginTop:"15px"}}><small>{this.props.form.helpmessage}</small></p>

          </CardFooter>
          }

        </Card>    
        
        


    );
  }
}
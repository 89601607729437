import React, { Component } from "react";
import { API } from "aws-amplify";
import {Link} from "react-router-dom"
import { Button, Card, CardBody, CardGroup, CardHeader, Col, Container, Row, Jumbotron } from 'reactstrap';
import SolidInfoCard from '../components/SolidInfoCard'
import DataTableResponse from '../components/DataTableResponse'
import DashBar from '../components/DashBar'
import ResponseToggleCard from '../components/ResponseToggleCard'

function getDate(){
  let today = new Date();
  let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
  return(date)
}

export default class Responses extends Component {
    constructor(props) {
      super(props);
      let nums = {}
      let now = new Date()
      nums['numberresponses']=0
      nums['numbercomplete']=0
      nums['avgtime']=0
      nums['todayresponse']=0
      let applyquota = false
      let quota = 0
      let passwordrequired = false
      let password = "None"
      let singleresponse = true
      let scheduleend = false
      let surveystart = null
      let surveyend = null
      let surveydescription = null
      this.getRow = this.getRow.bind(this)
      this.updateCard = this.updateCard.bind(this)
      this.routeAction = this.routeAction.bind(this)
      let initialrow = {}
      initialrow['responseId']=null
      this.state = {
        isLoading: false,
        numberresponses:nums,
        row:initialrow,
        activequestion: null,
        content:null,
        applyquota: applyquota,
        quota: quota,
        passwordrequired :passwordrequired,
        password: password,
        singleresponse: singleresponse,
        scheduleend: scheduleend,
        surveystart: surveystart,
        surveyend: surveyend,
        surveydescription: surveydescription,
      };
    }


    async componentDidMount() {
      if (!this.props.isAuthenticated) {return};

      /// to handle page refresh will try to reset props from storage

      if(this.props.orgId === null){
        try{
          this.props.userHasEmail(localStorage.getItem('email'))
          this.props.userHasId(localStorage.getItem('userId'))
          this.props.userHasRole(localStorage.getItem('userRole'))
          this.props.userHasLicense(localStorage.getItem('userLicense'))
          this.props.userHasProduct(localStorage.getItem('userProduct'))
          this.props.userHasOrg(localStorage.getItem('orgId'))
        } catch {
          this.props.history.push("/home")
        }
      }

      if(this.props.activeSurvey === null){
        this.props.history.push("/surveys")
      } 
    }

    routeAction(survey, stat){

      this.props.history.push("/surveys/responses/charts")

    }


    updateCard(n){
      this.setState({numberresponses:n})
    }

    getRow(s, r){
      if(s===true){
        this.setState({row:r, activequestion:r['responseId']})
      }
    } 


    getHeadline(){
      if (this.props.activeSurveyData !== null) {

        if(typeof this.props.activeSurveyData.status !== 'undefined'){

          if(this.props.activeSurveyData.status === 'LIVE'){
            return(
            <div style={{display:"flex", marginTop:"15px"}}>
              <p>Up to the minute data for survey: <mark>{this.props.activeSurvey}</mark></p>
              <p style={{color:"#8f9ba6", marginLeft:"5px"}}> {this.props.activeSurveyData.surveytitle}  |  Published On: {this.props.activeSurveyData.surveystart}</p>
            </div>
            )
          }

          if(this.props.activeSurveyData.status === 'COMPLETE'){
            return(
              <div style={{display:"flex", marginTop:"15px"}}>
              <p>Results for survey: <mark>{this.props.activeSurvey}</mark></p>
              <p style={{color:"#8f9ba6", marginLeft:"5px"}}> {this.props.activeSurveyData.surveytitle}  |  Published: {this.props.activeSurveyData.surveystart} |  End: {this.props.activeSurveyData.surveyend}</p>
            </div>
            )
          }

        } else {

          return(
            <p style={{marginTop:"15px"}}>Data for survey: <mark>{this.props.activeSurvey}</mark> </p>
          )

        }
      }
    }

    render() {
        return (
          <div>

          <Jumbotron style={{marginBottom:"30px", marginTop:"0px", padding:"0px"}}>
          <h1>Survey Responses</h1>
          <h5 style={{fontWeight:"200"}}>Details of individual survey responses</h5>
          <hr/>
          {this.getHeadline()}
          
          </Jumbotron>

            <Row>
                <Col sm="6" md="2">
                  <SolidInfoCard  color="dark" header={this.state.numberresponses['numberresponses']} value="100" invert >Total Responses</SolidInfoCard>
                </Col>
                <Col sm="6" md="2">
                  <SolidInfoCard  color="info" header={this.state.numberresponses['numbercomplete']} value="100" >Total Completes</SolidInfoCard>
                </Col>
                <Col sm="6" md="2">
                  <SolidInfoCard  color="primary" header={this.state.numberresponses['avgtime']} value="100" >Avg Time per Survey</SolidInfoCard>
                </Col>
                <Col sm="6" md="2">
                  <SolidInfoCard  color="secondary" header={this.state.numberresponses['todayresponse']} value="100" >Last 24 Hours</SolidInfoCard>
                </Col>
                <Col>
                <div style={{display:"flex", flexDirection:"column", justifyContent:"flex-end", height:"100%", paddingBottom:"1.5rem"}}>
                <div>
                <Link to={`/surveys/responses/analysis`} style={{color:"primary"}} activeStyle={{color:"white"}}>Survey Analysis</Link>
                </div>
                <div style={{marginTop:"10px"}}>
                <Link to={`/surveys/responses/crosstabs`} style={{color:"primary"}} activeStyle={{color:"white"}}>Survey Charts</Link>
                </div>
                </div>
                 </Col>

              </Row>
              {this.props.orgId &&
              <DataTableResponse
                getRow={this.getRow}
                orgId={this.props.orgId}
                activeSurvey={this.props.activeSurvey}
                updateCard={this.updateCard}
                />
              }

          </div>
    
        );
      }
    }
    
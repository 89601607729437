import React, { Component } from "react";
import { Card, CardBody, CardHeader, CardFooter, Col,ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Jumbotron, Nav, NavLink} from 'reactstrap';
import GifPlayer from 'react-gif-player';
import ReactPlayer from 'react-player';


/// ContentCard - assembles each card for SurveyContent
/// this is static content, so there are no events or inputs to handle

export default class ContentCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
        isValid: true,
        togglesidebar: false,
      };
    }
   
  async componentDidMount(){
  }

    /// a function to render the map of list details
    renderListItem(h, d){
        return(
        <ListGroupItem>
        <ListGroupItemHeading style={{fontSize:"1em", fontWeight:"200"}}>{h}</ListGroupItemHeading>
        <ListGroupItemText style={{fontSize:"0.75em", fontWeight:"400", marginBottom:"15px"}}>{d}</ListGroupItemText>
        </ListGroupItem >
        )
    }

    /// a callback to toggle the lightbox image component on SurveyContent
    handleImageToggle(i){
        this.props.onImageToggle(i)
    }

    /// called in render to map each content item to assemble into the card
    renderSelectType(content){

        if (content.type === "img"){
            return(
                <div tag="a"  onClick={() => this.handleImageToggle(content.id)} style={{cursor: "pointer"}} >
                <img width="100%" src={content.img} alt="Survey Image" />
                </div>
            )
        }

        if (content.type === "gif"){
                return(
                    <div style={{cursor: "pointer"}}>
                    <GifPlayer
                        gif={content.gif}
                        width="100%"
                        />
                    <p><small>Click to play</small></p>
                    </div>
            )
        }

        if (content.type === "video"){
            return(
                <div>
                <ReactPlayer
                    url={content.video}
                    controls='true'
                    muted='true'
                    width='100%'
                    />
                </div>
                )
        }

        if (content.type === "title"){
            return(
                <div>
                    <h1>
                        {content.text}
                    </h1>

                </div>
                )
        }

        if (content.type === "subtitle"){
            return(
                <div>
                    <h3>
                        {content.text}
                    </h3>

                </div>
                )
        }

        if (content.type === "small"){
            return(
                <div>
                    <p><small>
                        {content.text}
                    </small></p>
                </div>
                )
        }

        if (content.type === "bodytext"){
            return(
                <div>
                    <p>
                        {content.text}
                    </p>
                </div>
                )
        }

        if (content.type === "italictext"){
            return(
                <div>
                    <p style={{fontStyle:"italic", fontSize:"0.8em"}}>
                        {content.text}
                    </p>
                </div>
                )
        }

        if (content.type === "contentlist"){
            return(
                <ListGroup>
                {content.list.map((item, key=item.id)=>this.renderListItem(item.header, item.detail))}
                </ListGroup>
                )
        }

        if (content.type === "jumbotron"){
            return(
                <Jumbotron fluid>
            <div>
                <h1>
                    {content.headline}
                </h1>
            </div>
            <div>
                <h4>
                    {content.subheadline}
                </h4>
            </div>
                </Jumbotron>
                )
        }

        if (content.type === 'jumbotron2'){
            return(
                <Jumbotron className="survey-content-jumbotron2">
                    <h1>{content.headline}</h1>
                    <h4>{content.subheadline}</h4>
                    <hr/>
                    <p style={{marginTop:"15px"}}>{content.bodytext}</p>
                </Jumbotron>
            )
        }


    }


  render(){

    return(  

        <Card className="survey-choice-card" style={{backgroundColor:this.props.surveyprops.style.formbodycolor, maxWidth:this.props.maxcardwidth, minWidth:this.props.surveyprops.style.mincardwidth}}>

            {this.props.option.showheader &&
            <CardHeader>{this.props.option.optionheader}</CardHeader>
            }
        
            <CardBody>
            
            <div style={{display:"flex", flexDirection:"column"}}>

            {this.props.option.content.map((content, key=content.id)=>this.renderSelectType(content))}




            </div>
        
          </CardBody>

        </Card>           
        
    );
  }
}
import React, { Component } from "react";
import { Button, Card, CardBody, CardGroup, CardHeader, Col, Container, Row, Jumbotron } from 'reactstrap';
import SolidInfoCard from '../components/SolidInfoCard'
import DataTableAsset from '../components/DataTableAsset'
import SolidAssetCard from '../components/SolidAssetCard'

export default class Assets extends Component {
    constructor(props) {
      super(props);
      this.getRow = this.getRow.bind(this)
      this.updateCard = this.updateCard.bind(this)
      let nums = {}
      nums['numberassets']=0
      nums['numberimg']=0
      nums['numbervideo']=0
      nums['numbergif']=0
      this.state = {
        isLoading: false,
        numberassets:nums,
        activeasset:null,
      };
    }

    async componentDidMount() {
      if (!this.props.isAuthenticated) {return};

      /// to handle page refresh will try to reset props from storage

      if(this.props.orgId === null){
        try{
          this.props.userHasEmail(localStorage.getItem('email'))
          this.props.userHasId(localStorage.getItem('userId'))
          this.props.userHasRole(localStorage.getItem('userRole'))
          this.props.userHasLicense(localStorage.getItem('userLicense'))
          this.props.userHasProduct(localStorage.getItem('userProduct'))
          this.props.userHasOrg(localStorage.getItem('orgId'))
        } catch {
          this.props.history.push("/home")
        }
      }
    }

    getRow(s, r){
      if(s===true){
        this.setState({row:r, activeasset:r['assetId']})
      }
    } 

    updateCard(n){
      this.setState({numberassets:n})
    }
    
    render() {
        return (
          <div>
            <Jumbotron style={{marginBottom:"30px", marginTop:"0px", padding:"0px"}}>
              <h1>Assets</h1>
              <h5 style={{fontWeight:"200"}}>The image, lightbox, gifs and videos that bring your ideas and concepts to life</h5>
              <hr/>
        <p style={{marginTop:"15px"}}>{` `}</p>
            </Jumbotron>
            <Row>
            <Col sm="6" md="2">
                  <SolidInfoCard  color="dark" header={this.state.numberassets['numberassets']} value="100" invert >Total Assets</SolidInfoCard>
                </Col>
                <Col sm="6" md="2">
                  <SolidInfoCard  color="secondary" header={this.state.numberassets['numberimg']} value="100" >Images</SolidInfoCard>
                </Col>
                <Col sm="6" md="2">
                  <SolidInfoCard  color="primary" header={this.state.numberassets['numbervideo']} value="100" >Videos</SolidInfoCard>
                </Col>
                <Col sm="6" md="2">
                  <SolidInfoCard color="light" header={this.state.numberassets['numbergif']} value="100" >Gifs</SolidInfoCard>
                </Col>
                <Col sm="6" md="2">
                {this.state.activeasset !==null && 
                  <SolidAssetCard icon="icon-pencil" color="info" 
                  row={this.state.row} value="100"
                  >
                  </SolidAssetCard>
                }
                </Col>
            </Row>
            {this.props.orgId &&
            <DataTableAsset 
            orgId={this.props.orgId}
            updateCard={this.updateCard}
            getRow={this.getRow}
            />
            }
          </div>
    
        );
      }
    }
    
import React, { Component } from "react";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import { Button, Card, CardBody, CardGroup, Col, Container, Row, Form } from 'reactstrap';
import config from "../config";
import { API } from "aws-amplify";


export default class Organization extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: null,
      hasProfile: null,
      orgId: "",
      orgname: "",
      keywords: "",
      team: "",
      loc: "",
      licensetype: "",
      renewal: "",
    };
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }
  
  saveOrg(org) {
    console.log('saving new org')
    console.log(org)
    let y = API.put("org", "/org", {
      body: org
    });
    console.log(y)
  }
  
  handleSubmit = async event => {
 
    event.preventDefault();
  
    this.setState({ isLoading: true });
  
    try {
      await this.saveOrg({
        orgId: this.state.orgId,
        orgname: this.state.orgname,
        licensetype: this.state.licensetype,
        renewal: this.state.renewal,
      });
      this.props.history.push("/");
    } catch (e) {
      alert(e);
      this.setState({ isLoading: false });
    }
    }

  render() {
    return (
      <div className="app flex-row align-items-top">
      <Container>
        <Row className="justify-content-center">
        <Col md="8">
        <CardGroup>
        <Card className="p-6">
        <CardBody>

        <h1 class="display-4">Organization</h1>
        <Form onSubmit={this.handleSubmit}>
          <h1>Name</h1>
          <p className="text-muted">Company name</p>
            <FormGroup controlId="orgname">
            <FormControl
              label="orgname"
              onChange={this.handleChange}
              value={this.state.orgname}
              componentClass="textarea"
              rows="1"
            />
          </FormGroup>
          <h1>License</h1>
          <p className="text-muted">Software License</p>
            <FormGroup controlId="licensetype">
            <FormControl
              label="license"
              onChange={this.handleChange}
              value={this.state.licensetype}
              componentClass="textarea"
              rows="1"
            />
          </FormGroup>
          <h1>Renewal</h1>
          <p className="text-muted">Renewal Date</p>
            <FormGroup controlId="renewal">
            <FormControl
              label="renewal"
              onChange={this.handleChange}
              value={this.state.renewal}
              componentClass="textarea"
              rows="1"
            />
          </FormGroup> 
          <Row className="justify-content-right" >
          <Col xs="6">
          <Button color="primary" className="px-4">Submit</Button>
          </Col>
          </Row>
        </Form>
        </CardBody>
        </Card>
        </CardGroup>
        </Col>
        </Row>
        </Container>
      </div>
    );
  }
}

import React, { Component } from "react";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import { Button, Card, CardBody, CardGroup, Col, Container, Row, Form } from 'reactstrap';
import { LinkContainer } from "react-router-bootstrap";
import { ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText } from "reactstrap";
import { API } from "aws-amplify";


export default class Search extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: null,
      query: "",
      totalhits: 0,
      maxscore: 0.0,
      hits: [{}],
     };
  }

  async componentDidMount() {
    try {
      if (this.state.query != ""){
        await this.runSearch();}
    } catch (e) {
      console.log('here is the error')
      alert(e);
    }
  }


  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }


  handleSubmit = async event => {
    event.preventDefault();
  
    this.setState({ isLoading: true });
  
    try {
      let result = await this.runSearch(this.state.query);
      let response = result.hits;
      console.log(response)
      this.setState({
        isLoading: false,
        totalhits: response.length,
        hits: response,
      });

      return result

    } catch (e) {
      alert(e);
    }
  }
  
  
  runSearch(query) {
    console.log('starting query function')
    if (query != ""){
      console.log('in the function')
      var q ={};
      q['q']=query
      return API.get("notes", "/search", {queryStringParameters: q})}
    else {return query}
  }

  renderSearchResults(hits) {
    let x = []
    x = [{}].concat(hits).map((hit) =>
        <LinkContainer
        key={hit.source_noteId}
        to={`/notes/${hit.source_noteId}`}>
            <ListGroupItem>
            <ListGroupItemHeading>{"Title: " + hit.source_title}</ListGroupItemHeading>
            <ListGroupItemText>{"Description:  " + hit.source_content}</ListGroupItemText>
            <ListGroupItemText>{"Key Phrases:  " + hit.highlight_keyphrases}</ListGroupItemText>
            <ListGroupItemText>{"Entities:  " + hit.highlight_entities}</ListGroupItemText>
            <ListGroupItemText>{"Transcription:  " + hit.highlight_transcription}</ListGroupItemText>
            </ListGroupItem>
        </LinkContainer>
    );
    if (x[0]['key'] == null) {
      x.shift();
    }
    return x
}

  render() {
    return (
      <div className="app flex-row align-items-top">
      <Container>
        <Row className="justify-content-center">
        <Col md="8">
        <CardGroup>
        <Card className="p-6">
        <CardBody>

        <h1 className="display-4">Search</h1>
        <Form onSubmit={this.handleSubmit}>
          <p className="text-muted">Query your work items</p>
            <FormGroup controlId="query">
            <FormControl
              onChange={this.handleChange}
              value={this.state.query}
              type='text'
              size='lg'
            />
          </FormGroup>
          <Row className="justify-content-right" >
          <Col xs="6">
          <Button color="primary" className="px-4">Submit</Button>
          </Col>
          </Row>
        </Form>
        </CardBody>
        </Card>
        <Card className="p-6">
        <CardBody>
        <h1 className="display-4">Results</h1>
        <p className="text-muted">What we found</p>
        <ListGroup>
          {!this.state.isLoading && this.state.totalhits>0 && this.renderSearchResults(this.state.hits)}
        </ListGroup>
        </CardBody>
        </Card>
        </CardGroup>
        </Col>
        </Row>
        </Container>
      </div>
    );
  }
}

import React, { Component } from "react";
import {  Button, Input, Row, Col, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Jumbotron, ModalHeader, ModalBody, ModalFooter, CustomInput} from 'reactstrap';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable'
import Toggle from 'react-toggle'
import 'react-toggle/style.css'

///// if autoassemble == delete existing packages from page=== provide message === save this setting in props

/// styling for react-select
const customStyles = (isValid) => ({
    control: (styles: any, state:any) => ({
      ...styles,
      padding: 5,
      width:"100%",
      fontSize: "smaller",
      overflow: 'visible',
      ...(!isValid && { borderColor: 'red' }),
    }),
  });

    /// styling for react-select
    const customStyles2 = (isValid) => ({
      option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted black',
        backgroundColor: state.isSelected ? '#2f353a' : 'white',
        color: state.isSelected ? '#20a8d8' : '#2f353a',
        padding: 5,
        overflow: 'visible',
      }),
        control: (styles: any, state:any) => ({
          ...styles,
          color:"#2f353a",
          backgroundColor:"#f0f3f5",
          padding: 0,
          width:"120px",
          fontSize: "smaller",
          overflow: "visible",
          ...(!isValid && { borderColor: 'red' }),
        }),
      });


  function makeId(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }

 function truncateString(str, num) {
    if (typeof str === 'undefined'){
      return "..."
    }
    if (str.length <= num) {
      return str
    }
    return str.slice(0, num) + '...'
  }

export default class PageEditorModal extends Component {
  constructor(props) {
    super(props);
    let page = JSON.parse(JSON.stringify(this.props.page))

    if(page.pageprops['autoassembled']===undefined){page.pageprops['autoassembled']=false}
    if(page.pageprops['testwinners']===undefined){page.pageprops['testwinners']=false}
    if(page.pageprops['testprices']===undefined){page.pageprops['testprices']=false}
    if(page.pageprops['alwaysshow']===undefined){page.pageprops['alwaysshow']=false}
    if(page.pageprops['pricerange']===undefined){page.pageprops['pricerange']=0}
    if(page.pageprops['assembledchoices']===undefined){page.pageprops['assembledchoices']=2}
    if(page.pageprops['alwaysshowpackage']===undefined){page.pageprops['alwaysshowpackage']=null}
    if(page.pageprops['questiontype']==='AutoChoice'){page.pageprops['autoassembled'] = true}
    if(page.pageprops['questiontype']!=='AutoChoice'){page.pageprops['autoassembled'] = false}

    let alwaysshowoptions = []
    for(let o in this.props.surveyprops.packageoptions){
      alwaysshowoptions.push({"label": this.props.surveyprops.packageoptions[o]['id']+": "+truncateString(this.props.surveyprops.packageoptions[o]['optionheader'],7), 'value': this.props.surveyprops.packageoptions[o]['id']})
    }

    this.state = {
        isValid: false,
        isdeleting: false,
        changebuttons: false,
        page: page,
        autoalert: false,
        winneralert: false,
        alwaysshowoptions: alwaysshowoptions,
      };
    }
   
  async componentDidMount(){ 
  }


  getIfAuto(){

    if(this.state.page.pageprops.autoassembled === false && this.state.page.pageprops.testwinners === false){
      return(
      <div style={{fontSize:"0.7em", display:"flex", fontWeight:"300", marginBottom:"0px"}}>
      <p>Cards selection is: </p>
      <p style={{color:'#20a8d8', marginLeft:"5px"}}>manual</p>
      <p style={{color:'#20a8d8', marginRight:"5px"}}></p>
      </div>
      )
    }

    if(this.state.page.pageprops.autoassembled === true){
    return (
        <div>
        <div style={{fontSize:"0.7em", display:"flex", fontWeight:"300", marginBottom:"0px"}}>
        <p>Cards selection is: </p>
        <p style={{color:'#20a8d8', marginRLeft:"5px"}}>random</p>
        <p style={{color:'#20a8d8', marginRight:"5px"}}></p>
        </div>
        {this.state.autoalert &&
        <div style={{marginTop:"0px", marginBottom:"0px"}}>
        <p style={{color:'#f86c6b', fontSize:"0.7em", fontWeight:"200", marginTop:"0px", marginBottom:"0px"}}>Turning on auto select will remove exisiting cards from page</p>
        </div>
        }
        </div>
        )
    }

    if(this.state.page.pageprops.testwinners === true){
      return (
          <div>
          <div style={{fontSize:"0.7em", display:"flex", fontWeight:"300", marginBottom:"0px"}}>
          <p>Cards selection is: </p>
          <p style={{color:'#20a8d8', marginRLeft:"5px"}}>prior selected winners</p>
          <p style={{color:'#20a8d8', marginRight:"5px"}}></p>
          </div>
          {this.state.winneralert &&
          <div style={{marginTop:"0px", marginBottom:"0px"}}>
          <p style={{color:'#f86c6b', fontSize:"0.7em", fontWeight:"200", marginTop:"0px", marginBottom:"0px"}}>Turning on test winners will remove exisiting cards from page</p>
          </div>
          }
          </div>
          )
      }
}


    savePage(){
        this.props.savePage(this.state.page)
    }

    deletePage(){
      this.props.deletePage(this.state.page)
  }
    
    handleChange = event => {

        let fieldname = event.target.id
        let value = event.target.value

        if(fieldname === 'pricerange'){
          if(value<0){value = value * -1}
          if(value<1){value = value * 100}
        }

        this.setState(prevState => ({
                page: {...prevState.page,           
                    pageprops: {...prevState.page.pageprops,
                        [fieldname]:value}}}))
    }

    /// updates the toggle switch and then validates
    changeSelection(id, e){
        let fieldname = id
        let value = e

        if (fieldname === 'autoassembled'){
          let bigquestions = JSON.parse(JSON.stringify(this.props.page.questions))
          if(value === true){
            bigquestions[0]['questiontype']='AutoChoice'
            bigquestions[0]['cards']=[]
            this.setState(prevState => ({

              autoalert: true,
              winneralert: false,
              page: {...prevState.page,           
                  pageprops: {...prevState.page.pageprops,
                      randomizechoices:true,
                      questiontype:"AutoChoice",
                      autoassembled: value,
                    },
                  questions: bigquestions,
                  }}))
          } else {
            bigquestions[0]['questiontype']='SurveyChoice'
            this.setState(prevState => ({
              autoalert: false,
              winneralert: false,
              page: {...prevState.page,           
                  pageprops: {...prevState.page.pageprops,
                      randomizechoices:true,
                      questiontype:"SurveyChoice",
                      autoassembled: value,
                      },
                  questions: bigquestions,
                    }}))
        }
      }

      if (fieldname === 'testwinners'){
        let bigquestions = JSON.parse(JSON.stringify(this.props.page.questions))
        if(value === true){
          bigquestions[0]['questiontype']='ChoiceWinners'
          bigquestions[0]['questionprops']['selectiontype']='discrete'
          bigquestions[0]['cards']=[]
          this.setState(prevState => ({

            autoalert: false,
            winneralert: true,
            page: {...prevState.page,           
                pageprops: {...prevState.page.pageprops,
                    randomizechoices:true,
                    questiontype:"ChoiceWinners",
                    autoassembled: false,
                    testwinners: true,
                  },
                questions: bigquestions,
                }}))
        } else {
          bigquestions[0]['questiontype']='SurveyChoice'
          this.setState(prevState => ({
            autoalert: false,
            winneralert: false,
            page: {...prevState.page,           
                pageprops: {...prevState.page.pageprops,
                    randomizechoices:true,
                    questiontype:"SurveyChoice",
                    autoassembled: false,
                    testwinners: false,
                    },
                questions: bigquestions,
                  }}))
      }
    }

      else {

        this.setState(prevState => ({
                page: {...prevState.page,           
                    pageprops: {...prevState.page.pageprops,
                        [fieldname]:value}}}))
    }
  }


      getAlwaysShowPackage = (selectvalue, action) => {

            this.setState(prevState => ({
              page: {...prevState.page,           
                  pageprops: {...prevState.page.pageprops,
                      alwaysshowpackage:selectvalue.value}}}))
    }


  render(){

    return(
    <div>
        <ModalHeader style={{backgroundColor:"#2f353a", color:"#8f9ba6", fontSize:"1.2em", fontWeight:"200"}}>
            {`Properties for page ${this.props.currentpagenum + 1} `}
            {this.state.isdeleting &&
            <p style={{color:'#f86c6b', fontSize:"0.8em", fontWeight:"200", marginTop:"0px"}}>Please confirm that you want to delete this item</p>
            }
            {['AutoChoice', 'SurveyChoice', 'ChoiceWinners'].includes(this.state.page['pageprops']['questiontype']) &&
            this.getIfAuto()
            }
            </ModalHeader>
        <ModalBody>

            <p style={{marginBottom:"5px"}}><small>Title</small></p>
            <Input autoFocus type="text" name={"questiontitle"} id={"questiontitle"}
            value={this.state.page.pageprops.questiontitle}
            style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
            onChange={this.handleChange}
            />

            <p style={{marginBottom:"5px"}}><small>Subtitle</small></p>
            <Input type="textare" row="2" name={"questionsubtitle"} id={"questionsubtitle"}
            value={this.state.page.pageprops.questionsubtitle}
            style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
            onChange={this.handleChange}
            />

            <div style={{ padding:"30px", marginLeft:"-16px", marginRight:"-16px", marginBottom:"-16px", marginTop:"50px", color:"white", fontSize:"0.8em", backgroundColor:"#2f353a" }}>
                
            {['SurveyChoice', 'AutoChoice', 'ChoiceWinners'].includes(this.state.page.questions[0].questiontype) &&
            <div>
            <Row style={{display:"flex", alignItems:"flex-end"}} >
                <Col>
                <p style={{marginBottom:"5px",fontSize:"0.7em"}}>Random Selections </p>
                    <Toggle
                        id={"autoassembled"}
                        checked= {this.state.page.pageprops.autoassembled}
                        disabled={this.state.page.pageprops.testwinners}
                        icons={{unchecked: null}}
                        className = {"custom-success"}
                        onChange = {() => this.changeSelection("autoassembled", !this.state.page.pageprops.autoassembled)}
                            />

                </Col>

                <Col>
                <p style={{marginBottom:"5px",fontSize:"0.7em"}}>Test Prices </p>
                    <Toggle
                        id={"testprices"}
                        checked= {this.state.page.pageprops.testprices}
                        disabled={!this.state.page.pageprops.autoassembled}
                        icons={{unchecked: null}}
                        className = {"custom-success"}
                        onChange = {() => this.changeSelection("testprices", !this.state.page.pageprops.testprices)}
                            />
                </Col>
                <Col>
                <p style={{marginBottom:"5px",fontSize:"0.7em"}}>Always Show </p>
                    <Toggle
                        id={"alwaysshow"}
                        disabled={!this.state.page.pageprops.autoassembled}
                        checked= {this.state.page.pageprops.alwaysshow}
                        icons={{unchecked: null}}
                        className = {"custom-success"}
                        onChange = {() => this.changeSelection("alwaysshow", !this.state.page.pageprops.alwaysshow)}
                            />
                </Col>
                <Col>
                <p style={{marginBottom:"5px",fontSize:"0.7em"}}>Test Winners </p>
                    <Toggle
                        id={"testwinners"}
                        disabled={this.state.page.pageprops.autoassembled}
                        checked= {this.state.page.pageprops.testwinners}
                        icons={{unchecked: null}}
                        className = {"custom-success"}
                        onChange = {() => this.changeSelection("testwinners", !this.state.page.pageprops.testwinners)}
                            />
                </Col>
                </Row>

                {this.state.page.pageprops.autoassembled &&
                  <Row style={{display:"flex", alignItems:"flex-end"}}>
                    <Col>
                  <p style={{marginBottom:"5px",fontSize:"0.7em", marginTop:"5px", marginBottom:"5px"}}>Choices per Page </p>
                  <Input type="number" name={'assembledchoices'} id={'assembledchoices'}
                  value={this.state.page.pageprops.assembledchoices}
                  disabled={!this.state.page.pageprops.autoassembled}
                  style={{backgroundColor:"#f0f3f5", fontSize:"0.8em", fontWeight:"400"}}
                  onChange={this.handleChange}
                  />
                  </Col>
                  <Col>
                  {this.state.page.pageprops.testprices &&
                  <div style={{justifyContent:"center"}}>
                  <p style={{marginBottom:"5px",fontSize:"0.7em", marginTop:"5px", marginBottom:"5px"}}>Price +/- pct </p>
                  <Input type="number" name={'pricerange'} id={'pricerange'}
                  value={this.state.page.pageprops.pricerange}
                  disabled={!this.state.page.pageprops.testprices}
                  style={{backgroundColor:"#f0f3f5", fontSize:"0.8em", fontWeight:"400"}}
                  onChange={this.handleChange}
                  />
                  </div>
                  }
                  </Col>
                  <Col>
                  {this.state.page.pageprops.alwaysshow &&
                  <div>
                  <p style={{fontSize:"0.7em", marginBottom:"3px", marginTop:"5px"}}>Show this Pkg</p>
                    <Select
                    defaultValue={this.state.page.pageprops.alwaysshowpackage}
                    onChange={this.getAlwaysShowPackage}
                    options={this.state.alwaysshowoptions}
                    id={"alwaysshowpackage"}
                    name={"alwaysshowpackage"}
                    styles={customStyles2(true)}
                    />
                    </div>
                    }
                  </Col>
                  <Col>
                  </Col>
                  </Row>
                  }

                <hr style={{borderTop: "1px", borderStyle:"dashed", borderColor:"#5c6873", marginBottom:"15px"}}></hr>
                </div>
                }

                <Row style={{display:"flex", alignItems:"flex-end"}}>
                    <Col>
                    <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Show Title</p>
                    <Toggle
                            id={"showquestiontitle"}
                            checked= {this.state.page.pageprops.showquestiontitle}
                            icons={{unchecked: null}}
                            className = {"custom-success"}
                            onChange = {() => this.changeSelection("showquestiontitle", !this.state.page.pageprops.showquestiontitle)}
                                />
                    </Col>

                    <Col>
                    <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Show Subtitle</p>
                    <Toggle
                            id={"showquestionsubtitle"}
                            checked= {this.state.page.pageprops.showquestionsubtitle}
                            icons={{unchecked: null}}
                            className = {"custom-success"}
                            onChange = {() => this.changeSelection("showquestionsubtitle", !this.state.page.pageprops.showquestionsubtitle)}
                                />
                    </Col>

                    <Col>
                    <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Show Page Nums</p>
                    <Toggle
                            id={"pagenums"}
                            checked= {this.state.page.pageprops.showpagenums}
                            icons={{unchecked: null}}
                            className = {"custom-success"}
                            onChange = {() => this.changeSelection("showpagenums", !this.state.page.pageprops.showpagenums)}
                                />
                    </Col>
                    <Col>
                  <p style={{fontSize:"0.8em", marginBottom:"5px"}}>Random Order </p>
                      <Toggle
                          id={"randomize"}
                          disabled={this.state.page.pageprops.autoassembled}
                          checked= {this.state.page.pageprops.randomizechoices}
                          icons={{unchecked: null}}
                          className = {"custom-success"}
                          onChange = {() => this.changeSelection("randomizechoices", !this.state.page.pageprops.randomizechoices)}
                              />
                  </Col>

                </Row>
                
                <hr style={{borderTop: "1px", borderStyle:"dashed", borderColor:"#5c6873"}}></hr>

                <Row style={{display:"flex", alignItems:"flex-end", marginTop:"15px"}}>

                <Col>
                <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Change Button Labels</p>
                        <Toggle
                        id={"changebuttons"}
                        checked= {this.state.changebuttons}
                        icons={{unchecked: null}}
                        className = {"custom-success"}
                        onChange = {() => this.setState({changebuttons:!this.state.changebuttons})}
                            />
                </Col>
                <Col>
                <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Show Page Instructions</p>
                    <Toggle
                        id={"pagehint"}
                        checked= {this.state.page.pageprops.showpagehint}
                        icons={{unchecked: null}}
                        className = {"custom-success"}
                        onChange = {() => this.changeSelection("showpagehint", !this.state.page.pageprops.showpagehint)}
                            />
                </Col>

                <Col>
                <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Show Question Tip</p>
                    <Toggle
                        id={"showquestiontip"}
                        checked= {this.state.page.pageprops.showquestiontip}
                        icons={{unchecked: null}}
                        className = {"custom-success"}
                        onChange = {() => this.changeSelection("showquestiontip", !this.state.page.pageprops.showquestiontip)}
                            />
                </Col>

                <Col>
                <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Show Page Notification</p>
                    <Toggle
                        id={"showquestiontoast"}
                        checked= {this.state.page.pageprops.showquestiontoast}
                        icons={{unchecked: null}}
                        className = {"custom-success"}
                        onChange = {() => this.changeSelection("showquestiontoast", !this.state.page.pageprops.showquestiontoast)}
                            />
                </Col>
                </Row>

                {this.state.changebuttons &&
                <Row style={{marginTop:"30px"}}>
                <Col>
                <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Back Button Text</p>
                    <Input name={'backbutton'} id={'backbutton'}
                    value={this.state.page.pageprops.backbutton}
                    onChange={this.handleChange}
                    type="text"
                    />
                </Col>
                <Col>
                <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Forward Button Text</p>
                    <Input name={'nextbutton'} id={'nextbutton'}
                    value={this.state.page.pageprops.nextbutton}
                    onChange={this.handleChange}
                    type="text"
                    />
                </Col>
                </Row>
                }

                {this.state.page.pageprops.showpagehint &&
                <div>
                <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Page Instructions</p>
                    <Input name={'pagehint'} id={'pagehint'}
                    type="text"
                    value={this.state.page.pageprops.pagehint}
                    onChange={this.handleChange}
                    />
                </div>
                }

                {this.state.page.pageprops.showquestiontip &&
                <div>
                <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Page Tip</p>
                    <Input name={'questiontip'} id={'questiontip'}
                    type="text"
                    value={this.state.page.pageprops.questiontip}
                    onChange={this.handleChange}
                    />
                </div>
                }

                {this.state.page.pageprops.showquestiontoast &&
                <div>
                <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Page Notification</p>
                    <Input name={'questiontoast'} id={'questiontoast'}
                    type="text"
                    value={this.state.page.pageprops.questiontoast}
                    onChange={this.handleChange}
                    />
                </div>
                }

            </div>

        </ModalBody>
        <ModalFooter style={{marginTop:"0"}}>
        <div style={{alignSelf:"flex-start", marginRight:"auto"}}>
        {!this.state.isdeleting &&
        <Button color="danger" onClick={()=>this.setState({isdeleting:true})}>Delete Page</Button>
        }
        {this.state.isdeleting &&
        <Button color="danger" onClick={()=>this.deletePage()}>Confirm Delete</Button>
        }
        </div>
        {this.state.isdeleting &&
        <Button color="secondary" onClick={()=>this.setState({isdeleting:false})} >Cancel Delete</Button>
        }
        {!this.state.isdeleting &&
        <div>
        {'  '}
        <Button color="primary" onClick={()=>this.savePage()} >Save</Button>
        {'  '}
        <Button color="secondary" onClick={()=>this.props.toggleModal()} >Cancel</Button>
        </div>
        }
        </ModalFooter>
  </div>



    );
  }
}
import React, { Component } from "react";
import {  Button, Input, Row, Col, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Jumbotron,  ModalHeader, ModalBody, ModalFooter, CustomInput} from 'reactstrap';
import { Form, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable'
import Toggle from 'react-toggle'
import 'react-toggle/style.css'
import {BlockPicker} from 'react-color'

const defaultcolors = [
    '#000000', '#2f353a', '#c8ced3', '#f0f3f5','#FFFFFF',
    '#b71c1c', '#d32f2f',  '#f44336',  '#e57373', '#ffcdd2',
    '#ff6f00', '#ffa000',  '#ffc107', '#ffd54f',  '#ffecb3',
    '#827717', '#afb42b', '#cddc39', '#dce775', '#f0f4c3',
    '#33691e', '#689f38',  '#8bc34a',  '#aed581', '#dcedc8',
    '#006064', '#0097a7', '#00bcd4', '#4dd0e1', '#b2ebf2',
    '#0d47a1', '#1976D2',  '#2196F3', '#64B5F6', '#bbdefb',
    '#4a148c', '#7b1fa2',  '#9c27b0', '#ba68c8',  '#e1bee7',
    '#880e4f', '#c2185b',  '#e91e63', '#f06292', '#f8bbd0',
    '#3e2723',  '#5d4037',  '#795548',  '#a1887f', '#d7ccc8',
    '#263238', '#455a64', '#607d8b', '#90a4ae', '#cfd8dc',
    ]

/// styling for react-select
const customStyles = (isValid) => ({
    control: (styles: any, state:any) => ({
      ...styles,
      padding: 5,
      width:"100%",
      fontSize: "smaller",
      ...(!isValid && { borderColor: 'red' }),
    }),
  });

  /// styling for react-select
const customStyles2 = (isValid) => ({
    control: (styles: any, state:any) => ({
      ...styles,
      backgroundColor:"#f0f3f5",
      padding: 5,
      fontSize: "smaller",
      ...(!isValid && { borderColor: 'red' }),
    }),
  });


  function makeId(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }

 function truncateString(str, num) {
    if (typeof str === 'undefined'){
      return "..."
    }
    if (str.length <= num) {
      return str
    }
    return str.slice(0, num) + '...'
  }

export default class SurveyStyleModal extends Component {
  constructor(props) {
    super(props);
    let showmaincolors = true
    let showchoicecolors = false
    let showtablecolors = false
    let activeitem = "pagebodycolor"
    let activecolor = this.props.surveyprops.style.pagebodycolor
    if(this.props.page.pageprops.questiontype==="SurveyChoice"){
        activeitem = "noselectcolor"
        activecolor = "this.props.surveyprops.questionpropsdefault.noselectcolor"
        showmaincolors = false
        showchoicecolors = true
    }
    if(this.props.page.pageprops.questiontype==="SurveyList"){
        activeitem = "tablebackground"
        activecolor = "this.props.surveyprops.style.tablebackground"
        showmaincolors = false
        showtablecolors = true
    }
    this.state = {
        isValid: false,
        isdeleting: false,
        snapshot: JSON.parse(JSON.stringify(this.props.surveyprops)),
        activeitem: activeitem,
        activecolor: activecolor,
        showmaincolors: showmaincolors,
        showchoicecolors: showchoicecolors,
        showtablecolors: showtablecolors,

      };
    }
   
  async componentDidMount(){ 
  }


    savePage(){
        this.props.savePage(this.state.page)
    }
    
    handleChange = event => {
        let fieldname = event.target.id
        let value = event.target.value
        this.setState(prevState => ({
                page: {...prevState.page,           
                    pageprops: {...prevState.page.pageprops,
                        [fieldname]:value}}}))
    }

    /// updates the toggle switch and then validates
    changeSelection(id, e){
        let item = {}
        item['name']=id
        item['value']=e
        this.props.updateSurveyStyleProps(item)
    }


    toggleSelection(id, s){
        let showmaincolors = false
        let showchoicecolors = false
        let showtablecolors = false
        if(id === "showmaincolors"){
            if(s===true){
                showmaincolors = true
                showchoicecolors = false
                showtablecolors = false
            } else {showmaincolors = false}
        }

        if(id === "showchoicecolors"){
            if(s===true){
                showmaincolors = false
                showchoicecolors = true
                showtablecolors = false
            } else {showchoicecolors = false}
        }

        if(id === "showtablecolors"){
            if(s===true){
                showmaincolors = false
                showchoicecolors = false
                showtablecolors = true
            } else {showtablecolors = false}
        }
        this.setState({showmaincolors, showchoicecolors, showtablecolors})
    }

    activeSelection(id){
        let activecolor = this.props.surveyprops.style
        activecolor = activecolor[id]
        this.setState({activeitem:id, activecolor:activecolor})
    }

    changeColor = event => {
        let item = {}
        item['name']=this.state.activeitem
        item['value']=event.hex
        this.setState({activecolor:event.hex}, ()=>{this.props.updateSurveyStyleProps(item)})
    }

    getBackgroundColor(c){
        if(this.state.activeitem === c){
        return("#d8e4e8")}
        else {return ("transparent")}
    }

    getCurrentColorChoices(){
        let colorchoices = null
        if(this.state.showmaincolors === true){
            colorchoices="Main Canvas"
        }
        if(this.state.showchoicecolors === true){
            colorchoices="Choices and Selectors"
        }
        if(this.state.showtablecolors === true){
            colorchoices="Tables"
        }
        return(
            <div style={{fontSize:"0.7em", display:"flex", fontWeight:"300"}}>
            <p style={{color:'#20a8d8', marginRight:"5px"}}>{colorchoices}</p>
            </div>
        )
    }


  render(){

    return(
    <div>
        <ModalHeader style={{backgroundColor:"#2f353a", color:"#8f9ba6", fontSize:"1.2em", fontWeight:"200"}}>
            {`Survey Style Properties `}
            {this.getCurrentColorChoices()}

            </ModalHeader>
        <ModalBody>
            <div style={{display:"flex"}}>
            <div style={{flexGrow:"1"}}>
            {this.state.showmaincolors &&
            <div>
            <Row>
                <Col onClick = {() => this.activeSelection("pagebodycolor")} style={{padding:"5px", cursor:"pointer"}}>
                    <div style={{backgroundColor:this.getBackgroundColor("pagebodycolor"), display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-start"}}>
                   <div style={{backgroundColor:this.props.surveyprops.style.pagebodycolor, width:"20px", height:"20px", borderRadius:"50%", marginTop:"5px"}}></div>
                   <p style={{marginBottom:"5px"}}><small>main bkgrnd</small></p>
                   </div>
                </Col>
                <Col onClick = {() => this.activeSelection("pagefootcolor")} style={{padding:"5px"}}>
                    <div style={{backgroundColor:this.getBackgroundColor("pagefootcolor"), display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-start", marginBottom:"5px"}}>
                    <div style={{backgroundColor:this.props.surveyprops.style.pagefootcolor, width:"20px", height:"20px", borderRadius:"50%", marginTop:"5px"}}></div>
                    <p style={{marginBottom:"5px"}}><small>main footer</small></p>
                   </div>
                </Col>
            </Row>
            <Row>
                <Col onClick = {() => this.activeSelection("pageheadtextcolor")} style={{padding:"5px"}}>
                    <div style={{backgroundColor:this.getBackgroundColor("pageheadtextcolor"), display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-start", marginBottom:"5px"}}>
                    <div style={{backgroundColor:this.props.surveyprops.style.pageheadtextcolor, width:"20px", height:"20px", borderRadius:"50%", marginTop:"5px"}}></div>
                    <p style={{marginBottom:"5px"}}><small>pg head txt</small></p>
                   </div>
                </Col>

                <Col onClick = {() => this.activeSelection("questionheadtextcolor")} style={{padding:"5px"}}>
                    <div style={{backgroundColor:this.getBackgroundColor( "questionheadtextcolor"), display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-start", marginBottom:"5px"}}>
                    <div style={{backgroundColor:this.props.surveyprops.style.questionheadtextcolor, width:"20px", height:"20px", borderRadius:"50%", marginTop:"5px"}}></div>
                    <p style={{marginBottom:"5px"}}><small>title text</small></p>
                   </div>
                </Col>
            </Row>
            <Row>
                <Col onClick = {() => this.activeSelection("formbodycolor")} style={{padding:"5px"}}>
                    <div style={{backgroundColor:this.getBackgroundColor("formbodycolor"), display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-start", marginBottom:"5px"}}>
                    <div style={{backgroundColor:this.props.surveyprops.style.formbodycolor, width:"20px", height:"20px", borderRadius:"50%", marginTop:"5px"}}></div>
                    <p style={{marginBottom:"5px"}}><small>card body</small></p>
                   </div>
                </Col>
                <Col onClick = {() => this.activeSelection("formheadcolor")} style={{padding:"5px"}}>
                    <div style={{backgroundColor:this.getBackgroundColor("formheadcolor"), display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-start", marginBottom:"5px"}}>
                    <div style={{backgroundColor:this.props.surveyprops.style.formheadcolor, width:"20px", height:"20px", borderRadius:"50%", marginTop:"5px"}}></div>
                    <p style={{marginBottom:"5px"}}><small>card accent</small></p>
                   </div>
                </Col>
            </Row>
            <Row>     
                <Col onClick = {() => this.activeSelection("formfootcolor")} style={{padding:"5px"}}>
                    <div style={{backgroundColor:this.getBackgroundColor("formfootcolor"), display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-start", marginBottom:"5px"}}>
                    <div style={{backgroundColor:this.props.surveyprops.style.formfootcolor, width:"20px", height:"20px", borderRadius:"50%", marginTop:"5px"}}></div>
                    <p style={{marginBottom:"5px"}}><small>card footer</small></p>
                   </div>
                </Col>
                <Col onClick = {() => this.activeSelection("formheadtextcolor")} style={{padding:"5px"}}>
                    <div style={{backgroundColor:this.getBackgroundColor("formheadtextcolor"), display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-start", marginBottom:"5px"}}>
                    <div style={{backgroundColor:this.props.surveyprops.style.formheadtextcolor, width:"20px", height:"20px", borderRadius:"50%", marginTop:"5px"}}></div>
                    <p style={{marginBottom:"5px"}}><small>card head txt</small></p>
                   </div>
                </Col>
            </Row>
            <Row>
                <Col onClick = {() => this.activeSelection("formcardtextcolor")} style={{padding:"5px"}}>
                    <div style={{backgroundColor:this.getBackgroundColor("formcardtextcolor"), display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-start", marginBottom:"5px"}}>
                    <div style={{backgroundColor:this.props.surveyprops.style.formcardtextcolor, width:"20px", height:"20px", borderRadius:"50%", marginTop:"5px"}}></div>
                    <p style={{marginBottom:"5px"}}><small>main text color</small></p>
                   </div>
                </Col>
                <Col onClick = {() => this.activeSelection("formfoottextcolor")} style={{padding:"5px"}}>
                    <div style={{backgroundColor:this.getBackgroundColor("formfoottextcolor"), display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-start", marginBottom:"5px"}}>
                    <div style={{backgroundColor:this.props.surveyprops.style.formfoottextcolor, width:"20px", height:"20px", borderRadius:"50%", marginTop:"5px"}}></div>
                    <p style={{marginBottom:"5px"}}><small>card foot txt</small></p>
                   </div>
                </Col>
            </Row>
            <Row>
                <Col onClick = {() => this.activeSelection("jumbobackground")} style={{padding:"5px"}}>
                    <div style={{backgroundColor:this.getBackgroundColor("jumbobackground"), display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-start", marginBottom:"5px"}}>
                    <div style={{backgroundColor:this.props.surveyprops.style.jumbobackground, width:"20px", height:"20px", borderRadius:"50%", marginTop:"5px"}}></div>
                    <p style={{marginBottom:"5px"}}><small>jumbo bkgrnd</small></p>
                   </div>
                </Col>
                <Col onClick = {() => this.activeSelection("jumbotrontext")} style={{padding:"5px"}}>
                    <div style={{backgroundColor:this.getBackgroundColor("jumbotrontext"), display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-start", marginBottom:"5px"}}>
                    <div style={{backgroundColor:this.props.surveyprops.style.jumbotrontext, width:"20px", height:"20px", borderRadius:"50%", marginTop:"5px"}}></div>
                    <p style={{marginBottom:"5px"}}><small>jumbo txt</small></p>
                   </div>
                </Col>
            </Row>
            <Row>
                <Col onClick = {() => this.activeSelection("jumboline")} style={{padding:"5px"}}>
                    <div style={{backgroundColor:this.getBackgroundColor("jumboline"), display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-start", marginBottom:"5px"}}>
                    <div style={{backgroundColor:this.props.surveyprops.style.jumboline, width:"20px", height:"20px", borderRadius:"50%", marginTop:"5px"}}></div>
                    <p style={{marginBottom:"5px"}}><small>jumbo line</small></p>
                   </div>
                </Col>
                <Col onClick = {() => this.activeSelection("contentlistbackground")} style={{padding:"5px"}}>
                    <div style={{backgroundColor:this.getBackgroundColor("contentlistbackground"), display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-start", marginBottom:"5px"}}>
                    <div style={{backgroundColor:this.props.surveyprops.style.contentlistbackground, width:"20px", height:"20px", borderRadius:"50%", marginTop:"5px"}}></div>
                    <p style={{marginBottom:"5px"}}><small>list bkgrnd</small></p>
                   </div>
                </Col>
            </Row>
            <Row>
                <Col onClick = {() => this.activeSelection("contentlisttext")} style={{padding:"5px"}}>
                    <div style={{backgroundColor:this.getBackgroundColor("contentlisttext"), display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-start", marginBottom:"5px"}}>
                    <div style={{backgroundColor:this.props.surveyprops.style.contentlisttext, width:"20px", height:"20px", borderRadius:"50%", marginTop:"5px"}}></div>
                    <p style={{marginBottom:"5px"}}><small>list txt</small></p>
                   </div>
                </Col>
                <Col onClick = {() => this.activeSelection("contentlistborder")} style={{padding:"5px"}}>
                    <div style={{backgroundColor:this.getBackgroundColor("contentlistborder"), display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-start", marginBottom:"5px"}}>
                    <div style={{backgroundColor:this.props.surveyprops.style.contentlistborder, width:"20px", height:"20px", borderRadius:"50%", marginTop:"5px"}}></div>
                    <p style={{marginBottom:"5px"}}><small>list border</small></p>
                   </div>
                </Col>
            </Row>
            </div>
            }

            {this.state.showchoicecolors &&
            <div>
            <Row>
                <Col onClick = {() => this.activeSelection("noselectcolor")} style={{padding:"5px"}}>
                    <div style={{backgroundColor:this.getBackgroundColor("noselectcolor"), display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-start", marginBottom:"5px"}}>
                    <div style={{backgroundColor:this.props.surveyprops.questionpropdefaults.noselectcolor, width:"20px", height:"20px", borderRadius:"50%", marginTop:"5px"}}></div>
                    <p style={{marginBottom:"5px"}}><small>no select</small></p>
                   </div>
                </Col>
                <Col onClick = {() => this.activeSelection("touchcolor")} style={{padding:"5px"}}>
                    <div style={{backgroundColor:this.getBackgroundColor("touchcolor"), display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-start", marginBottom:"5px"}}>
                    <div style={{backgroundColor:this.props.surveyprops.questionpropdefaults.touchcolor, width:"20px", height:"20px", borderRadius:"50%", marginTop:"5px"}}></div>
                    <p style={{marginBottom:"5px"}}><small>touch color</small></p>
                   </div>
                </Col>
            </Row>
            <Row>
                <Col onClick = {() => this.activeSelection("selectcolor")} style={{padding:"5px"}}>
                    <div style={{backgroundColor:this.getBackgroundColor("selectcolor"), display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-start", marginBottom:"5px"}}>
                    <div style={{backgroundColor:this.props.surveyprops.questionpropdefaults.selectcolor, width:"20px", height:"20px", borderRadius:"50%", marginTop:"5px"}}></div>
                    <p style={{marginBottom:"5px"}}><small>select color</small></p>
                   </div>
                </Col>
                <Col onClick = {() => this.activeSelection("cardcardtextcolor")} style={{padding:"5px"}}>
                    <div style={{backgroundColor:this.getBackgroundColor("cardcardtextcolor"), display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-start", marginBottom:"5px"}}>
                    <div style={{backgroundColor:this.props.surveyprops.style.cardcardtextcolor, width:"20px", height:"20px", borderRadius:"50%", marginTop:"5px"}}></div>
                    <p style={{marginBottom:"5px"}}><small>choice txt</small></p>
                   </div>
                </Col>
            </Row>
            <Row>
                <Col onClick = {() => this.activeSelection("cardheadcolor")} style={{padding:"5px", cursor:"pointer"}}>
                    <div style={{backgroundColor:this.getBackgroundColor("cardheadcolor"), display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-start"}}>
                   <div style={{backgroundColor:this.props.surveyprops.style.cardheadcolor, width:"20px", height:"20px", borderRadius:"50%", marginTop:"5px"}}></div>
                   <p style={{marginBottom:"5px"}}><small>choice head</small></p>
                   </div>
                </Col>
                <Col onClick = {() => this.activeSelection("cardfootcolor")} style={{padding:"5px"}}>
                    <div style={{backgroundColor:this.getBackgroundColor("cardfootcolor"), display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-start", marginBottom:"5px"}}>
                    <div style={{backgroundColor:this.props.surveyprops.style.cardfootcolor, width:"20px", height:"20px", borderRadius:"50%", marginTop:"5px"}}></div>
                    <p style={{marginBottom:"5px"}}><small>choice footer</small></p>
                   </div>
                </Col>
            </Row>
            <Row>
                <Col onClick = {() => this.activeSelection("cardheadtextcolor")} style={{padding:"5px"}}>
                    <div style={{backgroundColor:this.getBackgroundColor("cardheadtextcolor"), display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-start", marginBottom:"5px"}}>
                    <div style={{backgroundColor:this.props.surveyprops.style.cardheadtextcolor, width:"20px", height:"20px", borderRadius:"50%", marginTop:"5px"}}></div>
                    <p style={{marginBottom:"5px"}}><small>choice head txt</small></p>
                   </div>
                </Col>
                <Col onClick = {() => this.activeSelection("cardfoottextcolor")} style={{padding:"5px"}}>
                    <div style={{backgroundColor:this.getBackgroundColor( "cardfoottextcolor"), display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-start", marginBottom:"5px"}}>
                    <div style={{backgroundColor:this.props.surveyprops.style.cardfoottextcolor, width:"20px", height:"20px", borderRadius:"50%", marginTop:"5px"}}></div>
                    <p style={{marginBottom:"5px"}}><small>choice foot txt</small></p>
                   </div>
                </Col>
            </Row>
            <Row>
                <Col onClick = {() => this.activeSelection("featurebackgroundcolor")} style={{padding:"5px"}}>
                    <div style={{backgroundColor:this.getBackgroundColor("featurebackgroundcolor"), display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-start", marginBottom:"5px"}}>
                    <div style={{backgroundColor:this.props.surveyprops.style.featurebackgroundcolor, width:"20px", height:"20px", borderRadius:"50%", marginTop:"5px"}}></div>
                    <p style={{marginBottom:"5px"}}><small>feature bkgnd</small></p>
                   </div>
                </Col>
                <Col onClick = {() => this.activeSelection("featureoutlinecolor")} style={{padding:"5px"}}>
                    <div style={{backgroundColor:this.getBackgroundColor("featureoutlinecolor"), display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-start", marginBottom:"5px"}}>
                    <div style={{backgroundColor:this.props.surveyprops.style.featureoutlinecolor, width:"20px", height:"20px", borderRadius:"50%", marginTop:"5px"}}></div>
                    <p style={{marginBottom:"5px"}}><small>feature outline</small></p>
                   </div>
                </Col>
            </Row>
            <Row>
                <Col onClick = {() => this.activeSelection("pricebackgroundcolor")} style={{padding:"5px"}}>
                    <div style={{backgroundColor:this.getBackgroundColor("pricebackgroundcolor"), display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-start", marginBottom:"5px"}}>
                    <div style={{backgroundColor:this.props.surveyprops.style.pricebackgroundcolor, width:"20px", height:"20px", borderRadius:"50%", marginTop:"5px"}}></div>
                    <p style={{marginBottom:"5px"}}><small>price bkgrd</small></p>
                   </div>
                </Col>
                <Col onClick = {() => this.activeSelection("pricetextcolor")} style={{padding:"5px"}}>
                    <div style={{backgroundColor:this.getBackgroundColor("pricetextcolor"), display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-start", marginBottom:"5px"}}>
                    <div style={{backgroundColor:this.props.surveyprops.style.pricetextcolor, width:"20px", height:"20px", borderRadius:"50%", marginTop:"5px"}}></div>
                    <p style={{marginBottom:"5px"}}><small>price text</small></p>
                   </div>
                </Col>
            </Row>
            <Row>
                <Col onClick = {() => this.activeSelection("bestselectcolor")} style={{padding:"5px"}}>
                    <div style={{backgroundColor:this.getBackgroundColor("bestselectcolor"), display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-start", marginBottom:"5px"}}>
                    <div style={{backgroundColor:this.props.surveyprops.questionpropdefaults.bestselectcolor, width:"20px", height:"20px", borderRadius:"50%", marginTop:"5px"}}></div>
                    <p style={{marginBottom:"5px"}}><small>best select</small></p>
                   </div>
                </Col>
                <Col onClick = {() => this.activeSelection("worstselectcolor")} style={{padding:"5px"}}>
                    <div style={{backgroundColor:this.getBackgroundColor("worstselectcolor"), display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-start", marginBottom:"5px"}}>
                    <div style={{backgroundColor:this.props.surveyprops.questionpropdefaults.worstselectcolor, width:"20px", height:"20px", borderRadius:"50%", marginTop:"5px"}}></div>
                    <p style={{marginBottom:"5px"}}><small>worst select</small></p>
                   </div>
                </Col>

            </Row>
            </div>
            }

            {this.state.showtablecolors &&
            <div>
            <Row>
            <Col onClick = {() => this.activeSelection("tablebackground")} style={{padding:"5px"}}>
                    <div style={{backgroundColor:this.getBackgroundColor("tablebackground"), display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-start", marginBottom:"5px"}}>
                    <div style={{backgroundColor:this.props.surveyprops.style.tablebackground, width:"20px", height:"20px", borderRadius:"50%", marginTop:"5px"}}></div>
                    <p style={{marginBottom:"5px"}}><small>table bkgrnd</small></p>
                   </div>
                </Col>
                <Col onClick = {() => this.activeSelection("tablebodytext")} style={{padding:"5px"}}>
                    <div style={{backgroundColor:this.getBackgroundColor("tablebodytext"), display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-start", marginBottom:"5px"}}>
                    <div style={{backgroundColor:this.props.surveyprops.style.tablebodytext, width:"20px", height:"20px", borderRadius:"50%", marginTop:"5px"}}></div>
                    <p style={{marginBottom:"5px"}}><small>table text</small></p>
                   </div>
                </Col>
            </Row>
            <Row>
                <Col onClick = {() => this.activeSelection("tableheadbackground")} style={{padding:"5px"}}>
                    <div style={{backgroundColor:this.getBackgroundColor("tableheadbackground"), display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-start", marginBottom:"5px"}}>
                    <div style={{backgroundColor:this.props.surveyprops.style.tableheadbackground, width:"20px", height:"20px", borderRadius:"50%", marginTop:"5px"}}></div>
                    <p style={{marginBottom:"5px"}}><small>table head</small></p>
                   </div>
                </Col>
                <Col onClick = {() => this.activeSelection("tableheadtext")} style={{padding:"5px"}}>
                    <div style={{backgroundColor:this.getBackgroundColor("tableheadtext"), display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-start", marginBottom:"5px"}}>
                    <div style={{backgroundColor:this.props.surveyprops.style.tableheadtext, width:"20px", height:"20px", borderRadius:"50%", marginTop:"5px"}}></div>
                    <p style={{marginBottom:"5px"}}><small>table head txt</small></p>
                   </div>
                </Col>
            </Row>

            </div>
            }
            </div>
            <div style={{width:"300"}}>
            <BlockPicker
            color={this.state.activecolor}
            triangle={"hide"}
            colors={defaultcolors}
            onChange={this.changeColor}
            />
            </div>
            </div>


            <div style={{ padding:"30px", marginLeft:"-16px", marginRight:"-16px", marginBottom:"-16px", marginTop:"50px", color:"white", fontSize:"0.8em", backgroundColor:"#2f353a" }}>
                

                <Row style={{display:"flex", alignItems:"flex-end"}}>

                    <div style={{display:"flex", justifyContent:"flex-end", alignItems:"stretch"}}>
  
                        <Col>
                        <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Show Canvas Colors</p>
                        <Toggle
                        id={"showmaincolors"}
                        checked= {this.state.showmaincolors}
                        icons={{unchecked: null}}
                        className = {"custom-success"}
                        onChange = {() => this.toggleSelection("showmaincolors", !this.state.showmaincolors)}
                            />
                        </Col>
                        <Col>
                        <p style={{ marginBottom:"5px", fontSize:"0.8em",}}>Show Choice Colors</p>
                        <Toggle
                        id={"showchoicecolors"}
                        checked= {this.state.showchoicecolors}
                        icons={{unchecked: null}}
                        className = {"custom-success"}
                        onChange = {() => this.toggleSelection("showchoicecolors", !this.state.showchoicecolors)}
                            />
                        </Col>
                        <Col>
                        <p style={{ marginBottom:"5px", fontSize:"0.8em",}}>Show Table Colors</p>
                        <Toggle
                        id={"showtablecolors"}
                        checked= {this.state.showtablecolors}
                        icons={{unchecked: null}}
                        className = {"custom-success"}
                        onChange = {() => this.toggleSelection("showtablecolors", !this.state.showtablecolors)}
                            />
                        </Col>
                        { this.state.showtablecolors && 
                       <Col>
                       <p style={{ marginBottom:"5px", fontSize:"0.8em", }}>Table Cell Borders</p>
                        <Toggle
                        id={"tablecellborders"}
                        checked= {this.props.surveyprops.style.tablecellborders}
                        icons={{unchecked: null}}
                        className = {"custom-success"}
                        onChange = {() => this.toggleSelection("tablecellborders", !this.props.surveyprops.style.tablecellborders)}
                            />
                        </Col>
                        }
                    </div>

                </Row>
                
 
            </div>

        </ModalBody>
        <ModalFooter style={{marginTop:"0"}}>
        <div style={{alignSelf:"flex-start", marginLeft:"auto"}}>
        {'  '}
        <Button color="primary" onClick={()=>this.props.toggleModal()} >Save</Button>
        {'  '}
        <Button color="secondary" onClick={()=>this.props.cancelSurveyPropChanges(this.state.snapshot)} >Cancel</Button>
        </div>
        </ModalFooter>
  </div>



    );
  }
}
import React, { Component } from "react";
import { Button, Card, CardBody, Container, Col,   CardHeader, CardFooter, Row, Jumbotron, ListGroupItem, ListGroupItemHeading, ListGroupItemText } from 'reactstrap';
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import Toggle from 'react-toggle'
import 'react-toggle/style.css'

let initialvals = {"PROJECT":false, "PRO":false, "ENTERPRISE":false, "FREE":false}

export default class SelectPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      selectvalue: initialvals,
      pageValid: false,
      accesscode:"",
    };
  }

  changeSelection(i, e){
    let newvalue = {}
    let validcheck = false
    if (e===true){
        for (let x in this.state.selectvalue){
            newvalue[x] = false
            validcheck = true
        }
    } else {
        for (let x in this.state.selectvalue){
            newvalue[x] = this.state.selectvalue[x]
            validcheck = false
        }
    }
    newvalue[i] = e

  this.setState(prevState => ({selectvalue: {...prevState.selectvalue, ...newvalue}, pageValid:validcheck}))
}

productConfirm(){
  let prod = null
  for (let s in this.state.selectvalue){
    if (this.state.selectvalue[s]===true){
      prod = s
    }
  }
  this.props.userHasProduct(prod);
  this.props.history.push("/paymentsettings");
}

handleChange = event => {
  this.setState({
    [event.target.id]: event.target.value
  })
}

getStyle(c){
  if(this.state.selectvalue[c]===true){
    return(
      "3px solid #20a8d8"
    )} else{
      return(
        "1px solid gray"
      )
    }
  }

  render() {
    return (
      <Container>

        <Jumbotron>
          <h1>Welcome to Pricekit!</h1>
          <h4>We can help you become a more data-driven product team and get answers to your most important problems</h4>
          <hr/>
          <p style={{marginTop:"15px"}}>You signed up as <mark>{this.props.email}</mark> Please choose the plan that's right for you. </p>
        </Jumbotron>

      <div style={{display:"flex", flexWrap: "nowrap", justifyContent:"space-evenly", alignItems:"stretch",}}>

        <Card md="4" style={{marginRight:"10px", border:this.getStyle("PROJECT")}}>
        <CardBody >
          <div style={{marginTop:"5px", marginBottom:"10px"}}>
          <div className="h1 mt-0 mb-0" style={{fontSize:"2em"}}>Single Project Plan</div>
          <small className="text-muted text-uppercase font-weight-bold">3 months access, single survey, all our features</small>
          </div>
          <div className="h1 mt-0 mb-0" style={{fontSize:"1.2em", marginTop:"15px", marginBottom:"15px"}}>$ 249 one time payment</div>
            

            <div style={{marginTop:"15px", marginBottom:"15px"}}>
              <p>Single user access</p>
              <p>Single live survey</p>
              <p>Maximum of 300 survey respondents</p>
              <p>All types of questions and custom styling</p>
              <p>Automatically test packages and price points</p>
              <p>Access detailed charts, graphs and data</p>
              <p>Capture design and UX feedback</p>
              <p>Download data for offline analysis</p>
              <p>Uniquely track respondents by segment or channel</p>
            </div>

            </CardBody>

            <CardFooter>

              <Toggle
              id={"PROJECT"}
              value={"PROJECT"}
              checked= {this.state.selectvalue["PROJECT"]}
              icons={{unchecked: null}}
              className = {"custom-success"}
              onChange = {() => this.changeSelection("PROJECT", !this.state.selectvalue["PROJECT"])}
              />

            </CardFooter>

          </Card>      
        
          <Card md="4" style={{border:this.getStyle("PRO")}}>

        <CardBody>
        <div style={{marginTop:"5px", marginBottom:"10px"}}>
          <div className="h1 mt-0 mb-0" style={{fontSize:"2em"}}>Professional Plan</div>
          <small className="text-muted text-uppercase font-weight-bold">Single user, unlimited surveys, core features</small>
        </div>
          <div className="h1 mt-0 mb-0" style={{fontSize:"1.2em", marginTop:"15px", marginBottom:"15px"}}>$ 49 per month</div>
            

            <div style={{marginTop:"15px", marginBottom:"15px"}} >
              <p>Single user access</p>
              <p>Unlimited live surveys</p>
              <p>Unlimited survey respondents</p>
              <p>All types of questions and custom styling</p>
              <p>Automatically test packages and price points</p>
              <p>Access detailed charts, graphs and data</p>
            </div>

            </CardBody>
  
              <CardFooter>
  
                <Toggle
                id={"PRO"}
                value={"PRO"}
                checked= {this.state.selectvalue["PRO"]}
                icons={{unchecked: null}}
                className = {"custom-success"}
                onChange = {() => this.changeSelection("PRO", !this.state.selectvalue["PRO"])}
                />
  
              </CardFooter>
  
            </Card >      
        
            <Card md="4" style={{marginLeft:"10px", border:this.getStyle("ENTERPRISE")}}>

          <CardBody>
          <div style={{marginTop:"5px", marginBottom:"10px"}}>
            <div className="h1 mt-0 mb-0" style={{fontSize:"2em"}}>Enterprise Plan</div>
            <small className="text-muted text-uppercase font-weight-bold">Team access, unlimited surveys, all our features</small>
          </div>    
            <div className="h1 mt-0 mb-0" style={{fontSize:"1.2em", marginTop:"15px", marginBottom:"15px"}}>$249 per month</div>
              

              <div style={{marginTop:"15px", marginBottom:"15px"}}>
                <p>Team access to shared content and common data</p>
                <p>Unlimited live surveys</p>
                <p>Unlimited survey respondents</p>
                <p>All types of questions and custom styling</p>
                <p>Automatically test packages and price points</p>
                <p>Access detailed charts, graphs and data</p>
                <p>Capture design and UX feedback</p>
                <p>Download data for offline analysis</p>
                <p>Uniquely track respondents by segment or channel</p>
              </div>

              </CardBody>

          <CardFooter>

            <Toggle
            id={"ENTERPRISE"}
            value={"ENTERPRISE"}
            checked= {this.state.selectvalue["ENTERPRISE"]}
            icons={{unchecked: null}}
            className = {"custom-success"}
            onChange = {() => this.changeSelection("ENTERPRISE", !this.state.selectvalue["ENTERPRISE"])}
            />

          </CardFooter>

        </Card>      
      
      </div>
        <Row style={{marginBottom:"30px"}}>
            <Col md="4">
                <Button color="primary" size="lg" type="submit" disabled={!this.state.pageValid} onClick={() => {this.productConfirm()}}>Submit Selection</Button>
            </Col>
            <Col md="4">
            </Col>
            <Col md="4" >
            <FormGroup controlId="accesscode" bsSize="small">
              <FormControl
              placeholder={"If you have an access code, enter it here"}
              value={this.state.accesscode}
              onChange={this.handleChange}
              type="text"
              />
            </FormGroup>
            </Col>
        </Row>


      </Container>


    );
  }

}

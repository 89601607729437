import React, { Component } from "react";
import { Button, Card, CardBody, CardGroup, CardHeader, Col, Container, Row } from 'reactstrap';

export default class PackageExpanderMenu extends Component {
    constructor(props) {
     console.log('getting expander for package')
      super(props);
      this.getFeature = this.getFeature.bind(this)
      this.state = {
      };
    }


        editSurvey(s){
            console.log('sending the survey', s)
            this.props.userHasActiveSurvey(s)
            console.log('now on to the edit...')
        }

        getFeature(feature){
          return(
            <div style={{marginTop:"5px", marginBottom:"5px"}}>
              <p style={{color:'#5c6873'}}><small>{feature}</small></p>
            </div>
          )
        }


    render() {
        return (
 
            <div style={{backgroundColor:"#f0f3f5", marginLeft:"50px"}}>
            <Row>
              <div style={{marginLeft:"15px", marginRight:"15px", marginTop:"10px", display:"flex", justifyContent:"space-around"}}>
        
  
              <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-end",marginLeft:"15px"}}>
              <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Updated By</small></p>
              <p style={{color:'#5c6873'}}><small>{this.props.data.email}</small></p>
              </div>

              {this.props.data.img !== "None" &&
              <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-end",marginLeft:"15px"}}>
              <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Image</small></p>
              <p style={{color:'#5c6873'}}><small>{this.props.data.img}</small></p>
              </div>
              }

              {this.props.data.video !== "None" &&
              <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-end",marginLeft:"15px"}}>
              <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Video</small></p>
              <p style={{color:'#5c6873'}}><small>{this.props.data.video}</small></p>
              </div>
              }

              {this.props.data.gif !== "None" &&
              <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-end",marginLeft:"15px"}}>
              <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Video</small></p>
              <p style={{color:'#5c6873'}}><small>{this.props.data.gif}</small></p>
              </div>
              }
              </div>
            </Row>
            {this.props.data.features !== "" &&
            <div style={{marginLeft:"15px", marginBottom:"15px"}}>
                <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Features</small></p>
                {this.props.data.features.map((feature) => this.getFeature(feature))}
            </div>
            }
            </div>
      );
    }
}
import React, { Component } from "react";
import { API } from "aws-amplify";
import { Button, Card, CardBody, CardGroup, CardHeader, Col, Container, Row, Jumbotron, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SolidInfoCard from '../components/SolidInfoCard'
import DataTableFeature from '../components/DataTableFeature'
import FeatureEditorModal from "../survey/FeatureEditorModal"
import SolidActionCard from '../components/SolidActionCard'
import SurveyAddCard from '../components/SurveyAddCard'
import CreatableSelect from 'react-select/creatable'


    /// styling for react-select
    const customStyles2 = (isValid) => ({
      option: (provided, state) => ({
        ...provided,
        fontSize: '1em',
        borderBottom: '1px dotted black',
        backgroundColor: state.isSelected ? '#2f353a' : 'white',
        color: state.isSelected ? '#20a8d8' : '#2f353a',
        padding: 20,
      }),
        control: (styles: any, state:any) => ({
          ...styles,
          color:"#2f353a",
          backgroundColor:"#f0f3f5",
          padding: 5,
          fontSize: "smaller",
          ...(!isValid && { borderColor: 'red' }),
        }),
      });

function truncateString(str, num) {
  if (typeof str === 'undefined'){
    return "..."
  }
  if (str === ""){
    return "..."
  }
  if (str === null){
    return "..."
  }
  if (str.length <= num) {
    return str
  }
  return str.slice(0, num) + '...'
}

function makeId(length) {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}


function getTime(){
  let today = new Date();
  let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
  let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  let dateTime = date+' '+time;
  return(dateTime)
}

export default class Features extends Component {
    constructor(props) {
      super(props);
      this.updateCard = this.updateCard.bind(this)
      this.getRow = this.getRow.bind(this)
      this.handleAction = this.handleAction.bind(this)
      this.routeAction = this.routeAction.bind(this)
      this.saveFeature = this.saveFeature.bind(this)
      this.deleteFeature = this.deleteFeature.bind(this)
      this.updateTable = this.updateTable.bind(this)
      this.state = {
        isLoading: true,
        numberfeatures:0,
        showModal: false,
        currentfeature:null,
        modalsize:"md",
        row:null,
        isSaved:true,
        modifiedfeature: null,
        featuretype:[],
        updatetable: false,
      };
    }

    async componentDidMount() {
      if (!this.props.isAuthenticated) {return};

      /// to handle page refresh will try to reset props from storage

      if(this.props.orgId === null){
        try{
          this.props.userHasEmail(localStorage.getItem('email'))
          this.props.userHasId(localStorage.getItem('userId'))
          this.props.userHasRole(localStorage.getItem('userRole'))
          this.props.userHasLicense(localStorage.getItem('userLicense'))
          this.props.userHasProduct(localStorage.getItem('userProduct'))
          this.props.userHasOrg(localStorage.getItem('orgId'))
        } catch {
          this.props.history.push("/home")
        }
      }


      if(this.props.orgId !== null){
      try{
        let querystring = {'queryStringParameters':{'orgId':this.props.orgId}}
        let refresponse = await API.get("reference", "/reference", querystring)
        this.setState({featuretype: refresponse['featuretype'], isLoading: false})
      } catch (e) {
          alert('There was problem getting feature type reference list', e);
      }
    } 
    }

    updateCard(n){
      this.setState({numberfeatures:n, updatetable:false})
    }

    getRow(s, r){
      if(s===true){
        this.setState({row:r, activefeature:r['featureId']})
      }
    } 

      /// need to handle other actions like publish, stop and delete
      handleAction(action){

        if(this.state.row!==null){ 
            if(action === 'Edit'){
              this.setState({showModal: true})
              }
      }
      if(action === 'Delete'){
        console.log('...handleaction..')
        this.deleteFeature(this.state.row)
        }
    }


    routeAction(survey, stat){

      if (stat === "New"){
        let newrow = {}
        newrow['featureId'] = makeId(5)
        newrow['header'] = ""
        newrow['detail'] = ""
        newrow['price'] = null
        newrow['featuretype'] = null
        newrow['pricemetric'] = null
        newrow['isActive']=true
        
        this.setState({row:newrow, showModal:true})
      }

    }


    updateTable(i){
      this.setState({updatetable:i})
    }

    toggleModal= modal => {
      this.setState({ showModal: false, activefeature:null, row:null})
    }

    async putFeature(feature){

      console.log('...put feature...')

      let resp = await API.put("features", "/features", {body:feature})
      this.setState({isSaved:true, showModal:false,updatetable:true, modifiedfeature:feature['featureId']})
  }

  deleteFeature(f){
    console.log('...deletefeature...')
    const feature = {}
    feature['localsavetime']=getTime()
    feature['email']= this.props.email
    feature['orgId']=this.props.orgId
    feature['userId']=this.props.userId
    feature['featureId'] = f['featureId']
    feature['header'] = f['header']
    feature['detail']=f['detail']
    feature['price'] = f['price']
    feature['pricemetric']=f['pricemetric']
    feature['featuretype']=f['featuretype']
    feature['isActive']=f['isActive']
    feature['action']="Delete"

    this.setState({isSaved:false, showModal:false, row:null}, ()=> this.putFeature(feature))
}

  
  saveFeature(f){

    console.log('...saving feature...')
      
      const feature = {}
      feature['localsavetime']=getTime()
      feature['email']= this.props.email
      feature['orgId']=this.props.orgId
      feature['userId']=this.props.userId
      feature['featureId'] = f['featureId']
      feature['header'] = f['header']
      feature['detail']=f['detail']
      feature['price'] = f['price']
      feature['pricemetric']=f['pricemetric']
      feature['featuretype']=f['featuretype']
      feature['isActive']=f['isActive']
      feature['action']="Save"

      this.setState({isSaved:false}, ()=> this.putFeature(feature))
      
  }

  getFeatureSelect(){

    if(!this.state.isLoading){
      return(
        <CreatableSelect
        isMulti
        defaultValue={this.state.featuretype}
        onChange={this.updateFeatureType}
        options={this.state.featuretype}
        name={'settings'}
        id={'settings'}
        styles={customStyles2(true)}
        />
      )
    }
}

  updateFeatureType = (selectvalue, action) => {

    if(selectvalue !== null){
        let ftype={}
        ftype['localsavetime']=getTime()
        ftype['email']= this.props.email
        ftype['orgId']=this.props.orgId
        ftype['updatetype']='ALL'         
        ftype['featuretype']=selectvalue
        let respon = API.put("reference", "/reference", {body:ftype})
        this.setState({featuretype: selectvalue})
    }
    }

    getModal(){

          return(
          <FeatureEditorModal
          feature={this.state.row}
          showModal={this.state.showModal}
          toggleModal={this.toggleModal}
          deleteItem={this.deleteFeature}
          saveFeature={this.saveFeature}
          orgId={this.props.orgId}
          email={this.props.email}
          featuretype={this.state.featuretype}
          />
          )
          }



    render() {
        return (
          <div>

            {this.state.showModal && 
            <Modal isOpen={this.state.showModal} autoFocus={false} size={this.state.modalsize} centered >
                {this.getModal()}
            </Modal>
            }

            <Jumbotron style={{marginBottom:"30px", marginTop:"0px", padding:"0px"}}>
              <h1>Features</h1>
              <h5 style={{fontWeight:"200"}}>The list of key attributes that make up your products, offers and concepts</h5>
              <hr/>
              <p style={{marginTop:"15px"}}>{` `}</p>
            </Jumbotron>

            <Row>
            <Col sm="6" md="2">
                  <SolidInfoCard  color="dark" header={this.state.numberfeatures} value="100" invert >Total Features</SolidInfoCard>
             </Col>
             
                {this.state.row !==null && 
                <Col sm="6" md="2">
                  <div style={{width:"100%", height:"100%", paddingBottom:"1.5rem"}}>
                  <SolidActionCard icon="icon-layer" color="primary" invert 
                  header={this.state.row['featureId']} value="100"
                  actionstatus={null}
                  handleAction={this.handleAction}
                  actiontype="Feature">
                    {this.state.row.header}</SolidActionCard>
                    </div>
                  </Col>
                  
                }

              <Col sm="6" md="4">
                <div style={{width:"100%", height:"100%", paddingBottom:"1.5rem"}}>
                <Card style={{ height:"100%", backgroundColor:"#f0f3f5"}}>     
                <CardBody>
                <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-end",marginRight:"10px", flexGrow:'1'}}>
                <div><small className="text-muted text-uppercase font-weight-bold">Feature Types</small></div>
                <div style={{flexDirection:"column", alignItems:"flex-end", marginLeft:"5px", marginTop:"5px", marginRight:"15px", width:"100%"}}>

                {this.getFeatureSelect()}

                </div>
                </div>
                </CardBody>
                </Card>
                </div>
              </Col>

                <Col sm="6" md="2" >
                <SurveyAddCard
                  color="dark"
                  invert
                  value="100"
                  type="feature"
                  routeAction={this.routeAction}
                  />
                </Col>
            </Row>

            {this.props.orgId &&
            <DataTableFeature 
            orgId={this.props.orgId}
            getRow={this.getRow}
            updateCard={this.updateCard}
            modifiedfeature={this.state.modifiedfeature}
            updatetable={this.state.updatetable}
            updateTable={this.updateTable}
            />
            }
          </div>
    
        );
      }
    }
    
import React, { Component } from "react";
import { Button, Card, CardBody, CardGroup, CardHeader, Col, Container, Row } from 'reactstrap';

export default class AssetExpanderMenu extends Component {
    constructor(props) {
      super(props);
      this.state = {
      };
    }


/// email

    render() {
        return (
 
            <div style={{backgroundColor:"#f0f3f5", marginLeft:"50px"}}>
            <Row>
            <div style={{marginLeft:"15px", marginRight:"15px", marginTop:"10px", display:"flex", justifyContent:"space-around"}}> 
                <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-end",marginLeft:"15px"}}>
                <p style={{color:'#2f353a', fontWeight:"400", marginBottom:"5px"}}><small>Updated By</small></p>
                <p style={{color:'#5c6873'}}><small>{this.props.data.email}</small></p>
                </div>
            </div>
            </Row>            
            </div>
      );
    }
}
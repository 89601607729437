import React, { Component } from "react";
import { API } from "aws-amplify";
import { Button, Card, CardBody, CardGroup, CardHeader, CardFooter, Col, Container, Row, Input, Jumbotron } from 'reactstrap';
import Loader from "react-loader-spinner";
import SolidInfoCard from '../components/SolidInfoCard'
import SolidInfoCardText from '../components/SolidInfoCardText'
import DashBar from '../components/DashBar'
import DashHeatmap from '../components/DashHeatmap'
import DashScatter from '../components/DashScatter'
import DashPie from '../components/DashPie'
import ResponseToggleCard from '../components/ResponseToggleCard'
import Select from 'react-select';
import Toggle from 'react-toggle'
 
function getDate(){
  let today = new Date();
  let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
  return(date)
}

/// styling for react-select
const customStyles = (isValid) => ({
    option: (provided, state) => ({
        ...provided,
        padding: 20,
      }),
    control: (styles: any, state:any) => ({
      ...styles,
      padding: 5,
      width: "100%",
      fontSize: "smaller",
      color:"#20a8d8",
      ...(!isValid && { borderColor: 'red', color: 'black' }),
    }),
  });

function truncateString(str, num) {
    if (typeof str === 'undefined'){
      return "..."
    }
    if (str === ""){
      return "..."
    }
    if (str === null){
      return "..."
    }
    if (str.length <= num) {
      return str
    }
    return str.slice(0, num) + '...'
  }

const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

export default class PackageAnalysis extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isLoading: true,
        data:this.props.data,
        models:this.props.models,
        showgraphs: this.props.showgraphs,
      };
    }


    async componentDidMount() {
      if (!this.props.isAuthenticated) {return};

    }


    handleToggleChange(){
      this.setState({showgraphs: !this.state.showgraphs})
    }

    getHeadline(){

      let splitdate = this.props.survey.surveystart.split(' ')

      if(this.props.surveyId !== undefined && this.props.surveyId !== null){
          return(
          <div style={{display:"flex", marginTop:"25px", marginBottom:"25px", alignItems:"baseline"}}>

            <p>Current data for survey: <mark>{` ${this.props.surveyId} ${this.props.survey.surveytitle} `}</mark></p>
            <small className="text-muted font-weight-light" style={{marginLeft:"15px"}}>{`Start Date: ${splitdate[0]}  `}</small>
            <a style={{fontSize:"0.8em", marginLeft:"5px"}} target="_blank" href={this.props.survey.surveyurl}>{this.props.survey.surveyurl}</a>

          </div>
          )
        }
    }


/// support multiple discrete types under a dict

    getDiscreteHero(){

            let bord = "#c8ced3"
            let bg = "#FFFFFF"
            let bdw ="1px"
            let chartwidth = "500px"

            let chartitems = new Set()
            for(let anz of this.state.data['selectpricematrix']){
              for(let idx of Object.keys(anz)){
                if(idx != 'answer'){
                  chartitems.add(idx.toString())
                }
              }
            }
      
            let chartkeys = Array.from(chartitems)

              return(

              <div style={{marginRight:"30px", marginLeft:"15px", width:"95%"}}>


              <div style={{display:"flex", width:"100%", alignItems:"stretch"}}>

              <Card style={{borderColor:bord, backgroundColor:bg, borderWidth:bdw, flexGrow:"1", marginRight:"15px"}}>
              <CardHeader>
                  {`Selection Results:  Most chosen packages`}
              </CardHeader>

              <CardBody>

              <DashPie
                    data={this.state.data['selectedpackagegraph']}
                    chartwidth={chartwidth}
                    colors={'paired'}             
                    />
                    
                </CardBody>
                <CardFooter style={{fontSize:"0.6em"}}>
                  {`This shows the count of selections for each package.  Note that at small sample volumes, some packages may be shown materiallty more/fewer times than others, skewing results.`}
                </CardFooter>
              </Card>


              <Card style={{borderColor:bord, backgroundColor:bg, borderWidth:bdw, flexGrow:"1"}}>
              <CardHeader>
                  {`Selection Results:  Least chosen packages`}
              </CardHeader>

              <CardBody>

              <DashPie
                    data={this.state.data['notselectedgraph']}
                    chartwidth={chartwidth}
                    colors={'paired'}            
                    />
                    
                </CardBody>
                <CardFooter style={{fontSize:"0.6em"}}>
                  {`This shows the count of shown but not selected for each package.  Note that at small sample volumes, some packages may be shown materiallty more/fewer times than others, skewing results.`}
                </CardFooter>
              </Card>

              </div>

              <div style={{display:"flex", width:"100%", alignItems:"stretch"}}>
              
              <Card style={{borderColor:bord, backgroundColor:bg, borderWidth:bdw, flexGrow:"1", marginRight:"15px"}}>
              <CardHeader>
                  {`Selection Frequency:  Most likely to be chosen`}
              </CardHeader>

              <CardBody>

              <DashBar
                data={this.state.data['frequencygraph']}
                groupmode={"grouped"}
                chartkeys={['responses']}
                chartindex={'answer'}
                chartwidth={chartwidth}            
                />
                </CardBody>
                <CardFooter style={{fontSize:"0.6em"}}>
                  {`This shows the percent of time that a package was selected each time it was shown to a respondent.`}
                </CardFooter>
              </Card>

              <Card style={{borderColor:bord, backgroundColor:bg, borderWidth:bdw, flexGrow:"1"}}>
              <CardHeader>
                  {`Price Adjusted Selections:  Price x Volume Effects`}
              </CardHeader>

              <CardBody>

              <DashBar
                data={this.state.data['priceadjustedgraph']}
                groupmode={"grouped"}
                chartkeys={['responses']}
                chartindex={'answer'}
                chartwidth={chartwidth}            
                />
                </CardBody>
                <CardFooter style={{fontSize:"0.6em"}}>
                  {`This shows the distribution of selection x price calibrating the effect of high volume/low price vs low vol/high price.`}
                </CardFooter>
              </Card>
              
              </div>

              <div style={{display:"flex", width:"100%", alignItems:"stretch"}}>


              <Card style={{borderColor:bord, backgroundColor:bg, borderWidth:bdw, marginRight:"15px", flexGrow:"1" }}>

              <CardHeader>
                  {`Price Sensitivy of Packages: Choices by Price Level` }
              </CardHeader>
                <CardBody>
                <DashHeatmap
                      data={this.state.data['selectpricematrix']}
                      groupmode={"grouped"}
                      chartkeys={chartkeys}
                      chartindex={'answer'}  
                      chartwidth={chartwidth}        
                  />

                  </CardBody>
                  <CardFooter style={{fontSize:"0.6em"}}>
                  {`This shows the aggregate number of choices respondents made for each package at each tested price level.`}
                  </CardFooter>
                  
                </Card>

                <Card style={{borderColor:bord, backgroundColor:bg, borderWidth:bdw, flexGrow:"1"}}>
              <CardHeader>
                  {`Average Selected Price`}
              </CardHeader>

              <CardBody>

              <DashBar
                data={this.state.data['averagepriceselected']}
                groupmode={"grouped"}
                chartkeys={['responses']}
                chartindex={'answer'}
                chartwidth={chartwidth}            
                />
                </CardBody>
                <CardFooter style={{fontSize:"0.6em"}}>
                  {`This shows the average price point when the package was the selected alternative.`}
                </CardFooter>
              </Card>

              </div>

              <div style={{marginLeft:"15px", marginRight:"15px", display:"flex", width:"100%", alignItems:"stretch"}}>

                              
              <Card style={{borderColor:bord, backgroundColor:bg, borderWidth:bdw, flexGrow:"1", marginRight:"15px"}}>

              <CardHeader>
                  {`Selected Price Ranges:  Choices by Price`}
              </CardHeader>
                <CardBody>
              <DashScatter
                        data={this.state.data['priceselectgraph']}
                        legend="responses from low to high"
                        axis="selected price"
                        chartwidth={chartwidth}         
                    />
                </CardBody>
                <CardFooter style={{fontSize:"0.6em"}}>
                {`This shows the selections respondents made based on the shown pricepoint, ordered from lowest selected price to highest price.`}
                </CardFooter>
                
              </Card>
              
              <Card style={{borderColor:bord, backgroundColor:bg, borderWidth:bdw, flexGrow:"1"}}>

              <CardHeader>
                  {`Natural Price Sensitivity:  Choices by Relative Price`}
              </CardHeader>
                <CardBody>
              <DashScatter
                        data={this.state.data['priceindexgraph']}
                        legend="responses from low to high"
                        axis="relative price (1=average)"
                        chartwidth={chartwidth}         
                    />
                </CardBody>
                <CardFooter style={{fontSize:"0.6em"}}>
                {`This shows the selection relative to the prices shown for other options.  Note that packages priced significantly lower/higher than others can skew results.`}
                </CardFooter>
                
              </Card>

              </div>
              </div>
              )

          }

    getMaxdiffHero(){

      let bord = "#c8ced3"
      let bg = "#FFFFFF"
      let bdw ="1px"
      let chartwidth = "500px"

      return(

        <div style={{marginRight:"30px", marginLeft:"15px", width:"95%"}}>


        <div style={{display:"flex", width:"100%", alignItems:"stretch"}}>

          {this.state.data['mdbestselectedpackagegraph'].length !== 0 &&

            <Card style={{borderColor:bord, backgroundColor:bg, borderWidth:bdw, flexGrow:"1", marginRight:"15px"}}>
            <CardHeader>
                {`Selection Results:  Times chosen as Best`}
            </CardHeader>

            <CardBody>

            <DashPie
                  data={this.state.data['mdbestselectedpackagegraph']}
                  chartwidth={chartwidth}
                  colors={'greens'}             
                  />
                  
              </CardBody>
              <CardFooter style={{fontSize:"0.6em"}}>
                {`This shows the allocation of choices selected as Best. `}
                {`Note:  definition of "best" depends on the wording of questions and how buttons are labeled in the survey.`}
              </CardFooter>
            </Card>
          }

      {this.state.data['mdworstselectedpackagegraph'].length !== 0 &&

        <Card style={{borderColor:bord, backgroundColor:bg, borderWidth:bdw, flexGrow:"1"}}>
        <CardHeader>
            {`Selection Results:  Times chosen as Worst`}
        </CardHeader>

        <CardBody>

        <DashPie
              data={this.state.data['mdworstselectedpackagegraph']}
              chartwidth={chartwidth}
              colors={'reds'}            
              />
              
          </CardBody>
          <CardFooter style={{fontSize:"0.6em"}}>
            {`This shows the allocation of choices selected as Worst. `}
            {`Note:  definition of "worst" depends on the wording of questions and how buttons are labeled in the survey.`}
          </CardFooter>
        </Card>
      }

        </div>




        <div style={{display:"flex", width:"100%", alignItems:"stretch"}}>


        
        <Card style={{borderColor:bord, backgroundColor:bg, borderWidth:bdw, flexGrow:"1", marginRight:"15px"}}>
        <CardHeader>
            {`Selection Frequency:  When shown percent of time selected Best and Worst`}
        </CardHeader>

        <CardBody>

        <DashBar
          data={this.state.data['mdfrequencygraph']}
          groupmode={"grouped"}
          chartkeys={['best', 'worst']}
          chartindex={'answer'}
          chartwidth={chartwidth}            
          />
          </CardBody>
          <CardFooter style={{fontSize:"0.6em"}}>
            {`This shows the number of times a package was selected as best or worst. No bars indicates that times selected best or worst selected was zero.`}
            {`Note:  definition of "best" and "worst" depends on the wording of questions and how buttons are labeled in the survey.`}
          </CardFooter>
        </Card>


        {this.state.data['mdpriceselectgraph'][0]['data'] !== [] || this.state.data['mdpriceselectgraph'][1]['data'] !== [] &&
        <Card style={{borderColor:bord, backgroundColor:bg, borderWidth:bdw, flexGrow:"1"}}>
        <CardHeader>
            {`Price Selections:  Best and Worst`}
        </CardHeader>

        <CardBody>
        <DashScatter
                  data={this.state.data['mdpriceselectgraph']}
                  legend="responses from low to high"
                  axis="selected price"
                  chartwidth={chartwidth}         
              />

          </CardBody>
          <CardFooter style={{fontSize:"0.6em"}}>
            {`This shows the distribution of the selected price points for Best and Worst package options. `}
          </CardFooter>
        </Card>
        }

        
        </div>


        </div>
        )

    }

    nonDiscreteGraphs(g){


      let bord = "#c8ced3"
      let bg = "#FFFFFF"
      let bdw ="1px"
      let chartwidth = "400px"

      let chartitems = new Set()
      for(let anz of g){
        for(let idx of Object.keys(anz)){
          if(idx==='null'){idx = "No Answer"}
          if(idx != 'answer'){
            chartitems.add(idx.toString())
          }
        }
      }
      let chartkeys = Array.from(chartitems)

      return(

        <div style={{marginRight:"30px", marginLeft:"15px", width:"95%"}}>
  
  
        <div style={{display:"flex", width:"100%", alignItems:"stretch"}}>
  
        <Card style={{borderColor:bord, backgroundColor:bg, borderWidth:bdw, flexGrow:"1", marginRight:"15px"}}>
        <CardHeader>
            {`Other Choice Questions`}
        </CardHeader>
  
        <CardBody>
  
        <DashBar
          data={g}
          groupmode={"stacked"}
          chartkeys={chartkeys}
          chartindex={'answer'}
          chartwidth={chartwidth}            
          />
          </CardBody>
          <CardFooter style={{fontSize:"0.6em"}}>
            {` No answer will show as null or none.`}
          </CardFooter>
        </Card>
        </div>
        </div>
        )

    }

/// note chart keys are response not responseS like in other graphs

    getModelHero(){

      let bord = "#c8ced3"
      let bg = "#FFFFFF"
      let bdw ="1px"
      let chartwidth = "500px"


        return(

        <div style={{marginRight:"30px", marginLeft:"15px", width:"95%"}}>

      <div style={{display:"flex", width:"100%", alignItems:"stretch"}}>
        
        <Card style={{borderColor:bord, backgroundColor:bg, borderWidth:bdw, flexGrow:"1", marginRight:"15px"}}>
        <CardHeader>
            {`Feature Drivers:  Features most important to predicting what package is chosen`}
        </CardHeader>

        <CardBody>

        <DashBar
          data={this.state.models['overallfeaturegraph']}
          groupmode={"grouped"}
          chartkeys={['response']}
          chartindex={'answer'}
          chartwidth={chartwidth}            
          />
          </CardBody>
          <CardFooter style={{fontSize:"0.6em"}}>
            {`This is a probability measure that indicates it's influence on whether a package is chosen.  Note: please see technical notes regarding interpreting logistic regression model.`}
          </CardFooter>
        </Card>
      
        </div>

        <div style={{display:"flex", width:"100%", alignItems:"stretch"}}>

        <Card style={{borderColor:bord, backgroundColor:bg, borderWidth:bdw, flexGrow:"1", marginRight:"15px"}}>
        <CardHeader>
            {`Cluster Allocations:  Number of Respondents by Segment`}
        </CardHeader>

        <CardBody>

        <DashPie
              data={this.state.models['clustersizegraph']}
              chartwidth={chartwidth}
              colors={'paired'}             
              />
              
          </CardBody>
          <CardFooter style={{fontSize:"0.6em"}}>
            {`Each group exhibits different choice behaviors.  This shows how heterogeneous the respondent population is with respect to their feature and package preferences.  Note: please see technical notes regarding interpreting k-means clustering model.`}
          </CardFooter>
        </Card>



        </div>




        </div>
        )

    }

    getClusterHero(){
      let bord = "#c8ced3"
      let bg = "#FFFFFF"
      let bdw ="1px"
      let chartwidth = "500px"


        return(

      <div style={{marginRight:"30px", marginLeft:"15px", width:"95%"}}>

      <div style={{display:"flex", width:"100%", alignItems:"stretch"}}>

      <Card style={{borderColor:bord, backgroundColor:bg, borderWidth:bdw, flexGrow:"1", marginRight:"15px"}}>
        <CardHeader>
            {`Cluster 0:  Package Selection Frequency`}
        </CardHeader>

        <CardBody>

        <DashPie
              data={this.state.models['clusterfeaturegraphs']['Cluster 0']['selectedpkgs']}
              chartwidth={chartwidth}
              colors={'paired'}             
              />
              
          </CardBody>
          <CardFooter style={{fontSize:"0.6em"}}>
            {`Shows the distribution of selected packages for this Cluster.`}
          </CardFooter>
        </Card>

        
        <Card style={{borderColor:bord, backgroundColor:bg, borderWidth:bdw, flexGrow:"1", marginRight:"15px"}}>
        <CardHeader>
            {`Cluster 0:  Feature Drivers`}
        </CardHeader>

        <CardBody>

        <DashBar
          data={this.state.models['clusterfeaturegraphs']['Cluster 0']['relative']}
          groupmode={"grouped"}
          chartkeys={['response']}
          chartindex={'answer'}
          chartwidth={chartwidth}            
          />
          </CardBody>
          <CardFooter style={{fontSize:"0.6em"}}>
            {`The relative importance of features to determining selection, compared to the overall population.  >1 is more important <1 is less important.`}
          </CardFooter>
        </Card>
      
        </div>

        <div style={{display:"flex", width:"100%", alignItems:"stretch"}}>

        <Card style={{borderColor:bord, backgroundColor:bg, borderWidth:bdw, flexGrow:"1", marginRight:"15px"}}>
          <CardHeader>
              {`Cluster 1:  Package Selection Frequency`}
          </CardHeader>

          <CardBody>

          <DashPie
                data={this.state.models['clusterfeaturegraphs']['Cluster 1']['selectedpkgs']}
                chartwidth={chartwidth}
                colors={'paired'}             
                />
                
            </CardBody>
            <CardFooter style={{fontSize:"0.6em"}}>
              {`Shows the distribution of selected packages for this Cluster.`}
            </CardFooter>
          </Card>

          
          <Card style={{borderColor:bord, backgroundColor:bg, borderWidth:bdw, flexGrow:"1", marginRight:"15px"}}>
          <CardHeader>
              {`Cluster 1:  Feature Drivers`}
          </CardHeader>

          <CardBody>

          <DashBar
            data={this.state.models['clusterfeaturegraphs']['Cluster 1']['relative']}
            groupmode={"grouped"}
            chartkeys={['response']}
            chartindex={'answer'}
            chartwidth={chartwidth}            
            />
            </CardBody>
            <CardFooter style={{fontSize:"0.6em"}}>
              {`The relative importance of features to determining selection, compared to the overall population.  >1 is more important <1 is less important.`}
            </CardFooter>
          </Card>

          </div>

          <div style={{display:"flex", width:"100%", alignItems:"stretch"}}>

          <Card style={{borderColor:bord, backgroundColor:bg, borderWidth:bdw, flexGrow:"1", marginRight:"15px"}}>
            <CardHeader>
                {`Cluster 2:  Package Selection Frequency`}
            </CardHeader>

            <CardBody>

            <DashPie
                  data={this.state.models['clusterfeaturegraphs']['Cluster 2']['selectedpkgs']}
                  chartwidth={chartwidth}
                  colors={'paired'}             
                  />
                  
              </CardBody>
              <CardFooter style={{fontSize:"0.6em"}}>
                {`Shows the distribution of selected packages for this Cluster.`}
              </CardFooter>
            </Card>

            
            <Card style={{borderColor:bord, backgroundColor:bg, borderWidth:bdw, flexGrow:"1", marginRight:"15px"}}>
            <CardHeader>
                {`Cluster 2:  Feature Drivers`}
            </CardHeader>

            <CardBody>

            <DashBar
              data={this.state.models['clusterfeaturegraphs']['Cluster 2']['relative']}
              groupmode={"grouped"}
              chartkeys={['response']}
              chartindex={'answer'}
              chartwidth={chartwidth}            
              />
              </CardBody>
              <CardFooter style={{fontSize:"0.6em"}}>
                {`The relative importance of features to determining selection, compared to the overall population.  >1 is more important <1 is less important.`}
              </CardFooter>
            </Card>

            </div>


            <div style={{display:"flex", width:"100%", alignItems:"stretch"}}>

            <Card style={{borderColor:bord, backgroundColor:bg, borderWidth:bdw, flexGrow:"1", marginRight:"15px"}}>
              <CardHeader>
                  {`Cluster 3:  Package Selection Frequency`}
              </CardHeader>

              <CardBody>

              <DashPie
                    data={this.state.models['clusterfeaturegraphs']['Cluster 3']['selectedpkgs']}
                    chartwidth={chartwidth}
                    colors={'paired'}             
                    />
                    
                </CardBody>
                <CardFooter style={{fontSize:"0.6em"}}>
                  {`Shows the distribution of selected packages for this Cluster.`}
                </CardFooter>
              </Card>

              
              <Card style={{borderColor:bord, backgroundColor:bg, borderWidth:bdw, flexGrow:"1", marginRight:"15px"}}>
              <CardHeader>
                  {`Cluster 3:  Feature Drivers`}
              </CardHeader>

              <CardBody>

              <DashBar
                data={this.state.models['clusterfeaturegraphs']['Cluster 3']['relative']}
                groupmode={"grouped"}
                chartkeys={['response']}
                chartindex={'answer'}
                chartwidth={chartwidth}            
                />
                </CardBody>
                <CardFooter style={{fontSize:"0.6em"}}>
                  {`The relative importance of features to determining selection, compared to the overall population.  >1 is more important <1 is less important.`}
                </CardFooter>
              </Card>

              </div>


          </div>


        )
    }


    render() {
        return (
          <div style={{marginTop:"30px"}}>

            <hr/> 

            {this.props.showcards &&
            <div>

                {this.getHeadline()}

                <Row>
                    <Col sm="6" md="2">
                      <SolidInfoCard  color="dark" header={this.props.numberresponses} value="100" invert >Individual Respondents</SolidInfoCard>
                    </Col>

                    <Col sm="6" md="2">
                    {this.state.data['bestfreqpkg']!==null &&
                      <SolidInfoCardText  color="primary" pname={this.state.data['bestfreqpkg']} header={this.state.data.pkgnames[this.state.data['bestfreqpkg']]} value="100" fontsize="1.5em" invert >Strongest Demand</SolidInfoCardText>
                    }
                    {this.state.data['bestfreqpkg']===null &&
                      <SolidInfoCardText  color="secondary" header={this.state.data['bestfreqpkg']} value="100" fontsize="3em" invert >Strongest Demand</SolidInfoCardText>
                    }
                    </Col>

                    <Col sm="6" md="2">
                    {this.state.data['bestpkg']!==null &&
                      <SolidInfoCardText color="primary" pname={this.state.data['bestpkg']} header={this.state.data.pkgnames[this.state.data['bestpkg']]} value="100" fontsize="1.5em" invert >Strongest Revenue</SolidInfoCardText>
                    }
                    {this.state.data['bestpkg']===null &&
                      <SolidInfoCardText color="secondary" header={this.state.data['bestpkg']} value="100" fontsize="3em" invert >Strongest Revenue</SolidInfoCardText>
                    }
                    </Col>
                    
                    
                    <Col sm="6" md="2">
                    {this.state.data['mostdiff']!==null &&
                      <SolidInfoCardText  color="primary" pname={this.state.data['mostdiff']} header={this.state.data.pkgnames[this.state.data['mostdiff']]} value="100" fontsize="1.5em" invert  >Best Net Diff</SolidInfoCardText>
                    }
                    {this.state.data['mostdiff']===null &&
                      <SolidInfoCardText  color="secondary" header={this.state.data['mostdiff']} value="100" fontsize="3em" invert  >Best Net Diff</SolidInfoCardText>
                    }
                    
                    </Col>

                    <Col sm="6" md="2">
                    {this.state.data['mostbest']!==null &&
                      <SolidInfoCardText color="primary" pname={this.state.data['mostbest']} header={this.state.data.pkgnames[this.state.data['mostbest']]} value="100" fontsize="1.5em" invert >Most Frequent Best</SolidInfoCardText>
                    }
                    {this.state.data['mostbest']===null &&
                      <SolidInfoCardText color="secondary" header={this.state.data['mostbest']} value="100" fontsize="3em" invert >Most Frequent Best</SolidInfoCardText>
                    }
                      </Col>
                    
                    <Col sm="6" md="2">
                    {this.state.data['mostworst']!=null &&
                      <SolidInfoCardText color="info" pname={this.state.data['mostworst']} header={this.state.data.pkgnames[this.state.data['mostworst']]}  value="100" fontsize="1.5em" invert >Most Frequent Worst</SolidInfoCardText>
                    }
                    {this.state.data['mostworst']===null &&
                      <SolidInfoCardText color="secondary" header={this.state.data['mostworst']} value="100" fontsize="3em" invert >Most Frequent Worst</SolidInfoCardText>
                    }
                      </Col>
                    
                    <Col sm="6" md="2">
                    {this.state.data['priceindex'] !== 0 &&
                      <SolidInfoCardText  color="dark" header={this.state.data['priceindex']} value="100" fontsize="4em"  >Price Sensitivity Index</SolidInfoCardText>
                    }
                    {this.state.data['priceindex'] === 0 &&
                      <SolidInfoCardText  color="secondary" header={this.state.data['priceindex']} value="100" fontsize="4em"  invert >Price Sensitivity Index</SolidInfoCardText>
                    }
                      </Col>

                    <Col sm="6" md="2">
                    {this.props.avgprice !== 0 &&
                      <SolidInfoCardText  color="dark" header={this.props.avgprice} value="100" fontsize="4em" >Avg Selected Price</SolidInfoCardText>
                    }
                    {this.props.avgprice === 0 &&
                      <SolidInfoCardText  color="secondary" header={this.props.avgprice} value="100" fontsize="4em" invert >Avg Selected Price</SolidInfoCardText>
                    }
                    </Col>
                    
                    <Col sm="6" md="2">
                    {this.state.data['percentcheapest'] !== 0 &&
                      <SolidInfoCardText  color="dark" header={this.state.data['percentcheapest']} value="100" fontsize="4em" >Percent Selected Cheapest</SolidInfoCardText>
                    }
                    {this.state.data['percentcheapest'] === 0 &&
                      <SolidInfoCardText  color="secondary" header={this.state.data['percentcheapest']} value="100" fontsize="4em" invert >Percent Selected Cheapest</SolidInfoCardText>
                    }
                      </Col>
                    
                    <Col sm="6" md="2">
                    {this.state.data['bestchosenpriceavg'] !== 0 &&
                      <SolidInfoCardText  color="dark" header={this.state.data['bestchosenpriceavg']} value="100" fontsize="4em" >Avg Best Selected Price</SolidInfoCardText>
                    }
                    {this.state.data['bestchosenpriceavg'] === 0 &&
                      <SolidInfoCardText  color="secondary" header={this.state.data['bestchosenpriceavg']} value="100" fontsize="4em" invert >Avg Best Selected Price</SolidInfoCardText>
                    }
                      </Col>
                    
                    <Col sm="6" md="2">
                    {this.state.data['bestpriceindexavg'] !== 0 &&
                      <SolidInfoCardText  color="dark" header={this.state.data['bestpriceindexavg']} value="100" fontsize="4em"  >Best Price Sensitivity</SolidInfoCardText>
                    }
                    {this.state.data['bestpriceindexavg'] === 0 &&
                      <SolidInfoCardText  color="secondary" header={this.state.data['bestpriceindexavg']} value="100" fontsize="4em" invert >Best Price Sensitivity</SolidInfoCardText>
                    }
                      </Col>
                    <Col>
                    <div style={{height:"80%", display:"flex", justifyContent:"flex-end", alignItems:"flex-end", marginRight:"30px"}}>
                    <small className="text-muted text-uppercase font-weight-bold" style={{marginRight:"5px"}}>More</small>    
                      <Toggle
                      id={"showgraphs"}
                      value={"ok"}
                      checked= {this.state.showgraphs}
                      icons={{unchecked: null}}
                      onChange = {() => this.handleToggleChange()}
                  />
                  </div>
                  </Col>
                  

                  </Row>
                  
            </div>
            }

              {this.state.showgraphs &&

              <div>

                  <Row>

                    {this.props.graphstoshow.includes('discrete') &&
                    this.getDiscreteHero()
                    }

                  </Row>

                  <hr/>

                  <Row>

                    {this.props.graphstoshow.includes('maxdiff') &&
                    this.getMaxdiffHero()
                    }

                  </Row>

                  <hr/>

                  <Row>

                  {this.props.graphstoshow.includes('features') &&
                  this.getModelHero()
                  }

                  {this.props.graphstoshow.includes('cluster') &&
                  this.getClusterHero()
                  }

                  </Row>

                  <Row>

                  {typeof this.state.data['nondiscretegraphlist'] !== 'undefined' && 
        
                  this.state.data['nondiscretegraphlist'].map((graph, key=graph.type) => (this.nonDiscreteGraphs(graph.data)))

                  }

                  </Row>

              </div>
              
              }

          </div>
    
        );
      }
    }
    
import React, { Component } from "react";
import { Row, CardDeck} from 'reactstrap';
import FormCardEditor from '../survey/FormCardEditor'


/// SurveyForm - component that creates any number of cards used to capture basic input
/// content from user.  Text, area, number, email, date, slider, range, toggle, radio, check
/// Not much logic other than validation required
/// FormCard is used to assemble each of the cards

/// gets viewport width
const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

export default class SurveyFormEditor extends Component {
  constructor(props) {
    super(props);
    let mwidth = this.props.forms.length
    mwidth = 1/mwidth
    mwidth = mwidth * 100
    mwidth = mwidth - 3
    mwidth = mwidth.toFixed()
    mwidth = mwidth + "%"
    if(vw < 600){
      mwidth = "100%"
    }
    this.changeSelection = this.changeSelection.bind(this)
    this.toggleRadioCheck = this.toggleRadioCheck.bind(this)
    let validfields = {}
    let validtype = {}
    let initialselections = {}
    let initializefalse = ['checkbox', 'radio']
    let initialforms = this.props.forms
    if (typeof this.props.savedpages != "undefined") {
        if(this.props.savedpages===true){
            initialselections = this.props.savedresponses['selectvalue']
            initialforms = this.props.savedresponses['forms']
        }
        } else {

                for (let i of this.props.forms){
                    for(let j of i['fields']){
                        let radiocheck = {}
                        let fieldid = j['id']
                        initialselections[fieldid] = null
                        validfields[fieldid]= false
                        validtype[fieldid]= j['validationtype']
                        if(j['validationtype']==='none'){validfields[fieldid]= true}
                        if(j['type']==='toggle'){initialselections[fieldid] = false}
                        if(j['type']==='slider'){initialselections[fieldid] = null}
                        if(j['type']==='range'){initialselections[fieldid] = j['rangedefault']}
                        if(initializefalse.includes(j['type']))
                            {
                            for (let s of j['selectoptions']){
                                radiocheck[s['value']]=false
                            }
                            initialselections[fieldid]=radiocheck
                            }
                }
                }
                }
    this.state = {
        selectvalue: initialselections,
        forms: initialforms,
        isvalid: false,
        validfields: validfields,
        validtype: validtype,
        mwidth: mwidth,
      };
    }

   
  async componentDidMount() {
    this.validateAllFields()
  }

  /// save state on exit, so if you go back and review this page the content is available to see
  componentWillUnmount(){
      this.props.stateChangePassThru(this.props.questionId, this.state)
  }

  /// looks to see if any of the fields have a false validation setting.  called on each input change
  validateAllFields(){
    let checkitems = Object.values(this.state.validfields)
    let validcheck = checkitems.includes(false)
    this.setState({isvalid: !validcheck}, () => {this.props.handlePageValid(this.state.isvalid)})
      }

    /// updates users input for everything except radio/check.  i is id;  e is the value
    changeSelection(i, e){
        let fieldvalid = false
        if(this.state.validtype[i] !== "none"){
            if(e != null && e != false){fieldvalid = true}
        } else {fieldvalid =true}

        this.setState(prevState => ({selectvalue: {...prevState.selectvalue, [i]: e}}), )
        this.setState(prevState => ({validfields: {...prevState.validfields, [i]: fieldvalid}}), () => {this.validateAllFields()} )
    }

    
    /// looks inside the dict containing the various radio/check options to see if any are true
    validateRadioCheck(i){
      let validcheck = true
      if(this.state.validtype[i] !== "none"){
            const entries = Object.values(this.state.selectvalue[i])
            const entriesvalid = entries.includes(true)
            if (entriesvalid ===false){validcheck = false}
            }
      this.setState(prevState => ({validfields: {...prevState.validfields, [i]: validcheck}}), () => {this.validateAllFields()} )
      }
    
    /// updates the radio/check option bool toggle.  i is id;  e is the value;  t is type
    toggleRadioCheck(i, e, t){

        if(t==='radio'){
            let newvals = this.state.selectvalue[i]
            for (let p in newvals)
                {
                newvals[p]=false
                }
            newvals[e]=true

            this.setState(prevState => ({
                selectvalue: {
                ...prevState.selectvalue,          
                [i]: newvals
                }
                }), () => {this.validateRadioCheck(i)}
                )
        } else {

            this.setState(prevState => ({
                selectvalue: {
                ...prevState.selectvalue,          
                [i]: {                     
                ...prevState.selectvalue[i], 
                [e]: !this.state.selectvalue[i][e]         
                }
                }
                }), () => {this.validateRadioCheck(i)}
                )
            }
    }

     /// calls the formcard component to assemble the elements for each card for the map of forms
     /// each form is a card
    getFormCard(form){
      return(
        <FormCardEditor
          key={form['id']}
          form={form}
          maxcardwidth={this.state.mwidth}
          surveyprops={this.props.surveyprops}
          selectvalue={this.state.selectvalue}
          questionprops={this.props.questionprops}
          questionId={this.props.questionId}
          onSelectionChange={this.changeSelection}
          onRadioCheckToggle={this.toggleRadioCheck}
          />
      )
    }



   render(){


    return(

      <div className = "survey-row-of-cards">

        {this.props.forms.map((form, key=form.id)=>this.getFormCard(form))}

      </div>

    );
  }
}
import React, { Component } from "react";
import {  Redirect } from "react-router-dom";
import { API } from "aws-amplify";
import { Button, Card, CardBody, CardGroup, CardHeader, Col, Container, Row, Jumbotron } from 'reactstrap';
import Loader from "react-loader-spinner";
import SolidInfoCard from '../components/SolidInfoCard'
import SurveyInfoCard from '../components/SurveyInfoCard'
import SurveyAddCard from '../components/SurveyAddCard'
import DashCalendar from '../components/DashCalendar'

function compareValues(key, order = 'asc') {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }

    const varA = (typeof a[key] === 'string')
      ? a[key].toUpperCase() : a[key];
    const varB = (typeof b[key] === 'string')
      ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return (
      (order === 'desc') ? (comparison * -1) : comparison
    );
  };
}

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.routeAction = this.routeAction.bind(this)
    this.state = {
      isLoading: true,
      assets:0,
      features:0,
      packages:0,
      designs:0,
      questions:0,
      audiences:0,
      surveyinfo:[{}],
      surveyaction:null,
      activeSurvey:null,
      showcalendar: false,
      totalresponses:0,
      takentoday:0,
      takenthirty:0,
      completed:0,
    };
  }
  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    if (this.props.userId === null){
      try{
        let accountinfo = await API.get("users", "/users")
        this.props.userHasEmail(accountinfo.email.S)
        this.props.userHasId(accountinfo.userId.S)
        this.props.userHasRole(accountinfo.role.S)
        this.props.userHasLicense(accountinfo.subscriptionstatus.S)
        this.props.userHasProduct(accountinfo.product.S)
        this.props.userHasOrg(accountinfo.orgId.S)
      } catch (e) {
        alert('There was problem loading the account', e);
      }
    }

    if (this.props.orgId !== null){


      let querystring = {}
      let orgquery = {}
      orgquery['orgId']=this.props.orgId
      orgquery['querytype']='ALL'
      querystring['queryStringParameters'] = orgquery
      try{
      
      let responses = await API.get("home", "/home", querystring)

      let surveyinfo = JSON.parse(responses['survey'])
      let assets = responses['assets']
      let designs = responses['designs']
      let features = responses['features']
      let packages = responses['packages']
      let questions = responses['questions']
      let summarystats = responses['summaryitem']
      let takentoday = JSON.parse(summarystats.takentoday)
      takentoday = takentoday["Yes"]
      let takenthirty = JSON.parse(summarystats.takenthirty)
      takenthirty = takenthirty["Yes"]
      if (takentoday === undefined){takentoday = 0}
      if (takenthirty === undefined){takenthirty = 0}

      this.setState({ isLoading: false, 
        assets:assets, 
        designs:designs, 
        features:features, 
        packages:packages, 
        questions:questions, 
        surveyinfo:surveyinfo, 
        summarystats:summarystats, 
        totalresponses:summarystats.totalresponses,
        completed:summarystats.completed,
        takentoday:takentoday,
        takenthirty:takenthirty,
      });
      } catch (e) {
        alert('There was problem loading the home content', e);
      }
    }

  }

  componentDidUpdate(prevProps, prevState){
    if(this.props.activeSurvey !==prevProps.activeSurvey){
      if(this.props.activeSurvey !== null){
          if(["DRAFT", "TEST", "PENDING", "TEST PENDING"].includes(this.state.surveyaction)){
            this.props.history.push("/surveys/editor")
          }
          if(["LIVE", "COMPLETE"].includes(this.state.surveyaction)){
            this.props.history.push("/surveys/responses")
          }
        } else {
          if(this.state.surveyaction === "New"){
            this.props.history.push("/surveys/editor")
          }
        }
      }
    }


  
  showLogin() {
    return (
      <div className="app flex-row align-items-top">
      <Redirect from="/" to="/Login" />
      <Redirect from="/Home" to="/Login" />
      </div>
    );
  }

  showLoader(){
    return(
    <div className='survey-loader'>
        <Loader type="Puff" color="#20a8d8" height={100} width={100} timeout={3000}/>
    </div>
    )
  }

  getSurveyCards(){

    let sortedsurveys = JSON.parse(JSON.stringify(this.state.surveyinfo))

    if(this.state.surveyinfo !== [{}]){

      for(let s in sortedsurveys){
        let d = sortedsurveys[s]['localsavetime']['S']
        let dt = new Date(d)
        let currentdate = Date.now()
        let difference = currentdate - dt
        let daysDiff = difference/(1000 * 60 * 60  * 24)
        let datemsg = (Math.floor(daysDiff)).toString() + ' days ago'
        if(daysDiff<2){datemsg = "yesterday"}
        if(daysDiff<1){datemsg = "today"}
        if(daysDiff<0.04){datemsg = "just now"}
        sortedsurveys[s]['datemsg']=datemsg
        sortedsurveys[s]['daysDiff']=daysDiff
      }

      sortedsurveys = sortedsurveys.sort(compareValues('daysDiff'))

      if(sortedsurveys.length>=6){
        sortedsurveys = sortedsurveys.slice(0,5)
      }

  
      return(
        <div style={{marginTop:"0px", width:"100%"}}>
        <small className="text-muted text-uppercase font-weight-light" style={{marginBottom:"5px"}}>{"Recent Surveys"}</small>

        <Row>

        {sortedsurveys.map((survey)=>this.renderSurveyCard(survey))}

        <Col sm="6" md="2">
        <SurveyAddCard
          color="dark"
          invert
          value="100"
          routeAction={this.routeAction}
          />
        </Col>
        </Row>
        </div>
        )

    }
  }
  
  routeAction(survey, stat){
    if(survey !== null)

      {this.setState({activeSurvey: survey['surveyId'], surveyaction:stat}, ()=>this.props.userHasActiveSurvey(survey) )
      
    } else {
      
      if (this.props.activeSurvey !== null)

        {this.setState({activeSurvey: null, surveyaction:stat}, ()=>this.props.userHasActiveSurvey(null) )
      
      } else {
        
        this.props.history.push("surveys/editor")}
    }
  }


  renderSurveyCard(s){
    return(
    <Col sm="6" md="2">
    <SurveyInfoCard
      invert
      color="dark" 
      value="100"
      header={s['surveyId']['S']}
      status={s['status']['S']}
      title={s['surveytitle']['S']}
      update={s['datemsg']}
      userhasactivesurvey={this.props.userHasActiveSurvey}
      routeAction={this.routeAction}
      />
  </Col>
    )
  }

  toggleCalendar(){
    this.setState({showcalendar:!this.state.showcalendar})
  }

  getResponseCards(){

    return(
      <div>
      <hr/>
      <small className="text-muted text-uppercase font-weight-light" style={{marginBottom:"5px"}}>{"Responses"}</small>
      <Row>
        <Col sm="6" md="2">
        <SolidInfoCard  color="primary" header={this.state.totalresponses} value="100" linkto="/dashboard" >All</SolidInfoCard>
      </Col>
      <Col sm="6" md="2">
        <SolidInfoCard color="red" header={this.state.completed} value="100" linkto="/dashboard" >Completes</SolidInfoCard>
      </Col>
      <Col sm="6" md="2">
        <SolidInfoCard color="purple" header={this.state.takenthirty} value="100" linkto="/dashboard" >Past 30 days</SolidInfoCard>
      </Col>
      <Col sm="6" md="2" >
        <SolidInfoCard color="green" header={this.state.takentoday} value="100" linkto="/dashboard" >Today</SolidInfoCard>
      </Col>
      <Col sm="6" md="2">
      <div style={{alignSelf:"flex-end", textAlign:"left", fontSize:"1.2em", fontWeight:"200", color:"gray", cursor:"pointer", marginLeft:"5px"}} >
      <i className="icon-calendar" onClick={()=>this.toggleCalendar()}></i>
      </div>
      </Col>

      </Row>
      </div>
    )

  }

  getCalendar(){
    return(
      <div>
      <hr/>
      <small className="text-muted text-uppercase font-weight-light" style={{marginBottom:"5px"}}>{"Responses by day"}</small>
      <Row>
      <div style={{alignItems:"center", justifyContent:"center"}}>

      <DashCalendar 
        data={this.state.summarystats.alldates}
        />
        </div>
        </Row>
        </div>
    )
  }

  getContentSummary(){
    return(
      <div>
        <hr/>
        <small className="text-muted text-uppercase font-weight-light" style={{marginBottom:"5px"}}>{"Content"}</small>
      <Row>
          <Col sm="6" md="2">
          <SolidInfoCard icon="icon-grid" color="primary" header={this.state.packages} value="100" linkto="surveys/packages" invert>Packages</SolidInfoCard>
        </Col>
        <Col sm="6" md="2">
          <SolidInfoCard icon="icon-layers" color="danger" header={this.state.features} value="100" linkto="surveys/features" invert>Features</SolidInfoCard>
        </Col>
        <Col sm="6" md="2">
          <SolidInfoCard icon="icon-picture" color="purple" header={this.state.designs} value="100" linkto="surveys/designs" invert>Designs</SolidInfoCard>
        </Col>
        <Col sm="6" md="2">
          <SolidInfoCard icon="icon-book-open" color="green" header={this.state.questions} value="100" linkto="surveys/questions" invert>Questions</SolidInfoCard>
        </Col>
        <Col sm="6" md="2">
          <SolidInfoCard icon="icon-folder-alt" color="info" header={this.state.assets} value="100"linkto="surveys/assets" invert>Assets</SolidInfoCard>
        </Col>
        <Col sm="6" md="2">
          <SolidInfoCard icon="icon-cursor" color="orange" header={this.state.audiences} value="0" linkto="/audience" invert>Audiences</SolidInfoCard>
        </Col>
        </Row>
        </div>
    )
  }


  showHome(){
    return(

      <div style={{marginRight:"30px", marginLeft:"15px", width:"95%"}}>



      {!this.state.isLoading && this.getSurveyCards()}


      {!this.state.isLoading && this.getResponseCards()}

      {this.state.showcalendar && this.getCalendar()}

      {!this.state.isLoading && this.getContentSummary()}


    </div>

    )
  }
 

  render() {
    return (
      <div style ={{marginTop:"25px"}}>

      <Jumbotron style={{marginBottom:"30px", marginTop:"0px", padding:"0px"}}>
          <h1>Pricekit Home</h1>
          <h5 style={{fontWeight:"200"}}>Summary of your work</h5> 
          <hr/>  
      </Jumbotron>

      {this.state.isLoading && this.showLoader()}
      {this.props.isAuthenticated ? this.showHome() : this.showLogin()}
      </div>

    );
  }
}

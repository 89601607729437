import React, { Component } from "react";
import { Card, CardBody, CardHeader, CardFooter, CustomInput, Input, Form, FormGroup, Col, Nav, NavItem, NavLink} from 'reactstrap';
import FeatureListEditor from '../survey/FeatureListEditor';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable'
import GifPlayer from 'react-gif-player';
import ReactPlayer from 'react-player';
import Slider from 'rc-slider';
import Tooltip from 'rc-tooltip';
import 'rc-slider/assets/index.css';
import Toggle from 'react-toggle'
import  'react-toggle/style.css'
import './RenderChoiceContent.scss';
import Image from '../components/Image'


/// ChoiceCard - assembles each card for SurveyCard
/// each card has the same select type function
/// there are various functions to handle input/events since the various
/// components used pass slightly different info
/// typically use onChange, but onBlur needed to handle numbers properly
/// this HAS STYLING which is needed to change the background color of selected and max-diff



/// styling for react-select
const customStyles = (isValid) => ({
    control: (styles: any) => ({
      ...styles,
      padding: 5,
      fontSize: "smaller",
      ...(!isValid && { borderColor: 'red' }),
    }),
  });

    /// styling for react-select
    const customStyles2 = (isValid) => ({
      option: (provided, state) => ({
        ...provided,
        fontSize: '0.9em',
        borderBottom: '1px gray',
        backgroundColor: state.isSelected ? '#2f353a' : 'white',
        color: state.isSelected ? '#20a8d8' : '#2f353a',
        padding: 10,
        overflow: 'visible',
      }),
        control: (styles: any, state:any) => ({
          ...styles,
          color:"#2f353a",
          backgroundColor:"#f0f3f5",
          padding: 5,
          fontSize: "smaller",
          overflow: "visible",
          ...(!isValid && { borderColor: 'red' }),
        }),
      });

  const { createSliderWithTooltip } = Slider;
  const Range = createSliderWithTooltip(Slider.Range);
  const { Handle } = Slider;
  
  const handle = props => {
    const { value, dragging, index, ...restProps } = props;
    return (
      <Tooltip
        prefixCls="rc-slider-tooltip"
        overlay={value}
        visible={dragging}
        placement="top"
        key={index}
      >
        <Handle value={value} {...restProps} />
      </Tooltip>
    );
  };
  

/// for randomizing elements in a list, like options and features
function shuffle(array) {
  var currentIndex = array.length, temporaryValue, randomIndex;
  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }
  return array;
}


export default class RenderChoiceContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
        isValid: false,
      };
    }
   
  async componentDidMount(){ 
  }

  /// callback to send option id (i) and the value of the event(e) back to parent component
  handleSelectionChange(i, e){
      this.props.onSelectionChange(i, e)
  }

  /// callback to send option id (i) to display full screen image for the lightbox component
  handleImageToggle(){
    this.props.onImageToggle(this.props.option.id)
  }

  /// callback :  Used by DISCRETE and MAXDIFF
  handleMaxDiffChange(e){
    this.props.onMaxDiffChange(this.props.option.id, e)
}

  /// calls Selection Change callback:  Used by SELECT and MULTI AND SLIDER and RANGE
  handleSelectChange = (selectvalue:any, action: any) => {
      this.handleSelectionChange(this.props.option.id, selectvalue)
  };

  /// calls Selection Change
  handleToggleSelect(e){
    this.handleSelectionChange(this.props.option.id, e)
  }




  /// callback:  used by ALLOCATION
  handleAllocationChange(id, e){


///    if(e>this.props.selectvalue[this.props.option.id]){ 

///    if( e > (this.props.remaining + this.props.selectvalue[this.props.option.id])){
///      e = this.props.remaining + this.props.selectvalue[this.props.option.id]
///    } 
///    }
    this.props.onAllocationChange(id, e)
  }





  /// calls Selection Change callback:  Used by TEXT-FIELD and TEXT-AREA
  handleFieldChange = (event) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    this.handleSelectionChange(this.props.option.id, value)
  }

   /// calls Selection Change callback:  Used by TOGGLE
  handleToggleChange(){
    this.props.onSelectionChange(this.props.option.id, !this.props.selectvalue[this.props.option.id])
}

  /// callback:  used by RADIO and CHECKBOX
  handleRadioCheckChange = (event) => {
    const target = event.target;
    if(target != null){
      let type = target.type
      let value = target.value
      let name = target.name
      this.props.onRadioCheckToggle(this.props.option.id, name, this.props.questionprops.selectiontype)
    }
  }

  /// function to generate the individual radio or checkbox items. i is id;  t is type
  getSelectItems(i, t){

    if(t==="checkbox"){
        return(
        <CustomInput type="checkbox" id={this.props.option.id+i['value']} label={i['label']} name={i['label']}></CustomInput>
  
        )}
        if(t==="radio"){
          return(
          <CustomInput type="radio" id={this.props.option.id+i['value']} label={i['label']} name={i['label']}></CustomInput>
        )}


  }

  getActiveCardStyle(activeId){
    if (activeId===this.props.currentcard)
     {return '#20a8d8'}
    else {return '#c8ced3'}
}

    activeItem(action, id){
        this.props.activeCard(id)
        let item = {}
        item['id'] = id
        item['action'] = action
        item['modalsize']="lg"
        this.props.activeQuestion(item)
    }


  getPackage = (selectvalue, action) => {
    if(selectvalue['value'] === "Create New Package"){
      this.activeItem("ChoiceCard", this.props.option.id)
    } else {
    let card={}
    card['fromcard']=this.props.option.id
    card['tocard']=selectvalue['value']
    this.props.swapCard(card)
    }
   }

  getPackageList(){
    if(this.props.option.optionheader==="" && this.props.option.features.length===0 && this.props.option.price === 0 && this.props.option.img==="" && this.props.option.video===""){
      return(
      <div style={{flexDirection:"column", alignItems:"flex-end", marginBottom:"15px"}}>
      <p style={{fontSize:"0.8em", marginBottom:"3px"}}>Add or Create Package</p>
          <Select
          defaultValue={null}
          onChange={this.getPackage}
          options={this.props.packagelist}
          id={this.props.option.id}
          name={"packages"}
          styles={customStyles2(true)}
          />

      </div>
      
      )
    }
  }

  getMode(i){
    if(i===undefined){ return "fit"}
    if(i===true){return "fit"}
    if(i===false){return "fill"}
  }


  /// called from render - this determines which selection component to use in the card footer
  renderSelectType(){

    if (this.props.questionprops.selectiontype === "select"){
         return(
            <Select
            defaultValue={this.props.selectvalue[this.props.option.id]}
            onChange={this.handleSelectChange}
            options={this.props.questionprops.selectoptions}
            styles={customStyles2(true)} 
          />
        )
        }
    if (this.props.questionprops.selectiontype === "selectcreatable"){
        return(
            <CreatableSelect
            defaultValue={this.props.selectvalue[this.props.option.id]}
            onChange={this.handleSelectChange}
            options={this.props.questionprops.selectoptions}
            styles={customStyles2(true)}  
            />
        )
        }
    if (this.props.questionprops.selectiontype === "multiselect"){
        return(
            <Select
            defaultValue={this.props.selectvalue[this.props.option.id]}
            isMulti
            onChange={this.handleSelectChange}
            options={this.props.questionprops.selectoptions}
            styles={customStyles2(true)}  
            />
        )
        }   
    if (this.props.questionprops.selectiontype === "multiselectcreatable"){
        return(
            <CreatableSelect
            isMulti
            defaultValue={this.props.selectvalue[this.props.option.id]}
            onChange={this.handleSelectChange}
            options={this.props.questionprops.selectoptions}
            styles={customStyles2(true)}  
            />
        )
        }
    if (this.props.questionprops.selectiontype === "discrete"){
        return(
            <button className="btn btn-pill btn-lg btn-outline-dark" style={{color: this.props.surveyprops.style.cardfoottextcolor}} onClick={() => this.handleMaxDiffChange("selected")}>{this.props.questionprops.buttonname}</button>
        )
        }
    if (this.props.questionprops.selectiontype === "maxdiff"){
        return(
        <div style={{display: "flex"}}>
        <button className="btn btn-pill btn-lg btn-outline-dark" style={{color: this.props.surveyprops.style.cardfoottextcolor}} onClick={() => this.handleMaxDiffChange("best")}>{this.props.questionprops.bestbuttonname}</button>
        <button className="btn btn-pill btn-lg btn-outline-dark" style={{marginLeft:"auto", color: this.props.surveyprops.style.cardfoottextcolor}} onClick={() => this.handleMaxDiffChange("worst")}>{this.props.questionprops.worstbuttonname}</button>
        </div>
        )
        }
    if (this.props.questionprops.selectiontype === "allocation"){
        return(
          <div style={{display:"flex", marginTop:"15px", marginBottom:"30px"}}>
          <Slider 
          marks={{0:"0", [this.props.remaining + this.props.selectvalue[this.props.option.id]]: <div style={{display:"flex", flexDirection:"column", fontSize:"0.8em"}}><span><i className="icon-arrow-up" style={{color:"#f86c6b", marginTop:"0px"}} ></i></span><p>{`+ ${this.props.remaining}`}</p></div>}}
          min={0}
          max={this.props.validallocationamount}
          step={this.props.questionprops.sliderstep}
          defaultValue={this.props.selectvalue[this.props.option.id]}
          onAfterChange={(e)=>this.handleAllocationChange(this.props.option.id, e)}
          tipFormatter={value => `${value}`}
          handle={handle}
          dotStyle={{borderColor: '#f86c6b'}}
          activeDotStyle={{borderColor: '#c8ced3'}}
          />
          <div style={{width:"30px", marginLeft:"20px", fontSize:"1.5em", fontWeight:"600"}}>
          {this.props.selectvalue[this.props.option.id]}
          </div>
          </div>
        )
        

      }




    if (this.props.questionprops.selectiontype === "toggle"){
      return(
          <Toggle
          id={this.props.option.id}
          value={this.props.option.togglevalue}
          checked= {this.props.selectvalue[this.props.option.id]}
          icons={{unchecked: null}}
          className = {this.props.option.togglestyle}
          onChange = {() => this.handleToggleChange()}
      />
      )
    }
    if (this.props.questionprops.selectiontype === "textfield"){
      return(
        <Input 
        name={this.props.option.id}
        type={"text"} 
        value={this.props.selectvalue[this.props.option.id]}
        onChange={this.handleFieldChange}/>
      )
    }

    if (this.props.questionprops.selectiontype === "textarea"){
    return(
        <Input 
        name={this.props.option.id}
        type={"textarea"} 
        value={this.props.selectvalue[this.props.option.id]}
        onChange={this.handleFieldChange}/>
    )
    }
    if (this.props.questionprops.selectiontype === "checkbox"){
      return(
        <Form>
        <FormGroup inline name={this.props.option.id} onChange={this.handleRadioCheckChange}>
            {this.props.questionprops.selectoptions.map((item, key=item.id)=>this.getSelectItems(item, "checkbox"))}
        </FormGroup>
      </Form>
      )
  }
    if (this.props.questionprops.selectiontype === "radio"){
      return(
        <Form>
        <FormGroup inline name={this.props.option.id} onChange={this.handleRadioCheckChange}>
            {this.props.questionprops.selectoptions.map((item, key=item.id)=>this.getSelectItems(item, "radio"))}
        </FormGroup>
      </Form>
      )
    }

    if (this.props.questionprops.selectiontype === "slider"){
      return(
        <div style={{marginTop:"15px", marginLeft:"15px", marginRight:"15px", marginBottom:"30px"}}>
        <Slider 
        marks={this.props.questionprops.slidermarks}
        min={this.props.questionprops.slidermin}
        max={this.props.questionprops.slidermax}
        step={this.props.questionprops.sliderstep}
        defaultValue={this.props.selectvalue[this.props.option.id]}
        onAfterChange={this.handleSelectChange}
        dotStyle={{borderColor: '#c8ced3'}}
        activeDotStyle={{borderColor: '#c8ced3'}}
        />
        </div>
      )
    }
    if (this.props.questionprops.selectiontype === "range"){
      return(
        <div style={{marginTop:"15px", marginLeft:"15px", marginRight:"15px", marginBottom:"30px"}}>
          <Range 
          marks={this.props.questionprops.slidermarks}
          min={this.props.questionprops.slidermin}
          max={this.props.questionprops.slidermax}
          step={this.props.questionprops.sliderstep} 
          defaultValue={this.props.questionprops.rangedefault}
          onAfterChange={this.handleSelectChange}
          />
        </div>
      )
    }


  }

  render(){

    return( 
        
        <div  className="render-choice-content-highlight">

        <Card className="survey-choice-card"  
        style={{
        width:this.props.maxcardwidth,
        height:"100%",
        borderColor: this.getActiveCardStyle(this.props.option.id)}}>
            
          <CardHeader 
          onClick={()=>this.activeItem("ChoiceCard", this.props.option.id)} 
          style={{backgroundColor:this.props.surveyprops.style.cardheadcolor, color:this.props.surveyprops.style.cardheadtextcolor}}>
             <h5 style={{fontSize:"1.2em", fontWeight:"300", marginBottom:"0px"}}>{this.props.option.optionheader}</h5> 
             {this.props.option.optionheader === "" &&
             <p style={{fontSize:"0.6em", color:"#8f9ba6"}}>Click to edit card</p>
             }
            </CardHeader>
          
          <CardBody style={{backgroundColor: this.props.cardcolor}}>

            {this.getPackageList()}

            {this.props.option.showimage &&  
              <div style={{height:"180px"}}>
              <div tag="a"  onClick={() => this.props.toggleLightbox(this.props.option.img, this.props.option.imageTitle, this.props.option.imageCaption)} style={{cursor: "pointer"}} >
              <Image src={this.props.option.img} width={'100%'} height={180} mode={this.getMode(this.props.option.imageFit)} />
              </div>
              </div>
            }

            {this.props.option.showgif &&
                <div style={{cursor: "pointer"}}>
                <GifPlayer
                    gif={this.props.option.gif}
                    width="100%"
                    />
                <p><small>Click to play</small></p>
                </div>
                
            }

            {this.props.option.showvideo &&
                <div>
                <ReactPlayer
                    url={this.props.option.video}
                    controls='true'
                    muted='true'
                    width='100%'
                    />
                </div>
            }

            {this.props.option.showfeatures &&  
                <FeatureListEditor
                key={this.props.option.id}
                surveyprops={this.props.surveyprops}
                features={this.props.option.features}
                randomize={this.props.option.randomize}
                showprice={this.props.option.showprice}
                price={this.props.option.price}
                pricemetric={this.props.option.pricemetric}
            />}
        
          </CardBody>

          <CardFooter className="survey-choice-card-footer" style={{backgroundColor:this.props.surveyprops.style.cardfootcolor, color: this.props.surveyprops.style.cardfoottextcolor}}>

          {this.renderSelectType()}
            
            {this.props.option.showquestionhelpmessage &&
            <div style={{ marginTop:"15px"}}><p><small>{this.props.option.helpmessage}</small></p></div>
            }

          </CardFooter>

        </Card>           
        </div>
        
    );
  }
}
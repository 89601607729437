import React, { Component } from "react";
import {  Button, Input, Form, FormGroup, Card, CardHeader, CardBody, CardFooter, Row, Col, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Jumbotron,  ModalHeader, ModalBody, ModalFooter, CustomInput} from 'reactstrap';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable'
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import Toggle from 'react-toggle'
import  'react-toggle/style.css'
import GifPlayer from 'react-gif-player';
import ReactPlayer from 'react-player';
import './RenderContent.scss';
import SurveyTextEditor from './SurveyTextEditor'
import FeatureListEditor from '../survey/FeatureListEditor';


/// styling for react-select
const customStyles = (isValid) => ({
    control: (styles: any, state:any) => ({
      ...styles,
      padding: 5,
      width:"100%",
      fontSize: "smaller",
      ...(!isValid && { borderColor: 'red' }),
    }),
  });
/// styling for react-select
const customStylesInModal = (isValid) => ({
    option: (provided, state) => ({
        ...provided,
        fontSize: '1em',
        borderBottom: '1px dotted black',
        backgroundColor: state.isSelected ? '#2f353a' : 'white',
        color: state.isSelected ? '#20a8d8' : '#2f353a',
        padding: 20,
      }),
    control: (styles: any, state:any) => ({
      ...styles,
      padding: 5,
      width:"100%",
      borderColor:"#20a8d8",
      ...(!isValid && { borderColor: 'red' }),
    }),
  });

  /// styling for react-select
const customStyles2 = (isValid) => ({
    control: (styles: any, state:any) => ({
      ...styles,
      backgroundColor:"#f0f3f5",
      padding: 5,
      fontSize: "smaller",
      ...(!isValid && { borderColor: 'red' }),
    }),
  });


  function makeId(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }

 function shuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }

const  selectiontypes = [
    { value: "toggle", label: 'Toggle - yes/no' },
    { value: "discretetoggle", label: 'Discrete Toggle - only one per column and row' },
    { value: "checkbox", label: 'Checkbox - check all that apply' },
    { value: "radio", label: 'Radio - select one' },
    { value: "select", label: 'Select - pick one choice' },
    { value: "selectcreatable", label: 'Select Creatable - pick one or add your own' },
    { value: "multiselect", label: 'Multiselect - pick all that apply' },
    { value: "multiselectcreatable", label: 'Multiselect Creatable- pick all or add own' },
    { value: "allocation", label: 'Allocation - scaled preferences' },
    { value: "range", label: 'Range - choose the upper/lower boundary values' },
    { value: "slider", label: 'Slider - choose along a scale' },
    { value: "textfield", label: 'Text field - custom entry' },
    { value: "textarea", label: 'Text area - free form comments' },
]

export default class ColumnEditorModal extends Component {
  constructor(props) {
    super(props);
        let initialselections = {}
        let initializefalse = ['checkbox', 'radio']
        let val = {}
        let initialtotal = 0
        let initialize = {}
        let initialremaining = this.props.page.questions[0].questionprops.allocationamount
        let newlistrow = this.props.page.questions[0].list.listrow
        let validcolumns = {}
        let pageisvalid = false
        let listindex = null
        let rowid = "TempId"
        let validationtype = null
        let toggletype = null
        let column = null
        for(let c in this.props.page.questions[0].list.listcolumn){

            let colid = this.props.page.questions[0].list.listcolumn[c]['id']

            if(colid === this.props.currentquestion){

                listindex=c
                validationtype =this.props.page.questions[0].list.listcolumn[c]['validationtype']
                toggletype = this.props.page.questions[0].list.listcolumn[c]['toggletype']
                column = this.props.page.questions[0].list.listcolumn[c]
                val[colid] = this.props.page.questions[0].list.listcolumn[c]['initial']

                if (column['type']==="range"){val[colid]=column['rangedefault']}

                if(this.props.page.questions[0].list.listcolumn[c]['validationtype']==="none"){
                    validcolumns[colid]=true
                } else {validcolumns[colid] = false}
                
                if (initializefalse.includes(this.props.page.questions[0].list.listcolumn[c]['type'])){
                    let startvals = {}
                    for (let s of this.props.page.questions[0].list.listcolumn[c]['selectoptions']){startvals[s['value']]=false}
                            val[colid]=startvals
                }
            }
        }
        initialize[rowid]=val

        let keylist = []
        let marklist = []
        if(column['type']==='slider'){
            let sortedmarks = Object.keys(column['slidermarks']).sort().reduce((acc, key) => ({...acc, [key]: column['slidermarks'][key]}), {})
            for (let [key, value] of Object.entries(sortedmarks)){
                marklist.push(value)
                keylist.push(key)
            }
            marklist=marklist.toString()
            keylist=keylist.toString()
        }
        if(column['type']==='allocation'){
            let sortedmarks = Object.keys(column['slidermarks']).sort().reduce((acc, key) => ({...acc, [key]: column['slidermarks'][key]}), {})
            for (let [key, value] of Object.entries(sortedmarks)){
                marklist.push(value)
                keylist.push(key)
            }
            marklist=marklist.toString()
            keylist=keylist.toString()
        }
        if(column['type']==='range'){
            let sortedmarks = Object.keys(column['slidermarks']).sort().reduce((acc, key) => ({...acc, [key]: column['slidermarks'][key]}), {})
            for (let [key, value] of Object.entries(sortedmarks)){
                marklist.push(value)
                keylist.push(key)
            }
            marklist=marklist.toString()
            keylist=keylist.toString()
        }


        initialselections = initialize
        let columntoggle = false
        let rowtoggle = false
        if(toggletype==="both"){
            rowtoggle = true
            columntoggle = true
        }
        if(toggletype==="row"){
            rowtoggle = true
            columntoggle = false
        }
        if(toggletype==="column"){
            rowtoggle = false
            columntoggle = true
        }
        if(toggletype==="column"){
            rowtoggle = false
            columntoggle = true
        }
        if(toggletype==="column"){
            rowtoggle = false
            columntoggle = true
        }
        let oneanswer = false
        let allanswer = false
        if(validationtype = "one"){
            oneanswer = true
            allanswer = false  
        }
        if(validationtype = "all"){
            oneanswer = false
            allanswer = true  
        }
        let columnheader = null
        for(let h in this.props.page.questions[0].list.listhead){
            if(this.props.page.questions[0].list.listhead[h].id===column.id){
                columnheader = this.props.page.questions[0].list.listhead[h].header
            }
        }


        this.state = {
            isValid: false,
            editchoicetype: true,
            isdeleting: false,
            selectvalue: initialselections,
            listrow: newlistrow,
            remaining: initialremaining,
            total: initialtotal,
            validallocationamount:this.props.page.questions[0].questionprops.allocationamount,
            isvalid: pageisvalid,
            validcolumns: validcolumns,
            listindex: listindex,
            allanswer:allanswer,
            oneanswer:oneanswer,
            rowtoggle:rowtoggle,
            columntoggle:columntoggle,
            validationtype:validationtype,
            toggletype:toggletype,
            column: column,
            columnheader: columnheader,
            rowid:rowid,
            marklist: marklist,
            keylist:keylist,
          };
        }
   
    
    updateItemProps = (event) => {
        const numericlist = ["slidermin", "slidermax", "sliderstep", "allocation"]
        const target = event.target;
        let value = target.value;
        const name = target.name;
        if (numericlist.includes(name)){
            value = Number(value)
            if(isNaN(value)){value=0}
        }
        this.setState(prevState => ({
            column: {...prevState.column,           
               [name]: value}
           }))
        }

    ///  marks are a dict in the slider/range component.  Pulled out into two separate lists so you can add and change key values
    updateSliderMarkProps = (event) => {
        const target = event.target;
        let value = target.value;
        const name = target.name;
        this.setState({[name]: value}, ()=>this.updateSliderMarkState())
    }

    /// works on blur so not trying to update dict with keys as they are being typed.  converts the strings into lists and then creates dict
    /// based on the same order as shown in the respective lists
    /// for some reason not able to access STATE in this function....?...
    updateSliderMarkState(){
        let keylist=this.state.keylist
        let marklist=this.state.marklist
        let newmarks = {}
        keylist = keylist.split(",")
        marklist = marklist.split(",")
        for (let k in keylist){
            if(!isNaN(k)){
            if (typeof marklist[k]==='undefined'){
                newmarks[keylist[k]]=""
            } else {
            newmarks[keylist[k]]=marklist[k]
            }
            }
        }
        this.setState(prevState => ({
            column: {...prevState.column,           
                slidermarks: newmarks}
            }))

        }

    getSliderMarks(){
        return(
        <div>

            <p style={{marginBottom:"5px"}}><small>Label Locations (numeric values, separated by commas)</small></p>
            <Input autofocus type="text" name={"keylist"} id={"keylist"}
            value={this.state.keylist.toString()}
            style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
            onChange={this.updateSliderMarkProps}
            />
            <p style={{marginBottom:"5px"}}><small>Label Values (separated by commas)</small></p>
            <Input autofocus type="text"  name={"marklist"} id={"marklist"}
            value={this.state.marklist.toString()}
            style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
            onChange={this.updateSliderMarkProps}
            />

        </div>
        )
        }
        

    updateSelectType = (event) => {
        const target = event.target;
        let value = target.value;
        const name = target.name;
        console.log(name, value)
        this.setState(prevState => ({
            question: {...prevState.question,           
                header: value}
            }))
        }

    updateOptions = (selectvalue, action) => {
        let newoptionlist = []
        let newoption = {}
        console.log('selectvalue', selectvalue)
        console.log('type of', typeof selectvalue)
        for (const o of selectvalue){
            newoption = {}
            newoption['label']=o.label
            newoption['value']=o.value
            newoptionlist.push(newoption)
        }
        this.setState(prevState => ({
            question: {...prevState.question,           
                selectoptions: newoptionlist}
            }))
    };

    updateChoiceProps(i){

        const selectypes = ["selectmulti", "selectcreate"]
        const nonselecttypes = ["html", "toggle", "textfield", "textarea", "checkbox", "radio", "slider", "range", "bigimg", "img", "video", "gif", "contentlist", "jumbotron2", "jumbotron"]

        if(i==='textfield'){this.setState({textarea:!this.state.textarea},
            ()=>this.setState(prevState => ({questionprops: {...prevState.questionprops, selectiontype: i}})))}

        if(i==='textarea'){this.setState({textfield:!this.state.textfield},
            ()=>this.setState(prevState => ({questionprops: {...prevState.questionprops, selectiontype: i}}
            ),()=>this.setState({datefield:false, emailfield:false, numberfield:false})
            ))}

        if(i==='checkbox'){this.setState({radio:!this.state.radio},
            ()=>this.setState(prevState => ({questionprops: {...prevState.questionprops, selectiontype: i}})))}

        if(i==='radio'){this.setState({checkbox:!this.state.checkbox},
            ()=>this.setState(prevState => ({questionprops: {...prevState.questionprops, selectiontype: i}})))}

        if(i==='sliderticks'){this.setState(prevState => ({questionprops: {...prevState.questionprops, sliderticks: !this.state.question.sliderticks}}))}

        if(i==='slidermarks'){
            if(this.state.slidermarks===true){
            this.getSliderMarks()
        }}

        if(i==='slider'){this.setState({range:!this.state.range},
            ()=>this.setState(prevState => ({questionprops: {...prevState.questionprops, selectiontype: i}})))}
        
        if(i==='range'){this.setState({slider:!this.state.slider},
            ()=>this.setState(prevState => ({questionprops: {...prevState.questionprops, selectiontype: i}})))}
        
        if(selectypes.includes(i)){
            let newselecttype = null
            if(this.state.selectmulti === true)
            {
                if(this.state.selectcreate=== true){newselecttype = "multiselectcreatable"} 
                else {newselecttype = "multiselect"}
            } 
            else 
            {
                if(this.state.selectcreate===true){newselecttype = "selectcreatable"}
                else {newselecttype = "select"}
            }
            this.setState(prevState => ({questionprops: {...prevState.questionprops, selectiontype: newselecttype}}))
        }

    }

    /// Changes the state params - AND logic to change the question type
    handleModalToggle(i){

        this.setState({[i]:!this.state[i]}, ()=>this.updateChoiceProps(i))

        }

    /// had some selectvalue initialization logic so when the new type is rendered the required default values are already set
    handleChoiceTypeChange = (selectvalue:any, action: any) => {
        let initialize = {}
        initialize[this.state.rowid]={}
        let keylist = []
        let marklist = []

        if(['select', 'multiselect', 'selectcreatable', 'multiselectcreatable', 'range', 'slider'].includes(selectvalue.value)){
            initialize[this.state.rowid][this.state.column.id]=null
        }
        if(['checkbox', 'radio'].includes(selectvalue.value)){
            let v = []
            let w = {}
            if(typeof this.state.column.selectoptions !=='undefined'){
                for(let x of this.state.column.selectoptions){
                    w[x.value]=false
                    v.push(w)
                }
            }
            initialize[this.state.rowid][this.state.column.id]=v
        }
        if(['toggle', 'discrete-toggle'].includes(selectvalue.value)){
            initialize[this.state.rowid]=false
        }
        if(['text', 'textarea'].includes(selectvalue.value)){
            initialize[this.state.rowid][this.state.column.id]=""
        }
        if(['allocation'].includes(selectvalue.value)){
            initialize[this.state.rowid][this.state.column.id]=100
            let sortedmarks = Object.keys(this.state.column['slidermarks']).sort().reduce((acc, key) => ({...acc, [key]: this.state.column['slidermarks'][key]}), {})
            for (let [key, value] of Object.entries(sortedmarks)){
                marklist.push(value)
                keylist.push(key)
            }
            marklist=marklist.toString()
            keylist=keylist.toString()
        }

        if(['range'].includes(selectvalue.value)){
            initialize[this.state.rowid][this.state.column.id]=[0,100]
            let sortedmarks = Object.keys(this.state.column['slidermarks']).sort().reduce((acc, key) => ({...acc, [key]: this.state.column['slidermarks'][key]}), {})
            for (let [key, value] of Object.entries(sortedmarks)){
                marklist.push(value)
                keylist.push(key)
            }
            marklist=marklist.toString()
            keylist=keylist.toString()
        }

        if(['slider'].includes(selectvalue.value)){
            initialize[this.state.rowid][this.state.column.id]=null
            let sortedmarks = Object.keys(this.state.column['slidermarks']).sort().reduce((acc, key) => ({...acc, [key]: this.state.column['slidermarks'][key]}), {})
            for (let [key, value] of Object.entries(sortedmarks)){
                marklist.push(value)
                keylist.push(key)
            }
            marklist=marklist.toString()
            keylist=keylist.toString()
        }

        this.setState(prevState => ({
            column: {...prevState.column, type: selectvalue.value},
            selectvalue: initialize,
            marklist: marklist,
            keylist:keylist
        }))
    };

    updateOptions = (selectvalue, action) => {
        let newoptionlist = []
        let newoption = {}
        if(selectvalue !== null){
            for (const o of selectvalue){
                newoption = {}
                newoption['label']=o.label
                newoption['value']=o.value
                newoptionlist.push(newoption)
            }
        } else {newoptionlist =[{}] }
        this.setState(prevState => ({
           column: {...prevState.column,           
                selectoptions: newoptionlist}
            }))
    };

    updateHeader = (event) => {
        const target = event.target;
        let value = target.value;
        const name = target.name;
        this.setState(prevState => ({
            columnheader: value
            }))
        }


    /// updating selection for r=row, c=column, v=value
    //// passing in hard coded "TempId" since selection doesnt
    //// matter in modal and there is no use for Row info
    changeSelection(r, c, v){

        this.setState(prevState => ({
                selectvalue: {
                ...prevState.selectvalue,          
                [r]: {                     
                ...prevState.selectvalue[r], 
                [c]: v         
                }
                }
                }))
        }

      /// callback to send selection update - for row, column, and bool toggle
  handleToggleChange(colid){
    this.changeSelection(this.state.rowid, colid, !this.state.selectvalue[this.state.rowid][colid])
}

/// callback to send selection update - for row, column, and bool toggle.  discrete toggle has type - logic to keep it frm being
/// valid one per column, one per row or both of these
handleDiscreteToggle(colid, type){
  this.changeDiscreteToggle(this.state.rowid, colid, !this.state.selectvalue[this.state.rowid][colid], type)
}

   /// updating selection for r=row, c=column, v=value, t=type (row - cant have more than one toggle in row, likewise for column, and can also enforce both for max-diff type)
   changeDiscreteToggle(r, c, v, t){

    if(t==="column" && v===true){
        for (let i of Object.keys(this.state.selectvalue)){

        this.setState(prevState => ({
            selectvalue: {
            ...prevState.selectvalue,          
            [i]: {                     
            ...prevState.selectvalue[i], 
            [c]: false      
            }
            }
            }))
        }
        this.setState(prevState => ({
            selectvalue: {
            ...prevState.selectvalue,          
            [r]: {                     
            ...prevState.selectvalue[r], 
            [c]: true     
            }
            }
            }),() => {this.columnValidation(c)})
    }

    if(t==="row" && v===true){
            for(let j of Object.keys(this.state.selectvalue[r])){
                for(let k in this.props.list.listcolumn){
                    if(this.props.list.listcolumn[k]['id']===j){
                        if (this.props.list.listcolumn[k]['type']==="discretetoggle"){
                            this.setState(prevState => ({
                                selectvalue: {
                                ...prevState.selectvalue,          
                                [r]: {                     
                                ...prevState.selectvalue[r], 
                                [this.props.list.listcolumn[k]['id']]: false     
                                }
                                }
                                }),() => {this.columnValidation(c)})
                            } 
                        }
                    }
                }
            this.setState(prevState => ({
                selectvalue: {
                ...prevState.selectvalue,          
                [r]: {                     
                ...prevState.selectvalue[r], 
                [c]: true      
                }
                }
                }),() => {this.columnValidation(c)})
            }

        if(t==="both" && v===true){
            for (let i of Object.keys(this.state.selectvalue)){

                this.setState(prevState => ({
                    selectvalue: {
                    ...prevState.selectvalue,          
                    [i]: {                     
                    ...prevState.selectvalue[i], 
                    [c]: false      
                    }
                    }
                    }),() => {this.columnValidation(c)})
                }
            for(let j of Object.keys(this.state.selectvalue[r])){
                for(let k in this.props.list.listcolumn){
                    if(this.props.list.listcolumn[k]['id']===j){
                        if (this.props.list.listcolumn[k]['type']==="discretetoggle"){
                            this.setState(prevState => ({
                                selectvalue: {
                                ...prevState.selectvalue,          
                                [r]: {                     
                                ...prevState.selectvalue[r], 
                                [this.props.list.listcolumn[k]['id']]: false     
                                }
                                }
                                }),() => {this.columnValidation(c)})
                            } 
                        }
                    }
                }
            this.setState(prevState => ({
                selectvalue: {
                ...prevState.selectvalue,          
                [r]: {                     
                ...prevState.selectvalue[r], 
                [c]: true      
                }
                }
                }),() => {this.columnValidation(c)})
            }
    }

/// below functions are to handle different selection types
handleSelectChange = (selectvalue:any, action:any) => {
  this.changeSelection(this.state.rowid, action.name, selectvalue)
};

/// NOTE the SLIDER-RANGE column id name - there is no variable-passed names, so this is ** HARD-CODED ** and therefore ONLY ONE per page
handleSliderChange = (value) => {
  this.changeSelection(this.state.rowid, "slider-range", value)
}

/// below handles allocation updates
handleAllocationChange = (event) => {
  const target = event.target;
  let value = Number(target.value);
  const name = target.name;
  let type = target.type
  this.changeAllocation(this.state.rowid, name, value)
}

/// below handles field input updates.  since event listening are shared, when use onChange - need to stop the null target or will error
handleFieldChange = (event) => {
  const target = event.target;
  if(target != null){
    let value = target.value;
    const name = target.name;
    this.changeSelection(this.state.rowid, name, value)
  }
}

/// below handles radio/check input updates.  since event listening are shared, when use onChange - need to stop the null target or will error
handleRadioCheckChange = (event) => {
  const target = event.target;
  if(target != null){
    let type = target.type
    let value = target.value;
    const name = target.name;
    this.toggleRadioCheck(this.state.rowid, name, value, type)
  }
}

    /// updating selection for r=row, c=column, v=value, t=type
    toggleRadioCheck(r, c, e, t){

        if(t==='radio'){
            let newvals = {}
            for (let p in this.state.selectvalue[r][c])
                {
                newvals[p]=false
                }
            newvals[e]=true

            this.setState(prevState => ({
                selectvalue: {
                ...prevState.selectvalue,          
                [r]: {                     
                ...prevState.selectvalue[r], 
                [c]: newvals

                }
                }
                }))
        } else {

            this.setState(prevState => ({
                selectvalue: {
                ...prevState.selectvalue,          
                [r]: {                     
                ...prevState.selectvalue[r], 
                [c]: {
                ...prevState.selectvalue[r][c], 
                [e]: !prevState.selectvalue[r][c][e]
                }
                }
                }
                }))
            }
    }


  /// used to generate the radio and checkbox items
  getSelectItems(item, type, id){
    if(type==="checkbox"){
      return(
      <CustomInput type="checkbox" id={item['value']} label={item['label']} name={id}></CustomInput>

      )}
      if(type==="radio"){
        return(
        <CustomInput type="radio" id={item['value']} label={item['label']} name={id}></CustomInput>
      )}
  }

/// this determines which selection component to use in the card footer
renderSelectType(column){

  if (column.type === "select"){
    return(
        <Row>
        <Col>
                <p style={{marginBottom:"5px"}}><small>Add/Edit Select Options</small></p>
                <CreatableSelect
                isMulti
                defaultValue={this.state.column.selectoptions}
                onChange={this.updateOptions}
                options={this.state.column.selectoptions}
                name={this.state.column.id}
                styles={customStyles2(true)}
                />
            </Col>
            <Col>
                <p style={{marginBottom:"5px"}}><small>Your Select Widget</small></p>
                <Select
                key={column.id}
                id={column.id}
                name={column.id}
                defaultValue={this.state.selectvalue[column.id]}
                value={this.state.selectvalue[column.id]}
                onChange={this.handleSelectChange}
                options={column.selectoptions}
                styles={customStyles(true)}
                />
            </Col>
        </Row>
        )

    }
  if (column.type === "selectcreatable"){
    return(
        <Row>
        <Col>
                <p style={{marginBottom:"5px"}}><small>Add/Edit Select Options</small></p>
                <CreatableSelect
                isMulti
                defaultValue={this.state.column.selectoptions}
                onChange={this.updateOptions}
                options={this.state.column.selectoptions}
                name={this.state.column.id}
                styles={customStyles2(true)}
                />
            </Col>
            <Col>
                <p style={{marginBottom:"5px"}}><small>Your Select-Creatable Widget</small></p>
                <CreatableSelect
                key={this.state.column.id}
                id={this.state.column.id}
                name={this.state.column.id}
                defaultValue={this.state.selectvalue[this.state.column.id]}
                value={this.state.selectvalue[this.state.column.id]}
                onChange={this.handleSelectChange}
                options={this.state.column.selectoptions}
                styles={customStyles(true)} 
                />
            </Col>
        </Row>
    )
      }

  if (column.type === "multiselect"){
    return(
        <Row>
        <Col>
                <p style={{marginBottom:"5px"}}><small>Add/Edit Select Options</small></p>
                <CreatableSelect
                isMulti
                defaultValue={this.state.column.selectoptions}
                onChange={this.updateOptions}
                options={this.state.column.selectoptions}
                name={this.state.column.id}
                styles={customStyles2(true)}
                />
            </Col>
            <Col>
                <p style={{marginBottom:"5px"}}><small>Your MultiSelect Widget</small></p>
                <Select
                isMulti
                key={this.state.column.id}
                id={this.state.column.id}
                name={this.state.column.id}
                defaultValue={this.state.selectvalue[this.state.rowid][this.state.column.id]}
                onChange={this.handleSelectChange}
                options={this.state.column.selectoptions}
                styles={customStyles(true)}  
                />
            </Col>
        </Row>
        
    )
      }

  if (column.type === "multiselectcreatable"){
    return(
        <Row>
        <Col>
                <p style={{marginBottom:"5px"}}><small>Add/Edit Select Options</small></p>
                <CreatableSelect
                isMulti
                defaultValue={this.state.column.selectoptions}
                onChange={this.updateOptions}
                options={this.state.column.selectoptions}
                name={this.state.column.id}
                styles={customStyles2(true)}
                />
            </Col>
            <Col>
                <p style={{marginBottom:"5px"}}><small>Your MultiSelect-Creatable Widget</small></p>
                <CreatableSelect
                key={this.state.column.id}
                id={this.state.column.id}
                name={this.state.column.id}
                defaultValue={this.state.selectvalue[this.state.rowid][this.state.column.id]}
                isMulti
                onChange={this.handleSelectChange}
                options={this.state.column.selectoptions}
                styles={customStyles(true)}  
                />
            </Col>
        </Row>
    )
      }


  if (column.type === "allocation"){
    return(
        <div style={{  margin: 20 }}>
        <p style={{marginBottom:"5px"}}><small>Allocation Widget</small></p>
        <Slider 
        marks={this.state.column.slidermarks}
        min={this.state.column.slidermin}
        max={this.state.column.allocationamount}
        step={this.state.column.sliderstep}
        defaultValue={this.state.selectvalue[this.state.rowid][this.state.column.id]}
        onAfterChange={(e)=>this.handleAllocationChange(this.state.column.id, e)}
        dotStyle={{borderColor: '#c8ced3'}}
        activeDotStyle={{borderColor: '#c8ced3'}}
        />
            <Row style={{marginTop:"30px"}}>
            <Col>
            <p style={{marginBottom:"5px"}}><small>Min</small></p>
            <Input 
            disabled
            type="number" max={0} name={"slidermin"} id={this.state.column.id}
            value={0}
            style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
            onChange={this.updateItemProps}
            />
            </Col>
            <Col>
            <p style={{marginBottom:"5px"}}><small>Allocation Amount</small></p>
            <Input type="number" min={this.state.column.slidermin} name={"allocationamount"} id={this.state.column.id}
            value={this.state.column.allocationamount}
            style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
            onChange={this.updateItemProps}
            />
            </Col>
            <Col>
            <p style={{marginBottom:"5px"}}><small>Step</small></p>
            <Input type="mumber" max={this.state.column.slidermax} name={"sliderstep"} id={this.state.column.id}
            value={this.state.column.sliderstep}
            style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
            onChange={this.updateItemProps}
            />
            </Col>
            </Row>

            {this.getSliderMarks()}

        </div>
        )  
  }


  if (column.type === "slider"){
    return(
    
    <div>
      <Slider
      name={this.state.column.id}
      marks={this.state.column.slidermarks}
      min={this.state.column.slidermin}
      max={this.state.column.slidermax}
      step={this.state.column.sliderstep} 
      defaultValue={this.state.selectvalue[this.state.rowid][this.state.column.id]}
      onAfterChange={this.handleSliderChange} 
      dotStyle={{borderColor: '#c8ced3'}}
      activeDotStyle={{borderColor: '#c8ced3'}}
      />


      <Row style={{marginTop:"30px"}}>
      <Col>
      <p style={{marginBottom:"5px"}}><small>Min</small></p>
      <Input type="number" max={this.state.column.slidermax} name={"slidermin"} id={this.state.column.id}
      value={this.state.column.slidermin}
      style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
      onChange={this.updateItemProps}
      />
      </Col>
      <Col>
      <p style={{marginBottom:"5px"}}><small>Max</small></p>
      <Input type="number" min={this.state.column.slidermin} name={"slidermax"} id={this.state.column.id}
      value={this.state.column.slidermax}
      style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
      onChange={this.updateItemProps}
      />
      </Col>
      <Col>
      <p style={{marginBottom:"5px"}}><small>Step</small></p>
      <Input type="mumber" max={this.state.column.slidermax} name={"sliderstep"} id={this.state.column.id}
      value={this.state.column.sliderstep}
      style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
      onChange={this.updateItemProps}
      />
      </Col>
      </Row>

      {this.getSliderMarks()}

    </div>


    )
  }




  if (column.type === "range"){
    return(
    <div>
        <Range
      name={this.state.column.id}
      marks={this.state.column.slidermarks}
      min={this.state.column.slidermin}
      max={this.state.column.slidermax}
      step={this.state.column.sliderstep} 
      defaultValue={this.state.selectvalue[this.state.rowid][this.state.column.id]}
      tipFormatter={value => `${value}`}
      onAfterChange={this.handleSliderChange}
        />

        <Row style={{marginTop:"30px"}}>
        <Col>
        <p style={{marginBottom:"5px"}}><small>Min</small></p>
        <Input type="number" max={this.state.column.slidermax} name={"slidermin"} id={this.state.column.id}
        value={this.state.column.slidermin}
        style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
        onChange={this.updateItemProps}
        />
        </Col>
        <Col>
        <p style={{marginBottom:"5px"}}><small>Max</small></p>
        <Input type="number" min={this.state.column.slidermin} name={"slidermax"} id={this.state.column.id}
        value={this.state.column.slidermax}
        style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
        onChange={this.updateItemProps}
        />
        </Col>
        <Col>
        <p style={{marginBottom:"5px"}}><small>Step</small></p>
        <Input type="mumber" max={this.state.column.slidermax} name={"sliderstep"} id={this.state.column.id}
        value={this.state.column.sliderstep}
        style={{marginBottom:"10px", backgroundColor:"#f0f3f5"}}
        onChange={this.updateItemProps}
        />
        </Col>
        </Row>
  
        {this.getSliderMarks()}
  
      </div>

    )
  }
  if (column.type === "toggle"){
  return(
      <div>
        <p style={{marginBottom:"5px"}}><small>Your Toggle Button</small></p>
        <Toggle
        id={this.state.column.id}
        value={this.state.column.value}
        checked= {this.state.selectvalue[this.state.rowid][this.state.column.id]}
        icons={{unchecked: null}}
        className = {column.style}
        onChange = {() => this.handleToggleChange(this.state.column.id)}
        />
        </div>

  )
  }
  /// using same as toggle since no validation in the modal
  if (column.type === "discretetoggle"){
      return(
        <div>
        <p style={{marginBottom:"5px"}}><small>Your Toggle Button</small></p>
          <Toggle
          id={this.state.column.id}
          value={this.state.column.value}
          checked= {this.state.selectvalue[this.state.rowid][this.state.column.id]}
          icons={{unchecked: null}}
          className = {column.style}
          onChange = {() => this.handleToggleChange(this.state.column.id)}
      />
    </div>
      )
      }

  if (column.type === "textfield"){
      return(
        <div>
        <p style={{marginBottom:"5px"}}><small>Your Text Input Field</small></p>
        <Input 
        name={this.state.column.id}
        type={this.state.column.texttype} 
        value={this.state.selectvalue[this.state.rowid][this.state.column.id]}
        onChange={this.handleFieldChange}/>
        </div>
      )
  }
  if (column.type === "textarea"){
    return(
        <div>
        <p style={{marginBottom:"5px"}}><small>Your Textarea Field</small></p>

         <Input 
        name={this.state.column.id}
        type={"textarea"} 
        value={this.state.selectvalue[this.state.rowid][this.state.column.id]}
        onChange={this.handleFieldChange}/>
        </div>
    )
  }

  if (column.type === "checkbox"){
    return(
    <Row>
    <Col>
            <p style={{marginBottom:"5px"}}><small>Add/Edit Checkbox Options</small></p>
            <CreatableSelect
            isMulti
            defaultValue={this.state.column.selectoptions}
            onChange={this.updateOptions}
            options={this.state.column.selectoptions}
            name={this.state.column.id}
            styles={customStyles2(true)}
            />
        </Col>
        <Col>
            <p style={{marginBottom:"5px"}}><small>Your Checkbox Widget</small></p>
            <Form>
                <FormGroup inline name={this.state.column.id} onChange={this.handleRadioCheckChange}>
                    {typeof this.state.column.selectoptions.map !== 'undefined' && 
                    this.state.column.selectoptions.map((item, key=column.id)=>this.getSelectItems(item, "checkbox", column['id']))
                    }
                </FormGroup>
            </Form>
        </Col>
    </Row>

    )
      
  }


  if (column.type === "radio"){
    return(
    <Row>
    <Col>
            <p style={{marginBottom:"5px"}}><small>Add/Edit Checkbox Options</small></p>
            <CreatableSelect
            isMulti
            defaultValue={this.state.column.selectoptions}
            onChange={this.updateOptions}
            options={this.state.column.selectoptions}
            name={this.state.column.id}
            styles={customStyles2(true)}
            />
        </Col>
        <Col>
            <p style={{marginBottom:"5px"}}><small>Your Radio Button Widget</small></p>
            <Form>
                <FormGroup inline name={this.state.column.id} onChange={this.handleRadioCheckChange}>
                    {typeof this.state.column.selectoptions.map !== 'undefined' &&
                   this.state.column.selectoptions.map((item, key=column.id)=>this.getSelectItems(item, "radio", column['id']))
                    }
                </FormGroup>
            </Form>
        </Col>
    </Row>

    )
  }
}



    /// splices out the column and header entry and sends the new list for updating
    deleteColumn(){
        let columnheadindex = null
        for(let h in this.props.page.questions[0].list.listhead){
            if(this.props.page.questions[0].list.listhead[h].id===this.state.column.id){
                columnheadindex = h
            }
        }
        let copyhead = [...this.props.page.questions[0].list.listhead]
        copyhead = copyhead.splice(columnheadindex,1)
        let listupdate = {}
        listupdate['type']=this.props.modalediting
        listupdate['questionId']=this.props.page.questions[0].questionId
        listupdate['listId']=this.props.page.questions[0].list.listid
        listupdate['questionId']=this.props.currentquestion
        listupdate['columnid']=this.props.currentquestion

        this.props.deleteColumn(listupdate)
    }

    updateColumn(){
        let columnheadindex = null
        for(let h in this.props.page.questions[0].list.listhead){
            if(this.props.page.questions[0].list.listhead[h].id===this.state.column.id){
                columnheadindex = h
            }
        }
        let copyhead = [...this.props.page.questions[0].list.listhead]
        copyhead[columnheadindex].header=this.state.columnheader
        let listupdate = {}
        let copylist = [...this.props.page.questions[0].list.listcolumn]
        copylist[this.state.listindex]=this.state.column
        listupdate['type']=this.props.modalediting
        listupdate['questionId']=this.props.page.questions[0].questionId
        listupdate['listId']=this.props.page.questions[0].list.listid
        listupdate['questionId']=this.props.currentquestion
        listupdate['listhead']=copyhead
        listupdate['listcolumn'] =copylist
        this.props.saveItem(listupdate)
    }



  render(){

    return(
    <div>
        <ModalHeader style={{backgroundColor:"#2f353a", color:"#8f9ba6", fontSize:"1.2em", fontWeight:"200"}}>
        {`SurveyList ${this.props.page.questions[0].list.listid}  `}
            {this.state.isdeleting &&
            <p style={{color:'#f86c6b', fontSize:"0.8em", fontWeight:"200", marginTop:"0px"}}>Please confirm that you want to delete this item</p>
            }
        </ModalHeader>
        <ModalBody >
        <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Column Heading</p>
        <h5 style={{fontSize:"1.2em", fontWeight:"300", marginBottom:"15px"}}>

            <Input type="text" name={"columnheader"} id={"columnheader"}
            value={this.state.columnheader}
            placeholder={"Column Header"}
            style={{marginBottom:"10px", backgroundColor:"#f0f3f5", fontSize:"1.2em", fontWeight:"200"}}
            onChange={this.updateHeader}
            />
        </h5>



        {this.renderSelectType(this.state.column)}
       


        <div style={{ padding:"30px", marginLeft:"-16px", marginRight:"-16px", marginBottom:"-16px", marginTop:"50px", color:"white", fontSize:"0.8em", backgroundColor:"#2f353a" }}>
                
                <Row style={{display:"flex", alignItems:"flex-end", marginTop:"15px"}}>
                <Col>
                <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Edit Choice Type</p>
                <Toggle
                    id={"editchoicetype"}
                    checked= {this.state.editchoicetype}
                    icons={{unchecked: null}}
                    className = {"custom-success"}
                    onChange = {() => this.handleModalToggle('editchoicetype')}
                        />
                </Col>
                <Col>
                <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>An Answer Required</p>
                <Toggle
                    id={"oneanswer"}
                    checked= {this.state.oneanswer}
                    icons={{unchecked: null}}
                    className = {"custom-success"}
                    onChange = {() => this.handleModalToggle('oneanswer')}
                        />
                </Col>
                <Col>
                <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Answer for Each Row</p>
                <Toggle
                    id={"allanswer"}
                    checked= {this.state.allanswer}
                    icons={{unchecked: null}}
                    className = {"custom-success"}
                    onChange = {() => this.handleModalToggle('allanswer')}
                        />
                </Col>
        
                    <Col>
                    {['toggle', 'discrete-toggle'].includes(this.state.column.type) &&
                    <div>
                    <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Only One per Column</p>
                    <Toggle
                        id={"columntoggle"}
                        checked= {this.state.columntoggle}
                        icons={{unchecked: null}}
                        className = {"custom-success"}
                        onChange = {() => this.handleModalToggle('columntoggle')}
                            />
                    </div>
                    }
                    </Col>
                    <Col>
                    {['toggle', 'discretetoggle'].includes(this.state.column.type) &&
                    <div>
                    <p style={{ marginBottom:"5px", fontSize:"0.8em"}}>Only One Toggle per Row</p>
                    <Toggle
                        id={"rowtoggle"}
                        checked= {this.state.rowtoggle}
                        icons={{unchecked: null}}
                        className = {"custom-success"}
                        onChange = {() => this.handleModalToggle('rowtoggle')}
                            />
                    </div>
                    }
                    </Col>
                </Row>

                {this.state.editchoicetype &&
                <div style={{marginTop:"15px"}}>
                    <Select
                    defaultValue={selectiontypes[this.state.selectiontypeindex]}
                    onChange={this.handleChoiceTypeChange}
                    options={selectiontypes}
                    styles={customStylesInModal(true)}
                    />
                </div>
                }

        </div>

        </ModalBody>

        <ModalFooter style={{marginTop:"0"}}>
        <div style={{alignSelf:"flex-start", marginRight:"auto"}}>
            {!this.state.isdeleting &&
            <Button color="danger" onClick={()=>this.setState({isdeleting:true})}>Delete Column</Button>
            }
            {this.state.isdeleting &&
            <Button color="danger" onClick={()=>this.deleteColumn()}>Confirm Delete</Button>
            }
        </div>
        {this.state.isdeleting &&
        <Button color="secondary" onClick={()=>this.setState({isdeleting:false})} >Cancel Delete</Button>
        }
        {!this.state.isdeleting &&
        <div>
        {'  '}
        <Button color="primary" onClick={()=>this.updateColumn()} >Save</Button>
        {'  '}
        <Button color="secondary" onClick={()=>this.props.toggleModal()} >Cancel</Button>
        </div>
        }
    </ModalFooter>

  </div>
    );
  }
}